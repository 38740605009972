import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface RibbonIconsProps {
  variant: string;
}

const RibbonIcons = ({ variant }: RibbonIconsProps) => {
  switch (variant) {
    case "warning":
      return <WarningIcon />;
    case "info":
    default:
      return <InfoIcon />;
  }
};

interface BannerProps {
  onClose?: () => void;
  icon?: "warning" | "info";
  color?: string;
  closeable?: boolean;
  title?: string;
}

export const NoticeBanner = (props: BannerProps) => {
  return (
    <Box
      id={"notice-banner"}
      sx={{
        backgroundColor: props.color,
        height: "48px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
        z: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <RibbonIcons variant={props?.icon ?? "info"} />
        <Typography sx={{ pl: 2, fontSize: 12 }}>{props?.title}</Typography>
      </Box>
      {props.closeable && (
        <IconButton
          size="small"
          onClick={() => {
            props.onClose && props.onClose();
          }}
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
