import { Asset } from "./Asset";
import { Token } from "./Token";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";

/**
 * Asset wrapper is used to where either an Asset or SmartInstrument need to
 * be interacted with as 1.
 */
export class AssetWrapper implements Asset {
  ["@type"]: string = "AssetWrapper";

  private asset: Asset | SmartInstrument | undefined = undefined;

  constructor(asset: Asset | SmartInstrument) {
    this.asset = asset;
  }

  getAsset(): Asset {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      throw TypeError("asset is SmartInstrument");
    }
    return this.asset;
  }

  getSmartInstrument(): SmartInstrument {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (!(this.asset instanceof SmartInstrument)) {
      throw TypeError("asset is not SmartInstrument");
    }
    return this.asset;
  }

  assetID(): string {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getId();
    }
    return this.asset.assetID();
  }

  assetOwnerID(): string {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getOwnerid();
    }
    return this.asset.assetOwnerID();
  }

  assetToken(): Token {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return Token.fromFutureToken(this.asset.getToken());
    }
    return this.asset.assetToken();
  }

  assetIssueDate(): string {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getToken()?.getCode() ?? "";
    }
    return this.asset.assetIssueDate();
  }

  assetName(): string {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getName();
    }
    return this.asset.assetName();
  }

  assetShortName(): string {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getToken()?.getCode() ?? "";
    }
    return this.asset.assetShortName();
  }

  assetFractionalisationAllowed(): boolean {
    if (!this.asset) {
      throw TypeError("asset not set on asset wrapper");
    }
    if (this.asset instanceof SmartInstrument) {
      return this.asset.getFractionalisationallowed();
    }
    return this.asset.assetFractionalisationAllowed();
  }
}
