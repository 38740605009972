import dayjs from "dayjs";
import { Document } from "james/document";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { DigitalInstrument } from "./DigitalInstrument";
import { AuditEntry } from "../audit";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { FinancialInstrumentState } from "./InstrumentState";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export const DigitalETNTypeName = "mesh::financial/DigitalETN";

export enum DigitalETNState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllDigitalETNStates: DigitalETNState[] = [
  DigitalETNState.Draft,
  DigitalETNState.PreIssued,
  DigitalETNState.Issued,
  DigitalETNState.Matured,
  DigitalETNState.Cancelled,
  DigitalETNState.Deleted,
];

export enum DigitalETNAction {
  //
  // DigitalETNCreator Actions
  //
  New,

  //
  // DigitalETNStateChanger Actions
  //
  MarkDeleted,
  Issue,
  PreIssue,
  MarkIssued,
  Cancel,
  Mature,

  //
  // DigitalETNUpdater Actions
  //
  DraftUpdate,
  IncreaseMaximumUnits,
  DecreaseMaximumUnits,
  ChangeMaturityDate,
  ChangeHoldings,
  ChangeSectorAllocations,
  ChangeCountryAllocations,
  ChangeAnnualPerformanceLog,
  ChangeSupportingDocuments,
}

export class DigitalETN implements DigitalInstrument {
  public ["@type"]: string = DigitalETNTypeName;

  public id = "";

  public ownerID = "";

  public state: DigitalETNState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public name = "";

  public shortName = "";

  public assetClass: AssetClassDEPRECATED | "" = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public token: Token = new Token();

  public maximumUnits: Amount = new Amount();

  public valuationToken: Token = new Token();

  public supportingDocuments: Document[] = [];

  public investorProfile: InvestorProfile | "" = "";

  public investorProfileDescription = "";

  public riskProfile: InstrumentRiskProfile | "" = "";

  public riskProfileDescription = "";

  public holdings: Holding[] = [];

  public sectorAllocations: SectorAllocation[] = [];

  public countryAllocations: CountryAllocation[] = [];

  public annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

  constructor(digitalETN?: DigitalETN) {
    if (!digitalETN) {
      return;
    }
    this.id = digitalETN.id;
    this.ownerID = digitalETN.ownerID;
    this.state = digitalETN.state;
    this.auditEntry = new AuditEntry(digitalETN.auditEntry);
    this.name = digitalETN.name;
    this.shortName = digitalETN.shortName;
    this.assetClass = digitalETN.assetClass;
    this.issueDate = digitalETN.issueDate;
    this.maturityDate = digitalETN.maturityDate;
    this.token = new Token(digitalETN.token);
    this.maximumUnits = new Amount(digitalETN.maximumUnits);
    this.valuationToken = new Token(digitalETN.valuationToken);
    this.supportingDocuments = digitalETN.supportingDocuments.map(
      (d) => new Document(d),
    );
    this.investorProfile = digitalETN.investorProfile;
    this.investorProfileDescription = digitalETN.investorProfileDescription;
    this.riskProfile = digitalETN.riskProfile;
    this.riskProfileDescription = digitalETN.riskProfileDescription;
    this.holdings = digitalETN.holdings.map((h) => new Holding(h));
    this.sectorAllocations = digitalETN.sectorAllocations.map(
      (s) => new SectorAllocation(s),
    );
    this.countryAllocations = digitalETN.countryAllocations.map(
      (c) => new CountryAllocation(c),
    );
    this.annualPerformanceLog = digitalETN.annualPerformanceLog.map(
      (ple) => new InstrumentAnnualPerformanceLogEntry(ple),
    );
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  digitalInstrumentValuationToken(): Token {
    return this.valuationToken;
  }

  digitalInstrumentMaximumUnits(): Amount {
    return this.maximumUnits;
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return this.assetClass;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return this.annualPerformanceLog;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }

  digitalInstrumentIssuePrice(): Amount {
    // TODO: return price here once it is moved back to the instruments
    return new Amount();
  }
}

export function getPotentialNextDigitalETNActions(
  digitalETNState: DigitalETNState | "",
): DigitalETNAction[] {
  switch (digitalETNState) {
    case DigitalETNState.Draft: {
      return [
        DigitalETNAction.MarkDeleted,
        DigitalETNAction.DraftUpdate,
        DigitalETNAction.Issue,
        DigitalETNAction.PreIssue,
      ];
    }

    case DigitalETNState.PreIssued: {
      return [
        DigitalETNAction.MarkIssued,
        DigitalETNAction.Cancel,
        DigitalETNAction.IncreaseMaximumUnits,
        DigitalETNAction.DecreaseMaximumUnits,
        DigitalETNAction.ChangeMaturityDate,
        DigitalETNAction.ChangeHoldings,
        DigitalETNAction.ChangeSectorAllocations,
        DigitalETNAction.ChangeCountryAllocations,
        DigitalETNAction.ChangeAnnualPerformanceLog,
        DigitalETNAction.ChangeSupportingDocuments,
      ];
    }

    case DigitalETNState.Issued:
      return [
        DigitalETNAction.Cancel,
        DigitalETNAction.ChangeHoldings,
        DigitalETNAction.ChangeSectorAllocations,
        DigitalETNAction.ChangeCountryAllocations,
        DigitalETNAction.ChangeAnnualPerformanceLog,
        DigitalETNAction.ChangeSupportingDocuments,
        DigitalETNAction.Mature,
      ];

    case DigitalETNState.Matured:
      return [];

    case DigitalETNState.Cancelled:
      return [];

    case DigitalETNState.Deleted:
      return [];

    default:
      throw new TypeError(`invalid DigitalETNState: '${digitalETNState}'`);
  }
}
