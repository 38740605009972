import { Document } from "james/document";
import config from "react-global-configuration";
import { UnitTrust } from "./UnitTrust";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { Context } from "../security";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export type DraftUpdateUnitTrustRequest = {
  context: Context;
  unitTrust: UnitTrust;
};

export type DraftUpdateUnitTrustResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustMaturityDateRequest = {
  context: Context;
  unitTrustID: string;
  maturityDate: string;
};

export type ChangeUnitTrustMaturityDateResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustHoldingsRequest = {
  context: Context;
  unitTrustID: string;
  updatedHoldings: Holding[];
};

export type ChangeUnitTrustHoldingsResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustSectorAllocationsRequest = {
  context: Context;
  unitTrustID: string;
  updatedSectorAllocations: SectorAllocation[];
};

export type ChangeUnitTrustSectorAllocationsResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustCountryAllocationsRequest = {
  context: Context;
  unitTrustID: string;
  updatedCountryAllocations: CountryAllocation[];
};

export type ChangeUnitTrustCountryAllocationsResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustAnnualPerformanceLogRequest = {
  context: Context;
  unitTrustID: string;
  updatedAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
};

export type ChangeUnitTrustAnnualPerformanceLogResponse = {
  unitTrust: UnitTrust;
};

export type ChangeUnitTrustSupportingDocumentsRequest = {
  context: Context;
  unitTrustID: string;
  updatedSupportingDocuments: Document[];
};

export type ChangeUnitTrustSupportingDocumentsResponse = {
  unitTrust: UnitTrust;
};

export const UnitTrustUpdater = {
  serviceProvider: "financial-UnitTrustUpdater",
  async DraftUpdateUnitTrust(
    request: DraftUpdateUnitTrustRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DraftUpdateUnitTrustResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.DraftUpdateUnitTrust`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustMaturityDate(
    request: ChangeUnitTrustMaturityDateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustMaturityDateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustMaturityDate`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustHoldings(
    request: ChangeUnitTrustHoldingsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustHoldingsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustHoldings`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustSectorAllocations(
    request: ChangeUnitTrustSectorAllocationsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustSectorAllocationsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustSectorAllocations`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustCountryAllocations(
    request: ChangeUnitTrustCountryAllocationsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustCountryAllocationsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustCountryAllocations`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustAnnualPerformanceLog(
    request: ChangeUnitTrustAnnualPerformanceLogRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustAnnualPerformanceLogResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustAnnualPerformanceLog`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
  async ChangeUnitTrustSupportingDocuments(
    request: ChangeUnitTrustSupportingDocumentsRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ChangeUnitTrustSupportingDocumentsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustUpdater.serviceProvider}.ChangeUnitTrustSupportingDocuments`,
        request,
      },
      opts,
    );
    return { unitTrust: new UnitTrust(response.unitTrust) };
  },
};
