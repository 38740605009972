import { ViewConfiguration } from "james/configuration";
import { MeshApp } from "App";
import { atLeastOneTrueFoundInViewConfigObject } from "../../routes/private/administrationRoutes";

// getViewConfigurationApps determines the mesh applications that an view configuration needs to have access to
export const getViewConfigurationApps = (
  viewConfiguration: ViewConfiguration,
): string[] => {
  const meshApp: string[] = [];

  if (atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Consistency)) {
    meshApp.push(MeshApp.Consistency);
  }

  if (
    (viewConfiguration.Instruments &&
      viewConfiguration.Instruments.ViewModel) ||
    viewConfiguration.Subscriptions ||
    atLeastOneTrueFoundInViewConfigObject(
      viewConfiguration["Smart Instruments"],
    )
  ) {
    meshApp.push(MeshApp.InstrumentBuilder);
  }

  if (viewConfiguration.Underwriting) {
    meshApp.push(MeshApp.Underwriting);
  }

  if (atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Administration)) {
    meshApp.push(MeshApp.Administration);
  }

  if (
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Consistency) ||
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Fees) ||
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Compliance) ||
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Affiliates) ||
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Banking) ||
    atLeastOneTrueFoundInViewConfigObject(
      viewConfiguration["Direct Order Ops"],
    ) ||
    atLeastOneTrueFoundInViewConfigObject(viewConfiguration.Liquidity)
  ) {
    meshApp.push(MeshApp.MarketManagement);
  }

  if (viewConfiguration.Marketplace) {
    meshApp.push(MeshApp.Marketplace);
  }

  return meshApp;
};
