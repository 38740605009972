import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { InstrumentAnnualPerformanceLogEntry } from "james/financial";
import range from "lodash/range";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { AssetPerformanceDataSheetSection } from "james/marketData";

export interface Props {
  disabled: boolean;
  viewMode: boolean;
  dataSheetSection: AssetPerformanceDataSheetSection;
}

dayjs.extend(localeData);
const shortMonths = dayjs().localeData().monthsShort();

export function AssetPerformanceDataSheetDataSheetSectionRenderer(
  props: Props,
) {
  // sort performance log in ascending order
  const sortedPerformanceLog = props.dataSheetSection.performanceLog.sort(
    (a, b) => a.year - b.year,
  );

  // prepare a year range
  const years = range(sortedPerformanceLog[0].year, dayjs().year() + 1);

  // initialise incoming performance log
  const annualPerformanceLog = useMemo(() => {
    // prepare a new performance log
    let newAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];
    let yearHasPLE = false;

    // process given performance log
    years.forEach((year) => {
      // for every required year
      // look for an associated performance log entry
      const ple = props.dataSheetSection.performanceLog.find(
        (p) => p.year === year,
      );

      // check if there is an entry on the newAnnualPerformanceLog
      if (newAnnualPerformanceLog.length < 1) {
        for (let i = 1; i < 13; i++) {
          // Check if the month has a ple per year
          if (!ple?.monthlyPerformance[i]) {
            // if that month has no ple, set the year has ple variable to false
            // for that month and check the next month
            yearHasPLE = false;
          } else {
            // if that month has ple, set the year has ple variable to true
            // and break out of the loop
            yearHasPLE = true;
            break;
          }
        }
      } else {
        // if there is an entry then add every year's performance after the first
        // entry on newAnnualPerformanceLog
        yearHasPLE = true;
      }

      if (ple && yearHasPLE) {
        // if found then add in
        newAnnualPerformanceLog.push(ple);
      }
    });

    // sort in descending order
    newAnnualPerformanceLog = newAnnualPerformanceLog.sort((x, y) => {
      if (x.year > y.year) {
        return -1;
      }
      if (x.year < y.year) {
        return 1;
      }
      return 0;
    });

    return newAnnualPerformanceLog;
  }, [years, props.dataSheetSection.performanceLog]);

  return (
    <Box
      sx={(theme) => ({
        overflowX: "auto",
        overflowY: "hidden",
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: theme.spacing(1),
        margin: theme.spacing(3, 3, 2, 0),
        paddingBottom: theme.spacing(1),
      })}
      className={"meshScroll"}
    >
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: {
            xs: "60px repeat(13, 45px)",
            sm: "60px repeat(13, calc((100% - 81px) / 13))",
          },
          alignItems: "center",
          rowGap: theme.spacing(1),
          columnGap: theme.spacing(0.2),
        })}
      >
        {/* Header */}
        <div />
        {range(0, 12).map((monthIdx) => (
          <Typography
            key={monthIdx}
            variant="body2"
            sx={{
              justifySelf: "center",
            }}
            children={shortMonths[monthIdx]}
          />
        ))}
        <Typography
          variant="body2"
          sx={{
            justifySelf: "center",
          }}
          children="Annual"
        />
        <Box
          sx={(theme) => ({
            gridColumn: "1/15",
            borderBottom: `solid 1px ${theme.palette.divider}`,
          })}
        />
      </Box>

      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: {
            xs: "60px repeat(13, 45px)",
            sm: "60px repeat(13, calc((100% - 81px) / 13))",
          },
          alignItems: "center",
          rowGap: theme.spacing(1),
          columnGap: theme.spacing(0.2),
          maxHeight: 565,
          overflowX: "hidden",
          overflowY: "auto",
        })}
        className={"meshScroll"}
      >
        {/* For every performance log entry add a row ... */}
        {annualPerformanceLog.map((pl, idx) => (
          <React.Fragment key={idx}>
            <Typography
              color="textSecondary"
              variant="body2"
              id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${idx}`}
              sx={{
                justifySelf: "center",
              }}
              children={+pl.year ? pl.year : "-"}
            />
            {range(0, 12).map((monthIdx) => (
              <Typography
                key={monthIdx}
                variant="body2"
                color="textSecondary"
                id={`viewEditInstrumentAnnualPerformanceLog-year-textField-${monthIdx}-${idx}`}
                sx={{
                  justifySelf: "center",
                }}
                children={
                  +pl.monthlyPerformance[monthIdx + 1]
                    ? `${pl.monthlyPerformance[monthIdx + 1]}%`
                    : "-"
                }
              />
            ))}
            <Typography
              variant="body2"
              color="textSecondary"
              id={`viewEditInstrumentAnnualPerformanceLog-annualReturns-textField-${idx}`}
              sx={{
                justifySelf: "center",
              }}
              children={
                +pl.annualPerformance ? `${pl.annualPerformance}%` : "-"
              }
            />
            {idx === annualPerformanceLog.length - 1 ? (
              <Box
                sx={(theme) => ({
                  gridColumn: "1/15",
                  borderBottom: `solid 1px ${theme.palette.divider}`,
                })}
              />
            ) : (
              <Box
                sx={(theme) => ({
                  gridColumn: "1/15",
                  borderBottom: `solid 1px ${theme.palette.divider}`,
                })}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}
