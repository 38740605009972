import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControlLabel,
  Link,
  Typography,
} from "@mui/material";
import { SubscribeButton } from "components/Buttons/SubscriptionButton/SubscriptionConfirmationButton";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";

export interface ConfirmationDialogProps {
  dialogProps: DialogProps;
  open: boolean;
  smDown: boolean;
  subscriptionTicketHeader: ReactNode;
  subscribe: () => void;
  unit: string;
  subscriptionAmount: AmountType;
  investmentAmount: AmountType;
  numberOfUnits: AmountType;
  assetType: string;
  closeDialog: () => void;
  submittingOrder: boolean;
  height: string;
}

export const ConfirmationDialog = ({
  dialogProps,
  smDown,
  subscriptionTicketHeader,
  subscribe,
  unit,
  investmentAmount,
  subscriptionAmount,
  numberOfUnits,
  assetType,
  open,
  closeDialog,
  submittingOrder,
  height,
}: ConfirmationDialogProps) => {
  const [termsSelected, setTermsSelected] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop ===
          container.clientHeight;
        setAtBottom(isAtBottom);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [termsSelected]);

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        ...dialogProps.PaperProps,
        sx: {
          width: { sm: 450 },
          minHeight: { sm: height, xs: "auto" },
          display: "flex",
          flexDirection: "column",
        },
      }}
      fullScreen={smDown}
      open={open}
    >
      {subscriptionTicketHeader}
      <DialogContent
        className="meshScroll"
        sx={{ flexGrow: 1, overflowY: "auto", px: { sm: 6, xs: 3 } }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.cardInner,
            my: 3,
          })}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          >
            Confirm Order
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              children={`Number of ${assetType}s`}
            />
            <Typography sx={{ mt: 0.5, fontSize: "16px", fontWeight: "bold" }}>
              {numberOfUnits.toString().split(" ")[1]}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              children={`Price per ${unit}`}
            />
            <Amount
              valueTypographyProps={{
                sx: {
                  fontWeight: "bold",
                  mt: 0.5,
                  fontSize: "16px",
                },
                noWrap: true,
              }}
              codeTypographyProps={{
                sx: {
                  mt: 0.5,
                  fontSize: "16px",
                },
              }}
              amount={subscriptionAmount}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              Investment Amount
            </Typography>
            <Amount
              codeTypographyProps={{
                variant: "body1",
                sx: (theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.custom.yellow,
                  mt: 0.5,
                  fontSize: "16px",
                }),
              }}
              valueTypographyProps={{
                variant: "body1",
                sx: (theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                  mt: 0.5,
                  fontSize: "16px",
                }),
              }}
              amount={investmentAmount}
            />
          </Box>
        </Box>
      </DialogContent>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          px: { sm: 6, xs: 3 },
          pt: 3,
          display: "flex",
          flexDirection: "column",
          pb: { sm: 0, xs: 3 },
        })}
      >
        {termsSelected && (
          <Box>
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              By signing this Application Request, I HEREBY DECLARE THAT
            </Typography>
            <Box
              ref={scrollContainerRef}
              sx={(theme) => ({
                mt: 2,
                mb: 3,
                px: 1,
                maxHeight: 80,
                "@media (min-height: 790px)": {
                  maxHeight: 180,
                },
                overflowY: "auto", // Ensure only this element scrolls
                display: "grid",
                gridTemplateColumns: "8px auto",
                color: theme.palette.text.secondary,
                gap: 1,
                transition: "mask-image 0.5s ease-in-out",
                maskImage: atBottom
                  ? "none"
                  : "linear-gradient(to bottom, black 60%, transparent 100%)",
              })}
              className="meshScroll"
            >
              {TermsList().map((item, idx) => (
                <React.Fragment key={idx}>
                  <Typography
                    sx={(theme) => ({
                      maskImage: "inherit",
                      color: theme.palette.text.secondary,
                      pr: 2,
                    })}
                  >
                    {String.fromCharCode(65 + idx)}
                  </Typography>
                  {React.cloneElement(item)}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        )}
        <FormControlLabel
          control={<Checkbox id="termsAndConditionsCheckBox" />}
          value={termsAgreed}
          onChange={() => setTermsAgreed(!termsAgreed)}
          sx={(theme) => ({
            height: "auto",
            backgroundColor: theme.palette.custom.midnight,
          })}
          label={
            <Box>
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  mr: 1,
                  fontSize: "12px",
                  lineHeight: "18px",
                })}
              >
                I confirm that I have read, understood and agree to the{" "}
                <Link
                  underline="hover"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setTermsSelected(!termsSelected);
                  }}
                >
                  Terms & Conditions
                </Link>{" "}
                of this trade.
              </Typography>
            </Box>
          }
        />
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
        })}
      >
        {!smDown && <Divider sx={{ mt: 4 }}></Divider>}
      </Box>
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          px: { sm: 6, xs: 3 },
          pb: 4,
          pt: 3,
          boxShadow: {
            xs: "0px 0px 18px 4px rgba(0,0,0,0.4);",
            sm: 0,
          },
          gap: 2,
          zIndex: 1,
          minHeight: "89px",
        }}
      >
        <Button
          sx={{
            width: { sm: "168px", xs: "50%" },
            border: 1,
          }}
          size={smDown ? "large" : "medium"}
          variant="outlined"
          onClick={closeDialog}
          disabled={submittingOrder}
        >
          Back
        </Button>
        <SubscribeButton
          onClick={subscribe}
          buttonDisabled={submittingOrder || !termsAgreed}
          width={smDown ? "50%" : "168px"}
          title={(() => {
            if (!termsAgreed) {
              return "You need to agree to terms and conditions";
            }
            return "";
          })()}
          smDown={smDown}
          fullWidth={false}
        />
      </DialogTitle>
    </Dialog>
  );
};

const TermsList = () => [
  <Typography>
    I have received the Prospectus and have read the terms and conditions of the
    Offer contained in the Prospectus and agree to be bound by them;
  </Typography>,
  <Typography>
    I will become the beneficial owner of the A Preference Shares of the Company
    issued to me under the Offer;
  </Typography>,
  <Typography>
    I have read and understood the risk factors set out in the Prospectus;
  </Typography>,
  <Typography>
    To the best of my knowledge and belief, the personal details and information
    I have given are correct;
  </Typography>,
  <Typography>
    I am aware of the fact that a full analysis could not be undertaken and that
    there may thus be limitations on the appropriateness of the advice or
    information I received in relation to the Offer;
  </Typography>,
  <Typography>
    I have carefully considered whether the advice or information on its own
    that I have received is appropriate considering my financial situation,
    needs and objectives; and
  </Typography>,
  <Typography>
    To prevent the risk of concluding a transaction that is not appropriate to
    my financial situation, objectives and needs, I have declined the offer of a
    needs analysis and has decided to continue with the Application in this
    Application Request.
  </Typography>,
];
