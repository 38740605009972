import { StellarNetwork } from "james/stellar";
import { TextExactCriterion } from "james/search/criterion";
import { Criterion } from "james/search/criterion/Criterion";
import { BitcoinNetwork } from "james/bitcoin";
import { Amount } from "./Amount";
import {
  LedgerNetwork,
  networkFromFutureNetwork,
  networkToFutureNetwork,
} from "./Network";
import BigNumber from "bignumber.js";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";

export type NewTokenProps = {
  code: string;
  issuer: string;
  network: LedgerNetwork | "-";
};

export class Token {
  public code = "-";

  public issuer = "-";

  public network: LedgerNetwork = "-";

  static fromFutureToken(futureToken?: FutureToken): Token {
    if (!futureToken) {
      return new Token();
    }
    return new Token({
      code: futureToken.getCode(),
      issuer: futureToken.getIssuer(),
      network: networkFromFutureNetwork(futureToken.getNetwork()),
    });
  }

  toFutureToken(): FutureToken {
    return new FutureToken()
      .setCode(this.code)
      .setIssuer(this.issuer)
      .setNetwork(networkToFutureNetwork(this.network));
  }

  constructor(token?: NewTokenProps) {
    if (!token) {
      return;
    }
    this.code = token.code;
    this.issuer = token.issuer;
    this.network = token.network;
  }

  newAmountOf(value: string | BigNumber): Amount {
    value = new BigNumber(value);
    switch (this.network) {
      case StellarNetwork.TestSDFNetwork:
      case StellarNetwork.PublicNetwork:
        return new Amount({
          value: value.decimalPlaces(7, BigNumber.ROUND_DOWN),
          token: new Token(this),
        });

      case BitcoinNetwork.TestNetwork:
      case BitcoinNetwork.PublicNetwork:
        return new Amount({
          value: value.decimalPlaces(8, BigNumber.ROUND_DOWN),
          token: new Token(this),
        });

      default:
        return new Amount({
          value,
          token: new Token(this),
        });
    }
  }

  isUndefined(): boolean {
    return this.code === "-" && this.issuer === "-" && this.network === "-";
  }

  isEqualTo(t2: Token): boolean {
    return (
      this.code === t2.code &&
      this.issuer === t2.issuer &&
      this.network === t2.network
    );
  }

  toFilter(): { [key: string]: Criterion } {
    return {
      "token.code": TextExactCriterion(this.code),
      "token.issuer": TextExactCriterion(this.issuer),
      "token.network": TextExactCriterion(this.network),
    };
  }

  string(): string {
    return `${this.code} by ${this.issuer} on ${this.network}`;
  }
}
