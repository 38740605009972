import React from "react";
import { Router } from "routes";
import {
  ViewSubscriptionOrderBook,
  SubscriptionOrderBookTable,
} from "components/MarketSubscriptions";
import { Box } from "@mui/material";

export function MarketSubscriptions() {
  return (
    <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Router
        baseURL={"/builder/subscriptions"}
        redirectPath={"/builder/subscriptions/table"}
        routes={[
          {
            name: "Table",
            id: "table",
            path: "/builder/subscriptions/table",
            component: (
              <SubscriptionOrderBookTable
                system={false}
                height={window.innerHeight - 85}
                hideColumns={["Res. Count"]}
              />
            ),
          },
          {
            name: "View",
            id: "view",
            path: "/builder/subscriptions/view",
            component: (
              <ViewSubscriptionOrderBook
                system={false}
                height={window.innerHeight - 85}
              />
            ),
          },
        ]}
      />
    </Box>
  );
}
