import config from "react-global-configuration";
import { InstrumentMarketingContent } from "./InstrumentMarketingContent";
import { SocialMediaLinks } from "./SocialMediaLinks";
import { IndependentReview } from "./IndependentReviews";
import { MarketingMedia } from "./MarketingMedia";
import { NewsArticle } from "./NewsArticle";
import { Context } from "james/security";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import BigNumber from "bignumber.js";

const serviceProvider = "marketing-InstrumentMarketingContentUpdater";

export interface UpdateSocialMediaLinksRequest {
  context: Context;
  ID: string;
  SocialMediaLinks: SocialMediaLinks;
}
export interface UpdateSocialMediaLinksResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export interface UpdateIndependentReviewRequest {
  context: Context;
  ID: string;
  IndependentReviews: IndependentReview[];
}
export interface UpdateIndependentReviewResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export interface UpdateInvestmentObjectiveRequest {
  context: Context;
  ID: string;
  InvestmentObjective: string;
}
export interface UpdateInvestmentObjectiveResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export interface UpdateEstimatedAnnualReturnRequest {
  context: Context;
  ID: string;
  EstimatedAnnualReturn: BigNumber;
}
export interface UpdateEstimatedAnnualReturnResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export interface UpdateMarketingMediaRequest {
  context: Context;
  ID: string;
  MarketingMedia: MarketingMedia[];
}
export interface UpdateMarketingMediaResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export interface UpdateNewsArticlesRequest {
  context: Context;
  ID: string;
  NewsArticles: NewsArticle[];
}
export interface UpdateNewsArticlesResponse {
  instrumentmarketingcontent: InstrumentMarketingContent;
}

export const InstrumentMarketingContentUpdater = {
  serviceProvider: serviceProvider,
  async UpdateNewsArticles(
    request: UpdateNewsArticlesRequest,
  ): Promise<UpdateNewsArticlesResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateNewsArticles`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
  async UpdateIndependentReview(
    request: UpdateIndependentReviewRequest,
  ): Promise<UpdateIndependentReviewResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateIndependentReviews`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
  async UpdateMarketingMedia(
    request: UpdateMarketingMediaRequest,
  ): Promise<UpdateMarketingMediaResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateMarketingMedia`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
  async UpdateInvestmentObjective(
    request: UpdateInvestmentObjectiveRequest,
  ): Promise<UpdateInvestmentObjectiveResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateInvestmentObjective`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
  async UpdateEstimatedAnnualReturn(
    request: UpdateEstimatedAnnualReturnRequest,
  ): Promise<UpdateEstimatedAnnualReturnResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateEstimatedAnnualReturn`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
  async UpdateSocialMediaLinks(
    request: UpdateSocialMediaLinksRequest,
  ): Promise<UpdateSocialMediaLinksResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${InstrumentMarketingContentUpdater.serviceProvider}.UpdateSocialMediaLinks`,
      request,
    });
    return {
      instrumentmarketingcontent: new InstrumentMarketingContent(
        response.instrumentmarketingcontent,
      ),
    };
  },
};
