import { FinancialInstrumentState } from "james/financial/InstrumentState";
import { Amount, Token } from "james/ledger";
import { ListingState } from "james/market/Listing";

export enum InstrumentType {
  // Note: the mismatch below is intentional.
  // In the back we refer to InstrumentStablecoin, Instrument and Digital Instrument.
  // On the UI we have only 'Rights To Instrument' and 'Instrument'
  ETFStablecoin = "R. ETF",
  DigitalETF = "ETF",
  ETNStablecoin = "R. ETN",
  DigitalETN = "ETN",
  UnitTrustStablecoin = "R. UNT",
  Bond = "Bond",
}

export const AllInstrumentTypes: InstrumentType[] = [
  InstrumentType.ETFStablecoin,
  InstrumentType.DigitalETF,
  InstrumentType.ETNStablecoin,
  InstrumentType.DigitalETN,
  InstrumentType.UnitTrustStablecoin,
  InstrumentType.Bond,
];

export const ModelTypeName = "mesh::financialInstrumentView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public id = "";

  public ownerID = "";

  public instrumentID = "";

  public name = "";

  public shortName = "";

  public instrumentType: InstrumentType | "" = "";

  public assetClass = "";

  public instrumentIssuanceDate = "";

  public instrumentMaturityDate = "";

  public unitsIssued: Amount = new Amount();

  public maximumUnits: Amount = new Amount();

  public valuationToken: Token = new Token();

  public instrumentState: FinancialInstrumentState | "" = "";

  public readyForPlacement = false;

  public listingState: ListingState | "" = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.ownerID = model.ownerID;
    this.instrumentID = model.instrumentID;
    this.name = model.name;
    this.shortName = model.shortName;
    this.instrumentType = model.instrumentType;
    this.assetClass = model.assetClass;
    this.instrumentIssuanceDate = model.instrumentIssuanceDate;
    this.instrumentMaturityDate = model.instrumentMaturityDate;
    this.unitsIssued = new Amount(model.unitsIssued);
    this.maximumUnits = new Amount(model.maximumUnits);
    this.valuationToken = new Token(model.valuationToken);
    this.instrumentState = model.instrumentState;
    this.listingState = model.listingState;
    this.readyForPlacement = model.readyForPlacement;
  }
}
