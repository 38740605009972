/**
 * @fileoverview gRPC-Web generated client stub for banking
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: banking/fundingOrderStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')

var banking_fundingOrder_pb = require('../banking/fundingOrder_pb.js')
const proto = {};
proto.banking = require('./fundingOrderStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.FundingOrderStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.banking.FundingOrderStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SettleFundingOrderRequest,
 *   !proto.banking.SettleFundingOrderResponse>}
 */
const methodDescriptor_FundingOrderStateController_SettleFundingOrder = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/SettleFundingOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.SettleFundingOrderRequest,
  proto.banking.SettleFundingOrderResponse,
  /**
   * @param {!proto.banking.SettleFundingOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SettleFundingOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SettleFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SettleFundingOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SettleFundingOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.settleFundingOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/SettleFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_SettleFundingOrder,
      callback);
};


/**
 * @param {!proto.banking.SettleFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SettleFundingOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.settleFundingOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/SettleFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_SettleFundingOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.CancelFundingOrderRequest,
 *   !proto.banking.CancelFundingOrderResponse>}
 */
const methodDescriptor_FundingOrderStateController_CancelFundingOrder = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/CancelFundingOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.CancelFundingOrderRequest,
  proto.banking.CancelFundingOrderResponse,
  /**
   * @param {!proto.banking.CancelFundingOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.CancelFundingOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.CancelFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.CancelFundingOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.CancelFundingOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.cancelFundingOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/CancelFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_CancelFundingOrder,
      callback);
};


/**
 * @param {!proto.banking.CancelFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.CancelFundingOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.cancelFundingOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/CancelFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_CancelFundingOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.SubmitFundingOrderRequest,
 *   !proto.banking.SubmitFundingOrderResponse>}
 */
const methodDescriptor_FundingOrderStateController_SubmitFundingOrder = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/SubmitFundingOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.SubmitFundingOrderRequest,
  proto.banking.SubmitFundingOrderResponse,
  /**
   * @param {!proto.banking.SubmitFundingOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.SubmitFundingOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.SubmitFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.SubmitFundingOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.SubmitFundingOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.submitFundingOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/SubmitFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_SubmitFundingOrder,
      callback);
};


/**
 * @param {!proto.banking.SubmitFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.SubmitFundingOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.submitFundingOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/SubmitFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_SubmitFundingOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.ResolveFundingOrderRequest,
 *   !proto.banking.ResolveFundingOrderResponse>}
 */
const methodDescriptor_FundingOrderStateController_ResolveFundingOrder = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/ResolveFundingOrder',
  grpc.web.MethodType.UNARY,
  proto.banking.ResolveFundingOrderRequest,
  proto.banking.ResolveFundingOrderResponse,
  /**
   * @param {!proto.banking.ResolveFundingOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.ResolveFundingOrderResponse.deserializeBinary
);


/**
 * @param {!proto.banking.ResolveFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.ResolveFundingOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.ResolveFundingOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.resolveFundingOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/ResolveFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_ResolveFundingOrder,
      callback);
};


/**
 * @param {!proto.banking.ResolveFundingOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.ResolveFundingOrderResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.resolveFundingOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/ResolveFundingOrder',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_ResolveFundingOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkFundingOrderSettledRequest,
 *   !proto.banking.MarkFundingOrderSettledResponse>}
 */
const methodDescriptor_FundingOrderStateController_MarkFundingOrderSettled = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/MarkFundingOrderSettled',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkFundingOrderSettledRequest,
  proto.banking.MarkFundingOrderSettledResponse,
  /**
   * @param {!proto.banking.MarkFundingOrderSettledRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkFundingOrderSettledResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkFundingOrderSettledRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkFundingOrderSettledResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkFundingOrderSettledResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.markFundingOrderSettled =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderSettled',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderSettled,
      callback);
};


/**
 * @param {!proto.banking.MarkFundingOrderSettledRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkFundingOrderSettledResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.markFundingOrderSettled =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderSettled',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderSettled);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkFundingOrderFailedRequest,
 *   !proto.banking.MarkFundingOrderFailedResponse>}
 */
const methodDescriptor_FundingOrderStateController_MarkFundingOrderFailed = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/MarkFundingOrderFailed',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkFundingOrderFailedRequest,
  proto.banking.MarkFundingOrderFailedResponse,
  /**
   * @param {!proto.banking.MarkFundingOrderFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkFundingOrderFailedResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkFundingOrderFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkFundingOrderFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkFundingOrderFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.markFundingOrderFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderFailed',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderFailed,
      callback);
};


/**
 * @param {!proto.banking.MarkFundingOrderFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkFundingOrderFailedResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.markFundingOrderFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderFailed',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.banking.MarkFundingOrderUnderInvestigationRequest,
 *   !proto.banking.MarkFundingOrderUnderInvestigationResponse>}
 */
const methodDescriptor_FundingOrderStateController_MarkFundingOrderUnderInvestigation = new grpc.web.MethodDescriptor(
  '/banking.FundingOrderStateController/MarkFundingOrderUnderInvestigation',
  grpc.web.MethodType.UNARY,
  proto.banking.MarkFundingOrderUnderInvestigationRequest,
  proto.banking.MarkFundingOrderUnderInvestigationResponse,
  /**
   * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.banking.MarkFundingOrderUnderInvestigationResponse.deserializeBinary
);


/**
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.banking.MarkFundingOrderUnderInvestigationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.banking.MarkFundingOrderUnderInvestigationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.banking.FundingOrderStateControllerClient.prototype.markFundingOrderUnderInvestigation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderUnderInvestigation,
      callback);
};


/**
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.banking.MarkFundingOrderUnderInvestigationResponse>}
 *     Promise that resolves to the response
 */
proto.banking.FundingOrderStateControllerPromiseClient.prototype.markFundingOrderUnderInvestigation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/banking.FundingOrderStateController/MarkFundingOrderUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_FundingOrderStateController_MarkFundingOrderUnderInvestigation);
};


module.exports = proto.banking;

