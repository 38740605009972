import React from "react";
import {
  Box,
  Card,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HowDoesItWorkSectionProps } from "./HowDoesItWork";
import { ContentType } from "./HowDoesItWorkContentProvider";
import underlying from "assets/images/howDoesItWork/underlying.png";
import document from "assets/images/howDoesItWork/document.png";
import stablecoin from "assets/images/howDoesItWork/stablecoin.png";
import price from "assets/images/howDoesItWork/price.png";
import step1 from "assets/images/firstLogin/step1.png";
import basket from "assets/images/howDoesItWork/basket.png";
import btcStablecoin from "assets/images/howDoesItWork/btcStablecoin.png";
import lumenStablecoin from "assets/images/howDoesItWork/lumenStablecoin.png";
import percentage from "assets/images/howDoesItWork/percentage.png";
import wallet from "assets/images/howDoesItWork/wallet.png";
import meshX1 from "assets/images/howDoesItWork/Mesh-Icon-X1.png";
import mzarCoin from "assets/images/howDoesItWork/mzarCoin.png";
import { AssetType } from "../../../../../james/views/marketListingView/Model";
import cx from "classnames";

type StructureSectionType = {
  packaged: ContentType[];
  additionalInfo: ContentType[];
  description: ContentType[];
};

const images: Record<string, string> = {
  "": step1,
  underlying: underlying,
  document: document,
  price: price,
  stablecoin: stablecoin,
  basket: basket,
  btcStablecoin: btcStablecoin,
  lumenStablecoin: lumenStablecoin,
  percentage: percentage,
  wallet: wallet,
  meshX1: meshX1,
  mzarCoin: mzarCoin,
};

export const StructureSection = ({
  section,
  marketListingViewModel,
}: HowDoesItWorkSectionProps) => {
  const content = section.content as StructureSectionType;
  const isBond = marketListingViewModel.assetType === AssetType.Bond;
  const isPrefshare =
    marketListingViewModel.assetType === AssetType.PreferenceShare;
  const theme = useTheme();
  return (
    <Box>
      {content.description && content.description.length > 0 && (
        <Box
          sx={{
            mx: {
              sm: 5,
              xs: 3,
            },
            mt: {
              sm: 5,
              xs: 3,
            },
            maxWidth: "498px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              mb: 1,
            }}
            variant="h5"
          >
            {content.description[0].title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: theme.palette.text.secondary,
            }}
          >
            {content.description[0].info}
          </Typography>
        </Box>
      )}
      <Box
        className={cx({
          bond: isBond,
        })}
        sx={{
          pt: {
            sm: 5,
            xs: 3,
          },
          px: {
            sm: 5,
            xs: 3,
          },
          pb: 4,
          mt: 2,
          display: "flex",
          flexDirection: {
            sm: "row",
            xs: "column",
          },
          gap: 2,
          "&.bond": {
            background: theme.palette.background.paper,
          },
        }}
      >
        {content.packaged.map((v, i, array) => {
          return (
            <Box
              key={i}
              sx={{
                flex: 1,
                pr: {
                  sm: array.length - 1 !== i ? 3 : 0,
                },
                pb: {
                  sm: 0,
                  xs: array.length - 1 !== i ? 2 : 0,
                },
              }}
            >
              <StructureCard
                title={v.title}
                info={v.info}
                image={v.imgURL ?? ""}
                end={array.length - 1 === i}
                theme={theme}
                items={content.packaged.length}
                prefShare={isPrefshare}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ position: "relative" }}>
        <Box
          className={cx({
            "not-bond": !isBond,
            bond: isBond,
            isPrefshare: isPrefshare,
          })}
          sx={(theme) => ({
            backgroundColor: theme.palette.custom.grape,
            px: {
              sm: 4,
              xs: 3,
            },
            pb: 3,
            pt: 5,
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            "&.bond": {
              background: "#433F69",
            },
            "&.isPrefshare": {
              p: 5,
            },
          })}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Additional Info:
          </Typography>
          {content.additionalInfo.map((v, i) => {
            return (
              <AdditionalInfoSection
                title={v.title}
                info={v.info}
                image={v.imgURL ?? ""}
                key={i}
                reverse={i % 2 !== 0}
                theme={theme}
                prefShare={isPrefshare}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

interface ContentSection {
  title: string;
  image: string;
  info?: string[];
  theme: Theme;
  prefShare: boolean;
}

const StructureCard = ({
  title,
  info,
  end,
  image,
  theme,
  items,
}: ContentSection & { end: boolean; items: number }) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        mt: {
          sm: 0,
          xs: 2,
        },
      }}
    >
      {/* Funky Border arrow handle thing */}
      {!end && (
        <Box
          sx={{
            position: "absolute",
            [theme.breakpoints.up("sm")]: {
              top: "16px",
              bottom: "20px",
              right: "-4px",
              width: "0px",
              borderTopRightRadius: "4px",
              mt: 3,
            },
            [theme.breakpoints.down("sm")]: {
              right: "20px",
              bottom: "-4px",
              left: "20px",
              borderBottomLeftRadius: "4px",
              mt: 3,
            },
            borderBottomRightRadius: "4px",
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              [theme.breakpoints.up("sm")]: {
                left: "-11px",
                top: "calc(50% - 11px)",
                transform: "rotate(45deg)",
              },
              [theme.breakpoints.down("sm")]: {
                top: "-11px",
                left: "calc(50% - 11px)",
                transform: "rotate(135deg)",
              },
              width: "24px",
              height: "24px",
              backgroundColor: theme.palette.custom.grapeLight,
              borderTopRightRadius: "4px",
              borderTop: `4px solid ${theme.palette.secondary.main}`,
              borderRight: `4px solid ${theme.palette.secondary.main}`,
            }}
          />
        </Box>
      )}
      {end && smDown && (
        <Box
          sx={{
            position: "absolute",
            [theme.breakpoints.up("sm")]: {
              top: "16px",
              bottom: "20px",
              right: "-4px",
              width: "0px",
              borderTopRightRadius: "4px",
              mt: 3,
            },
            [theme.breakpoints.down("sm")]: {
              right: "20px",
              bottom: "-4px",
              left: "20px",
              borderBottomLeftRadius: "4px",
              mt: 3,
            },
            borderBottomRightRadius: "4px",
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        ></Box>
      )}
      <Card
        className={cx({
          singular: items == 1,
        })}
        sx={{
          backgroundColor: theme.palette.custom.grapeLight,
          pt: {
            sm: 10,
            xs: 3,
          },
          px: 3,
          pb: 5,
          "&.singular": { pb: 1 },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: {
            sm: info ? "flex-start" : "center",
            xs: "flex-start",
          },
        }}
      >
        <Box
          component={"img"}
          src={images[image] ?? stablecoin}
          sx={{
            width: {
              xs: "104px",
              sm: "120px",
            },
            height: {
              xs: "104px",
              sm: "120px",
            },
            position: "absolute",
            top: {
              xs: "-40px",
              sm: "-44px",
            },
            right: {
              sm: "calc(50% - 60px)",
              xs: "12px",
            },
          }}
        />
        <Box
          className={cx({
            singular: items === 1,
          })}
          sx={{
            "&.singular": {
              display: "flex",
              justifyContent: { sm: "center", xs: "flex-start" },
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              maxWidth: {
                sm: "100%",
                xs: "calc(100% - 80px)",
              },
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
        </Box>
        {info?.length !== 0 &&
          info?.map((v, i) => {
            return (
              <Typography
                className={cx({
                  singular: items == 1,
                })}
                sx={{
                  mt: 2,
                  color: theme.palette.text.secondary,
                  "&.singular": {
                    px: { sm: 4 },
                    mb: 2,
                  },
                }}
                key={i}
              >
                {v}
              </Typography>
            );
          })}
      </Card>
    </Box>
  );
};

const AdditionalInfoSection = ({
  title,
  info,
  reverse,
  theme,
  image,
  prefShare,
}: ContentSection & { reverse?: boolean }) => {
  return (
    <Box>
      <Divider
        sx={{
          pt: 1,
          borderBottomWidth: 2,
          color: theme.palette.text.tertiary,
        }}
      />
      <Box
        className={cx({
          isPrefshare: prefShare,
        })}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: {
            sm: "flex-start",
            xs: "center",
          },
          mt: 4,
          px: { sm: 5 },
          flexDirection: {
            sm: reverse ? "row-reverse" : "row",
            xs: "column",
          },
          "&.isPrefshare": {
            px: { sm: 0 },
          },
        }}
      >
        {/* image Icon */}
        <Box
          component={"img"}
          sx={{
            width: "120px",
            height: "120px",
            mx: 2,
            mb: 2,
          }}
          src={images[image] ?? stablecoin}
        />
        <Box
          className={cx({
            isPrefshare: prefShare,
          })}
          sx={{
            maxWidth: "415px",
            "&.isPrefshare": {
              maxWidth: "100%",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              mb: 2,
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
          {info?.map((v, i) => {
            return (
              <Typography
                key={i}
                sx={{
                  mb: 3,
                  color: theme.palette.text.secondary,
                }}
              >
                {v}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
