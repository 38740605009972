import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ValidationResult } from "common/validation";
import dayjs from "dayjs";
import { Listing, Mechanism } from "james/market";
import { ListingState } from "james/market/Listing";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";

export type ListingFormData = {
  smartInstrumentToList: SmartInstrument;
  listing: Listing;
  subscriptionOrderBook: SubscriptionOrderBook;
};

export type FormUpdaterSpecsType = {
  smartInstrumentToList: (
    smartInstrumentToList: SmartInstrument,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  listing: (
    listing: Listing,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  subscriptionOrderBook: (
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: ListingFormData,
  ) => ListingFormData;
  mechanisms: (
    mechanisms: Mechanism[],
    prevFormData?: ListingFormData,
  ) => ListingFormData;
};

export const formDataUpdaterSpecs: FormUpdaterSpecsType = {
  smartInstrumentToList(
    smartInstrumentToList: SmartInstrument,
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      smartInstrumentToList,
    };
  },
  listing(listing: Listing, prevFormData?: ListingFormData): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      listing,
    };
  },
  subscriptionOrderBook(
    subscriptionOrderBook: SubscriptionOrderBook,
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    return {
      ...formData,
      subscriptionOrderBook,
    };
  },
  mechanisms(
    mechanisms: Mechanism[],
    prevFormData?: ListingFormData,
  ): ListingFormData {
    const formData = prevFormData as ListingFormData;
    formData.listing.marketMechanisms = mechanisms;
    return {
      ...formData,
      listing: formData.listing,
    };
  },
};

export const formDataValidationFunc = async (
  formData: ListingFormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  if (formData.subscriptionOrderBook.overSubscriptionAmount.value.isZero()) {
    validationResult.valid = false;
  }

  const allowFractions =
    formData.subscriptionOrderBook.fractionalisationAllowed;

  const maxDealSize =
    formData.listing.marketMechanisms[0].quoteParameters[0].maximumDealSize
      .value;
  const minDealSize =
    formData.listing.marketMechanisms[0].quoteParameters[0].minimumDealSize
      .value;
  const unitPrice = formData.subscriptionOrderBook.unitPrice.value;
  const subscriptionAmount =
    formData.subscriptionOrderBook.subscriptionAmount.value;
  const overSubscriptionAmount =
    formData.subscriptionOrderBook.overSubscriptionAmount.value;

  if (maxDealSize.isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maximumDealSize =
      "Maximum deal size must be greater than 0";
  }
  if (minDealSize.isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.minimumDealSize =
      "Minimum deal size must be greater than 0";
  }
  if (maxDealSize.isLessThan(minDealSize)) {
    validationResult.valid = false;
    validationResult.fieldValidations.maximumDealSize =
      "Maximum deal size must be greater than min deal size";

    validationResult.fieldValidations.minimumDealSize =
      "Minimum deal size must be less than or equal to max deal size";
  }

  // Less than zero is disabled on the component level
  // Validations are in place for the sake of completenes
  if (unitPrice.isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations["subscriptionOrderBook-unitPrice"] =
      "Unit price must be greater than 0";
  }

  if (subscriptionAmount.isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations[
      "subscriptionOrderBook-subscriptionAmount"
    ] = "Subscription amount must be greater than 0";
  }

  if (
    !allowFractions &&
    subscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations[
      "subscriptionOrderBook-subscriptionAmount"
    ] = "Subscription amount should be multiples of the unit price";
  }

  if (overSubscriptionAmount.isLessThanOrEqualTo(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations[
      "subscriptionOrderBook-overSubscriptionAmount"
    ] = "Over Subscription amount must be greater than 0";
  }

  if (
    !allowFractions &&
    overSubscriptionAmount.mod(unitPrice).abs().isGreaterThan(0)
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations[
      "subscriptionOrderBook-overSubscriptionAmount"
    ] = "Over Subscription amount should be multiples of the unit price";
  }

  if (overSubscriptionAmount.isLessThan(subscriptionAmount)) {
    validationResult.valid = false;
    validationResult.fieldValidations[
      "subscriptionOrderBook-overSubscriptionAmount"
    ] =
      "Over Subscription amount should be greater than or equal to subscription amount";

    validationResult.fieldValidations[
      "subscriptionOrderBook-subscriptionAmount"
    ] =
      "Subscription amount should be less than or equal to the over subscription amount";
  }

  // The date checks are handled by the component
  // A user can manually enter an invalid date
  // So these validations are needed
  const openDate = dayjs(formData.subscriptionOrderBook.openDate);
  const closeDate = dayjs(formData.subscriptionOrderBook.closeDate);
  const settlementDate = dayjs(formData.subscriptionOrderBook.settlementDate);

  if (openDate.startOf("day").isBefore(dayjs().endOf("day"))) {
    validationResult.valid = false;
    validationResult.fieldValidations["subscriptionOrderBook-openDate"] =
      "Open date should be after today";
  }
  if (openDate.endOf("day").isAfter(settlementDate.startOf("day"))) {
    validationResult.valid = false;
    validationResult.fieldValidations["subscriptionOrderBook-openDate"] =
      "Open date should be before settlement date";
  }

  if (
    closeDate.endOf("day").isAfter(settlementDate.add(1, "day").startOf("day"))
  ) {
    validationResult.valid = false;
    validationResult.fieldValidations["subscriptionOrderBook-closeDate"] =
      "Close date should be before settlement date";
  }

  if (closeDate.startOf("day").isBefore(openDate.endOf("day"))) {
    validationResult.valid = false;
    validationResult.fieldValidations["subscriptionOrderBook-closeDate"] =
      "Close date should be after open date";
    validationResult.fieldValidations["subscriptionOrderBook-openDate"] =
      "Open date should be before close date";
  }

  if (formData.listing.state === ListingState.Active) {
    validationResult.valid = true;
    validationResult.fieldValidations = {};
  }

  return validationResult;
};
