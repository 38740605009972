/**
 * @fileoverview gRPC-Web generated client stub for financialPaymentFundingView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: views/financialPaymentFundingView/modelReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')

var search_query_pb = require('../../search/query_pb.js')

var search_sorting_pb = require('../../search/sorting_pb.js')

var views_financialPaymentFundingView_model_pb = require('../../views/financialPaymentFundingView/model_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_amount_pb = require('../../ledger/amount_pb.js')
const proto = {};
proto.financialPaymentFundingView = require('./modelReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentFundingView.ModelReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentFundingView.ModelReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadOneModelRequest,
 *   !proto.financialPaymentFundingView.ReadOneModelResponse>}
 */
const methodDescriptor_ModelReader_ReadOneModel = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReader/ReadOneModel',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentFundingView.ReadOneModelRequest,
  proto.financialPaymentFundingView.ReadOneModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentFundingView.ReadOneModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadOneModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadOneModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderClient.prototype.readOneModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadOneModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderPromiseClient.prototype.readOneModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadManyModelRequest,
 *   !proto.financialPaymentFundingView.ReadManyModelResponse>}
 */
const methodDescriptor_ModelReader_ReadManyModel = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReader/ReadManyModel',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentFundingView.ReadManyModelRequest,
  proto.financialPaymentFundingView.ReadManyModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentFundingView.ReadManyModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadManyModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadManyModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderClient.prototype.readManyModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadManyModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderPromiseClient.prototype.readManyModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadAllModelRequest,
 *   !proto.financialPaymentFundingView.ReadAllModelResponse>}
 */
const methodDescriptor_ModelReader_ReadAllModel = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReader/ReadAllModel',
  grpc.web.MethodType.UNARY,
  proto.financialPaymentFundingView.ReadAllModelRequest,
  proto.financialPaymentFundingView.ReadAllModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financialPaymentFundingView.ReadAllModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadAllModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadAllModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderClient.prototype.readAllModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadAllModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadAllModel,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadAllModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderPromiseClient.prototype.readAllModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReader/ReadAllModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadAllModel);
};


module.exports = proto.financialPaymentFundingView;

