import { ReadManyPrimeRateRequest } from "@mesh/common-js/dist/financial/primeRateReader_meshproto_pb";
import { PrimeRate } from "@mesh/common-js/dist/financial/primeRate_pb";
import { Query as PastQuery } from "james/search/query";
import React, { useEffect, useState } from "react";
import { useAPIContext } from "../../../context/API";
import { Box, Typography, alpha } from "@mui/material";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import { useApplicationContext } from "context/Application/Application";
import { Column } from "components/Table/BPTable/BPTable";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import {
  DateRangeCriterion,
  RangeValue,
} from "@mesh/common-js/dist/search/dateRangeCriterion_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { LoadingButton } from "@mui/lab";
import { BPTable } from "components/Table";
import { enqueueSnackbar } from "notistack";
import { FaceOutlined as FaceIcon } from "@mui/icons-material";
import dayjs from "dayjs";

export type SearchPrimeRatesProps = {
  timezone: Timezone;
};

const defaultQuery = new Query().setLimit(15);
defaultQuery.addSorting(
  new Sorting().setField("date").setOrder(SortingOrder.ASC_SORTING_ORDER),
);

export const SearchPrimeRates = (props: SearchPrimeRatesProps) => {
  const { authContext } = useApplicationContext();
  const [primeRates, setPrimeRates] = useState<PrimeRate[]>([]);
  const [numPrimeRates, setNumPrimeRates] = useState<number>(5);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Timestamp>(
    Timestamp.fromDate(dayjs().subtract(1, "month").toDate()),
  );
  const [endDate, setEndDate] = useState<Timestamp>(
    Timestamp.fromDate(dayjs().toDate()),
  );
  const { financial } = useAPIContext();

  const searchRate = async () => {
    setLoading(true);
    try {
      // prepare request
      const request = new ReadManyPrimeRateRequest();
      const dateRangeCriterion = new DateRangeCriterion();
      dateRangeCriterion.setField("date");
      dateRangeCriterion.setStart(new RangeValue().setDate(startDate));
      dateRangeCriterion.setEnd(new RangeValue().setDate(endDate));
      request.addCriteria(
        new Criterion().setDaterangecriterion(dateRangeCriterion),
      );

      const response = await financial.primeRateReader.readManyPrimeRate(
        request.setContext(authContext.toFuture()).setQuery(defaultQuery),
      );
      setPrimeRates(response.getRecordsList());
      setNumPrimeRates(response.getTotal());
    } catch (e) {
      enqueueSnackbar({
        message: `Error Fetching Prime Rate ${e}`,
        variant: "error",
      });
      setPrimeRates([]);
    } finally {
      setLoading(false);
    }
  };

  // fetch some initial data for the table
  useEffect(() => {
    (async () => {
      await searchRate();
    })();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          py: 2,
          gap: 3,
          overflow: "hidden",
        }}
      >
        <DateTimeField
          disabled={loading}
          label="Start Date"
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue ?? Timestamp.fromDate(dayjs().toDate()));
          }}
        />
        <DateTimeField
          disabled={loading}
          label="End Date"
          value={endDate}
          onChange={(newValue) => {
            setEndDate(newValue ?? Timestamp.fromDate(dayjs().toDate()));
          }}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={searchRate}
          loading={loading}
        >
          Search Rates
        </LoadingButton>
      </Box>

      <BPTable
        title="Captured Prime Rates"
        loading={loading}
        data={primeRates}
        disableSelect
        query={PastQuery.fromFutureQuery(defaultQuery)}
        onQueryChange={() => {}}
        totalNoRecords={numPrimeRates}
        noDataSplashComponent={noDataSplashComponent()}
        columns={(() => {
          const columns: Column[] = [
            {
              label: "Date",
              field: "rate",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                return protobufTimestampToDayjs(
                  (data as PrimeRate).getDate() ?? new Timestamp(),
                )
                  .tz(timezoneToString(props.timezone))
                  .format("DD/MM/YYYY-HH:mm:ss");
              },
            },
            {
              label: "Rate",
              field: "rate",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                return (
                  decimalToBigNumber(
                    (data as PrimeRate).getRate() ?? new Decimal(),
                  ).toFormat() + "%"
                );
              },
            },
          ];
          return columns;
        })()}
      />
    </Box>
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={<span>No Prime Rates to Show</span>}
        />
      </Box>
    </Box>
  );
};
