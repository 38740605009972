import React from "react";

import { styled } from "@mui/material/styles";

import { Button, Icon, Link, Typography } from "@mui/material";

import { CreateOutlined, FingerprintOutlined } from "@mui/icons-material";

import { CompanyRegStep } from "views/KYB/CompanyRegistration";

import cx from "classnames";
import { useOpenLatestPaaSAgreement } from "james/compliance";
const PREFIX = "Landing";

const classes = {
  sectionWithRows2Gap: `${PREFIX}-sectionWithRows2Gap`,
  bodyContentWidth: `${PREFIX}-bodyContentWidth`,
  bodyContentTable: `${PREFIX}-bodyContentTable`,
  lavenderColor: `${PREFIX}-lavenderColor`,
  errorLightColor: `${PREFIX}-errorLightColor`,
  disabledColor: `${PREFIX}-disabledColor`,
  outlinedIcon: `${PREFIX}-outlinedIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.sectionWithRows2Gap}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  [`& .${classes.bodyContentWidth}`]: {
    width: 665,
  },

  [`& .${classes.bodyContentTable}`]: {
    padding: theme.spacing(3, 0),
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
  },

  [`& .${classes.lavenderColor}`]: {
    color: theme.palette.custom.lavender,
  },

  [`& .${classes.errorLightColor}`]: {
    color: theme.palette.error.light,
  },

  [`& .${classes.disabledColor}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.outlinedIcon}`]: {
    border: "1px solid",
    display: "flex",
    alignItems: "center",
    paddingLeft: 7,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
}));

interface LandingProps {
  onNext: (step: CompanyRegStep) => void;
}

export function Landing(props: LandingProps) {
  const { openLatestPaaSAgreement } = useOpenLatestPaaSAgreement();

  return (
    <Root
      id="company-registration-landing-page-root"
      className={classes.sectionWithRows2Gap}
    >
      <Typography color="secondary" variant="h2">
        Welcome to Mesh. Financial and Capital Markets, rebuilt, redefined and
        reimagined.
      </Typography>
      <Typography variant="subtitle1" className={classes.bodyContentWidth}>
        We’re so glad to have you onboard. Have a look at the instructions below
        and follow the prompts.
      </Typography>
      <div className={cx(classes.bodyContentTable, classes.bodyContentWidth)}>
        <Icon className={cx(classes.lavenderColor, classes.outlinedIcon)}>
          <FingerprintOutlined />
        </Icon>
        <Typography variant="body1" className={classes.disabledColor}>
          Ensure you have access to necessary company information (including
          personal details of shareholders, representatives, agents and the
          intended company administrator for your Mesh account).
        </Typography>

        <Icon className={cx(classes.errorLightColor, classes.outlinedIcon)}>
          <CreateOutlined />
        </Icon>
        <Typography variant="body1" className={classes.disabledColor}>
          Additionally, please acquaint yourself with the &nbsp;
          <Link
            id="openLatestPaaSAgreement-link-first"
            onClick={openLatestPaaSAgreement}
            children="Terms and Conditions"
            underline="none"
          />
          &nbsp; you will be required to agree with before finalising your
          company’s registration.
        </Typography>
      </div>

      <div>
        <Button
          color="primary"
          id="landingPage-letsStart"
          variant="contained"
          onClick={() => props.onNext(CompanyRegStep.companyProfile)}
          children={"Next"}
        />
      </div>
    </Root>
  );
}
