import React from "react";
import { Router } from "routes";
import { Table, Listing } from "./components";
import { Box } from "@mui/material";

export function PrimaryMarket() {
  return (
    <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Router
        baseURL={"/builder/primary-market"}
        redirectPath={"/builder/primary-market/table"}
        routes={[
          {
            name: "Table",
            id: "table",
            path: "/builder/primary-market/table",
            component: <Table />,
          },
          {
            name: "Listing",
            id: "listing",
            path: "/builder/primary-market/listing",
            component: <Listing />,
          },
        ]}
      />
    </Box>
  );
}
