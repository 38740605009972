import React, { forwardRef } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";

interface AssetManagingRiskSectionRendererProps {
  goToIssuer: () => void;
  documentsExist: boolean;
}

export const AssetManagingRiskSectionRenderer = forwardRef(
  ({ goToIssuer }: AssetManagingRiskSectionRendererProps, ref) => {
    return (
      <Box ref={ref} sx={{ pt: 4, pb: 5, pr: 3 }}>
        <Typography
          sx={(theme) => ({ color: theme.palette.text.secondary, mb: 4 })}
        >
          Information on deal structure, collateral used to secure this
          investment, and options in the case of a default on repayment by the
          Issuer.
        </Typography>
        <Divider
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.text.disabled}`,
            mb: 5,
          })}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "1fr 1fr",
              xs: "auto",
            },
            gap: { sm: 10, xs: 5 },
            "& > *": {
              display: "grid",
              gap: 4,
              gridTemplateRows: {
                sm: "repeat(2, 120px)",
                xs: "auto auto",
              },
            },
          }}
        >
          <Box>
            <Box
              sx={(theme) => ({
                pb: 4,
                borderBottom: `1px solid ${theme.palette.text.disabled}`,
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                })}
              >
                Legal recourse
              </Typography>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                })}
              >
                Subscription Agreement
              </Typography>

              <Typography
                sx={(theme) => ({ color: theme.palette.text.tertiary })}
              >
                Specifies the investment terms agreed to by the issuer and all
                investors; legally enforceable.{" "}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                pb: 4,
                borderBottom: `1px solid ${theme.palette.text.disabled}`,
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                })}
              >
                Due Diligence
              </Typography>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.tertiary,
                })}
              >
                Additional due diligence information can be obtained by
                contacting the{" "}
                <Link
                  underline="hover"
                  onClick={goToIssuer}
                  sx={{ fontWeight: "bold" }}
                >
                  issuer directly
                </Link>
                .
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={(theme) => ({
                pb: 4,
                borderBottom: `1px solid ${theme.palette.text.disabled}`,
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                })}
              >
                Recourse to issuer
              </Typography>
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                Yes
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                borderBottom: {
                  sm: `1px solid ${theme.palette.text.disabled}`,
                },
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.warning.light,
                  fontWeight: "bold",
                })}
              >
                Post-close reporting
              </Typography>
              <Typography
                sx={(theme) => ({ color: theme.palette.text.tertiary })}
              >
                Investors can access issuer-related updates.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
