import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Token } from "james/ledger";
import { useLedgerContext } from "context/Ledger";
import { Tooltip } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
const PREFIX = "Description";

const classes = {
  infoIcon: `${PREFIX}-infoIcon`,
};

const StyledTooltip = styled(Tooltip)(() => ({
  [`& .${classes.infoIcon}`]: {
    cursor: "pointer",
  },
}));

export type DescriptionProps = {
  token: Token;
};

export function Description(props: DescriptionProps) {
  const { ledgerContextGetAssetTokenDescription } = useLedgerContext();

  const [description, setDescription] = useState("loading...");
  useEffect(() => {
    (async () => {
      setDescription(await ledgerContextGetAssetTokenDescription(props.token));
    })();
  }, [ledgerContextGetAssetTokenDescription, props.token]);

  return (
    <StyledTooltip placement="top" title={description}>
      <InfoIcon className={classes.infoIcon} />
    </StyledTooltip>
  );
}
