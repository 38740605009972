import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";
import { ConnectedIndividual } from "james/legal";
import {
  ConnectionType,
  IdentificationType,
} from "james/legal/ConnectedIndividual";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import React, { ChangeEvent, useEffect, useState } from "react";
import { countries } from "james/country";
import cx from "classnames";
import {
  FormFieldsValidation,
  FormValidator,
  IndividualFormFieldValidationState,
} from "./Validation";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
const PREFIX = "ConnectedIndividualDialog";

const classes = {
  dialogPaperOverride: `${PREFIX}-dialogPaperOverride`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  dialogContent: `${PREFIX}-dialogContent`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  sectionWithColumns1Gap: `${PREFIX}-sectionWithColumns1Gap`,
  sectionHelperText: `${PREFIX}-sectionHelperText`,
  sectionWith2EqColumns2Gap: `${PREFIX}-sectionWith2EqColumns2Gap`,
  postalAddressTitleLayout: `${PREFIX}-postalAddressTitleLayout`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogPaperOverride}`]: {
    backgroundColor: theme.palette.custom.midnight,
  },

  [`& .${classes.dialogTitle}`]: {
    padding: theme.spacing(1, 3),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContent}`]: {
    padding: `${theme.spacing(2, 3)} !important`,
    display: "grid",
    gridTemplateColumns: "450px 450px",
    columnGap: theme.spacing(8),
    rowGap: theme.spacing(2.5),
  },

  [`& .${classes.sectionHeading}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.sectionWithColumns1Gap}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  [`& .${classes.sectionHelperText}`]: {
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.sectionWith2EqColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },

  [`& .${classes.postalAddressTitleLayout}`]: {
    margin: theme.spacing(-0.8, 0),
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },
}));

interface ConnectedIndividualProps {
  open: boolean;
  onClose: () => void;
  connectedIndividual?: ConnectedIndividual;
  onChange?: (individual: ConnectedIndividual) => void;
}

export const ConnectedIndividualDialog: React.FC<ConnectedIndividualProps> = (
  props: ConnectedIndividualProps,
) => {
  const { open, onClose, connectedIndividual, onChange } = props;
  const [individual, setIndividual] = React.useState<ConnectedIndividual>(
    connectedIndividual
      ? new ConnectedIndividual(connectedIndividual)
      : new ConnectedIndividual({
          ...new ConnectedIndividual(),
          physicalAddress: new Address(),
          postalAddress: new Address(),
        } as ConnectedIndividual),
  );
  const [toggle, setToggle] = React.useState(false);
  const [postalOfficeAddressSame, setPostalOfficeAddressSame] =
    React.useState(false);
  const [postalAddress, setPostalAddress] = React.useState<Address>(
    new Address(
      connectedIndividual ? connectedIndividual.postalAddress : undefined,
    ),
  );
  const [invalidFormFields, setInvalidFormFields] = useState<{
    [key: string]: string | undefined;
  }>(IndividualFormFieldValidationState);

  if (postalOfficeAddressSame) {
    individual.postalAddress = individual.physicalAddress;
  } else {
    individual.postalAddress = postalAddress;
  }

  useEffect(() => {
    (() => {
      if (postalOfficeAddressSame) {
        setInvalidFormFields((value) => {
          const InvalidFormFields = { ...value };
          delete InvalidFormFields.postalAddressCity;
          delete InvalidFormFields.postalAddressCountryCode;
          delete InvalidFormFields.postalAddressAddressLine1;
          return InvalidFormFields;
        });
      }
    })();
  }, [postalOfficeAddressSame]);

  const InlineFormValidation = (field: string, value: string) =>
    debounce(() => {
      const errors = FormValidator(field, value);
      let InvalidFormFields = { ...invalidFormFields };

      // Conditionals for inline validation
      if (
        individual.telephoneNumber !== "" ||
        individual.cellphoneNumber !== "" ||
        individual.emailAddress !== ""
      ) {
        delete InvalidFormFields.cellphoneNumber;
        delete InvalidFormFields.emailAddress;
        delete InvalidFormFields.telephoneNumber;
      }

      if (isEqual(new Address(individual.postalAddress), new Address())) {
        delete InvalidFormFields.postalAddressAddressLine1;
        delete InvalidFormFields.postalAddressCity;
        delete InvalidFormFields.postalAddressCountryCode;
      }

      if (errors.length !== 0) {
        InvalidFormFields = {
          ...InvalidFormFields,
          [field]: errors.join("; "),
        };
      } else {
        delete InvalidFormFields[field];
      }

      setInvalidFormFields(InvalidFormFields);
    }, 600);

  const onUpdate = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    updateState(name, event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateState = (name: string, value: any) => {
    const newIndividual = individual;
    const newPostalAddress = postalAddress;
    const keys = name.split(".") as (keyof ConnectedIndividual)[];
    switch (keys.length) {
      case 1:
        newIndividual[keys[0]] = value;
        break;
      case 2:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (newIndividual[keys[0]] as any)[keys[1]] = value;
        if (keys[0] === "postalAddress") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (newPostalAddress as any)[`${[keys[1]]}`] = value;
          newIndividual.postalAddress = newPostalAddress as Address;
        }
        break;
      default:
        console.error("unable to update company representative");
    }

    InlineFormValidation(camelCase(name), value)();
    setIndividual(new ConnectedIndividual(newIndividual));
    setPostalAddress(new Address(newPostalAddress));
    setToggle(!toggle);
  };

  const onUpdateIndividual = () => {
    if (
      FormFieldsValidation(invalidFormFields, individual, setInvalidFormFields)
    ) {
      if (onChange) {
        onChange(new ConnectedIndividual({ ...individual }));
      }
      close();
    }
  };
  const close = () => {
    onClose();
  };

  const onClear = () => {
    const clearedIndividual = new ConnectedIndividual();
    clearedIndividual.postalAddress = new Address();
    clearedIndividual.physicalAddress = new Address();
    setPostalAddress(new Address());
    setIndividual(clearedIndividual);
  };

  const onAddIndividual = () => {
    // Validation
    if (
      FormFieldsValidation(invalidFormFields, individual, setInvalidFormFields)
    ) {
      if (onChange) {
        onChange(new ConnectedIndividual({ ...individual }));
      }
      close();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={close}
      maxWidth="lg"
      classes={{ paper: classes.dialogPaperOverride }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">New Connected Individual</Typography>
        <div className={classes.sectionWithColumns1Gap}>
          {!connectedIndividual && (
            <Button
              id="connectedIndividual-addIndividual"
              onClick={onAddIndividual}
              variant="contained"
              color="primary"
            >
              Add Individual
            </Button>
          )}
          {connectedIndividual && (
            <Button onClick={onClear} variant="outlined">
              Clear Info
            </Button>
          )}
          {connectedIndividual && (
            <Button
              variant="contained"
              onClick={onUpdateIndividual}
              color="primary"
            >
              Update Info
            </Button>
          )}
          <IconButton
            onClick={close}
            id="connectedIndividual-closeDialog"
            size="small"
          >
            <Icon>
              <Clear />
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={cx(classes.dialogContent, "meshScroll")}>
        <TextField
          id="connectedIndividual-connectionToCompany"
          margin="dense"
          select
          variant="outlined"
          fullWidth
          value={individual.connectionType}
          error={Boolean(invalidFormFields.connectionType)}
          helperText={invalidFormFields.connectionType}
          onChange={onUpdate("connectionType")}
          label="Connection To Company"
        >
          <MenuItem
            id={`connectedIndividual-connectionToCompany-${ConnectionType.ManagerConnectionType}`}
            value={ConnectionType.ManagerConnectionType}
          >
            {ConnectionType.ManagerConnectionType}
          </MenuItem>
          <MenuItem
            id={`connectedIndividual-connectionToCompany-${ConnectionType.ShareholderConnectionType}`}
            value={ConnectionType.ShareholderConnectionType}
          >
            {ConnectionType.ShareholderConnectionType}
          </MenuItem>
          <MenuItem
            id={`connectedIndividual-connectionToCompany-${ConnectionType.AgentConnectionType}`}
            value={ConnectionType.AgentConnectionType}
          >
            {ConnectionType.AgentConnectionType}
          </MenuItem>
          <MenuItem
            id={`connectedIndividual-connectionToCompany-${ConnectionType.SoleProprietorConnectionType}`}
            value={ConnectionType.SoleProprietorConnectionType}
          >
            {ConnectionType.SoleProprietorConnectionType}
          </MenuItem>
          <MenuItem
            id={`connectedIndividual-connectionToCompany-${ConnectionType.PartnerConnectionType}`}
            value={ConnectionType.PartnerConnectionType}
          >
            {ConnectionType.PartnerConnectionType}
          </MenuItem>
        </TextField>

        <div />

        {/* ---- Personal Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Personal Details"
          />
          <div className={classes.sectionWith2EqColumns2Gap}>
            <TextField
              id="connectedIndividual-personalDetails-firstName"
              margin="dense"
              variant="outlined"
              fullWidth
              label="First Name"
              error={Boolean(invalidFormFields.firstName)}
              helperText={invalidFormFields.firstName}
              onChange={onUpdate("firstName")}
              value={individual.firstName}
            />
            <TextField
              id="connectedIndividual-personalDetails-middleName"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Middle Names(s)"
              onChange={onUpdate("middleNames")}
              value={individual.middleNames}
              placeholder="Optional"
            />

            <TextField
              id="connectedIndividual-personalDetails-lastName"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Last Name"
              error={Boolean(invalidFormFields.lastName)}
              helperText={invalidFormFields.lastName}
              value={individual.lastName}
              onChange={onUpdate("lastName")}
            />
            <div />

            <TextField
              id="connectedIndividual-personalDetails-dateOfBirth"
              margin="dense"
              type="date"
              placeholder="YYYY-MM-DD"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Date of Birth"
              value={individual.dateOfBirth}
              error={Boolean(invalidFormFields.dateOfBirth)}
              helperText={invalidFormFields.dateOfBirth}
              onChange={onUpdate("dateOfBirth")}
            />
            <CountryDropdown
              id="connectedIndividual-personalDetails-nationality"
              isOptionEqualToValue={(option, value) => {
                if (value.value === "") {
                  return true;
                }
                return option === value;
              }}
              onChange={autoCompleteOnUpdate("nationality")}
              value={
                individual.nationality === ""
                  ? {
                      value: "",
                      label: "",
                    }
                  : {
                      value: individual.nationality,
                      label: countries.filter(
                        (c) => c.value === individual.nationality,
                      )[0].label,
                    }
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="connectedIndividual-personalDetails-nationality-TextField"
                  margin="dense"
                  label="Nationality"
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select...",
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={Boolean(invalidFormFields.nationality)}
                  fullWidth
                  helperText={invalidFormFields.nationality}
                />
              )}
            />

            <TextField
              id="connectedIndividual-personalDetails-identificationType"
              select
              margin="dense"
              variant="outlined"
              fullWidth
              label="Identification Type"
              value={individual.identificationType}
              onChange={onUpdate("identificationType")}
              error={Boolean(invalidFormFields.identificationType)}
              helperText={invalidFormFields.identificationType}
            >
              <MenuItem
                id={`connectedIndividual-personalDetails-identificationType-${camelCase(
                  IdentificationType.SouthAfricanIDIdentificationType,
                )}`}
                value={IdentificationType.SouthAfricanIDIdentificationType}
              >
                {IdentificationType.SouthAfricanIDIdentificationType}
              </MenuItem>
              <MenuItem
                id={`connectedIndividual-personalDetails-identificationType-${camelCase(
                  IdentificationType.PassportIdentificationType,
                )}`}
                value={IdentificationType.PassportIdentificationType}
              >
                {IdentificationType.PassportIdentificationType}
              </MenuItem>
            </TextField>
            <TextField
              id="connectedIndividual-personalDetails-identificationNumber"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Identification Number"
              value={individual.identificationNumber}
              error={Boolean(invalidFormFields.identificationNumber)}
              helperText={invalidFormFields.identificationNumber}
              onChange={onUpdate("identificationNumber")}
            />
          </div>
        </div>

        {/* ---- Contact Details ---- */}
        <div>
          <Typography
            className={classes.sectionHeading}
            variant="h6"
            children="Contact Details"
          />
          <TextField
            id="connectedIndividual-contactDetails-email"
            margin="dense"
            variant="outlined"
            fullWidth
            label="Email"
            value={individual.emailAddress}
            error={Boolean(invalidFormFields.emailAddress)}
            helperText={invalidFormFields.emailAddress}
            onChange={onUpdate("emailAddress")}
          />
          <div className={classes.sectionWith2EqColumns2Gap}>
            <TextField
              id="connectedIndividual-contactDetails-mobile"
              variant="outlined"
              fullWidth
              label="Mobile"
              margin="dense"
              value={individual.cellphoneNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  onUpdate("cellphoneNumber")(event);
                }
              }}
            />
            <TextField
              id="connectedIndividual-contactDetails-telephone"
              margin="dense"
              variant="outlined"
              fullWidth
              label="Telephone"
              value={individual.telephoneNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                if (
                  /^[+]?[0-9]{0,15}$/.test(String(value)) ||
                  value.length === 0
                ) {
                  onUpdate("telephoneNumber")(event);
                }
              }}
            />
          </div>
        </div>

        {/* ---- Physical Address ---- */}
        <div>
          <Typography
            variant="h6"
            children="Physical Address"
            className={classes.sectionHeading}
          />
          {individual.physicalAddress && (
            <>
              <CountryDropdown
                id="connectedIndividual-physicalAddress-country"
                isOptionEqualToValue={(option, value) => {
                  if (value.value === "") {
                    return true;
                  }
                  return option === value;
                }}
                onChange={autoCompleteOnUpdate("physicalAddress.countryCode")}
                value={
                  individual.physicalAddress.countryCode === ""
                    ? {
                        value: "",
                        label: "",
                      }
                    : {
                        value: individual.physicalAddress.countryCode,
                        label: countries.filter(
                          (c) =>
                            c.value ===
                            new Address(individual.physicalAddress).countryCode,
                        )[0].label,
                      }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="connectedIndividual-physicalAddress-country-TextField"
                    margin="dense"
                    label="Country"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    error={Boolean(
                      invalidFormFields.physicalAddressCountryCode,
                    )}
                    fullWidth
                    helperText={invalidFormFields.physicalAddressCountryCode}
                  />
                )}
              />
              <TextField
                id="connectedIndividual-physicalAddress-line1"
                margin="dense"
                variant="outlined"
                fullWidth
                label="Address 1"
                value={individual.physicalAddress.addressLine1}
                onChange={onUpdate("physicalAddress.addressLine1")}
                error={Boolean(invalidFormFields.physicalAddressAddressLine1)}
                helperText={invalidFormFields.physicalAddressAddressLine1}
              />
              <TextField
                id="connectedIndividual-physicalAddress-line2"
                margin="dense"
                variant="outlined"
                fullWidth
                label="Address 2"
                value={individual.physicalAddress.addressLine2}
                onChange={onUpdate("physicalAddress.addressLine2")}
                placeholder="Optional"
                helperText={invalidFormFields.physicalAddressAddressLine2}
              />
              <div className={classes.sectionWith2EqColumns2Gap}>
                <TextField
                  id="connectedIndividual-physicalAddress-suburb"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  value={individual.physicalAddress.suburb}
                  onChange={onUpdate("physicalAddress.suburb")}
                  error={Boolean(invalidFormFields.physicalAddressSuburb)}
                  helperText={invalidFormFields.physicalAddressSuburb}
                />
                <TextField
                  id="connectedIndividual-physicalAddress-city"
                  variant="outlined"
                  fullWidth
                  label="City"
                  margin="dense"
                  value={individual.physicalAddress.city}
                  onChange={onUpdate("physicalAddress.city")}
                  error={Boolean(invalidFormFields.physicalAddressCity)}
                  helperText={invalidFormFields.physicalAddressCity}
                />
                <TextField
                  id="connectedIndividual-physicalAddress-province"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Province"
                  value={individual.physicalAddress.province}
                  onChange={onUpdate("physicalAddress.province")}
                  error={Boolean(invalidFormFields.physicalAddressProvince)}
                  helperText={invalidFormFields.physicalAddressProvince}
                />
                <TextField
                  id="connectedIndividual-physicalAddress-postalCode"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  label="Postal Code"
                  value={individual.physicalAddress.postalCode}
                  onChange={onUpdate("physicalAddress.postalCode")}
                  error={Boolean(invalidFormFields.physicalAddressPostalCode)}
                  helperText={invalidFormFields.physicalAddressPostalCode}
                />
              </div>
            </>
          )}
        </div>

        {/* ---- Postal Address ---- */}
        <div>
          <div className={classes.postalAddressTitleLayout}>
            <Typography variant="h6" children="Postal Address (Optional)" />
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  id="connectedIndividual-postalAddress-sameAsPhysicalAddress"
                  size="small"
                  color="primary"
                  checked={postalOfficeAddressSame}
                  onChange={() => {
                    setPostalOfficeAddressSame(!postalOfficeAddressSame);
                  }}
                />
              }
              label={
                <Typography variant="subtitle2" children="Copy Physical" />
              }
            />
          </div>
          {individual.postalAddress && (
            <>
              <CountryDropdown
                id="connectedIndividual-postalAddress-country"
                disabled={postalOfficeAddressSame}
                isOptionEqualToValue={(option, value) => {
                  if (value.value === "") {
                    return true;
                  }
                  return option === value;
                }}
                onChange={autoCompleteOnUpdate("postalAddress.countryCode")}
                value={
                  individual.postalAddress.countryCode === ""
                    ? {
                        value: "",
                        label: "",
                      }
                    : {
                        value: individual.postalAddress.countryCode,
                        label: countries.filter(
                          (c) =>
                            c.value ===
                            new Address(individual.postalAddress).countryCode,
                        )[0].label,
                      }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="connectedIndividual-postalAddress-country-TextField"
                    margin="dense"
                    label="Country"
                    InputProps={{
                      ...params.InputProps,
                      placeholder: "Select...",
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    error={Boolean(invalidFormFields.postalAddressCountryCode)}
                    fullWidth
                    helperText={invalidFormFields.postalAddressCountryCode}
                  />
                )}
              />
              <TextField
                id="connectedIndividual-postalAddress-line1"
                variant="outlined"
                fullWidth
                label="Address 1"
                margin="dense"
                value={individual.postalAddress.addressLine1}
                disabled={postalOfficeAddressSame}
                onChange={onUpdate("postalAddress.addressLine1")}
              />
              <TextField
                id="connectedIndividual-postalAddress-line2"
                variant="outlined"
                fullWidth
                label="Address 2"
                margin="dense"
                value={individual.postalAddress.addressLine2}
                disabled={postalOfficeAddressSame}
                onChange={onUpdate("postalAddress.addressLine2")}
                placeholder="Optional"
              />

              <div className={classes.sectionWith2EqColumns2Gap}>
                <TextField
                  id="connectedIndividual-postalAddress-suburb"
                  variant="outlined"
                  fullWidth
                  label="Suburb"
                  margin="dense"
                  value={individual.postalAddress.suburb}
                  disabled={postalOfficeAddressSame}
                  onChange={onUpdate("postalAddress.suburb")}
                  placeholder="Optional"
                />
                <TextField
                  id="connectedIndividual-postalAddress-city"
                  variant="outlined"
                  fullWidth
                  label="City"
                  margin="dense"
                  value={individual.postalAddress.city}
                  disabled={postalOfficeAddressSame}
                  onChange={onUpdate("postalAddress.city")}
                  error={Boolean(invalidFormFields.postalAddressCity)}
                  helperText={invalidFormFields.postalAddressCity}
                />

                <TextField
                  id="connectedIndividual-postalAddress-province"
                  variant="outlined"
                  fullWidth
                  label="Province"
                  placeholder="Optional"
                  margin="dense"
                  value={individual.postalAddress.province}
                  disabled={postalOfficeAddressSame}
                  onChange={onUpdate("postalAddress.province")}
                />

                <TextField
                  id="connectedIndividual-postalAddress-postalCode"
                  variant="outlined"
                  fullWidth
                  label="Postal Code"
                  placeholder="Optional"
                  margin="dense"
                  value={individual.postalAddress.postalCode}
                  disabled={postalOfficeAddressSame}
                  onChange={onUpdate("postalAddress.postalCode")}
                />
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </StyledDialog>
  );
};
