import config from "react-global-configuration";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { DigitalETF } from "./DigitalETF";

export interface IssueDigitalETFRequest {
  context: Context;
  digitalETFID: string;
  issuanceAccountID: string;
}

export interface IssueDigitalETFResponse {
  digitalETF: DigitalETF;
}

export interface PreIssueDigitalETFRequest {
  context: Context;
  digitalETFID: string;
  issuanceAccountID: string;
}

export interface PreIssueDigitalETFResponse {
  digitalETF: DigitalETF;
}

export interface CancelDigitalETFRequest {
  context: Context;
  digitalETFID: string;
}

export interface CancelDigitalETFResponse {
  digitalETF: DigitalETF;
}

export interface MatureDigitalETFRequest {
  context: Context;
  digitalETFID: string;
}

export interface MatureDigitalETFResponse {
  digitalETF: DigitalETF;
}

export interface MarkDigitalETFDeletedRequest {
  context: Context;
  digitalETFID: string;
}

export interface MarkDigitalETFDeletedResponse {
  digitalETF: DigitalETF;
}

const DigitalETFStateChangerServiceProviderName =
  "financial-DigitalETFStateChanger";

export const DigitalETFStateChanger = {
  async IssueDigitalETF(
    request: IssueDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<IssueDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFStateChangerServiceProviderName}.IssueDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async PreIssueDigitalETF(
    request: PreIssueDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<PreIssueDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFStateChangerServiceProviderName}.PreIssueDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async CancelDigitalETF(
    request: CancelDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<CancelDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFStateChangerServiceProviderName}.CancelDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async MatureDigitalETF(
    request: MatureDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<MatureDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFStateChangerServiceProviderName}.MatureDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async MarkDigitalETFDeleted(
    request: MarkDigitalETFDeletedRequest,
    additionalHeaders?: Headers,
  ): Promise<MarkDigitalETFDeletedResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFStateChangerServiceProviderName}.MarkDigitalETFDeleted`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
};
