import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  alpha,
} from "@mui/material";
import {
  Block as NotAllowedIcon,
  PublishOutlined as UploadIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";
import { useApplicationContext } from "context/Application/Application";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FutureDocument } from "@mesh/common-js/dist/document/document_pb";
import { doUpload } from "utilities/network/upload";
import { TextField } from "components/FormFields";

const allowedDocumentTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

export type DropzoneUploaderProps = {
  disabled: boolean;
  onNewlyUploaded: (docs: FutureDocument[]) => void;
};

export const DropzoneUploader = (props: DropzoneUploaderProps) => {
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [allComplete, setAllComplete] = useState(false);
  const [pendingDocumentUploads, setPendingDocumentUploads] = useState<
    {
      document: FutureDocument;
      file: File;
      percent: number;
      complete: boolean;
    }[]
  >([]);

  useEffect(() => {
    // if not yet all complete then do nothing
    if (!allComplete) {
      return;
    }

    // otherwise call on update method
    props.onNewlyUploaded(pendingDocumentUploads.map((d) => d.document));

    // and then clear
    setAllComplete(false);
    setPendingDocumentUploads([]);
  }, [pendingDocumentUploads, allComplete]);

  // Document Uploading
  const dropZoneProps = useDropzone({
    // allowed document types
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
      "application/pdf": [],
    },

    // document drop validator
    validator: (f: File) => {
      if (!allowedDocumentTypes.includes(f.type)) {
        enqueueSnackbar("File Type Not Supported", { variant: "warning" });
        return [
          {
            message: "Unsupported File Type",
            code: "unsupported-file-type",
          },
        ];
      }

      if (f.size > 20 * 1000 * 1000) {
        enqueueSnackbar("File is Too Large For Upload", { variant: "warning" });
        return [
          {
            message: "Max of 20MB allowed",
            code: "file-too-large",
          },
        ];
      }

      return null;
    },

    // document drop function
    onDrop: async (acceptedFiles: File[]) => {
      // for every file that has been dropped...
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          // request to upload a document against the instrument
          const response =
            await InstrumentDocumentController.RequestDocumentUploadForInstrument(
              {
                context: authContext,
              },
            );

          // add a pending document upload
          setPendingDocumentUploads((prev) => [
            ...prev,
            {
              document: new FutureDocument()
                .setId(response.documentID)
                .setName(file.name)
                .setMimetype(file.type)
                .setDescription(""),
              file,
              complete: false,
              percent: 0,
            },
          ]);

          // perform upload within an awaited promise to allow asynchronous UI updates
          await (() =>
            new Promise((resolve, reject) => {
              file.arrayBuffer().then((arrayBuffer) => {
                doUpload({
                  url: response.uploadURL,
                  documentData: arrayBuffer,
                  documentType: file.type,
                  onProgress: (p: number) =>
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.getId() === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].percent = p;
                      return [...prev];
                    }),
                  onComplete: () => {
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.getId() === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].complete = true;
                      return [...prev];
                    });
                    resolve(true);
                  },
                  onError: reject,
                });
              });
            }))();
        }),
      );

      setAllComplete(true);
    },
  });

  return (
    <Box>
      <Box
        {...dropZoneProps.getRootProps()}
        sx={(theme) => ({
          padding: theme.spacing(2, 0, 1, 0),
          width: "100%",
          height: 143,
          border: dropZoneProps.isDragAccept
            ? `1px dashed ${theme.palette.success.main}`
            : dropZoneProps.isDragReject
              ? `1px dashed ${theme.palette.error.main}`
              : `1px dashed ${theme.palette.text.primary}`,
          backgroundColor: dropZoneProps.isDragAccept
            ? alpha(theme.palette.success.main, 0.2)
            : dropZoneProps.isDragReject
              ? alpha(theme.palette.error.main, 0.2)
              : alpha(theme.palette.text.secondary, 0.1),
          display: "grid",
          gridTemplateColumns: "1fr",
          alignItems: "center",
          justifyItems: "center",
          gridRowGap: theme.spacing(1),
        })}
      >
        <input
          {...dropZoneProps.getInputProps()}
          disabled={props.disabled || !!pendingDocumentUploads.length}
        />
        {(() => {
          if (pendingDocumentUploads.length) {
            return (
              <>
                <Typography variant="body2" children="Uploads in Progress" />
                <CircularProgress />
              </>
            );
          }

          if (dropZoneProps.isDragActive) {
            return dropZoneProps.isDragAccept ? (
              <>
                <Typography
                  variant="body1"
                  sx={(theme) => ({ color: theme.palette.success.light })}
                  children="Drop Documents For Upload"
                />
                <UploadIcon
                  sx={(theme) => ({ color: theme.palette.success.light })}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  color="error"
                  children="Unsupported Document Type"
                />
                <NotAllowedIcon color="error" />
              </>
            );
          }
          return (
            <>
              <Typography
                variant="body2"
                children="Drop Documents Here to Upload"
              />
              <Typography variant="body2" children="or" />
              <Button
                disabled={props.disabled}
                id="etfDialog-selectFilesUpload-button"
                variant="contained"
                color="secondary"
                children="Select Files"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={(theme) => ({ marginTop: theme.spacing(1.5) })}
                children=".png, .jpeg, or .pdf up to max size of 20MB each"
              />
            </>
          );
        })()}
      </Box>
      {pendingDocumentUploads.map((d, idx: number) => (
        <Box
          key={idx}
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gridColumnGap: theme.spacing(1),
            gridRowGap: theme.spacing(1),
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingBottom: theme.spacing(2),
          })}
        >
          <TextField
            id="etfDialog-description-formField"
            label="Description"
            value={d.file.name}
            disabled
          />
          {d.complete ? (
            <DoneIcon
              sx={(theme) => ({ color: theme.palette.success.light })}
            />
          ) : (
            <CircularProgress
              sx={(theme) => ({ marginTop: theme.spacing(1.5) })}
              size={25}
              variant={d.percent ? "determinate" : "indeterminate"}
              value={d.percent}
            />
          )}
          <Typography
            sx={{ gridColumn: "1/3" }}
            variant="body2"
            color="secondary"
            children={d.file.name}
          />
        </Box>
      ))}
    </Box>
  );
};
