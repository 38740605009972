import { Token } from "james/ledger";
import { ListingState } from "james/market/Listing";
import dayjs from "dayjs";

export const ModelTypeName = "mesh::financialYieldBearingStablecoinView/Model";
export class Model {
  ["@type"]: string = ModelTypeName;
  id = "";
  yieldBearingStablecoinID = "";
  listingState: ListingState | "" = "";
  name = "";
  shortName = "";
  underlyingAssetID = "";
  token: Token = new Token();
  issueDate: string = dayjs().format();
  issuer = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.yieldBearingStablecoinID = model.yieldBearingStablecoinID;
    this.listingState = model.listingState;
    this.name = model.name;
    this.shortName = model.shortName;
    this.underlyingAssetID = model.underlyingAssetID;
    this.token = new Token(model.token);
    this.issueDate = model.issueDate;
    this.issuer = model.issuer;
  }
}
