import BigNumber from "bignumber.js";

const negativeZero = -0;

export function roundToN(
  valueToRound: string,
  noDecimalPlaces: number,
): string {
  if (noDecimalPlaces === negativeZero) {
    return valueToRound;
  } else if (noDecimalPlaces === 0) {
    return new BigNumber(valueToRound)
      .integerValue(BigNumber.ROUND_HALF_UP)
      .toString();
  }

  const bigValueToRound = new BigNumber(valueToRound);
  if (bigValueToRound.isNaN()) {
    console.error("given value to round is not a number");
    return "";
  }
  if (noDecimalPlaces < 0) {
    console.error(
      `invalid noDecimalPlaces value: '${noDecimalPlaces}' given to round`,
    );
    return "";
  }

  // determine no. of decimal places already in value
  const valueToRoundDecimalPointIdx = valueToRound.indexOf(".");
  if (valueToRoundDecimalPointIdx < 0) {
    // if there is no decimal point then no rounding is necessary
    return valueToRound;
  }
  const valueToRoundNoDecimalDigits = valueToRound.slice(
    valueToRoundDecimalPointIdx + 1,
  ).length;

  if (valueToRoundNoDecimalDigits <= noDecimalPlaces) {
    // if there are less decimal digits than required then no rounding is required
    return valueToRound;
  }

  // perform rounding
  const valueIsNegative = bigValueToRound.isNegative();
  if (valueIsNegative) {
    // dealing with negative as though it is a positive since rounding is
    // up regardless of sign and we want -1.055 to round down to -1.056
    valueToRound = valueToRound.slice(1);
  }

  // perform rounding and put back negative if necessary
  let roundedValue = `${valueIsNegative ? "-" : ""}${new BigNumber(valueToRound)
    .decimalPlaces(noDecimalPlaces, BigNumber.ROUND_HALF_UP)
    .toString()}`;
  const bigRoundedValue = new BigNumber(roundedValue);

  // if value was rounded down or stayed the same AND
  // the `noDecimalPlaces'nth` decimal digit in the original value to round was a zero
  // then trailing zeros should be added as required
  if (
    bigRoundedValue.lte(bigValueToRound) &&
    valueToRound.slice(valueToRoundDecimalPointIdx + 1)[noDecimalPlaces - 1] ===
      "0"
  ) {
    // determine no. of decimal places in rounded value
    let noDecimalPlacesInRoundedValue = 0;
    const roundedValueDecimalPointIdx = roundedValue.indexOf(".");
    if (roundedValueDecimalPointIdx < 0) {
      // no decimal point, add one
      roundedValue += ".";
    } else {
      noDecimalPlacesInRoundedValue = roundedValue.slice(
        roundedValueDecimalPointIdx + 1,
      ).length;
    }
    roundedValue += giveMe0s(noDecimalPlaces - noDecimalPlacesInRoundedValue);
  }

  return roundedValue;
}

export function giveMe0s(noZeros: number): string {
  let zeros = "";
  for (let i = 0; i < noZeros; i++) {
    zeros += "0";
  }
  return zeros;
}

/**
 * Returns the amount of decimal place needed to format a number to
 * a specified maximum length.
 *
 * @param {BigNumber} number number that will be rounded to consider
 * @param {number} [max=12] maximum digit length for number
 * @param {number }[min=2] minimum precision for decimals
 *
 * ```ts
 * x = new BigNumber("123456.1")
 * getNoDecimals(x)                  // 2
 * ```
 */
export const getNoDecimals = (number: BigNumber, max = 12, min = 2): number => {
  // sd() -> gets significant digits and rounds up
  // dp() -> gets decimal places of result
  let decimalPlaces = number.sd(max).dp();
  decimalPlaces = decimalPlaces ?? max;
  // return dp() if dp is greater than min decimal places
  return decimalPlaces > min ? decimalPlaces : min;
};
