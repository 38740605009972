import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Model as LedgerTokenViewModel,
  Reader as LedgerTokenViewReader,
} from "../../../../../james/views/ledgerTokenView";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import {
  TextListCriterion,
  TextNINListCriterion,
} from "james/search/criterion";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { ValidationResult } from "common/validation";
import { StellarNetwork } from "james/stellar";
import {
  allFutureNetworks,
  criteriaFromToken,
} from "@mesh/common-js/dist/ledger";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useValidatedForm } from "hooks/useForm";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
  FormUpdaterSpecsType as FormDataUpdaterType,
  ListingFormData,
} from "./components/ListingForm/useValidatedForm";
import { useErrorContext } from "context/Error";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FutureToken } from "@mesh/common-js/dist/ledger/token_pb";
import { Token } from "james/ledger";
import {
  ErrSubscriptionOrderBookNotFoundCode,
  ListingRepository,
  Mechanism,
  MechanismType,
  QuoteParameter,
  SmartInstrumentPrimaryMarketLister,
  SubscriptionOrderBookRepository,
} from "james/market";
import { TokenIdentifier } from "james/search/identifier";
import { ErrListingNotFoundCode } from "james/market/ListingRepository";
import { Listing, ListingState } from "james/market/Listing";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import BigNumber from "bignumber.js";
import { networkFromFutureNetwork } from "james/ledger/Network";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";
import {
  SubscriptionOrderBook,
  SubscriptionOrderBookState,
} from "james/market/SubscriptionOrderBook";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  MarketSubscriptionOrderBookViewModel,
  MarketSubscriptionOrderBookViewReader,
} from "james/views/marketSubscriptionOrderBookView";
import { enqueueSnackbar } from "notistack";

dayjs.extend(utc);

export enum ViewMode {
  Undefined = "-",
  View = "view",
  Edit = "edit",
}

export type ListingContextType = {
  apiCallInProgress: boolean;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  // associated data
  ledgerTokenViewModels: LedgerTokenViewModel[];
  subscriptionOrderBookViewModel?:
    | MarketSubscriptionOrderBookViewModel
    | undefined;

  // data for form and validation
  formData: ListingFormData;
  formDataValidationResult: ValidationResult;
  formDataUpdater: FormDataUpdaterType;

  performListingCreation: () => void;
};

export const defaultContext: ListingContextType = {
  apiCallInProgress: false,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  // associated data
  ledgerTokenViewModels: [],
  subscriptionOrderBookViewModel: undefined,

  // data for form and validation
  formData: {
    listing: new Listing(),
    smartInstrumentToList: new SmartInstrument(),
    subscriptionOrderBook: new SubscriptionOrderBook(),
  },
  formDataValidationResult: {
    valid: false,
    fieldValidations: {},
  },
  formDataUpdater: formDataUpdaterSpecs,

  performListingCreation: () => null,
};

const ListingContext = React.createContext<ListingContextType>(defaultContext);

export const useListingContext = () => useContext(ListingContext);

export const ListingContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    financial: { smartInstrumentReader },
  } = useAPIContext();
  const { errorContextErrorTranslator, errorContextDefaultWarningFeedback } =
    useErrorContext();
  const navigate = useNavigate();
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();

  const [formData, formDataValidationResult, formDataUpdater] =
    useValidatedForm(
      formDataValidationFunc,
      undefined,
      formDataUpdaterSpecs,
      defaultContext.formData,
      new Set<string>([]),
      { skipTouchedFieldsOnValidation: true },
    );

  // ----------- associated data -----------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  const [assetTokenViewModels, setAssetTokenViewModels] = useState<
    LedgerTokenViewModel[]
  >([]);
  useEffect(() => {
    // do nothing if:
    // - required data already loaed
    // - if there was a data loading error
    // - component no longer mounted
    if (dataLoaded || dataLoadError || !isMounted()) {
      return;
    }

    // get the token for the asset that is to be listed from the url
    const urlTokenCode = searchParams.get("token-code");
    const urlTokenIssuer = searchParams.get("token-issuer");
    const urlTokenNetwork = Number(searchParams.get("token-network"));
    if (
      !(urlTokenCode && urlTokenIssuer && urlTokenNetwork) ||
      !allFutureNetworks.includes(urlTokenNetwork)
    ) {
      console.error("token not found in url");
      navigate({
        pathname: "/listing/primary-market/table",
      });
      return;
    }
    const futureTokenForPrimaryMarketListing = new FutureToken()
      .setCode(urlTokenCode)
      .setIssuer(urlTokenIssuer)
      .setNetwork(urlTokenNetwork);

    // Otherwise data loading needs to take place - do that now.
    (async () => {
      // prepare data required for listing
      let retrievedSmartInstrument: SmartInstrument = new SmartInstrument();
      let retrievedLedgerTokenViewModels: LedgerTokenViewModel[] = [];

      // fetch required data
      try {
        await Promise.all([
          // fetch the asset that is to be listed
          (async () => {
            const readSmartInstrument = (
              await smartInstrumentReader.readOneSmartInstrument(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList(
                    criteriaFromToken(futureTokenForPrimaryMarketListing),
                  ),
              )
            ).getSmartinstrument();
            if (!readSmartInstrument) {
              throw new TypeError("smart instrument is nil after reading");
            }
            retrievedSmartInstrument = readSmartInstrument;
          })(),

          // fetch all eligible ledger token view models that can be used
          // as the quote asset on the listing
          (async () => {
            retrievedLedgerTokenViewModels = (
              await LedgerTokenViewReader.Read({
                criteria: {
                  tokenCategory: TextNINListCriterion([
                    TokenCategory.InstrumentStablecoin,
                    TokenCategory.DigitalInstrument,
                    TokenCategory.LiquidityPoolShares,
                    TokenCategory.Unknown,
                  ]),
                  "token.network": TextListCriterion([
                    StellarNetwork.PublicNetwork,
                    StellarNetwork.TestSDFNetwork,
                  ]),
                },
              })
            ).models;

            // confirm at least 1 asset token found
            if (!retrievedLedgerTokenViewModels.length) {
              throw new Error("no token view models found");
            }
          })(),
        ]);
        if (isMounted()) {
          formDataUpdater.smartInstrumentToList(retrievedSmartInstrument);
          setAssetTokenViewModels(retrievedLedgerTokenViewModels);
          setDataLoaded(true);
        }
      } catch (e) {
        // if anything goes wrong store a data loaded error
        console.error("error initialising", e);
        setDataLoadError("Error Fetching Required Data.");
      }
    })();
  }, [dataLoaded, dataLoadError, isMounted]);

  // ----------- listing -----------
  const [listingLoaded, setListingLoaded] = useState(false);
  const [listingLoadError, setListingLoadError] = useState<string | null>(null);
  const [subscriptionOrderBookViewModel, setSubscriptionOrderBookViewModel] =
    useState<MarketSubscriptionOrderBookViewModel | undefined>(undefined);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - data not yet loaded
      // - listing already loaed
      // - if there was an listing loading error
      // - component no longer mounted
      if (!dataLoaded || listingLoaded || listingLoadError || !isMounted()) {
        return;
      }

      // if the listing & subscription order book in the form are already set to those
      // identified by the token found in the URL then do nothing
      if (
        formData.listing.token.isEqualTo(
          Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
        ) &&
        formData.subscriptionOrderBook.token.isEqualTo(
          Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
        )
      ) {
        return;
      }

      // First try and fetch an associated listing.
      // If one is found then update the listing in the form data to this
      // listing, otherwise populate the listing in the form data with a
      // new listing.
      let listingForState: Listing | undefined;
      try {
        // fetch listing
        listingForState = (
          await ListingRepository.RetrieveListing({
            context: authContext,
            identifier: TokenIdentifier(
              Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
            ),
          })
        ).listing;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === ErrListingNotFoundCode) {
          // if execution reaches here then the listing does not yet exist - create a new one
          listingForState = new Listing();
          listingForState.exchangeNetwork = networkFromFutureNetwork(
            formData.smartInstrumentToList.getToken()?.getNetwork() ??
              FutureNetwork.UNDEFINED_NETWORK,
          );
          listingForState.token = Token.fromFutureToken(
            formData.smartInstrumentToList.getToken(),
          );
          listingForState.marketMechanisms.push(
            new Mechanism({
              type: MechanismType.Subscription,
              quoteParameters: [
                new QuoteParameter({
                  quoteToken: (() => {
                    const qt = assetTokenViewModels.find((v) =>
                      v.token.isEqualTo(
                        Token.fromFutureToken(
                          formData.smartInstrumentToList
                            ?.getUnitnominal()
                            ?.getToken(),
                        ),
                      ),
                    );
                    return qt?.token ?? assetTokenViewModels[0].token;
                  })(),
                  minimumDealSize: listingForState.token.newAmountOf(
                    new BigNumber("1"),
                  ),
                  maximumDealSize: listingForState.token.newAmountOf(
                    new BigNumber("100"),
                  ),
                }),
              ],
            }),
          );
          listingForState.state = ListingState.Inactive;
        } else {
          console.error(
            "unexpected error retrieving subscription order book",
            e,
          );
          setListingLoadError(err.message);
          return;
        }
      }
      if (!listingForState) {
        setListingLoadError("Listing not correctly initialised.");
        return;
      }
      formDataUpdater.listing(listingForState);

      // First try and fetch an associated subscription order book.
      // If one is found then update the book in the form data to this
      // book, otherwise populate the book in the form data with a
      // new book.
      let subscriptionOrderBookForState: SubscriptionOrderBook | undefined;
      let subscriptionOrderBookViewModelForState:
        | MarketSubscriptionOrderBookViewModel
        | undefined;
      try {
        // fetch subscriptionOrderBook
        subscriptionOrderBookForState = (
          await SubscriptionOrderBookRepository.RetrieveSubscriptionOrderBook({
            context: authContext,
            identifier: TokenIdentifier(
              Token.fromFutureToken(formData.smartInstrumentToList.getToken()),
            ),
          })
        ).subscriptionOrderBook;

        subscriptionOrderBookViewModelForState = (
          await MarketSubscriptionOrderBookViewReader.ReadOne({
            context: authContext,
            criteria: Token.fromFutureToken(
              formData.smartInstrumentToList.getToken(),
            ).toFilter(),
          })
        ).model;
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === ErrSubscriptionOrderBookNotFoundCode) {
          // if execution reaches here then the subscriptionOrderBook does not yet exist - create a new one
          subscriptionOrderBookForState = new SubscriptionOrderBook();
          subscriptionOrderBookForState.exchangeNetwork =
            networkFromFutureNetwork(
              formData.smartInstrumentToList.getToken()?.getNetwork() ??
                FutureNetwork.UNDEFINED_NETWORK,
            );
          const unitNominal = LedgerAmount.fromFutureAmount(
            formData.smartInstrumentToList.getUnitnominal(),
          );
          subscriptionOrderBookForState.unitPrice = unitNominal;
          subscriptionOrderBookForState.subscriptionAmount =
            unitNominal.setValue(
              unitNominal.value.multipliedBy(new BigNumber("1000")),
            );
          subscriptionOrderBookForState.overSubscriptionAmount =
            unitNominal.setValue(
              unitNominal.value.multipliedBy(new BigNumber("2000")),
            );
          const issueDate = protobufTimestampToDayjs(
            formData.smartInstrumentToList.getIssuedate() ?? new Timestamp(),
          );
          subscriptionOrderBookForState.settlementDate = issueDate.format();
          subscriptionOrderBookForState.closeDate = issueDate.format();
          subscriptionOrderBookForState.openDate = issueDate
            .subtract(2, "weeks")
            .format();
          subscriptionOrderBookForState.token = Token.fromFutureToken(
            formData.smartInstrumentToList.getToken(),
          );
          subscriptionOrderBookForState.state =
            SubscriptionOrderBookState.Pending;
        } else {
          console.error(
            "unexpected error retrieving subscription order book",
            e,
          );
          setListingLoadError(err.message);
          return;
        }
      }
      if (!subscriptionOrderBookForState) {
        setListingLoadError("SubscriptionOrderBook not correctly initialised.");
        return;
      }
      formDataUpdater.subscriptionOrderBook(subscriptionOrderBookForState);
      setSubscriptionOrderBookViewModel(subscriptionOrderBookViewModelForState);

      setListingLoaded(true);
    })();
  }, [dataLoaded, listingLoaded, listingLoadError, isMounted]);

  const clearInitialisationError = useCallback(() => {
    setDataLoadError(null);
    setListingLoadError(null);
  }, [dataLoadError, listingLoadError]);

  const [listingInProgress, setListingInProgress] = useState(false);
  const performListingCreation = async () => {
    setListingInProgress(true);
    try {
      const { subscriptionOrderBook, listing } =
        await SmartInstrumentPrimaryMarketLister.NewSmartInstrumentPrimaryMarketSubscription(
          {
            context: authContext,
            smartInstrumentID: formData.smartInstrumentToList.getId(),

            // listing details
            minimumDealSize:
              formData.listing.marketMechanisms[0].quoteParameters[0]
                .minimumDealSize,
            maximumDealSize:
              formData.listing.marketMechanisms[0].quoteParameters[0]
                .maximumDealSize,

            // subscription order book details
            openDate: formData.subscriptionOrderBook.openDate,
            closeDate: formData.subscriptionOrderBook.closeDate,
            unitPrice: formData.subscriptionOrderBook.unitPrice,
            subscriptionAmount:
              formData.subscriptionOrderBook.subscriptionAmount,
            overSubscriptionAmount:
              formData.subscriptionOrderBook.overSubscriptionAmount,
          },
        );
      formDataUpdater.subscriptionOrderBook(subscriptionOrderBook);
      formDataUpdater.listing(listing);
      enqueueSnackbar("Listed!", { variant: "success" });
    } catch (e) {
      console.error("error performing listing", e);
      errorContextDefaultWarningFeedback(e, "performing listing");
    }
    setListingInProgress(false);
  };

  return (
    <ListingContext.Provider
      value={{
        apiCallInProgress: listingInProgress,

        // viewMode,
        // setEditViewMode,
        // setViewViewMode,

        initialised: dataLoaded && listingLoaded,
        initialisationError: dataLoadError || listingLoadError,
        clearInitialisationError,

        ledgerTokenViewModels: assetTokenViewModels,
        subscriptionOrderBookViewModel,

        formData,
        formDataValidationResult,
        formDataUpdater,

        performListingCreation,
      }}
    >
      {children}
    </ListingContext.Provider>
  );
};
