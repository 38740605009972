// source: financial/paymentTrigger.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var financial_assetflow_pb = require('../financial/assetflow_pb.js');
goog.object.extend(proto, financial_assetflow_pb);
var financial_call_pb = require('../financial/call_pb.js');
goog.object.extend(proto, financial_call_pb);
goog.exportSymbol('proto.financial.PaymentTrigger', null, global);
goog.exportSymbol('proto.financial.PaymentTrigger.PaymenttriggerCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PaymentTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.financial.PaymentTrigger.oneofGroups_);
};
goog.inherits(proto.financial.PaymentTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PaymentTrigger.displayName = 'proto.financial.PaymentTrigger';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.financial.PaymentTrigger.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.financial.PaymentTrigger.PaymenttriggerCase = {
  PAYMENTTRIGGER_NOT_SET: 0,
  ASSETFLOW: 1,
  CALL: 2
};

/**
 * @return {proto.financial.PaymentTrigger.PaymenttriggerCase}
 */
proto.financial.PaymentTrigger.prototype.getPaymenttriggerCase = function() {
  return /** @type {proto.financial.PaymentTrigger.PaymenttriggerCase} */(jspb.Message.computeOneofCase(this, proto.financial.PaymentTrigger.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PaymentTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PaymentTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PaymentTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PaymentTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetflow: (f = msg.getAssetflow()) && financial_assetflow_pb.Assetflow.toObject(includeInstance, f),
    call: (f = msg.getCall()) && financial_call_pb.Call.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PaymentTrigger}
 */
proto.financial.PaymentTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PaymentTrigger;
  return proto.financial.PaymentTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PaymentTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PaymentTrigger}
 */
proto.financial.PaymentTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_assetflow_pb.Assetflow;
      reader.readMessage(value,financial_assetflow_pb.Assetflow.deserializeBinaryFromReader);
      msg.setAssetflow(value);
      break;
    case 2:
      var value = new financial_call_pb.Call;
      reader.readMessage(value,financial_call_pb.Call.deserializeBinaryFromReader);
      msg.setCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PaymentTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PaymentTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PaymentTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PaymentTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetflow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_assetflow_pb.Assetflow.serializeBinaryToWriter
    );
  }
  f = message.getCall();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_call_pb.Call.serializeBinaryToWriter
    );
  }
};


/**
 * optional Assetflow assetflow = 1;
 * @return {?proto.financial.Assetflow}
 */
proto.financial.PaymentTrigger.prototype.getAssetflow = function() {
  return /** @type{?proto.financial.Assetflow} */ (
    jspb.Message.getWrapperField(this, financial_assetflow_pb.Assetflow, 1));
};


/**
 * @param {?proto.financial.Assetflow|undefined} value
 * @return {!proto.financial.PaymentTrigger} returns this
*/
proto.financial.PaymentTrigger.prototype.setAssetflow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.financial.PaymentTrigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PaymentTrigger} returns this
 */
proto.financial.PaymentTrigger.prototype.clearAssetflow = function() {
  return this.setAssetflow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PaymentTrigger.prototype.hasAssetflow = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Call call = 2;
 * @return {?proto.financial.Call}
 */
proto.financial.PaymentTrigger.prototype.getCall = function() {
  return /** @type{?proto.financial.Call} */ (
    jspb.Message.getWrapperField(this, financial_call_pb.Call, 2));
};


/**
 * @param {?proto.financial.Call|undefined} value
 * @return {!proto.financial.PaymentTrigger} returns this
*/
proto.financial.PaymentTrigger.prototype.setCall = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.financial.PaymentTrigger.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PaymentTrigger} returns this
 */
proto.financial.PaymentTrigger.prototype.clearCall = function() {
  return this.setCall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PaymentTrigger.prototype.hasCall = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.financial);
