import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AllFinancialInstrumentTypes,
  FinancialInstrumentViewModel,
  FinancialInstrumentViewModelInstrumentType,
  FinancialInstrumentViewUpdater,
  useFinancialInstrumentViewReaderRead,
} from "james/views/financialInstrumentView";
import { BPTable } from "components/Table";
import dayjs from "dayjs";
import { DateFormat } from "const/dateformats";
import {
  Close as ClearIcon,
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  FaceOutlined as FaceIcon,
  Refresh as ReloadIcon,
  RemoveRedEyeOutlined as ViewIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  AllAssetClasses,
  DigitalETFAction,
  DigitalETFState,
  DigitalETNAction,
  DigitalETNState,
  ETFAction,
  ETFStablecoinAction,
  ETFStablecoinState,
  ETFStablecoinStateChangerCoordinator,
  ETFState,
  ETNAction,
  ETNStablecoinAction,
  ETNStablecoinState,
  ETNStablecoinStateChangerCoordinator,
  ETNState,
  getPotentialNextDigitalETFActions,
  getPotentialNextDigitalETNActions,
  getPotentialNextETFActions,
  getPotentialNextETFStablecoinActions,
  getPotentialNextETNActions,
  getPotentialNextETNStablecoinActions,
  getPotentialNextUnitTrustActions,
  getPotentialNextUnitTrustStablecoinActions,
  UnitTrustAction,
  UnitTrustStablecoinAction,
  UnitTrustStablecoinState,
  UnitTrustStablecoinStateChangerCoordinator,
  UnitTrustState,
} from "james/financial";
import { DigitalETFStateChanger } from "james/financial/DigitalETFStateChanger";
import { NewSorting, Query } from "james/search/query";
import {
  DateRangeCriterion,
  TextExactCriterion,
  TextListCriterion,
  TextNEExactCriterion,
  TextNEExactCriterionTypeName,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  FinancialInstrumentState,
  AllFinancialInstrumentStates,
  DeletedFinancialInstrumentState,
} from "james/financial/InstrumentState";
import { InstrumentType } from "james/views/financialInstrumentView/Model";
import {
  FinancialCurrencyStablecoinViewModel,
  useFinancialCurrencyStablecoinViewReaderRead,
} from "james/views/financialCurrencyStablecoinView";
import { MintAssetDialog } from "components/Dialogs/Minting";
import { dateIsValid } from "utilities/date/dateIsValid";
import { InstrumentStateChip, PlacementStateChip } from "./Chips";
import { DateField } from "components/FormFields";
import { DateRangeValue } from "james/search/criterion/date/Range";
import { TextExactCriterionType } from "james/search/criterion/text/Exact";
import { formatTextNum } from "utilities/number";
import { WarningDialogOptions } from "./common";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { DigitalETNStateChanger } from "james/financial/DigitalETNStateChanger";
import { TokenInfo } from "components/Ledger/Token";
import { InstrumentsViewPaths } from "./Instruments";
import { useApplicationContext } from "context/Application/Application";
import { ListingState } from "james/market/Listing";
import { Token } from "james/ledger";
import { ListingStateController } from "james/market";
import { CapturePriceDialog } from "views/Marketplace/components/CapturePriceDialog";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";

const PREFIX = "Table";

const classes = {
  noInstrumentsSplashCardContent: `${PREFIX}-noInstrumentsSplashCardContent`,
  noInstrumentsSplashBody: `${PREFIX}-noInstrumentsSplashBody`,
  noInstrumentsSplashCardTitle: `${PREFIX}-noInstrumentsSplashCardTitle`,
  noInstrumentsSplashFaceIcon: `${PREFIX}-noInstrumentsSplashFaceIcon`,
  filterField: `${PREFIX}-filterField`,
  clearIcon: `${PREFIX}-clearIcon`,
  stablecoinCellLayout: `${PREFIX}-stablecoinCellLayout`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.noInstrumentsSplashCardContent}`]: {
    height: "calc(100vh - 190px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.noInstrumentsSplashBody}`]: {
    display: "grid",
    gridTemplateColumns: "auto",
    justifyItems: "center",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.noInstrumentsSplashCardTitle}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.noInstrumentsSplashFaceIcon}`]: {
    fontSize: 110,
    color: alpha(theme.palette.background.default, 0.5),
  },

  [`& .${classes.filterField}`]: {
    width: 200,
  },

  [`& .${classes.clearIcon}`]: {
    marginRight: -6,
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.stablecoinCellLayout}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1),
  },
}));

const AllFinancialInstrumentStatesMinusDeleted =
  AllFinancialInstrumentStates.filter(
    (s) => s !== DeletedFinancialInstrumentState,
  );

const excludeDeletedCriteria = {
  instrumentState: TextNEExactCriterion(DeletedFinancialInstrumentState),
};

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export function Table() {
  const { errorContextErrorTranslator } = useErrorContext();
  const navigate = useNavigate();
  const { authContext, viewConfiguration } = useApplicationContext();
  const { readRequest, readResponse, setReadRequest, loading } =
    useFinancialInstrumentViewReaderRead({
      context: authContext,
      criteria: {
        ...excludeDeletedCriteria,
      },
      query: new Query(initialQuery),
    });
  const { readResponse: currencyStablecoinViewReadResponse } =
    useFinancialCurrencyStablecoinViewReaderRead({
      context: authContext,
      criteria: {},
    });
  const [apiLoading, setAPILoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedModels, setSelectedModels] = useState<
    FinancialInstrumentViewModel[]
  >([]);
  const [nameShortNameCriterion, setNameShortNameCriterion] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any>(null);
  const [issueDateCriterionFrom, setIssueDateCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [issueDateCriterionTo, setIssueDateCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [maturityDateCriterionFrom, setMaturityDateCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [maturityDateCriterionTo, setMaturityDateCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [otherCriterion, setOtherCriterion] = useState<any>({});
  const [showMintAssetDialog, setShowMintAssetDialog] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const [showPriceCaptureScreen, setShowPriceCaptureScreen] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {
      ...excludeDeletedCriteria,
      ...otherCriterion,
    };

    if (nameShortNameCriterion) {
      criteria = {
        ...criteria,
        ...nameShortNameCriterion,
      };
    }

    if (issueDateCriterionFrom || issueDateCriterionTo) {
      criteria.instrumentIssuanceDate = DateRangeCriterion(
        issueDateCriterionFrom,
        issueDateCriterionTo,
      );
    }
    if (maturityDateCriterionFrom || maturityDateCriterionTo) {
      criteria.instrumentMaturityDate = DateRangeCriterion(
        maturityDateCriterionFrom,
        maturityDateCriterionTo,
      );
    }

    setReadRequest({
      context: readRequest.context,
      query: new Query(initialQuery),
      criteria,
    });
  }, [
    issueDateCriterionFrom,
    issueDateCriterionTo,
    maturityDateCriterionFrom,
    maturityDateCriterionTo,
    otherCriterion,
    readRequest.context,
    setReadRequest,
    authContext,
    nameShortNameCriterion,
  ]);

  // access necessary view permissions
  const instrumentsViewConfig = viewConfiguration.Instruments
    ? viewConfiguration.Instruments
    : {};
  const instrumentActionsViewConfig = instrumentsViewConfig.InstrumentActions
    ? instrumentsViewConfig.InstrumentActions
    : {};
  const instrumentsViewModelConfig = instrumentsViewConfig.ViewModel
    ? instrumentsViewConfig.ViewModel
    : {};
  const marketAssetViewConfig = instrumentsViewConfig.MarketAssetActions
    ? instrumentsViewConfig.MarketAssetActions
    : {};

  // function to return a placement retry button
  const retryPlacementButton = useCallback(
    (assetToken: Token) => (
      <Button
        id="instrumentsTable-retryPlacement-button"
        variant="outlined"
        children="Retry Placement"
        disabled={apiLoading}
        onClick={async () => {
          setAPILoading(true);
          try {
            // invoke call to retry asset listing
            await ListingStateController.RetryListAsset({
              context: authContext,
              assetToken,
            });

            // notify that listing is in progress
            enqueueSnackbar(
              "Listing is in Progress - Refresh Table to Monitor Progress",
              { variant: "info" },
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(`error retrying`, e);
            enqueueSnackbar(`Error Retrying Listing: ${err.message}`, {
              variant: "error",
            });
          }
          setAPILoading(false);
        }}
      />
    ),
    [authContext, apiLoading],
  );

  const handleFullUpdate = async () => {
    setAPILoading(true);
    try {
      await FinancialInstrumentViewUpdater.FullUpdate({
        context: authContext,
      });
      setReadRequest({
        ...readRequest,
        query: new Query(initialQuery),
      });
      enqueueSnackbar("Full Update Complete", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error performing full update: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Performing Full Update: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  };

  const getDigitalETFActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // access digitalETFActions view config
    const digitalETFActionsViewConfig = instrumentsViewConfig.DigitalETFActions
      ? instrumentsViewConfig.DigitalETFActions
      : {};

    // get potential next actions
    const nextPotentialActions = getPotentialNextDigitalETFActions(
      selectedModels[0].instrumentState as DigitalETFState,
    );

    //
    // determine if user should see edit icon button
    //
    if (
      // if user has view permission for update
      digitalETFActionsViewConfig.Update && // AND
      [
        // at least 1 of the following DigitalETF actions are
        // among the nextPotentialActions at this state
        DigitalETFAction.DraftUpdate,
        DigitalETFAction.IncreaseMaximumUnits,
        DigitalETFAction.DecreaseMaximumUnits,
        DigitalETFAction.ChangeMaturityDate,
        DigitalETFAction.ChangeHoldings,
        DigitalETFAction.ChangeSectorAllocations,
        DigitalETFAction.ChangeCountryAllocations,
      ].some((v) => nextPotentialActions.includes(v))
    ) {
      // then show the edit icon
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Edit" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-editDigitalETF-button"
                size="small"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.DigitalETF}?&id=${selectedModels[0].instrumentID}&edit=true`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    //
    // determine if user should see the place (aka. list) button
    //
    if (marketAssetViewConfig.List && selectedModels[0].readyForPlacement) {
      switch (selectedModels[0].listingState) {
        case "":
          prioritisedButtonActions.push({
            node: (
              <Button
                id="instrumentsTable-placeDigitalETF-button"
                variant="outlined"
                children="place"
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.PlaceDigitalInstrument}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              />
            ),
            priority: 1,
          });
          break;

        case ListingState.ListingFailed:
          prioritisedButtonActions.push({
            node: retryPlacementButton(selectedModels[0].unitsIssued.token),
            priority: 1,
          });
          break;
      }
    }

    //
    // other action buttons
    //
    nextPotentialActions.forEach((a) => {
      switch (a) {
        case DigitalETFAction.MarkDeleted:
          if (digitalETFActionsViewConfig.ChangeState) {
            // then show the edit icon
            prioritisedIconActions.push({
              node: (
                <Tooltip title="Delete Draft" placement="top">
                  <span>
                    <IconButton
                      id="instrumentsTable-deleteDraftDigitalETF-button"
                      size="small"
                      disabled={apiLoading}
                      onClick={() =>
                        setWarningDialogOptions({
                          yesMethod: async () => {
                            // user confirms deletion
                            setAPILoading(true);

                            // perform mark deleted
                            try {
                              await DigitalETFStateChanger.MarkDigitalETFDeleted(
                                {
                                  context: authContext,
                                  digitalETFID: selectedModels[0].instrumentID,
                                },
                              );
                              enqueueSnackbar("Instrument Deleted", {
                                variant: "success",
                              });
                              setReadRequest({
                                ...readRequest,
                                query: new Query(initialQuery),
                              });
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              console.error(`error deleting instrument`, e);
                              enqueueSnackbar(
                                `Error Deleting Instrument: ${err.message}`,
                                { variant: "error" },
                              );
                            }

                            setWarningDialogOptions(null);
                            setAPILoading(false);
                          },
                          // user cancels deletion
                          noMethod: () => setWarningDialogOptions(null),
                          title: "Delete Draft Instrument?",
                          messageParagraphs: [
                            `
                          Select Yes to confirm deletion of instrument.
                          `,
                          ],
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
              priority: 1,
            });
          }
          break;
      }
    });

    // if digital etf is issued
    if (
      (selectedModels[0].instrumentState as DigitalETFState) ===
      DigitalETFState.Issued
    ) {
      // and the executing user has the Mint view configuration
      if (digitalETFActionsViewConfig.Mint) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              id="instrumentsTable-mintDigitalETF-button"
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // add view button
    if (
      (selectedModels[0].instrumentState as DigitalETFState) !==
      DigitalETFState.Deleted
    ) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="View" placement="top">
            <span>
              <IconButton
                size="small"
                id="instrumentsTable-viewDigitalETF-button"
                disabled={apiLoading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.DigitalETF}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 3,
      });
    }

    return [
      // buttons before icons
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),

      // icons after buttons
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  const getETFActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // access digitalETFActions view config
    const etfActionsViewConfig = instrumentsViewConfig.ETFActions
      ? instrumentsViewConfig.ETFActions
      : {};
    const etfStablecoinActionsViewConfig =
      instrumentsViewConfig.ETFStablecoinActions
        ? instrumentsViewConfig.ETFStablecoinActions
        : {};

    // get potential next actions
    const nextPotentialETFActions = getPotentialNextETFActions(
      selectedModels[0].instrumentState as ETFState,
    );
    const nextPotentialETFStablecoinActions =
      getPotentialNextETFStablecoinActions(
        selectedModels[0].instrumentState as ETFStablecoinState,
      );

    //
    // determine if user should see edit icon button
    //
    if (
      // if user has view permission for update
      etfActionsViewConfig.Update &&
      etfStablecoinActionsViewConfig.Update && // AND
      [
        // at least 1 of the following ETF actions are
        // among the nextPotentialActions at this state
        ETFAction.DraftUpdate,
        ETFAction.ChangeMaturityDate,
        ETFAction.ChangeHoldings,
        ETFAction.ChangeSectorAllocations,
        ETFAction.ChangeCountryAllocations,
      ].some((v: ETFAction) => nextPotentialETFActions.includes(v))
    ) {
      // then show the edit icon
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Edit" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-editETF-button"
                size="small"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToETF}?&id=${selectedModels[0].instrumentID}&edit=true`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    //
    // determine if user should see the place (aka. list) button
    //
    if (marketAssetViewConfig.List && selectedModels[0].readyForPlacement) {
      switch (selectedModels[0].listingState) {
        case "":
          prioritisedButtonActions.push({
            node: (
              <Button
                variant="outlined"
                id="instrumentsTable-placeETF-button"
                children="place"
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              />
            ),
            priority: 1,
          });
          break;

        case ListingState.ListingFailed:
          prioritisedButtonActions.push({
            node: retryPlacementButton(selectedModels[0].unitsIssued.token),
            priority: 1,
          });
          break;
      }
    }

    nextPotentialETFStablecoinActions.forEach(
      (nextPotentialETFStablecoinAction: ETFStablecoinAction) => {
        switch (nextPotentialETFStablecoinAction) {
          case ETFStablecoinAction.MarkDeleted:
            if (etfActionsViewConfig.ChangeState) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="instrumentsTable-deleteDraftETF-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              try {
                                await ETFStablecoinStateChangerCoordinator.CoordinateMarkETFStablecoinDeleted(
                                  {
                                    context: authContext,
                                    etfStablecoinID:
                                      selectedModels[0].instrumentID,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Deleted",
                                  { variant: "success" },
                                );
                                setReadRequest({
                                  ...readRequest,
                                  query: new Query(initialQuery),
                                });
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting rights to instrument`,
                                  e,
                                );
                                enqueueSnackbar(
                                  `Error Deleting Rights to Instrument: ${err.message}`,
                                  { variant: "error" },
                                );
                              }

                              setWarningDialogOptions(null);
                              setAPILoading(false);
                            },
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Rights to Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of rights to instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
            break;
        }
      },
    );

    // if etf stablecoin is issued
    if (
      (selectedModels[0].instrumentState as ETFStablecoinState) ===
      ETFStablecoinState.Issued
    ) {
      // and the executing user has the Mint view configuration
      if (etfStablecoinActionsViewConfig.Mint) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              id="instrumentsTable-mintETF-button"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // add view button
    if ((selectedModels[0].instrumentState as ETFState) !== ETFState.Deleted) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="View" placement="top">
            <span>
              <IconButton
                size="small"
                id="instrumentsTable-viewETF-button"
                disabled={apiLoading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToETF}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 3,
      });
    }

    return [
      // buttons before icons
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),

      // icons after buttons
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  const getDigitalETNActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // access digitalETNActions view config
    const digitalETNActionsViewConfig = instrumentsViewConfig.DigitalETNActions
      ? instrumentsViewConfig.DigitalETNActions
      : {};

    // get potential next actions
    const nextPotentialActions = getPotentialNextDigitalETNActions(
      selectedModels[0].instrumentState as DigitalETNState,
    );

    //
    // determine if user should see edit icon button
    //
    if (
      // if user has view permission for update
      digitalETNActionsViewConfig.Update && // AND
      [
        // at least 1 of the following DigitalETN actions are
        // among the nextPotentialActions at this state
        DigitalETNAction.DraftUpdate,
        DigitalETNAction.IncreaseMaximumUnits,
        DigitalETNAction.DecreaseMaximumUnits,
        DigitalETNAction.ChangeMaturityDate,
        DigitalETNAction.ChangeHoldings,
        DigitalETNAction.ChangeSectorAllocations,
        DigitalETNAction.ChangeCountryAllocations,
      ].some((v) => nextPotentialActions.includes(v))
    ) {
      // then show the edit icon
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Edit" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-editDigitalETN-button"
                size="small"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.DigitalETN}?&id=${selectedModels[0].instrumentID}&edit=true`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    //
    // determine if user should see the place (aka. list) button
    //
    if (marketAssetViewConfig.List && selectedModels[0].readyForPlacement) {
      switch (selectedModels[0].listingState) {
        case "":
          prioritisedButtonActions.push({
            node: (
              <Button
                id="instrumentsTable-placeDigitalETN-button"
                variant="outlined"
                children="place"
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.PlaceDigitalInstrument}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              />
            ),
            priority: 1,
          });
          break;

        case ListingState.ListingFailed:
          prioritisedButtonActions.push({
            node: retryPlacementButton(selectedModels[0].unitsIssued.token),
            priority: 1,
          });
          break;
      }
    }

    //
    // other action buttons
    //
    nextPotentialActions.forEach((a) => {
      switch (a) {
        case DigitalETNAction.MarkDeleted:
          if (digitalETNActionsViewConfig.ChangeState) {
            // then show the edit icon
            prioritisedIconActions.push({
              node: (
                <Tooltip title="Delete Draft" placement="top">
                  <span>
                    <IconButton
                      id="instrumentsTable-deleteDraftDigitalETN-button"
                      size="small"
                      disabled={apiLoading}
                      onClick={() =>
                        setWarningDialogOptions({
                          yesMethod: async () => {
                            // user confirms deletion
                            setAPILoading(true);

                            // perform mark deleted
                            try {
                              await DigitalETNStateChanger.MarkDigitalETNDeleted(
                                {
                                  context: authContext,
                                  digitalETNID: selectedModels[0].instrumentID,
                                },
                              );

                              enqueueSnackbar("Instrument Deleted", {
                                variant: "success",
                              });
                              setReadRequest({
                                ...readRequest,
                                query: new Query(initialQuery),
                              });
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              console.error(`error deleting instrument`, e);
                              enqueueSnackbar(
                                `Error Deleting Instrument: ${err.message}`,
                                { variant: "error" },
                              );
                            }

                            setWarningDialogOptions(null);
                            setAPILoading(false);
                          },
                          // user cancels deletion
                          noMethod: () => setWarningDialogOptions(null),
                          title: "Delete Draft Instrument?",
                          messageParagraphs: [
                            `
                          Select Yes to confirm deletion of instrument.
                          `,
                          ],
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
              priority: 1,
            });
          }
          break;
      }
    });

    // if digital etn is issued
    if (
      (selectedModels[0].instrumentState as DigitalETNState) ===
      DigitalETNState.Issued
    ) {
      // and the executing user has the Mint view configuration
      if (digitalETNActionsViewConfig.Mint) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              id="instrumentsTable-mintDigitalETN-button"
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // add view button
    if (
      (selectedModels[0].instrumentState as DigitalETNState) !==
      DigitalETNState.Deleted
    ) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="View" placement="top">
            <span>
              <IconButton
                size="small"
                id="instrumentsTable-viewDigitalETN-button"
                disabled={apiLoading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.DigitalETN}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 3,
      });
    }

    return [
      // buttons before icons
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),

      // icons after buttons
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  const getETNActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // access digitalETNActions view config
    const etnActionsViewConfig = instrumentsViewConfig.ETNActions
      ? instrumentsViewConfig.ETNActions
      : {};
    const etnStablecoinActionsViewConfig =
      instrumentsViewConfig.ETNStablecoinActions
        ? instrumentsViewConfig.ETNStablecoinActions
        : {};

    // get potential next actions
    const nextPotentialETNActions = getPotentialNextETNActions(
      selectedModels[0].instrumentState as ETNState,
    );
    const nextPotentialETNStablecoinActions =
      getPotentialNextETNStablecoinActions(
        selectedModels[0].instrumentState as ETNStablecoinState,
      );

    //
    // determine if user should see edit icon button
    //
    if (
      // if user has view permission for update
      etnActionsViewConfig.Update &&
      etnStablecoinActionsViewConfig.Update && // AND
      [
        // at least 1 of the following ETN actions are
        // among the nextPotentialActions at this state
        ETNAction.DraftUpdate,
        ETNAction.ChangeMaturityDate,
        ETNAction.ChangeHoldings,
        ETNAction.ChangeSectorAllocations,
        ETNAction.ChangeCountryAllocations,
      ].some((v: ETNAction) => nextPotentialETNActions.includes(v))
    ) {
      // then show the edit icon
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Edit" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-editETN-button"
                size="small"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToETN}?&id=${selectedModels[0].instrumentID}&edit=true`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    //
    // determine if user should see the place (aka. list) button
    //
    if (marketAssetViewConfig.List && selectedModels[0].readyForPlacement) {
      switch (selectedModels[0].listingState) {
        case "":
          prioritisedButtonActions.push({
            node: (
              <Button
                id="instrumentsTable-placeETN-button"
                variant="outlined"
                children="place"
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              />
            ),
            priority: 1,
          });
          break;

        case ListingState.ListingFailed:
          prioritisedButtonActions.push({
            node: retryPlacementButton(selectedModels[0].unitsIssued.token),
            priority: 1,
          });
          break;
      }
    }

    nextPotentialETNStablecoinActions.forEach(
      (nextPotentialETNStablecoinAction: ETNStablecoinAction) => {
        switch (nextPotentialETNStablecoinAction) {
          case ETNStablecoinAction.MarkDeleted:
            if (etnActionsViewConfig.ChangeState) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="instrumentsTable-deleteDraftETN-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              // perform mark deleted
                              try {
                                await ETNStablecoinStateChangerCoordinator.CoordinateMarkETNStablecoinDeleted(
                                  {
                                    context: authContext,
                                    etnStablecoinID:
                                      selectedModels[0].instrumentID,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Deleted",
                                  { variant: "success" },
                                );
                                setReadRequest({
                                  ...readRequest,
                                  query: new Query(initialQuery),
                                });
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting rights to instrument`,
                                  e,
                                );
                                enqueueSnackbar(
                                  `Error Deleting Rights to Instrument: ${err.message}`,
                                  { variant: "error" },
                                );
                              }

                              setWarningDialogOptions(null);
                              setAPILoading(false);
                            },
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Rights to Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of rights to instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
            break;
        }
      },
    );

    // if etn stablecoin is issued
    if (
      (selectedModels[0].instrumentState as ETNStablecoinState) ===
      ETNStablecoinState.Issued
    ) {
      // and the executing user has the Mint view configuration
      if (etnStablecoinActionsViewConfig.Mint) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // add view button
    if ((selectedModels[0].instrumentState as ETNState) !== ETNState.Deleted) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="View" placement="top">
            <span>
              <IconButton
                size="small"
                id="instrumentsTable-viewETN-button"
                disabled={apiLoading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToETN}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 3,
      });
    }

    return [
      // buttons before icons
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),

      // icons after buttons
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  const getUnitTrustActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // access digitalUnitTrustActions view config
    const UnitTrustActionsViewConfig = instrumentsViewConfig.UnitTrustActions
      ? instrumentsViewConfig.UnitTrustActions
      : {};
    const UnitTrustStablecoinActionsViewConfig =
      instrumentsViewConfig.UnitTrustStablecoinActions
        ? instrumentsViewConfig.UnitTrustStablecoinActions
        : {};

    // get potential next actions
    const nextPotentialUnitTrustActions = getPotentialNextUnitTrustActions(
      selectedModels[0].instrumentState as UnitTrustState,
    );
    const nextPotentialUnitTrustStablecoinActions =
      getPotentialNextUnitTrustStablecoinActions(
        selectedModels[0].instrumentState as UnitTrustStablecoinState,
      );

    //
    // determine if user should see edit icon button
    //
    if (
      // if user has view permission for update
      UnitTrustActionsViewConfig.Update &&
      UnitTrustStablecoinActionsViewConfig.Update && // AND
      [
        // at least 1 of the following UnitTrust actions are
        // among the nextPotentialActions at this state
        UnitTrustAction.DraftUpdate,
        UnitTrustAction.ChangeMaturityDate,
        UnitTrustAction.ChangeHoldings,
        UnitTrustAction.ChangeSectorAllocations,
        UnitTrustAction.ChangeCountryAllocations,
      ].some((v: UnitTrustAction) => nextPotentialUnitTrustActions.includes(v))
    ) {
      // then show the edit icon
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Edit" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-editUnitTrust-button"
                size="small"
                disabled={loading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${selectedModels[0].instrumentID}&edit=true`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    //
    // determine if user should see the place (aka. list) button
    //
    if (marketAssetViewConfig.List && selectedModels[0].readyForPlacement) {
      switch (selectedModels[0].listingState) {
        case "":
          prioritisedButtonActions.push({
            node: (
              <Button
                variant="outlined"
                id="instrumentsTable-placeUnitTrust-button"
                children="place"
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              />
            ),
            priority: 1,
          });
          break;

        case ListingState.ListingFailed:
          prioritisedButtonActions.push({
            node: retryPlacementButton(selectedModels[0].unitsIssued.token),
            priority: 1,
          });
          break;
      }
    }

    nextPotentialUnitTrustStablecoinActions.forEach(
      (nextPotentialUnitTrustStablecoinAction: UnitTrustStablecoinAction) => {
        switch (nextPotentialUnitTrustStablecoinAction) {
          case UnitTrustStablecoinAction.MarkDeleted:
            if (UnitTrustActionsViewConfig.ChangeState) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="instrumentsTable-deleteDraftUnitTrust-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              // perform mark deleted
                              try {
                                await UnitTrustStablecoinStateChangerCoordinator.CoordinateMarkUnitTrustStablecoinDeleted(
                                  {
                                    context: authContext,
                                    unitTrustStablecoinID:
                                      selectedModels[0].instrumentID,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Deleted",
                                  { variant: "success" },
                                );
                                setReadRequest({
                                  ...readRequest,
                                  query: new Query(initialQuery),
                                });
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting rights to instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                enqueueSnackbar(
                                  `Error Deleting Rights to Instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                  { variant: "error" },
                                );
                              }

                              setWarningDialogOptions(null);
                              setAPILoading(false);
                            },
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Rights to Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of rights to instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
            break;
        }
      },
    );

    // if UnitTrust stablecoin is issued
    if (
      (selectedModels[0].instrumentState as UnitTrustStablecoinState) ===
      UnitTrustStablecoinState.Issued
    ) {
      // and the executing user has the Mint view configuration
      if (UnitTrustStablecoinActionsViewConfig.Mint) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              id="instrumentsTable-mintUnitTrust-button"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // add view button
    if (
      (selectedModels[0].instrumentState as UnitTrustState) !==
      UnitTrustState.Deleted
    ) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="View" placement="top">
            <span>
              <IconButton
                size="small"
                id="instrumentsTable-viewUnitTrust-button"
                disabled={apiLoading}
                onClick={() =>
                  navigate(
                    `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${selectedModels[0].instrumentID}`,
                  )
                }
              >
                <ViewIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 3,
      });
    }

    return [
      // buttons before icons
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),

      // icons after buttons
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  const getBondActions = () => {
    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];

    // add ability to record price if the bond is in an issued state
    if (selectedModels[0].instrumentState === UnitTrustState.Issued) {
      prioritisedIconActions.push({
        node: (
          <Tooltip title="Capture Price" placement="top">
            <span>
              <IconButton
                id="instrumentsTable-recordPrice-button"
                size="small"
                disabled={loading}
                onClick={() => setShowPriceCaptureScreen(true)}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        ),
        priority: 2,
      });
    }

    return [
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  // show that no instruments exist splash iff
  const showNoInstrumentsExistSplash =
    // there are no records
    readResponse.models.length === 0 && // AND
    // there is only 1 entry in read criteria
    Object.keys(readRequest.criteria).length === 1 && // AND
    readRequest.criteria.instrumentState && // it is for instrumentState // AND
    // it is to exclude deleted instruments
    readRequest.criteria.instrumentState.type === TextNEExactCriterionTypeName;

  return (
    <Root>
      {(() => {
        if (!(loading || apiLoading) && showNoInstrumentsExistSplash) {
          return (
            <Card>
              <CardHeader
                disableTypography
                title={
                  <div className={classes.noInstrumentsSplashCardTitle}>
                    <Typography variant="h5" children="Instrument List" />
                    <Grid container spacing={1}>
                      {instrumentsViewModelConfig.Update && (
                        <Grid item>
                          <Button
                            id="instrumentsTable-fullViewUpdate-button"
                            color="primary"
                            variant="contained"
                            children="full view update"
                            onClick={handleFullUpdate}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <IconButton
                          disabled={loading}
                          size="small"
                          id="instrumentsTable-instrumentListRefresh-button"
                          onClick={() =>
                            setReadRequest({
                              ...readRequest,
                              query: new Query(initialQuery),
                            })
                          }
                        >
                          <ReloadIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
              <CardContent className={classes.noInstrumentsSplashCardContent}>
                <div className={classes.noInstrumentsSplashBody}>
                  <FaceIcon className={classes.noInstrumentsSplashFaceIcon} />
                  <Typography
                    color="secondary"
                    variant="h4"
                    children="No Instruments Added Yet!"
                  />
                  <Typography
                    variant="body2"
                    children={
                      instrumentActionsViewConfig.Create
                        ? "You will see the list once you add an instrument"
                        : "You will see the list once instruments have been added"
                    }
                  />
                  {instrumentActionsViewConfig.Create && (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        children="build new"
                        id="instrumentsTable-noInstrumentsSplashBuildNew-button"
                        onClick={() => {
                          navigate(InstrumentsViewPaths.NewInstrument);
                        }}
                      />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          );
        }

        return (
          <BPTable
            height={window.innerHeight - 138 - noticeBannerHeight}
            title="Instruments"
            loading={loading || apiLoading}
            data={readResponse.models}
            totalNoRecords={readResponse.total}
            onSelectedDataChange={(data) =>
              setSelectedModels(data as FinancialInstrumentViewModel[])
            }
            query={readRequest.query}
            onQueryChange={(query) =>
              setReadRequest({
                ...readRequest,
                query,
              })
            }
            toolBarControls={(() => {
              const controls: React.ReactNode[] = [];

              // add control for view full update
              // only MC has the permissions required
              // to see this button
              if (instrumentsViewModelConfig.Update) {
                controls.push(
                  <Button
                    color="primary"
                    variant="contained"
                    id="instrumentsTable-instrumentViewModelFullUpdate-button"
                    children="full view update"
                    onClick={handleFullUpdate}
                  />,
                );
              }

              // buttons only visible when a single instrument is selected
              if (selectedModels.length === 1) {
                // consider the type of instrument that was selected
                switch (selectedModels[0].instrumentType) {
                  case FinancialInstrumentViewModelInstrumentType.DigitalETF:
                    // add actions associated with DigitalETF
                    getDigitalETFActions().forEach((a) => controls.push(a));
                    break;

                  case FinancialInstrumentViewModelInstrumentType.ETFStablecoin:
                    // add actions associated with ETF
                    getETFActions().forEach((a) => controls.push(a));
                    break;

                  case FinancialInstrumentViewModelInstrumentType.DigitalETN:
                    // add actions associated with DigitalETN
                    getDigitalETNActions().forEach((a) => controls.push(a));
                    break;

                  case FinancialInstrumentViewModelInstrumentType.ETNStablecoin:
                    // add actions associated with ETN
                    getETNActions().forEach((a) => controls.push(a));
                    break;

                  case FinancialInstrumentViewModelInstrumentType.UnitTrustStablecoin:
                    // add actions associated with UnitTrust
                    getUnitTrustActions().forEach((a) => controls.push(a));
                    break;

                  case FinancialInstrumentViewModelInstrumentType.Bond:
                    getBondActions().forEach((a) => controls.push(a));
                    break;
                }
              }

              // everyone can see the reload btn at all times
              controls.push(
                <Tooltip title="Refresh" placement="top">
                  <span>
                    <IconButton
                      disabled={loading}
                      size="small"
                      onClick={() =>
                        setReadRequest({
                          ...readRequest,
                          query: new Query(initialQuery),
                        })
                      }
                      id="instrumentsTable-tableRefresh-button"
                    >
                      <ReloadIcon />
                    </IconButton>
                  </span>
                </Tooltip>,
              );

              return controls;
            })()}
            filters={[
              <TextField
                variant="outlined"
                margin="dense"
                className={classes.filterField}
                id="instrumentsTable-nameShortNameFilter-textField"
                label="Name / Short Name"
                placeholder="Start Typing..."
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setNameShortNameCriterion(null);
                  } else {
                    setNameShortNameCriterion({
                      $or: [
                        { name: TextSubstringCriterion(e.target.value) },
                        { shortName: TextSubstringCriterion(e.target.value) },
                      ],
                    });
                  }
                }}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="instrumentsTable-instrumentType-autoComplete"
                disabled={loading}
                className={classes.filterField}
                options={AllFinancialInstrumentTypes}
                value={
                  otherCriterion.instrumentType
                    ? ((otherCriterion.instrumentType as TextExactCriterionType)
                        .text as InstrumentType)
                    : null
                }
                onChange={(_, selected: InstrumentType | null) => {
                  if (selected) {
                    setOtherCriterion({
                      ...otherCriterion,
                      instrumentType: TextExactCriterion(selected),
                    });
                  } else {
                    delete otherCriterion.instrumentType;
                    setOtherCriterion({ ...otherCriterion });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="instrumentsTable-instrumentType-autoCompleteTextField"
                    label="Type"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      otherCriterion.instrumentType ? undefined : "Select..."
                    }
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="instrumentsTable-instrumentState-autoComplete"
                disabled={loading}
                className={classes.filterField}
                options={AllFinancialInstrumentStatesMinusDeleted}
                value={
                  otherCriterion.instrumentState
                    ? ((
                        otherCriterion.instrumentState as TextExactCriterionType
                      ).text as Omit<FinancialInstrumentState, "Deleted">)
                    : null
                }
                onChange={(
                  _,
                  selected: Omit<FinancialInstrumentState, "Deleted"> | null,
                ) => {
                  if (selected) {
                    setOtherCriterion({
                      ...otherCriterion,
                      instrumentState: TextExactCriterion(selected.toString()),
                    });
                  } else {
                    delete otherCriterion.instrumentState;
                    setOtherCriterion({ ...otherCriterion });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="instrumentsTable-instrumentState-autoCompleteTextField"
                    className={classes.filterField}
                    label="State"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      otherCriterion.instrumentState ? undefined : "Select..."
                    }
                  />
                )}
              />,
              <DateField
                label="Issue Date"
                disabled={loading}
                id="instrumentsTable-issueDateFrom-dateField"
                className={classes.filterField}
                value={
                  issueDateCriterionFrom ? issueDateCriterionFrom.date : null
                }
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    setIssueDateCriterionFrom(undefined);
                    setIssueDateCriterionTo(undefined);
                  } else {
                    setIssueDateCriterionFrom(
                      newValue
                        ? {
                            date: newValue.startOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    );
                    setIssueDateCriterionTo(
                      newValue
                        ? {
                            date: newValue.endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    );
                  }
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (() => (
                        <>
                          {issueDateCriterionFrom && (
                            <Tooltip title="Clear" placement="top">
                              <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                  setIssueDateCriterionFrom(undefined);
                                  setIssueDateCriterionTo(undefined);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {textFieldProps.InputProps &&
                          textFieldProps.InputProps.endAdornment
                            ? textFieldProps.InputProps.endAdornment
                            : null}
                        </>
                      ))(),
                    }}
                  />
                )}
              />,
              <DateField
                label="Maturity Date"
                disabled={loading}
                id="instrumentsTable-maturityDateFrom-dateField"
                className={classes.filterField}
                value={
                  maturityDateCriterionFrom
                    ? maturityDateCriterionFrom.date
                    : null
                }
                onChange={(newValue) => {
                  if (!(newValue && dateIsValid(newValue))) {
                    setMaturityDateCriterionFrom(undefined);
                    setMaturityDateCriterionTo(undefined);
                  } else {
                    setMaturityDateCriterionFrom(
                      newValue
                        ? {
                            date: newValue.startOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    );
                    setMaturityDateCriterionTo(
                      newValue
                        ? {
                            date: newValue.endOf("day").format(),
                            inclusive: true,
                            ignore: false,
                          }
                        : undefined,
                    );
                  }
                }}
                renderInput={(textFieldProps: TextFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (() => (
                        <>
                          {maturityDateCriterionFrom && (
                            <Tooltip title="Clear" placement="top">
                              <IconButton
                                className={classes.clearIcon}
                                size="small"
                                onClick={() => {
                                  setMaturityDateCriterionFrom(undefined);
                                  setMaturityDateCriterionTo(undefined);
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {textFieldProps.InputProps &&
                          textFieldProps.InputProps.endAdornment
                            ? textFieldProps.InputProps.endAdornment
                            : null}
                        </>
                      ))(),
                    }}
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="instrumentsTable-valuationStablecoin-autoComplete"
                disabled={loading}
                className={classes.filterField}
                getOptionLabel={(
                  option: FinancialCurrencyStablecoinViewModel,
                ) => option.token.code}
                options={currencyStablecoinViewReadResponse.models}
                onChange={(
                  _,
                  selected: FinancialCurrencyStablecoinViewModel | null,
                ) => {
                  const updatedOtherCriterion = { ...otherCriterion };
                  if (selected) {
                    setOtherCriterion({
                      ...updatedOtherCriterion,
                      "valuationToken.code": TextExactCriterion(
                        selected.token.code,
                      ),
                      "valuationToken.issuer": TextExactCriterion(
                        selected.token.issuer,
                      ),
                      "valuationToken.network": TextExactCriterion(
                        selected.token.network,
                      ),
                    });
                  } else {
                    delete updatedOtherCriterion["token.valuationToken"];
                    delete updatedOtherCriterion["token.valuationToken"];
                    delete updatedOtherCriterion["token.valuationToken"];
                    setOtherCriterion({ ...updatedOtherCriterion });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="instrumentsTable-valuationStablecoin-autoCompleteTextField"
                    className={classes.filterField}
                    label="Stablecoin"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      otherCriterion.valuationTokenViewModel
                        ? undefined
                        : "Select..."
                    }
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="instrumentsTable-assetClass-autoComplete"
                disabled={loading}
                className={classes.filterField}
                options={AllAssetClasses}
                onChange={(_, selected: string | null) => {
                  if (selected) {
                    setOtherCriterion({
                      ...otherCriterion,
                      assetClass: TextExactCriterion(selected),
                    });
                  } else {
                    delete otherCriterion.assetClass;
                    setOtherCriterion({ ...otherCriterion });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="instrumentsTable-assetClass-autoCompleteTextField"
                    className={classes.filterField}
                    label="Asset Class"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      otherCriterion.assetClass ? undefined : "Select..."
                    }
                  />
                )}
              />,
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                id="instrumentsTable-placed-autoComplete"
                disabled={loading}
                className={classes.filterField}
                options={["Yes", "No"]}
                onChange={(_, selected: string | null) => {
                  if (selected) {
                    setOtherCriterion({
                      ...otherCriterion,
                      listingState:
                        selected === "Yes"
                          ? TextExactCriterion("Active")
                          : TextListCriterion([
                              "Inactive",
                              "Listing Failed",
                              "Listing Under Investigation",
                              "",
                            ]),
                    });
                  } else {
                    delete otherCriterion.listingState;
                    setOtherCriterion({ ...otherCriterion });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="instrumentsTable-placed-autoCompleteTextField"
                    className={classes.filterField}
                    label="Placement"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    placeholder={
                      otherCriterion.placed ? undefined : "Select..."
                    }
                  />
                )}
              />,
            ]}
            columns={[
              {
                field: "name",
                label: "Name",
                accessor: (data) => {
                  const { name } = data as FinancialInstrumentViewModel;
                  return <Box sx={{ mr: 1 }}>{name || "-"}</Box>;
                },
              },
              {
                field: "shortName",
                label: "Short Name",
                accessor: (data) => {
                  const { shortName } = data as FinancialInstrumentViewModel;
                  return <Box sx={{ mr: 1 }}>{shortName || "-"}</Box>;
                },
              },
              {
                field: "instrumentType",
                label: "Type",
              },
              {
                field: "assetClass",
                label: "Asset",
                accessor: (data) => {
                  const { assetClass } = data as FinancialInstrumentViewModel;
                  return assetClass || "-";
                },
              },
              {
                field: "instrumentIssuanceDate",
                label: "Issued",
                accessor: (data) =>
                  dayjs(
                    (data as FinancialInstrumentViewModel)
                      .instrumentIssuanceDate,
                  ).format(DateFormat),
              },
              {
                field: "instrumentMaturityDate",
                label: "Maturing",
                accessor: (data) =>
                  dayjs(
                    (data as FinancialInstrumentViewModel)
                      .instrumentMaturityDate,
                  ).format(DateFormat),
              },
              {
                field: "unitsIssued",
                label: "Issued Units",
                accessor: (data) =>
                  formatTextNum(
                    (data as FinancialInstrumentViewModel).unitsIssued.value,
                    { addDecimalPadding: true },
                  ),
              },
              {
                field: "maximumUnits",
                label: "Max Units",
                accessor: (data) =>
                  formatTextNum(
                    (data as FinancialInstrumentViewModel).maximumUnits.value,
                    { addDecimalPadding: true },
                  ),
              },
              {
                field: "valuationStablecoinID",
                label: "Stablecoin",
                accessor: (data) => {
                  const viewModel = data as FinancialInstrumentViewModel;
                  return viewModel.valuationToken ? (
                    <TokenInfo token={viewModel.valuationToken} />
                  ) : (
                    ""
                  );
                },
              },
              {
                field: "instrumentState",
                label: "State",
                accessor: (data) => (
                  <Box sx={{ mr: 1 }}>
                    <InstrumentStateChip
                      state={
                        (data as FinancialInstrumentViewModel).instrumentState
                      }
                    />
                  </Box>
                ),
              },
              {
                field: "placed",
                label: "Placed",
                accessor: (data) =>
                  (data as FinancialInstrumentViewModel).readyForPlacement ? (
                    <Box sx={{ mr: 1 }}>
                      <PlacementStateChip
                        listingState={
                          (data as FinancialInstrumentViewModel).listingState
                        }
                      />
                    </Box>
                  ) : (
                    "-"
                  ),
              },
            ]}
          />
        );
      })()}
      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onAwayClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
      />
      {showMintAssetDialog && (
        <MintAssetDialog
          onCloseClick={() => setShowMintAssetDialog(false)}
          assetToMintID={selectedModels[0].instrumentID}
        />
      )}
      {selectedModels[0] && showPriceCaptureScreen && (
        <CapturePriceDialog
          assetID={selectedModels[0].instrumentID}
          valuationToken={selectedModels[0].valuationToken}
          token={selectedModels[0].unitsIssued.token}
          assetIssueDate={selectedModels[0].instrumentIssuanceDate}
          assetName={selectedModels[0].name}
          open={showPriceCaptureScreen}
          handleClose={() => setShowPriceCaptureScreen(false)}
        />
      )}
    </Root>
  );
}
