import { AuditEntry } from "james/audit";
import dayjs from "dayjs";
import { Token } from "james/ledger/Token";
import { Asset } from "james/ledger/Asset";
import { Currency } from "./Currency";

export const CryptoCurrencyTypeName = "mesh::financial/CryptoCurrency";

export class CryptoCurrency implements Currency, Asset {
  public ["@type"]: string = CryptoCurrencyTypeName;

  public id = "";

  public ownerID = "";

  public name = "";

  public shortName = "";

  public token: Token = new Token();

  public issueDate: string = dayjs().format();

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(cryptoCurrency?: CryptoCurrency) {
    if (!cryptoCurrency) {
      return;
    }
    this.id = cryptoCurrency.id;
    this.ownerID = cryptoCurrency.ownerID;
    this.name = cryptoCurrency.name;
    this.shortName = cryptoCurrency.shortName;
    this.token = new Token(cryptoCurrency.token);
    this.issueDate = cryptoCurrency.issueDate;
    this.auditEntry = new AuditEntry(cryptoCurrency.auditEntry);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  currencyID(): string {
    return this.id;
  }

  currencyName(): string {
    return this.name;
  }

  currencyCode(): string {
    return this.token.code;
  }

  firstCutOffAfter(): string {
    throw new Error("not implemented");
  }

  firstCutOffBefore(): string {
    throw new Error("not implemented");
  }

  firstStartOfDayBefore(): string {
    throw new Error("not implemented");
  }
}
