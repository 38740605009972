import { ETF, ETFStablecoin } from "james/financial";
import dayjs from "dayjs";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import { StepValidationResult, TouchedFields } from "./common";
import { Mint } from "james/ledger";
import { dateIsValid } from "utilities/date/dateIsValid";
import BigNumber from "bignumber.js";
import { PercentageInstrumentManagementFee } from "james/financial/InstrumentManagementFeePercentage";

const big100 = new BigNumber("100");
const big0 = new BigNumber("0");

export function validateRights2ETFStep1(
  etf: ETF,
  etfStablecoin: ETFStablecoin,
  mint: Mint | undefined,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  // determine the validity of dates
  const etfStablecoinIssueDateValid = dateIsValid(etfStablecoin.issueDate);
  const etfStablecoinMaturityDateValid = dateIsValid(
    etfStablecoin.maturityDate,
  );
  const etfIssueDateValid = dateIsValid(etf.issueDate);
  const etfMaturityDateValid = dateIsValid(etf.maturityDate);

  // if all of the dates are valid
  if (
    etfStablecoinIssueDateValid &&
    etfStablecoinMaturityDateValid &&
    etfIssueDateValid &&
    etfMaturityDateValid
  ) {
    // then date validation can be done

    // parse dates required for validation
    const etfStablecoinIssueDateDayjs = dayjs(etfStablecoin.issueDate);
    const etfStablecoinMaturityDateDayjs = dayjs(etfStablecoin.maturityDate);
    const etfIssueDateDayjs = dayjs(etf.issueDate);
    const etfMaturityDateDayjs = dayjs(etf.maturityDate);
    const now = dayjs();

    //
    // etfStablecoin.issueDate
    //
    // if the issueDate is after the maturityDate
    if (etfStablecoinIssueDateDayjs.isAfter(etfStablecoinMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-issueDate"] =
          "Cannot be after maturity";
      }
    }
    // if the issueDate is before that of the reference etf issueDate
    if (etfStablecoinIssueDateDayjs.isBefore(etfIssueDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-issueDate"] =
          "Cannot be before underlying ETF issuance";
      }
    }

    //
    // etfStablecoin.maturityDate
    //
    // if the maturity date is in the past
    if (etfStablecoinMaturityDateDayjs.isBefore(now)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-maturityDate"] =
          "Cannot be in the past";
      }
    }
    // if the issueDate is after the maturityDate
    if (etfStablecoinIssueDateDayjs.isAfter(etfStablecoinMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-maturityDate"] =
          "Cannot be before issuance";
      }
    }
    // if the maturityDate is after that of the reference etf maturityDate
    if (etfStablecoinMaturityDateDayjs.isAfter(etfMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-maturityDate"] =
          "Cannot be after underlying ETF maturity";
      }
    }
  } else {
    // otherwise 1 or more are not valid - no further date validation can be done

    // show errors on fields with invalid dates

    // if the etfStablecoin issue date is not valid
    if (!etfStablecoinIssueDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-issueDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-issueDate"] =
          "Must be a valid date";
      }
    }

    // if the etfStablecoin maturityDate is not valid
    if (!etfStablecoinMaturityDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etfStablecoin-maturityDate"]
      ) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etfStablecoin-maturityDate"] =
          "Must be a valid date";
      }
    }
  }

  //
  // etfStablecoin.ownerID
  //
  // if ownerID is not set
  if (etfStablecoin.ownerID === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etfStablecoin-ownerID"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etfStablecoin-ownerID"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etfStablecoin.maximumUnits
  //
  // if maximumUnits is zero
  if (etfStablecoin.maximumUnits.value.lte(big0)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etfStablecoin-maximumUnits"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etfStablecoin-maximumUnits"] =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }
  // if maximumUnits is less than issued units
  if (mint && etfStablecoin.maximumUnits.value.lt(mint.issuedUnits.value)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etfStablecoin-maximumUnits"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etfStablecoin-maximumUnits"] =
        "Cannot be less than issued units";
    }
  }

  //
  // etfStablecoin.valuationToken
  //
  // if valuationToken token is not set
  if (etfStablecoin.valuationToken.isUndefined()) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etfStablecoin-valuationToken"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etfStablecoin-valuationToken"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  return validationResult;
}

export function validateRights2ETFStep2(
  etf: ETF,
  etfStablecoin: ETFStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  // determine the validity of dates
  const etfStablecoinIssueDateValid = dateIsValid(etfStablecoin.issueDate);
  const etfIssueDateValid = dateIsValid(etf.issueDate);
  const etfStablecoinMaturityDateValid = dateIsValid(
    etfStablecoin.maturityDate,
  );
  const etfMaturityDateValid = dateIsValid(etf.maturityDate);

  // if all of the dates are valid
  if (
    etfStablecoinIssueDateValid &&
    etfIssueDateValid &&
    etfStablecoinMaturityDateValid &&
    etfMaturityDateValid
  ) {
    // parse dates required for validation
    const etfStablecoinIssueDateDayjs = dayjs(etfStablecoin.issueDate);
    const etfIssueDateDayjs = dayjs(etf.issueDate);
    const etfStablecoinMaturityDateDayjs = dayjs(etfStablecoin.maturityDate);
    const etfMaturityDateDayjs = dayjs(etf.maturityDate);
    const now = dayjs();

    //
    // etf.issueDate
    //
    // if the issueDate is after the maturityDate
    if (etfIssueDateDayjs.isAfter(etfMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-issueDate"] =
          "Cannot be after maturity";
      }
    }
    // if the etfStablecoin.issueDate is before that of the etf issueDate
    if (etfStablecoinIssueDateDayjs.isBefore(etfIssueDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-issueDate"] =
          "Cannot be after digital instrument issuance";
      }
    }

    //
    // etf.maturityDate
    //
    // if the maturity date is in the past
    if (etfMaturityDateDayjs.isBefore(now)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-maturityDate"] =
          "Cannot be in the past";
      }
    }
    // if the issueDate is after the maturityDate
    if (etfIssueDateDayjs.isAfter(etfMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-maturityDate"] =
          "Cannot be before issuance";
      }
    }
    // if the etfStablecoin.maturityDate is after that of the etf maturityDate
    if (etfStablecoinMaturityDateDayjs.isAfter(etfMaturityDateDayjs)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-maturityDate"] =
          "Cannot be before digital instrument maturity";
      }
    }
  } else {
    // otherwise 1 or more are not valid - no further date validation can be done

    // show errors on fields with invalid dates

    // if the etf issueDate is not valid
    if (!etfIssueDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-issueDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-issueDate"] =
          "Must be a valid date";
      }
    }

    // if the etf maturityDate is not valid
    if (!etfMaturityDateValid) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the field has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-maturityDate"]) {
        // then an error message should be shown on it
        validationResult.fieldValidations["etf-maturityDate"] =
          "Must be a valid date";
      }
    }
  }

  //
  // etf.name
  //
  // if name is not set
  if (etf.name === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-name"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-name"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  } else if (etf.name.length < 3) {
    // otherwise if name is less than 3 characters long

    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-name"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-name"] =
        "Cannot be less than 3 characters";
    }
  }

  //
  // etf.assetClass
  //
  if (etf.assetClass === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-assetClass"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-assetClass"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etf.issuerName
  //
  if (etf.issuerName === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-issuerName"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-issuerName"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // unitTrust.issuerManagementFee.percentage
  //
  if (
    etf.issuerManagementFee instanceof PercentageInstrumentManagementFee &&
    etf.issuerManagementFee.percentage.isGreaterThan(big100)
  ) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etf-issuerManagementFee"]
    ) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-issuerManagementFeePercentage"] =
        "Cannot be more than 100";
    }
  }

  return validationResult;
}

export function validateRights2ETFStep3(
  etf: ETF,
  etfStablecoin: ETFStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  //
  // etf.investorProfile
  //
  // if investorProfile is not set
  if (etf.investorProfile === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-investorProfile"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-investorProfile"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etf.instrumentRiskProfile
  //
  // if instrumentRiskProfile is not set
  if (etf.riskProfile === "") {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the field has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-riskProfile"]) {
      // then an error message should be shown on it
      validationResult.fieldValidations["etf-riskProfile"] =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  //
  // etf.countryAllocations
  //
  // prepare variables for validation
  const usedCountryAllocations: { [key: string]: boolean } = {};
  let totalCountryAllocation = new BigNumber("0");

  // for every countryAllocation...
  etf.countryAllocations.forEach((c, i) => {
    // if the name is not set
    if (c.name === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the countryAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etf-countryAllocations"]
      ) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etf-countryAllocations-${i}-name`] =
          "Country code cannot be blank";
        validationResult.fieldValidations["etf-countryAllocations"] =
          "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedCountryAllocations[c.name.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the countryAllocations section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields["etf-countryAllocations"]
        ) {
          // then an error message should be shown on the specific countryAllocation where the problem is
          validationResult.fieldValidations[
            `etf-countryAllocations-${i}-name`
          ] = "Entry with this name already exists";
          validationResult.fieldValidations["etf-countryAllocations"] =
            "Error in list";
        }
      } else {
        // name is unique - record used
        usedCountryAllocations[c.name.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the countryAllocations <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the countryAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etf-countryAllocations"]
      ) {
        // then an error message should be shown on the specific countryAllocation where the problem is
        validationResult.fieldValidations[
          `etf-countryAllocations-${i}-percentage`
        ] = "Percentage must be greater than 0";
        validationResult.fieldValidations["etf-countryAllocations"] =
          "Error in list";
      }
    }

    // add to total amount for total checks
    totalCountryAllocation = totalCountryAllocation.plus(c.percentage);
  });

  // if the sum of all countryAllocation percentages exceeds 100
  if (totalCountryAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the countryAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etf-countryAllocations"]
    ) {
      // then an error message should be shown on the countryAllocation section
      validationResult.fieldValidations["etf-countryAllocations"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no countryAllocations are set
  if (etf.countryAllocations.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the countryAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etf-countryAllocations"]
    ) {
      // then an error message should be shown on the countryAllocation section
      validationResult.fieldValidations["etf-countryAllocations"] =
        "At least 1 is required";
    }
  }

  //
  // etf.holdings
  //
  // prepare variables for validation
  const usedHoldingNames: { [key: string]: boolean } = {};
  let totalHoldingAllocation = new BigNumber("0");

  // for every holding...
  etf.holdings.forEach((c, i) => {
    // if the name is not set
    if (c.name === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the holdings section has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-holdings"]) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etf-holdings-${i}-name`] =
          "Holding name cannot be blank";
        validationResult.fieldValidations["etf-holdings"] = "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedHoldingNames[c.name.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the holdings section has been touched
        if (addAllFieldValidationsErrors || touchedFields["etf-holdings"]) {
          // then an error message should be shown on the specific holding where the problem is
          validationResult.fieldValidations[`etf-holdings-${i}-name`] =
            "Entry with this name already exists";
          validationResult.fieldValidations["etf-holdings"] = "Error in list";
        }
      } else {
        // name is unique - record used
        usedHoldingNames[c.name.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the holdings is <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the holdings section has been touched
      if (addAllFieldValidationsErrors || touchedFields["etf-holdings"]) {
        // then an error message should be shown on the specific holding where the problem is
        validationResult.fieldValidations[`etf-holdings-${i}-percentage`] =
          "Percentage must be greater than 0";
        validationResult.fieldValidations["etf-holdings"] = "Error in list";
      }
    }

    // add to total amount for total checks
    totalHoldingAllocation = totalHoldingAllocation.plus(c.percentage);
  });

  // if the sum of all holding percentages exceeds 100
  if (totalHoldingAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the holdings section has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-holdings"]) {
      // then an error message should be shown on the holding section
      validationResult.fieldValidations["etf-holdings"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no holdings are set
  if (etf.holdings.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the holdings section has been touched
    if (addAllFieldValidationsErrors || touchedFields["etf-holdings"]) {
      // then an error message should be shown on the holding section
      validationResult.fieldValidations["etf-holdings"] =
        "At least 1 is required";
    }
  }

  //
  // etf.sectorAllocations
  //
  // prepare variables for validation
  const usedSectorAllocations: { [key: string]: boolean } = {};
  let totalSectorAllocation = new BigNumber("0");

  // for every sectorAllocation...
  etf.sectorAllocations.forEach((c, i) => {
    // if the name is not set
    if (c.sector === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the sectorAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etf-sectorAllocations"]
      ) {
        // then an error message should be shown on the specific allocation where the problem is
        validationResult.fieldValidations[`etf-sectorAllocations-${i}-name`] =
          "Sector name cannot be blank";
        validationResult.fieldValidations["etf-sectorAllocations"] =
          "Error in list";
      }
    } else {
      // otherwise, if the name is set...

      // and it is not unique
      if (usedSectorAllocations[c.sector.toLowerCase()]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the sectorAllocations section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields["etf-sectorAllocations"]
        ) {
          // then an error message should be shown on the specific sectorAllocation where the problem is
          validationResult.fieldValidations[`etf-sectorAllocations-${i}-name`] =
            "Entry with this name already exists";
          validationResult.fieldValidations["etf-sectorAllocations"] =
            "Error in list";
        }
      } else {
        // name is unique - record used
        usedSectorAllocations[c.sector.toLowerCase()] = true;
      }
    }

    // if the percentage of any of the sectorAllocations is <= 0
    if (c.percentage.lte(big0)) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the sectorAllocations section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields["etf-sectorAllocations"]
      ) {
        // then an error message should be shown on the specific sectorAllocation where the problem is
        validationResult.fieldValidations[
          `etf-sectorAllocations-${i}-percentage`
        ] = "Percentage must be greater than 0";
        validationResult.fieldValidations["etf-sectorAllocations"] =
          "Error in list";
      }
    }

    // add to total amount for total checks
    totalSectorAllocation = totalSectorAllocation.plus(c.percentage);
  });

  // if the sum of all sectorAllocation percentages exceeds 100
  if (totalSectorAllocation.isGreaterThan(big100)) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the sectorAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etf-sectorAllocations"]
    ) {
      // then an error message should be shown on the sectorAllocation section
      validationResult.fieldValidations["etf-sectorAllocations"] =
        "Sum cannot exceed 100%";
    }
  }

  // if no sectorAllocations are set
  if (etf.sectorAllocations.length === 0) {
    // then the step is not complete
    validationResult.stepComplete = false;

    // and if the sectorAllocations section has been touched
    if (
      addAllFieldValidationsErrors ||
      touchedFields["etf-sectorAllocations"]
    ) {
      // then an error message should be shown on the sectorAllocation section
      validationResult.fieldValidations["etf-sectorAllocations"] =
        "At least 1 is required";
    }
  }

  //
  // etf.annualPerformanceLog
  //
  // prepare variables for validation
  const usedYears: { [key: number]: boolean } = {};

  // for every performance log entry
  etf.annualPerformanceLog.forEach((pl, i) => {
    // if the year is not appropriately set
    if (pl.year <= 0) {
      // then the step is not complete
      validationResult.stepComplete = false;

      // and if the annualPerformanceLog section has been touched
      if (addAllFieldValidationsErrors || touchedFields.annualPerformanceLog) {
        // then an error message should be shown on the specific entry where the problem is
        validationResult.fieldValidations[`annualPerformanceLog-${i}-year`] =
          "Year cannot be <= 0";
        validationResult.fieldValidations.annualPerformanceLog =
          "Error in list";
      }
    } else {
      // otherwise, if the year is set appropriately

      // and it is not unique
      if (usedYears[pl.year]) {
        // then the step is not complete
        validationResult.stepComplete = false;

        // and if the annualPerformanceLog section has been touched
        if (
          addAllFieldValidationsErrors ||
          touchedFields.annualPerformanceLog
        ) {
          // then an error message should be shown on the specific sectorAllocation where the problem is
          validationResult.fieldValidations[`annualPerformanceLog-${i}-year`] =
            "Year already used";
          validationResult.fieldValidations.annualPerformanceLog =
            "Error in list";
        }
      } else {
        // year is unique - record used
        usedYears[pl.year] = true;

        for (let idx = 0; idx < 12; idx++) {
          if (pl.monthlyPerformance[idx + 1]) {
            if (pl.monthlyPerformance[idx + 1] === "NaN") {
              validationResult.stepComplete = false;
              validationResult.fieldValidations[
                `annualPerformanceLog-${i}-year-${idx + 1}-month`
              ] = "invalid";
              validationResult.fieldValidations[
                `annualPerformanceLog-${i}-year`
              ] = "All fields must be numeric";
            }
          }
        }
      }
    }
  });

  return validationResult;
}

export function validateRightsToETFSupportingDocuments(
  etf: ETF,
  etfStablecoin: ETFStablecoin,
  touchedFields: TouchedFields,
  addAllFieldValidationsErrors: boolean,
): StepValidationResult {
  // prepare step validation result
  const validationResult: StepValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    stepComplete: true,
    // contains field validations
    fieldValidations: {},
  };

  //
  // etf.supportingDocuments
  //
  // for every supporting document
  etf.supportingDocuments.forEach((doc, i) => {
    // if description is not set
    if (doc.description === "") {
      // then the step is not complete
      validationResult.stepComplete = false;

      // indicate that some descriptions are missing
      validationResult.fieldValidations["etf-supportingDocuments"] =
        "Names cannot be blank";

      // and if the supportingDocuments section has been touched
      if (
        addAllFieldValidationsErrors ||
        touchedFields[`etf-supportingDocuments-${i}-description`]
      ) {
        // then an error message should be shown on the specific document
        // where the problem is
        validationResult.fieldValidations[
          `etf-supportingDocuments-${i}-description`
        ] = "Name cannot be blank";
      }
    }
  });

  return validationResult;
}
