"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allAssetClasses = void 0;
exports.assetClassToString = assetClassToString;
exports.stringToAssetClass = stringToAssetClass;
const assetClass_pb_1 = require("./assetClass_pb");
// Get all asset class as enum values
exports.allAssetClasses = Object.values(assetClass_pb_1.AssetClass).filter((value) => typeof value === "number");
// Define explicit mappings between AssetClass enums and custom string representations
const assetClassToStringMapping = {
    [assetClass_pb_1.AssetClass.UNDEFINED_ASSET_CLASS]: "-",
    [assetClass_pb_1.AssetClass.CASH_ASSET_CLASS]: "Cash",
    [assetClass_pb_1.AssetClass.FIXED_INCOME_ASSET_CLASS]: "Fixed Income",
    [assetClass_pb_1.AssetClass.EQUITY_ASSET_CLASS]: "Equity",
    [assetClass_pb_1.AssetClass.DEBT_ASSET_CLASS]: "Debt",
    [assetClass_pb_1.AssetClass.REAL_ESTATE_ASSET_CLASS]: "Real Estate",
    [assetClass_pb_1.AssetClass.COMMODITIES_ASSET_CLASS]: "Commodities",
    [assetClass_pb_1.AssetClass.FOREIGN_EXCHANGE_ASSET_CLASS]: "Foreign Exchange",
    [assetClass_pb_1.AssetClass.DERIVATIVES_ASSET_CLASS]: "Derivatives",
};
// Reverse mapping from string to AssetClass enum
const stringToAssetClassMapping = {};
for (const [key, value] of Object.entries(assetClassToStringMapping)) {
    stringToAssetClassMapping[value] = Number(key);
}
class UnsupportedAssetClassError extends Error {
    constructor(assetClass) {
        const message = `Unsupported AssetClass: ${assetClass}`;
        super(message);
        this.assetClass = assetClass;
    }
}
/**
 * Converts a AssetClass enum instance to a custom string representation.
 * @param {AssetClass} assetClass - The asset class to convert.
 * @returns {string} The custom string representation of the asset class.
 */
function assetClassToString(assetClass) {
    if (assetClass in assetClassToStringMapping) {
        return assetClassToStringMapping[assetClass];
    }
    else {
        throw new UnsupportedAssetClassError(assetClass);
    }
}
class UnsupportedAssetClassStringError extends Error {
    constructor(assetClassStr) {
        const message = `Unsupported asset class string: ${assetClassStr}`;
        super(message);
        this.assetClassStr = assetClassStr;
    }
}
/**
 * Converts a custom string representation to a AssetClass enum instance.
 * @param {string} assetClassStr - The custom string representation of the asset class.
 * @returns {AssetClass} The corresponding AssetClass enum instance.
 */
function stringToAssetClass(assetClassStr) {
    if (assetClassStr in stringToAssetClassMapping) {
        return stringToAssetClassMapping[assetClassStr];
    }
    else {
        throw new UnsupportedAssetClassStringError(assetClassStr);
    }
}
