import React, { ReactNode, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextareaAutosize,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CurrencyTextField,
  DateField,
  TextField,
  TextNumField,
} from "components/FormFields";
import {
  AddCircleOutlineOutlined as AddIcon,
  Block as NotAllowedIcon,
  CancelOutlined as RemoveIcon,
  Close as CloseIcon,
  DeleteOutlineOutlined as DeleteIcon,
  Done as DoneIcon,
  EditOutlined as EditIcon,
  ExpandMore as ExpandMoreIcon,
  GetAppOutlined as DownloadIcon,
  Info as InfoIcon,
  PublishOutlined as UploadIcon,
} from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import cx from "classnames";
import dayjs from "dayjs";
import {
  FinancialCurrencyStablecoinViewModel,
  FinancialCurrencyStablecoinViewReader,
} from "james/views/financialCurrencyStablecoinView";
import { Group, GroupRepository } from "james/group";
import { countries } from "james/country";
import { CountryOption } from "james/country/countries";
import { useSnackbar } from "notistack";
import isEqual from "lodash/isEqual";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Model as StellarAccountStubViewModel,
  Reader as StellarAccountStubViewReader,
} from "james/views/stellarAccountStubView";
import { Currency } from "james/financial/Currency";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import {
  LedgerAccountCategory,
  Mint,
  MintRepository,
  Token,
} from "james/ledger";
import { IDIdentifier } from "james/search/identifier/ID";
import { getFormat } from "james/currency/Format";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { useDropzone } from "react-dropzone";
import { Document } from "james/document";
import { doUpload } from "utilities/network/upload";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { formatTextNum } from "utilities/number";
import { ISO4217_currencyList } from "james/currency/supportedCurrencies";
import { Money } from "james/money";

// financial
import {
  AllFinancialSectors,
  AllInstrumentRiskProfiles,
  AllInvestorProfiles,
  AllRatingAgencies,
  AssetClass,
  CountryAllocation,
  ErrDraftUnitTrustNameAlreadyInUseErrorCode,
  ErrUnitTrustNameAlreadyInUseErrorCode,
  UnitTrust,
  UnitTrustAction,
  UnitTrustStablecoin,
  UnitTrustStablecoinAction,
  UnitTrustStablecoinState,
  UnitTrustState,
  FinancialCurrencyCollection,
  FinancialInstrumentCollection,
  FinancialInstrumentStablecoinCollection,
  getPotentialNextUnitTrustActions,
  getPotentialNextUnitTrustStablecoinActions,
  Holding,
  InstrumentRiskProfile,
  InvestorProfile,
  RatingAgency,
  Ratings,
  SectorAllocation,
} from "james/financial";

// financial.UnitTrust
import { UnitTrustUpdater } from "james/financial/UnitTrustUpdater";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";

// financial.UnitTrustStablecoin
import { UnitTrustStablecoinUpdater } from "james/financial/UnitTrustStablecoinUpdater";
import { UnitTrustStablecoinStateChangerCoordinator } from "james/financial/UnitTrustStablecoinStateChangerCoordinator";
import { MintAssetDialog } from "components/Dialogs/Minting";
import { ViewEditInstrumentAnnualPerformanceLog } from "./ViewEditInstrumentAnnualPerformanceLog";
import { LedgerAssetReadyToList, ListingInspector } from "james/market";
import { download } from "utilities/network/download";
import { Query } from "james/search/query";
import { UnderlyingCurrencyCategory } from "james/views/financialCurrencyStablecoinView/Model";
import { ClientKYCStatus } from "james/client";
import { SectorAllocationPieChart } from "./SectorAllocationPieChart";
import { CountryAllocationPieChart } from "./CountryAllocationPieChart";
import { HoldingsPieChart } from "./HoldingsPieChart";
import { InstrumentStateChip, PlacementStateWordsChip } from "./Chips";
import {
  IssueWarningDialog,
  IssueWarningDialogOptions,
} from "./IssueWarningDialog";
import {
  PendingDocumentUpload,
  StepValidationResult,
  TouchedFields,
  WarningDialogOptions,
} from "./common";
import {
  validateRights2UnitTrustStep1,
  validateRights2UnitTrustStep2,
  validateRights2UnitTrustStep3,
  validateRightsToUnitTrustSupportingDocuments,
} from "./unitTrustValidations";
import { InstrumentsViewPaths } from "./Instruments";
import { dateIsValid } from "utilities/date/dateIsValid";
import { useErrorContext } from "context/Error";
import BigNumber from "bignumber.js";
import {
  AllInstrumentManagementFeeFrequencies,
  AllInstrumentManagementFeeTypes,
  InstrumentManagementFeeType,
} from "james/financial/InstrumentManagementFee";
import { CurrencyAmountInstrumentManagementFee } from "james/financial/InstrumentManagementFeeCurrencyAmount";
import { PercentageInstrumentManagementFee } from "james/financial/InstrumentManagementFeePercentage";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "UnitTrustDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  successText: `${PREFIX}-successText`,
  errorText: `${PREFIX}-errorText`,
  tokenClassSelectionLayout: `${PREFIX}-tokenClassSelectionLayout`,
  docsMediaMsgLayout: `${PREFIX}-docsMediaMsgLayout`,
  docsMediaInfoIcon: `${PREFIX}-docsMediaInfoIcon`,
  docsMediaInfoMsg: `${PREFIX}-docsMediaInfoMsg`,
  dropZone: `${PREFIX}-dropZone`,
  dropZoneAccept: `${PREFIX}-dropZoneAccept`,
  dropZoneReject: `${PREFIX}-dropZoneReject`,
  dropZoneGuidanceText: `${PREFIX}-dropZoneGuidanceText`,
  docsMediaColContent: `${PREFIX}-docsMediaColContent`,
  docsMediaColContentWarningMsg: `${PREFIX}-docsMediaColContentWarningMsg`,
  docsMediaLineItemLayout: `${PREFIX}-docsMediaLineItemLayout`,
  docsMediaLineItemMarginTop: `${PREFIX}-docsMediaLineItemMarginTop`,
  docsMediaLineItemBinIcon: `${PREFIX}-docsMediaLineItemBinIcon`,
  docsMediaLineItemFileName: `${PREFIX}-docsMediaLineItemFileName`,
  docsMediaLineItemFileNameWithDL: `${PREFIX}-docsMediaLineItemFileNameWithDL`,
  docsMediaDownloadIconButton: `${PREFIX}-docsMediaDownloadIconButton`,
  step1InstrumentDetailsMaxUnitsInIssueInfoIcon: `${PREFIX}-step1InstrumentDetailsMaxUnitsInIssueInfoIcon`,
  step2FieldsLayout: `${PREFIX}-step2FieldsLayout`,
  step2FieldDivider: `${PREFIX}-step2FieldDivider`,
  step2InstrumentInfoFieldLayout: `${PREFIX}-step2InstrumentInfoFieldLayout`,
  step2IssuerInfoFieldLayout: `${PREFIX}-step2IssuerInfoFieldLayout`,
  step2IssuerManagementFeeFieldLayout: `${PREFIX}-step2IssuerManagementFeeFieldLayout`,
  step2IssuerManagementFeeCcyField: `${PREFIX}-step2IssuerManagementFeeCcyField`,
  step3GeneralDescriptionFieldLayout: `${PREFIX}-step3GeneralDescriptionFieldLayout`,
  step3GeneralDescriptionFieldTextAreaViewMode: `${PREFIX}-step3GeneralDescriptionFieldTextAreaViewMode`,
  step3GeneralDescriptionFieldTextArea: `${PREFIX}-step3GeneralDescriptionFieldTextArea`,
  holdingsAndAllocationsViewModeSectionLayout: `${PREFIX}-holdingsAndAllocationsViewModeSectionLayout`,
  holdingsAndAllocationsViewModeList: `${PREFIX}-holdingsAndAllocationsViewModeList`,
  holdingsAndAllocationsViewModeLineItem: `${PREFIX}-holdingsAndAllocationsViewModeLineItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  //
  // Dialog Title
  //
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
  },

  [`& .${classes.heading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 40,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  //
  // text
  //
  [`& .${classes.successText}`]: { color: theme.palette.success.light },

  [`& .${classes.errorText}`]: { color: theme.palette.error.main },

  //
  // token class selection
  //
  [`& .${classes.tokenClassSelectionLayout}`]: {
    padding: theme.spacing(3, 2, 3, 2),
  },

  //
  // docs media
  //
  [`& .${classes.docsMediaMsgLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(1),
    justifyItems: "center",
  },

  [`& .${classes.docsMediaInfoIcon}`]: {
    fontSize: 60,
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.docsMediaInfoMsg}`]: {
    maxWidth: 300,
    textAlign: "center",
  },

  [`& .${classes.dropZone}`]: {
    padding: theme.spacing(2, 0, 1, 0),
    width: "calc(0.3vw - 30)",
    height: 143,
    border: `1px dashed ${theme.palette.text.primary}`,
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    display: "grid",
    gridTemplateColumns: "1fr",
    alignItems: "center",
    justifyItems: "center",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.dropZoneAccept}`]: {
    border: `1px dashed ${theme.palette.success.main}`,
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },

  [`& .${classes.dropZoneReject}`]: {
    border: `1px dashed ${theme.palette.error.main}`,
    backgroundColor: alpha(theme.palette.error.main, 0.2),
  },

  [`& .${classes.dropZoneGuidanceText}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaColContent}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.docsMediaColContentWarningMsg}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.docsMediaLineItemLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.docsMediaLineItemMarginTop}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaLineItemBinIcon}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaLineItemFileName}`]: {
    gridColumn: "1/3",
  },

  [`& .${classes.docsMediaLineItemFileNameWithDL}`]: {
    gridColumn: "1/3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.docsMediaDownloadIconButton}`]: {
    marginLeft: theme.spacing(1),
  },

  // -------------------------------------------------
  // Step 1 - Digital Instrument Section
  // -------------------------------------------------
  [`& .${classes.step1InstrumentDetailsMaxUnitsInIssueInfoIcon}`]: {
    // marginLeft: -45,
    // zIndex: 1,
  },

  // -------------------------------------------------
  // Step 2 - Reference Instrument Section
  // -------------------------------------------------
  [`& .${classes.step2FieldsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "2fr auto 1fr",
  },

  [`& .${classes.step2FieldDivider}`]: {
    padding: theme.spacing(1, 2),
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },

  [`& .${classes.step2InstrumentInfoFieldLayout}`]: {
    paddingTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.step2IssuerInfoFieldLayout}`]: {
    paddingTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.step2IssuerManagementFeeFieldLayout}`]: {
    maxWidth: 250,
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.step2IssuerManagementFeeCcyField}`]: {
    width: 170,
    marginRight: theme.spacing(1),
  },

  // -------------------------------------------------
  // Step 3 - Asset Exposure
  // -------------------------------------------------
  [`& .${classes.step3GeneralDescriptionFieldLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(0.5),
  },

  [`& .${classes.step3GeneralDescriptionFieldTextAreaViewMode}`]: {
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    maxHeight: 131,
    overflowY: "auto",
  },

  [`& .${classes.step3GeneralDescriptionFieldTextArea}`]: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(0.5, 1),
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    borderRadius: 4,
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    resize: "none",
  },

  //
  // holdings and allocations
  //
  [`& .${classes.holdingsAndAllocationsViewModeSectionLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    gridRowGap: theme.spacing(2),
    justifyItems: "center",
  },

  [`& .${classes.holdingsAndAllocationsViewModeList}`]: {
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.holdingsAndAllocationsViewModeLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const allowedDocumentTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

export function UnitTrustDialog() {
  // Hook Invocations
  const theme = useTheme();
  const {
    viewConfiguration,
    authContext,
    myClientKYCStatus,
    myClient,
    myClientRetrievalErr,
  } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Document Uploading
  const dropZoneProps = useDropzone({
    // allowed document types
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
      "application/pdf": [],
    },

    // document drop validator
    validator: (f: File) => {
      if (!allowedDocumentTypes.includes(f.type)) {
        enqueueSnackbar("File Type Not Supported", { variant: "warning" });
        return [
          {
            message: "Unsupported File Type",
            code: "unsupported-file-type",
          },
        ];
      }

      if (f.size > 20 * 1000 * 1000) {
        enqueueSnackbar("File is Too Large For Upload", { variant: "warning" });
        return [
          {
            message: "Max of 20MB allowed",
            code: "file-too-large",
          },
        ];
      }

      return null;
    },

    // document drop function
    onDrop: async (acceptedFiles: File[]) => {
      // for every file that has been dropped...
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          // request to upload a document against the instrument
          const response =
            await InstrumentDocumentController.RequestDocumentUploadForInstrument(
              {
                context: authContext,
              },
            );

          // add a pending document upload
          setPendingDocumentUploads((prev) => [
            ...prev,
            {
              document: new Document({
                id: response.documentID,
                name: file.name,
                mimeType: file.type,
                description: "",
              }),
              file,
              complete: false,
              percent: 0,
            },
          ]);

          // perform upload within an awaited promise to allow asynchronous UI updates
          await (() =>
            new Promise((resolve, reject) => {
              file.arrayBuffer().then((arrayBuffer) => {
                doUpload({
                  url: response.uploadURL,
                  documentData: arrayBuffer,
                  documentType: file.type,
                  onProgress: (p: number) =>
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].percent = p;
                      return [...prev];
                    }),
                  onComplete: () => {
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].complete = true;
                      return [...prev];
                    });
                    resolve(true);
                  },
                  onError: reject,
                });
              });
            }))();
        }),
      );

      // finally update supporting documents and clear the pending document uploads
      setPendingDocumentUploads((prev) => {
        // update supporting documents
        handleChangeEntitiesInDialog(
          undefined,
          "supportingDocuments",
        )([...prev.map((pd) => pd.document), ...unitTrust.supportingDocuments]);

        return [];
      });
    },
  });

  // Component State
  const [apiLoading, setAPILoading] = useState(false);
  const [unitTrust, setUnitTrust] = useState(new UnitTrust());
  const [copyOfUnitTrust, setCopyOfUnitTrust] = useState(new UnitTrust());
  const [unitTrustStablecoin, setUnitTrustStablecoin] = useState(
    new UnitTrustStablecoin(),
  );
  const [mint, setMint] = useState<Mint | undefined>(undefined);
  const [copyOfUnitTrustStablecoin, setCopyOfUnitTrustStablecoin] = useState(
    new UnitTrustStablecoin(),
  );
  const [potentialGroupOwners, setPotentialGroupOwners] = useState<Group[]>([]);
  const [
    potentialValuationCurrencyStablecoins,
    setPotentialValuationCurrencyStablecoins,
  ] = useState<FinancialCurrencyStablecoinViewModel[]>([]);
  const [
    valuationStablecoinUnderlyingCurrencies,
    setValuationStablecoinUnderlyingCurrencies,
  ] = useState<Currency[]>([]);
  const [accordionSectionsOpen, setAccordionSectionsOpen] = useState<{
    [key: string]: boolean;
  }>({
    // step1: true,
    // step2: false,
    // step3: false,
    step1: false, // FIXME
    step2: true,
    step3: false,
  });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [pendingCountryAllocation, setPendingCountryAllocation] =
    useState<CountryAllocation>(new CountryAllocation());
  const [pendingHolding, setPendingHolding] = useState<Holding>(new Holding());
  const [pendingSectorAllocation, setPendingSectorAllocation] =
    useState<SectorAllocation>(new SectorAllocation());
  const [step1ValidationResult, setStep1ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [step2ValidationResult, setStep2ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [step3ValidationResult, setStep3ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [supportingDocsStepValidationResult, setSupportingDocsStepResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const [issueWarningDialogOptions, setIssueWarningDialogOptions] =
    useState<IssueWarningDialogOptions | null>(null);
  const [issuanceAccountStub, setIssuanceAccountStub] = useState(
    new StellarAccountStubViewModel(),
  );
  const [viewModeOn, setViewModeOn] = useState(true);
  const [pendingDocumentUploads, setPendingDocumentUploads] = useState<
    PendingDocumentUpload[]
  >([]);
  const [showMintAssetDialog, setShowMintAssetDialog] = useState(false);
  const instrumentIsPlaced = useRef(false);

  // Computed State
  const unsavedUnitTrustChangesExist = !isEqual(unitTrust, copyOfUnitTrust);
  const unsavedUnitTrustStablecoinChangesExist = !isEqual(
    unitTrustStablecoin,
    copyOfUnitTrustStablecoin,
  );
  const unsavedChangesExist =
    unsavedUnitTrustChangesExist || unsavedUnitTrustStablecoinChangesExist;
  const allStepsComplete =
    step1ValidationResult.stepComplete &&
    step2ValidationResult.stepComplete &&
    step3ValidationResult.stepComplete &&
    supportingDocsStepValidationResult.stepComplete;
  const instrumentsViewConfig = viewConfiguration.Instruments
    ? viewConfiguration.Instruments
    : {};
  const marketAssetViewConfig = instrumentsViewConfig.MarketAssetActions
    ? instrumentsViewConfig.MarketAssetActions
    : {};
  const unitTrustActionsViewConfig = instrumentsViewConfig.UnitTrustActions
    ? instrumentsViewConfig.UnitTrustActions
    : {};
  const unitTrustStablecoinActionsViewConfig =
    instrumentsViewConfig.UnitTrustStablecoinActions
      ? instrumentsViewConfig.UnitTrustStablecoinActions
      : {};
  const potentialNextActionsForUnitTrust =
    unitTrust.state === ""
      ? []
      : getPotentialNextUnitTrustActions(unitTrust.state);
  const potentialNextActionsForUnitTrustStablecoin =
    unitTrustStablecoin.state === ""
      ? []
      : getPotentialNextUnitTrustStablecoinActions(unitTrustStablecoin.state);
  const creatingNew = unitTrust.id === "";
  const editIsANextAction =
    [
      // 'edit' is a next action if
      // some of these update actions are included in the
      // next potential actions for unitTrust...
      UnitTrustAction.DraftUpdate,
      UnitTrustAction.ChangeMaturityDate,
      UnitTrustAction.ChangeHoldings,
      UnitTrustAction.ChangeSectorAllocations,
      UnitTrustAction.ChangeCountryAllocations,
      UnitTrustAction.ChangeSupportingDocuments,
    ].some((a) => potentialNextActionsForUnitTrust.includes(a)) || // OR
    [
      // some of these update actions are included in the
      // next potential actions for unitTrust stablecoin then
      UnitTrustStablecoinAction.DraftUpdate,
      UnitTrustStablecoinAction.IncreaseMaximumUnits,
      UnitTrustStablecoinAction.DecreaseMaximumUnits,
      UnitTrustStablecoinAction.ChangeMaturityDate,
    ].some((a) => potentialNextActionsForUnitTrustStablecoin.includes(a));
  const kycStatusVerified =
    myClientKYCStatus === ClientKYCStatus.VerifiedStatus;
  // determine the validity of dates
  const invalidDatesInForm = !(
    dateIsValid(unitTrustStablecoin.issueDate) &&
    dateIsValid(unitTrust.issueDate) &&
    dateIsValid(unitTrustStablecoin.maturityDate) &&
    dateIsValid(unitTrust.maturityDate)
  );

  // Initial Component Load
  useEffect(() => {
    (async () => {
      // start loading
      setAPILoading(true);

      if (myClientRetrievalErr && !myClient) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        navigate(InstrumentsViewPaths.Table);
        return;
      }

      if (!myClient) {
        return;
      }

      // prepare the in dialog UnitTrust/UnitTrustStablecoin pair
      let unitTrustStablecoinInDialog = new UnitTrustStablecoin();
      unitTrustStablecoinInDialog.state = UnitTrustStablecoinState.Draft;
      let unitTrustInDialog = new UnitTrust();
      unitTrustInDialog.state = UnitTrustState.Draft;

      // prepare the in dialog Mint
      let mintInDialog: Mint | undefined;

      // determine if an existing instrument/stablecoin pair is being viewed/manipulated
      // or if a new UnitTrust/UnitTrustStablecoin pair being created
      const unitTrustStablecoinIDFromURL = searchParams.get("id");
      let newPair: boolean;
      if (unitTrustStablecoinIDFromURL) {
        // id from url set :- viewing/editing existing

        if (
          searchParams.get("edit") &&
          !unitTrustStablecoinActionsViewConfig.Update
        ) {
          // if edit mode is selected and user does not have update permission
          // then return to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newPair = false;

        if (unitTrustStablecoinIDFromURL === unitTrustStablecoin.id) {
          // stop reload at this point if the UnitTrustStablecoin in the dialog state matches the url
          setAPILoading(false);
          return;
        }

        // retrieve the UnitTrustStablecoin
        try {
          const retrievedStablecoin = (
            await FinancialInstrumentStablecoinCollection.RetrieveInstrumentStablecoin(
              {
                context: authContext,
                identifier: IDIdentifier(unitTrustStablecoinIDFromURL),
              },
            )
          ).instrumentStablecoin;
          if (retrievedStablecoin instanceof UnitTrustStablecoin) {
            unitTrustStablecoinInDialog = retrievedStablecoin;
          } else {
            console.error("unexpected stablecoin type");
            navigate(InstrumentsViewPaths.Table);
            return;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving instrument stablecoin: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Instrument Stablecoin: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        // retrieve the UnitTrust backing the UnitTrustStablecoin
        try {
          const retrievedInstrument = (
            await FinancialInstrumentCollection.RetrieveInstrument({
              context: authContext,
              identifier: IDIdentifier(
                unitTrustStablecoinInDialog.instrumentID,
              ),
            })
          ).instrument;
          if (retrievedInstrument instanceof UnitTrust) {
            unitTrustInDialog = retrievedInstrument;
          } else {
            console.error("unexpected instrument type");
            navigate(InstrumentsViewPaths.Table);
            return;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving instrument: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Instrument: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          navigate(InstrumentsViewPaths.Table);
          return;
        }
      } else {
        // id from url not set :- creating new

        // default asset class to Fund
        unitTrustInDialog.assetClass = AssetClass.Fund;

        if (!unitTrustStablecoinActionsViewConfig.ChangeState) {
          // if user does not have permission to change state (creation incl.) then go back to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newPair = true;
      }
      // If execution reaches here either both the UnitTrustStablecoin and UnitTrust have been retrieved
      // or neither and both are non created drafts.

      // load associated required data
      await Promise.all([
        // if digital unitTrust can be minted then retrieve the mint
        (async () => {
          try {
            const mintRecords = (
              await MintRepository.SearchMint({
                context: authContext,
                criteria: {
                  "token.code": TextExactCriterion(
                    unitTrustStablecoinInDialog.token.code,
                  ),
                  "token.issuer": TextExactCriterion(
                    unitTrustStablecoinInDialog.token.issuer,
                  ),
                  "token.network": TextExactCriterion(
                    unitTrustStablecoinInDialog.token.network,
                  ),
                },
                query: new Query({
                  limit: 1,
                  offset: 0,
                  sorting: [],
                }),
              })
            ).records;
            if (mintRecords.length === 1) {
              mintInDialog = mintRecords[0];
            }
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving mint: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),

        // determine if instrument is already listed
        (async () => {
          if (!LedgerAssetReadyToList(unitTrustStablecoinInDialog)) {
            return;
          }
          try {
            instrumentIsPlaced.current = (
              await ListingInspector.DoesListingForTokenExist({
                context: authContext,
                token: unitTrustStablecoinInDialog.token,
              })
            ).exists;
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error determining if instrument stablecoin is ready for listing: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),

        // fetch required groups
        (async () => {
          if (unitTrustStablecoinActionsViewConfig.ChangeState) {
            // if the user has permission to create (change state incl.) a UnitTrust then
            // get all groups in which the user has permission to CreateNewStablecoin
            try {
              const updatedPotentialGroupOwners = (
                await GroupRepository.SearchGroups({
                  context: authContext,
                  criteria: (
                    await Determiner.DetermineScopeCriteriaByRoles({
                      context: authContext,
                      service: new Permission({
                        serviceName: "CoordinateCreateNewUnitTrustStablecoin",
                        serviceProvider:
                          UnitTrustStablecoinStateChangerCoordinator.serviceProvider,
                        description: "-",
                      }),
                      criteria: {},
                      scopeFields: [ScopeFields.IDField],
                      buildScopeTree: false,
                    })
                  ).criteria,
                })
              ).records;
              setPotentialGroupOwners(updatedPotentialGroupOwners);
              if (updatedPotentialGroupOwners.length && newPair) {
                unitTrustStablecoinInDialog.ownerID =
                  updatedPotentialGroupOwners[0].id;
                unitTrustInDialog.ownerID = updatedPotentialGroupOwners[0].id;
              }
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `unable to search for potential group owners: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          } else if (unitTrustInDialog.id) {
            // Otherwise, if the UnitTrust already exists, then just get the owner group
            try {
              const updatedPotentialGroupOwners = (
                await GroupRepository.SearchGroups({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(unitTrustInDialog.ownerID),
                  },
                })
              ).records;
              setPotentialGroupOwners(updatedPotentialGroupOwners);
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `unable to search for potential group owners: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          }
        })(),

        // set issuance account
        (async () => {
          try {
            const potentialIssuanceAccountStubs = (
              await StellarAccountStubViewReader.Read({
                context: authContext,
                criteria: {
                  ownerID: TextExactCriterion(myClient.ownerID),
                  category: TextExactCriterion(LedgerAccountCategory.Issuance),
                },
              })
            ).models;

            if (potentialIssuanceAccountStubs.length > 1) {
              console.error("retrieved more than 1 issuance account");
              enqueueSnackbar("Retrieved More Than 1 Issuance Account", {
                variant: "error",
              });
            } else if (potentialIssuanceAccountStubs.length === 0) {
              console.error("issuance account not found");
              enqueueSnackbar("Issuance Account Not Found", {
                variant: "error",
              });
            } else {
              setIssuanceAccountStub(potentialIssuanceAccountStubs[0]);
            }
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving issuance account: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Retrieving Issuance Account: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
          }
        })(),

        // get all currency stablecoins in which new instrument stablecoin can be valued
        (async () => {
          try {
            // retrieve all potential valuation currency stablecoins
            const updatedPotentialValuationStablecoins = (
              await FinancialCurrencyStablecoinViewReader.Read({
                context: authContext,
                criteria: {
                  underlyingCurrencyCategory: TextExactCriterion(
                    UnderlyingCurrencyCategory.Fiat,
                  ),
                },
              })
            ).models;

            // retrieve each associated currency
            const updatedValuationStablecoinUnderlyingCurrencies = (
              await FinancialCurrencyCollection.SearchCurrency({
                context: authContext,
                criteria: {
                  id: TextListCriterion(
                    updatedPotentialValuationStablecoins.map(
                      (sc) => sc.currencyID,
                    ),
                  ),
                },
              })
            ).records;

            // if at least 1 stablecoin is retrieved
            // AND a new pair is being created
            if (updatedPotentialValuationStablecoins.length && newPair) {
              // set starting valuation token
              unitTrustStablecoinInDialog.valuationToken = new Token(
                updatedPotentialValuationStablecoins[0].token,
              );

              // find resultant starting currency
              const currency =
                updatedValuationStablecoinUnderlyingCurrencies.find(
                  (c: Currency) =>
                    updatedPotentialValuationStablecoins[0].currencyID ===
                    c.currencyID(),
                );
              if (!currency) {
                console.error("could not find associated currency");
                return;
              }

              // use resultant starting currency to set initial issue and maturity date
              unitTrustInDialog.issueDate = dayjs().startOf("day").format();
              unitTrustInDialog.maturityDate = currency.firstCutOffAfter(
                dayjs(unitTrustInDialog.issueDate).add(1, "day").format(),
              );
              unitTrustStablecoinInDialog.issueDate =
                unitTrustInDialog.issueDate;
              unitTrustStablecoinInDialog.maturityDate =
                unitTrustInDialog.maturityDate;
            }

            setPotentialValuationCurrencyStablecoins(
              updatedPotentialValuationStablecoins,
            );
            setValuationStablecoinUnderlyingCurrencies(
              updatedValuationStablecoinUnderlyingCurrencies,
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `unable to search for potential valuation stablecoin: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),
      ]);

      setStep1ValidationResult(
        validateRights2UnitTrustStep1(
          unitTrustInDialog,
          unitTrustStablecoinInDialog,
          mintInDialog,
          {},
          false,
        ),
      );
      setStep2ValidationResult(
        validateRights2UnitTrustStep2(
          unitTrustInDialog,
          unitTrustStablecoinInDialog,
          {},
          false,
        ),
      );
      setStep3ValidationResult(
        validateRights2UnitTrustStep3(
          unitTrustInDialog,
          unitTrustStablecoinInDialog,
          {},
          false,
        ),
      );
      setSupportingDocsStepResult(
        validateRightsToUnitTrustSupportingDocuments(
          unitTrustInDialog,
          unitTrustStablecoinInDialog,
          {},
          false,
        ),
      );

      // set the in dialog entities
      setUnitTrust(new UnitTrust(unitTrustInDialog));
      setCopyOfUnitTrust(new UnitTrust(unitTrustInDialog));
      setUnitTrustStablecoin(
        new UnitTrustStablecoin(unitTrustStablecoinInDialog),
      );
      setCopyOfUnitTrustStablecoin(
        new UnitTrustStablecoin(unitTrustStablecoinInDialog),
      );
      setMint(mintInDialog);

      // set view mode as requested
      setViewModeOn(!(newPair || searchParams.get("edit")));

      setAPILoading(false);
    })();
  }, [
    myClient,
    unitTrustStablecoinActionsViewConfig.ChangeState,
    unitTrustStablecoinActionsViewConfig.Update,
    enqueueSnackbar,
    history,
    unitTrustStablecoin.id,
    authContext,
  ]);

  type UnitTrustStablecoinField = keyof UnitTrustStablecoin;
  type UnitTrustStablecoinValue<T extends UnitTrustStablecoinField> =
    UnitTrustStablecoin[T];

  type UnitTrustField = keyof UnitTrust;
  type UnitTrustValue<T extends UnitTrustField> = UnitTrust[T];

  //  In Dialog Entity Updates
  const handleChangeEntitiesInDialog =
    (
      stablecoinField?: string,
      instrumentField?: string,
      otherFieldsAffected?: string[], // optional list of other fields that are affected by this update
    ) =>
    <T extends UnitTrustStablecoinField, K extends UnitTrustField>(
      newValue: UnitTrustStablecoinValue<T> | UnitTrustValue<K>,
    ) => {
      // prepare references to existing instrument and instrument stablecoin
      // before performing any updates as required
      let updatedUnitTrust = unitTrust;
      let updatedUnitTrustStablecoin = unitTrustStablecoin;

      // prepare updated touched fields
      const updatedTouchedFields = { ...touchedFields };
      if (otherFieldsAffected) {
        otherFieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // first perform the required field update before moving on
      // to consider 'side effects'
      if (stablecoinField) {
        // indicate touched field
        updatedTouchedFields[`unitTrustStablecoin-${stablecoinField}`] = true;

        // implement field update
        updatedUnitTrustStablecoin = new UnitTrustStablecoin({
          ...updatedUnitTrustStablecoin,
          [stablecoinField]: newValue,
        } as UnitTrustStablecoin);
      }
      if (instrumentField) {
        // indicate touched field
        updatedTouchedFields[`unitTrust-${instrumentField}`] = true;

        // implement field update
        updatedUnitTrust = new UnitTrust({
          ...updatedUnitTrust,
          [instrumentField]: newValue,
        } as UnitTrust);
      }

      // if an instrument field was given then perform necessary 'side effect' changes
      if (instrumentField) {
        // if the rating agency is changed then the credit rating should be cleared
        if (instrumentField === "issuerRatingAgency") {
          updatedUnitTrust.issuerCreditRating = "";
        }

        if (
          // if valuation token is set on the instrument stablecoin
          !updatedUnitTrustStablecoin.valuationToken.isUndefined() && // AND
          // any of the following fields are being updated on the instrument
          ["annualPerformanceLog", "issueDate", "maturityDate"].includes(
            instrumentField,
          ) && // AND
          // the set issue and maturity dates are valid
          dateIsValid(updatedUnitTrust.issueDate) &&
          dateIsValid(updatedUnitTrust.maturityDate)
        ) {
          // find selected valuation stablecoin
          const valuationCurrencyStablecoin =
            potentialValuationCurrencyStablecoins.find((c) =>
              c.token.isEqualTo(updatedUnitTrustStablecoin.valuationToken),
            );
          if (!valuationCurrencyStablecoin) {
            console.error(
              "could not find valuation currency stablecoin among those available",
            );
            return;
          }

          // find currency by which the stablecoin is backed
          const backingCurrency = valuationStablecoinUnderlyingCurrencies.find(
            (c) => valuationCurrencyStablecoin.currencyID === c.currencyID(),
          );
          if (!backingCurrency) {
            console.error("could not find backing currency");
            return;
          }

          // update relevant field on instrument
          switch (instrumentField) {
            case "issueDate":
              updatedUnitTrust.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedUnitTrust.issueDate).add(1, "minute").format(),
                );
              break;

            case "maturityDate":
              updatedUnitTrust.maturityDate = backingCurrency.firstCutOffAfter(
                dayjs(updatedUnitTrust.maturityDate).startOf("day").format(),
              );
              break;
          }
        }

        if (
          // if any of the following fields are being updated on the instrument
          ["annualPerformanceLog", "issueDate", "maturityDate"].includes(
            instrumentField,
          ) && // AND
          // the set issue and maturity dates are valid
          dateIsValid(updatedUnitTrust.issueDate) &&
          dateIsValid(updatedUnitTrust.maturityDate)
        ) {
          // then process annual performance log

          // get now
          const now = dayjs();

          // remove any years in the future

          updatedUnitTrust.annualPerformanceLog =
            updatedUnitTrust.annualPerformanceLog.filter(
              // by keeping only those entries with year <= this year
              (pl) => pl.year <= now.year(),
            );

          // remove any years prior to issue date
          updatedUnitTrust.annualPerformanceLog =
            updatedUnitTrust.annualPerformanceLog.filter(
              // by keep only those entries with year >= year in which instrument is issued
              (pl) => pl.year >= dayjs(updatedUnitTrust.issueDate).year(),
            );

          // of the entries which remain
          for (const i in updatedUnitTrust.annualPerformanceLog) {
            if (
              Object.prototype.hasOwnProperty.call(
                updatedUnitTrust.annualPerformanceLog,
                i,
              )
            ) {
              for (const month in updatedUnitTrust.annualPerformanceLog[i]
                .monthlyPerformance) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    updatedUnitTrust.annualPerformanceLog[i].monthlyPerformance,
                    month,
                  )
                ) {
                  // get first day of month in year
                  const firstDayOfMonthInYear = dayjs()
                    .year(updatedUnitTrust.annualPerformanceLog[i].year)
                    .month(+month - 1)
                    .startOf("month");

                  // remove any months in future
                  if (firstDayOfMonthInYear.isAfter(now, "month")) {
                    delete updatedUnitTrust.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }

                  // remove any months before issue date
                  if (
                    firstDayOfMonthInYear.isBefore(
                      updatedUnitTrust.issueDate,
                      "month",
                    )
                  ) {
                    delete updatedUnitTrust.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }
                }
              }
            }
          }
        }
      }

      // if a stablecoin field is given then perform necessary 'side effect' changes
      if (stablecoinField) {
        if (
          // if valuation token ID is set
          updatedUnitTrustStablecoin.valuationToken && // AND
          // the field being updated is one of the following
          ["issueDate", "maturityDate", "valuationToken"].includes(
            stablecoinField,
          ) && // AND
          // the set issue and maturity date fields are valid
          dateIsValid(updatedUnitTrustStablecoin.issueDate) &&
          dateIsValid(updatedUnitTrustStablecoin.maturityDate)
        ) {
          // find selected valuation stablecoin
          const valuationCurrencyStablecoin =
            potentialValuationCurrencyStablecoins.find((c) =>
              c.token.isEqualTo(updatedUnitTrustStablecoin.valuationToken),
            );
          if (!valuationCurrencyStablecoin) {
            console.error(
              "could not find valuation currency stablecoin among those available",
            );
            return;
          }

          // find currency by which the stablecoin is backed
          const backingCurrency = valuationStablecoinUnderlyingCurrencies.find(
            (c) => valuationCurrencyStablecoin.currencyID === c.currencyID(),
          );
          if (!backingCurrency) {
            console.error("could not find backing currency");
            return;
          }

          switch (stablecoinField) {
            case "issueDate":
              updatedUnitTrustStablecoin.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedUnitTrustStablecoin.issueDate)
                    .add(1, "minute")
                    .format(),
                );
              break;

            case "maturityDate":
              updatedUnitTrustStablecoin.maturityDate =
                backingCurrency.firstCutOffAfter(
                  dayjs(updatedUnitTrustStablecoin.maturityDate)
                    .startOf("day")
                    .format(),
                );
              break;

            case "valuationToken":
              // parse issue date as is
              updatedUnitTrustStablecoin.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedUnitTrustStablecoin.issueDate)
                    .add(1, "minute")
                    .format(),
                );
              updatedUnitTrustStablecoin.maturityDate =
                backingCurrency.firstCutOffAfter(
                  dayjs(updatedUnitTrustStablecoin.maturityDate)
                    .startOf("day")
                    .format(),
                );
              if (
                // if the set issue and maturity dates on the instrument are valid
                dateIsValid(updatedUnitTrust.issueDate) &&
                dateIsValid(updatedUnitTrust.maturityDate)
              ) {
                updatedUnitTrust.issueDate =
                  backingCurrency.firstStartOfDayBefore(
                    dayjs(updatedUnitTrust.issueDate).add(1, "minute").format(),
                  );
                updatedUnitTrust.maturityDate =
                  backingCurrency.firstCutOffAfter(
                    dayjs(updatedUnitTrust.maturityDate)
                      .startOf("day")
                      .format(),
                  );
              }
              break;
          }
        }
      }

      // set updated touched fields
      setTouchedFields(updatedTouchedFields);

      // clear any pending validation
      clearTimeout(validationTimeoutRef.current);

      // defer validation to take place in 200ms
      setValidationInProgress(true);
      clearTimeout(validationTimeoutRef.current);
      validationTimeoutRef.current = setTimeout(() => {
        setStep1ValidationResult(
          validateRights2UnitTrustStep1(
            updatedUnitTrust,
            updatedUnitTrustStablecoin,
            mint,
            updatedTouchedFields,
            false,
          ),
        );
        setStep2ValidationResult(
          validateRights2UnitTrustStep2(
            updatedUnitTrust,
            updatedUnitTrustStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setStep3ValidationResult(
          validateRights2UnitTrustStep3(
            updatedUnitTrust,
            updatedUnitTrustStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setSupportingDocsStepResult(
          validateRightsToUnitTrustSupportingDocuments(
            updatedUnitTrust,
            updatedUnitTrustStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setValidationInProgress(false);
      }, 800);

      setUnitTrust(updatedUnitTrust);
      setUnitTrustStablecoin(updatedUnitTrustStablecoin);
    };

  // handleDiscreteUnitTrustStablecoinUpdates performs any discrete updates required on the UnitTrustStablecoin.
  // e.g. maturity date change, holdings or allocations
  // The updated UnitTrustStablecoin is returned.
  const handleDiscreteUnitTrustStablecoinUpdates: () => Promise<UnitTrustStablecoin> =
    async () => {
      if (!unsavedUnitTrustStablecoinChangesExist) {
        // if there are no changes then do nothing
        return unitTrustStablecoin;
      }

      let updatedUnitTrustStablecoin = unitTrustStablecoin;

      if (
        !isEqual(
          unitTrustStablecoin.maturityDate,
          copyOfUnitTrustStablecoin.maturityDate,
        )
      ) {
        updatedUnitTrustStablecoin = (
          await UnitTrustStablecoinUpdater.ChangeUnitTrustStablecoinMaturityDate(
            {
              context: authContext,
              unitTrustStablecoinID: unitTrustStablecoin.id,
              maturityDate: unitTrust.maturityDate,
            },
          )
        ).unitTrustStablecoin;
      }

      if (
        unitTrustStablecoin.maximumUnits.value.gt(
          copyOfUnitTrustStablecoin.maximumUnits.value,
        )
      ) {
        updatedUnitTrustStablecoin = (
          await UnitTrustStablecoinUpdater.IncreaseUnitTrustStablecoinMaximumUnits(
            {
              context: authContext,
              unitTrustStablecoinID: unitTrustStablecoin.id,
              amount: unitTrustStablecoin.maximumUnits.setValue(
                unitTrustStablecoin.maximumUnits.value.minus(
                  copyOfUnitTrustStablecoin.maximumUnits.value,
                ),
              ),
            },
          )
        ).unitTrustStablecoin;
      } else if (
        unitTrustStablecoin.maximumUnits.value.lt(
          copyOfUnitTrustStablecoin.maximumUnits.value,
        )
      ) {
        updatedUnitTrustStablecoin = (
          await UnitTrustStablecoinUpdater.DecreaseUnitTrustStablecoinMaximumUnits(
            {
              context: authContext,
              unitTrustStablecoinID: unitTrustStablecoin.id,
              amount: unitTrustStablecoin.maximumUnits.setValue(
                copyOfUnitTrustStablecoin.maximumUnits.value.minus(
                  unitTrustStablecoin.maximumUnits.value,
                ),
              ),
            },
          )
        ).unitTrustStablecoin;
      }

      return updatedUnitTrustStablecoin;
    };

  // handleDiscreteUnitTrustUpdates performs any discrete updates required on the UnitTrust.
  // e.g. maturity date change, holdings or allocations
  // The updated UnitTrust is returned.
  const handleDiscreteUnitTrustUpdates: () => Promise<UnitTrust> = async () => {
    if (!unsavedUnitTrustChangesExist) {
      // if there are no changes then do nothing
      return unitTrust;
    }

    let updatedUnitTrust = unitTrust;

    if (!isEqual(unitTrust.maturityDate, copyOfUnitTrust.maturityDate)) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustMaturityDate({
          context: authContext,
          unitTrustID: unitTrust.id,
          maturityDate: unitTrust.maturityDate,
        })
      ).unitTrust;
    }

    if (
      !isEqual(unitTrust.countryAllocations, copyOfUnitTrust.countryAllocations)
    ) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustCountryAllocations({
          context: authContext,
          unitTrustID: unitTrust.id,
          updatedCountryAllocations: unitTrust.countryAllocations,
        })
      ).unitTrust;
    }

    if (!isEqual(unitTrust.holdings, copyOfUnitTrust.holdings)) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustHoldings({
          context: authContext,
          unitTrustID: unitTrust.id,
          updatedHoldings: unitTrust.holdings,
        })
      ).unitTrust;
    }

    if (
      !isEqual(unitTrust.sectorAllocations, copyOfUnitTrust.sectorAllocations)
    ) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustSectorAllocations({
          context: authContext,
          unitTrustID: unitTrust.id,
          updatedSectorAllocations: unitTrust.sectorAllocations,
        })
      ).unitTrust;
    }

    if (
      !isEqual(
        unitTrust.annualPerformanceLog,
        copyOfUnitTrust.annualPerformanceLog,
      )
    ) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustAnnualPerformanceLog({
          context: authContext,
          unitTrustID: unitTrust.id,
          updatedAnnualPerformanceLog: unitTrust.annualPerformanceLog,
        })
      ).unitTrust;
    }

    if (
      !isEqual(
        unitTrust.supportingDocuments,
        copyOfUnitTrust.supportingDocuments,
      )
    ) {
      updatedUnitTrust = (
        await UnitTrustUpdater.ChangeUnitTrustSupportingDocuments({
          context: authContext,
          unitTrustID: unitTrust.id,
          updatedSupportingDocuments: unitTrust.supportingDocuments,
        })
      ).unitTrust;
    }

    return updatedUnitTrust;
  };

  // handlePerformRequiredUpdates performs any updates required on either the unitTrust or unitTrust stablecoin
  // i.e. either creation, draft update or discrete update
  // Both updated entities are returned.
  const handlePerformRequiredUpdates: () => Promise<{
    updatedUnitTrust: UnitTrust;
    updatedUnitTrustStablecoin: UnitTrustStablecoin;
  }> = async () => {
    if (creatingNew) {
      // if creation is in progress then create the new unitTrust
      const {
        unitTrust: createdUnitTrust,
        unitTrustStablecoin: createdUnitTrustStablecoin,
      } =
        await UnitTrustStablecoinStateChangerCoordinator.CoordinateCreateNewUnitTrustStablecoin(
          {
            context: authContext,
            ownerID: unitTrust.ownerID,
            unitTrustFields: {
              name: unitTrust.name,
              isin: unitTrust.isin,
              assetClass: unitTrust.assetClass,
              issueDate: unitTrust.issueDate,
              maturityDate: unitTrust.maturityDate,
              issuerName: unitTrust.issuerName,
              issuerRatingAgency: unitTrust.issuerRatingAgency,
              issuerCreditRating: unitTrust.issuerCreditRating,
              issuerManagementFee: unitTrust.issuerManagementFee,
              issuerManagementFeeFrequency:
                unitTrust.issuerManagementFeeFrequency,
              supportingDocuments: unitTrust.supportingDocuments,
              investorProfile: unitTrust.investorProfile,
              investorProfileDescription: unitTrust.investorProfileDescription,
              riskProfile: unitTrust.riskProfile,
              riskProfileDescription: unitTrust.riskProfileDescription,
              holdings: unitTrust.holdings,
              sectorAllocations: unitTrust.sectorAllocations,
              countryAllocations: unitTrust.countryAllocations,
              annualPerformanceLog: unitTrust.annualPerformanceLog,
            },
            stablecoinFields: {
              issueDate: unitTrustStablecoin.issueDate,
              maturityDate: unitTrustStablecoin.maturityDate,
              maximumUnits: unitTrustStablecoin.maximumUnits,
              valuationToken: unitTrustStablecoin.valuationToken,
            },
          },
        );

      return {
        updatedUnitTrust: createdUnitTrust,
        updatedUnitTrustStablecoin: createdUnitTrustStablecoin,
      };
    }
    // If execution reaches here then both the unitTrust and unitTrustStablecoin already exist.
    // Following - both of them are updated as required.

    if (unitTrust.state === UnitTrustState.Draft) {
      // if the instrument is in draft then perform draft updates
      return {
        updatedUnitTrust: unsavedUnitTrustChangesExist
          ? (
              await UnitTrustUpdater.DraftUpdateUnitTrust({
                context: authContext,
                unitTrust,
              })
            ).unitTrust
          : unitTrust,
        updatedUnitTrustStablecoin: unsavedUnitTrustStablecoinChangesExist
          ? (
              await UnitTrustStablecoinUpdater.DraftUpdateUnitTrustStablecoin({
                context: authContext,
                unitTrustStablecoin,
              })
            ).unitTrustStablecoin
          : unitTrustStablecoin,
      };
    }
    // otherwise perform discrete updates
    return {
      updatedUnitTrust: await handleDiscreteUnitTrustUpdates(),
      updatedUnitTrustStablecoin:
        await handleDiscreteUnitTrustStablecoinUpdates(),
    };
  };

  // Determine Dialog Actions
  const getAvailableActions = () => {
    // if the issue date of either the unitTrustStablecoin or unitTrust are invalid
    if (
      !(
        dateIsValid(unitTrustStablecoin.issueDate) &&
        dateIsValid(unitTrust.issueDate)
      )
    ) {
      // then return with no actions
      return [];
    }

    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // for every potential action available at the UnitTrustStablecoin's state
    // consider what action buttons to add to list
    for (const action of potentialNextActionsForUnitTrustStablecoin) {
      switch (action) {
        case UnitTrustStablecoinAction.PreIssue: // PreIssue is a possible next action AND...
          if (unitTrustStablecoinActionsViewConfig.ChangeState) {
            if (dayjs(unitTrustStablecoin.issueDate).isAfter(dayjs())) {
              // ...The unitTrust stablecoin issue date is in the future.
              // Add a button for Pre-Issue.
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        id="rightsToUnitTrustDialog-preIssue-button"
                        // disable unless both steps are complete
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading
                        }
                        variant="contained"
                        children="Pre-Issue"
                        color="primary"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Pre-Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: true,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare stablecoin ID for post api actions
                                let unitTrustStablecoinID = "";

                                // perform any required updates
                                const result =
                                  await handlePerformRequiredUpdates();
                                unitTrustStablecoinID =
                                  result.updatedUnitTrustStablecoin.id;

                                // pre-issue unitTrust stablecoin
                                await UnitTrustStablecoinStateChangerCoordinator.CoordinatePreIssueUnitTrustStablecoin(
                                  {
                                    context: authContext,
                                    issuanceAccountID:
                                      issuanceAccountStub.accountID,
                                    unitTrustStablecoinID,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Pre-Issued",
                                  { variant: "success" },
                                );
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${unitTrustStablecoinID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrUnitTrustNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftUnitTrustNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      "unitTrust-name":
                                        "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${unitTrust.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  console.error(
                                    `error pre-issuing rights to instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(err);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case UnitTrustStablecoinAction.Issue: // Issue is a possible next action AND...
          if (unitTrustStablecoinActionsViewConfig.ChangeState) {
            if (dayjs(unitTrustStablecoin.issueDate).isBefore(dayjs())) {
              // ...The unitTrust stablecoin issue date is in the past.
              // Add a button for Issue.
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        id="rightsToUnitTrustDialog-issue-button"
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading ||
                          !!pendingDocumentUploads.length
                        }
                        variant="contained"
                        children="issue"
                        color="primary"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: false,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare stablecoin ID for post api actions
                                let unitTrustStablecoinID = "";

                                // perform any required updates
                                const result =
                                  await handlePerformRequiredUpdates();
                                unitTrustStablecoinID =
                                  result.updatedUnitTrustStablecoin.id;

                                // issue unitTrust stablecoin
                                await UnitTrustStablecoinStateChangerCoordinator.CoordinateIssueUnitTrustStablecoin(
                                  {
                                    context: authContext,
                                    issuanceAccountID:
                                      issuanceAccountStub.accountID,
                                    unitTrustStablecoinID,
                                  },
                                );

                                enqueueSnackbar("Rights to Instrument Issued", {
                                  variant: "success",
                                });
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${unitTrustStablecoinID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrUnitTrustNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftUnitTrustNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      "unitTrust-name":
                                        "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${unitTrust.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  console.error(
                                    `error issuing rights to instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(err);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case UnitTrustStablecoinAction.MarkDeleted:
          if (unitTrustStablecoinActionsViewConfig.ChangeState) {
            if (!creatingNew) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="rightsToUnitTrustDialog-deleteDraftUnitTrust-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              try {
                                await UnitTrustStablecoinStateChangerCoordinator.CoordinateMarkUnitTrustStablecoinDeleted(
                                  {
                                    context: authContext,
                                    unitTrustStablecoinID:
                                      unitTrustStablecoin.id,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Deleted",
                                  { variant: "success" },
                                );
                                navigate(InstrumentsViewPaths.Table);
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting rights to instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                enqueueSnackbar(
                                  "Error Deleting Rights to Instrument",
                                  { variant: "error" },
                                );
                              }

                              setAPILoading(false);
                            },
                            // user cancels deletion
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Rights to Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of rights to instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;
      }
    }

    // if unitTrust stablecoin can be minted
    if (mint) {
      // and the executing user has the Mint view configuration
      // and view mode is on
      if (unitTrustStablecoinActionsViewConfig.Mint && viewModeOn) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              id="unitTrustDialog-mint-button"
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // check if the place button should be shown
    if (
      marketAssetViewConfig.List &&
      LedgerAssetReadyToList(unitTrustStablecoin) &&
      !instrumentIsPlaced.current
    ) {
      prioritisedButtonActions.push({
        node: (
          <Button
            variant="contained"
            color="primary"
            children="place"
            id="rightsToUnitTrustDialog-place-button"
            disabled={apiLoading || validationInProgress || !allStepsComplete}
            onClick={async () => {
              if (unsavedChangesExist) {
                // if there are any unsaved changes save them before going to the placement screen
                setAPILoading(true);
                try {
                  await handlePerformRequiredUpdates();
                  setAPILoading(false);
                  return;
                } catch (e) {
                  const err = errorContextErrorTranslator.translateError(e);
                  console.error(
                    `error saving rights to instrument: ${
                      err.message ? err.message : err.toString()
                    }`,
                  );
                  enqueueSnackbar("Error Saving Rights to Instrument", {
                    variant: "error",
                  });
                  setAPILoading(false);
                  return;
                }
              }

              // go to placement screen
              navigate(
                `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${unitTrustStablecoin.id}`,
              );
            }}
          />
        ),
        priority: 1,
      });
    }

    //
    // Update Actions
    //

    // if user has update view config set
    if (unitTrustActionsViewConfig.Update) {
      if (viewModeOn) {
        // and if view mode is on (i.e. not editing)
        // then consider whether or not to show the Edit Button
        // (i.e. the button that turns edit mode on)

        if (editIsANextAction) {
          prioritisedIconActions.push({
            node: (
              <Tooltip title="Edit" placement="top">
                <span>
                  <IconButton
                    id="rightsToUnitTrustDialog-turnEditModeOn-button"
                    size="small"
                    disabled={apiLoading}
                    onClick={() => {
                      setViewModeOn(false);
                      navigate(
                        `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${unitTrustStablecoin.id}&edit=true`,
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ),
            priority: 2,
          });
        }
      } else {
        // otherwise view mode is off (i.e. editing)
        // show save button at all times
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              id="rightsToUnitTrustDialog-save-button"
              disabled={
                invalidDatesInForm ||
                validationInProgress ||
                apiLoading ||
                !!pendingDocumentUploads.length ||
                // if not in a draft state
                (unitTrust.state !== UnitTrustState.Draft &&
                  // then button is disabled unless all steps are complete
                  !allStepsComplete)
              }
              onClick={async () => {
                setAPILoading(true);

                // if unsaved changes exist then perform any required updates
                let updatedUnitTrustStablecoin = unitTrustStablecoin;
                let updatedUnitTrust = unitTrust;
                if (creatingNew || unsavedChangesExist) {
                  try {
                    const result = await handlePerformRequiredUpdates();
                    updatedUnitTrustStablecoin =
                      result.updatedUnitTrustStablecoin;
                    updatedUnitTrust = result.updatedUnitTrust;
                    enqueueSnackbar("Rights to Instrument Saved", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    if (
                      err.code === ErrUnitTrustNameAlreadyInUseErrorCode ||
                      err.code === ErrDraftUnitTrustNameAlreadyInUseErrorCode
                    ) {
                      setStep2ValidationResult({
                        stepComplete: false,
                        fieldValidations: {
                          ...step2ValidationResult.fieldValidations,
                          "unitTrust-name": "Name is already in use",
                        },
                      });
                      enqueueSnackbar(
                        `Instrument Name '${unitTrust.name}' is Already in Use`,
                        { variant: "warning" },
                      );
                    } else {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error saving rights to instrument: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      errorContextDefaultErrorFeedback(err);
                    }
                    setAPILoading(false);
                    return;
                  }
                }

                // clear field validations
                setStep1ValidationResult({
                  ...step1ValidationResult,
                  fieldValidations: {},
                });
                setStep2ValidationResult({
                  ...step2ValidationResult,
                  fieldValidations: {},
                });
                setStep3ValidationResult({
                  ...step3ValidationResult,
                  fieldValidations: {},
                });
                setSupportingDocsStepResult({
                  ...supportingDocsStepValidationResult,
                  fieldValidations: {},
                });

                // turn view mode on
                setViewModeOn(true);
                navigate(
                  `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${updatedUnitTrustStablecoin.id}`,
                );

                // update in dialog components
                setUnitTrust(new UnitTrust(updatedUnitTrust));
                setCopyOfUnitTrust(new UnitTrust(updatedUnitTrust));
                setUnitTrustStablecoin(
                  new UnitTrustStablecoin(updatedUnitTrustStablecoin),
                );
                setCopyOfUnitTrustStablecoin(
                  new UnitTrustStablecoin(updatedUnitTrustStablecoin),
                );

                // stop api loading
                setAPILoading(false);
              }}
              children="save"
            />
          ),
          priority: 2,
        });
      }
    }

    return [
      // ensure icon actions always render after button actions
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  // UnitTrust Field View Mode Determination
  const unitTrustDraftUpdateOnlyFieldViewModeOn =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![UnitTrustAction.DraftUpdate].some((a) =>
      potentialNextActionsForUnitTrust.includes(a),
    );

  const unitTrustMaturityDateReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![UnitTrustAction.DraftUpdate, UnitTrustAction.ChangeMaturityDate].some(
      (a) => potentialNextActionsForUnitTrust.includes(a),
    );

  const unitTrustHoldingsAndAllocationsSectionReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      UnitTrustAction.DraftUpdate,
      UnitTrustAction.ChangeHoldings,
      UnitTrustAction.ChangeCountryAllocations,
      UnitTrustAction.ChangeSectorAllocations,
    ].some((a) => potentialNextActionsForUnitTrust.includes(a));

  const unitTrustAnnualPerformanceLogReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      UnitTrustAction.DraftUpdate,
      UnitTrustAction.ChangeAnnualPerformanceLog,
    ].some((a) => potentialNextActionsForUnitTrust.includes(a));

  const unitTrustSupportingDocumentsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      UnitTrustAction.DraftUpdate,
      UnitTrustAction.ChangeSupportingDocuments,
    ].some((a) => potentialNextActionsForUnitTrust.includes(a));

  // UnitTrust Stablecoin Field View Mode Determination
  const unitTrustStablecoinDraftUpdateOnlyFieldViewModeOn =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![UnitTrustStablecoinAction.DraftUpdate].some((a) =>
      potentialNextActionsForUnitTrustStablecoin.includes(a),
    );

  const unitTrustStablecoinMaximumUnitsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      UnitTrustStablecoinAction.DraftUpdate,
      UnitTrustStablecoinAction.IncreaseMaximumUnits,
      UnitTrustStablecoinAction.DecreaseMaximumUnits,
    ].some((a) => potentialNextActionsForUnitTrustStablecoin.includes(a));

  const unitTrustStablecoinMaturityDateReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !unitTrustStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      UnitTrustStablecoinAction.DraftUpdate,
      UnitTrustStablecoinAction.ChangeMaturityDate,
    ].some((a) => potentialNextActionsForUnitTrustStablecoin.includes(a));

  return (
    <>
      <StyledDialog open fullScreen>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item className={classes.heading}>
              <div className={classes.miniLogoWrapper}>
                <img alt="" width="100%" src={meshMiniLogo} />
              </div>
              <Typography
                variant="h5"
                children={
                  unitTrust.state
                    ? unitTrustStablecoin.state ===
                      UnitTrustStablecoinState.Draft
                      ? "Instrument Builder"
                      : unitTrust.name
                    : "Instrument Builder"
                }
              />
            </Grid>
            {!creatingNew && (
              <>
                <Grid item>
                  <InstrumentStateChip state={unitTrustStablecoin.state} />
                </Grid>
                {LedgerAssetReadyToList(unitTrustStablecoin) ? (
                  <Grid item>
                    <PlacementStateWordsChip
                      placed={instrumentIsPlaced.current}
                    />
                  </Grid>
                ) : null}
              </>
            )}
            {apiLoading && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {getAvailableActions().map((a, idx) => (
              <Grid item key={idx}>
                {a}
              </Grid>
            ))}
            <Grid item>
              <Tooltip title="Close" placement="top">
                <span>
                  <IconButton
                    size="small"
                    id="rightsToUnitTrustDialog-close-button"
                    disabled={apiLoading}
                    onClick={() => {
                      // if unsaved changes exist
                      if (unsavedChangesExist) {
                        // show warning dialog
                        setWarningDialogOptions({
                          yesMethod: async () => {
                            // if the 'Yes' button is pressed on dialog
                            setAPILoading(true);

                            try {
                              await handlePerformRequiredUpdates();
                              enqueueSnackbar("Rights to Instrument Saved", {
                                variant: "success",
                              });
                              navigate(InstrumentsViewPaths.Table);
                              return;
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              if (
                                err.code ===
                                  ErrUnitTrustNameAlreadyInUseErrorCode ||
                                err.code ===
                                  ErrDraftUnitTrustNameAlreadyInUseErrorCode
                              ) {
                                setStep2ValidationResult({
                                  stepComplete: false,
                                  fieldValidations: {
                                    ...step2ValidationResult.fieldValidations,
                                    "unitTrust-name": "Name is already in use",
                                  },
                                });
                                enqueueSnackbar(
                                  `Instrument Name '${unitTrust.name}' is Already in Use`,
                                  { variant: "warning" },
                                );
                              } else {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error saving rights to instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                errorContextDefaultErrorFeedback(err);
                              }
                            }

                            setAPILoading(false);
                          },
                          noMethod: async () => {
                            // if the 'No' button is pressed on the warning dialog
                            // just go straight back to instruments table without
                            // showing warning dialog
                            navigate(InstrumentsViewPaths.Table);
                          },
                          title: "Save Rights to Instrument?",
                          messageParagraphs: [
                            `
                        You have not saved the changes that you have made.
                        Select 'Yes' to save the changes made to the
                        Rights to an UnitTrust you have been working on in it's
                        current state and return to the instrument dashboard.
                        Select 'No' to proceed without saving.
                        `,
                          ],
                        });
                      } else {
                        // no unsaved changes exist -
                        // just go straight back to instruments table
                        navigate(InstrumentsViewPaths.Table);
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
          }}
          className={"meshScroll"}
        >
          <Box
            sx={{
              display: "grid",
              // gridTemplateColumns: "1fr",
              gridTemplateRows: "auto 1fr",
              borderRight: `solid 1px ${theme.palette.divider}`,
            }}
          >
            {/* ----- Token Class Section ----------------------------- */}
            <Grid
              className={classes.tokenClassSelectionLayout}
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              {(() => [
                <Tooltip
                  title="Only 'Rights To' token class available for Unit Trust."
                  placement="top-start"
                >
                  <span>
                    <TextField
                      id="rightsToUnitTrustDialog-tokenClass-textField"
                      sx={{ width: 250 }}
                      label="Token Class"
                      value="Rights To An Instrument"
                      readOnly
                      disabled
                    />
                  </span>
                </Tooltip>,
                <TextField
                  label="Issuer"
                  sx={{ width: 250 }}
                  value={myClient?.name}
                  readOnly
                />,
              ])().map((i, idx) => (
                <Grid key={idx} item>
                  {i}
                </Grid>
              ))}
            </Grid>

            {/* ----- Accordion Section ----------------------------- */}
            <Box>
              {/*
              // -------------------------------------------------
              // Step 1
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step1
                    ? accordionSectionsOpen.step1
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step1: !accordionSectionsOpen.step1,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToUnitTrustDialog-step1-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToUnitTrustDialog-step1-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  sx={{
                    flexDirection: "row-reverse",
                    backgroundColor: theme.palette.custom.midnight,
                    padding: theme.spacing(0, 3),
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(1),
                      display: "grid",
                      gridTemplateColumns: "200px auto 1fr auto",
                      gridColumnGap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">Rights to Instrument</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 1
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step1ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step1ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      unitTrustActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToUnitTrustDialogStep1-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${unitTrustStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(3),
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ maxWidth: 740 }}
                  >
                    Enter defining details of the new rights to an instrument
                    that you are building. This will allow you to trade rights
                    to an existing instrument on Mesh.
                  </Typography>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gridRowGap: theme.spacing(1),
                    }}
                  >
                    {/* Row 1 */}
                    {creatingNew ? (
                      <TextField
                        label="Instrument Type"
                        id="rightsToUnitTrustDialog-instrumentType-textField"
                        sx={{ width: 250 }}
                        disabled={apiLoading}
                        value="Unit Trust"
                        select
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "Unit Trust":
                              // do nothing, already here
                              break;

                            case "ETN":
                            case "ETF":
                              // if unsaved changes exist
                              if (unsavedChangesExist) {
                                // then show a warning dialog
                                setWarningDialogOptions({
                                  yesMethod: async () => {
                                    // if the 'Yes' button is pressed on the warning dialog
                                    setAPILoading(true);

                                    try {
                                      // then save any existing changes
                                      await handlePerformRequiredUpdates();
                                      enqueueSnackbar(
                                        "Rights to Instrument Saved",
                                        { variant: "success" },
                                      );
                                      // and go to rights to ETN/ETF creation
                                      navigate(
                                        e.target.value === "ETF"
                                          ? InstrumentsViewPaths.RightsToETF
                                          : InstrumentsViewPaths.RightsToETN,
                                      );
                                      return;
                                    } catch (e) {
                                      const err =
                                        errorContextErrorTranslator.translateError(
                                          e,
                                        );
                                      if (
                                        err.code ===
                                          ErrUnitTrustNameAlreadyInUseErrorCode ||
                                        err.code ===
                                          ErrDraftUnitTrustNameAlreadyInUseErrorCode
                                      ) {
                                        setStep2ValidationResult({
                                          stepComplete: false,
                                          fieldValidations: {
                                            ...step2ValidationResult.fieldValidations,
                                            "unitTrust-name":
                                              "Name is already in use",
                                          },
                                        });
                                        enqueueSnackbar(
                                          `Instrument Name '${unitTrust.name}' is Already in Use`,
                                          { variant: "warning" },
                                        );
                                      } else {
                                        console.error(
                                          `error saving rights to instrument: ${
                                            err.message
                                              ? err.message
                                              : err.toString()
                                          }`,
                                        );
                                        errorContextDefaultErrorFeedback(err);
                                      }
                                    }

                                    setAPILoading(false);
                                  },
                                  noMethod: async () => {
                                    // if the 'No' button is pressed on the warning dialog
                                    // go straight to rights to ETN/ETF creation
                                    navigate(
                                      e.target.value === "ETF"
                                        ? InstrumentsViewPaths.RightsToETF
                                        : InstrumentsViewPaths.RightsToETN,
                                    );
                                  },
                                  title: "Save Rights to Instrument?",
                                  messageParagraphs: [
                                    `
                              Changing the instrument type requires that a new instrument be created.
                              Select 'Yes' to save rights to an Unit Trust you are working on in it's current
                              state and proceed to the creation of new rights to an ${e.target.value}.
                              `,
                                  ],
                                });
                              } else {
                                // no unsaved changes - go straight to rights to ETN/ETF creation
                                // without showing a warning dialog
                                navigate(
                                  e.target.value === "ETF"
                                    ? InstrumentsViewPaths.RightsToETF
                                    : InstrumentsViewPaths.RightsToETN,
                                );
                              }
                              break;
                          }
                        }}
                      >
                        <MenuItem
                          id="rightsToUnitTrustDialog-instrumentTypeETF-menuItem"
                          value="ETF"
                        >
                          ETF
                        </MenuItem>
                        <MenuItem
                          id="rightsToUnitTrustDialog-instrumentTypeETN-menuItem"
                          value="ETN"
                        >
                          ETN
                        </MenuItem>
                        <MenuItem
                          id="rightsToUnitTrustDialog-instrumentTypeUnitTrust-menuItem"
                          value="Unit Trust"
                        >
                          Unit Trust
                        </MenuItem>
                      </TextField>
                    ) : viewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-type-textField"
                        sx={{ width: 250 }}
                        label="Instrument Type"
                        value="Unit Trust"
                        readOnly
                      />
                    ) : (
                      <Tooltip
                        title="Cannot Change After Instrument has Been Created"
                        placement="top-start"
                      >
                        <span>
                          <TextField
                            id="rightsToUnitTrustDialog-type-textField"
                            sx={{ width: 250 }}
                            label="Instrument Type"
                            value="Unit Trust"
                            readOnly
                            disabled
                          />
                        </span>
                      </Tooltip>
                    )}
                    <DateField
                      label="Issue Date"
                      disabled={apiLoading}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog("issueDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      value={unitTrustStablecoin.issueDate}
                      readOnly={
                        unitTrustStablecoinDraftUpdateOnlyFieldViewModeOn
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: 250 }}
                          id="rightsToUnitTrustDialog-stablecoinIssueDate-dateField"
                          disabled={apiLoading}
                          readOnly={
                            unitTrustStablecoinDraftUpdateOnlyFieldViewModeOn
                          }
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-issueDate"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-issueDate"
                            ]
                          }
                        />
                      )}
                    />
                    {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-valuationTokenTokenID-textField"
                        sx={{ width: 250 }}
                        label="Valuation Stablecoin"
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(
                                unitTrustStablecoin.valuationToken,
                              ),
                            );
                          return model
                            ? `${model.token.code} - ${model.issuer}`
                            : "-";
                        })()}
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToUnitTrustDialog-valuationTokenTokenID-autoComplete"
                        sx={{ width: 250 }}
                        disabled={
                          apiLoading || unitTrustDraftUpdateOnlyFieldViewModeOn
                        }
                        getOptionLabel={(
                          option: FinancialCurrencyStablecoinViewModel,
                        ) => `${option.token.code} - ${option.issuer}`}
                        options={potentialValuationCurrencyStablecoins}
                        loading={apiLoading}
                        onChange={(
                          _,
                          selected: FinancialCurrencyStablecoinViewModel | null,
                        ) => {
                          if (selected) {
                            // something selected - set valuation token ID
                            handleChangeEntitiesInDialog("valuationToken")(
                              new Token(selected.token),
                            );
                          } else {
                            // nothing selected - clear valuation token ID
                            handleChangeEntitiesInDialog("valuationToken")(
                              new Token(),
                            );
                          }
                        }}
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(
                                unitTrustStablecoin.valuationToken,
                              ),
                            );
                          return model || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={apiLoading}
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            id="rightsToUnitTrustDialog-valuationTokenTokenID-autoCompleteTextField"
                            label="Valuation Stablecoin"
                            InputProps={{
                              ...params.InputProps,
                              placeholder:
                                unitTrustDraftUpdateOnlyFieldViewModeOn
                                  ? unitTrustStablecoin.valuationToken.isUndefined()
                                    ? unitTrustStablecoin.valuationToken.string()
                                    : "-"
                                  : "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "unitTrustStablecoin-valuationToken"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "unitTrustStablecoin-valuationToken"
                              ]
                            }
                          />
                        )}
                      />
                    )}

                    {/* Row 2 */}
                    {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-unitTrustStablecoinOwnerID-textField"
                        sx={{ width: 250 }}
                        label="Owner Group"
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === unitTrust.ownerID,
                          );
                          return group ? group.name : "-";
                        })()}
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToUnitTrustDialog-unitTrustStablecoinOwnerID-autoComplete"
                        sx={{ width: 250 }}
                        disabled={
                          apiLoading || unitTrustDraftUpdateOnlyFieldViewModeOn
                        }
                        getOptionLabel={(option: Group) => option.name}
                        options={potentialGroupOwners}
                        loading={apiLoading}
                        onChange={(_, selected: Group | null) =>
                          handleChangeEntitiesInDialog(
                            "ownerID",
                            " ownerID",
                          )(selected ? selected.id : "")
                        }
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === unitTrust.ownerID,
                          );
                          return group || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToUnitTrustDialog-unitTrustStablecoinOwnerID-autoCompleteTextField"
                            label="Owner Group"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            disabled={apiLoading}
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "unitTrustStablecoin-ownerID"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "unitTrustStablecoin-ownerID"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    <DateField
                      label="Maturity Date"
                      disabled={apiLoading}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog("maturityDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      value={unitTrustStablecoin.maturityDate}
                      readOnly={unitTrustStablecoinMaturityDateReadOnly}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: 250 }}
                          id="rightsToUnitTrustDialog-unitTrustStablecoinMaturityDate-dateField"
                          disabled={apiLoading}
                          readOnly={unitTrustStablecoinMaturityDateReadOnly}
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-maturityDate"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-maturityDate"
                            ]
                          }
                        />
                      )}
                    />
                    {viewModeOn ? (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "160px 1fr",
                          alignItems: "center",
                        }}
                      >
                        <TextNumField
                          label="Max Units To Issue"
                          id="rightsToUnitTrustDialog-unitTrustStablecoinMaximumUnits-textNumField"
                          sx={{ width: 250 }}
                          disabled={apiLoading}
                          readOnly
                          placeholder="0.00"
                          value={unitTrustStablecoin.maximumUnits.value}
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-maximumUnits"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "unitTrustStablecoin-maximumUnits"
                            ]
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                children={
                                  <Tooltip
                                    placement="top"
                                    title="Maximum number of tokens the issuer is looking to mint on Mesh"
                                  >
                                    <InfoIcon
                                      sx={{
                                        color: theme.palette.action.disabled,
                                        "&:hover": {
                                          color: theme.palette.action.active,
                                        },
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                }
                              />
                            ),
                          }}
                        />
                      </Box>
                    ) : (
                      <TextNumField
                        label="Max Units To Issue"
                        id="rightsToUnitTrustDialog-unitTrustStablecoinMaximumUnits-textNumField"
                        sx={{ width: 250 }}
                        disabled={apiLoading}
                        readOnly={unitTrustStablecoinMaximumUnitsReadOnly}
                        placeholder="0.00"
                        value={unitTrustStablecoin.maximumUnits.value}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) =>
                          handleChangeEntitiesInDialog("maximumUnits")(
                            unitTrustStablecoin.maximumUnits.setValue(
                              e.target.value,
                            ),
                          )
                        }
                        disallowNegative
                        helperText={
                          step1ValidationResult.fieldValidations[
                            "unitTrustStablecoin-maximumUnits"
                          ]
                        }
                        error={
                          !!step1ValidationResult.fieldValidations[
                            "unitTrustStablecoin-maximumUnits"
                          ]
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              children={
                                <Tooltip
                                  placement="top"
                                  title="Maximum number of tokens the issuer is looking to mint on Mesh"
                                >
                                  <InfoIcon
                                    sx={{
                                      color: theme.palette.action.disabled,
                                      "&:hover": {
                                        color: theme.palette.action.active,
                                      },
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              }
                            />
                          ),
                        }}
                      />
                    )}

                    {viewModeOn ? (
                      <>
                        <TextField
                          label="Instrument Name"
                          id="rightsToUnitTrustDialog-unitTrustStablecoinInstrumentName-textField"
                          sx={{ width: 250 }}
                          readOnly
                          value={unitTrust.name ? unitTrust.name : "-"}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          label="Asset Class"
                          id="rightsToUnitTrustDialog-unitTrustStablecoinAssetClass-textField"
                          sx={{ width: 250 }}
                          readOnly
                          value={unitTrust.assetClass}
                          InputLabelProps={{ shrink: true }}
                        />
                        {mint &&
                          ![
                            "",
                            UnitTrustStablecoinState.Draft,
                            UnitTrustStablecoinState.PreIssued,
                          ].includes(unitTrustStablecoin.state) && (
                            <TextNumField
                              label="Issued Units"
                              id="rightsToUnitTrustDialog-unitsInIssue-textNumFieldReadOnly"
                              sx={{ width: 250 }}
                              disabled={apiLoading}
                              readOnly
                              InputLabelProps={{ shrink: true }}
                              value={mint.issuedUnits.value}
                            />
                          )}
                      </>
                    ) : (
                      <>
                        <div />
                        <div />
                        {mint && unitTrust.state !== UnitTrustState.Draft && (
                          <TextNumField
                            label="Issued Units"
                            id="rightsToUnitTrustDialog-unitsInIssue-textNumFieldReadOnly"
                            sx={{ width: 250 }}
                            disabled={apiLoading}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                            value={mint.issuedUnits.value}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Step 2
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step2
                    ? accordionSectionsOpen.step2
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step2: !accordionSectionsOpen.step2,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToUnitTrustDialog-step2-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToUnitTrustDialog-step2-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  sx={{
                    flexDirection: "row-reverse",
                    backgroundColor: theme.palette.custom.midnight,
                    padding: theme.spacing(0, 3),
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(1),
                      display: "grid",
                      gridTemplateColumns: "200px auto 1fr auto",
                      gridColumnGap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">Reference Instrument</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 2
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step2ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step2ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      unitTrustActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToUnitTrustDialogStep2-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${unitTrustStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(3),
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ maxWidth: 740 }}
                  >
                    Enter details of the existing instrument created outside of
                    Mesh. This instrument will be seen as the underlying
                    instrument of the one which you are currently building.
                  </Typography>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      rowGap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
                      variant="h6"
                      children="Instrument Info"
                    />
                    <Box />
                    <Box />

                    <TextField
                      label="Instrument Name"
                      id="rightsToUnitTrustDialog-unitTrustName-textField"
                      sx={{ width: 250 }}
                      disabled={apiLoading}
                      readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                      inputProps={{ maxLength: 40 }}
                      value={
                        unitTrustDraftUpdateOnlyFieldViewModeOn
                          ? unitTrust.name
                            ? unitTrust.name
                            : "-"
                          : unitTrust.name
                      }
                      placeholder="Enter an Instrument Name"
                      onChange={(e) =>
                        handleChangeEntitiesInDialog(
                          undefined,
                          "name",
                        )(e.target.value)
                      }
                      helperText={
                        step2ValidationResult.fieldValidations["unitTrust-name"]
                      }
                      error={
                        !!step2ValidationResult.fieldValidations[
                          "unitTrust-name"
                        ]
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <DateField
                      label="Issue Date"
                      disabled={apiLoading}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog(
                          undefined,
                          "issueDate",
                        )(newDate ? newDate.format() : "")
                      }
                      value={unitTrust.issueDate}
                      readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: 250 }}
                          id="rightsToUnitTrustDialog-unitTrustIssueDate-dateField"
                          readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                          helperText={
                            step2ValidationResult.fieldValidations[
                              "unitTrust-issueDate"
                            ]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations[
                              "unitTrust-issueDate"
                            ]
                          }
                        />
                      )}
                    />
                    <Box />

                    <TextField
                      label="ISIN (Optional)"
                      id="rightsToUnitTrustDialog-unitTrustISIN-textField"
                      sx={{ width: 250 }}
                      disabled={apiLoading}
                      readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                      inputProps={{ maxLength: 20 }}
                      value={
                        unitTrustDraftUpdateOnlyFieldViewModeOn
                          ? unitTrust.isin
                            ? unitTrust.isin
                            : "-"
                          : unitTrust.isin
                      }
                      placeholder="Enter an ISIN"
                      onChange={(e) =>
                        handleChangeEntitiesInDialog(
                          undefined,
                          "isin",
                        )(e.target.value)
                      }
                      helperText={
                        step2ValidationResult.fieldValidations["unitTrust-isin"]
                      }
                      error={
                        !!step2ValidationResult.fieldValidations[
                          "unitTrust-isin"
                        ]
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <DateField
                      label="Maturity Date"
                      disabled={apiLoading}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog(
                          undefined,
                          "maturityDate",
                        )(newDate ? newDate.format() : "")
                      }
                      value={unitTrust.maturityDate}
                      readOnly={unitTrustMaturityDateReadOnly}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: 250 }}
                          id="rightsToUnitTrustDialog-unitTrustIssueDate-dateField"
                          disabled={apiLoading}
                          readOnly={unitTrustMaturityDateReadOnly}
                          helperText={
                            step2ValidationResult.fieldValidations[
                              "unitTrust-maturityDate"
                            ]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations[
                              "unitTrust-maturityDate"
                            ]
                          }
                        />
                      )}
                    />
                    <Box />

                    <TextField
                      id="rightsToUnitTrustDialog-unitTrustAssetClass-textField"
                      sx={{ width: 250 }}
                      label="Asset Class"
                      value={unitTrust.assetClass}
                      readOnly
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      rowGap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
                      variant="h6"
                      children="Issuer Info"
                    />
                    <Typography
                      sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
                      variant="h6"
                      children="Management Fee (Optional)"
                    />
                    <Box />

                    <TextField
                      label="Name"
                      id="rightsToUnitTrustDialog-unitTrustIssuerName-textField"
                      sx={{ width: 250 }}
                      disabled={apiLoading}
                      readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                      inputProps={{ maxLength: 20 }}
                      value={
                        unitTrustDraftUpdateOnlyFieldViewModeOn
                          ? unitTrust.issuerName
                            ? unitTrust.issuerName
                            : "-"
                          : unitTrust.issuerName
                      }
                      placeholder={"Enter the Issuer's Name"}
                      onChange={(e) =>
                        handleChangeEntitiesInDialog(
                          undefined,
                          "issuerName",
                        )(e.target.value)
                      }
                      helperText={
                        step2ValidationResult.fieldValidations[
                          "unitTrust-issuerName"
                        ]
                      }
                      error={
                        !!step2ValidationResult.fieldValidations[
                          "unitTrust-issuerName"
                        ]
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-issuerManagementFeeType-textField"
                        sx={{ width: 250 }}
                        label="Type"
                        value={unitTrust.issuerManagementFee.instrumentManagementFeeType()}
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToUnitTrustDialog-issuerManagementFeeType-autoComplete"
                        sx={{ width: 250 }}
                        options={AllInstrumentManagementFeeTypes}
                        disabled={
                          apiLoading || unitTrustDraftUpdateOnlyFieldViewModeOn
                        }
                        disableClearable
                        value={unitTrust.issuerManagementFee.instrumentManagementFeeType()}
                        onChange={(__: object, value) => {
                          switch (value) {
                            case InstrumentManagementFeeType.CurrencyAmount:
                              handleChangeEntitiesInDialog(
                                undefined,
                                "issuerManagementFee",
                              )(new CurrencyAmountInstrumentManagementFee());
                              break;

                            case InstrumentManagementFeeType.Percentage:
                              handleChangeEntitiesInDialog(
                                undefined,
                                "issuerManagementFee",
                              )(new PercentageInstrumentManagementFee());
                              break;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToUnitTrustDialog-issuerManagementFeeType-autoCompleteTextField"
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            label="Type"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "unitTrust-issuerManagementFeeType"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "unitTrust-issuerManagementFeeType"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    <Box />

                    {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-unitTrustIssuerRatingAgency-textField"
                        sx={{ width: 250 }}
                        label="Rating Agency (Optional)"
                        value={
                          unitTrust.issuerRatingAgency
                            ? unitTrust.issuerRatingAgency
                            : "-"
                        }
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToUnitTrustDialog-unitTrustIssuerRatingAgency-autoComplete"
                        sx={{ width: 250 }}
                        options={AllRatingAgencies}
                        disabled={
                          apiLoading || unitTrustDraftUpdateOnlyFieldViewModeOn
                        }
                        value={
                          unitTrust.issuerRatingAgency
                            ? (unitTrust.issuerRatingAgency as RatingAgency)
                            : null
                        }
                        onChange={(__: object, value) =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "issuerRatingAgency",
                          )(value || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToUnitTrustDialog-refInstrumentIssuerRatingAgency-autoCompleteTextField"
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            label="Rating Agency (Optional)"
                            placeholder={
                              unitTrustDraftUpdateOnlyFieldViewModeOn
                                ? unitTrust.issuerRatingAgency
                                  ? unitTrust.issuerRatingAgency
                                  : "-"
                                : "Select..."
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "unitTrust-issuerRatingAgency"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "unitTrust-issuerRatingAgency"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToUnitTrustDialog-issuerManagementFeeFrequency-textField"
                        sx={{ width: 250 }}
                        label="Frequency"
                        value={
                          unitTrust.issuerManagementFeeFrequency
                            ? unitTrust.issuerManagementFeeFrequency
                            : "-"
                        }
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        disableClearable
                        id="rightsToUnitTrustDialog-issuerManagementFeeFrequency-autoComplete"
                        sx={{ width: 250 }}
                        options={AllInstrumentManagementFeeFrequencies}
                        disabled={
                          apiLoading || unitTrustDraftUpdateOnlyFieldViewModeOn
                        }
                        value={unitTrust.issuerManagementFeeFrequency}
                        onChange={(__: object, value) =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "issuerManagementFeeFrequency",
                          )(value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToUnitTrustDialog-issuerManagementFeeFrequency-autoCompleteTextField"
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            label="Frequency"
                            placeholder={
                              unitTrustDraftUpdateOnlyFieldViewModeOn
                                ? unitTrust.issuerManagementFeeFrequency
                                  ? unitTrust.issuerManagementFeeFrequency
                                  : "-"
                                : "Select..."
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "unitTrust-issuerManagementFeeFrequency"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "unitTrust-issuerManagementFeeFrequency"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    <Box />

                    {unitTrust.issuerRatingAgency ? (
                      unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                        <TextField
                          label="Credit Rating (Optional)"
                          id="rightsToUnitTrustDialog-unitTrustIssuerCreditRating-textField"
                          sx={{ width: 250 }}
                          readOnly
                          value={
                            unitTrust.issuerCreditRating
                              ? unitTrust.issuerCreditRating
                              : "-"
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          id="rightsToUnitTrustDialog-unitTrustIssuerCreditRating-autoComplete"
                          sx={{ width: 250 }}
                          options={
                            Ratings[unitTrust.issuerRatingAgency]
                              ? Ratings[unitTrust.issuerRatingAgency]
                              : []
                          }
                          disabled={
                            apiLoading ||
                            unitTrustDraftUpdateOnlyFieldViewModeOn
                          }
                          value={
                            unitTrust.issuerCreditRating
                              ? unitTrust.issuerCreditRating
                              : null
                          }
                          onChange={(__: object, value) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "issuerCreditRating",
                            )(value || "")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="rightsToUnitTrustDialog-refInstrumentIssuerCreditRating-autoCompleteTextField"
                              readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                              disabled={apiLoading}
                              label="Credit Rating (Optional)"
                              placeholder={
                                unitTrustDraftUpdateOnlyFieldViewModeOn
                                  ? unitTrust.issuerCreditRating
                                    ? unitTrust.issuerCreditRating
                                    : "-"
                                  : "Select..."
                              }
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                step1ValidationResult.fieldValidations[
                                  "unitTrust-issuerCreditRating"
                                ]
                              }
                              error={
                                !!step1ValidationResult.fieldValidations[
                                  "unitTrust-issuerCreditRating"
                                ]
                              }
                            />
                          )}
                        />
                      )
                    ) : (
                      <Tooltip
                        title="Select Rating Agency First"
                        placement="top"
                      >
                        <span>
                          <TextField
                            label="Credit Rating (Optional)"
                            id="rightsToUnitTrustDialog-unitTrustIssuerCreditRating-textField"
                            sx={{ width: 250 }}
                            disabled
                            readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                            value="-"
                            InputLabelProps={{ shrink: true }}
                          />
                        </span>
                      </Tooltip>
                    )}
                    {(() => {
                      switch (true) {
                        case unitTrust.issuerManagementFee instanceof
                          CurrencyAmountInstrumentManagementFee: {
                          const moneyManagementFee =
                            unitTrust.issuerManagementFee as CurrencyAmountInstrumentManagementFee;
                          if (
                            viewModeOn &&
                            moneyManagementFee.amount.currencyCode
                          ) {
                            return (
                              <CurrencyTextField
                                value={moneyManagementFee.amount}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      children={
                                        getFormat(
                                          moneyManagementFee.amount
                                            .currencyCode,
                                        ).grapheme
                                      }
                                    />
                                  ),
                                }}
                                readOnly
                              />
                            );
                          } else {
                            return (
                              <div
                                className={
                                  classes.step2IssuerManagementFeeFieldLayout
                                }
                              >
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  id="rightsToUnitTrustDialog-unitTrustIssuerManagementFeeCcy-autoComplete"
                                  className={
                                    classes.step2IssuerManagementFeeCcyField
                                  }
                                  options={ISO4217_currencyList}
                                  disabled={
                                    apiLoading ||
                                    unitTrustDraftUpdateOnlyFieldViewModeOn
                                  }
                                  value={
                                    moneyManagementFee.amount.currencyCode
                                      ? moneyManagementFee.amount.currencyCode
                                      : null
                                  }
                                  onChange={(__: object, value) =>
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "issuerManagementFee",
                                    )(
                                      value
                                        ? new CurrencyAmountInstrumentManagementFee(
                                            {
                                              ...unitTrust.issuerManagementFee,
                                              amount: new Money({
                                                ...moneyManagementFee.amount,
                                                currencyCode: value,
                                              }),
                                            },
                                          )
                                        : new CurrencyAmountInstrumentManagementFee(
                                            {
                                              ...unitTrust.issuerManagementFee,
                                              amount: new Money({
                                                ...moneyManagementFee.amount,
                                                currencyCode: "",
                                              }),
                                            },
                                          ),
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      id="rightsToUnitTrustDialog-unitTrustIssuerManagementFeeCcy-autoCompleteTextField"
                                      readOnly={
                                        unitTrustDraftUpdateOnlyFieldViewModeOn
                                      }
                                      placeholder={
                                        unitTrustDraftUpdateOnlyFieldViewModeOn
                                          ? moneyManagementFee.amount
                                              .currencyCode
                                            ? moneyManagementFee.amount
                                                .currencyCode
                                            : "-"
                                          : "Ccy"
                                      }
                                      variant="outlined"
                                      InputLabelProps={{ shrink: true }}
                                      helperText={
                                        step2ValidationResult.fieldValidations[
                                          "unitTrust-issuerManagementFeeCurrencyAmountCode"
                                        ]
                                      }
                                      error={
                                        !!step2ValidationResult
                                          .fieldValidations[
                                          "unitTrust-issuerManagementFeeCurrencyAmountCode"
                                        ]
                                      }
                                    />
                                  )}
                                />
                                <CurrencyTextField
                                  value={moneyManagementFee.amount}
                                  id="rightsToUnitTrustDialog-unitTrustIssuerManagementFeeAmount-ccyTextField"
                                  onChange={(e) =>
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "issuerManagementFee",
                                    )(
                                      new CurrencyAmountInstrumentManagementFee(
                                        {
                                          ...moneyManagementFee,
                                          amount: new Money(e),
                                        },
                                      ),
                                    )
                                  }
                                  InputProps={{ startAdornment: null }}
                                  readOnly={
                                    unitTrustDraftUpdateOnlyFieldViewModeOn
                                  }
                                  disabled={apiLoading}
                                  error={
                                    !!step2ValidationResult.fieldValidations[
                                      "unitTrust-issuerManagementFeeCurrencyAmount"
                                    ]
                                  }
                                  helperText={
                                    step2ValidationResult.fieldValidations[
                                      "unitTrust-issuerManagementFeeCurrencyAmount"
                                    ]
                                  }
                                />
                              </div>
                            );
                          }
                        }

                        case unitTrust.issuerManagementFee instanceof
                          PercentageInstrumentManagementFee: {
                          const percentageManagementFee =
                            unitTrust.issuerManagementFee as PercentageInstrumentManagementFee;
                          return (
                            <TextNumField
                              disallowNegative
                              disabled={apiLoading}
                              sx={{ width: viewModeOn ? 50 : 250 }}
                              readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                              id="rightsToUnitTrustDialog-issuerManagementFeePercentage-textNumField"
                              InputProps={{ endAdornment: "%" }}
                              placeholder="-"
                              value={percentageManagementFee.percentage}
                              onChange={(e) =>
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "issuerManagementFee",
                                )(
                                  new PercentageInstrumentManagementFee({
                                    ...percentageManagementFee,
                                    percentage: new BigNumber(e.target.value),
                                  }),
                                )
                              }
                              error={
                                !!step2ValidationResult.fieldValidations[
                                  "unitTrust-issuerManagementFeePercentage"
                                ]
                              }
                              helperText={
                                step2ValidationResult.fieldValidations[
                                  "unitTrust-issuerManagementFeePercentage"
                                ]
                              }
                            />
                          );
                        }

                        default:
                          return null;
                      }
                    })()}
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Step 3
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step3
                    ? accordionSectionsOpen.step3
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step3: !accordionSectionsOpen.step3,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToUnitTrustDialog-step3-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToUnitTrustDialog-step3-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  sx={{
                    flexDirection: "row-reverse",
                    backgroundColor: theme.palette.custom.midnight,
                    padding: theme.spacing(0, 3),
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(1),
                      display: "grid",
                      gridTemplateColumns: "200px auto 1fr auto",
                      gridColumnGap: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h6">Asset Exposure</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 3
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step3ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step3ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      unitTrustActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToUnitTrustDialogStep3-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToUnitTrust}?&id=${unitTrustStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacing(3),
                  }}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ maxWidth: 740 }}
                  >
                    Enter details of the division of assets within your
                    investment portfolio.
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(1),
                      }}
                    >
                      <Typography
                        sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
                        variant="h6"
                        children="Investor Profile"
                      />
                      {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                        <TextField
                          label="Investor"
                          id="rightsToUnitTrustDialog-unitTrustInvestorProfile-textField"
                          sx={{ width: 250 }}
                          readOnly
                          value={
                            unitTrust.investorProfile
                              ? unitTrust.investorProfile
                              : "-"
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          id="rightsToUnitTrustDialog-unitTrustInvestorProfile-autocomplete"
                          sx={{ width: 250 }}
                          options={AllInvestorProfiles}
                          disabled={
                            apiLoading ||
                            unitTrustDraftUpdateOnlyFieldViewModeOn
                          }
                          value={
                            unitTrust.investorProfile
                              ? (unitTrust.investorProfile as InvestorProfile)
                              : null
                          }
                          onChange={(__: object, value) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "investorProfile",
                            )(value || "")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="rightsToUnitTrustDialog-unitTrustInvestorProfile-autocompleteTextField"
                              label="Investor"
                              readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                              disabled={apiLoading}
                              placeholder={
                                unitTrustDraftUpdateOnlyFieldViewModeOn
                                  ? unitTrust.investorProfile
                                    ? unitTrust.investorProfile
                                    : "-"
                                  : "Select..."
                              }
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              helperText={
                                step3ValidationResult.fieldValidations[
                                  "unitTrust-investorProfile"
                                ]
                              }
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-investorProfile"
                                ]
                              }
                            />
                          )}
                        />
                      )}
                      {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                        <Typography
                          className={cx(
                            classes.step3GeneralDescriptionFieldTextAreaViewMode,
                            "meshScroll",
                          )}
                          variant="body1"
                          color="textSecondary"
                          children={unitTrust.investorProfileDescription}
                        />
                      ) : (
                        <div
                          className={classes.step3GeneralDescriptionFieldLayout}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            children="Description  (Optional)"
                          />
                          <TextareaAutosize
                            id="rightsToUnitTrustDialog-unitTrustInvestorProfileDescription-textArea"
                            minRows={5}
                            maxRows={5}
                            disabled={apiLoading}
                            value={unitTrust.investorProfileDescription}
                            onChange={(e) => {
                              let newValue: string = e.target.value;
                              if (newValue.length >= 500) {
                                newValue = newValue.slice(0, 500);
                              }
                              handleChangeEntitiesInDialog(
                                undefined,
                                "investorProfileDescription",
                              )(newValue);
                            }}
                            className={cx(
                              classes.step3GeneralDescriptionFieldTextArea,
                              "meshScroll",
                            )}
                          />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            children={`${
                              500 - unitTrust.investorProfileDescription.length
                            } characters left`}
                          />
                        </div>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(1),
                      }}
                    >
                      <Typography
                        sx={(theme) => ({ paddingBottom: theme.spacing(1) })}
                        variant="h6"
                        children="Risk Profile"
                      />
                      {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                        <TextField
                          label="Investor"
                          id="rightsToUnitTrustDialog-unitTrustRiskProfile-textField"
                          sx={{ width: 250 }}
                          readOnly
                          value={
                            unitTrust.riskProfile ? unitTrust.riskProfile : "-"
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          id="rightsToUnitTrustDialog-unitTrustRiskProfile-autocomplete"
                          sx={{ width: 250 }}
                          options={AllInstrumentRiskProfiles}
                          disabled={
                            apiLoading ||
                            unitTrustDraftUpdateOnlyFieldViewModeOn
                          }
                          value={
                            unitTrust.riskProfile
                              ? (unitTrust.riskProfile as InstrumentRiskProfile)
                              : null
                          }
                          onChange={(__: object, value) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "riskProfile",
                            )(value || "")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              readOnly={unitTrustDraftUpdateOnlyFieldViewModeOn}
                              disabled={apiLoading}
                              id="rightsToUnitTrustDialog-unitTrustRiskProfile-autocompleteTextField"
                              InputLabelProps={{ shrink: true }}
                              label="Risk"
                              placeholder={
                                unitTrustDraftUpdateOnlyFieldViewModeOn
                                  ? unitTrust.riskProfile
                                    ? unitTrust.riskProfile
                                    : "-"
                                  : "Select..."
                              }
                              variant="outlined"
                              helperText={
                                step3ValidationResult.fieldValidations[
                                  "unitTrust-riskProfile"
                                ]
                              }
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-riskProfile"
                                ]
                              }
                            />
                          )}
                        />
                      )}
                      {unitTrustDraftUpdateOnlyFieldViewModeOn ? (
                        <Typography
                          className={cx(
                            classes.step3GeneralDescriptionFieldTextAreaViewMode,
                            "meshScroll",
                          )}
                          variant="body1"
                          color="textSecondary"
                          children={unitTrust.riskProfileDescription}
                        />
                      ) : (
                        <div
                          className={classes.step3GeneralDescriptionFieldLayout}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            children="Description (Optional)"
                          />
                          <TextareaAutosize
                            minRows={5}
                            maxRows={5}
                            disabled={apiLoading}
                            id="rightsToUnitTrustDialog-unitTrustRiskProfileDescription-textArea"
                            value={unitTrust.riskProfileDescription}
                            onChange={(e) => {
                              let newValue: string = e.target.value;
                              if (newValue.length >= 500) {
                                newValue = newValue.slice(0, 500);
                              }
                              handleChangeEntitiesInDialog(
                                undefined,
                                "riskProfileDescription",
                              )(newValue);
                            }}
                            className={cx(
                              classes.step3GeneralDescriptionFieldTextArea,
                              "meshScroll",
                            )}
                          />
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            children={`${
                              500 - unitTrust.riskProfileDescription.length
                            } characters left`}
                          />
                        </div>
                      )}
                    </Box>
                  </Box>

                  {!viewModeOn && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ maxWidth: 740 }}
                    >
                      Enter details of asset exposure allocation.
                    </Typography>
                  )}

                  {viewModeOn ||
                  unitTrustHoldingsAndAllocationsSectionReadOnly ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: theme.spacing(1),
                      }}
                    >
                      {[
                        // Country Allocations
                        <>
                          <Typography
                            variant="h6"
                            children="Country Allocation"
                          />
                          {accordionSectionsOpen.step3 && (
                            <CountryAllocationPieChart
                              height={210}
                              countryAllocations={unitTrust.countryAllocations}
                            />
                          )}
                          <div
                            className={cx(
                              classes.holdingsAndAllocationsViewModeList,
                              "meshScroll",
                            )}
                          >
                            {unitTrust.countryAllocations.map((ca, idx) => (
                              <div
                                key={idx}
                                className={
                                  classes.holdingsAndAllocationsViewModeLineItem
                                }
                                style={{
                                  width: window.innerWidth * (0.5 / 3) - 50,
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={`${formatTextNum(ca.percentage, {
                                    addDecimalPadding: true,
                                  })}%`}
                                />
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={
                                    ca.name
                                      ? countries.find(
                                          (c) => c.value === ca.name,
                                        )?.label
                                      : "-"
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </>,
                        // Holding Allocations
                        <>
                          <Typography variant="h6" children="Holdings" />
                          {accordionSectionsOpen.step3 && (
                            <HoldingsPieChart
                              height={210}
                              holdings={unitTrust.holdings}
                            />
                          )}
                          <div
                            className={cx(
                              classes.holdingsAndAllocationsViewModeList,
                              "meshScroll",
                            )}
                          >
                            {unitTrust.holdings.map((ca, idx) => (
                              <div
                                key={idx}
                                className={
                                  classes.holdingsAndAllocationsViewModeLineItem
                                }
                                style={{
                                  width: window.innerWidth * (0.5 / 3) - 50,
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={`${formatTextNum(ca.percentage, {
                                    addDecimalPadding: true,
                                  })}%`}
                                />
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={ca.name ? ca.name : "-"}
                                />
                              </div>
                            ))}
                          </div>
                        </>,
                        // Sector Allocations
                        <>
                          <Typography
                            variant="h6"
                            children="Sector Allocation"
                          />
                          {accordionSectionsOpen.step3 && (
                            <SectorAllocationPieChart
                              height={210}
                              sectorAllocations={unitTrust.sectorAllocations}
                            />
                          )}
                          <div
                            className={cx(
                              classes.holdingsAndAllocationsViewModeList,
                              "meshScroll",
                            )}
                          >
                            {unitTrust.sectorAllocations.map((ca, idx) => (
                              <div
                                key={idx}
                                className={
                                  classes.holdingsAndAllocationsViewModeLineItem
                                }
                                style={{
                                  width: window.innerWidth * (0.5 / 3) - 50,
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={`${formatTextNum(ca.percentage, {
                                    addDecimalPadding: true,
                                  })}%`}
                                />
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  children={ca.sector ? ca.sector : "-"}
                                />
                              </div>
                            ))}
                          </div>
                        </>,
                      ].map((section, idx) => (
                        <Box
                          key={`holdingsAndAllocationSection-${idx}`}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: theme.spacing(2),
                          }}
                        >
                          {section}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: theme.spacing(1),
                      }}
                    >
                      {/* -------------- Country Allocation -------------- */}
                      <Box sx={{ maxWidth: 290 }}>
                        <Typography
                          variant="h6"
                          children="Country Allocation"
                        />
                        {(() => {
                          const totalAllocation =
                            unitTrust.countryAllocations.reduce(
                              (total, ca) => ca.percentage.plus(total),
                              new BigNumber(0),
                            );
                          return (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={(theme) => ({
                                paddingTop: theme.spacing(0.5),
                              })}
                              className={cx({
                                [classes.successText]:
                                  totalAllocation.isEqualTo(new BigNumber(100)),
                                [classes.errorText]:
                                  touchedFields.countryAllocations &&
                                  (totalAllocation.isZero() ||
                                    totalAllocation.isGreaterThan(
                                      new BigNumber(100),
                                    )),
                              })}
                              children={`Total Allocated: ${totalAllocation} %`}
                            />
                          );
                        })()}
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              disabled={apiLoading}
                              id="rightsToUnitTrustDialog-unitTrustCountryAllocationControlRowName-autocomplete"
                              sx={{
                                width: 170,
                                paddingRight: theme.spacing(1),
                              }}
                              getOptionLabel={(c) => c.label}
                              options={countries}
                              value={(() => {
                                const c = countries.find(
                                  (countryOption) =>
                                    countryOption.value ===
                                    pendingCountryAllocation.name,
                                );
                                return c || null;
                              })()}
                              onChange={(
                                __: object,
                                country: CountryOption | null,
                              ) => {
                                if (country) {
                                  setPendingCountryAllocation(
                                    new CountryAllocation({
                                      ...pendingCountryAllocation,
                                      name: country.value,
                                    }),
                                  );
                                } else {
                                  setPendingCountryAllocation(
                                    new CountryAllocation({
                                      ...pendingCountryAllocation,
                                      name: "",
                                    }),
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  disabled={apiLoading}
                                  id="rightsToUnitTrustDialog-unitTrustCountryAllocationControlRowName-autocompleteTextField"
                                  InputLabelProps={{ shrink: true }}
                                  placeholder="Select..."
                                  variant="outlined"
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      "unitTrust-countryAllocations"
                                    ]
                                  }
                                />
                              )}
                            />
                            <TextNumField
                              disallowNegative
                              disabled={apiLoading}
                              sx={{ width: 75 }}
                              id="rightsToUnitTrustDialog-unitTrustCountryAllocationControlRowPercentage-textNumField"
                              InputProps={{ endAdornment: "%" }}
                              placeholder="-"
                              value={pendingCountryAllocation.percentage}
                              onChange={(e) =>
                                setPendingCountryAllocation({
                                  ...pendingCountryAllocation,
                                  percentage: new BigNumber(e.target.value),
                                })
                              }
                              inputProps={{ maxLength: 40 }}
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-countryAllocations"
                                ]
                              }
                            />
                            <IconButton
                              size="small"
                              disabled={apiLoading}
                              id="rightsToUnitTrustDialog-unitTrustCountryAllocationControlAdd-button"
                              onClick={() => {
                                if (!pendingCountryAllocation.name) {
                                  return;
                                }
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "countryAllocations",
                                )([
                                  ...unitTrust.countryAllocations,
                                  new CountryAllocation(
                                    pendingCountryAllocation,
                                  ),
                                ]);
                                setPendingCountryAllocation(
                                  new CountryAllocation(),
                                );
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Box>
                          {!!step3ValidationResult.fieldValidations[
                            "unitTrust-countryAllocations"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              children={
                                step3ValidationResult.fieldValidations[
                                  "unitTrust-countryAllocations"
                                ]
                              }
                            />
                          )}
                        </Box>
                        <Box
                          className={"meshScroll"}
                          sx={{
                            maxHeight: 180,
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {unitTrust.countryAllocations.map(
                            (countryAllocation, idx) => (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    disableClearable
                                    disabled={apiLoading}
                                    id={`rightsToUnitTrustDialog-unitTrustCountryAllocationLineCountryCode-autocomplete-${idx}`}
                                    sx={{
                                      width: 170,
                                      paddingRight: theme.spacing(1),
                                    }}
                                    getOptionLabel={(c) => c.label}
                                    options={countries}
                                    value={countries.find(
                                      (countryOption) =>
                                        countryOption.value ===
                                        countryAllocation.name,
                                    )}
                                    onChange={(
                                      __: object,
                                      country: CountryOption | null,
                                    ) => {
                                      if (country) {
                                        unitTrust.countryAllocations[idx].name =
                                          country.value;
                                      } else {
                                        unitTrust.countryAllocations[idx].name =
                                          "";
                                      }
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(unitTrust.countryAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        disabled={apiLoading}
                                        id={`rightsToUnitTrustDialog-unitTrustCountryAllocationLineCountryCode-autocompleteTextField-${idx}`}
                                        placeholder="..."
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step3ValidationResult
                                            .fieldValidations[
                                            `unitTrust-countryAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`rightsToUnitTrustDialog-unitTrustCountryAllocationLinePercentage-textNumField-${idx}`}
                                    sx={{ width: 75 }}
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={countryAllocation.percentage}
                                    onChange={(e) => {
                                      unitTrust.countryAllocations[
                                        idx
                                      ].percentage = new BigNumber(
                                        e.target.value,
                                      );
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(unitTrust.countryAllocations);
                                    }}
                                    error={
                                      !!step3ValidationResult.fieldValidations[
                                        `unitTrust-countryAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`rightsToUnitTrustDialog-unitTrustCountryAllocationLinePercentage-textNumField-${idx}`}
                                    onClick={() =>
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(
                                        unitTrust.countryAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Box>
                                {!!step3ValidationResult.fieldValidations[
                                  `unitTrust-countryAllocations-${idx}-name`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `unitTrust-countryAllocations-${idx}-name`
                                      ]
                                    }
                                  />
                                )}
                                {!!step3ValidationResult.fieldValidations[
                                  `unitTrust-countryAllocations-${idx}-percentage`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `unitTrust-countryAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                )}
                              </>
                            ),
                          )}
                        </Box>
                      </Box>

                      {/* -------------- Holding Allocation -------------- */}
                      <Box sx={{ maxWidth: 290 }}>
                        <Typography variant="h6" children="Holdings" />
                        {(() => {
                          const totalAllocation = unitTrust.holdings.reduce(
                            (total, holding) => holding.percentage.plus(total),
                            new BigNumber("0"),
                          );
                          return (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={(theme) => ({
                                paddingTop: theme.spacing(0.5),
                              })}
                              className={cx({
                                [classes.successText]:
                                  totalAllocation.isEqualTo(new BigNumber(100)),
                                [classes.errorText]:
                                  touchedFields.holdings &&
                                  (totalAllocation.isZero() ||
                                    totalAllocation.isGreaterThan(
                                      new BigNumber(100),
                                    )),
                              })}
                              children={`Total Allocated: ${totalAllocation} %`}
                            />
                          );
                        })()}
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              placeholder="Add a Holding"
                              value={pendingHolding.name}
                              sx={{
                                width: 170,
                                paddingRight: theme.spacing(1),
                              }}
                              inputProps={{ maxLength: 40 }}
                              id="rightsToUnitTrustDialog-unitTrustHoldingControlRowName-textField"
                              disabled={apiLoading}
                              onChange={(e) =>
                                setPendingHolding(
                                  new Holding({
                                    ...pendingHolding,
                                    name: e.target.value,
                                  }),
                                )
                              }
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-holdings"
                                ]
                              }
                            />
                            <TextNumField
                              disallowNegative
                              disabled={apiLoading}
                              sx={{ width: 75 }}
                              id="rightsToUnitTrustDialog-unitTrustHoldingControlRowPercentage-textNumField"
                              InputProps={{ endAdornment: "%" }}
                              placeholder="-"
                              value={pendingHolding.percentage}
                              onChange={(e) =>
                                setPendingHolding({
                                  ...pendingHolding,
                                  percentage: new BigNumber(e.target.value),
                                })
                              }
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-holdings"
                                ]
                              }
                            />
                            <IconButton
                              size="small"
                              disabled={apiLoading}
                              id="rightsToUnitTrustDialog-unitTrustHoldingControlRowAdd-button"
                              onClick={() => {
                                if (!pendingHolding.name) {
                                  return;
                                }
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "holdings",
                                )([
                                  ...unitTrust.holdings,
                                  new Holding(pendingHolding),
                                ]);
                                setPendingHolding(new Holding());
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Box>
                          {!!step3ValidationResult.fieldValidations[
                            "unitTrust-holdings"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              children={
                                step3ValidationResult.fieldValidations[
                                  "unitTrust-holdings"
                                ]
                              }
                            />
                          )}
                        </Box>
                        <Box
                          className={"meshScroll"}
                          sx={{
                            maxHeight: 180,
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {unitTrust.holdings.map((holding, idx) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  disabled={apiLoading}
                                  inputProps={{ maxLength: 40 }}
                                  id={`rightsToUnitTrustDialog-unitTrustHoldingRow-${idx}-name-textField`}
                                  sx={{
                                    width: 170,
                                    paddingRight: theme.spacing(1),
                                  }}
                                  value={holding.name}
                                  onChange={(e) => {
                                    unitTrust.holdings[idx].name =
                                      e.target.value;
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(unitTrust.holdings);
                                  }}
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      `unitTrust-holdings-${idx}-name`
                                    ]
                                  }
                                />
                                <TextNumField
                                  disallowNegative
                                  disabled={apiLoading}
                                  id={`rightsToUnitTrustDialog-unitTrustHoldingControlRow-${idx}-percentage-textNumField`}
                                  sx={{ width: 75 }}
                                  InputProps={{ endAdornment: "%" }}
                                  placeholder="-"
                                  value={holding.percentage}
                                  onChange={(e) => {
                                    unitTrust.holdings[idx].percentage =
                                      new BigNumber(e.target.value);
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(unitTrust.holdings);
                                  }}
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      `unitTrust-holdings-${idx}-percentage`
                                    ]
                                  }
                                />
                                <IconButton
                                  size="small"
                                  disabled={apiLoading}
                                  id={`rightsToUnitTrustDialog-unitTrustHoldingRow-${idx}-remove-button`}
                                  onClick={() =>
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(
                                      unitTrust.holdings.filter(
                                        (_, i) => i !== idx,
                                      ),
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Box>
                              {!!step3ValidationResult.fieldValidations[
                                `unitTrust-holdings-${idx}-name`
                              ] && (
                                <Typography
                                  color="error"
                                  variant="body2"
                                  children={
                                    step3ValidationResult.fieldValidations[
                                      `unitTrust-holdings-${idx}-name`
                                    ]
                                  }
                                />
                              )}
                              {!!step3ValidationResult.fieldValidations[
                                `unitTrust-holdings-${idx}-percentage`
                              ] && (
                                <Typography
                                  color="error"
                                  variant="body2"
                                  children={
                                    step3ValidationResult.fieldValidations[
                                      `unitTrust-holdings-${idx}-percentage`
                                    ]
                                  }
                                />
                              )}
                            </>
                          ))}
                        </Box>
                      </Box>

                      {/* /!* -------------- Sector Allocation -------------- *!/ */}
                      <Box sx={{ maxWidth: 290 }}>
                        <Typography variant="h6" children="Sector Allocation" />
                        {(() => {
                          const totalAllocation =
                            unitTrust.sectorAllocations.reduce(
                              (total, ca) => ca.percentage.plus(total),
                              new BigNumber("0"),
                            );
                          return (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={(theme) => ({
                                paddingTop: theme.spacing(0.5),
                              })}
                              className={cx({
                                [classes.successText]:
                                  totalAllocation.isEqualTo(new BigNumber(100)),
                                [classes.errorText]:
                                  touchedFields.sectorAllocations &&
                                  (totalAllocation.isZero() ||
                                    totalAllocation.isGreaterThan(
                                      new BigNumber(100),
                                    )),
                              })}
                              children={`Total Allocated: ${totalAllocation} %`}
                            />
                          );
                        })()}
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              id="rightsToUnitTrustDialog-unitTrustSectorControlRowSector-autoComplete"
                              sx={{
                                width: 170,
                                paddingRight: theme.spacing(1),
                              }}
                              options={AllFinancialSectors}
                              disabled={apiLoading}
                              value={
                                pendingSectorAllocation.sector
                                  ? pendingSectorAllocation.sector
                                  : null
                              }
                              onChange={(__: object, value) =>
                                setPendingSectorAllocation(
                                  new SectorAllocation({
                                    ...pendingSectorAllocation,
                                    sector: value || "",
                                  }),
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="rightsToUnitTrustDialog-unitTrustSectorControlRowSector-autoCompleteTextField"
                                  placeholder={
                                    pendingSectorAllocation.sector
                                      ? pendingSectorAllocation.sector
                                        ? pendingSectorAllocation.sector
                                        : "-"
                                      : "Select..."
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      "unitTrust-sectorAllocations"
                                    ]
                                  }
                                />
                              )}
                            />
                            <TextNumField
                              disallowNegative
                              disabled={apiLoading}
                              sx={{ width: 75 }}
                              id="rightsToUnitTrustDialog-sectorControlRowPercentage-textNumField"
                              InputProps={{ endAdornment: "%" }}
                              placeholder="-"
                              value={pendingSectorAllocation.percentage}
                              onChange={(e) =>
                                setPendingSectorAllocation({
                                  ...pendingSectorAllocation,
                                  percentage: new BigNumber(e.target.value),
                                })
                              }
                              error={
                                !!step3ValidationResult.fieldValidations[
                                  "unitTrust-sectorAllocations"
                                ]
                              }
                            />
                            <IconButton
                              size="small"
                              disabled={apiLoading}
                              id="rightsToUnitTrustDialog-sectorControlRowAdd-button"
                              onClick={() => {
                                if (!pendingSectorAllocation.sector) {
                                  return;
                                }
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "sectorAllocations",
                                )([
                                  ...unitTrust.sectorAllocations,
                                  new SectorAllocation(pendingSectorAllocation),
                                ]);
                                setPendingSectorAllocation(
                                  new SectorAllocation(),
                                );
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Box>
                          {!!step3ValidationResult.fieldValidations[
                            "unitTrust-sectorAllocations"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              children={
                                step3ValidationResult.fieldValidations[
                                  "unitTrust-sectorAllocations"
                                ]
                              }
                            />
                          )}
                        </Box>
                        <Box
                          className={"meshScroll"}
                          sx={{
                            maxHeight: 180,
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {unitTrust.sectorAllocations.map(
                            (sectorAllocation, idx) => (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    id={`rightsToUnitTrustDialog-unitTrustSectorControlRowName-autocomplete-${idx}`}
                                    sx={{
                                      width: 170,
                                      paddingRight: theme.spacing(1),
                                    }}
                                    options={AllFinancialSectors}
                                    disabled={apiLoading}
                                    value={
                                      sectorAllocation.sector
                                        ? sectorAllocation.sector
                                        : null
                                    }
                                    onChange={(__: object, value) => {
                                      unitTrust.sectorAllocations[idx].sector =
                                        value || "";
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(unitTrust.sectorAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id={`rightsToUnitTrustDialog-unitTrustSectorControlRowName-autocompleteTextField-${idx}`}
                                        placeholder={
                                          pendingSectorAllocation.sector
                                            ? pendingSectorAllocation.sector
                                              ? pendingSectorAllocation.sector
                                              : "-"
                                            : "Select..."
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step3ValidationResult
                                            .fieldValidations[
                                            `unitTrust-sectorAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`rightsToUnitTrustDialog-unitTrustSectorControlRowPercentage-textField-${idx}`}
                                    sx={{ width: 75 }}
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={sectorAllocation.percentage}
                                    onChange={(e) => {
                                      unitTrust.sectorAllocations[
                                        idx
                                      ].percentage = new BigNumber(
                                        e.target.value,
                                      );
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(unitTrust.sectorAllocations);
                                    }}
                                    error={
                                      !!step3ValidationResult.fieldValidations[
                                        `unitTrust-sectorAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`rightsToUnitTrustDialog-unitTrustSectorControlRowAdd-button-${idx}`}
                                    onClick={() =>
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(
                                        unitTrust.sectorAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Box>
                                {!!step3ValidationResult.fieldValidations[
                                  `unitTrust-sectorAllocations-${idx}-name`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `unitTrust-sectorAllocations-${idx}-name`
                                      ]
                                    }
                                  />
                                )}
                                {!!step3ValidationResult.fieldValidations[
                                  `unitTrust-sectorAllocations-${idx}-percentage`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `unitTrust-sectorAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                )}
                              </>
                            ),
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {/* -------------- Returns Log -------------- */}
                  <Typography variant="h6" children="Returns Log" />
                  {!unitTrustAnnualPerformanceLogReadOnly && (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        alignItems: "center",
                        columnGap: theme.spacing(1),
                        margin: theme.spacing(-1, 0, 1, 0),
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        children="Add returns by month and annually in %"
                      />
                      {!!step3ValidationResult.fieldValidations[
                        "unitTrust-annualPerformanceLog"
                      ] && (
                        <Typography
                          color="error"
                          variant="body2"
                          children={
                            step3ValidationResult.fieldValidations[
                              "unitTrust-annualPerformanceLog"
                            ]
                          }
                        />
                      )}
                    </Box>
                  )}
                  <ViewEditInstrumentAnnualPerformanceLog
                    annualPerformanceLog={unitTrust.annualPerformanceLog}
                    onChange={(updated) =>
                      handleChangeEntitiesInDialog(
                        undefined,
                        "annualPerformanceLog",
                      )(updated)
                    }
                    disabled={apiLoading}
                    viewMode={unitTrustAnnualPerformanceLogReadOnly}
                    fieldValidations={step3ValidationResult.fieldValidations}
                    issueDate={unitTrust.issueDate}
                    maturityDate={unitTrust.maturityDate}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box
            sx={{
              padding: theme.spacing(5, 3.5, 2, 3.5),
            }}
          >
            {!viewModeOn &&
            unitTrustActionsViewConfig.Update &&
            !unitTrustSupportingDocumentsReadOnly ? (
              <div className={classes.docsMediaColContent}>
                <div className={classes.docsMediaColContentWarningMsg}>
                  <Typography
                    variant="h6"
                    children="Supporting Documents/Media"
                  />
                  {!!supportingDocsStepValidationResult.fieldValidations[
                    "unitTrust-supportingDocuments"
                  ] && (
                    <Typography
                      variant="body2"
                      color="error"
                      children={
                        supportingDocsStepValidationResult.fieldValidations[
                          "unitTrust-supportingDocuments"
                        ]
                      }
                    />
                  )}
                </div>
                <div
                  {...dropZoneProps.getRootProps()}
                  className={cx(classes.dropZone, {
                    [classes.dropZoneAccept]: dropZoneProps.isDragAccept,
                    [classes.dropZoneReject]: dropZoneProps.isDragReject,
                  })}
                >
                  <input
                    {...dropZoneProps.getInputProps()}
                    disabled={apiLoading || !!pendingDocumentUploads.length}
                  />
                  {(() => {
                    if (pendingDocumentUploads.length) {
                      return (
                        <>
                          <Typography
                            variant="body2"
                            children="Uploads in Progress"
                          />
                          <CircularProgress />
                        </>
                      );
                    }

                    if (dropZoneProps.isDragActive) {
                      return dropZoneProps.isDragAccept ? (
                        <>
                          <Typography
                            variant="body1"
                            className={classes.successText}
                            children="Drop Documents For Upload"
                          />
                          <UploadIcon className={classes.successText} />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body1"
                            color="error"
                            children="Unsupported Document Type"
                          />
                          <NotAllowedIcon color="error" />
                        </>
                      );
                    }
                    return (
                      <>
                        <Typography
                          variant="body2"
                          children="Drop Documents Here to Upload"
                        />
                        <Typography variant="body2" children="or" />
                        <Button
                          disabled={apiLoading}
                          id="unitTrustDialog-selectFilesUpload-button"
                          variant="contained"
                          color="secondary"
                          children="Select Files"
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.dropZoneGuidanceText}
                          children=".png, .jpeg, or .pdf up to max size of 20MB each"
                        />
                      </>
                    );
                  })()}
                </div>
                {/* -----------------Render Upload in Progress------------------ */}
                {pendingDocumentUploads.map((d, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id="unitTrustDialog-description-formField"
                      label="Description"
                      value={d.file.name}
                      disabled
                    />
                    {d.complete ? (
                      <DoneIcon className={classes.successText} />
                    ) : (
                      <CircularProgress
                        className={classes.docsMediaLineItemMarginTop}
                        size={25}
                        variant={d.percent ? "determinate" : "indeterminate"}
                        value={d.percent}
                      />
                    )}
                    <Typography
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="secondary"
                      children={d.file.name}
                    />
                  </div>
                ))}

                {/* -----------------Render Existing Docs------------------ */}
                {unitTrust.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id={`unitTrustDialog-addAName-${doc.name}formField`}
                      label="Name"
                      value={doc.description}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Add a Name"
                      disabled={apiLoading || !!pendingDocumentUploads.length}
                      onChange={(e) => {
                        unitTrust.supportingDocuments[idx].description =
                          e.target.value;
                        handleChangeEntitiesInDialog(
                          undefined,
                          "supportingDocuments",
                          [`supportingDocuments-${idx}-description`],
                        )(unitTrust.supportingDocuments);
                      }}
                      error={
                        !!supportingDocsStepValidationResult.fieldValidations[
                          `unitTrust-supportingDocuments-${idx}-description`
                        ]
                      }
                      helperText={
                        supportingDocsStepValidationResult.fieldValidations[
                          `unitTrust-supportingDocuments-${idx}-description`
                        ]
                      }
                    />
                    <span>
                      <IconButton
                        id={`unitTrustDialog-deleteDocument-${doc.name}-button`}
                        className={classes.docsMediaLineItemBinIcon}
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        onClick={() =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "supportingDocuments",
                          )(
                            unitTrust.supportingDocuments.filter(
                              (_, i) => i !== idx,
                            ),
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                    <Typography
                      id={`unitTrustDialog-docName-${doc.name}typography`}
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="secondary"
                      children={doc.name}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={classes.docsMediaColContent}>
                <Typography
                  variant="h6"
                  children="Supporting Documents/Media"
                />
                {!unitTrust.supportingDocuments.length && (
                  <Typography
                    className={classes.docsMediaLineItemFileName}
                    variant="body2"
                    color="textSecondary"
                    children="Nothing has been uploaded yet"
                  />
                )}
                {/* -----------------Render Existing Docs------------------ */}
                {unitTrust.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      children={doc.description ? doc.description : "-"}
                    />
                    <div className={classes.docsMediaLineItemFileNameWithDL}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        children={doc.name}
                      />
                      <IconButton
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        className={classes.docsMediaDownloadIconButton}
                        onClick={async () => {
                          setAPILoading(true);
                          try {
                            download(
                              (
                                await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                                  {
                                    context: authContext,
                                    documentID: doc.id,
                                  },
                                )
                              ).downloadURL,
                              doc.name,
                            );
                          } catch (e) {
                            const err =
                              errorContextErrorTranslator.translateError(e);
                            console.error(
                              `error requesting download url: ${
                                err.message ? err.message : err.toString()
                              }`,
                            );
                          }
                          setAPILoading(false);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Box>
        </DialogContent>
      </StyledDialog>
      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
      />
      <IssueWarningDialog
        showDialog={!!issueWarningDialogOptions}
        onCloseClick={() => setIssueWarningDialogOptions(null)}
        onYesClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.yesMethod
            : () => null
        }
        onNoClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.noMethod
            : () => null
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
        preIssuing={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.preIssuing
            : false
        }
      />
      {showMintAssetDialog && (
        <MintAssetDialog
          onCloseClick={() => setShowMintAssetDialog(false)}
          assetToMintID={unitTrustStablecoin.id}
        />
      )}
    </>
  );
}
