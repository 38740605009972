import { LoginClaims } from "james/security/claims";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Login } from "james/security/claims/Login";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { UserAuthenticated } from "const";

interface LoginRequest {
  firebaseUserIDToken: string;
}

interface LoginResponse {
  loginClaims: LoginClaims;
}

interface RefreshLoginRequest {
  context: Context;
}

interface RefreshLoginResponse {
  loginClaims: LoginClaims;
}

export const Authenticator = {
  serviceProvider: "authentication-Authenticator",
  async Login(request: LoginRequest): Promise<LoginResponse> {
    const loginResponse = await jsonRPCRequest({
      url: `${config.get("meshAuthenticationURL")}/login`,
      method: `${Authenticator.serviceProvider}.Login`,
      request,
    });
    sessionStorage.setItem(UserAuthenticated, UserAuthenticated);
    return loginResponse;
  },
  async Logout(): Promise<Response> {
    const url = `${config.get("meshAuthenticationURL")}/logout`;
    const logoutResponse = fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
    });
    sessionStorage.removeItem(UserAuthenticated);
    return logoutResponse;
  },
  async RefreshLogin(
    request: RefreshLoginRequest,
  ): Promise<RefreshLoginResponse> {
    const response = await jsonRPCRequest({
      url: `${config.get("meshURL")}/api/refresh-login`,
      method: `${Authenticator.serviceProvider}.RefreshLogin`,
      request,
    });
    sessionStorage.setItem(UserAuthenticated, UserAuthenticated);
    return {
      loginClaims: new Login(response.loginClaims),
    };
  },
};
