import { Address } from "james/location";
import { Amount } from "./Amount";

export class AssetParticipants {
  public issuer: IssuerProfile;
  public underwriters: Underwriter[];
  public brokers: BrokerProfile[];
  public exchanges: ExchangeProfile[];
  public advisor: Advisor;
  public gurantor: Gurantor;

  constructor(p: AssetParticipants) {
    this.issuer = new IssuerProfile(p.issuer);
    this.underwriters = p.underwriters.map((u) => new Underwriter(u));
    this.brokers = p.brokers;
    this.exchanges = p.exchanges;
    this.advisor = p.advisor;
    this.gurantor = p.gurantor;
  }
}

export class IssuerProfile {
  public name = "";
  public description = "";
  public email = "";
  public website = "";
  public twitter = "";
  public linkedIn = "";
  public instagram = "";
  public facebook = "";
  public companyAddress: Address = new Address();
  public contactNumber = "";
  public representativeName = "";
  public logoUrl = "";
  public youtube = "";

  constructor(p?: IssuerProfile) {
    if (!p) return;

    this.name = p.name;
    this.description = p.description;
    this.email = p.email;
    this.website = p.website;
    this.twitter = p.twitter;
    this.linkedIn = p.linkedIn;
    this.instagram = p.instagram;
    this.companyAddress = new Address(p.companyAddress);
    this.contactNumber = p.contactNumber;
    this.representativeName = p.representativeName;
    this.logoUrl = p.logoUrl;
    this.facebook = p.facebook;
    this.youtube = p.youtube;
  }
}

export class Underwriter {
  public name = "";
  public underwrittenAmount: Amount = new Amount();
  public companyRep = "";
  public address: Address = new Address();
  public email = "";
  public contactNumber = "";
  public website = "";
  public twitter = "";
  public facebook = "";
  public instagram = "";
  public youtube = "";
  public linkedIn = "";

  constructor(u?: Underwriter) {
    if (!u) return;

    this.name = u.name;
    this.underwrittenAmount = new Amount(u.underwrittenAmount);
    this.address = new Address(u.address);
    this.email = u.email;
    this.contactNumber = u.contactNumber;
    this.website = u.website;
    this.twitter = u.twitter;
    this.facebook = u.facebook;
    this.instagram = u.instagram;
    this.youtube = u.youtube;
    this.linkedIn = u.linkedIn;
    this.companyRep = u.companyRep;
  }
}

export interface BrokerProfile {
  name: string;
  description: string;
  email: string;
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
}

export interface ExchangeProfile {
  name: string;
  description: string;
  email: string;
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
}

export interface Advisor {
  name: string;
  description: string;
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
  linkedIn: string;
  logoUrl: string;
  youtube: string;
}

export interface Gurantor {
  name: string;
  companyRep: string;
  address: string;
  contactNumber: string;
  email: string;
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
  youtube: string;
  linkedIn: string;
}
