import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { LoadingBar } from "components/LoadingBar/LoadingBar";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";

interface AssetSubscriptionOverviewRendererProps {
  subscriptionAmount: LedgerAmount;
  subscribedAmount: LedgerAmount;
  committedAmount: LedgerAmount;
}

export const AssetSubscriptionOverviewRenderer = ({
  subscriptionAmount,
  subscribedAmount,
  committedAmount,
}: AssetSubscriptionOverviewRendererProps) => {
  const subscribedPercentage = useMemo(() => {
    if (subscriptionAmount && subscribedAmount) {
      return subscribedAmount.value
        .dividedBy(subscriptionAmount.value)
        .multipliedBy(100);
    }
    return new BigNumber(0);
  }, [subscriptionAmount, subscribedAmount]);

  const commitmentPercent = useMemo(() => {
    if (subscriptionAmount && committedAmount) {
      if (committedAmount.value.gt(subscriptionAmount.value)) {
        return new BigNumber(100);
      }
      return committedAmount.value
        .dividedBy(subscriptionAmount.value)
        .multipliedBy(100);
    }
    return new BigNumber(0);
  }, [subscriptionAmount, committedAmount]);

  return (
    <Box sx={{ pt: 4, pb: 5, pr: { sm: 5, xs: 3 } }}>
      {subscribedAmount && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              justifyContent: "space-between",
              mt: 2,
              gap: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
                mb: { sm: 2, xs: 0 },
              })}
              children={`${subscribedPercentage.dp(2)}% Subscribed`}
            />
            {/* Subscription Target */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                mb: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  mr: 0.5,
                  fontSize: "16px",
                  fontWeight: theme.typography.fontWeightBold,
                  lineHeight: 1,
                })}
                children="Target:"
              />
              <Amount
                reverse={true}
                codeTypographyProps={{
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                    lineHeight: 1,
                  }),
                }}
                valueTypographyProps={{
                  sx: (theme) => ({
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                    lineHeight: 1,
                  }),
                }}
                amount={subscriptionAmount}
              />
            </Box>
          </Box>
          <LoadingBar
            percent={subscribedPercentage}
            commitmentPercent={commitmentPercent}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2,
              gap: 2,
              alignItems: "flex-end",
            }}
          >
            {/* Amount Subscribed */}
            <Box>
              <Typography
                variant="caption"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
                children="Amount Subscribed"
              />
              <Amount
                reverse={true}
                codeTypographyProps={{
                  sx: (theme) => ({
                    color: theme.palette.success.light,
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                  }),
                }}
                valueTypographyProps={{
                  sx: (theme) => ({
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.success.light,
                  }),
                }}
                amount={
                  subscribedAmount.isUndefined()
                    ? subscriptionAmount.setValue("0")
                    : subscribedAmount
                }
              />
            </Box>
            {/* Amount Commited */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
                children="Amount Committed"
              />
              <Amount
                reverse={true}
                codeTypographyProps={{
                  sx: (theme) => ({
                    color: theme.palette.info.light,
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                  }),
                }}
                valueTypographyProps={{
                  sx: (theme) => ({
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.info.light,
                  }),
                }}
                amount={
                  committedAmount.isUndefined()
                    ? committedAmount.setValue("0")
                    : committedAmount
                }
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
