import { AllStellarNetworks, StellarNetwork } from "james/stellar/Network";
import { AllBitcoinNetworks, BitcoinNetwork } from "james/bitcoin/Network";
import { AllCTSENetworks, CTSENetwork } from "james/ctse";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/network_pb";

export type LedgerNetwork = "-" | StellarNetwork | BitcoinNetwork | CTSENetwork;

export const AllLedgerNetworks: LedgerNetwork[] = [
  ...AllStellarNetworks,
  ...AllBitcoinNetworks,
  ...AllCTSENetworks,
];

export function networkFromFutureNetwork(
  futureNetwork: FutureNetwork,
): LedgerNetwork {
  switch (futureNetwork) {
    case FutureNetwork.UNDEFINED_NETWORK:
      return "-";
    case FutureNetwork.STELLAR_TEST_SDF_NETWORK:
      return StellarNetwork.TestSDFNetwork;

    case FutureNetwork.STELLAR_PUBLIC_NETWORK:
      return StellarNetwork.PublicNetwork;

    case FutureNetwork.BITCOIN_TEST_NETWORK:
      return BitcoinNetwork.TestNetwork;

    case FutureNetwork.BITCOIN_PUBLIC_NETWORK:
      return BitcoinNetwork.PublicNetwork;

    default:
      throw TypeError(`unsupported future network: ${futureNetwork}`);
  }
}

export function networkToFutureNetwork(network: LedgerNetwork): FutureNetwork {
  switch (network) {
    case "-":
      return FutureNetwork.UNDEFINED_NETWORK;

    case StellarNetwork.TestSDFNetwork:
      return FutureNetwork.STELLAR_TEST_SDF_NETWORK;

    case StellarNetwork.PublicNetwork:
      return FutureNetwork.STELLAR_PUBLIC_NETWORK;

    case BitcoinNetwork.TestNetwork:
      return FutureNetwork.BITCOIN_TEST_NETWORK;

    case BitcoinNetwork.PublicNetwork:
      return FutureNetwork.BITCOIN_PUBLIC_NETWORK;

    default:
      throw TypeError(`unsupported network: ${network}`);
  }
}
