import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { InstrumentRiskProfile, InvestorProfile } from "../financial";
import { DataSheetSection } from "./DataSheetSection";

export const AssetInvestmentObjectiveDataSheetSectionTypeName =
  "mesh::marketData/AssetInvestmentObjective";

export const AssetInvestmentObjectiveDataSheetSectionName =
  "Investment Objective";

export class AssetInvestmentObjectiveDataSheetSection
  implements DataSheetSection
{
  public ["@type"] = AssetInvestmentObjectiveDataSheetSectionTypeName;

  public investmentObjective = "";

  public instrumentRiskProfile: InstrumentRiskProfile | "" = "";

  public investorProfile: InvestorProfile | "" = "";

  public socialMediaLinks?: SocialMediaLinks;

  public estimatedAnnualReturn: Decimal = new Decimal();

  constructor(l?: AssetInvestmentObjectiveDataSheetSection) {
    if (!l) {
      return;
    }

    this.investmentObjective = l.investmentObjective;
    this.instrumentRiskProfile = l.instrumentRiskProfile;
    this.investorProfile = l.investorProfile;
    this.socialMediaLinks = l.socialMediaLinks;
    this.estimatedAnnualReturn = l.estimatedAnnualReturn;
  }

  public DataSheetSectionName(): string {
    return AssetInvestmentObjectiveDataSheetSectionName;
  }
}

export type SocialMediaLinks = {
  youtube: string;
  linkedin: string;
  web: string;
  facebook: string;
};
