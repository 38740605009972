import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextField } from "../../../../../../../../../components/FormFields";
import { ViewMode, useBuilderContext } from "../../../../Context";
import {
  DeleteOutlineOutlined as DeleteIcon,
  GetAppOutlined as DownloadIcon,
} from "@mui/icons-material";
import { DropzoneUploader } from "./components";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { download } from "utilities/network/download";
import { useApplicationContext } from "context/Application/Application";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";
import { useErrorContext } from "context/Error";

export const DocumentsSection = () => {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const {
    apiCallInProgress,
    viewMode,
    formData,
    formDataUpdater,
    changeDocumentsAction,
  } = useBuilderContext();
  // set a local view mode initialised to view
  const [localViewMode, setLocalViewMode] = useState(ViewMode.View);

  // read only determined by global builder view mode if instrument is in draft, other wise local
  const readOnly =
    formData.smartInstrument.getState() ===
    SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
      ? viewMode === ViewMode.View
      : localViewMode === ViewMode.View;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Supporting Documents</Typography>
        {(() => {
          // if the instrument is in draft then view mode is determined by global view mode
          // so do not show local save/edit button
          if (
            formData.smartInstrument.getState() ===
            SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE
          ) {
            return null;
          }

          return localViewMode === ViewMode.Edit ? (
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await changeDocumentsAction();
                setLocalViewMode(ViewMode.View);
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setLocalViewMode(ViewMode.Edit)}
            >
              Edit
            </Button>
          );
        })()}
      </Box>
      {!readOnly && (
        <DropzoneUploader
          disabled={apiCallInProgress}
          onNewlyUploaded={(docs) => formDataUpdater.addDocuments(docs)}
        />
      )}
      {(() => {
        if (readOnly) {
          if (!formData.smartInstrument.getDocumentsList().length) {
            return (
              <Typography
                sx={{
                  gridColumn: "1/3",
                }}
                variant="body2"
                color="textSecondary"
                children="Nothing has been uploaded yet"
              />
            );
          }
          return formData.smartInstrument
            .getDocumentsList()
            .map((doc, idx: number) => (
              <Box
                key={idx}
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gridColumnGap: theme.spacing(1),
                  gridRowGap: theme.spacing(1),
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  paddingBottom: theme.spacing(2),
                })}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  children={doc.getDescription() ? doc.getDescription() : "-"}
                />
                <Box
                  sx={{
                    gridColumn: "1/3",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="secondary"
                    children={doc.getName()}
                  />
                  <IconButton
                    size="small"
                    disabled={apiCallInProgress}
                    sx={(theme) => ({
                      marginLeft: theme.spacing(1),
                    })}
                    onClick={async () => {
                      // setAPILoading(true);
                      try {
                        download(
                          (
                            await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                              {
                                context: authContext,
                                documentID: doc.getId(),
                              },
                            )
                          ).downloadURL,
                          doc.getName(),
                        );
                      } catch (e) {
                        const err =
                          errorContextErrorTranslator.translateError(e);
                        console.error(
                          `error requesting download url: ${
                            err.message ? err.message : err.toString()
                          }`,
                        );
                      }
                      // setAPILoading(false);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </Box>
            ));
        } else {
          return formData.smartInstrument
            .getDocumentsList()
            .map((doc, idx: number) => (
              <Box
                key={idx}
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gridColumnGap: theme.spacing(1),
                  gridRowGap: theme.spacing(1),
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  paddingBottom: theme.spacing(2),
                })}
              >
                <TextField
                  id={`etfDialog-addAName-formField`}
                  label="Name"
                  value={doc.getDescription()}
                  inputProps={{ maxLength: 40 }}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Add a Name"
                  disabled={apiCallInProgress}
                  onChange={(e) =>
                    formDataUpdater.changeDocumentDescription({
                      docIdx: idx,
                      newDescription: e.target.value,
                    })
                  }
                  // error={
                  //   !!supportingDocsStepValidationResult.fieldValidations[
                  //   `etf-supportingDocuments-${idx}-description`
                  //   ]
                  // }
                  // helperText={
                  //   supportingDocsStepValidationResult.fieldValidations[
                  //   `etf-supportingDocuments-${idx}-description`
                  //   ]
                  // }
                />
                <span>
                  <IconButton
                    id={`etfDialog-deleteDocument-button`}
                    sx={(theme) => ({
                      marginTop: theme.spacing(1.5),
                    })}
                    size="small"
                    disabled={apiCallInProgress}
                    onClick={() => formDataUpdater.removeDocument(idx)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
                <Typography
                  id={`etfDialog-docName-typography`}
                  sx={{
                    gridColumn: "1/3",
                  }}
                  variant="body2"
                  color="secondary"
                  children={doc.getName()}
                />
              </Box>
            ));
        }
      })()}
    </Box>
  );
};
