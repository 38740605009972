import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {
  Close as CloseIcon,
  FileCopy as CopyPasteIcon,
} from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { TextField, TextNumField } from "components/FormFields";
import { AccountOperator, MaxSetLimitAmount } from "james/stellar";
import { useSnackbar } from "notistack";
import { Model } from "james/views/stellarAccountView";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { FinancialCurrencyStablecoinViewReader } from "james/views/financialCurrencyStablecoinView";
import { TextExactCriterion } from "james/search/criterion/text";
import { ScopeDeterminer } from "james/search/scope";
import { Permission } from "james/security/Permission";
import { AccountOperatorServiceProviderName } from "james/stellar/AccountOperator";
import { formatTextNum } from "utilities/number";
import { Amount } from "james/ledger";
import { FundingAcknowledgementDialog } from "./components/FundingAcknowledgementDialog";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useErrorContext } from "context/Error";

interface FundAccountProps {
  accountID: string;
  open: boolean;
  onClose: () => void;
}

export function FundAccountDialog(props: FundAccountProps) {
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldCollapse = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const [openDialog, setOpenDialog] = useState(props.open);
  const { authContext } = useApplicationContext();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [assetLimit, setAssetLimit] = useState<Amount>(new Amount());
  const [tokenBalance, setTokenBalance] = useState<Amount>(new Amount());
  const [openRefNumberCopiedToolTip, setRefNumberOpenCopiedTooltip] =
    useState(false);
  const [openAccNumberCopiedToolTip, setAccNumberOpenCopiedTooltip] =
    useState(false);
  const [isUserASignatoryOnAcc, setIsUserASignatoryOnAcc] = useState(false);
  const [accModel, setAccModel] = useState<Model | undefined>(undefined);
  const [openAcknowledgementDialog, setOpenAcknowledgementDialog] =
    useState(true);
  const [canUpdateAssetLimit, setCanUpdateAssetLimit] = useState(false);
  const [validationState, setValidationState] = useState<{
    [key: string]: string | undefined;
  }>({});
  const { stellarAccountContext } = useAccountContext();

  const performValidations: () => boolean = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (+assetLimit < +tokenBalance) {
      newValidationState.assetLimit =
        "Your Limit cannot be less than what you hold in your account";
    }

    if (+assetLimit === 0) {
      newValidationState.assetLimit = "Your Limit cannot be 0";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!isMounted) {
        return;
      }

      // wait for the account context data to load before proceeding
      if (stellarAccountContext.loading) {
        return;
      }

      // if there was an error retrieving the accounts from the stellarContext
      // show an snackbar and then close the dialog
      if (stellarAccountContext.error) {
        console.error(`initialization error:${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          { variant: "error" },
        );

        // close the dialog
        props.onClose();
      }

      // retrieve the account view model
      const accountViewModel = stellarAccountContext.accounts.find(
        (v) => v.id === props.accountID,
      );
      if (!accountViewModel) {
        console.error(`Unexpected error retrieving account view model`);
        enqueueSnackbar("Unexpected error retrieving account view model", {
          variant: "error",
        });
        // close the dialog
        props.onClose();
        return;
      }

      setAccModel(accountViewModel);

      // try to find the mesh issued mZAR stablecoin in the account balances
      const mZARBalance = accountViewModel.balances.find(
        (val) =>
          val.tokenViewModel.token.code === "mZAR" &&
          val.tokenViewModel.tokenCategory ===
            TokenCategory.RightsToFiatCurrency &&
          val.tokenViewModel.issuer.includes("Mesh"),
      );
      if (mZARBalance) {
        if (isMounted) {
          setAssetLimit(mZARBalance.limit);
          setTokenBalance(mZARBalance.availableBalance());
        }
      } else {
        // if the mZAR stablecoin was not found then set retrieve it and set the tokenID and asset limit
        try {
          const stablecoinViewReadResponse =
            await FinancialCurrencyStablecoinViewReader.Read({
              context: authContext,
              criteria: {
                "token.code": TextExactCriterion("mZAR"),
              },
            });
          const meshMZAR = stablecoinViewReadResponse.models.find((value) =>
            value.issuer.includes("Mesh"),
          );
          if (meshMZAR) {
            if (isMounted) {
              setAssetLimit(meshMZAR.token.newAmountOf("0"));
            }
          } else {
            console.error("error retrieving mZAR currency stablecoin");
            enqueueSnackbar("Error Retrieving mZAR Currency Stablecoin", {
              variant: "error",
            });
            props.onClose();
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `could not find mZAR currency stablecoin: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Retrieving mZAR Currency Stablecoin", {
            variant: "error",
          });
          props.onClose();
        }
      }
      // check if the user is an signatory on the account
      try {
        if (isMounted) {
          setIsUserASignatoryOnAcc(
            await stellarAccountContext.checkUserSignatoryOnAccount(
              LedgerIDIdentifier(accountViewModel.ledgerID),
            ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      // check if the user has permission to call set limit in the group that
      // owns the account
      try {
        const determineScopeAuthorisationByRolesResponse =
          await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
            context: authContext,
            groupID: accountViewModel ? accountViewModel.ownerID : "",
            buildScopeTree: false,
            service: new Permission({
              serviceName: "SetLimit",
              serviceProvider: AccountOperatorServiceProviderName,
              description: "?",
            }),
          });

        if (determineScopeAuthorisationByRolesResponse.authorized) {
          setCanUpdateAssetLimit(true);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining scope authorisation by roles: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Scope Authorisation", {
          variant: "error",
        });
        props.onClose();
      }
      setLoading(false);
    })();
    return () => {
      isMounted = false;
    };
  }, [
    stellarAccountContext.loading,
    stellarAccountContext.error,
    enqueueSnackbar,
    authContext,
    props,
  ]);

  // useEffect to display acknowledgement dialog when the app loads
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setOpenAcknowledgementDialog(true);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSetLimit = async () => {
    if (!performValidations()) {
      return;
    }

    setLoading(true);
    try {
      if (!accModel) {
        console.error("accModel is undefined");
        return;
      }
      await AccountOperator.SetLimit({
        context: authContext,
        limit: assetLimit,
        accountID: accModel.id,
      });
      enqueueSnackbar("asset limit updated", { variant: "success" });
      setAssetLimit(assetLimit);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to update asset limit: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Unable to update asset limit: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setLoading(false);
  };

  const closeFuncAccountDialog = () => {
    setOpenDialog(!openDialog);
    props.onClose();
  };

  return (
    <>
      <Dialog
        PaperProps={{
          "data-component-info": JSON.stringify({
            component_id: "fund_account",
            component_business_name: "Fund account payment details",
            component_title: "Payment Details",
            component_driver: InteractionDriver.FundAccount,
          } as DataComponentInfo),
        }}
        fullScreen
        open={openDialog && !openAcknowledgementDialog}
      >
        <DialogTitle
          sx={(theme) => ({ backgroundColor: theme.palette.custom.spaceblue })}
        >
          <Grid
            sx={{ alignItems: "center" }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                gridColumnGap: theme.spacing(2),
              })}
              item
            >
              <Box
                sx={{
                  height: 32,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="" width={40} src={meshMiniLogo} />
              </Box>
              <Typography variant="h5">
                Funding {!isMobile ? "Mesh mZAR" : ""}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                disabled={loading}
                id="fundAccount-close-button"
                onClick={closeFuncAccountDialog}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          sx={[
            {
              backgroundColor: theme.palette.custom.midnight,
              overflowY: "auto",
              padding: 0,
              boxShadow: "0 -10px 12px -14px #000 inset",
            },
          ]}
          className={"meshScroll"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { sm: "center" },
              padding: {
                sm: theme.spacing(1),
                xs: theme.spacing(3),
              },
              paddingRight: {
                sm: theme.spacing(1),
                xs: "18px",
              },
              paddingBottom: { xs: theme.spacing(1) },
            }}
          >
            <Box
              sx={[
                (theme) => ({
                  display: "flex",
                  justifyContent: {
                    sm: "flex-start",
                    xs: "flex-start",
                  },
                  maxWidth: { sm: "1280px" },
                  marginBottom: theme.spacing(3),
                }),
                shouldCollapse && {
                  flexDirection: "column",
                },
                desktop && { width: "100%" },
              ]}
            >
              {/* Payment details section */}
              <Box
                sx={{
                  padding: {
                    sm: theme.spacing(4),
                  },
                }}
              >
                <Typography
                  style={{
                    fontWeight: theme.typography.fontWeightBold,
                    paddingBottom: theme.spacing(1),
                  }}
                  variant="h4"
                >
                  Payment Details
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  sx={[
                    {
                      paddingBottom: theme.spacing(3),
                      color: theme.palette.text.tertiary,
                      maxWidth: "500px",
                    },
                  ]}
                >
                  Please make payment from your bank account to Mesh using the
                  banking details below.
                </Typography>
                <Box
                  sx={[
                    !isMobile && {
                      marginBottom: theme.spacing(4),
                      display: "flex",
                      flexDirection: "row",
                    },
                    isMobile && {
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: theme.spacing(2),
                    },
                  ]}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "250px",
                    }}
                  >
                    <TextField
                      readOnly
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      fullWidth
                      label="Bank"
                      value="Investec Bank Limited"
                    />
                    <TextField
                      readOnly
                      variant="outlined"
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      label="Account Type"
                      value="Current Account"
                    />
                    <TextField
                      readOnly
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      label="Branch Number"
                      value="580105"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "290px",
                    }}
                  >
                    <TextField
                      readOnly
                      variant="outlined"
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      fullWidth
                      label="Recipient"
                      value="Mesh Trade South Africa Pty Ltd"
                    />
                    <TextField
                      readOnly
                      InputProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                          width: "193px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              onMouseLeave={() =>
                                setAccNumberOpenCopiedTooltip(false)
                              }
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() =>
                                setAccNumberOpenCopiedTooltip(false)
                              }
                              open={openAccNumberCopiedToolTip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied"
                            >
                              <CopyPasteIcon
                                data-link-info={JSON.stringify({
                                  content_interaction_id: "copy-info",
                                  content_interaction_action:
                                    InteractionAction.Copy,
                                  content_interaction_text: "account number",
                                  content_interaction_type:
                                    InteractionType.Button,
                                  content_interaction_driver:
                                    InteractionDriver.FundAccount,
                                } as DataLinkInfoType)}
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText("40005083339")
                                    .then(() =>
                                      setAccNumberOpenCopiedTooltip(true),
                                    );
                                }}
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.tertiary,
                                  margin: theme.spacing(0, 0, 1, -9),
                                })}
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "16px",
                          color: theme.palette.text.tertiary,
                        },
                      }}
                      label="Account Number"
                      value="40005083339"
                    />
                    {!loading ? (
                      <TextField
                        readOnly
                        InputLabelProps={{
                          sx: {
                            fontSize: "16px",
                            color: theme.palette.text.tertiary,
                          },
                        }}
                        InputProps={{
                          sx: () => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.warning.main,
                            fontSize: "16px",
                            width: "150px",
                          }),
                          endAdornment: (
                            <Tooltip
                              onMouseLeave={() =>
                                setRefNumberOpenCopiedTooltip(false)
                              }
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() =>
                                setRefNumberOpenCopiedTooltip(false)
                              }
                              open={openRefNumberCopiedToolTip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied"
                            >
                              <CopyPasteIcon
                                data-link-info={JSON.stringify({
                                  content_interaction_id: "copy-info",
                                  content_interaction_action:
                                    InteractionAction.Copy,
                                  content_interaction_text:
                                    "payment reference number",
                                  content_interaction_driver:
                                    InteractionDriver.FundAccount,
                                } as DataLinkInfoType)}
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(accModel ? accModel.number : "")
                                    .then(() =>
                                      setRefNumberOpenCopiedTooltip(true),
                                    );
                                }}
                                sx={(theme) => ({
                                  cursor: "pointer",
                                  color: theme.palette.text.tertiary,
                                  margin: theme.spacing(0, 0, 1, -9),
                                })}
                              />
                            </Tooltip>
                          ),
                        }}
                        label="Your Ref. Number"
                        value={accModel ? accModel.number : ""}
                      />
                    ) : (
                      <>
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.text.tertiary,
                          })}
                          variant="caption"
                        >
                          Ref. Number
                        </Typography>
                        <Skeleton width={120} height={29} />
                      </>
                    )}
                  </Box>
                </Box>

                {!isMobile && (
                  <Button
                    id="fundAccount-close-button"
                    data-link-info={JSON.stringify({
                      content_interaction_id: "close-modal",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_text: "close",
                      content_interaction_driver:
                        InteractionDriver.FundAccountCompleteClose,
                    } as DataLinkInfoType)}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenDialog(false);
                      props.onClose();
                    }}
                  >
                    Close
                  </Button>
                )}
                {!isMobile && (
                  <Box>
                    <Typography
                      sx={[
                        {
                          paddingBottom: theme.spacing(1),
                          paddingTop: theme.spacing(3),
                        },
                      ]}
                      variant="h6"
                    >
                      Please note:
                    </Typography>
                    <Typography
                      sx={[
                        (theme) => ({
                          color: theme.palette.text.tertiary,
                        }),
                        isMobile && {
                          marginBottom: "30px",
                        },
                      ]}
                      variant="caption"
                    >
                      - Always use your unique reference when making a payment.
                      <br /> - Banks can take up to two Business Days to settle
                      the payment to Mesh.
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* Divider */}
              {!shouldCollapse && (
                <Divider
                  variant="middle"
                  sx={[
                    {
                      height: "inherit",
                      backgroundColor: theme.palette.secondary.main,
                    },
                  ]}
                  orientation="vertical"
                />
              )}
              {/* Asset limit setting section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "500px",
                  padding: {
                    sm: theme.spacing(4, 4, 4, 6),
                  },
                  paddingTop: {
                    xs: 0,
                    sm: 0,
                    md: theme.spacing(8),
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={[
                    {
                      paddingBottom: theme.spacing(1),
                    },
                  ]}
                >
                  Asset Limit
                </Typography>
                <Typography
                  sx={[
                    {
                      color: theme.palette.text.tertiary,
                      marginBottom: theme.spacing(2),
                    },
                  ]}
                  variant="body1"
                >
                  Limits indicate how much of an asset you are willing to hold
                  in the account. <br />
                  <br /> By default, your account limit is set to its maximum.
                  This will ensure that funding requests can be processed
                  without delays.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SkeletonLoader
                    loading={loading}
                    {...(isMobile ? { width: "100%" } : {})}
                  >
                    <TextNumField
                      fullWidth={isMobile}
                      id="fundAccount-limit-textNumField"
                      disallowNegative
                      noDecimalPlaces={7}
                      disabled={!canUpdateAssetLimit}
                      value={assetLimit.value}
                      sx={[
                        {
                          marginBottom: theme.spacing(2),
                          marginTop: theme.spacing(3),
                        },
                        !isMobile && {
                          width: 280,
                        },
                      ]}
                      InputProps={{
                        endAdornment: (
                          <Typography
                            id="fundAccount-max-typography"
                            onClick={() =>
                              setAssetLimit(
                                assetLimit.setValue(MaxSetLimitAmount),
                              )
                            }
                            sx={{ cursor: "pointer" }}
                            color={
                              canUpdateAssetLimit
                                ? "secondary"
                                : "textSecondary"
                            }
                          >
                            MAX
                          </Typography>
                        ),
                      }}
                      helperText={
                        validationState.assetLimit
                          ? validationState.assetLimit
                          : `Available Balance: mZAR ${formatTextNum(
                              tokenBalance.value,
                              { addDecimalPadding: true },
                            )} `
                      }
                      error={!!validationState.assetLimit}
                      label="Limit"
                      onChange={(e) => {
                        handleClearValidation("assetLimit");
                        setAssetLimit(assetLimit.setValue(e.target.value));
                        performValidations();
                      }}
                    />
                  </SkeletonLoader>
                  <SkeletonLoader
                    loading={loading}
                    {...(isMobile ? { width: "100%" } : {})}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        !isUserASignatoryOnAcc
                          ? "user is not a signatory on account"
                          : !canUpdateAssetLimit
                            ? "not authorized to set asset limit on account"
                            : ""
                      }
                    >
                      <Box
                        data-link-info={JSON.stringify({
                          content_interaction_id: "update_limit",
                          content_interaction_action: InteractionAction.Click,
                          content_interaction_type: InteractionType.Button,
                          content_interaction_text: "update",
                          content_interaction_driver:
                            InteractionDriver.UpdateFundLimit,
                        } as DataLinkInfoType)}
                        component={"span"}
                      >
                        <Button
                          id="fundAccount-update-button"
                          disabled={
                            !isUserASignatoryOnAcc || !canUpdateAssetLimit
                          }
                          data-link-info={JSON.stringify({
                            content_interaction_id: "update_limit",
                            content_interaction_action: InteractionAction.Click,
                            content_interaction_type: InteractionType.Button,
                            content_interaction_text: "update",
                            content_interaction_driver:
                              InteractionDriver.UpdateFundLimit,
                          } as DataLinkInfoType)}
                          sx={[
                            {
                              height: 32,
                              marginTop: "10px",
                            },
                            isMobile && {
                              height: 48,
                            },
                          ]}
                          fullWidth={isMobile}
                          onClick={handleSetLimit}
                          variant="contained"
                          color="secondary"
                        >
                          Update
                        </Button>
                      </Box>
                    </Tooltip>
                  </SkeletonLoader>
                </Box>
              </Box>
            </Box>
            {isMobile && (
              <Box>
                <Typography
                  sx={[{ paddingBottom: theme.spacing(1) }]}
                  variant="h6"
                >
                  Please note:
                </Typography>
                <Box
                  sx={{
                    color: theme.palette.text.tertiary,
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="caption"
                      sx={(theme) => ({ paddingRight: theme.spacing(1) })}
                    >
                      -
                    </Typography>
                    <Typography variant="caption">
                      Always use your unique reference when making a payment.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="caption"
                      sx={(theme) => ({ paddingRight: theme.spacing(1) })}
                    >
                      -
                    </Typography>
                    <Typography variant="caption">
                      Banks can take up to two Business Days to settle the
                      payment to Mesh.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        {isMobile && (
          <DialogTitle
            sx={{
              padding: theme.spacing(3),
            }}
          >
            <Box
              sx={{
                paddingBottom: theme.spacing(2),
              }}
            >
              <Button
                id="fundAccount-close-button"
                disabled={loading}
                data-link-info={JSON.stringify({
                  content_interaction_id: "close_modal",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Button,
                  content_interaction_text: "close",
                  content_interaction_driver:
                    InteractionDriver.FundAccountCompleteClose,
                } as DataLinkInfoType)}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  setOpenDialog(false);
                  props.onClose();
                }}
                sx={{ height: "48px" }}
              >
                Close
              </Button>
            </Box>
          </DialogTitle>
        )}
      </Dialog>
      {openAcknowledgementDialog && (
        <FundingAcknowledgementDialog
          open={openAcknowledgementDialog}
          onClose={() => {
            setOpenAcknowledgementDialog(false);
            closeFuncAccountDialog();
          }}
          onContinue={() => setOpenAcknowledgementDialog(false)}
        />
      )}
    </>
  );
}
