import { Document } from "james/document";
import config from "react-global-configuration";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { DigitalETF } from "./DigitalETF";
import { Context } from "../security";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export interface CreateNewDigitalETFRequest {
  context: Context;
  ownerID: string;
  name: string;
  assetClass: AssetClassDEPRECATED | "";
  issueDate: string;
  maturityDate: string;
  maximumUnits: Amount;
  valuationToken: Token;
  supportingDocuments: Document[];
  investorProfile: InvestorProfile | "";
  investorProfileDescription: string;
  riskProfile: InstrumentRiskProfile | "";
  riskProfileDescription: string;
  holdings: Holding[];
  sectorAllocations: SectorAllocation[];
  countryAllocations: CountryAllocation[];
  annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface CreateNewDigitalETFResponse {
  digitalETF: DigitalETF;
}

export const DigitalETFCreator = {
  serviceProvider: "financial-DigitalETFCreator",
  async CreateNewDigitalETF(
    request: CreateNewDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<CreateNewDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFCreator.serviceProvider}.CreateNewDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
};
