import { ValidationResult } from "common/validation";
import { FormData, ListingStateChangeAction } from "./ListingManagementDialog";
import { Mechanism, QuoteParameter } from "james/market";
import { Amount, PlatformMinimumDealSize, Token } from "james/ledger";
import { MaxSetLimitAmount } from "james/stellar";

export type FormUpdaterSpecsType = {
  baseToken: (baseToken: Token, prevFormData?: FormData) => FormData;
  marketMechanism: (
    marketMechanism: Mechanism,
    prevFormData?: FormData,
  ) => FormData;
  addQuoteParameter: (quoteToken: Token, prevFormData?: FormData) => FormData;
  removeQuoteParameter: (
    parameterIdx: number,
    prevFormData?: FormData,
  ) => FormData;
  updateQuoteParameterMaxDealSize: (
    updateProps: { parameterIdx: number; amount: Amount },
    prevFormData?: FormData,
  ) => FormData;
  updateQuoteParameterMinDealSize: (
    updateProps: { parameterIdx: number; amount: Amount },
    prevFormData?: FormData,
  ) => FormData;

  listingStateChangeAction: (
    listingStateChangeAction: ListingStateChangeAction | undefined,
    prevFormData?: FormData,
  ) => FormData;
  lastActionAnnotation: (
    lastActionAnnotation: string,
    prevFormData?: FormData,
  ) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  baseToken(baseToken: Token, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      baseToken,
    };
  },
  marketMechanism(
    marketMechanism: Mechanism,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      marketMechanism: new Mechanism(marketMechanism),
    };
  },
  addQuoteParameter(quoteToken: Token, prevFormData?: FormData): FormData {
    prevFormData = prevFormData as FormData;

    return {
      ...prevFormData,
      marketMechanism: new Mechanism({
        ...prevFormData.marketMechanism,
        quoteParameters: [
          ...prevFormData.marketMechanism.quoteParameters,
          new QuoteParameter({
            quoteToken,
            minimumDealSize: prevFormData.baseToken.newAmountOf(
              PlatformMinimumDealSize,
            ),
            maximumDealSize:
              prevFormData.baseToken.newAmountOf(MaxSetLimitAmount),
          }),
        ],
      }),
    };
  },
  removeQuoteParameter(
    parameterIdx: number,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      marketMechanism: new Mechanism({
        ...prevFormData.marketMechanism,
        quoteParameters: prevFormData.marketMechanism.quoteParameters.filter(
          (qp, idx) => idx !== parameterIdx,
        ),
      }),
    };
  },
  updateQuoteParameterMaxDealSize(
    updateProps: { parameterIdx: number; amount: Amount },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    prevFormData.marketMechanism.quoteParameters[
      updateProps.parameterIdx
    ].maximumDealSize = updateProps.amount;
    return {
      ...prevFormData,
      marketMechanism: new Mechanism(prevFormData.marketMechanism),
    };
  },
  updateQuoteParameterMinDealSize(
    updateProps: { parameterIdx: number; amount: Amount },
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    prevFormData.marketMechanism.quoteParameters[
      updateProps.parameterIdx
    ].minimumDealSize = updateProps.amount;
    return {
      ...prevFormData,
      marketMechanism: new Mechanism(prevFormData.marketMechanism),
    };
  },
  listingStateChangeAction(
    listingStateChangeAction: ListingStateChangeAction | undefined,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      listingStateChangeAction,
      lastActionAnnotation: "",
    };
  },
  lastActionAnnotation(
    lastActionAnnotation: string,
    prevFormData?: FormData,
  ): FormData {
    prevFormData = prevFormData as FormData;
    return {
      ...prevFormData,
      lastActionAnnotation,
    };
  },
};

export const validationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  // ensure at least 1 quote parameter is given
  if (!formData.marketMechanism.quoteParameters.length) {
    validationResult.valid = false;

    validationResult.fieldValidations.quoteTokens = "Select at least 1";
  }

  // validate minimum and maximum deal sizes
  formData.marketMechanism.quoteParameters.forEach((qp, idx) => {
    // minimum deal size validation
    if (qp.minimumDealSize.value.lt(PlatformMinimumDealSize)) {
      validationResult.valid = false;
      validationResult.fieldValidations[`quoteParam-${idx}-minimumDealSize`] =
        `Must be > ${PlatformMinimumDealSize}`;
    } else if (qp.minimumDealSize.value.gte(qp.maximumDealSize.value)) {
      validationResult.valid = false;
      validationResult.fieldValidations[`quoteParam-${idx}-minimumDealSize`] =
        `Must be < Max`;
    }

    // maximum deal size validation
    if (qp.maximumDealSize.value.lt(PlatformMinimumDealSize)) {
      validationResult.valid = false;
      validationResult.fieldValidations[`quoteParam-${idx}-maximumDealSize`] =
        `Must be > ${PlatformMinimumDealSize}`;
    } else if (qp.minimumDealSize.value.gte(qp.maximumDealSize.value)) {
      validationResult.valid = false;
      validationResult.fieldValidations[`quoteParam-${idx}-maximumDealSize`] =
        `Must be > Min`;
    }
  });

  if (formData.listingStateChangeAction && !formData.lastActionAnnotation) {
    validationResult.valid = false;
    validationResult.fieldValidations.lastActionAnnotation = "Cannot be blank";
  }

  return validationResult;
};
