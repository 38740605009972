import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { Amount } from "james/ledger";
import { Listing } from "./Listing";

export type NewSmartInstrumentPrimaryMarketSubscriptionRequest = {
  context: Context;
  smartInstrumentID: string;

  // Listing Details
  minimumDealSize: Amount;
  maximumDealSize: Amount;

  // Subscription Order Book Details
  openDate: string;
  closeDate: string;
  unitPrice: Amount;
  subscriptionAmount: Amount;
  overSubscriptionAmount: Amount;
};

export type NewSmartInstrumentPrimaryMarketSubscriptionResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
  listing: Listing;
};

export const SmartInstrumentPrimaryMarketListerServiceProviderName =
  "market-SmartInstrumentPrimaryMarketLister";

export const SmartInstrumentPrimaryMarketLister = {
  async NewSmartInstrumentPrimaryMarketSubscription(
    request: NewSmartInstrumentPrimaryMarketSubscriptionRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<NewSmartInstrumentPrimaryMarketSubscriptionResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SmartInstrumentPrimaryMarketListerServiceProviderName}.NewSmartInstrumentPrimaryMarketSubscription`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
      listing: new Listing(response.listing),
    };
  },
};
