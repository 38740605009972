import dayjs from "dayjs";
import { Document } from "james/document";
import { Instrument } from "./Instrument";
import { AuditEntry } from "../audit";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { RatingAgency } from "./RatingAgency";
import { FinancialInstrumentState } from "./InstrumentState";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import {
  InstrumentManagementFee,
  InstrumentManagementFeeFrequency,
  NewInstrumentManagementFee,
} from "./InstrumentManagementFee";
import { CurrencyAmountInstrumentManagementFee } from "./InstrumentManagementFeeCurrencyAmount";

export const UnitTrustTypeName = "mesh::financial/UnitTrust";

export enum UnitTrustState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllUnitTrustStates: UnitTrustState[] = [
  UnitTrustState.Draft,
  UnitTrustState.PreIssued,
  UnitTrustState.Issued,
  UnitTrustState.Matured,
  UnitTrustState.Cancelled,
  UnitTrustState.Deleted,
];

export enum UnitTrustAction {
  //
  // UnitTrustCreator Actions
  //
  New,

  //
  // UnitTrustStateChanger Actions
  //
  MarkDeleted,
  MarkPreIssued,
  MarkIssued,
  MarkCancelled,
  MarkMatured,

  //
  // UnitTrustUpdater Actions
  //
  DraftUpdate,
  ChangeMaturityDate,
  ChangeHoldings,
  ChangeSectorAllocations,
  ChangeCountryAllocations,
  ChangeAnnualPerformanceLog,
  ChangeSupportingDocuments,
}

export class UnitTrust implements Instrument {
  public ["@type"]: string = UnitTrustTypeName;

  public id = "";

  public ownerID = "";

  public state: UnitTrustState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public name = "";

  public isin = "";

  public assetClass: AssetClassDEPRECATED | "" = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public issuerName = "";

  public issuerRatingAgency: RatingAgency | "" = "";

  public issuerCreditRating = "";

  public issuerManagementFee: InstrumentManagementFee =
    new CurrencyAmountInstrumentManagementFee();

  public issuerManagementFeeFrequency: InstrumentManagementFeeFrequency =
    InstrumentManagementFeeFrequency.Yearly;

  public supportingDocuments: Document[] = [];

  public investorProfile: InvestorProfile | "" = "";

  public investorProfileDescription = "";

  public riskProfile: InstrumentRiskProfile | "" = "";

  public riskProfileDescription = "";

  public holdings: Holding[] = [];

  public sectorAllocations: SectorAllocation[] = [];

  public countryAllocations: CountryAllocation[] = [];

  public annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

  constructor(unitTrust?: UnitTrust) {
    if (!unitTrust) {
      return;
    }
    this.id = unitTrust.id;
    this.ownerID = unitTrust.ownerID;
    this.state = unitTrust.state;
    this.auditEntry = new AuditEntry(unitTrust.auditEntry);
    this.name = unitTrust.name;
    this.isin = unitTrust.isin;
    this.assetClass = unitTrust.assetClass;
    this.issueDate = unitTrust.issueDate;
    this.maturityDate = unitTrust.maturityDate;
    this.issuerName = unitTrust.issuerName;
    this.issuerRatingAgency = unitTrust.issuerRatingAgency;
    this.issuerCreditRating = unitTrust.issuerCreditRating;
    this.issuerManagementFee = NewInstrumentManagementFee(
      unitTrust.issuerManagementFee,
    );
    this.issuerManagementFeeFrequency = unitTrust.issuerManagementFeeFrequency;
    this.supportingDocuments = unitTrust.supportingDocuments.map(
      (d) => new Document(d),
    );
    this.investorProfile = unitTrust.investorProfile;
    this.investorProfileDescription = unitTrust.investorProfileDescription;
    this.riskProfile = unitTrust.riskProfile;
    this.riskProfileDescription = unitTrust.riskProfileDescription;
    this.holdings = unitTrust.holdings.map((h) => new Holding(h));
    this.sectorAllocations = unitTrust.sectorAllocations.map(
      (s) => new SectorAllocation(s),
    );
    this.countryAllocations = unitTrust.countryAllocations.map(
      (c) => new CountryAllocation(c),
    );
    this.annualPerformanceLog = unitTrust.annualPerformanceLog.map(
      (ple) => new InstrumentAnnualPerformanceLogEntry(ple),
    );
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return this.assetClass;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return this.annualPerformanceLog;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }
}

export function getPotentialNextUnitTrustActions(
  unitTrustState: UnitTrustState | "",
): UnitTrustAction[] {
  switch (unitTrustState) {
    case UnitTrustState.Draft: {
      return [
        UnitTrustAction.MarkDeleted,
        UnitTrustAction.DraftUpdate,
        UnitTrustAction.MarkPreIssued,
        UnitTrustAction.MarkIssued,
      ];
    }

    case UnitTrustState.PreIssued: {
      return [
        UnitTrustAction.MarkIssued,
        UnitTrustAction.MarkCancelled,
        UnitTrustAction.ChangeMaturityDate,
        UnitTrustAction.ChangeHoldings,
        UnitTrustAction.ChangeSectorAllocations,
        UnitTrustAction.ChangeCountryAllocations,
        UnitTrustAction.ChangeAnnualPerformanceLog,
        UnitTrustAction.ChangeSupportingDocuments,
      ];
    }

    case UnitTrustState.Issued:
      return [
        UnitTrustAction.MarkCancelled,
        UnitTrustAction.ChangeHoldings,
        UnitTrustAction.MarkMatured,
        UnitTrustAction.ChangeSectorAllocations,
        UnitTrustAction.ChangeCountryAllocations,
        UnitTrustAction.ChangeAnnualPerformanceLog,
        UnitTrustAction.ChangeSupportingDocuments,
      ];

    case UnitTrustState.Matured:
      return [];

    case UnitTrustState.Cancelled:
      return [];

    case UnitTrustState.Deleted:
      return [];

    default:
      throw new TypeError(`invalid UnitTrustState: '${unitTrustState}'`);
  }
}
