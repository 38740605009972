import React from "react";
import { Box, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { SearchIndicativePricesResponse } from "james/market/IndicativePriceRepository";
import dayjs from "dayjs";
import { formatTextNum } from "utilities/number";
import { Model } from "james/views/ledgerTokenView/Model";
import { IndicativePrice } from "james/market";

interface PriceHistoryTableProps {
  searchIndicativePricesResponse: SearchIndicativePricesResponse;
  valuationTokenViewModel?: Model;
  isLoading: boolean;
  noDataSplashComponent?: JSX.Element;
}

export function PriceHistoryTable(props: PriceHistoryTableProps) {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { records: prices } = props.searchIndicativePricesResponse;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        p: {
          sm: 1,
        },
        mt: {
          sm: 3,
          xs: 2,
        },
      }}
    >
      {/*  Table Header */}
      {!smDown && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            alignItems: "center",
            gridRowGap: 1,
            pb: 1,
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" children="Date" />
          <Typography variant="h6" children="Time" />
          <Typography variant="h6" children="Type" />
          <Typography variant="h6" children="Buy Price" />
          <Typography variant="h6" children="Sell Price" />
        </Box>
      )}
      {/*  Table Body */}
      <Box
        sx={{
          overflowX: "hidden",
          overflowY: {
            sm: "auto",
          },
          height: { sm: "260px" },
        }}
        className={smDown ? "" : "meshScroll"}
      >
        {!props.isLoading &&
          prices.map((p, idx) => (
            <PriceHistoryComponent
              price={p}
              code={props.valuationTokenViewModel?.token.code}
              key={idx}
            />
          ))}
        {props.isLoading && (
          <>
            <Box
              sx={{
                height: "26px",
              }}
            >
              <Skeleton width="100%" height="100%" />
            </Box>
            <Box
              sx={{
                height: "26px",
              }}
            >
              <Skeleton width="100%" height="100%" />
            </Box>
            <Box
              sx={{
                height: "26px",
              }}
            >
              <Skeleton width="100%" height="100%" />
            </Box>
          </>
        )}
        {!props.isLoading &&
          props.searchIndicativePricesResponse?.total === 0 && (
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props.noDataSplashComponent}
            </Box>
          )}
      </Box>
    </Box>
  );
}

interface PriceHistoryComponentProps {
  price: IndicativePrice;
  code?: string;
  key: React.Key | null | undefined;
}

const PriceHistoryComponent = ({
  price,
  code,
  key,
}: PriceHistoryComponentProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const fields: Record<string, string> = {
    Date: dayjs(price.timeOfPrice).format("YYYY-MM-DD"),
    Time: dayjs(price.timeOfPrice).format("HH:mm A"),
    Type: price.type,
    "Buy Price": `${code} ${formatTextNum(price.buyPrice.value, {
      addDecimalPadding: true,
    })}`,
    "Sell Price": `${code} ${formatTextNum(price.sellPrice.value, {
      addDecimalPadding: true,
    })}`,
  };

  return (
    <Box
      key={key}
      sx={(theme: Theme) => ({
        display: "grid",
        [theme.breakpoints.down("sm")]: {
          gridTemplateRows: "repeat(5, 1fr)",
          backgroundColor: theme.palette.custom.midnight,
          py: 1,
          px: 2,
          borderRadius: "10px",
          mb: 2,
        },
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(5, 1fr)",
          borderBottom: `solid 1px ${theme.palette.divider}`,
        },
        alignItems: "center",
      })}
    >
      {Object.keys(fields).map((val, idx) => {
        return (
          <Box
            key={`${key}-${idx}`}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {smDown && (
              <Typography
                sx={(theme: Theme) => ({
                  p: 0.5,
                  pb: 0.6,
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  alignItems: "center",
                  color: theme.palette.text.secondary,
                })}
                variant={smDown ? "body1" : "body2"}
                children={val}
              />
            )}
            <Typography
              id={`priceHistoryTable-${val}-Typography`}
              sx={{
                p: 0.5,
                pb: 0.6,
                display: "grid",
                gridTemplateColumns: "1fr",
                alignItems: "center",
                color: (theme: Theme) => ({
                  xs: theme.palette.text.primary,
                  sm: theme.palette.text.disabled,
                }),
              }}
              variant={smDown ? "body1" : "body2"}
              children={fields[val]}
            />
          </Box>
        );
      })}
    </Box>
  );
};
