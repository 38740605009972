import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { TransactionBatch } from "./TransactionBatch";

export interface RetrieveTransactionBatchRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveTransactionBatchResponse {
  transactionBatch: TransactionBatch;
}

export interface SearchTransactionBatchesRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchTransactionBatchesResponse {
  records: TransactionBatch[];
  total: number;
}

export const TransactionBatchRepository = {
  serviceProvider: "ledger-TransactionBatchRepository",
  async RetrieveTransactionBatch(
    request: RetrieveTransactionBatchRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetrieveTransactionBatchResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionBatchRepository.serviceProvider}.RetrieveTransactionBatch`,
        request,
      },
      opts,
    );
    return {
      transactionBatch: new TransactionBatch(response.transactionBatch),
    };
  },
  async SearchTransactionBatches(
    request: SearchTransactionBatchesRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SearchTransactionBatchesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TransactionBatchRepository.serviceProvider}.SearchTransactionBatches`,
        request,
      },
      opts,
    );
    return {
      records: response.records.map(
        (c: TransactionBatch) => new TransactionBatch(c),
      ),
      total: response.total,
    };
  },
};
