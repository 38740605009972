import React from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Advisor } from "james/ledger/AssetParticipants";

interface AdvisorSectionProps {
  advisor: Advisor;
}

export const AdvisorSection = ({ advisor }: AdvisorSectionProps) => {
  const prettyWebsiteName = advisor.website.split("https://")[1].split("/")[0];
  return (
    <Box sx={{ mt: { sm: 4, xs: 4 }, mx: { sm: 5, xs: 3 } }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.text.disabled,
            fontWeight: "bold",
          })}
        >
          Advisor:
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold", ml: 1 }}>
          {advisor.name}
        </Typography>
      </Box>
      <Box sx={{ height: { sm: 300, sx: "auto" } }}>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            mb: 2,
          })}
        >
          {advisor.description}
        </Typography>
      </Box>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          mb: 1,
        })}
      >
        Website:{" "}
        <Link
          href={advisor.website}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          {prettyWebsiteName}
        </Link>
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          src={advisor.logoUrl}
          sx={{ height: "109px", width: "145px", mr: "auto" }}
        />
        <Box>
          {advisor.linkedIn && (
            <Box
              component={Link}
              underline="none"
              href={advisor.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  color: "inherit",
                  p: 1,
                }}
              >
                <LinkedInIcon
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Box>
          )}
          {advisor.instagram && (
            <Box
              component={Link}
              underline="none"
              href={advisor.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  color: "inherit",
                  p: 1,
                }}
              >
                <InstagramIcon
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Box>
          )}
          {advisor.facebook && (
            <Box
              component={Link}
              underline="none"
              href={advisor.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  color: "inherit",
                  p: 1,
                }}
              >
                <FacebookIcon
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Box>
          )}
          {advisor.twitter && (
            <Box
              component={Link}
              underline="none"
              href={advisor.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  color: "inherit",
                  p: 1,
                }}
              >
                <XIcon
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Box>
          )}
          {advisor.youtube && (
            <Box
              component={Link}
              underline="none"
              href={advisor.youtube}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                sx={{
                  color: "inherit",
                  p: 1,
                }}
              >
                <YouTubeIcon
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
