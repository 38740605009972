import { Box } from "@mui/system";
import React from "react";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import { InputAdornment, Tooltip } from "@mui/material";
import { TextNumField } from "components/FormFields";
import { UnitCategory } from "@mesh/common-js/dist/financial/unitCategory_pb";
import { unitCategoryToString } from "@mesh/common-js/dist/financial";
import { Info as InfoIcon } from "@mui/icons-material";
import { ValidationResult } from "common/validation";
import { DateTimeField } from "@mesh/common-js-react/dist/FormFields";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import dayjs from "dayjs";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export type SubscriptionFormProps = {
  readOnly: boolean;
  disabled: boolean;
  unitCategory: UnitCategory;
  onChange: (updatedSubscriptionOrderBook: SubscriptionOrderBook) => void;
  subscriptionOrderBook: SubscriptionOrderBook;
  validationResult: ValidationResult;
};

export const SubscriptionForm = (props: SubscriptionFormProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <TextNumField
          id={
            "primaryMarketListing-subscriptionOrderBook-unitPrice-textNumField"
          }
          readOnly={props.readOnly}
          disabled={props.disabled}
          fullWidth
          noDecimalPlaces={7}
          label={`${unitCategoryToString(props.unitCategory)} Price`}
          disallowNegative
          value={props.subscriptionOrderBook.unitPrice.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            props.subscriptionOrderBook.unitPrice =
              props.subscriptionOrderBook.unitPrice.setValue(e.target.value);
            props.onChange(props.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {props.subscriptionOrderBook.unitPrice.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Cost of a single ${unitCategoryToString(props.unitCategory)}`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-unitPrice"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-unitPrice"
            ]
          }
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "start",
        })}
      >
        <TextNumField
          id={
            "primaryMarketListing-subscriptionOrderBook-subscriptionAmount-textNumField"
          }
          readOnly={props.readOnly}
          disabled={props.disabled}
          fullWidth
          noDecimalPlaces={7}
          label={"Subscription Amount"}
          disallowNegative
          value={props.subscriptionOrderBook.subscriptionAmount.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            props.subscriptionOrderBook.subscriptionAmount =
              props.subscriptionOrderBook.subscriptionAmount.setValue(
                e.target.value,
              );
            props.onChange(props.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {props.subscriptionOrderBook.subscriptionAmount.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip placement="top" title={"Target Amount to Raise"}>
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-subscriptionAmount"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-subscriptionAmount"
            ]
          }
        />
        <TextNumField
          id={
            "primaryMarketListing-subscriptionOrderBook-unitsToSell-textNumField"
          }
          readOnly
          fullWidth
          noDecimalPlaces={7}
          label={`${unitCategoryToString(props.unitCategory)}'s To Sell`}
          disallowNegative
          value={
            props.subscriptionOrderBook.subscriptionAmount.value
              .dividedBy(props.subscriptionOrderBook.unitPrice.value)
              .isFinite()
              ? props.subscriptionOrderBook.subscriptionAmount.value.dividedBy(
                  props.subscriptionOrderBook.unitPrice.value,
                )
              : undefined
          }
          sx={{ maxWidth: 320 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {props.subscriptionOrderBook.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Subscription Amount / ${unitCategoryToString(props.unitCategory)} Price`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-overSubscriptionAmount"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-overSubscriptionAmount"
            ]
          }
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "start",
        })}
      >
        <TextNumField
          id={
            "primaryMarketListing-subscriptionOrderBook-overSubscriptionAmount-textNumField"
          }
          readOnly={props.readOnly}
          disabled={props.disabled}
          fullWidth
          noDecimalPlaces={7}
          label={"Over Subscription Amount"}
          disallowNegative
          value={props.subscriptionOrderBook.overSubscriptionAmount.value}
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            props.subscriptionOrderBook.overSubscriptionAmount =
              props.subscriptionOrderBook.overSubscriptionAmount.setValue(
                e.target.value,
              );
            props.onChange(props.subscriptionOrderBook);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {props.subscriptionOrderBook.overSubscriptionAmount.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={"Absolute Possible Maximum Amount to Raise"}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-overSubscriptionAmount"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-overSubscriptionAmount"
            ]
          }
        />
        <TextNumField
          id={
            "primaryMarketListing-subscriptionOrderBook-maxUnitsToSell-textNumField"
          }
          readOnly
          fullWidth
          noDecimalPlaces={7}
          label={`Maximum ${unitCategoryToString(props.unitCategory)}'s To Sell`}
          disallowNegative
          value={
            props.subscriptionOrderBook.overSubscriptionAmount.value
              .dividedBy(props.subscriptionOrderBook.unitPrice.value)
              .isFinite()
              ? props.subscriptionOrderBook.overSubscriptionAmount.value.dividedBy(
                  props.subscriptionOrderBook.unitPrice.value,
                )
              : undefined
          }
          sx={{ maxWidth: 320 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {props.subscriptionOrderBook.token.code}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Over Subscription Amount / ${unitCategoryToString(props.unitCategory)} Price`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-overSubscriptionAmount"
            ]
          }
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          flexFlow: "wrap",
          gap: theme.spacing(1),
          alignItems: "start",
        })}
      >
        <DateTimeField
          id={
            "primaryMarketListing-subscriptionOrderBook-openDate-dateTimeField"
          }
          sx={{ maxWidth: 320 }}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Open Date"}
          value={dayjsToProtobufTimestamp(
            dayjs(props.subscriptionOrderBook.openDate),
          )}
          minDate={dayjs().add(1, "day")}
          maxDateTime={dayjs(props.subscriptionOrderBook.closeDate).subtract(
            1,
            "day",
          )}
          onChange={(newValue) => {
            props.subscriptionOrderBook.openDate = protobufTimestampToDayjs(
              newValue ?? new Timestamp(),
            ).format();
            props.onChange(props.subscriptionOrderBook);
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-openDate"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-openDate"
            ]
          }
        />
        <DateTimeField
          id={
            "primaryMarketListing-subscriptionOrderBook-closeDate-dateTimeField"
          }
          sx={{ maxWidth: 320 }}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Close Date"}
          minDate={dayjs(props.subscriptionOrderBook.openDate).add(1, "day")}
          maxDateTime={dayjs(props.subscriptionOrderBook.settlementDate)}
          value={dayjsToProtobufTimestamp(
            dayjs(props.subscriptionOrderBook.closeDate),
          )}
          onChange={(newValue) => {
            props.subscriptionOrderBook.closeDate = protobufTimestampToDayjs(
              newValue ?? new Timestamp(),
            ).format();
            props.onChange(props.subscriptionOrderBook);
          }}
          error={
            !!props.validationResult.fieldValidations[
              "subscriptionOrderBook-closeDate"
            ]
          }
          helperText={
            props.validationResult.fieldValidations[
              "subscriptionOrderBook-closeDate"
            ]
          }
        />
        <Tooltip
          placement="top"
          title={`Set to the Issue Date of the ${unitCategoryToString(props.unitCategory)}`}
        >
          <span>
            <DateTimeField
              id={
                "primaryMarketListing-subscriptionOrderBook-settlementDate-dateTimeField"
              }
              sx={{ maxWidth: 320 }}
              readOnly
              label={"Settlement Date"}
              value={dayjsToProtobufTimestamp(
                dayjs(props.subscriptionOrderBook.settlementDate),
              )}
            />
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
