/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: financial/paymentStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var financial_paymentTrigger_pb = require('../financial/paymentTrigger_pb.js')

var financial_payment_pb = require('../financial/payment_pb.js')

var ledger_amount_pb = require('../ledger/amount_pb.js')
const proto = {};
proto.financial = require('./paymentStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PaymentStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PaymentStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.InitialisePaymentRequest,
 *   !proto.financial.InitialisePaymentResponse>}
 */
const methodDescriptor_PaymentStateController_InitialisePayment = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/InitialisePayment',
  grpc.web.MethodType.UNARY,
  proto.financial.InitialisePaymentRequest,
  proto.financial.InitialisePaymentResponse,
  /**
   * @param {!proto.financial.InitialisePaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.InitialisePaymentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.InitialisePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.InitialisePaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.InitialisePaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.initialisePayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/InitialisePayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_InitialisePayment,
      callback);
};


/**
 * @param {!proto.financial.InitialisePaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.InitialisePaymentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.initialisePayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/InitialisePayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_InitialisePayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.PayPaymentRequest,
 *   !proto.financial.PayPaymentResponse>}
 */
const methodDescriptor_PaymentStateController_PayPayment = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/PayPayment',
  grpc.web.MethodType.UNARY,
  proto.financial.PayPaymentRequest,
  proto.financial.PayPaymentResponse,
  /**
   * @param {!proto.financial.PayPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.PayPaymentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.PayPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.PayPaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.PayPaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.payPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/PayPayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_PayPayment,
      callback);
};


/**
 * @param {!proto.financial.PayPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.PayPaymentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.payPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/PayPayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_PayPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkPaymentFailedRequest,
 *   !proto.financial.MarkPaymentFailedResponse>}
 */
const methodDescriptor_PaymentStateController_MarkPaymentFailed = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/MarkPaymentFailed',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkPaymentFailedRequest,
  proto.financial.MarkPaymentFailedResponse,
  /**
   * @param {!proto.financial.MarkPaymentFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkPaymentFailedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkPaymentFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkPaymentFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkPaymentFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.markPaymentFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentFailed',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentFailed,
      callback);
};


/**
 * @param {!proto.financial.MarkPaymentFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkPaymentFailedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.markPaymentFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentFailed',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkPaymentPaidRequest,
 *   !proto.financial.MarkPaymentPaidResponse>}
 */
const methodDescriptor_PaymentStateController_MarkPaymentPaid = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/MarkPaymentPaid',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkPaymentPaidRequest,
  proto.financial.MarkPaymentPaidResponse,
  /**
   * @param {!proto.financial.MarkPaymentPaidRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkPaymentPaidResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkPaymentPaidRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkPaymentPaidResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkPaymentPaidResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.markPaymentPaid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentPaid',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentPaid,
      callback);
};


/**
 * @param {!proto.financial.MarkPaymentPaidRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkPaymentPaidResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.markPaymentPaid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentPaid',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentPaid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.CleanUpPaymentRequest,
 *   !proto.financial.CleanUpPaymentResponse>}
 */
const methodDescriptor_PaymentStateController_CleanUpPayment = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/CleanUpPayment',
  grpc.web.MethodType.UNARY,
  proto.financial.CleanUpPaymentRequest,
  proto.financial.CleanUpPaymentResponse,
  /**
   * @param {!proto.financial.CleanUpPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.CleanUpPaymentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.CleanUpPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.CleanUpPaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.CleanUpPaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.cleanUpPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/CleanUpPayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_CleanUpPayment,
      callback);
};


/**
 * @param {!proto.financial.CleanUpPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.CleanUpPaymentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.cleanUpPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/CleanUpPayment',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_CleanUpPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkPaymentCleanupUnderInvestigationRequest,
 *   !proto.financial.MarkPaymentCleanupUnderInvestigationResponse>}
 */
const methodDescriptor_PaymentStateController_MarkPaymentCleanupUnderInvestigation = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/MarkPaymentCleanupUnderInvestigation',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkPaymentCleanupUnderInvestigationRequest,
  proto.financial.MarkPaymentCleanupUnderInvestigationResponse,
  /**
   * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkPaymentCleanupUnderInvestigationResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkPaymentCleanupUnderInvestigationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkPaymentCleanupUnderInvestigationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.markPaymentCleanupUnderInvestigation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentCleanupUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentCleanupUnderInvestigation,
      callback);
};


/**
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkPaymentCleanupUnderInvestigationResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.markPaymentCleanupUnderInvestigation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentCleanupUnderInvestigation',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentCleanupUnderInvestigation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkPaymentCleanupFailedRequest,
 *   !proto.financial.MarkPaymentCleanupFailedResponse>}
 */
const methodDescriptor_PaymentStateController_MarkPaymentCleanupFailed = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/MarkPaymentCleanupFailed',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkPaymentCleanupFailedRequest,
  proto.financial.MarkPaymentCleanupFailedResponse,
  /**
   * @param {!proto.financial.MarkPaymentCleanupFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkPaymentCleanupFailedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkPaymentCleanupFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkPaymentCleanupFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkPaymentCleanupFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.markPaymentCleanupFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentCleanupFailed',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentCleanupFailed,
      callback);
};


/**
 * @param {!proto.financial.MarkPaymentCleanupFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkPaymentCleanupFailedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.markPaymentCleanupFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentCleanupFailed',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentCleanupFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkPaymentCompleteRequest,
 *   !proto.financial.MarkPaymentCompleteResponse>}
 */
const methodDescriptor_PaymentStateController_MarkPaymentComplete = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/MarkPaymentComplete',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkPaymentCompleteRequest,
  proto.financial.MarkPaymentCompleteResponse,
  /**
   * @param {!proto.financial.MarkPaymentCompleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkPaymentCompleteResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkPaymentCompleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkPaymentCompleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkPaymentCompleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.markPaymentComplete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentComplete',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentComplete,
      callback);
};


/**
 * @param {!proto.financial.MarkPaymentCompleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkPaymentCompleteResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.markPaymentComplete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/MarkPaymentComplete',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_MarkPaymentComplete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ResolvePaymentStateRequest,
 *   !proto.financial.ResolvePaymentStateResponse>}
 */
const methodDescriptor_PaymentStateController_ResolvePaymentState = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/ResolvePaymentState',
  grpc.web.MethodType.UNARY,
  proto.financial.ResolvePaymentStateRequest,
  proto.financial.ResolvePaymentStateResponse,
  /**
   * @param {!proto.financial.ResolvePaymentStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ResolvePaymentStateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ResolvePaymentStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ResolvePaymentStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ResolvePaymentStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.resolvePaymentState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/ResolvePaymentState',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_ResolvePaymentState,
      callback);
};


/**
 * @param {!proto.financial.ResolvePaymentStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ResolvePaymentStateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.resolvePaymentState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/ResolvePaymentState',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_ResolvePaymentState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ResolvePaymentIDStateRequest,
 *   !proto.financial.ResolvePaymentIDStateResponse>}
 */
const methodDescriptor_PaymentStateController_ResolvePaymentIDState = new grpc.web.MethodDescriptor(
  '/financial.PaymentStateController/ResolvePaymentIDState',
  grpc.web.MethodType.UNARY,
  proto.financial.ResolvePaymentIDStateRequest,
  proto.financial.ResolvePaymentIDStateResponse,
  /**
   * @param {!proto.financial.ResolvePaymentIDStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ResolvePaymentIDStateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ResolvePaymentIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ResolvePaymentIDStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ResolvePaymentIDStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentStateControllerClient.prototype.resolvePaymentIDState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentStateController/ResolvePaymentIDState',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_ResolvePaymentIDState,
      callback);
};


/**
 * @param {!proto.financial.ResolvePaymentIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ResolvePaymentIDStateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentStateControllerPromiseClient.prototype.resolvePaymentIDState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentStateController/ResolvePaymentIDState',
      request,
      metadata || {},
      methodDescriptor_PaymentStateController_ResolvePaymentIDState);
};


module.exports = proto.financial;

