import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import meshLogo from "assets/images/logo/meshLogo.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";

interface AgentSectionProps {
  issuerName: string;
  assetName: string;
}

export const AgentSection = ({ issuerName, assetName }: AgentSectionProps) => {
  return (
    <Box sx={{ mt: 4, mx: { sm: 5, xs: 3 } }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.text.disabled,
            fontWeight: "bold",
          })}
        >
          Agent:
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold", ml: 1 }}>
          Mesh
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            mb: 2,
          })}
        >
          {issuerName} has appointed Mesh to act as transfer agent, paying
          agent, and calculation agent for {assetName}, serviced end-to-end
          through the Mesh.trade platform.
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            mb: 2,
          })}
        >
          Mesh is a digital ecosystem for raising capital and trading capital
          markets assets. Using the best of leading-edge blockchain technology,
          Mesh has removed many of the traditional barriers to entry, eliminated
          excessive participation costs, and made capital raising and investing
          much more efficient for all market participants. Mesh Trade South
          Africa (Pty) Ltd is a licensed Financial Services Provider (53710) and
          an Accountable Institution registered with the Financial Intelligence
          Centre.
        </Typography>
      </Box>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          mb: 1,
        })}
      >
        Website:{" "}
        <Link
          href="https://www.mesh.trade/"
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
        >
          mesh.trade
        </Link>
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          src={meshLogo}
          sx={{
            height: "109px",
            width: "145px",
            mr: "auto",
          }}
        />
        <Box sx={{ display: "flex" }}>
          <Box
            component={Link}
            underline="none"
            href="https://www.linkedin.com/company/mesh-trade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                color: "inherit",
                p: 1,
              }}
            >
              <LinkedInIcon
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
            </IconButton>
          </Box>
          <Box
            component={Link}
            underline="none"
            href="https://www.facebook.com/Mesh.trade"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                color: "inherit",
                p: 1,
              }}
            >
              <FacebookOutlinedIcon
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
            </IconButton>
          </Box>
          <Box
            component={Link}
            underline="none"
            href="https://twitter.com/mesh_trade"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              sx={{
                color: "inherit",
                p: 1,
              }}
            >
              <XIcon
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
