import React, { ReactNode, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextareaAutosize,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CurrencyTextField,
  DateField,
  TextField,
  TextNumField,
} from "components/FormFields";
import {
  AddCircleOutlineOutlined as AddIcon,
  Block as NotAllowedIcon,
  CancelOutlined as RemoveIcon,
  Close as CloseIcon,
  DeleteOutlineOutlined as DeleteIcon,
  Done as DoneIcon,
  EditOutlined as EditIcon,
  ExpandMore as ExpandMoreIcon,
  GetAppOutlined as DownloadIcon,
  Info as InfoIcon,
  PublishOutlined as UploadIcon,
} from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import cx from "classnames";
import dayjs from "dayjs";
import {
  FinancialCurrencyStablecoinViewModel,
  FinancialCurrencyStablecoinViewReader,
} from "james/views/financialCurrencyStablecoinView";
import {
  FinancialETNRollCorporateActionViewModel,
  FinancialETNRollCorporateActionViewReader,
} from "james/views/financialETNRollCorporateActionView";
import { Group, GroupRepository } from "james/group";
import { countries } from "james/country";
import { CountryOption } from "james/country/countries";
import { useSnackbar } from "notistack";
import isEqual from "lodash/isEqual";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Model as StellarAccountStubViewModel,
  Reader as StellarAccountStubViewReader,
} from "james/views/stellarAccountStubView";
import { Currency } from "james/financial/Currency";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import {
  LedgerAccountCategory,
  Mint,
  MintRepository,
  Token,
} from "james/ledger";
import { IDIdentifier } from "james/search/identifier/ID";
import { getFormat } from "james/currency/Format";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { useDropzone } from "react-dropzone";
import { Document } from "james/document";
import { doUpload } from "utilities/network/upload";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { formatTextNum } from "utilities/number";
import { ISO4217_currencyList } from "james/currency/supportedCurrencies";
import { Money } from "james/money";

// financial
import {
  AllStockExchanges,
  StockExchange,
} from "james/financial/StockExchange";
import {
  AllFinancialSectors,
  AllInstrumentRiskProfiles,
  AllInvestorProfiles,
  AllRatingAgencies,
  AssetClass,
  CountryAllocation,
  ErrDraftETNNameAlreadyInUseErrorCode,
  ErrETNNameAlreadyInUseErrorCode,
  ETN,
  ETNAction,
  ETNStablecoin,
  ETNStablecoinAction,
  ETNStablecoinState,
  ETNStablecoinStateChangerCoordinator,
  ETNState,
  FinancialCurrencyCollection,
  FinancialInstrumentCollection,
  FinancialInstrumentStablecoinCollection,
  getPotentialNextETNActions,
  getPotentialNextETNStablecoinActions,
  Holding,
  InstrumentRiskProfile,
  InvestorProfile,
  RatingAgency,
  Ratings,
  SectorAllocation,
} from "james/financial";

// financial.ETN
import { ETNUpdater } from "james/financial/ETNUpdater";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";

// financial.ETNStablecoin
import { ETNStablecoinCreatorCoordinator } from "james/financial/ETNStablecoinCreatorCoordinator";
import { ETNStablecoinUpdater } from "james/financial/ETNStablecoinUpdater";
import { MintAssetDialog } from "components/Dialogs/Minting";
import { UnderlyingCurrencyCategory } from "james/views/financialCurrencyStablecoinView/Model";
import { ViewEditInstrumentAnnualPerformanceLog } from "./ViewEditInstrumentAnnualPerformanceLog";
import { LedgerAssetReadyToList, ListingInspector } from "james/market";
import { download } from "utilities/network/download";
import { Query } from "james/search/query";
import { Client, ClientKYCStatus } from "james/client";
import { SectorAllocationPieChart } from "./SectorAllocationPieChart";
import { CountryAllocationPieChart } from "./CountryAllocationPieChart";
import { InstrumentStateChip, PlacementStateWordsChip } from "./Chips";
import {
  IssueWarningDialog,
  IssueWarningDialogOptions,
} from "./IssueWarningDialog";
import {
  PendingDocumentUpload,
  StepValidationResult,
  TouchedFields,
  WarningDialogOptions,
} from "./common";
import {
  validateRights2ETNStep1,
  validateRights2ETNStep2,
  validateRights2ETNStep3,
  validateRightsToETNSupportingDocuments,
} from "./etnValidations";
import { InstrumentsViewPaths } from "./Instruments";
import { HoldingsPieChart } from "./HoldingsPieChart";
import { TokenClass } from "./NewInstrumentDialog";
import { dateIsValid } from "utilities/date/dateIsValid";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import BigNumber from "bignumber.js";
import {
  AllInstrumentManagementFeeFrequencies,
  AllInstrumentManagementFeeTypes,
  InstrumentManagementFeeType,
} from "james/financial/InstrumentManagementFee";
import { CurrencyAmountInstrumentManagementFee } from "james/financial/InstrumentManagementFeeCurrencyAmount";
import { PercentageInstrumentManagementFee } from "james/financial/InstrumentManagementFeePercentage";
import { ETNRollCorporateActionCard } from "components/CorporateActions/ETNRollCorporateAction";

const PREFIX = "ETNDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  rootLeftColumn: `${PREFIX}-rootLeftColumn`,
  rootRightColumn: `${PREFIX}-rootRightColumn`,
  successText: `${PREFIX}-successText`,
  errorText: `${PREFIX}-errorText`,
  searchIcon: `${PREFIX}-searchIcon`,
  paddingBottom: `${PREFIX}-paddingBottom`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  killGridPadding: `${PREFIX}-killGridPadding`,
  tokenClassSelectionLayout: `${PREFIX}-tokenClassSelectionLayout`,
  docsMediaMsgLayout: `${PREFIX}-docsMediaMsgLayout`,
  docsMediaInfoIcon: `${PREFIX}-docsMediaInfoIcon`,
  docsMediaInfoMsg: `${PREFIX}-docsMediaInfoMsg`,
  dropZone: `${PREFIX}-dropZone`,
  dropZoneAccept: `${PREFIX}-dropZoneAccept`,
  dropZoneReject: `${PREFIX}-dropZoneReject`,
  dropZoneGuidanceText: `${PREFIX}-dropZoneGuidanceText`,
  docsMediaColContent: `${PREFIX}-docsMediaColContent`,
  docsMediaColContentWarningMsg: `${PREFIX}-docsMediaColContentWarningMsg`,
  docsMediaLineItemLayout: `${PREFIX}-docsMediaLineItemLayout`,
  docsMediaLineItemMarginTop: `${PREFIX}-docsMediaLineItemMarginTop`,
  docsMediaLineItemBinIcon: `${PREFIX}-docsMediaLineItemBinIcon`,
  docsMediaLineItemFileName: `${PREFIX}-docsMediaLineItemFileName`,
  docsMediaLineItemFileNameWithDL: `${PREFIX}-docsMediaLineItemFileNameWithDL`,
  docsMediaDownloadIconButton: `${PREFIX}-docsMediaDownloadIconButton`,
  stepsCommonAccordionSummaryRootOverride: `${PREFIX}-stepsCommonAccordionSummaryRootOverride`,
  stepsCommonAccordionSummaryContentLayout: `${PREFIX}-stepsCommonAccordionSummaryContentLayout`,
  stepsCommonAccordionDetailsLayout: `${PREFIX}-stepsCommonAccordionDetailsLayout`,
  stepsCommonGuidanceText: `${PREFIX}-stepsCommonGuidanceText`,
  step1InstrumentDetailsFieldsSection: `${PREFIX}-step1InstrumentDetailsFieldsSection`,
  step1InstrumentDetailsInstrumentFieldsRiskProfileLayout: `${PREFIX}-step1InstrumentDetailsInstrumentFieldsRiskProfileLayout`,
  step1InstrumentDetailsMaxUnitsInIssueLayout: `${PREFIX}-step1InstrumentDetailsMaxUnitsInIssueLayout`,
  step1InstrumentDetailsMaxUnitsInIssueInfoIcon: `${PREFIX}-step1InstrumentDetailsMaxUnitsInIssueInfoIcon`,
  step2FieldsLayout: `${PREFIX}-step2FieldsLayout`,
  step2FieldDivider: `${PREFIX}-step2FieldDivider`,
  step2InstrumentInfoFieldLayout: `${PREFIX}-step2InstrumentInfoFieldLayout`,
  step2IssuerInfoFieldLayout: `${PREFIX}-step2IssuerInfoFieldLayout`,
  step2IssuerManagementFeeFieldLayout: `${PREFIX}-step2IssuerManagementFeeFieldLayout`,
  step2IssuerManagementFeeCcyField: `${PREFIX}-step2IssuerManagementFeeCcyField`,
  step3GeneralDescriptionFieldLayout: `${PREFIX}-step3GeneralDescriptionFieldLayout`,
  step3GeneralDescriptionFieldTextAreaViewMode: `${PREFIX}-step3GeneralDescriptionFieldTextAreaViewMode`,
  step3GeneralDescriptionFieldTextArea: `${PREFIX}-step3GeneralDescriptionFieldTextArea`,
  holdingsAndAllocationSumLine: `${PREFIX}-holdingsAndAllocationSumLine`,
  holdingsAndAllocationsViewModeLayout: `${PREFIX}-holdingsAndAllocationsViewModeLayout`,
  holdingsAndAllocationsViewModeSectionLayout: `${PREFIX}-holdingsAndAllocationsViewModeSectionLayout`,
  holdingsAndAllocationsViewModeList: `${PREFIX}-holdingsAndAllocationsViewModeList`,
  holdingsAndAllocationsViewModeLineItem: `${PREFIX}-holdingsAndAllocationsViewModeLineItem`,
  holdingsAndAllocationsLayout: `${PREFIX}-holdingsAndAllocationsLayout`,
  holdingsAndAllocationsSpacer: `${PREFIX}-holdingsAndAllocationsSpacer`,
  holdingsAndAllocationsControlLineItem: `${PREFIX}-holdingsAndAllocationsControlLineItem`,
  holdingsAndAllocationsLineItem: `${PREFIX}-holdingsAndAllocationsLineItem`,
  holdingsAnAllocationsLineItemHelperText: `${PREFIX}-holdingsAnAllocationsLineItemHelperText`,
  holdingsAndAllocationsNameField: `${PREFIX}-holdingsAndAllocationsNameField`,
  holdingsAndAllocationsPercentField: `${PREFIX}-holdingsAndAllocationsPercentField`,
  holdingsAndAllocationsList: `${PREFIX}-holdingsAndAllocationsList`,
  instrumentAnnualPerformanceLogHelperText: `${PREFIX}-instrumentAnnualPerformanceLogHelperText`,
  infoIcon: `${PREFIX}-infoIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  //
  // Dialog Title
  //
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
  },

  [`& .${classes.heading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 40,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  //
  // Dialog Content
  //
  [`& .${classes.dialogContent}`]: {
    padding: 0,
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    height: "calc(100vh - 57px)",
    overflowY: "auto",
  },

  [`& .${classes.rootLeftColumn}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    borderRight: `solid 1px ${theme.palette.divider}`,
  },

  [`& .${classes.rootRightColumn}`]: {
    padding: theme.spacing(5, 3.5, 2, 3.5),
  },

  //
  // text
  //
  [`& .${classes.successText}`]: { color: theme.palette.success.light },

  [`& .${classes.errorText}`]: { color: theme.palette.error.main },

  //
  // other
  //
  [`& .${classes.searchIcon}`]: {
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.paddingBottom}`]: {
    paddingBottom: theme.spacing(10),
  },

  [`& .${classes.fieldWidth}`]: {
    width: 250,
  },

  [`& .${classes.killGridPadding}`]: {},

  //
  // token class selection
  //
  [`& .${classes.tokenClassSelectionLayout}`]: {
    padding: theme.spacing(3, 2, 3, 3),
  },

  //
  // docs media
  //
  [`& .${classes.docsMediaMsgLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(1),
    justifyItems: "center",
  },

  [`& .${classes.docsMediaInfoIcon}`]: {
    fontSize: 60,
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.docsMediaInfoMsg}`]: {
    maxWidth: 300,
    textAlign: "center",
  },

  [`& .${classes.dropZone}`]: {
    padding: theme.spacing(2, 0, 1, 0),
    width: "calc(0.3vw - 30)",
    height: 143,
    border: `1px dashed ${theme.palette.text.primary}`,
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    display: "grid",
    gridTemplateColumns: "1fr",
    alignItems: "center",
    justifyItems: "center",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.dropZoneAccept}`]: {
    border: `1px dashed ${theme.palette.success.main}`,
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },

  [`& .${classes.dropZoneReject}`]: {
    border: `1px dashed ${theme.palette.error.main}`,
    backgroundColor: alpha(theme.palette.error.main, 0.2),
  },

  [`& .${classes.dropZoneGuidanceText}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaColContent}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.docsMediaColContentWarningMsg}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.docsMediaLineItemLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.docsMediaLineItemMarginTop}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaLineItemBinIcon}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaLineItemFileName}`]: {
    gridColumn: "1/3",
  },

  [`& .${classes.docsMediaLineItemFileNameWithDL}`]: {
    gridColumn: "1/3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.docsMediaDownloadIconButton}`]: {
    marginLeft: theme.spacing(1),
  },

  // -------------------------------------------------
  // Steps Common
  // -------------------------------------------------
  [`& .${classes.stepsCommonAccordionSummaryRootOverride}`]: {
    flexDirection: "row-reverse",
    paddingLeft: theme.spacing(3),
    backgroundColor: alpha(theme.palette.background.default, 0.6),
  },

  [`& .${classes.stepsCommonAccordionSummaryContentLayout}`]: {
    width: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    display: "grid",
    gridTemplateColumns: "200px auto 1fr auto",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.stepsCommonAccordionDetailsLayout}`]: {
    display: "grid",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(3),
  },

  [`& .${classes.stepsCommonGuidanceText}`]: {
    maxWidth: 740,
  },

  // -------------------------------------------------
  // Step 1 - Digital Instrument Section
  // -------------------------------------------------
  [`& .${classes.step1InstrumentDetailsFieldsSection}`]: {
    display: "grid",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 250px)",
  },

  [`& .${classes.step1InstrumentDetailsInstrumentFieldsRiskProfileLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(5),
  },

  [`& .${classes.step1InstrumentDetailsMaxUnitsInIssueLayout}`]: {
    display: "grid",
    gridTemplateColumns: "160px 1fr",
    alignItems: "center",
  },

  [`& .${classes.step1InstrumentDetailsMaxUnitsInIssueInfoIcon}`]: {
    marginLeft: -45,
    zIndex: 1,
  },

  // -------------------------------------------------
  // Step 2 - Reference Instrument Section
  // -------------------------------------------------
  [`& .${classes.step2FieldsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "2fr auto 1fr",
  },

  [`& .${classes.step2FieldDivider}`]: {
    padding: theme.spacing(1, 2),
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },

  [`& .${classes.step2InstrumentInfoFieldLayout}`]: {
    paddingTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.step2IssuerInfoFieldLayout}`]: {
    paddingTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.step2IssuerManagementFeeFieldLayout}`]: {
    maxWidth: 250,
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.step2IssuerManagementFeeCcyField}`]: {
    width: 170,
    marginRight: theme.spacing(1),
  },

  // -------------------------------------------------
  // Step 3 - Asset Exposure
  // -------------------------------------------------
  [`& .${classes.step3GeneralDescriptionFieldLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(0.5),
  },

  [`& .${classes.step3GeneralDescriptionFieldTextAreaViewMode}`]: {
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    maxHeight: 131,
    overflowY: "auto",
  },

  [`& .${classes.step3GeneralDescriptionFieldTextArea}`]: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(0.5, 1),
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    borderRadius: 4,
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    resize: "none",
  },

  //
  // holdings and allocations
  //
  [`& .${classes.holdingsAndAllocationSumLine}`]: {
    paddingTop: theme.spacing(1),
    marginLeft: -6,
  },

  [`& .${classes.holdingsAndAllocationsViewModeLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  [`& .${classes.holdingsAndAllocationsViewModeSectionLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    gridRowGap: theme.spacing(2),
    justifyItems: "center",
  },

  [`& .${classes.holdingsAndAllocationsViewModeList}`]: {
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.holdingsAndAllocationsViewModeLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.holdingsAndAllocationsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto 1fr auto",
  },

  [`& .${classes.holdingsAndAllocationsSpacer}`]: {
    width: theme.spacing(1),
  },

  [`& .${classes.holdingsAndAllocationsControlLineItem}`]: {
    marginRight: theme.spacing(1.5),
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    justifyItems: "start",
  },

  [`& .${classes.holdingsAndAllocationsLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    justifyItems: "start",
  },

  [`& .${classes.holdingsAnAllocationsLineItemHelperText}`]: {
    gridColumn: "1/4",
    paddingLeft: theme.spacing(0.8),
  },

  [`& .${classes.holdingsAndAllocationsNameField}`]: {
    width: 170,
  },

  [`& .${classes.holdingsAndAllocationsPercentField}`]: {
    width: 65,
  },

  [`& .${classes.holdingsAndAllocationsList}`]: {
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden",
  },

  //
  // Performance Log
  //
  [`& .${classes.instrumentAnnualPerformanceLogHelperText}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    columnGap: theme.spacing(1),
    margin: theme.spacing(-1, 0, 1, 0),
  },

  //
  // other
  //
  [`& .${classes.infoIcon}`]: {
    marginRight: -10,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const allowedDocumentTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

export function ETNDialog() {
  // Hook Invocations
  const { viewConfiguration, authContext, myClientKYCStatus, myClient } =
    useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Component State
  const [apiLoading, setAPILoading] = useState(false);
  const [etn, setETN] = useState(new ETN());
  const [copyOfETN, setCopyOfETN] = useState(new ETN());
  const [etnStablecoin, setETNStablecoin] = useState(new ETNStablecoin());
  const [mint, setMint] = useState<Mint | undefined>();
  const [copyOfETNStablecoin, setCopyOfETNStablecoin] = useState(
    new ETNStablecoin(),
  );
  const [corporateActions, setCorporateActions] = useState<
    FinancialETNRollCorporateActionViewModel[]
  >([]);
  const [potentialGroupOwners, setPotentialGroupOwners] = useState<Group[]>([]);
  const [
    potentialValuationCurrencyStablecoins,
    setPotentialValuationCurrencyStablecoins,
  ] = useState<FinancialCurrencyStablecoinViewModel[]>([]);
  const [
    valuationStablecoinUnderlyingCurrencies,
    setValuationStablecoinUnderlyingCurrencies,
  ] = useState<Currency[]>([]);
  const [accordionSectionsOpen, setAccordionSectionsOpen] = useState<{
    [key: string]: boolean;
  }>({
    step1: true,
    step2: false,
    step3: false,
    corporateActions: false,
  });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [pendingCountryAllocation, setPendingCountryAllocation] =
    useState<CountryAllocation>(new CountryAllocation());
  const [pendingHolding, setPendingHolding] = useState<Holding>(new Holding());
  const [pendingSectorAllocation, setPendingSectorAllocation] =
    useState<SectorAllocation>(new SectorAllocation());
  const [step1ValidationResult, setStep1ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [step2ValidationResult, setStep2ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [step3ValidationResult, setStep3ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [supportingDocsStepValidationResult, setSupportingDocsStepResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const [issueWarningDialogOptions, setIssueWarningDialogOptions] =
    useState<IssueWarningDialogOptions | null>(null);
  const [issuanceAccountStub, setIssuanceAccountStub] = useState(
    new StellarAccountStubViewModel(),
  );
  const [viewModeOn, setViewModeOn] = useState(true);
  const [pendingDocumentUploads, setPendingDocumentUploads] = useState<
    PendingDocumentUpload[]
  >([]);
  const [showMintAssetDialog, setShowMintAssetDialog] = useState(false);
  const instrumentIsPlaced = useRef(false);

  // Computed State
  const unsavedETNChangesExist = !isEqual(etn, copyOfETN);
  const unsavedETNStablecoinChangesExist = !isEqual(
    etnStablecoin,
    copyOfETNStablecoin,
  );
  const unsavedChangesExist =
    unsavedETNChangesExist || unsavedETNStablecoinChangesExist;
  const allStepsComplete =
    step1ValidationResult.stepComplete &&
    step2ValidationResult.stepComplete &&
    step3ValidationResult.stepComplete &&
    supportingDocsStepValidationResult.stepComplete;
  const instrumentsViewConfig = viewConfiguration.Instruments
    ? viewConfiguration.Instruments
    : {};
  const marketAssetViewConfig = instrumentsViewConfig.MarketAssetActions
    ? instrumentsViewConfig.MarketAssetActions
    : {};
  const etnActionsViewConfig = instrumentsViewConfig.ETNActions
    ? instrumentsViewConfig.ETNActions
    : {};
  const etnStablecoinActionsViewConfig =
    instrumentsViewConfig.ETNStablecoinActions
      ? instrumentsViewConfig.ETNStablecoinActions
      : {};
  const potentialNextActionsForETN =
    etn.state === "" ? [] : getPotentialNextETNActions(etn.state);
  const potentialNextActionsForETNStablecoin =
    etnStablecoin.state === ""
      ? []
      : getPotentialNextETNStablecoinActions(etnStablecoin.state);
  const creatingNew = etn.id === "";
  const editIsANextAction =
    [
      // 'edit' is a next action if
      // some of these update actions are included in the
      // next potential actions for etn...
      ETNAction.DraftUpdate,
      ETNAction.ChangeMaturityDate,
      ETNAction.ChangeHoldings,
      ETNAction.ChangeSectorAllocations,
      ETNAction.ChangeCountryAllocations,
      ETNAction.ChangeSupportingDocuments,
    ].some((a) => potentialNextActionsForETN.includes(a)) || // OR
    [
      // some of these update actions are included in the
      // next potential actions for etn stablecoin then
      ETNStablecoinAction.DraftUpdate,
      ETNStablecoinAction.IncreaseMaximumUnits,
      ETNStablecoinAction.DecreaseMaximumUnits,
      ETNStablecoinAction.ChangeMaturityDate,
    ].some((a) => potentialNextActionsForETNStablecoin.includes(a));
  const kycStatusVerified =
    myClientKYCStatus === ClientKYCStatus.VerifiedStatus;
  // determine the validity of dates
  const invalidDatesInForm = !(
    dateIsValid(etnStablecoin.issueDate) &&
    dateIsValid(etn.issueDate) &&
    dateIsValid(etnStablecoin.maturityDate) &&
    dateIsValid(etn.maturityDate)
  );

  // Initial Component Load
  useEffect(() => {
    (async () => {
      // start loading
      setAPILoading(true);

      // prepare the in dialog ETN/ETNStablecoin pair
      let etnStablecoinInDialog = new ETNStablecoin();
      etnStablecoinInDialog.state = ETNStablecoinState.Draft;
      let etnInDialog = new ETN();
      etnInDialog.state = ETNState.Draft;

      // prepare the in dialog Mint
      let mintInDialog: Mint | undefined;

      // determine if an existing instrument/stablecoin pair is being viewed/manipulated
      // or if a new ETN/ETNStablecoin pair being created
      const etnStablecoinIDFromURL = searchParams.get("id");
      let newPair: boolean;
      if (etnStablecoinIDFromURL) {
        // id from url set :- viewing/editing existing

        if (
          searchParams.get("edit") &&
          !etnStablecoinActionsViewConfig.Update
        ) {
          // if edit mode is selected and user does not have update permission
          // then return to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newPair = false;

        if (etnStablecoinIDFromURL === etnStablecoin.id) {
          // stop reload at this point if the ETNStablecoin in the dialog state matches the url
          setAPILoading(false);
          return;
        }

        // retrieve the ETNStablecoin
        try {
          const retrievedStablecoin = (
            await FinancialInstrumentStablecoinCollection.RetrieveInstrumentStablecoin(
              {
                context: authContext,
                identifier: IDIdentifier(etnStablecoinIDFromURL),
              },
            )
          ).instrumentStablecoin;
          if (retrievedStablecoin instanceof ETNStablecoin) {
            etnStablecoinInDialog = retrievedStablecoin;
          } else {
            console.error("unexpected stablecoin type");
            navigate(InstrumentsViewPaths.Table);
            return;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving instrument stablecoin: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Instrument Stablecoin: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        // retrieve the ETN backing the ETNStablecoin
        try {
          const retrievedInstrument = (
            await FinancialInstrumentCollection.RetrieveInstrument({
              context: authContext,
              identifier: IDIdentifier(etnStablecoinInDialog.instrumentID),
            })
          ).instrument;
          if (retrievedInstrument instanceof ETN) {
            etnInDialog = retrievedInstrument;
          } else {
            console.error("unexpected instrument type");
            navigate(InstrumentsViewPaths.Table);
            return;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving instrument: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Instrument: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          navigate(InstrumentsViewPaths.Table);
          return;
        }
      } else {
        // id from url not set :- creating new

        // default asset class to Debt
        etnInDialog.assetClass = AssetClass.Debt;

        if (!etnStablecoinActionsViewConfig.Create) {
          // if user does not have permission to create then go back to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newPair = true;
      }
      // If execution reaches here either both the ETNStablecoin and ETN have been retrieved
      // or neither and both are non created drafts.

      // load associated required data
      try {
        await Promise.all([
          // if digital etn can be minted then retrieve the mint
          (async () => {
            try {
              const mintRecords = (
                await MintRepository.SearchMint({
                  context: authContext,
                  criteria: {
                    "token.code": TextExactCriterion(
                      etnStablecoinInDialog.token.code,
                    ),
                    "token.issuer": TextExactCriterion(
                      etnStablecoinInDialog.token.issuer,
                    ),
                    "token.network": TextExactCriterion(
                      etnStablecoinInDialog.token.network,
                    ),
                  },
                  query: new Query({
                    limit: 1,
                    offset: 0,
                    sorting: [],
                  }),
                })
              ).records;
              if (mintRecords.length === 1) {
                mintInDialog = mintRecords[0];
              }
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `error retrieving mint: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          })(),

          // determine if instrument is already listed
          (async () => {
            if (!LedgerAssetReadyToList(etnStablecoinInDialog)) {
              return;
            }
            try {
              instrumentIsPlaced.current = (
                await ListingInspector.DoesListingForTokenExist({
                  context: authContext,
                  token: etnStablecoinInDialog.token,
                })
              ).exists;
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `error determining if instrument stablecoin is ready for listing: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          })(),

          // fetch required groups
          (async () => {
            if (etnStablecoinActionsViewConfig.Create) {
              // if the user has permission to create a ETN then
              // get all groups in which the user has permission to CreateNewStablecoin
              try {
                const updatedPotentialGroupOwners = (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: (
                      await Determiner.DetermineScopeCriteriaByRoles({
                        context: authContext,
                        service: new Permission({
                          serviceName: "CoordinateCreateNewETNStablecoin",
                          serviceProvider:
                            ETNStablecoinCreatorCoordinator.serviceProvider,
                          description: "-",
                        }),
                        criteria: {},
                        scopeFields: [ScopeFields.IDField],
                        buildScopeTree: false,
                      })
                    ).criteria,
                  })
                ).records;
                setPotentialGroupOwners(updatedPotentialGroupOwners);
                if (updatedPotentialGroupOwners.length && newPair) {
                  etnStablecoinInDialog.ownerID =
                    updatedPotentialGroupOwners[0].id;
                  etnInDialog.ownerID = updatedPotentialGroupOwners[0].id;
                }
              } catch (e) {
                const err = errorContextErrorTranslator.translateError(e);
                console.error(
                  `unable to search for potential group owners: ${
                    err.message ? err.message : err.toString()
                  }`,
                );
              }
            } else if (etnInDialog.id) {
              // Otherwise, if the ETN already exists, then just get the owner group
              try {
                const updatedPotentialGroupOwners = (
                  await GroupRepository.SearchGroups({
                    context: authContext,
                    criteria: {
                      id: TextExactCriterion(etnInDialog.ownerID),
                    },
                  })
                ).records;
                setPotentialGroupOwners(updatedPotentialGroupOwners);
              } catch (e) {
                const err = errorContextErrorTranslator.translateError(e);
                console.error(
                  `unable to search for potential group owners: ${
                    err.message ? err.message : err.toString()
                  }`,
                );
              }
            }
          })(),

          // set issuance account
          (async () => {
            try {
              const potentialIssuanceAccountStubs = (
                await StellarAccountStubViewReader.Read({
                  context: authContext,
                  criteria: {
                    ownerID: TextExactCriterion(new Client(myClient).ownerID),
                    category: TextExactCriterion(
                      LedgerAccountCategory.Issuance,
                    ),
                  },
                })
              ).models;

              if (potentialIssuanceAccountStubs.length > 1) {
                console.error("retrieved more than 1 issuance account");
                enqueueSnackbar("Retrieved More Than 1 Issuance Account", {
                  variant: "error",
                });
              } else if (potentialIssuanceAccountStubs.length === 0) {
                console.error("issuance account not found");
                enqueueSnackbar("Issuance Account Not Found", {
                  variant: "error",
                });
              } else {
                setIssuanceAccountStub(potentialIssuanceAccountStubs[0]);
              }
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `error retrieving issuance account: ${
                  err.message ? err.message : err.toString()
                }`,
              );
              enqueueSnackbar(
                `Error Retrieving Issuance Account: ${
                  err.message ? err.message : err.toString()
                }`,
                { variant: "error" },
              );
            }
          })(),

          // get all currency stablecoins in which new instrument can be valued
          (async () => {
            try {
              // retrieve all potential valuation currency stablecoins
              const updatedPotentialValuationStablecoins = (
                await FinancialCurrencyStablecoinViewReader.Read({
                  context: authContext,
                  criteria: {
                    underlyingCurrencyCategory: TextExactCriterion(
                      UnderlyingCurrencyCategory.Fiat,
                    ),
                  },
                })
              ).models;

              // retrieve each associated currency
              const updatedValuationStablecoinUnderlyingCurrencies = (
                await FinancialCurrencyCollection.SearchCurrency({
                  context: authContext,
                  criteria: {
                    id: TextListCriterion(
                      updatedPotentialValuationStablecoins.map(
                        (sc) => sc.currencyID,
                      ),
                    ),
                  },
                })
              ).records;

              // if at least 1 stablecoin is retrieved
              // AND a new pair is being created
              if (updatedPotentialValuationStablecoins.length && newPair) {
                // set starting valuationToken
                etnStablecoinInDialog.valuationToken = new Token(
                  updatedPotentialValuationStablecoins[0].token,
                );

                // find resultant starting currency
                const currency =
                  updatedValuationStablecoinUnderlyingCurrencies.find(
                    (c: Currency) =>
                      updatedPotentialValuationStablecoins[0].currencyID ===
                      c.currencyID(),
                  );
                if (!currency) {
                  console.error("could not find associated currency");
                  return;
                }

                // use resultant starting currency to set initial issue and maturity date
                etnInDialog.issueDate = dayjs().startOf("day").format();
                etnInDialog.maturityDate = currency.firstCutOffAfter(
                  dayjs(etnInDialog.issueDate).add(1, "day").format(),
                );
                etnStablecoinInDialog.issueDate = etnInDialog.issueDate;
                etnStablecoinInDialog.maturityDate = etnInDialog.maturityDate;
              }

              setPotentialValuationCurrencyStablecoins(
                updatedPotentialValuationStablecoins,
              );
              setValuationStablecoinUnderlyingCurrencies(
                updatedValuationStablecoinUnderlyingCurrencies,
              );
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `unable to search for potential valuation stablecoin: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          })(),

          // fetch any corporate actions associated with the instrument
          (async () => {
            // Only attempt to retrieve corporate actions for ETNs that already exist
            if (newPair) {
              return;
            }
            try {
              setCorporateActions(
                (
                  await FinancialETNRollCorporateActionViewReader.Read({
                    context: authContext,
                    criteria: {
                      instrumentID: TextExactCriterion(etnInDialog.id),
                    },
                  })
                ).models,
              );
            } catch (e) {
              console.error("error fetching corporate actions:", e);
            }
          })(),
        ]);
      } catch (e) {
        errorContextDefaultErrorFeedback(
          e,
          "error retrieving associated required data",
        );
        navigate(InstrumentsViewPaths.Table);
        return;
      }

      setStep1ValidationResult(
        validateRights2ETNStep1(
          etnInDialog,
          etnStablecoinInDialog,
          mintInDialog,
          {},
          false,
        ),
      );
      setStep2ValidationResult(
        validateRights2ETNStep2(etnInDialog, etnStablecoinInDialog, {}, false),
      );
      setStep3ValidationResult(
        validateRights2ETNStep3(etnInDialog, etnStablecoinInDialog, {}, false),
      );
      setSupportingDocsStepResult(
        validateRightsToETNSupportingDocuments(
          etnInDialog,
          etnStablecoinInDialog,
          {},
          false,
        ),
      );

      // set the in dialog entities
      setETN(new ETN(etnInDialog));
      setCopyOfETN(new ETN(etnInDialog));
      setETNStablecoin(new ETNStablecoin(etnStablecoinInDialog));
      setCopyOfETNStablecoin(new ETNStablecoin(etnStablecoinInDialog));
      setMint(mintInDialog);

      // set view mode as requested
      setViewModeOn(!(newPair || searchParams.get("edit")));

      setAPILoading(false);
    })();
  }, [
    myClient,
    etnStablecoinActionsViewConfig.Create,
    etnStablecoinActionsViewConfig.Update,
    enqueueSnackbar,
    history,
    etnStablecoin.id,
    authContext,
  ]);

  type ETNStablecoinField = keyof ETNStablecoin;
  type ETNStablecoinValue<T extends ETNStablecoinField> = ETNStablecoin[T];

  type ETNField = keyof ETN;
  type ETNValue<T extends ETNField> = ETN[T];

  //  In Dialog Entity Updates
  const handleChangeEntitiesInDialog =
    (
      stablecoinField?: string,
      instrumentField?: string,
      otherFieldsAffected?: string[], // optional list of other fields that are affected by this update
    ) =>
    <T extends ETNStablecoinField, K extends ETNField>(
      newValue: ETNStablecoinValue<T> | ETNValue<K>,
    ) => {
      // prepare references to existing instrument and instrument stablecoin
      // before performing any updates as required
      let updatedETN = etn;
      let updatedETNStablecoin = etnStablecoin;

      // prepare updated touched fields
      const updatedTouchedFields = { ...touchedFields };
      if (otherFieldsAffected) {
        otherFieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // first perform the required field update before moving on
      // to consider 'side effects'
      if (stablecoinField) {
        // indicate touched field
        updatedTouchedFields[`etnStablecoin-${stablecoinField}`] = true;

        // implement field update
        updatedETNStablecoin = new ETNStablecoin({
          ...updatedETNStablecoin,
          [stablecoinField]: newValue,
        } as ETNStablecoin);
      }
      if (instrumentField) {
        // indicate touched field
        updatedTouchedFields[`etn-${instrumentField}`] = true;

        // implement field update
        updatedETN = new ETN({
          ...updatedETN,
          [instrumentField]: newValue,
        } as ETN);
      }

      // if an instrument field was given then perform necessary 'side effect' changes
      if (instrumentField) {
        // if the rating agency is changed then the credit rating should be cleared
        if (instrumentField === "issuerRatingAgency") {
          updatedETN.issuerCreditRating = "";
        }

        if (
          // if valuation token is set on the instrument stablecoin
          !updatedETNStablecoin.valuationToken.isUndefined() && // AND
          // any of the following fields are being updated on the instrument
          ["annualPerformanceLog", "issueDate", "maturityDate"].includes(
            instrumentField,
          ) && // AND
          // the set issue and maturity dates are valid
          dateIsValid(updatedETN.issueDate) &&
          dateIsValid(updatedETN.maturityDate)
        ) {
          // find selected valuation stablecoin
          const valuationCurrencyStablecoin =
            potentialValuationCurrencyStablecoins.find((c) =>
              c.token.isEqualTo(updatedETNStablecoin.valuationToken),
            );
          if (!valuationCurrencyStablecoin) {
            console.error(
              "could not find valuation currency stablecoin among those available",
            );
            return;
          }

          // find currency by which the stablecoin is backed
          const backingCurrency = valuationStablecoinUnderlyingCurrencies.find(
            (c) => valuationCurrencyStablecoin.currencyID === c.currencyID(),
          );
          if (!backingCurrency) {
            console.error("could not find backing currency");
            return;
          }

          // update relevant field on instrument
          switch (instrumentField) {
            case "issueDate":
              updatedETN.issueDate = backingCurrency.firstStartOfDayBefore(
                dayjs(updatedETN.issueDate).add(1, "minute").format(),
              );
              break;

            case "maturityDate":
              updatedETN.maturityDate = backingCurrency.firstCutOffAfter(
                dayjs(updatedETN.maturityDate).startOf("day").format(),
              );
              break;
          }
        }

        if (
          // if any of the following fields are being updated on the instrument
          ["annualPerformanceLog", "issueDate", "maturityDate"].includes(
            instrumentField,
          ) && // AND
          // the set issue and maturity dates are valid
          dateIsValid(updatedETN.issueDate) &&
          dateIsValid(updatedETN.maturityDate)
        ) {
          // then process annual performance log

          // get now
          const now = dayjs();

          // remove any years in the future

          updatedETN.annualPerformanceLog =
            updatedETN.annualPerformanceLog.filter(
              // by keeping only those entries with year <= this year
              (pl) => pl.year <= now.year(),
            );

          // remove any years prior to issue date
          updatedETN.annualPerformanceLog =
            updatedETN.annualPerformanceLog.filter(
              // by keep only those entries with year >= year in which instrument is issued
              (pl) => pl.year >= dayjs(updatedETN.issueDate).year(),
            );

          // of the entries which remain
          for (const i in updatedETN.annualPerformanceLog) {
            if (
              Object.prototype.hasOwnProperty.call(
                updatedETN.annualPerformanceLog,
                i,
              )
            ) {
              for (const month in updatedETN.annualPerformanceLog[i]
                .monthlyPerformance) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    updatedETN.annualPerformanceLog[i].monthlyPerformance,
                    month,
                  )
                ) {
                  // get first day of month in year
                  const firstDayOfMonthInYear = dayjs()
                    .year(updatedETN.annualPerformanceLog[i].year)
                    .month(+month - 1)
                    .date(1);

                  // remove any months in future
                  if (firstDayOfMonthInYear.isAfter(now, "month")) {
                    delete updatedETN.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }

                  // remove any months before issue date
                  if (
                    firstDayOfMonthInYear.isBefore(
                      updatedETN.issueDate,
                      "month",
                    )
                  ) {
                    delete updatedETN.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }
                }
              }
            }
          }
        }
      }

      // if a stablecoin field is given then perform necessary 'side effect' changes
      if (stablecoinField) {
        if (
          // if valuation token ID is set
          updatedETNStablecoin.valuationToken && // AND
          // the field being updated is one of the following
          ["issueDate", "maturityDate", "valuationToken"].includes(
            stablecoinField,
          ) && // AND
          // the set issue and maturity date fields are valid
          dateIsValid(updatedETNStablecoin.issueDate) &&
          dateIsValid(updatedETNStablecoin.maturityDate)
        ) {
          // find selected valuation stablecoin
          const valuationCurrencyStablecoin =
            potentialValuationCurrencyStablecoins.find((c) =>
              c.token.isEqualTo(updatedETNStablecoin.valuationToken),
            );
          if (!valuationCurrencyStablecoin) {
            console.error(
              "could not find valuation currency stablecoin among those available",
            );
            return;
          }

          // find currency by which the stablecoin is backed
          const backingCurrency = valuationStablecoinUnderlyingCurrencies.find(
            (c) => valuationCurrencyStablecoin.currencyID === c.currencyID(),
          );
          if (!backingCurrency) {
            console.error("could not find backing currency");
            return;
          }

          switch (stablecoinField) {
            case "issueDate":
              updatedETNStablecoin.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedETNStablecoin.issueDate)
                    .add(1, "minute")
                    .format(),
                );
              break;

            case "maturityDate":
              updatedETNStablecoin.maturityDate =
                backingCurrency.firstCutOffAfter(
                  dayjs(updatedETNStablecoin.maturityDate)
                    .startOf("day")
                    .format(),
                );
              break;

            case "valuationToken":
              // parse issue date as is
              updatedETNStablecoin.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedETNStablecoin.issueDate)
                    .add(1, "minute")
                    .format(),
                );
              updatedETNStablecoin.maturityDate =
                backingCurrency.firstCutOffAfter(
                  dayjs(updatedETNStablecoin.maturityDate)
                    .startOf("day")
                    .format(),
                );
              if (
                // if the set issue and maturity dates on the instrument are valid
                dateIsValid(updatedETN.issueDate) &&
                dateIsValid(updatedETN.maturityDate)
              ) {
                updatedETN.issueDate = backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedETN.issueDate).add(1, "minute").format(),
                );
                updatedETN.maturityDate = backingCurrency.firstCutOffAfter(
                  dayjs(updatedETN.maturityDate).startOf("day").format(),
                );
              }
              break;
          }
        }
      }

      // set updated touched fields
      setTouchedFields(updatedTouchedFields);

      // clear any pending validation
      clearTimeout(validationTimeoutRef.current);

      // defer validation to take place in 200ms
      setValidationInProgress(true);
      clearTimeout(validationTimeoutRef.current);
      validationTimeoutRef.current = setTimeout(() => {
        setStep1ValidationResult(
          validateRights2ETNStep1(
            updatedETN,
            updatedETNStablecoin,
            mint,
            updatedTouchedFields,
            false,
          ),
        );
        setStep2ValidationResult(
          validateRights2ETNStep2(
            updatedETN,
            updatedETNStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setStep3ValidationResult(
          validateRights2ETNStep3(
            updatedETN,
            updatedETNStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setSupportingDocsStepResult(
          validateRightsToETNSupportingDocuments(
            updatedETN,
            updatedETNStablecoin,
            updatedTouchedFields,
            false,
          ),
        );
        setValidationInProgress(false);
      }, 800);

      setETN(updatedETN);
      setETNStablecoin(updatedETNStablecoin);
    };

  // handleDiscreteETNStablecoinUpdates performs any discrete updates required on the ETNStablecoin.
  // e.g. maturity date change, holdings or allocations
  // The updated ETNStablecoin is returned.
  const handleDiscreteETNStablecoinUpdates: () => Promise<ETNStablecoin> =
    async () => {
      if (!unsavedETNStablecoinChangesExist) {
        // if there are no changes then do nothing
        return etnStablecoin;
      }

      let updatedETNStablecoin = etnStablecoin;

      if (
        !isEqual(etnStablecoin.maturityDate, copyOfETNStablecoin.maturityDate)
      ) {
        updatedETNStablecoin = (
          await ETNStablecoinUpdater.ChangeETNStablecoinMaturityDate({
            context: authContext,
            etnStablecoinID: etnStablecoin.id,
            maturityDate: etn.maturityDate,
          })
        ).etnStablecoin;
      }

      if (
        etnStablecoin.maximumUnits.value.gt(
          copyOfETNStablecoin.maximumUnits.value,
        )
      ) {
        updatedETNStablecoin = (
          await ETNStablecoinUpdater.IncreaseETNStablecoinMaximumUnits({
            context: authContext,
            etnStablecoinID: etnStablecoin.id,
            amount: etnStablecoin.maximumUnits.setValue(
              etnStablecoin.maximumUnits.value.minus(
                copyOfETNStablecoin.maximumUnits.value,
              ),
            ),
          })
        ).etnStablecoin;
      } else if (
        etnStablecoin.maximumUnits.value.lt(
          copyOfETNStablecoin.maximumUnits.value,
        )
      ) {
        updatedETNStablecoin = (
          await ETNStablecoinUpdater.DecreaseETNStablecoinMaximumUnits({
            context: authContext,
            etnStablecoinID: etnStablecoin.id,
            amount: etnStablecoin.maximumUnits.setValue(
              copyOfETNStablecoin.maximumUnits.value.minus(
                etnStablecoin.maximumUnits.value,
              ),
            ),
          })
        ).etnStablecoin;
      }

      return updatedETNStablecoin;
    };

  // handleDiscreteETNUpdates performs any discrete updates required on the ETN.
  // e.g. maturity date change, holdings or allocations
  // The updated ETN is returned.
  const handleDiscreteETNUpdates: () => Promise<ETN> = async () => {
    if (!unsavedETNChangesExist) {
      // if there are no changes then do nothing
      return etn;
    }

    let updatedETN = etn;

    if (!isEqual(etn.maturityDate, copyOfETN.maturityDate)) {
      updatedETN = (
        await ETNUpdater.ChangeETNMaturityDate({
          context: authContext,
          etnID: etn.id,
          maturityDate: etn.maturityDate,
        })
      ).etn;
    }

    if (!isEqual(etn.countryAllocations, copyOfETN.countryAllocations)) {
      updatedETN = (
        await ETNUpdater.ChangeETNCountryAllocations({
          context: authContext,
          etnID: etn.id,
          updatedCountryAllocations: etn.countryAllocations,
        })
      ).etn;
    }

    if (!isEqual(etn.holdings, copyOfETN.holdings)) {
      updatedETN = (
        await ETNUpdater.ChangeETNHoldings({
          context: authContext,
          etnID: etn.id,
          updatedHoldings: etn.holdings,
        })
      ).etn;
    }

    if (!isEqual(etn.sectorAllocations, copyOfETN.sectorAllocations)) {
      updatedETN = (
        await ETNUpdater.ChangeETNSectorAllocations({
          context: authContext,
          etnID: etn.id,
          updatedSectorAllocations: etn.sectorAllocations,
        })
      ).etn;
    }

    if (!isEqual(etn.annualPerformanceLog, copyOfETN.annualPerformanceLog)) {
      updatedETN = (
        await ETNUpdater.ChangeETNAnnualPerformanceLog({
          context: authContext,
          etnID: etn.id,
          updatedAnnualPerformanceLog: etn.annualPerformanceLog,
        })
      ).etn;
    }

    if (!isEqual(etn.supportingDocuments, copyOfETN.supportingDocuments)) {
      updatedETN = (
        await ETNUpdater.ChangeETNSupportingDocuments({
          context: authContext,
          etnID: etn.id,
          updatedSupportingDocuments: etn.supportingDocuments,
        })
      ).etn;
    }

    return updatedETN;
  };

  // handlePerformRequiredUpdates performs any updates required on either the etn or etn stablecoin
  // i.e. either creation, draft update or discrete update
  // Both updated entities are returned.
  const handlePerformRequiredUpdates: () => Promise<{
    updatedETN: ETN;
    updatedETNStablecoin: ETNStablecoin;
  }> = async () => {
    if (creatingNew) {
      // if creation is in progress then create the new etn
      const { etn: createdETN, etnStablecoin: createdETNStablecoin } =
        await ETNStablecoinCreatorCoordinator.CoordinateCreateNewETNStablecoin({
          context: authContext,
          ownerID: etn.ownerID,
          etnFields: {
            name: etn.name,
            isin: etn.isin,
            exchange: etn.exchange,
            exchangeCode: etn.exchangeCode,
            assetClass: etn.assetClass,
            issueDate: etn.issueDate,
            maturityDate: etn.maturityDate,
            issuerName: etn.issuerName,
            issuerRatingAgency: etn.issuerRatingAgency,
            issuerCreditRating: etn.issuerCreditRating,
            issuerManagementFee: etn.issuerManagementFee,
            issuerManagementFeeFrequency: etn.issuerManagementFeeFrequency,
            supportingDocuments: etn.supportingDocuments,
            investorProfile: etn.investorProfile,
            investorProfileDescription: etn.investorProfileDescription,
            riskProfile: etn.riskProfile,
            riskProfileDescription: etn.riskProfileDescription,
            holdings: etn.holdings,
            sectorAllocations: etn.sectorAllocations,
            countryAllocations: etn.countryAllocations,
            annualPerformanceLog: etn.annualPerformanceLog,
          },
          stablecoinFields: {
            issueDate: etnStablecoin.issueDate,
            maturityDate: etnStablecoin.maturityDate,
            maximumUnits: etnStablecoin.maximumUnits,
            valuationToken: etnStablecoin.valuationToken,
          },
        });

      return {
        updatedETN: createdETN,
        updatedETNStablecoin: createdETNStablecoin,
      };
    }
    // If execution reaches here then both the etn and etnStablecoin already exist.
    // Following - both of them are updated as required.

    if (etn.state === ETNState.Draft) {
      // if the instrument is in draft then perform draft updates
      return {
        updatedETN: unsavedETNChangesExist
          ? (
              await ETNUpdater.DraftUpdateETN({
                context: authContext,
                etn,
              })
            ).etn
          : etn,
        updatedETNStablecoin: unsavedETNStablecoinChangesExist
          ? (
              await ETNStablecoinUpdater.DraftUpdateETNStablecoin({
                context: authContext,
                etnStablecoin,
              })
            ).etnStablecoin
          : etnStablecoin,
      };
    }
    // otherwise perform discrete updates
    return {
      updatedETN: await handleDiscreteETNUpdates(),
      updatedETNStablecoin: await handleDiscreteETNStablecoinUpdates(),
    };
  };

  // Document Uploading
  const dropZoneProps = useDropzone({
    // allowed document types
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
      "application/pdf": [],
    },

    // document drop validator
    validator: (f: File) => {
      if (!allowedDocumentTypes.includes(f.type)) {
        enqueueSnackbar("File Type Not Supported", { variant: "warning" });
        return [
          {
            message: "Unsupported File Type",
            code: "unsupported-file-type",
          },
        ];
      }

      if (f.size > 20 * 1000 * 1000) {
        enqueueSnackbar("File is Too Large For Upload", { variant: "warning" });
        return [
          {
            message: "Max of 20MB allowed",
            code: "file-too-large",
          },
        ];
      }

      return null;
    },

    // document drop function
    onDrop: async (acceptedFiles: File[]) => {
      // for every file that has been dropped...
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          // request to upload a document against the instrument
          const response =
            await InstrumentDocumentController.RequestDocumentUploadForInstrument(
              {
                context: authContext,
              },
            );

          // add a pending document upload
          setPendingDocumentUploads((prev) => [
            ...prev,
            {
              document: new Document({
                id: response.documentID,
                name: file.name,
                mimeType: file.type,
                description: "",
              }),
              file,
              complete: false,
              percent: 0,
            },
          ]);

          // perform upload within an awaited promise to allow asynchronous UI updates
          await (() =>
            new Promise((resolve, reject) => {
              file.arrayBuffer().then((arrayBuffer) => {
                doUpload({
                  url: response.uploadURL,
                  documentData: arrayBuffer,
                  documentType: file.type,
                  onProgress: (p: number) =>
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].percent = p;
                      return [...prev];
                    }),
                  onComplete: () => {
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].complete = true;
                      return [...prev];
                    });
                    resolve(true);
                  },
                  onError: reject,
                });
              });
            }))();
        }),
      );

      // finally update supporting documents and clear the pending document uploads
      setPendingDocumentUploads((prev) => {
        // update supporting documents
        handleChangeEntitiesInDialog(
          undefined,
          "supportingDocuments",
        )([...prev.map((pd) => pd.document), ...etn.supportingDocuments]);

        return [];
      });
    },
  });

  // Determine Dialog Actions
  const getAvailableActions = () => {
    // if the issue date of either the etnStablecoin or etn are invalid
    if (!(dateIsValid(etnStablecoin.issueDate) && dateIsValid(etn.issueDate))) {
      // then return with no actions
      return [];
    }

    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // for every potential action available at the ETNStablecoin's state
    // consider what action buttons to add to list
    for (const action of potentialNextActionsForETNStablecoin) {
      switch (action) {
        case ETNStablecoinAction.PreIssue: // PreIssue is a possible next action AND...
          if (etnStablecoinActionsViewConfig.ChangeState) {
            if (dayjs(etnStablecoin.issueDate).isAfter(dayjs())) {
              // ...The etn stablecoin issue date is in the future.
              // Add a button for Pre-Issue.
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        id="rightsToETNDialog-preIssue-button"
                        // disable unless both steps are complete
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading
                        }
                        variant="contained"
                        children="Pre-Issue"
                        color="primary"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Pre-Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: true,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare stablecoin ID for post api actions
                                let etnStablecoinID = "";

                                // perform required updates and pre-issuance with consistency
                                // perform any required updates
                                const result =
                                  await handlePerformRequiredUpdates();
                                etnStablecoinID =
                                  result.updatedETNStablecoin.id;

                                // pre-issue etn stablecoin
                                await ETNStablecoinStateChangerCoordinator.CoordinatePreIssueETNStablecoin(
                                  {
                                    context: authContext,
                                    issuanceAccountID:
                                      issuanceAccountStub.accountID,
                                    etnStablecoinID,
                                  },
                                );

                                enqueueSnackbar(
                                  "Rights to Instrument Pre-Issued",
                                  { variant: "success" },
                                );
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${etnStablecoinID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrETNNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftETNNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      "etn-name": "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${etn.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  console.error(
                                    `error pre-issuing rights to instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(err);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case ETNStablecoinAction.Issue: // Issue is a possible next action AND...
          if (etnStablecoinActionsViewConfig.ChangeState) {
            if (dayjs(etnStablecoin.issueDate).isBefore(dayjs())) {
              // ...The etn stablecoin issue date is in the past.
              // Add a button for Issue.
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        id="rightsToETNDialog-issue-button"
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading ||
                          !!pendingDocumentUploads.length
                        }
                        variant="contained"
                        children="issue"
                        color="primary"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: false,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare stablecoin ID for post api actions
                                let etnStablecoinID = "";

                                // perform required updates and issue
                                const result =
                                  await handlePerformRequiredUpdates();
                                etnStablecoinID =
                                  result.updatedETNStablecoin.id;

                                // issue etn stablecoin
                                await ETNStablecoinStateChangerCoordinator.CoordinateIssueETNStablecoin(
                                  {
                                    context: authContext,
                                    issuanceAccountID:
                                      issuanceAccountStub.accountID,
                                    etnStablecoinID,
                                  },
                                );

                                enqueueSnackbar("Rights to Instrument Issued", {
                                  variant: "success",
                                });
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${etnStablecoinID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrETNNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftETNNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      "etn-name": "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${etn.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  const err =
                                    errorContextErrorTranslator.translateError(
                                      e,
                                    );
                                  console.error(
                                    `error issuing rights to instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(e);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case ETNStablecoinAction.MarkDeleted:
          if (etnStablecoinActionsViewConfig.ChangeState) {
            if (!creatingNew) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="rightsToETNDialog-deleteDraftETN-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              try {
                                await ETNStablecoinStateChangerCoordinator.CoordinateMarkETNStablecoinDeleted(
                                  {
                                    context: authContext,
                                    etnStablecoinID: etnStablecoin.id,
                                  },
                                );
                                enqueueSnackbar(
                                  "Rights to Instrument Deleted",
                                  { variant: "success" },
                                );
                                navigate(InstrumentsViewPaths.Table);
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting rights to instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                enqueueSnackbar(
                                  "Error Deleting Rights to Instrument",
                                  { variant: "error" },
                                );
                              }

                              setAPILoading(false);
                            },
                            // user cancels deletion
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Rights to Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of rights to instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;
      }
    }

    // if etn stablecoin can be minted
    if (mint) {
      // and the executing user has the Mint view configuration
      // and view mode is on
      if (etnStablecoinActionsViewConfig.Mint && viewModeOn) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              id="etnDialog-mint-button"
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // check if the place button should be shown
    if (
      marketAssetViewConfig.List &&
      LedgerAssetReadyToList(etnStablecoin) &&
      !instrumentIsPlaced.current
    ) {
      prioritisedButtonActions.push({
        node: (
          <Button
            variant="contained"
            color="primary"
            id="rightsToETNDialog-place-button"
            children="place"
            disabled={apiLoading || validationInProgress || !allStepsComplete}
            onClick={async () => {
              if (unsavedChangesExist) {
                // if there are any unsaved changes save them before going to the placement screen
                setAPILoading(true);
                try {
                  await handlePerformRequiredUpdates();
                  setAPILoading(false);
                  return;
                } catch (e) {
                  const err = errorContextErrorTranslator.translateError(e);
                  console.error(
                    `error saving rights to instrument: ${
                      err.message ? err.message : err.toString()
                    }`,
                  );
                  enqueueSnackbar("Error Saving Rights to Instrument", {
                    variant: "error",
                  });
                  setAPILoading(false);
                  return;
                }
              }

              // go to placement screen
              navigate(
                `${InstrumentsViewPaths.PlaceInstrumentStablecoin}?&id=${etnStablecoin.id}`,
              );
            }}
          />
        ),
        priority: 1,
      });
    }

    //
    // Update Actions
    //

    // if user has update view config set
    if (etnActionsViewConfig.Update) {
      if (viewModeOn) {
        // and if view mode is on (i.e. not editing)
        // then consider whether or not to show the Edit Button
        // (i.e. the button that turns edit mode on)

        if (editIsANextAction) {
          prioritisedIconActions.push({
            node: (
              <Tooltip title="Edit" placement="top">
                <span>
                  <IconButton
                    id="rightsToETNDialog-turnEditModeOn-button"
                    size="small"
                    disabled={apiLoading}
                    onClick={() => {
                      setViewModeOn(false);
                      navigate(
                        `${InstrumentsViewPaths.RightsToETN}?&id=${etnStablecoin.id}&edit=true`,
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ),
            priority: 2,
          });
        }
      } else {
        // otherwise view mode is off (i.e. editing)
        // show save button at all times
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              id="rightsToETNDialog-save-button"
              disabled={
                invalidDatesInForm ||
                validationInProgress ||
                apiLoading ||
                !!pendingDocumentUploads.length ||
                // if not in a draft state
                (etn.state !== ETNState.Draft &&
                  // then button is disabled unless all steps are complete
                  !allStepsComplete)
              }
              onClick={async () => {
                setAPILoading(true);

                // if unsaved changes exist then perform any required updates
                let updatedETNStablecoin = etnStablecoin;
                let updatedETN = etn;
                if (creatingNew || unsavedChangesExist) {
                  try {
                    const result = await handlePerformRequiredUpdates();
                    updatedETNStablecoin = result.updatedETNStablecoin;
                    updatedETN = result.updatedETN;
                    enqueueSnackbar("Rights to Instrument Saved", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    if (
                      err.code === ErrETNNameAlreadyInUseErrorCode ||
                      err.code === ErrDraftETNNameAlreadyInUseErrorCode
                    ) {
                      setStep2ValidationResult({
                        stepComplete: false,
                        fieldValidations: {
                          ...step2ValidationResult.fieldValidations,
                          "etn-name": "Name is already in use",
                        },
                      });
                      enqueueSnackbar(
                        `Instrument Name '${etn.name}' is Already in Use`,
                        { variant: "warning" },
                      );
                    } else {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error saving rights to instrument: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      errorContextDefaultErrorFeedback(e);
                    }
                    setAPILoading(false);
                    return;
                  }
                }

                // clear field validations
                setStep1ValidationResult({
                  ...step1ValidationResult,
                  fieldValidations: {},
                });
                setStep2ValidationResult({
                  ...step2ValidationResult,
                  fieldValidations: {},
                });
                setStep3ValidationResult({
                  ...step3ValidationResult,
                  fieldValidations: {},
                });
                setSupportingDocsStepResult({
                  ...supportingDocsStepValidationResult,
                  fieldValidations: {},
                });

                // turn view mode on
                setViewModeOn(true);
                navigate(
                  `${InstrumentsViewPaths.RightsToETN}?&id=${updatedETNStablecoin.id}`,
                );

                // update in dialog components
                setETN(new ETN(updatedETN));
                setCopyOfETN(new ETN(updatedETN));
                setETNStablecoin(new ETNStablecoin(updatedETNStablecoin));
                setCopyOfETNStablecoin(new ETNStablecoin(updatedETNStablecoin));

                // stop api loading
                setAPILoading(false);
              }}
              children="save"
            />
          ),
          priority: 2,
        });
      }
    }

    return [
      // ensure icon actions always render after button actions
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  // ETN Field View Mode Determination
  const etnDraftUpdateOnlyFieldViewModeOn =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![ETNAction.DraftUpdate].some((a) =>
      potentialNextActionsForETN.includes(a),
    );

  const etnMaturityDateReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![ETNAction.DraftUpdate, ETNAction.ChangeMaturityDate].some((a) =>
      potentialNextActionsForETN.includes(a),
    );

  const etnHoldingsAndAllocationsSectionReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      ETNAction.DraftUpdate,
      ETNAction.ChangeHoldings,
      ETNAction.ChangeCountryAllocations,
      ETNAction.ChangeSectorAllocations,
    ].some((a) => potentialNextActionsForETN.includes(a));

  const etnAnnualPerformanceLogReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![ETNAction.DraftUpdate, ETNAction.ChangeAnnualPerformanceLog].some((a) =>
      potentialNextActionsForETN.includes(a),
    );

  const etnSupportingDocumentsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![ETNAction.DraftUpdate, ETNAction.ChangeSupportingDocuments].some((a) =>
      potentialNextActionsForETN.includes(a),
    );

  // ETN Stablecoin Field View Mode Determination
  const etnStablecoinDraftUpdateOnlyFieldViewModeOn =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![ETNStablecoinAction.DraftUpdate].some((a) =>
      potentialNextActionsForETNStablecoin.includes(a),
    );

  const etnStablecoinMaximumUnitsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      ETNStablecoinAction.DraftUpdate,
      ETNStablecoinAction.IncreaseMaximumUnits,
      ETNStablecoinAction.DecreaseMaximumUnits,
    ].some((a) => potentialNextActionsForETNStablecoin.includes(a));

  const etnStablecoinMaturityDateReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !etnStablecoinActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      ETNStablecoinAction.DraftUpdate,
      ETNStablecoinAction.ChangeMaturityDate,
    ].some((a) => potentialNextActionsForETNStablecoin.includes(a));

  return (
    <>
      <StyledDialog open fullScreen>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item className={classes.heading}>
              <div className={classes.miniLogoWrapper}>
                <img alt="" width="100%" src={meshMiniLogo} />
              </div>
              <Typography
                variant="h5"
                children={
                  etn.state
                    ? etnStablecoin.state === ETNStablecoinState.Draft
                      ? "Instrument Builder"
                      : etn.name
                    : "Instrument Builder"
                }
              />
            </Grid>
            {!creatingNew && (
              <>
                <Grid item>
                  <InstrumentStateChip state={etnStablecoin.state} />
                </Grid>
                {LedgerAssetReadyToList(etnStablecoin) ? (
                  <Grid item>
                    <PlacementStateWordsChip
                      placed={instrumentIsPlaced.current}
                    />
                  </Grid>
                ) : null}
              </>
            )}
            {apiLoading && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {getAvailableActions().map((a, idx) => (
              <Grid item key={idx}>
                {a}
              </Grid>
            ))}
            <Grid item>
              <Tooltip title="Close" placement="top">
                <span>
                  <IconButton
                    size="small"
                    id="rightsToETNDialog-close-button"
                    disabled={apiLoading}
                    onClick={() => {
                      // if unsaved changes exist
                      if (unsavedChangesExist) {
                        // show warning dialog
                        setWarningDialogOptions({
                          yesMethod: async () => {
                            // if the 'Yes' button is pressed on dialog
                            setAPILoading(true);

                            try {
                              await handlePerformRequiredUpdates();
                              enqueueSnackbar("Rights to Instrument Saved", {
                                variant: "success",
                              });
                              navigate(InstrumentsViewPaths.Table);
                              return;
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              if (
                                err.code === ErrETNNameAlreadyInUseErrorCode ||
                                err.code ===
                                  ErrDraftETNNameAlreadyInUseErrorCode
                              ) {
                                setStep2ValidationResult({
                                  stepComplete: false,
                                  fieldValidations: {
                                    ...step2ValidationResult.fieldValidations,
                                    "etn-name": "Name is already in use",
                                  },
                                });
                                enqueueSnackbar(
                                  `Instrument Name '${etn.name}' is Already in Use`,
                                  { variant: "error" },
                                );
                              } else {
                                console.error(
                                  `error saving rights to instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                errorContextDefaultErrorFeedback(err);
                              }
                            }

                            setAPILoading(false);
                          },
                          noMethod: async () => {
                            // if the 'No' button is pressed on the warning dialog
                            // just go straight back to instruments table without
                            // showing warning dialog
                            navigate(InstrumentsViewPaths.Table);
                          },
                          title: "Save Rights to Instrument?",
                          messageParagraphs: [
                            `
                        You have not saved the changes that you have made.
                        Select 'Yes' to save the changes made to the
                        Rights to an ETN you have been working on in it's
                        current state and return to the instrument dashboard.
                        Select 'No' to proceed without saving.
                        `,
                          ],
                        });
                      } else {
                        // no unsaved changes exist -
                        // just go straight back to instruments table
                        navigate(InstrumentsViewPaths.Table);
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          classes={{ root: cx(classes.dialogContent, "meshScroll") }}
        >
          <div className={classes.rootLeftColumn}>
            {/* ----- Token Class Section ----------------------------- */}
            <Grid
              className={classes.tokenClassSelectionLayout}
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              {(() => [
                creatingNew ? (
                  <TextField
                    label="Token Class"
                    id="rightsToETNDialog-tokenClass-textField"
                    className={classes.fieldWidth}
                    disabled={apiLoading}
                    value={TokenClass.RightsToInstrument}
                    select
                    onChange={(e) => {
                      switch (e.target.value) {
                        case TokenClass.RightsToInstrument:
                          // do nothing, already here
                          break;

                        case TokenClass.NewInstrument:
                          // if unsaved changes exist
                          if (unsavedChangesExist) {
                            // then show a warning dialog
                            setWarningDialogOptions({
                              yesMethod: async () => {
                                // if the 'Yes' button is pushed on the warning dialog
                                setAPILoading(true);

                                try {
                                  // then save any existing changes
                                  await handlePerformRequiredUpdates();
                                  enqueueSnackbar(
                                    "Rights to Instrument Saved",
                                    { variant: "success" },
                                  );
                                  // and go to new DigitalETN creation
                                  navigate(InstrumentsViewPaths.DigitalETN);
                                  return;
                                } catch (e) {
                                  const err =
                                    errorContextErrorTranslator.translateError(
                                      e,
                                    );
                                  if (
                                    err.code ===
                                      ErrETNNameAlreadyInUseErrorCode ||
                                    err.code ===
                                      ErrDraftETNNameAlreadyInUseErrorCode
                                  ) {
                                    setStep2ValidationResult({
                                      stepComplete: false,
                                      fieldValidations: {
                                        ...step2ValidationResult.fieldValidations,
                                        "etn-name": "Name is already in use",
                                      },
                                    });
                                    enqueueSnackbar(
                                      `Instrument Name '${etn.name}' is Already in Use`,
                                      { variant: "warning" },
                                    );
                                  } else {
                                    const err =
                                      errorContextErrorTranslator.translateError(
                                        e,
                                      );
                                    console.error(
                                      `error saving rights to instrument: ${
                                        err.message
                                          ? err.message
                                          : err.toString()
                                      }`,
                                    );
                                    errorContextDefaultErrorFeedback(err);
                                  }
                                }

                                setAPILoading(false);
                              },
                              noMethod: async () => {
                                // if the 'No' button on the warning dialog is pushed
                                // then do nothing and go straight to new DigitalETN creation
                                navigate(InstrumentsViewPaths.DigitalETN);
                              },
                              title: "Save Rights to Instrument?",
                              messageParagraphs: [
                                `
                              Changing the token class requires that you create a new instrument.
                              Select 'Yes' to save your Current Rights to an ETN and start a new ETN.
                              Alternatively, select 'No' to proceed without saving.
                              `,
                              ],
                            });
                          } else {
                            // no unsaved changes exist - go straight to new digitalETN creation
                            // without showing warning dialog
                            navigate(InstrumentsViewPaths.DigitalETN);
                          }
                          break;
                      }
                    }}
                  >
                    <MenuItem value={TokenClass.NewInstrument}>
                      {TokenClass.NewInstrument}
                    </MenuItem>
                    <MenuItem value={TokenClass.RightsToInstrument}>
                      {TokenClass.RightsToInstrument}
                    </MenuItem>
                  </TextField>
                ) : viewModeOn ? (
                  <TextField
                    id="rightsToETNDialog-tokenClass-textField"
                    className={classes.fieldWidth}
                    label="Token Class"
                    value="Rights To An Instrument"
                    readOnly
                  />
                ) : (
                  <Tooltip
                    title="Cannot Change After Instrument has Been Created"
                    placement="top-start"
                  >
                    <span>
                      <TextField
                        id="rightsToETNDialog-tokenClass-textField"
                        className={classes.fieldWidth}
                        label="Token Class"
                        value="Rights To An Instrument"
                        readOnly
                        disabled
                      />
                    </span>
                  </Tooltip>
                ),
                <TextField
                  label="Issuer"
                  className={classes.fieldWidth}
                  value={new Client(myClient).name}
                  readOnly
                />,
              ])().map((i, idx) => (
                <Grid key={idx} item>
                  {i}
                </Grid>
              ))}
            </Grid>

            {/* ----- Accordion Section ----------------------------- */}
            <div>
              {/*
              // -------------------------------------------------
              // Step 1
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step1
                    ? accordionSectionsOpen.step1
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step1: !accordionSectionsOpen.step1,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToETNDialog-step1-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToETNDialog-step1-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  classes={{
                    root: classes.stepsCommonAccordionSummaryRootOverride,
                  }}
                >
                  <div
                    className={classes.stepsCommonAccordionSummaryContentLayout}
                  >
                    <Typography variant="h6">Rights to Instrument</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 1
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step1ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step1ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      etnActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToETNDialogStep1-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToETN}?&id=${etnStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.stepsCommonAccordionDetailsLayout}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.stepsCommonGuidanceText}
                  >
                    Enter defining details of the new rights to an instrument
                    that you are building. This will allow you to trade rights
                    to an existing instrument on Mesh.
                  </Typography>

                  <div className={classes.step1InstrumentDetailsFieldsSection}>
                    {/* Row 1 */}
                    {creatingNew ? (
                      <TextField
                        label="Instrument Type"
                        id="rightsToETNDialog-instrumentType-textField"
                        className={classes.fieldWidth}
                        disabled={apiLoading}
                        value="ETN"
                        select
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "ETN":
                              // do nothing, already here
                              break;

                            case "ETF":
                            case "Unit Trust":
                              // if unsaved changes exist
                              if (unsavedChangesExist) {
                                // then show a warning dialog
                                setWarningDialogOptions({
                                  yesMethod: async () => {
                                    // if the 'Yes' button is pressed on the warning dialog
                                    setAPILoading(true);

                                    try {
                                      // then save any existing changes
                                      await handlePerformRequiredUpdates();
                                      enqueueSnackbar(
                                        "Rights to Instrument Saved",
                                        { variant: "success" },
                                      );
                                      // and go to rights to ETF/Unit Trust creation
                                      navigate(
                                        e.target.value === "ETF"
                                          ? InstrumentsViewPaths.RightsToETF
                                          : InstrumentsViewPaths.RightsToUnitTrust,
                                      );
                                      return;
                                    } catch (e) {
                                      const err =
                                        errorContextErrorTranslator.translateError(
                                          e,
                                        );
                                      if (
                                        err.code ===
                                          ErrETNNameAlreadyInUseErrorCode ||
                                        err.code ===
                                          ErrDraftETNNameAlreadyInUseErrorCode
                                      ) {
                                        setStep2ValidationResult({
                                          stepComplete: false,
                                          fieldValidations: {
                                            ...step2ValidationResult.fieldValidations,
                                            "etn-name":
                                              "Name is already in use",
                                          },
                                        });
                                        enqueueSnackbar(
                                          `Instrument Name '${etn.name}' is Already in Use`,
                                          { variant: "warning" },
                                        );
                                      } else {
                                        console.error(
                                          `error saving rights to instrument: ${
                                            err.message
                                              ? err.message
                                              : err.toString()
                                          }`,
                                        );
                                        errorContextDefaultErrorFeedback(err);
                                      }
                                    }

                                    setAPILoading(false);
                                  },
                                  noMethod: async () => {
                                    // if the 'No' button is pressed on the warning dialog
                                    // go straight to rights to ETF/Unit Trust creation
                                    navigate(
                                      e.target.value === "ETF"
                                        ? InstrumentsViewPaths.RightsToETF
                                        : InstrumentsViewPaths.RightsToUnitTrust,
                                    );
                                  },
                                  title: "Save Rights to Instrument?",
                                  messageParagraphs: [
                                    `
                              Changing the instrument type requires that a new instrument be created.
                              Select 'Yes' to save rights to an ETN you are working on in it's current
                              state and proceed to the creation of new rights to an ${e.target.value}.
                              `,
                                  ],
                                });
                              } else {
                                // no unsaved changes - go straight to rights to ETF/Unit Trust creation
                                // without showing a warning dialog
                                navigate(
                                  e.target.value === "ETF"
                                    ? InstrumentsViewPaths.RightsToETF
                                    : InstrumentsViewPaths.RightsToUnitTrust,
                                );
                              }
                              break;
                          }
                        }}
                      >
                        <MenuItem
                          id="etnDialog-instrumentTypeETF-menuItem"
                          value="ETF"
                        >
                          ETF
                        </MenuItem>
                        <MenuItem
                          id="etnDialog-instrumentTypeETN-menuItem"
                          value="ETN"
                        >
                          ETN
                        </MenuItem>
                        <MenuItem
                          id="etnDialog-instrumentTypeUnitTrust-menuItem"
                          value="Unit Trust"
                        >
                          Unit Trust
                        </MenuItem>
                      </TextField>
                    ) : viewModeOn ? (
                      <TextField
                        id="rightsToETNDialog-type-textField"
                        className={classes.fieldWidth}
                        label="Instrument Type"
                        value="ETN"
                        readOnly
                      />
                    ) : (
                      <Tooltip
                        title="Cannot Change After Instrument has Been Created"
                        placement="top-start"
                      >
                        <span>
                          <TextField
                            id="rightsToETNDialog-type-textField"
                            className={classes.fieldWidth}
                            label="Instrument Type"
                            value="ETN"
                            readOnly
                            disabled
                          />
                        </span>
                      </Tooltip>
                    )}
                    <DateField
                      label="Issue Date"
                      className={classes.fieldWidth}
                      disabled={apiLoading}
                      readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog("issueDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      value={etnStablecoin.issueDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="rightsToETNDialog-stablecoinIssueDate-dateField"
                          fullWidth
                          disabled={apiLoading}
                          readOnly={etnStablecoinDraftUpdateOnlyFieldViewModeOn}
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "etnStablecoin-issueDate"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "etnStablecoin-issueDate"
                            ]
                          }
                        />
                      )}
                    />
                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToETNDialog-valuationTokenTokenID-textField"
                        className={classes.fieldWidth}
                        label="Valuation Stablecoin"
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(etnStablecoin.valuationToken),
                            );
                          return model
                            ? `${model.token.code} - ${model.issuer}`
                            : "-";
                        })()}
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToETNDialog-valuationTokenTokenID-autoComplete"
                        className={classes.fieldWidth}
                        disabled={
                          apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                        }
                        getOptionLabel={(
                          option: FinancialCurrencyStablecoinViewModel,
                        ) => `${option.token.code} - ${option.issuer}`}
                        options={potentialValuationCurrencyStablecoins}
                        loading={apiLoading}
                        onChange={(
                          a,
                          selected: FinancialCurrencyStablecoinViewModel | null,
                        ) => {
                          if (selected) {
                            // something selected - set valuation token ID
                            handleChangeEntitiesInDialog("valuationToken")(
                              new Token(selected.token),
                            );
                          } else {
                            // nothing selected - clear valuation token ID
                            handleChangeEntitiesInDialog("valuationToken")(
                              new Token(),
                            );
                          }
                        }}
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(etnStablecoin.valuationToken),
                            );
                          return model || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={apiLoading}
                            readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                            id="rightsToETNDialog-valuationTokenTokenID-autoCompleteTextField"
                            label="Valuation Stablecoin"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: etnDraftUpdateOnlyFieldViewModeOn
                                ? etnStablecoin.valuationToken.isUndefined()
                                  ? etnStablecoin.valuationToken.string()
                                  : "-"
                                : "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "etnStablecoin-valuationToken"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "etnStablecoin-valuationToken"
                              ]
                            }
                          />
                        )}
                      />
                    )}

                    {/* Row 2 */}
                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        id="rightsToETNDialog-etnStablecoinOwnerID-textField"
                        className={classes.fieldWidth}
                        label="Owner Group"
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === etn.ownerID,
                          );
                          return group ? group.name : "-";
                        })()}
                        readOnly
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToETNDialog-etnStablecoinOwnerID-autoComplete"
                        className={classes.fieldWidth}
                        disabled={
                          apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                        }
                        getOptionLabel={(option: Group) => option.name}
                        options={potentialGroupOwners}
                        loading={apiLoading}
                        onChange={(a, selected: Group | null) =>
                          handleChangeEntitiesInDialog(
                            "ownerID",
                            " ownerID",
                          )(selected ? selected.id : "")
                        }
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === etn.ownerID,
                          );
                          return group || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToETNDialog-etnStablecoinOwnerID-autoCompleteTextField"
                            label="Owner Group"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            disabled={apiLoading}
                            readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                            helperText={
                              step1ValidationResult.fieldValidations[
                                "etnStablecoin-ownerID"
                              ]
                            }
                            error={
                              !!step1ValidationResult.fieldValidations[
                                "etnStablecoin-ownerID"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    <DateField
                      label="Maturity Date"
                      className={classes.fieldWidth}
                      disabled={apiLoading}
                      readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                      onChange={(newDate) =>
                        handleChangeEntitiesInDialog("maturityDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      value={etnStablecoin.maturityDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="rightsToETNDialog-etnStablecoinMaturityDate-dateField"
                          fullWidth
                          disabled={apiLoading}
                          readOnly={etnStablecoinMaturityDateReadOnly}
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "etnStablecoin-maturityDate"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "etnStablecoin-maturityDate"
                            ]
                          }
                        />
                      )}
                    />
                    {viewModeOn ? (
                      <div
                        className={
                          classes.step1InstrumentDetailsMaxUnitsInIssueLayout
                        }
                      >
                        <TextNumField
                          label="Max Units To Issue"
                          id="rightsToETNDialog-etnStablecoinMaximumUnits-textNumField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly
                          placeholder="0.00"
                          value={etnStablecoin.maximumUnits.value}
                          helperText={
                            step1ValidationResult.fieldValidations[
                              "etnStablecoin-maximumUnits"
                            ]
                          }
                          error={
                            !!step1ValidationResult.fieldValidations[
                              "etnStablecoin-maximumUnits"
                            ]
                          }
                        />
                        <InputAdornment
                          position="start"
                          children={
                            <Tooltip
                              placement="top"
                              title="Maximum number of tokens the issuer is looking to mint on Mesh"
                            >
                              <InfoIcon
                                className={cx(
                                  classes.infoIcon,
                                  classes.step1InstrumentDetailsMaxUnitsInIssueInfoIcon,
                                )}
                              />
                            </Tooltip>
                          }
                        />
                      </div>
                    ) : (
                      <TextNumField
                        label="Max Units To Issue"
                        id="rightsToETNDialog-etnStablecoinMaximumUnits-textNumField"
                        className={classes.fieldWidth}
                        disabled={apiLoading}
                        readOnly={etnStablecoinMaximumUnitsReadOnly}
                        placeholder="0.00"
                        value={etnStablecoin.maximumUnits.value}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) =>
                          handleChangeEntitiesInDialog("maximumUnits")(
                            etnStablecoin.maximumUnits.setValue(e.target.value),
                          )
                        }
                        disallowNegative
                        helperText={
                          step1ValidationResult.fieldValidations[
                            "etnStablecoin-maximumUnits"
                          ]
                        }
                        error={
                          !!step1ValidationResult.fieldValidations[
                            "etnStablecoin-maximumUnits"
                          ]
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              children={
                                <Tooltip
                                  placement="top"
                                  title="Maximum number of tokens the issuer is looking to mint on Mesh"
                                >
                                  <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                              }
                            />
                          ),
                        }}
                      />
                    )}
                  </div>

                  {viewModeOn ? (
                    <div
                      className={classes.step1InstrumentDetailsFieldsSection}
                    >
                      <TextField
                        label="Instrument Name"
                        id="rightsToETNDialog-etnStablecoinInstrumentName-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={etn.name ? etn.name : "-"}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        label="Asset Class"
                        id="rightsToETNDialog-etnStablecoinAssetClass-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={etn.assetClass}
                        InputLabelProps={{ shrink: true }}
                      />
                      {mint &&
                        ![
                          "",
                          ETNStablecoinState.Draft,
                          ETNStablecoinState.PreIssued,
                        ].includes(etnStablecoin.state) && (
                          <TextNumField
                            label="Issued Units"
                            id="rightsToETNDialog-unitsInIssue-textNumFieldReadOnly"
                            className={classes.fieldWidth}
                            disabled={apiLoading}
                            readOnly
                            InputLabelProps={{ shrink: true }}
                            value={mint.issuedUnits.value}
                          />
                        )}
                    </div>
                  ) : (
                    <div
                      className={classes.step1InstrumentDetailsFieldsSection}
                    >
                      <div />
                      <div />
                      {mint && etn.state !== ETNState.Draft && (
                        <TextNumField
                          label="Issued Units"
                          id="rightsToETNDialog-unitsInIssue-textNumFieldReadOnly"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly
                          InputLabelProps={{ shrink: true }}
                          value={mint.issuedUnits.value}
                        />
                      )}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Step 2
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step2
                    ? accordionSectionsOpen.step2
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step2: !accordionSectionsOpen.step2,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToETNDialog-step2-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToETNDialog-step2-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  classes={{
                    root: classes.stepsCommonAccordionSummaryRootOverride,
                  }}
                >
                  <div
                    className={classes.stepsCommonAccordionSummaryContentLayout}
                  >
                    <Typography variant="h6">Reference Instrument</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 2
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step2ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step2ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      etnActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToETNDialogStep2-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToETN}?&id=${etnStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.stepsCommonAccordionDetailsLayout}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.stepsCommonGuidanceText}
                  >
                    Enter details of the existing instrument created outside of
                    Mesh. This instrument will be seen as the underlying
                    instrument of the one which you are currently building.
                  </Typography>

                  <div className={classes.step2FieldsLayout}>
                    <div>
                      <Typography variant="h6" children="Instrument Info" />
                      <div className={classes.step2InstrumentInfoFieldLayout}>
                        <TextField
                          label="Instrument Name"
                          id="rightsToETNDialog-etnName-textField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          inputProps={{ maxLength: 40 }}
                          value={
                            etnDraftUpdateOnlyFieldViewModeOn
                              ? etn.name
                                ? etn.name
                                : "-"
                              : etn.name
                          }
                          placeholder="Enter an Instrument Name"
                          onChange={(e) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "name",
                            )(e.target.value)
                          }
                          helperText={
                            step2ValidationResult.fieldValidations["etn-name"]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations["etn-name"]
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                        <DateField
                          label="Issue Date"
                          className={classes.fieldWidth}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          disabled={apiLoading}
                          onChange={(newDate) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "issueDate",
                            )(newDate ? newDate.format() : "")
                          }
                          value={etn.issueDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="rightsToETNDialog-etnIssueDate-dateField"
                              fullWidth
                              disabled={apiLoading}
                              readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                              helperText={
                                step2ValidationResult.fieldValidations[
                                  "etn-issueDate"
                                ]
                              }
                              error={
                                !!step2ValidationResult.fieldValidations[
                                  "etn-issueDate"
                                ]
                              }
                            />
                          )}
                        />

                        <TextField
                          label="ISIN (Optional)"
                          id="rightsToETNDialog-etnISIN-textField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          inputProps={{ maxLength: 20 }}
                          value={
                            etnDraftUpdateOnlyFieldViewModeOn
                              ? etn.isin
                                ? etn.isin
                                : "-"
                              : etn.isin
                          }
                          placeholder="Enter an ISIN"
                          onChange={(e) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "isin",
                            )(e.target.value)
                          }
                          helperText={
                            step2ValidationResult.fieldValidations["etn-isin"]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations["etn-isin"]
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                        <DateField
                          label="Maturity Date"
                          id="rightsToETNDialog-etnMaturityDate-dateField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          onChange={(newDate) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "maturityDate",
                            )(newDate ? newDate.format() : "")
                          }
                          value={etn.maturityDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="rightsToETNDialog-etnIssueDate-dateField"
                              fullWidth
                              disabled={apiLoading}
                              readOnly={etnMaturityDateReadOnly}
                              helperText={
                                step2ValidationResult.fieldValidations[
                                  "etn-maturityDate"
                                ]
                              }
                              error={
                                !!step2ValidationResult.fieldValidations[
                                  "etn-maturityDate"
                                ]
                              }
                            />
                          )}
                        />

                        {etnDraftUpdateOnlyFieldViewModeOn ? (
                          <TextField
                            id="rightsToETNDialog-etnExchange-textField"
                            className={classes.fieldWidth}
                            label="Exchange (Optional)"
                            value={(() => {
                              const c = AllStockExchanges.find(
                                (se) => se.code === etn.exchange,
                              );
                              return c ? c.name : "-";
                            })()}
                            readOnly
                          />
                        ) : (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            disabled={
                              apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                            }
                            id="rightsToETNDialog-etnExchange-Autocomplete"
                            className={classes.fieldWidth}
                            getOptionLabel={(c: StockExchange) => c.name}
                            options={AllStockExchanges}
                            value={(() => {
                              const c = AllStockExchanges.find(
                                (se) => se.code === etn.exchange,
                              );
                              return c || null;
                            })()}
                            onChange={(
                              __: object,
                              se: StockExchange | null,
                            ) => {
                              if (se) {
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "exchange",
                                )(se.code);
                              } else {
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "exchange",
                                )("");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled={apiLoading}
                                readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                label="Exchange (Optional)"
                                placeholder={
                                  etnDraftUpdateOnlyFieldViewModeOn
                                    ? etn.exchange
                                      ? undefined
                                      : "-"
                                    : "Select..."
                                }
                                id="rightsToETNDialog-refInstrumentExchange-autocompleteTextField"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={
                                  !!step2ValidationResult.fieldValidations[
                                    "etn-exchange"
                                  ]
                                }
                                helperText={
                                  step2ValidationResult.fieldValidations[
                                    "etn-exchange"
                                  ]
                                }
                              />
                            )}
                          />
                        )}
                        <div />

                        <TextField
                          label="Exchange Code (Optional)"
                          id="rightsToETNDialog-etnExchangeCode-textField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          inputProps={{ maxLength: 20 }}
                          value={
                            etnDraftUpdateOnlyFieldViewModeOn
                              ? etn.exchangeCode
                                ? etn.exchangeCode
                                : "-"
                              : etn.exchangeCode
                          }
                          placeholder="Enter an Exchange Code"
                          onChange={(e) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "exchangeCode",
                            )(e.target.value)
                          }
                          helperText={
                            step2ValidationResult.fieldValidations[
                              "etn-exchangeCode"
                            ]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations[
                              "etn-exchangeCode"
                            ]
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                        <div />

                        <TextField
                          id="rightsToETNDialog-etnAssetClass-textField"
                          className={classes.fieldWidth}
                          label="Asset Class"
                          value={etn.assetClass}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className={classes.step2FieldDivider} />
                    <div>
                      <Typography variant="h6" children="Issuer Info" />
                      <div className={classes.step2IssuerInfoFieldLayout}>
                        <TextField
                          label="Name"
                          id="rightsToETNDialog-etnIssuerName-textField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                          inputProps={{ maxLength: 20 }}
                          value={
                            etnDraftUpdateOnlyFieldViewModeOn
                              ? etn.issuerName
                                ? etn.issuerName
                                : "-"
                              : etn.issuerName
                          }
                          placeholder={"Enter the Issuer's Name"}
                          onChange={(e) =>
                            handleChangeEntitiesInDialog(
                              undefined,
                              "issuerName",
                            )(e.target.value)
                          }
                          helperText={
                            step2ValidationResult.fieldValidations[
                              "etn-issuerName"
                            ]
                          }
                          error={
                            !!step2ValidationResult.fieldValidations[
                              "etn-issuerName"
                            ]
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                        {etnDraftUpdateOnlyFieldViewModeOn ? (
                          <TextField
                            id="rightsToETNDialog-etnIssuerRatingAgency-textField"
                            className={classes.fieldWidth}
                            label="Rating Agency (Optional)"
                            value={
                              etn.issuerRatingAgency
                                ? etn.issuerRatingAgency
                                : "-"
                            }
                            readOnly
                          />
                        ) : (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            id="rightsToETNDialog-etnIssuerRatingAgency-autoComplete"
                            className={classes.fieldWidth}
                            options={AllRatingAgencies}
                            disabled={
                              apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                            }
                            value={
                              etn.issuerRatingAgency
                                ? (etn.issuerRatingAgency as RatingAgency)
                                : null
                            }
                            onChange={(__: object, value) =>
                              handleChangeEntitiesInDialog(
                                undefined,
                                "issuerRatingAgency",
                              )(value || "")
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="rightsToETNDialog-refInstrumentIssuerRatingAgency-autoCompleteTextField"
                                readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                disabled={apiLoading}
                                label="Rating Agency (Optional)"
                                placeholder={
                                  etnDraftUpdateOnlyFieldViewModeOn
                                    ? etn.issuerRatingAgency
                                      ? etn.issuerRatingAgency
                                      : "-"
                                    : "Select..."
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                helperText={
                                  step1ValidationResult.fieldValidations[
                                    "etn-issuerRatingAgency"
                                  ]
                                }
                                error={
                                  !!step1ValidationResult.fieldValidations[
                                    "etn-issuerRatingAgency"
                                  ]
                                }
                              />
                            )}
                          />
                        )}
                        {etn.issuerRatingAgency ? (
                          etnDraftUpdateOnlyFieldViewModeOn ? (
                            <TextField
                              label="Credit Rating (Optional)"
                              id="rightsToETNDialog-etnIssuerCreditRating-textField"
                              className={classes.fieldWidth}
                              readOnly
                              value={
                                etn.issuerCreditRating
                                  ? etn.issuerCreditRating
                                  : "-"
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                          ) : (
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              id="rightsToETNDialog-etnIssuerCreditRating-autoComplete"
                              className={classes.fieldWidth}
                              options={
                                Ratings[etn.issuerRatingAgency]
                                  ? Ratings[etn.issuerRatingAgency]
                                  : []
                              }
                              disabled={
                                apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                              }
                              value={
                                etn.issuerCreditRating
                                  ? etn.issuerCreditRating
                                  : null
                              }
                              onChange={(__: object, value) =>
                                handleChangeEntitiesInDialog(
                                  undefined,
                                  "issuerCreditRating",
                                )(value || "")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="rightsToETNDialog-refInstrumentIssuerCreditRating-autoCompleteTextField"
                                  readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                  disabled={apiLoading}
                                  label="Credit Rating (Optional)"
                                  placeholder={
                                    etnDraftUpdateOnlyFieldViewModeOn
                                      ? etn.issuerCreditRating
                                        ? etn.issuerCreditRating
                                        : "-"
                                      : "Select..."
                                  }
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  helperText={
                                    step1ValidationResult.fieldValidations[
                                      "etn-issuerCreditRating"
                                    ]
                                  }
                                  error={
                                    !!step1ValidationResult.fieldValidations[
                                      "etn-issuerCreditRating"
                                    ]
                                  }
                                />
                              )}
                            />
                          )
                        ) : (
                          <Tooltip
                            title="Select Rating Agency First"
                            placement="top"
                          >
                            <span>
                              <TextField
                                label="Credit Rating (Optional)"
                                id="rightsToETNDialog-etnIssuerCreditRating-textField"
                                className={classes.fieldWidth}
                                disabled
                                readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                value="-"
                                InputLabelProps={{ shrink: true }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        <Typography
                          variant="h6"
                          children="Management Fee (Optional)"
                        />
                        {etnDraftUpdateOnlyFieldViewModeOn ? (
                          <TextField
                            id="rightsToETNDialog-issuerManagementFeeType-textField"
                            className={classes.fieldWidth}
                            label="Type"
                            value={etn.issuerManagementFee.instrumentManagementFeeType()}
                            readOnly
                          />
                        ) : (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            id="rightsToETNDialog-issuerManagementFeeType-autoComplete"
                            className={classes.fieldWidth}
                            options={AllInstrumentManagementFeeTypes}
                            disabled={
                              apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                            }
                            disableClearable
                            value={etn.issuerManagementFee.instrumentManagementFeeType()}
                            onChange={(__: object, value) => {
                              switch (value) {
                                case InstrumentManagementFeeType.CurrencyAmount:
                                  handleChangeEntitiesInDialog(
                                    undefined,
                                    "issuerManagementFee",
                                  )(
                                    new CurrencyAmountInstrumentManagementFee(),
                                  );
                                  break;

                                case InstrumentManagementFeeType.Percentage:
                                  handleChangeEntitiesInDialog(
                                    undefined,
                                    "issuerManagementFee",
                                  )(new PercentageInstrumentManagementFee());
                                  break;
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="rightsToETNDialog-issuerManagementFeeType-autoCompleteTextField"
                                readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                disabled={apiLoading}
                                label="Type"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                helperText={
                                  step1ValidationResult.fieldValidations[
                                    "etn-issuerManagementFeeType"
                                  ]
                                }
                                error={
                                  !!step1ValidationResult.fieldValidations[
                                    "etn-issuerManagementFeeType"
                                  ]
                                }
                              />
                            )}
                          />
                        )}
                        {etnDraftUpdateOnlyFieldViewModeOn ? (
                          <TextField
                            id="rightsToETNDialog-issuerManagementFeeFrequency-textField"
                            className={classes.fieldWidth}
                            label="Frequency"
                            value={
                              etn.issuerManagementFeeFrequency
                                ? etn.issuerManagementFeeFrequency
                                : "-"
                            }
                            readOnly
                          />
                        ) : (
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            disableClearable
                            id="rightsToETNDialog-issuerManagementFeeFrequency-autoComplete"
                            className={classes.fieldWidth}
                            options={AllInstrumentManagementFeeFrequencies}
                            disabled={
                              apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                            }
                            value={etn.issuerManagementFeeFrequency}
                            onChange={(__: object, value) =>
                              handleChangeEntitiesInDialog(
                                undefined,
                                "issuerManagementFeeFrequency",
                              )(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="rightsToETNDialog-issuerManagementFeeFrequency-autoCompleteTextField"
                                readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                disabled={apiLoading}
                                label="Frequency"
                                placeholder={
                                  etnDraftUpdateOnlyFieldViewModeOn
                                    ? etn.issuerManagementFeeFrequency
                                      ? etn.issuerManagementFeeFrequency
                                      : "-"
                                    : "Select..."
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                helperText={
                                  step1ValidationResult.fieldValidations[
                                    "etn-issuerManagementFeeFrequency"
                                  ]
                                }
                                error={
                                  !!step1ValidationResult.fieldValidations[
                                    "etn-issuerManagementFeeFrequency"
                                  ]
                                }
                              />
                            )}
                          />
                        )}
                        {(() => {
                          switch (true) {
                            case etn.issuerManagementFee instanceof
                              CurrencyAmountInstrumentManagementFee: {
                              const moneyManagementFee =
                                etn.issuerManagementFee as CurrencyAmountInstrumentManagementFee;
                              if (
                                viewModeOn &&
                                moneyManagementFee.amount.currencyCode
                              ) {
                                return (
                                  <CurrencyTextField
                                    value={moneyManagementFee.amount}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          position="start"
                                          children={
                                            getFormat(
                                              moneyManagementFee.amount
                                                .currencyCode,
                                            ).grapheme
                                          }
                                        />
                                      ),
                                    }}
                                    readOnly
                                  />
                                );
                              } else {
                                return (
                                  <div
                                    className={
                                      classes.step2IssuerManagementFeeFieldLayout
                                    }
                                  >
                                    <Autocomplete
                                      isOptionEqualToValue={(option, value) =>
                                        option === value
                                      }
                                      id="rightsToETNDialog-etnIssuerManagementFeeCcy-autoComplete"
                                      className={
                                        classes.step2IssuerManagementFeeCcyField
                                      }
                                      options={ISO4217_currencyList}
                                      disabled={
                                        apiLoading ||
                                        etnDraftUpdateOnlyFieldViewModeOn
                                      }
                                      value={
                                        moneyManagementFee.amount.currencyCode
                                          ? moneyManagementFee.amount
                                              .currencyCode
                                          : null
                                      }
                                      onChange={(__: object, value) =>
                                        handleChangeEntitiesInDialog(
                                          undefined,
                                          "issuerManagementFee",
                                        )(
                                          value
                                            ? new CurrencyAmountInstrumentManagementFee(
                                                {
                                                  ...etn.issuerManagementFee,
                                                  amount: new Money({
                                                    ...moneyManagementFee.amount,
                                                    currencyCode: value,
                                                  }),
                                                },
                                              )
                                            : new CurrencyAmountInstrumentManagementFee(
                                                {
                                                  ...etn.issuerManagementFee,
                                                  amount: new Money({
                                                    ...moneyManagementFee.amount,
                                                    currencyCode: "",
                                                  }),
                                                },
                                              ),
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          id="rightsToETNDialog-etnIssuerManagementFeeCcy-autoCompleteTextField"
                                          readOnly={
                                            etnDraftUpdateOnlyFieldViewModeOn
                                          }
                                          placeholder={
                                            etnDraftUpdateOnlyFieldViewModeOn
                                              ? moneyManagementFee.amount
                                                  .currencyCode
                                                ? moneyManagementFee.amount
                                                    .currencyCode
                                                : "-"
                                              : "Ccy"
                                          }
                                          variant="outlined"
                                          InputLabelProps={{ shrink: true }}
                                          helperText={
                                            step2ValidationResult
                                              .fieldValidations[
                                              "etf-issuerManagementFeeCurrencyAmountCode"
                                            ]
                                          }
                                          error={
                                            !!step2ValidationResult
                                              .fieldValidations[
                                              "etf-issuerManagementFeeCurrencyAmountCode"
                                            ]
                                          }
                                        />
                                      )}
                                    />
                                    <CurrencyTextField
                                      value={moneyManagementFee.amount}
                                      id="rightsToETNDialog-etnIssuerManagementFeeAmount-ccyTextField"
                                      onChange={(e) =>
                                        handleChangeEntitiesInDialog(
                                          undefined,
                                          "issuerManagementFee",
                                        )(
                                          new CurrencyAmountInstrumentManagementFee(
                                            {
                                              ...moneyManagementFee,
                                              amount: new Money(e),
                                            },
                                          ),
                                        )
                                      }
                                      InputProps={{ startAdornment: null }}
                                      readOnly={
                                        etnDraftUpdateOnlyFieldViewModeOn
                                      }
                                      disabled={apiLoading}
                                      error={
                                        !!step2ValidationResult
                                          .fieldValidations[
                                          "etn-issuerManagementFeeCurrencyAmount"
                                        ]
                                      }
                                      helperText={
                                        step2ValidationResult.fieldValidations[
                                          "etn-issuerManagementFeeCurrencyAmount"
                                        ]
                                      }
                                    />
                                  </div>
                                );
                              }
                            }

                            case etn.issuerManagementFee instanceof
                              PercentageInstrumentManagementFee: {
                              const percentageManagementFee =
                                etn.issuerManagementFee as PercentageInstrumentManagementFee;
                              return (
                                <TextNumField
                                  disallowNegative
                                  disabled={apiLoading}
                                  sx={{ width: viewModeOn ? 50 : 250 }}
                                  readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                                  id="rightsToETNDialog-issuerManagementFeePercentage-textNumField"
                                  InputProps={{ endAdornment: "%" }}
                                  placeholder="-"
                                  value={percentageManagementFee.percentage}
                                  onChange={(e) =>
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "issuerManagementFee",
                                    )(
                                      new PercentageInstrumentManagementFee({
                                        ...percentageManagementFee,
                                        percentage: new BigNumber(
                                          e.target.value,
                                        ),
                                      }),
                                    )
                                  }
                                  error={
                                    !!step2ValidationResult.fieldValidations[
                                      "etn-issuerManagementFeePercentage"
                                    ]
                                  }
                                  helperText={
                                    step2ValidationResult.fieldValidations[
                                      "etn-issuerManagementFeePercentage"
                                    ]
                                  }
                                />
                              );
                            }

                            default:
                              return null;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Step 3
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.step3
                    ? accordionSectionsOpen.step3
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    step3: !accordionSectionsOpen.step3,
                  })
                }
              >
                <AccordionSummary
                  id="rightsToETNDialog-step3-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="rightsToETNDialog-step3-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  classes={{
                    root: classes.stepsCommonAccordionSummaryRootOverride,
                  }}
                >
                  <div
                    className={classes.stepsCommonAccordionSummaryContentLayout}
                  >
                    <Typography variant="h6">Asset Exposure</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 3
                    </Typography>
                    <Typography
                      variant="body2"
                      className={
                        step3ValidationResult.stepComplete
                          ? classes.successText
                          : classes.errorText
                      }
                    >
                      {step3ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      etnActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="rightsToETNDialogStep3-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.RightsToETN}?&id=${etnStablecoin.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={cx(
                    classes.stepsCommonAccordionDetailsLayout,
                    classes.paddingBottom,
                  )}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.stepsCommonGuidanceText}
                  >
                    Enter details of the division of assets within your
                    investment portfolio.
                  </Typography>

                  <div
                    className={
                      classes.step1InstrumentDetailsInstrumentFieldsRiskProfileLayout
                    }
                  >
                    <Typography variant="h6" children="Investor Profile" />
                    <Typography variant="h6" children="Risk Profile" />

                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        label="Investor"
                        id="rightsToETNDialog-etnInvestorProfile-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={etn.investorProfile ? etn.investorProfile : "-"}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToETNDialog-etnInvestorProfile-autocomplete"
                        className={classes.fieldWidth}
                        options={AllInvestorProfiles}
                        disabled={
                          apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                        }
                        value={
                          etn.investorProfile
                            ? (etn.investorProfile as InvestorProfile)
                            : null
                        }
                        onChange={(__: object, value) =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "investorProfile",
                          )(value || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="rightsToETNDialog-etnInvestorProfile-autocompleteTextField"
                            label="Investor"
                            readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            placeholder={
                              etnDraftUpdateOnlyFieldViewModeOn
                                ? etn.investorProfile
                                  ? etn.investorProfile
                                  : "-"
                                : "Select..."
                            }
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            helperText={
                              step3ValidationResult.fieldValidations[
                                "etn-investorProfile"
                              ]
                            }
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-investorProfile"
                              ]
                            }
                          />
                        )}
                      />
                    )}
                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        label="Investor"
                        id="rightsToETNDialog-etnRiskProfile-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={etn.riskProfile ? etn.riskProfile : "-"}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="rightsToETNDialog-etnRiskProfile-autocomplete"
                        className={classes.fieldWidth}
                        options={AllInstrumentRiskProfiles}
                        disabled={
                          apiLoading || etnDraftUpdateOnlyFieldViewModeOn
                        }
                        value={
                          etn.riskProfile
                            ? (etn.riskProfile as InstrumentRiskProfile)
                            : null
                        }
                        onChange={(__: object, value) =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "riskProfile",
                          )(value || "")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            readOnly={etnDraftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            id="rightsToETNDialog-etnRiskProfile-autocompleteTextField"
                            InputLabelProps={{ shrink: true }}
                            label="Risk"
                            placeholder={
                              etnDraftUpdateOnlyFieldViewModeOn
                                ? etn.riskProfile
                                  ? etn.riskProfile
                                  : "-"
                                : "Select..."
                            }
                            variant="outlined"
                            helperText={
                              step3ValidationResult.fieldValidations[
                                "etn-riskProfile"
                              ]
                            }
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-riskProfile"
                              ]
                            }
                          />
                        )}
                      />
                    )}

                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <Typography
                        className={cx(
                          classes.step3GeneralDescriptionFieldTextAreaViewMode,
                          "meshScroll",
                        )}
                        variant="body1"
                        color="textSecondary"
                        children={etn.investorProfileDescription}
                      />
                    ) : (
                      <div
                        className={classes.step3GeneralDescriptionFieldLayout}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children="Description  (Optional)"
                        />
                        <TextareaAutosize
                          id="rightsToETNDialog-etnInvestorProfileDescription-textArea"
                          minRows={5}
                          maxRows={5}
                          disabled={apiLoading}
                          value={etn.investorProfileDescription}
                          onChange={(e) => {
                            let newValue: string = e.target.value;
                            if (newValue.length >= 500) {
                              newValue = newValue.slice(0, 500);
                            }
                            handleChangeEntitiesInDialog(
                              undefined,
                              "investorProfileDescription",
                            )(newValue);
                          }}
                          className={cx(
                            classes.step3GeneralDescriptionFieldTextArea,
                            "meshScroll",
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children={`${
                            500 - etn.investorProfileDescription.length
                          } characters left`}
                        />
                      </div>
                    )}

                    {etnDraftUpdateOnlyFieldViewModeOn ? (
                      <Typography
                        className={cx(
                          classes.step3GeneralDescriptionFieldTextAreaViewMode,
                          "meshScroll",
                        )}
                        variant="body1"
                        color="textSecondary"
                        children={etn.riskProfileDescription}
                      />
                    ) : (
                      <div
                        className={classes.step3GeneralDescriptionFieldLayout}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children="Description (Optional)"
                        />
                        <TextareaAutosize
                          minRows={5}
                          maxRows={5}
                          disabled={apiLoading}
                          id="rightsToETNDialog-etnRiskProfileDescription-textArea"
                          value={etn.riskProfileDescription}
                          onChange={(e) => {
                            let newValue: string = e.target.value;
                            if (newValue.length >= 500) {
                              newValue = newValue.slice(0, 500);
                            }
                            handleChangeEntitiesInDialog(
                              undefined,
                              "riskProfileDescription",
                            )(newValue);
                          }}
                          className={cx(
                            classes.step3GeneralDescriptionFieldTextArea,
                            "meshScroll",
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children={`${
                            500 - etn.riskProfileDescription.length
                          } characters left`}
                        />
                      </div>
                    )}
                  </div>

                  {!viewModeOn && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.stepsCommonGuidanceText}
                    >
                      Enter details of asset exposure allocation.
                    </Typography>
                  )}

                  {viewModeOn || etnHoldingsAndAllocationsSectionReadOnly ? (
                    <div
                      className={classes.holdingsAndAllocationsViewModeLayout}
                    >
                      {/* -------------- Country Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography
                          variant="h6"
                          children="Country Allocation"
                        />
                        {accordionSectionsOpen.step3 && (
                          <CountryAllocationPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            countryAllocations={etn.countryAllocations}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {etn.countryAllocations.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={
                                  ca.name
                                    ? countries.find((c) => c.value === ca.name)
                                        ?.label
                                    : "-"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* -------------- Holding Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography variant="h6" children="Holdings" />
                        {accordionSectionsOpen.step3 && (
                          <HoldingsPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            holdings={etn.holdings}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {etn.holdings.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={ca.name ? ca.name : "-"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* -------------- Sector Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography variant="h6" children="Sector Allocation" />
                        {accordionSectionsOpen.step3 && (
                          <SectorAllocationPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            sectorAllocations={etn.sectorAllocations}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {etn.sectorAllocations.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={ca.sector ? ca.sector : "-"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.holdingsAndAllocationsLayout}>
                      {/* -------------- Country Allocation -------------- */}
                      <div>
                        <Typography
                          variant="h6"
                          children="Country Allocation"
                        />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation =
                              etn.countryAllocations.reduce(
                                (total, ca) => ca.percentage.plus(total),
                                new BigNumber(0),
                              );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.countryAllocations &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            disabled={apiLoading}
                            id="rightsToETNDialog-etnCountryAllocationControlRowName-autocomplete"
                            className={classes.holdingsAndAllocationsNameField}
                            getOptionLabel={(c) => c.label}
                            options={countries}
                            value={(() => {
                              const c = countries.find(
                                (countryOption) =>
                                  countryOption.value ===
                                  pendingCountryAllocation.name,
                              );
                              return c || null;
                            })()}
                            onChange={(
                              __: object,
                              country: CountryOption | null,
                            ) => {
                              if (country) {
                                setPendingCountryAllocation(
                                  new CountryAllocation({
                                    ...pendingCountryAllocation,
                                    name: country.value,
                                  }),
                                );
                              } else {
                                setPendingCountryAllocation(
                                  new CountryAllocation({
                                    ...pendingCountryAllocation,
                                    name: "",
                                  }),
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled={apiLoading}
                                id="rightsToETNDialog-etnCountryAllocationControlRowName-autocompleteTextField"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Select..."
                                variant="outlined"
                                error={
                                  !!step3ValidationResult.fieldValidations[
                                    "etn-countryAllocations"
                                  ]
                                }
                              />
                            )}
                          />
                          <TextNumField
                            disallowNegative
                            disabled={apiLoading}
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            id="rightsToETNDialog-etnCountryAllocationControlRowPercentage-textNumField"
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingCountryAllocation.percentage}
                            onChange={(e) =>
                              setPendingCountryAllocation({
                                ...pendingCountryAllocation,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            inputProps={{ maxLength: 40 }}
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-countryAllocations"
                              ]
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="rightsToETNDialog-etnCountryAllocationControlAdd-button"
                            onClick={() => {
                              if (!pendingCountryAllocation.name) {
                                return;
                              }
                              handleChangeEntitiesInDialog(
                                undefined,
                                "countryAllocations",
                              )([
                                ...etn.countryAllocations,
                                new CountryAllocation(pendingCountryAllocation),
                              ]);
                              setPendingCountryAllocation(
                                new CountryAllocation(),
                              );
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step3ValidationResult.fieldValidations[
                            "etn-countryAllocations"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step3ValidationResult.fieldValidations[
                                  "etn-countryAllocations"
                                ]
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {etn.countryAllocations.map(
                              (countryAllocation, idx) => (
                                <Grid
                                  item
                                  key={idx}
                                  className={
                                    classes.holdingsAndAllocationsLineItem
                                  }
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    disabled={apiLoading}
                                    id={`rightsToETNDialog-etnCountryAllocationLineCountryCode-autocomplete-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsNameField
                                    }
                                    getOptionLabel={(c) => c.label}
                                    options={countries}
                                    value={(() => {
                                      const c = countries.find(
                                        (countryOption) =>
                                          countryOption.value ===
                                          countryAllocation.name,
                                      );
                                      return c || null;
                                    })()}
                                    onChange={(
                                      __: object,
                                      country: CountryOption | null,
                                    ) => {
                                      if (country) {
                                        etn.countryAllocations[idx].name =
                                          country.value;
                                      } else {
                                        etn.countryAllocations[idx].name = "";
                                      }
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(etn.countryAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        disabled={apiLoading}
                                        id={`rightsToETNDialog-etnCountryAllocationLineCountryCode-autocompleteTextField-${idx}`}
                                        placeholder="..."
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step3ValidationResult
                                            .fieldValidations[
                                            `etn-countryAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`rightsToETNDialog-etnCountryAllocationLinePercentage-textNumField-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsPercentField
                                    }
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={countryAllocation.percentage}
                                    onChange={(e) => {
                                      etn.countryAllocations[idx].percentage =
                                        new BigNumber(e.target.value);
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(etn.countryAllocations);
                                    }}
                                    error={
                                      !!step3ValidationResult.fieldValidations[
                                        `etn-countryAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`rightsToETNDialog-etnCountryAllocationLinePercentage-textNumField-${idx}`}
                                    onClick={() =>
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "countryAllocations",
                                      )(
                                        etn.countryAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  {!!step3ValidationResult.fieldValidations[
                                    `etn-countryAllocations-${idx}-name`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step3ValidationResult.fieldValidations[
                                          `etn-countryAllocations-${idx}-name`
                                        ]
                                      }
                                    />
                                  )}
                                  {!!step3ValidationResult.fieldValidations[
                                    `etn-countryAllocations-${idx}-percentage`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step3ValidationResult.fieldValidations[
                                          `etn-countryAllocations-${idx}-percentage`
                                        ]
                                      }
                                    />
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </div>
                      </div>

                      {/* placeholder to take up space and keep scroll bar against line items */}
                      <div className={classes.holdingsAndAllocationsSpacer} />

                      {/* -------------- Holding Allocation -------------- */}
                      <div>
                        <Typography variant="h6" children="Holdings" />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation = etn.holdings.reduce(
                              (total, holding) =>
                                holding.percentage.plus(total),
                              new BigNumber("0"),
                            );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.holdings &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <TextField
                            placeholder="Add a Holding"
                            value={pendingHolding.name}
                            inputProps={{ maxLength: 40 }}
                            id="rightsToETNDialog-etnHoldingControlRowName-textField"
                            className={classes.holdingsAndAllocationsNameField}
                            disabled={apiLoading}
                            onChange={(e) =>
                              setPendingHolding(
                                new Holding({
                                  ...pendingHolding,
                                  name: e.target.value,
                                }),
                              )
                            }
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-holdings"
                              ]
                            }
                          />
                          <TextNumField
                            disallowNegative
                            disabled={apiLoading}
                            id="rightsToETNDialog-etnHoldingControlRowPercentage-textNumField"
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingHolding.percentage}
                            onChange={(e) =>
                              setPendingHolding({
                                ...pendingHolding,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-holdings"
                              ]
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="rightsToETNDialog-etnHoldingControlRowAdd-button"
                            onClick={() => {
                              if (!pendingHolding.name) {
                                return;
                              }
                              handleChangeEntitiesInDialog(
                                undefined,
                                "holdings",
                              )([...etn.holdings, new Holding(pendingHolding)]);
                              setPendingHolding(new Holding());
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step3ValidationResult.fieldValidations[
                            "etn-holdings"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step3ValidationResult.fieldValidations[
                                  "etn-holdings"
                                ]
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {etn.holdings.map((holding, idx) => (
                              <Grid
                                item
                                key={idx}
                                className={
                                  classes.holdingsAndAllocationsLineItem
                                }
                              >
                                <TextField
                                  disabled={apiLoading}
                                  inputProps={{ maxLength: 40 }}
                                  id={`rightsToETNDialog-etnHoldingRow-${idx}-name-textField`}
                                  className={
                                    classes.holdingsAndAllocationsNameField
                                  }
                                  value={holding.name}
                                  onChange={(e) => {
                                    etn.holdings[idx].name = e.target.value;
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(etn.holdings);
                                  }}
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      `etn-holdings-${idx}-name`
                                    ]
                                  }
                                />
                                <TextNumField
                                  disallowNegative
                                  disabled={apiLoading}
                                  id={`rightsToETNDialog-etnHoldingControlRow-${idx}-percentage-textNumField`}
                                  className={
                                    classes.holdingsAndAllocationsPercentField
                                  }
                                  InputProps={{ endAdornment: "%" }}
                                  placeholder="-"
                                  value={holding.percentage}
                                  onChange={(e) => {
                                    etn.holdings[idx].percentage =
                                      new BigNumber(e.target.value);
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(etn.holdings);
                                  }}
                                  error={
                                    !!step3ValidationResult.fieldValidations[
                                      `etn-holdings-${idx}-percentage`
                                    ]
                                  }
                                />
                                <IconButton
                                  size="small"
                                  disabled={apiLoading}
                                  id={`rightsToETNDialog-etnHoldingRow-${idx}-remove-button`}
                                  onClick={() =>
                                    handleChangeEntitiesInDialog(
                                      undefined,
                                      "holdings",
                                    )(etn.holdings.filter((_, i) => i !== idx))
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                                {!!step3ValidationResult.fieldValidations[
                                  `etn-holdings-${idx}-name`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    className={
                                      classes.holdingsAnAllocationsLineItemHelperText
                                    }
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `etn-holdings-${idx}-name`
                                      ]
                                    }
                                  />
                                )}
                                {!!step3ValidationResult.fieldValidations[
                                  `etn-holdings-${idx}-percentage`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    className={
                                      classes.holdingsAnAllocationsLineItemHelperText
                                    }
                                    children={
                                      step3ValidationResult.fieldValidations[
                                        `etn-holdings-${idx}-percentage`
                                      ]
                                    }
                                  />
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>

                      {/* placeholder to take up space and keep scroll bar against line items */}
                      <div className={classes.holdingsAndAllocationsSpacer} />

                      {/* -------------- Sector Allocation -------------- */}
                      <div>
                        <Typography variant="h6" children="Sector Allocation" />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation =
                              etn.sectorAllocations.reduce(
                                (total, ca) => ca.percentage.plus(total),
                                new BigNumber("0"),
                              );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.sectorAllocations &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            id="rightsToETNDialog-etnSectorControlRowSector-autoComplete"
                            className={classes.holdingsAndAllocationsNameField}
                            options={AllFinancialSectors}
                            disabled={apiLoading}
                            value={
                              pendingSectorAllocation.sector
                                ? pendingSectorAllocation.sector
                                : null
                            }
                            onChange={(__: object, value) =>
                              setPendingSectorAllocation(
                                new SectorAllocation({
                                  ...pendingSectorAllocation,
                                  sector: value || "",
                                }),
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="rightsToETNDialog-etnSectorControlRowSector-autoCompleteTextField"
                                placeholder={
                                  pendingSectorAllocation.sector
                                    ? pendingSectorAllocation.sector
                                      ? pendingSectorAllocation.sector
                                      : "-"
                                    : "Select..."
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                error={
                                  !!step3ValidationResult.fieldValidations[
                                    "etn-sectorAllocations"
                                  ]
                                }
                              />
                            )}
                          />
                          <TextNumField
                            disallowNegative
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            disabled={apiLoading}
                            id="rightsToETNDialog-sectorControlRowPercentage-textNumField"
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingSectorAllocation.percentage}
                            onChange={(e) =>
                              setPendingSectorAllocation({
                                ...pendingSectorAllocation,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            error={
                              !!step3ValidationResult.fieldValidations[
                                "etn-sectorAllocations"
                              ]
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="rightsToETNDialog-sectorControlRowAdd-button"
                            onClick={() => {
                              if (!pendingSectorAllocation.sector) {
                                return;
                              }
                              handleChangeEntitiesInDialog(
                                undefined,
                                "sectorAllocations",
                              )([
                                ...etn.sectorAllocations,
                                new SectorAllocation(pendingSectorAllocation),
                              ]);
                              setPendingSectorAllocation(
                                new SectorAllocation(),
                              );
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step3ValidationResult.fieldValidations[
                            "etn-sectorAllocations"
                          ] && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step3ValidationResult.fieldValidations[
                                  "etn-sectorAllocations"
                                ]
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {etn.sectorAllocations.map(
                              (sectorAllocation, idx) => (
                                <Grid
                                  item
                                  key={idx}
                                  className={
                                    classes.holdingsAndAllocationsLineItem
                                  }
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    id={`rightsToETNDialog-etnSectorControlRowName-autocomplete-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsNameField
                                    }
                                    options={AllFinancialSectors}
                                    disabled={apiLoading}
                                    value={
                                      sectorAllocation.sector
                                        ? sectorAllocation.sector
                                        : null
                                    }
                                    onChange={(__: object, value) => {
                                      etn.sectorAllocations[idx].sector =
                                        value || "";
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(etn.sectorAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id={`rightsToETNDialog-etnSectorControlRowName-autocompleteTextField-${idx}`}
                                        placeholder={
                                          pendingSectorAllocation.sector
                                            ? pendingSectorAllocation.sector
                                              ? pendingSectorAllocation.sector
                                              : "-"
                                            : "Select..."
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step3ValidationResult
                                            .fieldValidations[
                                            `etn-sectorAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`rightsToETNDialog-etnSectorControlRowPercentage-textField-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsPercentField
                                    }
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={sectorAllocation.percentage}
                                    onChange={(e) => {
                                      etn.sectorAllocations[idx].percentage =
                                        new BigNumber(e.target.value);
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(etn.sectorAllocations);
                                    }}
                                    error={
                                      !!step3ValidationResult.fieldValidations[
                                        `etn-sectorAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`rightsToETNDialog-etnSectorControlRowAdd-button-${idx}`}
                                    onClick={() =>
                                      handleChangeEntitiesInDialog(
                                        undefined,
                                        "sectorAllocations",
                                      )(
                                        etn.sectorAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  {!!step3ValidationResult.fieldValidations[
                                    `etn-sectorAllocations-${idx}-name`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step3ValidationResult.fieldValidations[
                                          `etn-sectorAllocations-${idx}-name`
                                        ]
                                      }
                                    />
                                  )}
                                  {!!step3ValidationResult.fieldValidations[
                                    `etn-sectorAllocations-${idx}-percentage`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step3ValidationResult.fieldValidations[
                                          `etn-sectorAllocations-${idx}-percentage`
                                        ]
                                      }
                                    />
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* -------------- Returns Log -------------- */}
                  <Typography variant="h6" children="Returns Log" />
                  {!etnAnnualPerformanceLogReadOnly && (
                    <div
                      className={
                        classes.instrumentAnnualPerformanceLogHelperText
                      }
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        children="Add returns by month and annually in %"
                      />
                      {!!step3ValidationResult.fieldValidations[
                        "etn-annualPerformanceLog"
                      ] && (
                        <Typography
                          color="error"
                          variant="body2"
                          children={
                            step3ValidationResult.fieldValidations[
                              "etn-annualPerformanceLog"
                            ]
                          }
                        />
                      )}
                    </div>
                  )}
                  <ViewEditInstrumentAnnualPerformanceLog
                    annualPerformanceLog={etn.annualPerformanceLog}
                    onChange={(updated) =>
                      handleChangeEntitiesInDialog(
                        undefined,
                        "annualPerformanceLog",
                      )(updated)
                    }
                    disabled={apiLoading}
                    viewMode={etnAnnualPerformanceLogReadOnly}
                    fieldValidations={step3ValidationResult.fieldValidations}
                    issueDate={etn.issueDate}
                    maturityDate={etn.maturityDate}
                  />
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Corporate Actions
              // -------------------------------------------------
            */}
              {!!corporateActions.length && (
                <Accordion
                  expanded={
                    accordionSectionsOpen.corporateActions
                      ? accordionSectionsOpen.corporateActions
                      : false
                  }
                  onChange={() =>
                    setAccordionSectionsOpen({
                      ...accordionSectionsOpen,
                      corporateActions: !accordionSectionsOpen.corporateActions,
                    })
                  }
                >
                  <AccordionSummary
                    id="rightsToETNDialog-corporateActions-accordion"
                    expandIcon={
                      <ExpandMoreIcon
                        id="rightsToETNDialog-corporateActions-accordionToggleIconButton"
                        color="primary"
                      />
                    }
                    classes={{
                      root: classes.stepsCommonAccordionSummaryRootOverride,
                    }}
                  >
                    <div
                      className={
                        classes.stepsCommonAccordionSummaryContentLayout
                      }
                    >
                      <Typography variant="h6">Corporate Actions</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    className={classes.stepsCommonAccordionDetailsLayout}
                  >
                    {corporateActions.map((ca, idx) => (
                      <React.Fragment key={`assetCorporateAction-${idx}`}>
                        <ETNRollCorporateActionCard
                          etnRollCorporateAction={ca}
                        />
                        {!!(
                          corporateActions.length - 1 &&
                          idx !== corporateActions.length - 1
                        ) && (
                          <Divider
                            sx={{
                              marginTop: theme.spacing(3),
                              marginBottom: theme.spacing(3),
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </div>
          <div className={classes.rootRightColumn}>
            {!viewModeOn &&
            etnActionsViewConfig.Update &&
            !etnSupportingDocumentsReadOnly ? (
              <div className={classes.docsMediaColContent}>
                <div className={classes.docsMediaColContentWarningMsg}>
                  <Typography
                    variant="h6"
                    children="Supporting Documents/Media"
                  />
                  {!!supportingDocsStepValidationResult.fieldValidations[
                    "etn-supportingDocuments"
                  ] && (
                    <Typography
                      variant="body2"
                      color="error"
                      children={
                        supportingDocsStepValidationResult.fieldValidations[
                          "etn-supportingDocuments"
                        ]
                      }
                    />
                  )}
                </div>
                <div
                  {...dropZoneProps.getRootProps()}
                  className={cx(classes.dropZone, {
                    [classes.dropZoneAccept]: dropZoneProps.isDragAccept,
                    [classes.dropZoneReject]: dropZoneProps.isDragReject,
                  })}
                >
                  <input
                    {...dropZoneProps.getInputProps()}
                    disabled={apiLoading || !!pendingDocumentUploads.length}
                  />
                  {(() => {
                    if (pendingDocumentUploads.length) {
                      return (
                        <>
                          <Typography
                            variant="body2"
                            children="Uploads in Progress"
                          />
                          <CircularProgress />
                        </>
                      );
                    }

                    if (dropZoneProps.isDragActive) {
                      return dropZoneProps.isDragAccept ? (
                        <>
                          <Typography
                            variant="body1"
                            className={classes.successText}
                            children="Drop Documents For Upload"
                          />
                          <UploadIcon className={classes.successText} />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body1"
                            color="error"
                            children="Unsupported Document Type"
                          />
                          <NotAllowedIcon color="error" />
                        </>
                      );
                    }
                    return (
                      <>
                        <Typography
                          variant="body2"
                          children="Drop Documents Here to Upload"
                        />
                        <Typography variant="body2" children="or" />
                        <Button
                          disabled={apiLoading}
                          id="etnDialog-selectFilesUpload-button"
                          variant="contained"
                          color="secondary"
                          children="Select Files"
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.dropZoneGuidanceText}
                          children=".png, .jpeg, or .pdf up to max size of 20MB each"
                        />
                      </>
                    );
                  })()}
                </div>
                {/* -----------------Render Upload in Progress------------------ */}
                {pendingDocumentUploads.map((d, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id="etnDialog-description-formField"
                      label="Description"
                      value={d.file.name}
                      disabled
                    />
                    {d.complete ? (
                      <DoneIcon className={classes.successText} />
                    ) : (
                      <CircularProgress
                        className={classes.docsMediaLineItemMarginTop}
                        size={25}
                        variant={d.percent ? "determinate" : "indeterminate"}
                        value={d.percent}
                      />
                    )}
                    <Typography
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="secondary"
                      children={d.file.name}
                    />
                  </div>
                ))}

                {/* -----------------Render Existing Docs------------------ */}
                {etn.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id={`etnDialog-addAName-${doc.name}formField`}
                      label="Name"
                      value={doc.description}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Add a Name"
                      disabled={apiLoading || !!pendingDocumentUploads.length}
                      onChange={(e) => {
                        etn.supportingDocuments[idx].description =
                          e.target.value;
                        handleChangeEntitiesInDialog(
                          undefined,
                          "supportingDocuments",
                          [`supportingDocuments-${idx}-description`],
                        )(etn.supportingDocuments);
                      }}
                      error={
                        !!supportingDocsStepValidationResult.fieldValidations[
                          `etn-supportingDocuments-${idx}-description`
                        ]
                      }
                      helperText={
                        supportingDocsStepValidationResult.fieldValidations[
                          `etn-supportingDocuments-${idx}-description`
                        ]
                      }
                    />
                    <span>
                      <IconButton
                        id={`etnDialog-deleteDocument-${doc.name}-button`}
                        className={classes.docsMediaLineItemBinIcon}
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        onClick={() =>
                          handleChangeEntitiesInDialog(
                            undefined,
                            "supportingDocuments",
                          )(etn.supportingDocuments.filter((_, i) => i !== idx))
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                    <Typography
                      id={`etnDialog-docName-${doc.name}typography`}
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="secondary"
                      children={doc.name}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={classes.docsMediaColContent}>
                <Typography
                  variant="h6"
                  children="Supporting Documents/Media"
                />
                {!etn.supportingDocuments.length && (
                  <Typography
                    className={classes.docsMediaLineItemFileName}
                    variant="body2"
                    color="textSecondary"
                    children="Nothing has been uploaded yet"
                  />
                )}
                {/* -----------------Render Existing Docs------------------ */}
                {etn.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      children={doc.description ? doc.description : "-"}
                    />
                    <div className={classes.docsMediaLineItemFileNameWithDL}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        children={doc.name}
                      />
                      <IconButton
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        className={classes.docsMediaDownloadIconButton}
                        onClick={async () => {
                          setAPILoading(true);
                          try {
                            download(
                              (
                                await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                                  {
                                    context: authContext,
                                    documentID: doc.id,
                                  },
                                )
                              ).downloadURL,
                              doc.name,
                            );
                          } catch (e) {
                            const err =
                              errorContextErrorTranslator.translateError(e);
                            console.error(
                              `error requesting download url: ${
                                err.message ? err.message : err.toString()
                              }`,
                            );
                          }
                          setAPILoading(false);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
      </StyledDialog>
      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
      />
      <IssueWarningDialog
        showDialog={!!issueWarningDialogOptions}
        onCloseClick={() => setIssueWarningDialogOptions(null)}
        onYesClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.yesMethod
            : () => null
        }
        onNoClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.noMethod
            : () => null
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
        preIssuing={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.preIssuing
            : false
        }
      />
      {showMintAssetDialog && (
        <MintAssetDialog
          onCloseClick={() => setShowMintAssetDialog(false)}
          assetToMintID={etnStablecoin.id}
        />
      )}
    </>
  );
}
