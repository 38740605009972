import { Amount, Token } from "james/ledger";

export enum MechanismType {
  DirectOrder = "Direct Order",
  Spot = "Spot",
  Subscription = "Subscription",
}

export const allMechanismTypes: MechanismType[] = [
  MechanismType.DirectOrder,
  MechanismType.Subscription,
  MechanismType.Spot,
];

export const allPrimaryMarketMechanismTypes: MechanismType[] = [
  MechanismType.Subscription,
];

export class Mechanism {
  public type: MechanismType | "" = "";

  public quoteParameters: QuoteParameter[] = [];

  constructor(m?: Mechanism) {
    if (!m) {
      return;
    }
    this.type = m.type;
    this.quoteParameters = m.quoteParameters.map(
      (qp) => new QuoteParameter(qp),
    );
  }
}

export type QuoteParameterProps = {
  quoteToken: Token;
  minimumDealSize: Amount;
  maximumDealSize: Amount;
};

export class QuoteParameter {
  public quoteToken: Token = new Token();

  public minimumDealSize: Amount = new Amount();

  public maximumDealSize: Amount = new Amount();

  constructor(q?: QuoteParameterProps) {
    if (!q) {
      return;
    }
    this.quoteToken = new Token(q.quoteToken);
    this.minimumDealSize = new Amount(q.minimumDealSize);
    this.maximumDealSize = new Amount(q.maximumDealSize);
  }
}
