import React from "react";
import { Router } from "routes";
import { Table, Builder } from "./components";
import { Box } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";

export function SmartInstruments() {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Smart Instruments"]?.ReadUNSCOPED;

  return (
    <Router
      baseURL={"/builder/smart-instruments"}
      redirectPath={"/builder/smart-instruments/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/builder/smart-instruments/table",
          component: (
            <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
              <Table system={system} />
            </Box>
          ),
        },
        {
          name: "Builder",
          id: "builder",
          path: "/builder/smart-instruments/builder",
          component: <Builder system={system} />,
        },
      ]}
    />
  );
}
