import { Asset, Horizon } from "stellar-sdk";
import { Token } from "james/ledger";
import { LedgerNetwork } from "james/ledger/Network";
import { AccountInspector } from "./AccountInspector";
export * from "./AccountOperator";
import {
  Transaction as StellarTransaction,
  TransactionTypeName as StellarTransactionTypeName,
} from "./Transaction";
import {
  Account as StellarAccount,
  AccountTypeName as StellarAccountTypeName,
} from "./Account";
import { SourceAccount as StellarSourceAccount } from "./SourceAccount";
import { LiquidityPool } from "./LiquidityPool";
import { LiquidityPoolRegistration } from "./LiquidityPoolRegistration";
import { LiquidityPoolRegistrationInspector } from "./LiquidityPoolRegistrationInspector";
import { LiquidityPoolRegistrar } from "./LiquidityPoolRegistrar";
import { LiquidityPoolCalculator } from "./LiquidityPoolCalculator";
import { LiquidityPoolOperator } from "./LiquidityPoolOperator";
import { AllStellarNetworks, StellarNetwork } from "./Network";
import { IsLiquidityPoolToken } from "./liquidityPoolToken";
import { AccountChangedNotification } from "./AccountChangedNotification";
import { AccountOpenedNotification } from "./AccountOpenedNotification";
import { SourceAccountStateController } from "./SourceAccountStateController";

export * from "./Network";

export * from "./liquidityPoolToken";

export const MaxSetLimitAmount = "922337203685.4775807";

export const NativeAssetTokenCode = "XLM";

export const StellarAccountNotificationChannelName = "StellarAccount";

export const StellarAccountPortfolioNotificationChannelName =
  "StellarAccountPortfolio";

export {
  AccountOpenedNotification,
  AccountChangedNotification,
  AccountInspector,
  StellarTransaction,
  StellarTransactionTypeName,
  StellarAccount,
  StellarAccountTypeName,
  LiquidityPool,
  LiquidityPoolRegistration,
  LiquidityPoolRegistrationInspector,
  LiquidityPoolRegistrar,
  LiquidityPoolCalculator,
  LiquidityPoolOperator,
  StellarSourceAccount,
  SourceAccountStateController,
};

export function getNetworkServer(network: LedgerNetwork | "-"): Horizon.Server {
  switch (network) {
    case StellarNetwork.PublicNetwork:
      return new Horizon.Server("https://horizon-public.pv.mesh.trade");

    case StellarNetwork.TestSDFNetwork:
      return new Horizon.Server("https://horizon-testnet.pv.mesh.trade");

    default:
      throw new Error(`unexpected network ${network}`);
  }
}

export function tokenToAsset(token: Token): Asset {
  if (!AllStellarNetworks.includes(token.network as StellarNetwork)) {
    throw new TypeError(
      `given token is not on a supported stellar network: ${token.network}`,
    );
  }
  if (IsLiquidityPoolToken(token)) {
    throw new TypeError(
      `liquidity pool token '${token.code}:${token.issuer}' cannot be converted to asset`,
    );
  }

  if (token.code === NativeAssetTokenCode) {
    return new Asset(NativeAssetTokenCode);
  }
  return new Asset(token.code, token.issuer);
}
