import { Box } from "@mui/material";

export const CallabilitySection = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
      })}
    >
      Coming Soon!
    </Box>
  );
};
