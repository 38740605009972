import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAPIContext } from "../../../context/API";
import { useApplicationContext } from "context/Application/Application";
import { CapturePrimeRateRequest } from "@mesh/common-js/dist/financial/primeRateRecorder_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import { bigNumberToDecimal } from "@mesh/common-js/dist/num";
import BigNumber from "bignumber.js";
import {
  DateTimeField,
  TextNumField,
} from "@mesh/common-js-react/dist/FormFields";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { SearchPrimeRates } from "./SearchPrimeRates";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Info as InfoIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
dayjs.extend(utc);

export const PrimeRateCapturer = () => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const [timezone, setTimezone] = useState<Timezone>(Timezone.SAST_TIMEZONE);
  const [formState, setFormState] = useState({
    captureRequest: new CapturePrimeRateRequest()
      .setContext(authContext.toFuture())
      .setDate(
        dayjsToProtobufTimestamp(dayjs().utc().startOf("day").set("h", -2)),
      )
      .setRate(bigNumberToDecimal(new BigNumber(10.12))),
  });
  const { financial } = useAPIContext();
  const [loading, setLoading] = useState(false);

  const captureRate = async () => {
    setLoading(true);
    try {
      await financial.primeRateRecorder.capturePrimeRate(
        formState.captureRequest,
      );
      enqueueSnackbar({
        message: "Prime Rate Captured",
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar({
        message: `Error Capturing Prime Rate: ${e}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card sx={{ width: "100%" }}>
        <CardContent sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Timezone"
              select
              value={timezone}
              onChange={(e) => setTimezone(Number(e.target.value))}
            >
              {allTimezones
                .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                .map((tz, idx) => (
                  <MenuItem key={idx} value={tz}>
                    {timezoneToString(tz)}
                  </MenuItem>
                ))}
            </TextField>
            <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
        </CardContent>
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            textAlign: "start",
          })}
        >
          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Rate Capturing
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 2fr",
              gap: 3,
              alignItems: "center",
            }}
          >
            <DateTimeField
              timezone={timezone}
              disabled={loading}
              label="Date"
              value={formState.captureRequest.getDate()}
              onChange={(newValue) =>
                setFormState({
                  captureRequest: formState.captureRequest.setDate(newValue),
                })
              }
            />
            <TextNumField
              disabled={loading}
              fullWidth
              label="Coupon Rate"
              value={formState.captureRequest.getRate()}
              onChange={(newValue: Decimal) =>
                setFormState({
                  captureRequest: formState.captureRequest.setRate(newValue),
                })
              }
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={captureRate}
                loading={loading}
                disabled={
                  viewConfiguration["Rate Sources"]?.["Prime Rate Capturer"]
                    ? false
                    : true
                }
              >
                Capture Rate
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
        <CardContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            textAlign: "start",
          })}
        >
          <Typography variant="h6" sx={{ gridColumn: "1/6" }}>
            Search Existing Prime Rates
          </Typography>
          <SearchPrimeRates timezone={timezone} />
        </CardContent>
      </Card>
    </Box>
  );
};
