import React from "react";
import { Chip, ChipProps, SxProps, useTheme } from "@mui/material";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { SubscriptionOrderState } from "james/market/SubscriptionOrder";
import { SubscriptionUnderwritingState } from "james/market/SubscriptionUnderwriting";
import camelCase from "lodash/camelCase";

export interface SubscriptionOrderBookStateChipProps extends ChipProps {
  state: SubscriptionOrderBookState | "";
}

export function SubscriptionOrderBookStateChip({
  state,
  ...props
}: SubscriptionOrderBookStateChipProps) {
  switch (state) {
    case SubscriptionOrderBookState.Pending:
      return (
        <Chip
          {...props}
          color="info"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case SubscriptionOrderBookState.Opening:
      return (
        <Chip
          {...props}
          color="warning"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case SubscriptionOrderBookState.OpeningUnderInvestigation:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case SubscriptionOrderBookState.OpeningFailed:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case SubscriptionOrderBookState.Open:
      return (
        <Chip
          {...props}
          color="info"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    case SubscriptionOrderBookState.Closed:
      return (
        <Chip
          {...props}
          color="info"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionOrderBookState.SettlementInProgress:
      return (
        <Chip
          {...props}
          color="warning"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionOrderBookState.SettlementFailed:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionOrderBookState.SettlementUnderInvestigation:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionOrderBookState.Settled:
      return (
        <Chip
          {...props}
          color="success"
          id={`subscriptionOrderBookStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );

    default:
      return <>{state}</>;
  }
}

export interface SubscriptionOrderStateChipProps {
  state: SubscriptionOrderState | "";
  chipProps?: ChipProps;
}

export const SubscriptionOrderStateChip = ({
  state,
  chipProps,
}: SubscriptionOrderStateChipProps) => {
  const theme = useTheme();

  const styleMap: { [x in SubscriptionOrderState | ""]: SxProps } = {
    [SubscriptionOrderState.SubmissionInProgress]: {
      backgroundColor: theme.palette.warning.main,
    },
    [SubscriptionOrderState.AwaitingSettlement]: {
      backgroundColor: theme.palette.info.main,
    },
    [SubscriptionOrderState.Settled]: {
      backgroundColor: theme.palette.success.main,
    },
    [SubscriptionOrderState.Failing]: {
      backgroundColor: theme.palette.error.main,
    },
    [SubscriptionOrderState.Failed]: {
      backgroundColor: theme.palette.error.main,
    },
    [SubscriptionOrderState.SubmissionUnderInvestigation]: {
      backgroundColor: theme.palette.error.main,
    },
    [SubscriptionOrderState.FailureUnderInvestigation]: {
      backgroundColor: theme.palette.error.main,
    },
    "": {
      backgroundColor: theme.palette.info.main,
    },
  };

  return (
    <Chip
      {...chipProps}
      sx={styleMap[state]}
      id={`directOrderStateChip-${camelCase(state)}-chip`}
      size="small"
      label={state}
    />
  );
};

export interface SubscriptionUnderwritingStateChipProps {
  state: SubscriptionUnderwritingState | "";
}

export function SubscriptionUnderwritingStateChip({
  state,
  ...props
}: SubscriptionUnderwritingStateChipProps) {
  switch (state) {
    case SubscriptionUnderwritingState.AwaitingFunding:
      return (
        <Chip
          {...props}
          color="info"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.FundingInProgress:
      return (
        <Chip
          {...props}
          color="warning"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.FundingUnderInvestigation:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.FundingFailed:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.AwaitingSettlement:
      return (
        <Chip
          {...props}
          color="info"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.RefundInProgress:
      return (
        <Chip
          {...props}
          color="warning"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.RefundUnderInvestigation:
      return (
        <Chip
          {...props}
          color="error"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.Settled:
      return (
        <Chip
          {...props}
          color="success"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    case SubscriptionUnderwritingState.ZeroObligation:
      return (
        <Chip
          {...props}
          color="success"
          id={`subscriptionUnderwritingStateChip-${state}-chip`}
          size={"small"}
          label={state}
        />
      );
    default:
      return <div>{state}</div>;
  }
}
