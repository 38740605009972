import config from "react-global-configuration";
import { UnitTrustStablecoin } from "./UnitTrustStablecoin";
import { Context } from "../security";
import { jsonRPCRequest } from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Amount } from "../ledger/Amount";

export type DraftUpdateUnitTrustStablecoinRequest = {
  context: Context;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type DraftUpdateUnitTrustStablecoinResponse = {
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type IncreaseUnitTrustStablecoinMaximumUnitsRequest = {
  context: Context;
  unitTrustStablecoinID: string;
  amount: Amount;
};

export type IncreaseUnitTrustStablecoinMaximumUnitsResponse = {
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type DecreaseUnitTrustStablecoinMaximumUnitsRequest = {
  context: Context;
  unitTrustStablecoinID: string;
  amount: Amount;
};

export type DecreaseUnitTrustStablecoinMaximumUnitsResponse = {
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type ChangeUnitTrustStablecoinMaturityDateRequest = {
  context: Context;
  unitTrustStablecoinID: string;
  maturityDate: string;
};

export type ChangeUnitTrustStablecoinMaturityDateResponse = {
  unitTrustStablecoin: UnitTrustStablecoin;
};

export const UnitTrustStablecoinUpdater = {
  serviceProvider: "financial-UnitTrustStablecoinUpdater",
  async DraftUpdateUnitTrustStablecoin(
    request: DraftUpdateUnitTrustStablecoinRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinUpdater.serviceProvider}.DraftUpdateUnitTrustStablecoin`,
        request,
      },
      { additionalHeaders },
    );
    return {
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async IncreaseUnitTrustStablecoinMaximumUnits(
    request: IncreaseUnitTrustStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<IncreaseUnitTrustStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinUpdater.serviceProvider}.IncreaseUnitTrustStablecoinMaximumUnits`,
        request,
      },
      { additionalHeaders },
    );
    return {
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async DecreaseUnitTrustStablecoinMaximumUnits(
    request: DecreaseUnitTrustStablecoinMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<DecreaseUnitTrustStablecoinMaximumUnitsResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinUpdater.serviceProvider}.DecreaseUnitTrustStablecoinMaximumUnits`,
        request,
      },
      { additionalHeaders },
    );
    return {
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async ChangeUnitTrustStablecoinMaturityDate(
    request: ChangeUnitTrustStablecoinMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeUnitTrustStablecoinMaturityDateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinUpdater.serviceProvider}.ChangeUnitTrustStablecoinMaturityDate`,
        request,
      },
      { additionalHeaders },
    );
    return {
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
};
