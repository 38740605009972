import React from "react";
import { ListingContextProvider } from "./Context";
import { ListingForm } from "./components";

export const Listing = () => {
  return (
    <ListingContextProvider system={false}>
      <ListingForm />
    </ListingContextProvider>
  );
};
