import dayjs from "dayjs";
import { Document } from "james/document";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { DigitalInstrument } from "./DigitalInstrument";
import { AuditEntry } from "../audit";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { FinancialInstrumentState } from "./InstrumentState";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export const DigitalETFTypeName = "mesh::financial/DigitalETF";

export enum DigitalETFState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllDigitalETFStates: DigitalETFState[] = [
  DigitalETFState.Draft,
  DigitalETFState.PreIssued,
  DigitalETFState.Issued,
  DigitalETFState.Matured,
  DigitalETFState.Cancelled,
  DigitalETFState.Deleted,
];

export enum DigitalETFAction {
  //
  // DigitalETFCreator Actions
  //
  New,

  //
  // DigitalETFStateChanger Actions
  //
  MarkDeleted,
  Issue,
  PreIssue,
  MarkIssued,
  Cancel,
  Mature,

  //
  // DigitalETFUpdater Actions
  //
  DraftUpdate,
  IncreaseMaximumUnits,
  DecreaseMaximumUnits,
  ChangeMaturityDate,
  ChangeHoldings,
  ChangeSectorAllocations,
  ChangeCountryAllocations,
  ChangeAnnualPerformanceLog,
  ChangeSupportingDocuments,
}

export class DigitalETF implements DigitalInstrument {
  public ["@type"]: string = DigitalETFTypeName;

  public id = "";

  public ownerID = "";

  public state: DigitalETFState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public name = "";

  public shortName = "";

  public assetClass: AssetClassDEPRECATED | "" = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public token: Token = new Token();

  public maximumUnits: Amount = new Amount();

  public valuationToken: Token = new Token();

  public supportingDocuments: Document[] = [];

  public investorProfile: InvestorProfile | "" = "";

  public investorProfileDescription = "";

  public riskProfile: InstrumentRiskProfile | "" = "";

  public riskProfileDescription = "";

  public holdings: Holding[] = [];

  public sectorAllocations: SectorAllocation[] = [];

  public countryAllocations: CountryAllocation[] = [];

  public annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[] = [];

  constructor(digitalETF?: DigitalETF) {
    if (!digitalETF) {
      return;
    }
    this.id = digitalETF.id;
    this.ownerID = digitalETF.ownerID;
    this.state = digitalETF.state;
    this.auditEntry = new AuditEntry(digitalETF.auditEntry);
    this.name = digitalETF.name;
    this.shortName = digitalETF.shortName;
    this.assetClass = digitalETF.assetClass;
    this.issueDate = digitalETF.issueDate;
    this.maturityDate = digitalETF.maturityDate;
    this.token = new Token(digitalETF.token);
    this.maximumUnits = new Amount(digitalETF.maximumUnits);
    this.valuationToken = new Token(digitalETF.valuationToken);
    this.supportingDocuments = digitalETF.supportingDocuments.map(
      (d) => new Document(d),
    );
    this.investorProfile = digitalETF.investorProfile;
    this.investorProfileDescription = digitalETF.investorProfileDescription;
    this.riskProfile = digitalETF.riskProfile;
    this.riskProfileDescription = digitalETF.riskProfileDescription;
    this.holdings = digitalETF.holdings.map((h) => new Holding(h));
    this.sectorAllocations = digitalETF.sectorAllocations.map(
      (s) => new SectorAllocation(s),
    );
    this.countryAllocations = digitalETF.countryAllocations.map(
      (c) => new CountryAllocation(c),
    );
    this.annualPerformanceLog = digitalETF.annualPerformanceLog.map(
      (ple) => new InstrumentAnnualPerformanceLogEntry(ple),
    );
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  digitalInstrumentValuationToken(): Token {
    return this.valuationToken;
  }

  digitalInstrumentMaximumUnits(): Amount {
    return this.maximumUnits;
  }

  instrumentID(): string {
    return this.id;
  }

  instrumentState(): FinancialInstrumentState {
    return this.state;
  }

  instrumentOwnerID(): string {
    return this.ownerID;
  }

  instrumentIssueDate(): string {
    return this.issueDate;
  }

  instrumentMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentName(): string {
    return this.name;
  }

  instrumentAssetClass(): AssetClassDEPRECATED | "" {
    return this.assetClass;
  }

  instrumentRiskProfile(): InstrumentRiskProfile | "" {
    return this.riskProfile;
  }

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[] {
    return this.annualPerformanceLog;
  }

  instrumentInvestorProfile(): InvestorProfile | "" {
    return this.investorProfile;
  }

  instrumentSupportingDocuments(): Document[] {
    return this.supportingDocuments;
  }

  digitalInstrumentIssuePrice(): Amount {
    // TODO: return price here once it is moved back to the instruments
    return new Amount();
  }
}

export function getPotentialNextDigitalETFActions(
  digitalETFState: DigitalETFState | "",
): DigitalETFAction[] {
  switch (digitalETFState) {
    case DigitalETFState.Draft: {
      return [
        DigitalETFAction.MarkDeleted,
        DigitalETFAction.DraftUpdate,
        DigitalETFAction.Issue,
        DigitalETFAction.PreIssue,
      ];
    }

    case DigitalETFState.PreIssued: {
      return [
        DigitalETFAction.MarkIssued,
        DigitalETFAction.Cancel,
        DigitalETFAction.IncreaseMaximumUnits,
        DigitalETFAction.DecreaseMaximumUnits,
        DigitalETFAction.ChangeMaturityDate,
        DigitalETFAction.ChangeHoldings,
        DigitalETFAction.ChangeSectorAllocations,
        DigitalETFAction.ChangeCountryAllocations,
        DigitalETFAction.ChangeAnnualPerformanceLog,
        DigitalETFAction.ChangeSupportingDocuments,
      ];
    }

    case DigitalETFState.Issued:
      return [
        DigitalETFAction.Cancel,
        DigitalETFAction.ChangeHoldings,
        DigitalETFAction.ChangeSectorAllocations,
        DigitalETFAction.ChangeCountryAllocations,
        DigitalETFAction.ChangeAnnualPerformanceLog,
        DigitalETFAction.ChangeSupportingDocuments,
        DigitalETFAction.Mature,
      ];

    case DigitalETFState.Matured:
      return [];

    case DigitalETFState.Cancelled:
      return [];

    case DigitalETFState.Deleted:
      return [];

    default:
      throw new TypeError(`invalid DigitalETFState: '${digitalETFState}'`);
  }
}
