import React from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  InteractionAction,
  InteractionType,
  InteractionDriver,
  DataLinkInfoType,
  DataComponentInfo,
} from "const/gtm";
import background from "./background.jpg";

export const ConvergencePartnerBanner = () => {
  return (
    <Box
      id="marketplace_banner_convergence_partner_Banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        backgroundPosition: "60%",
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "convergence partner",
        component_driver: "showcase",
      } as DataComponentInfo)}
      data-testid="marketplace_banner_convergence_partner_Banner"
    >
      <Box
        sx={{
          maxWidth: 699,
          ml: {
            lg: "120px",
            md: "80px",
            sm: "60px",
          },
          mr: "60px",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: 18,
            color: theme.palette.primary.main,
          })}
        >
          Mesh News
        </Typography>
        <Typography
          fontSize={28}
          letterSpacing={0}
          lineHeight={"32px"}
          mb={2}
          mt={1}
        >
          Convergence Partners’ Digital Infrastructure fund invests in the
          Future of Capital Markets.
        </Typography>
        <Button
          id="convergencePartnerBanner-readMore-button"
          href="https://www.mesh.trade/mesh-parent-42markets-group-secures-investment-from-convergence-partners-digital-infrastructure-fund/"
          variant="contained"
          color="primary"
          rel="noopener noreferrer"
          target="_blank"
          data-link-info={JSON.stringify({
            content_interaction_id: "hero-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "read more",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
        >
          Read More
        </Button>
      </Box>
    </Box>
  );
};
