import { saveAs } from "file-saver";
import { read, writeFile } from "xlsx";

export function download(url: string, name: string) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";

  xhr.onload = () => {
    saveAs(xhr.response, name);
  };

  xhr.onerror = () => {
    console.error("could not download file");
  };

  xhr.send();
}

interface DownloadExcelFileProps {
  // encoded string
  encodedString: string;
  // name of the file to save as
  fileName: string;
  // extension of the file name
  fileExt: string;
  // is account statement or not
  isAccountStatement?: boolean;
}

export function downloadExcelFile({
  encodedString,
  fileName,
  fileExt,
  isAccountStatement,
}: DownloadExcelFileProps) {
  const decodedString = atob(encodedString);

  const uint8Array = new Uint8Array(decodedString.length);
  for (let i = 0; i < decodedString.length; ++i) {
    uint8Array[i] = decodedString.charCodeAt(i);
  }

  // todo - revert back to previous download method post excelize v2.8.0 release
  // file generated using excelize v2.7.1 is corrupted in order to fix this we are using xlsx library
  // Load the Excel file using XLSX
  const workbook = read(uint8Array, { type: "array" });

  // Set column width only for account statement
  if (isAccountStatement) {
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    worksheet["!cols"] = [
      { width: 20 },
      { width: 70 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ]; // set column width
  }

  writeFile(workbook, `${fileName}.${fileExt}`);
}
