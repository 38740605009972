import config from "react-global-configuration";
import { Context } from "../security";
import { UnitTrustStablecoin } from "./UnitTrustStablecoin";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "../../utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Amount } from "../ledger/Amount";
import { Token } from "../ledger";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { RatingAgency } from "./RatingAgency";
import {
  InstrumentManagementFee,
  InstrumentManagementFeeFrequency,
} from "./InstrumentManagementFee";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { Document } from "../document";
import { UnitTrust } from "./UnitTrust";

export type CoordinateCreateNewUnitTrustStablecoinRequest = {
  context: Context;
  ownerID: string;
  unitTrustFields: {
    name: string;
    isin: string;
    assetClass: AssetClassDEPRECATED | "";
    issueDate: string;
    maturityDate: string;
    issuerName: string;
    issuerRatingAgency: RatingAgency | "";
    issuerCreditRating: string;
    issuerManagementFee: InstrumentManagementFee;
    issuerManagementFeeFrequency: InstrumentManagementFeeFrequency;
    supportingDocuments: Document[];
    investorProfile: InvestorProfile | "";
    investorProfileDescription: string;
    riskProfile: InstrumentRiskProfile | "";
    riskProfileDescription: string;
    holdings: Holding[];
    sectorAllocations: SectorAllocation[];
    countryAllocations: CountryAllocation[];
    annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
  };
  stablecoinFields: {
    issueDate: string;
    maturityDate: string;
    maximumUnits: Amount;
    valuationToken: Token;
  };
};

export type CoordinateCreateNewUnitTrustStablecoinResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinateIssueUnitTrustStablecoinRequest = {
  context: Context;
  unitTrustStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinateIssueUnitTrustStablecoinResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinatePreIssueUnitTrustStablecoinRequest = {
  context: Context;
  unitTrustStablecoinID: string;
  issuanceAccountID: string;
};

export type CoordinatePreIssueUnitTrustStablecoinResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinateCancelUnitTrustStablecoinRequest = {
  context: Context;
  unitTrustStablecoinID: string;
};

export type CoordinateCancelUnitTrustStablecoinResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinateMatureUnitTrustStablecoinRequest = {
  context: Context;
  unitTrustStablecoinID: string;
};

export type CoordinateMatureUnitTrustStablecoinResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinateMarkUnitTrustStablecoinIssuedRequest = {
  context: Context;
  unitTrustStablecoinID: string;
};

export type CoordinateMarkUnitTrustStablecoinIssuedResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export type CoordinateMarkUnitTrustStablecoinDeletedRequest = {
  context: Context;
  unitTrustStablecoinID: string;
};

export type CoordinateMarkUnitTrustStablecoinDeletedResponse = {
  unitTrust: UnitTrust;
  unitTrustStablecoin: UnitTrustStablecoin;
};

export const UnitTrustStablecoinStateChangerCoordinator = {
  serviceProvider: "financial-UnitTrustStablecoinStateChangerCoordinator",
  async CoordinateCreateNewUnitTrustStablecoin(
    request: CoordinateCreateNewUnitTrustStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateCreateNewUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateCreateNewUnitTrustStablecoin`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinateIssueUnitTrustStablecoin(
    request: CoordinateIssueUnitTrustStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateIssueUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateIssueUnitTrustStablecoin`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinatePreIssueUnitTrustStablecoin(
    request: CoordinatePreIssueUnitTrustStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinatePreIssueUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinatePreIssueUnitTrustStablecoin`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinateCancelUnitTrustStablecoin(
    request: CoordinateCancelUnitTrustStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateCancelUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateCancelUnitTrustStablecoin`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinateMatureUnitTrustStablecoin(
    request: CoordinateMatureUnitTrustStablecoinRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMatureUnitTrustStablecoinResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMatureUnitTrustStablecoin`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinateMarkUnitTrustStablecoinIssued(
    request: CoordinateMarkUnitTrustStablecoinIssuedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkUnitTrustStablecoinIssuedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkUnitTrustStablecoinIssued`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
  async CoordinateMarkUnitTrustStablecoinDeleted(
    request: CoordinateMarkUnitTrustStablecoinDeletedRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<CoordinateMarkUnitTrustStablecoinDeletedResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${UnitTrustStablecoinStateChangerCoordinator.serviceProvider}.CoordinateMarkUnitTrustStablecoinDeleted`,
        request,
      },
      opts,
    );
    return {
      unitTrust: new UnitTrust(response.unitTrust),
      unitTrustStablecoin: new UnitTrustStablecoin(
        response.unitTrustStablecoin,
      ),
    };
  },
};
