import dayjs from "dayjs";
import { AuditEntry } from "james/audit";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { InstrumentStablecoin } from "./InstrumentStablecoin";
import { FinancialInstrumentStablecoinState } from "./InstrumentStablecoinState";

export const ETFStablecoinTypeName = "mesh::financial/ETFStablecoin";

export enum ETFStablecoinState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllETFStablecoinStates: ETFStablecoinState[] = [
  ETFStablecoinState.Draft,
  ETFStablecoinState.PreIssued,
  ETFStablecoinState.Issued,
  ETFStablecoinState.Matured,
  ETFStablecoinState.Cancelled,
  ETFStablecoinState.Deleted,
];

export enum ETFStablecoinAction {
  //
  // ETFStablecoinCreator Actions
  //
  New,

  //
  // ETFStablecoinStateChanger Actions
  //
  MarkDeleted,
  Issue,
  PreIssue,
  MarkIssued,
  Cancel,
  Mature,

  //
  // ETFStablecoinUpdater Actions
  //
  DraftUpdate,
  IncreaseMaximumUnits,
  DecreaseMaximumUnits,
  ChangeMaturityDate,
}

export class ETFStablecoin implements InstrumentStablecoin {
  public ["@type"]: string = ETFStablecoinTypeName;

  public id = "";

  public ownerID = "";

  public state: ETFStablecoinState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public shortName = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public token: Token = new Token();

  public instrumentID = "";

  public valuationToken: Token = new Token();

  public maximumUnits: Amount = new Amount();

  constructor(etfStablecoin?: ETFStablecoin) {
    if (!etfStablecoin) {
      return;
    }
    this.id = etfStablecoin.id;
    this.ownerID = etfStablecoin.ownerID;
    this.state = etfStablecoin.state;
    this.auditEntry = new AuditEntry(etfStablecoin.auditEntry);
    this.shortName = etfStablecoin.shortName;
    this.issueDate = etfStablecoin.issueDate;
    this.maturityDate = etfStablecoin.maturityDate;
    this.token = new Token(etfStablecoin.token);
    this.instrumentID = etfStablecoin.instrumentID;
    this.valuationToken = new Token(etfStablecoin.valuationToken);
    this.maximumUnits = new Amount(etfStablecoin.maximumUnits);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.shortName;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  instrumentStablecoinInstrumentID(): string {
    return this.instrumentID;
  }

  instrumentStablecoinValuationToken(): Token {
    return this.valuationToken;
  }

  instrumentStablecoinMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentStablecoinMaximumUnits(): Amount {
    return this.maximumUnits;
  }

  instrumentStablecoinState(): FinancialInstrumentStablecoinState {
    return this.state;
  }
}

export function getPotentialNextETFStablecoinActions(
  etfStablecoinState: ETFStablecoinState | "",
): ETFStablecoinAction[] {
  switch (etfStablecoinState) {
    case ETFStablecoinState.Draft: {
      return [
        ETFStablecoinAction.MarkDeleted,
        ETFStablecoinAction.DraftUpdate,
        ETFStablecoinAction.Issue,
        ETFStablecoinAction.PreIssue,
      ];
    }

    case ETFStablecoinState.PreIssued: {
      return [
        ETFStablecoinAction.MarkIssued,
        ETFStablecoinAction.Cancel,
        ETFStablecoinAction.IncreaseMaximumUnits,
        ETFStablecoinAction.DecreaseMaximumUnits,
        ETFStablecoinAction.ChangeMaturityDate,
      ];
    }

    case ETFStablecoinState.Issued:
      return [ETFStablecoinAction.Cancel, ETFStablecoinAction.Mature];

    case ETFStablecoinState.Matured:
      return [];

    case ETFStablecoinState.Cancelled:
      return [];

    case ETFStablecoinState.Deleted:
      return [];

    default:
      throw new TypeError(
        `invalid ETFStablecoinState: '${etfStablecoinState}'`,
      );
  }
}
