import { Box } from "@mui/system";
import { allPrimaryMarketMechanismTypes, MechanismType } from "james/market";
import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { TextField, TextNumField } from "components/FormFields";
import { SubscriptionForm, PrimaryMarket } from "./components";
import { useListingContext } from "../../Context";
import { AllLedgerNetworks, Token } from "james/ledger";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { unitCategoryToString } from "@mesh/common-js/dist/financial";

enum TabOption {
  Listing = "Listing",
  Subscription = "Subscription",
}

export const ListingForm = () => {
  const {
    apiCallInProgress,
    formData,
    formDataUpdater,
    formDataValidationResult,
    initialised,
    initialisationError,
    clearInitialisationError,
    ledgerTokenViewModels,
    performListingCreation,
    subscriptionOrderBookViewModel,
  } = useListingContext();

  // tabs will be shown after listing is created
  const [selectedTab, setSelectedTab] = useState<TabOption>(TabOption.Listing);

  // the listing already exists if the id is not blank
  const listingAlreadyExists = !!formData.listing.id;

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Listing"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>Something went wrong while initialsing.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Getting things ready for you..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  // this should never happen if form data is correctly initialised
  if (formData.listing.marketMechanisms.length === 0) {
    return <Box>Market Mechanisms Not Set.</Box>;
  }

  // assume that the first market mechanism is the primary market mechanism
  const primaryMarketMechanism = formData.listing.marketMechanisms[0];
  if (primaryMarketMechanism.quoteParameters.length === 0) {
    return <Box>Market Mechanism Quote Parameters Not Set.</Box>;
  }

  const listingFormContent = (
    <CardContent
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      })}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(1),
            alignItems: "center",
          })}
        >
          <Typography variant="h4">Primary Market Listing</Typography>
          <Typography>of</Typography>
          <Typography variant="h6">
            {`${formData.smartInstrumentToList.getName()} - ${formData.smartInstrumentToList.getToken()?.getCode()}`}
          </Typography>
        </Box>
        {!listingAlreadyExists && (
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              gap: theme.spacing(1),
              alignItems: "center",
            })}
          >
            <Tooltip
              placement="top"
              title={formDataValidationResult.valid ? "" : "Fix errors"}
            >
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    apiCallInProgress || !formDataValidationResult.valid
                  }
                  onClick={performListingCreation}
                >
                  List
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          columnGap: theme.spacing(1),
        })}
      >
        <Typography variant="h6">Network of Exchange:</Typography>
        <TextField
          fullWidth
          disabled
          sx={{ maxWidth: 320 }}
          id={"primaryMarketListing-exchangeNetwork-selectField"}
          select
          value={formData.listing.exchangeNetwork}
        >
          {AllLedgerNetworks.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            );
          })}
        </TextField>

        <Typography variant="h6">Primary Market Mechanism:</Typography>
        <TextField
          fullWidth
          disabled
          sx={{ maxWidth: 320 }}
          id={"primaryMarketListing-mechanismType-selectField"}
          select
          value={primaryMarketMechanism.type}
        >
          {allPrimaryMarketMechanismTypes.map((v) => {
            return (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <Typography variant="h6">Primary Market Sale Parameters</Typography>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <TextField
          id="primaryMarketListing-marketMechanism-token-select"
          readOnly={listingAlreadyExists}
          disabled={apiCallInProgress}
          fullWidth
          sx={{ maxWidth: 320 }}
          label="Token to Raise"
          select
          value={
            ledgerTokenViewModels.find((cst) =>
              cst.token.isEqualTo(
                primaryMarketMechanism.quoteParameters[0].quoteToken,
              ),
            )?.id ?? ""
          }
          onChange={(e) => {
            const newQuoteToken =
              ledgerTokenViewModels.find((cst) => cst.id === e.target.value)
                ?.token ?? new Token();

            // update market mechanism
            primaryMarketMechanism.quoteParameters[0].quoteToken =
              newQuoteToken;
            formData.listing.marketMechanisms[0] = primaryMarketMechanism;
            formDataUpdater.mechanisms(formData.listing.marketMechanisms);

            // update subscription order book
            formData.subscriptionOrderBook.unitPrice =
              newQuoteToken.newAmountOf(
                formData.subscriptionOrderBook.unitPrice.value,
              );
            formData.subscriptionOrderBook.subscriptionAmount =
              newQuoteToken.newAmountOf(
                formData.subscriptionOrderBook.subscriptionAmount.value,
              );
            formData.subscriptionOrderBook.overSubscriptionAmount =
              newQuoteToken.newAmountOf(
                formData.subscriptionOrderBook.overSubscriptionAmount.value,
              );
            formDataUpdater.subscriptionOrderBook(
              formData.subscriptionOrderBook,
            );
          }}
          error={!!formDataValidationResult.fieldValidations.nominalAmountToken}
          helperText={
            formDataValidationResult.fieldValidations.nominalAmountToken
          }
        >
          {ledgerTokenViewModels.map((cst, idx) => (
            <MenuItem key={idx} value={cst.id}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: theme.spacing(1),
                })}
              >
                <TokenIconViewUpload
                  disableChangeIcon
                  size={23}
                  token={cst.token}
                />
                <Typography children={`${cst.token.code} - ${cst.name}`} />
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
          alignItems: "center",
        })}
      >
        <TextNumField
          id={
            "primaryMarketListing-marketMechanism-minimumDealSize-textNumField"
          }
          readOnly={listingAlreadyExists}
          disabled={apiCallInProgress}
          fullWidth
          noDecimalPlaces={7}
          label={"Minimum Deal Size"}
          disallowNegative
          value={
            primaryMarketMechanism.quoteParameters[0].minimumDealSize.value
          }
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            primaryMarketMechanism.quoteParameters[0].minimumDealSize =
              primaryMarketMechanism.quoteParameters[0].minimumDealSize.setValue(
                e.target.value,
              );
            formData.listing.marketMechanisms[0] = primaryMarketMechanism;
            formDataUpdater.mechanisms(formData.listing.marketMechanisms);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {
                  primaryMarketMechanism.quoteParameters[0].minimumDealSize
                    .token.code
                }
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Minimum number of ${unitCategoryToString(formData.smartInstrumentToList.getUnitcategory())}s to buy.`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!formDataValidationResult.fieldValidations.minimumDealSize}
          helperText={formDataValidationResult.fieldValidations.minimumDealSize}
        />
        <TextNumField
          id={
            "primaryMarketListing-marketMechanism-maximumDealSize-textNumField"
          }
          readOnly={listingAlreadyExists}
          disabled={apiCallInProgress}
          fullWidth
          noDecimalPlaces={7}
          label={"Maximum Deal Size"}
          disallowNegative
          value={
            primaryMarketMechanism.quoteParameters[0].maximumDealSize.value
          }
          sx={{ maxWidth: 320 }}
          onChange={(e) => {
            primaryMarketMechanism.quoteParameters[0].maximumDealSize =
              primaryMarketMechanism.quoteParameters[0].maximumDealSize.setValue(
                e.target.value,
              );
            formData.listing.marketMechanisms[0] = primaryMarketMechanism;
            formDataUpdater.mechanisms(formData.listing.marketMechanisms);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
                {
                  primaryMarketMechanism.quoteParameters[0].maximumDealSize
                    .token.code
                }
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                children={
                  <Tooltip
                    placement="top"
                    title={`Maximum number of ${unitCategoryToString(formData.smartInstrumentToList.getUnitcategory())}s to buy.`}
                  >
                    <InfoIcon
                      sx={(theme) => ({
                        cursor: "pointer",
                        color: theme.palette.text.secondary,
                        width: "16px",
                        height: "16px",
                        mb: 0.5,
                      })}
                    />
                  </Tooltip>
                }
              />
            ),
          }}
          error={!!formDataValidationResult.fieldValidations.maximumDealSize}
          helperText={formDataValidationResult.fieldValidations.maximumDealSize}
        />
      </Box>
      {(() => {
        switch (primaryMarketMechanism.type) {
          case MechanismType.Subscription:
            return (
              <SubscriptionForm
                validationResult={formDataValidationResult}
                readOnly={listingAlreadyExists}
                disabled={apiCallInProgress}
                unitCategory={formData.smartInstrumentToList.getUnitcategory()}
                subscriptionOrderBook={formData.subscriptionOrderBook}
                onChange={(updatedSubscriptionOrderBook) =>
                  formDataUpdater.subscriptionOrderBook(
                    updatedSubscriptionOrderBook,
                  )
                }
              />
            );

          default:
            return "Unsupported Primary Market Mechanism.";
        }
      })()}
    </CardContent>
  );

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      })}
    >
      <Breadcrumbs
        sx={(theme) => ({ padding: theme.spacing(0.5) })}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="inherit" href="/builder">
          Issuance Hub
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/builder/smart-instruments/builder/table"
        >
          Primary Market Listings
        </Link>
        <Typography color="text.primary">
          {formData.smartInstrumentToList.getName() ?? "No Name"}
        </Typography>
      </Breadcrumbs>
      <Card>
        {(() => {
          if (subscriptionOrderBookViewModel) {
            return (
              <>
                <Paper
                  sx={(theme) => ({
                    backgroundColor: theme.palette.custom.midnight,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  })}
                >
                  <Tabs value={selectedTab}>
                    <Tab
                      onClick={() => setSelectedTab(TabOption.Listing)}
                      value={TabOption.Listing}
                      label={TabOption.Listing}
                    />
                    <Tab
                      onClick={() => setSelectedTab(TabOption.Subscription)}
                      value={TabOption.Subscription}
                      label={TabOption.Subscription}
                    />
                  </Tabs>
                </Paper>
                <Box
                  className={"meshScroll"}
                  sx={{
                    height: window.innerHeight - 180,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {(() => {
                    switch (selectedTab) {
                      case TabOption.Listing:
                        return listingFormContent;

                      case TabOption.Subscription:
                        return (
                          <PrimaryMarket
                            system={false}
                            subscriptionOrderBook={
                              subscriptionOrderBookViewModel
                            }
                            reload={() => null}
                            underWriterView={false}
                          />
                        );

                      default:
                        return null;
                    }
                  })()}
                </Box>
              </>
            );
          } else {
            return listingFormContent;
          }
        })()}
      </Card>
    </Box>
  );
};
