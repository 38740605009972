import { Client } from "./Client";
import { NotFoundError, Horizon } from "stellar-sdk";
import BigNumber from "bignumber.js";
import * as Sentry from "@sentry/react";
import {
  Account,
  AuthFlags,
  Balance,
  Signatory,
  SignatoryType,
} from "./Account";
import { StellarNetwork } from "./Network";
import { Amount } from "pkgTemp/ledger";
import { primitiveAssetToToken, tokenFromAssetString } from "./token";
import { NewLiquidityPoolToken } from "./liquidityPoolToken";
import { LedgerAccountState } from "../ledger/Account";

export type PopulateAccountWithLedgerDetailsRequest = {
  account: Account;
};

export type PopulateAccountWithLedgerDetailsResponse = {
  account: Account;
};

export type NewStellarAccountLedgerDetailsPopulatorProps = {
  client: Client;
};

export type NewStellarAccountLedgerDetailsPopulator = {
  client: Client;
};

export class StellarAccountLedgerDetailsPopulator {
  private readonly stellarClient: Client;

  constructor(props: NewStellarAccountLedgerDetailsPopulator) {
    this.stellarClient = props.client;
  }

  async PopulateAccountWithLedgerDetails(
    request: PopulateAccountWithLedgerDetailsRequest,
  ): Promise<PopulateAccountWithLedgerDetailsResponse> {
    //
    // retrieve the account from stellar
    //

    const account = request.account;

    // clear current balance that may have been set
    account.balances = [];

    let stellarAccount: undefined | Horizon.ServerApi.AccountRecord = undefined;
    try {
      stellarAccount = await this.stellarClient.accountRecord(account.ledgerID);
    } catch (e) {
      if (e instanceof NotFoundError) {
        // we assume the account is closed
        account.state = LedgerAccountState.Closed;
        return { account };
      }
      const err = e as Error;

      // throw error
      Sentry.captureException(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
      throw new Error(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }

    // set the account state to open
    account.state = LedgerAccountState.Open;

    //
    // populate the account balance
    //
    for (const b of stellarAccount.balances) {
      switch (b.asset_type) {
        case "native":
          {
            const token = primitiveAssetToToken(
              {
                asset_code: "XLM",
                asset_issuer: this.stellarClient.network,
                asset_type: b.asset_type,
              },
              this.stellarClient.network as StellarNetwork,
            );

            account.balances.push(
              new Balance({
                amount: token.newAmountOf(new BigNumber(b.balance)),
                limit: new Amount(),
                buyingLiabilities: token.newAmountOf(
                  new BigNumber(b.buying_liabilities),
                ),
                sellingLiabilities: token.newAmountOf(
                  new BigNumber(b.selling_liabilities),
                ),
              }),
            );
          }
          break;
        case "liquidity_pool_shares":
          try {
            const liquidityPoolRecordResponse =
              await this.stellarClient.liquidityPoolRecord(b.liquidity_pool_id);

            const tokenA = tokenFromAssetString(
              liquidityPoolRecordResponse.reserves[0].asset,
              this.stellarClient.network as StellarNetwork,
            );

            const tokenB = tokenFromAssetString(
              liquidityPoolRecordResponse.reserves[1].asset,
              this.stellarClient.network as StellarNetwork,
            );

            const token = NewLiquidityPoolToken(tokenA, tokenB);

            account.balances.push(
              new Balance({
                amount: token.newAmountOf(b.balance),
                limit: token.newAmountOf(b.limit),
                buyingLiabilities: token.newAmountOf("0"),
                sellingLiabilities: token.newAmountOf("0"),
              }),
            );
          } catch (e) {
            const err = e as Error;
            Sentry.captureException(
              `could not determine liquidity pool shares balance: ${
                err.message ? err.message : err.toString()
              }`,
            );
            throw new Error(
              `could not determine liquidity pool shares balance: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
          break;
        default: {
          const token = primitiveAssetToToken(
            {
              asset_code: b.asset_code,
              asset_issuer: b.asset_issuer,
              asset_type: b.asset_type,
            },
            this.stellarClient.network as StellarNetwork,
          );

          account.balances.push(
            new Balance({
              amount: token.newAmountOf(new BigNumber(b.balance)),
              limit: token.newAmountOf(new BigNumber(b.limit)),
              buyingLiabilities: token.newAmountOf(
                new BigNumber(b.buying_liabilities),
              ),
              sellingLiabilities: token.newAmountOf(
                new BigNumber(b.selling_liabilities),
              ),
            }),
          );
        }
      }
    }

    //
    // populate the account signatory details
    //
    for (const s of stellarAccount.signers) {
      account.signatories.push(
        new Signatory({
          type: s.type as SignatoryType,
          key: s.key,
          weight: s.weight,
        }),
      );
    }

    //
    // populate the auth flags
    //
    account.authFlags = new AuthFlags({
      authImmutable: stellarAccount.flags.auth_immutable,
      authRevocable: stellarAccount.flags.auth_revocable,
      authRequired: stellarAccount.flags.auth_required,
    });

    return { account };
  }
}
