import { Query as FutureQuery } from "@mesh/common-js/dist/search/query_pb";
import {
  Sorting as FutureSorting,
  SortingOrder as FutureSortingOrder,
} from "@mesh/common-js/dist/search/sorting_pb";

export interface SortingType {
  field: string;
  sortOrder: "asc" | "desc";
}

function NewSorting(field: string, sortOrder: "asc" | "desc"): SortingType {
  return {
    field,
    sortOrder,
  };
}

export type NewQueryProps = {
  limit: number;
  offset: number;
  sorting: SortingType[];
};
export class Query {
  public limit: number = 0;

  public offset: number = 0;

  public sorting: SortingType[] = [];

  static fromFutureQuery(q?: FutureQuery): Query {
    if (!q) {
      return new Query();
    }
    return new Query({
      limit: q.getLimit(),
      offset: q.getOffset(),
      sorting: q
        .getSortingList()
        .map((ns) =>
          NewSorting(
            ns.getField(),
            ns.getOrder() === FutureSortingOrder.ASC_SORTING_ORDER
              ? "asc"
              : "desc",
          ),
        ),
    });
  }

  toFutureQuery(): FutureQuery {
    return new FutureQuery()
      .setLimit(this.limit)
      .setOffset(this.offset)
      .setSortingList(
        this.sorting.map((s) => {
          let ns = new FutureSorting().setField(s.field);

          switch (s.sortOrder) {
            case "asc":
              ns = ns.setOrder(FutureSortingOrder.ASC_SORTING_ORDER);
              break;

            case "desc":
              ns = ns.setOrder(FutureSortingOrder.DESC_SORTING_ORDER);
              break;
          }

          return ns;
        }),
      );
  }

  constructor(query?: Query | NewQueryProps) {
    if (!query) {
      return;
    }
    this.limit = query.limit;
    this.offset = query.offset;
    this.sorting = query.sorting.map((s) => NewSorting(s.field, s.sortOrder));
  }
}

export { NewSorting };
