import { Box, IconButton, Tooltip, Typography, alpha } from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import {
  DataTable,
  RowType,
} from "@mesh/common-js-react/dist/Tables/DataTable";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import {
  FaceOutlined as FaceIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { Model as FinancialPaymentViewModel } from "@mesh/common-js/dist/views/financialPaymentView/model_pb";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { PaymentStateChip } from "views/InstrumentBuilder/Payments/components/StateChip";

export type PaymentsProps = {
  height: number;
};

export const Payments = (props: PaymentsProps) => {
  const { formData, payments } = useBuilderContext();

  const paymentColumns: RowType<
    FinancialPaymentViewModel,
    Omit<
      FinancialPaymentViewModel.AsObject & { actions: unknown },
      | "id"
      | "ownerid"
      | "paymentid"
      | "smartinstrumentid"
      | "smartinstrumentname"
      | "triggerid"
      | "holdingaccountledgeraccountid"
      | "stateresolutioncount"
      | "timezone"
      | "paymenttriggerfixed"
    >
  > = useMemo(
    () => ({
      number: {
        title: "Number",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return rowData.getNumber();
        },
      },
      date: {
        title: "Date",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return protobufTimestampToDayjs(rowData.getDate() ?? new Timestamp())
            .tz(timezoneToString(rowData.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return assetflowCategoryToString(rowData.getCategory());
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getAmount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
      fundedamount: {
        title: "Funded",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getFundedamount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
      refundamount: {
        title: "Refund",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(
              rowData.getRefundamount()?.getValue() ?? new Decimal(),
            ),
          )}`;
        },
      },
      state: {
        title: "State",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return <PaymentStateChip state={rowData.getState()} />;
        },
      },
      actions: {
        title: "",
        renderCell: (rowData: FinancialPaymentViewModel) => {
          return (
            <Tooltip placement="top" title="View payment detail in new tab">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `${
                      window.location.origin
                    }/builder/payments/view?id=${rowData.getPaymentid()}`,
                    "_blank",
                  );
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!payments.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Payments</i> here after creating at least one
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height - 50}
      data={payments}
      columns={paymentColumns}
    />
  );
};
