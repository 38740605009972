import { AllDigitalETFStates, DigitalETFState } from "./DigitalETF";
import { AllETFStates, ETFState } from "./ETF";
import { AllDigitalETNStates, DigitalETNState } from "./DigitalETN";
import { AllETNStates, ETNState } from "./ETN";
import { AllUnitTrustStates, UnitTrustState } from "./UnitTrust";

export const DeletedFinancialInstrumentState = "Deleted";

export type FinancialInstrumentState =
  | ""
  | DigitalETFState
  | ETFState
  | DigitalETNState
  | ETNState
  | UnitTrustState;

export const AllFinancialInstrumentStates: FinancialInstrumentState[] = [
  ...AllDigitalETFStates,
  ...AllETFStates,
  ...AllDigitalETNStates,
  ...AllETNStates,
  ...AllUnitTrustStates,
].filter((value, index, self) => self.indexOf(value) === index);
