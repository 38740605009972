import React, { useState } from "react";
import { useErrorContext } from "context/Error";
import { Amount } from "james/ledger";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AccountOperator,
  SetLimitRequest,
} from "james/stellar/AccountOperator";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { TextNumField } from "components/FormFields";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { Query } from "james/search/query";
import {
  TextListCriterion,
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { AllStellarNetworks, MaxSetLimitAmount } from "james/stellar";
import { TokenCategory } from "james/views/ledgerTokenView/Model";

interface SetLimitSectionProps {
  accountID: string;
}

const defaultCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextNEExactCriterion(TokenCategory.LiquidityPoolShares),
};

export const SetLimitSection = ({ accountID }: SetLimitSectionProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const [show] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [
    selectedTokenViewModelForLimitSetting,
    setSelectedTokenViewModelForLimitSetting,
  ] = useState<TokenViewModel | null>(null);
  const [setLimitRequest, setSetLimitRequest] = useState<SetLimitRequest>({
    context: authContext,
    accountID: accountID,
    limit: new Amount(),
  });
  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultCriteria,
  });

  const handleSetLimit = async () => {
    setSubmitting(true);
    try {
      await AccountOperator.SetLimit(setLimitRequest);
      enqueueSnackbar("Limit Set", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to set limit on account: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Unable to set limit on account: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setSubmitting(false);
  };

  return show ? (
    <Box
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="h4" fontWeight={"bold"}>
        Set Limit
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: { sm: 2, xs: 0 },
          mt: { sm: 0, xs: 1 },
          alignItems: "center",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            columnGap: { sm: 2, xs: 0 },
            width: { xs: "100%", sm: 520 },
          }}
        >
          <Autocomplete
            sx={{ width: { sm: "60%" } }}
            fullWidth={smDown}
            disabled={submitting}
            renderInput={(props) => (
              <TextField
                {...props}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: defaultCriteria,
                    });
                  } else {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: {
                        ...defaultCriteria,
                        "token.code": TextSubstringCriterion(e.target.value),
                      },
                    });
                  }
                }}
              />
            )}
            options={ledgerTokenViewReadResponse.models}
            getOptionLabel={(option: TokenViewModel) =>
              `${option.token.code} - ${option.issuer}`
            }
            value={selectedTokenViewModelForLimitSetting}
            onChange={(a, selected: TokenViewModel | null) => {
              if (selected) {
                setSetLimitRequest({
                  ...setLimitRequest,
                  limit: selected.token.newAmountOf(
                    setLimitRequest.limit.value,
                  ),
                });
                setSelectedTokenViewModelForLimitSetting(selected);
              }
            }}
          />
          <TextNumField
            fullWidth={smDown}
            value={setLimitRequest.limit.value}
            disabled={submitting}
            onChange={(e) =>
              setSetLimitRequest({
                ...setLimitRequest,
                limit: setLimitRequest.limit.setValue(e.target.value),
              })
            }
            InputProps={{
              endAdornment: (
                <Button
                  disabled={submitting}
                  children="set max"
                  onClick={() =>
                    setSetLimitRequest({
                      ...setLimitRequest,
                      limit: setLimitRequest.limit.setValue(MaxSetLimitAmount),
                    })
                  }
                />
              ),
            }}
          />
        </Box>
        <Button
          fullWidth={smDown}
          variant="outlined"
          sx={{ width: { sm: 160 }, height: 38, mt: { sm: 0.5 } }}
          disabled={
            submitting ||
            !selectedTokenViewModelForLimitSetting ||
            !setLimitRequest.limit ||
            setLimitRequest.limit.isUndefined()
          }
          onClick={handleSetLimit}
        >
          Set Limit{" "}
          {submitting && <CircularProgress size={16} sx={{ ml: 1 }} />}
        </Button>
      </Box>
    </Box>
  ) : null;
};
