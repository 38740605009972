import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ButtonBase,
  Card,
  IconButton,
  Slider,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { NewsSection } from "./components/NewsSection";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import meshLogo from "assets/images/logo/meshLogo.svg";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import { MarketingMedia, MediaType } from "james/marketing/MarketingMedia";
import { AssetMarketingMediaDataSheetSection } from "james/marketData/AssetMarketingMedia";
import cx from "classnames";
interface AssetMediaAndResourcesSectionRendererProps {
  section: AssetMarketingMediaDataSheetSection;
}

export const AssetMediaAndResourcesSectionRenderer = ({
  section,
}: AssetMediaAndResourcesSectionRendererProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        py: 3,
        pr: 4,
        flexDirection: {
          sm: "row",
          xs: "column",
        },
        gap: "24px",
      }}
    >
      {section.marketingMedia.length > 0 && (
        <Box
          sx={{
            maxWidth:
              section.newsArticles.length === 0
                ? { lg: 760, xs: "100%" }
                : { lg: 500, sm: "calc(100% - 300px)", xs: "100%" },
            mx: "auto",
          }}
        >
          <GalleryPlayer media={section.marketingMedia} />
        </Box>
      )}
      {section.newsArticles.length > 0 && (
        <Box
          sx={{
            width: {
              sm: section.marketingMedia.length > 0 ? "258px" : "100%",
              xs: "100%",
            },
            pr: {
              sm: 2,
              lg: 0,
            },
          }}
        >
          <NewsSection
            showScrollbar={!smDown}
            newsArticles={section.newsArticles}
            horizontal={section.marketingMedia.length === 0 && !smDown}
          />
        </Box>
      )}
    </Box>
  );
};

interface GalleryPlayerProps {
  media: MarketingMedia[];
}

const GalleryPlayer = ({ media }: GalleryPlayerProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMedia, setSelectedMedia] = useState<MarketingMedia>(media[0]);

  return (
    <Box>
      <Typography sx={{ mb: 1 }}>Latest Media</Typography>
      <Card
        sx={(theme) => ({
          height: { xs: 280, md: 360, lg: 280 },
          width: { xs: 497, md: 640, lg: 497 }, // 16:9 aspect ratio
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100%",
          backgroundColor: theme.palette.background.default,
        })}
      >
        {selectedMedia.type === MediaType.Image ? (
          <img
            src={selectedMedia.url}
            height="auto"
            width="auto"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        ) : selectedMedia.type === MediaType.Video ? (
          <MediaPlayer src={selectedMedia.url} />
        ) : (
          <Box
            component="iframe"
            src={selectedMedia.url}
            width="100%"
            height="100%"
            sx={{ border: 0 }}
            allowFullScreen
          />
        )}
      </Card>
      <Box
        className="meshScroll"
        sx={{
          overflowX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: media.length * 108,
            justifyContent: "space-between",
            pt: 1,
            pb: 2,
          }}
        >
          {media.map((v, i) => {
            return (
              <Card
                className={cx({
                  selected: i === selectedIndex,
                })}
                sx={(theme) => ({
                  width: 97,
                  height: 54,
                  "&.selected": {
                    p: "1px",
                    backgroundColor: theme.palette.primary.main,
                  },
                })}
              >
                <Card
                  component={ButtonBase}
                  key={i}
                  className={cx({
                    selected: i === selectedIndex,
                  })}
                  sx={(theme) => ({
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: theme.palette.background.default,
                    "&.selected": {
                      boxShadow: `0px 0px 10px 0px ${alpha(
                        theme.palette.primary.main,
                        0.8,
                      )} inset`,
                    },
                    "&:hover > *": {
                      color: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.text.primary, 0.1),
                    },
                  })}
                  onClick={() => {
                    setSelectedMedia(v);
                    setSelectedIndex(i);
                  }}
                >
                  {v.thumbnailImage.url ? (
                    <img
                      src={v.thumbnailImage.url}
                      height="auto"
                      width="auto"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <div>no thumbnail</div>
                  )}
                  {(v.type === MediaType.EmbeddedVideo ||
                    v.type === MediaType.Video) && (
                    <IconButton
                      sx={(theme) => ({
                        position: "absolute",
                        top: "calc(50% - 16px)",
                        left: "calc(50% - 16px)",
                        p: "4px",
                        color: theme.palette.primary.main,
                      })}
                    >
                      <PlayArrowIcon
                        sx={{
                          height: 24,
                          width: 24,
                        }}
                      />
                    </IconButton>
                  )}
                </Card>
              </Card>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

interface MediaPlayerProps {
  src: string;
}

const MediaPlayer = ({ src }: MediaPlayerProps) => {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement>();
  const controlsRef = useRef<HTMLDivElement>();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [volume, setVolume] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    return () => {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    };
  }, []);

  const handleTimeUpdate = () => {
    if (!isDragging) {
      setCurrentTime(video?.currentTime ?? 0);
    }
  };

  const togglePlay = () => {
    if (video === null) return;
    setCurrentTime(video.currentTime);
    setDuration(video.duration);
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleProgressBarClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (video) {
      const progressBar = event.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const totalWidth = progressBar.offsetWidth;
      const clickX = event.clientX - rect.left;
      const newTime = (clickX / totalWidth) * duration;
      setCurrentTime(newTime);
      video.currentTime = newTime;
    }
  };

  const handleFullscreenChange = () => {
    setFullscreen(false);
  };

  useEffect(() => {
    if (video === null) return;
    setDuration(video.duration);
    if (containerRef.current) {
      containerRef.current.addEventListener(
        "onfullscreenchange",
        handleFullscreenChange,
      );
    }
    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("play", () => {
      if (controlsRef.current) {
        controlsRef.current.classList.remove("paused");
        controlsRef.current.classList.add("play");
      }
    });
    video.addEventListener("pause", () => {
      if (controlsRef.current) {
        controlsRef.current.classList.add("paused");
        controlsRef.current.classList.remove("play");
      }
    });
    return () => {
      video.removeEventListener("play", () => null);
      video.removeEventListener("pause", () => null);
      video.removeEventListener("timeupdate", handleTimeUpdate);
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          "onfullscreenchange",
          handleFullscreenChange,
        );
      }
    };
  }, [video]);

  const handleProgressBarDragStart = () => {
    setIsDragging(true);
  };

  const handleProgressBarDragEnd = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setIsDragging(false);
    handleProgressBarClick(event);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "relative",
        "&:hover .controls": {
          opacity: 1,
        },
        "& > .controls, .play": {
          transition: "opacity 200ms ease-in-out",
          opacity: 0,
        },
        "& > .paused": {
          opacity: 1,
        },
      }}
    >
      <video
        onClick={(e) => {
          e.stopPropagation();
          togglePlay();
        }}
        ref={(player) => setVideo(player)}
        style={{
          minWidth: "300px",
          minHeight: "200px",
        }}
        width="100%"
        height={"100%"}
        poster={meshLogo}
      >
        <source src={src} />
      </video>

      <Box
        ref={controlsRef}
        className="controls paused"
        sx={(theme) => ({
          position: "absolute",
          bottom: "8px",
          left: "16px",
          right: "16px",
          display: "grid",
          gridTemplateColumns: `40px auto ${
            showVolume ? "120px" : "40px"
          } 40px 40px`,
          backgroundColor: alpha("#000000", 0.8),
          borderRadius: "4px",
          px: 1,
          alignItems: "center",
          transition: "grid-template-columns 200ms ease-in-out",
          "& > *": {
            color: theme.palette.text.primary,
          },
        })}
      >
        <IconButton onClick={togglePlay}>
          <PlayArrowIcon />
        </IconButton>
        <Box
          sx={(theme) => ({
            borderRadius: "6px",
            width: "100%",
            backgroundColor: "#000000",
            height: "12px",
            border: 1,
            boxShadow: `0 0 4px 0 ${theme.palette.primary.light}`,
            transition: "box-shadow 200ms ease-in-out",
            "&:hover": {
              boxShadow: `0 0 6px 1px ${theme.palette.primary.light}`,
            },
          })}
          onClick={handleProgressBarClick}
          onMouseDown={handleProgressBarDragStart}
          onMouseUp={handleProgressBarDragEnd}
        >
          <Box
            sx={(theme) => ({
              borderRadius: "6px",
              width: `${(currentTime / duration) * 100}%`,
              backgroundColor: theme.palette.primary.light,
              height: "100%",
            })}
          ></Box>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
          onMouseLeave={() => setShowVolume(false)}
          onMouseEnter={() => setShowVolume(true)}
        >
          {showVolume && (
            <Slider
              value={volume}
              sx={{ width: "100%" }}
              onChange={(_e, v) => {
                if (!video) return;
                setVolume(+v);
                video.volume = +v / 100;
              }}
              aria-label="Volume"
            />
          )}
          <IconButton
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
            onClick={() => {
              if (video !== null) {
                video.muted = !video.muted;
              }
            }}
          >
            <VolumeUpIcon />
          </IconButton>
        </Box>
        <IconButton
          onClick={() => {
            if (document.fullscreenElement) {
              document.exitFullscreen();
              setFullscreen(false);
              return;
            }
            if (containerRef.current) {
              containerRef.current.requestFullscreen();
              setFullscreen(true);
            }
          }}
        >
          {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        <IconButton
          onClick={() => {
            if (video && document.pictureInPictureEnabled) {
              video.requestPictureInPicture();
            }
          }}
        >
          <PictureInPictureIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
