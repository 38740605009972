import React, { useMemo } from "react";
import { Box, Card, Theme, Typography, useMediaQuery } from "@mui/material";
import { EstimateRepaymentCard } from "./components/EstimatedRepaymentCard";
import { LegendIcon } from "./components/LegendIcon";
import { MiniDisplayCard } from "./components/MiniDisplayCard";
import { RepaymentPieChart } from "./components/RepaymentPieCart";
import { AssetRepaymentTermsDataSheetSection } from "james/marketData/AssetRepaymentTermsDataSheetSection";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import dayjs from "dayjs";
import { PaymentState } from "@mesh/common-js/dist/financial/payment_pb";
import { frequencyToString } from "@mesh/common-js/dist/financial";
import { AssetType } from "james/views/marketListingView/Model";

interface AssetRepaymentTermsSectionRendererProps {
  dataSheetSection: AssetRepaymentTermsDataSheetSection;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
}

interface DynamicFillInTerms {
  tokenHolderType: string;
  paymentTypeLowerCase: string;
  paymentTypeUpperCase: string;
  unitCategory: string;
  termEndDescription: string;
  yieldDescription: string;
  yieldTooltip: string;
}

export const AssetRepaymentTermsSectionRenderer = ({
  dataSheetSection,
  marketListingViewModel,
}: AssetRepaymentTermsSectionRendererProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const amountOfPaymentsMade = useMemo(() => {
    let i = 0;
    for (const m of dataSheetSection.cashFlowViewModels) {
      if (![PaymentState.PAID_PAYMENT_STATE].includes(m.paymentState)) {
        return i;
      }
      i++;
    }

    return "";
  }, [dataSheetSection.cashFlowViewModels]);

  // generate dynamic fill in terms
  const dynamicFillInTerms = useMemo((): DynamicFillInTerms => {
    switch (marketListingViewModel.assetType) {
      case AssetType.Bond:
        return {
          tokenHolderType: "noteholder",
          paymentTypeLowerCase: "coupon",
          paymentTypeUpperCase: "Coupon",
          unitCategory: "note",
          termEndDescription: "Maturity",
          yieldDescription: "Coupon Rate",
          yieldTooltip:
            "Annual return payable to noteholders as a percentage of the nominal value of their holdings.",
        };
      case AssetType.PreferenceShare:
        return {
          tokenHolderType: "shareholder",
          paymentTypeLowerCase: "dividend",
          paymentTypeUpperCase: "Dividend",
          unitCategory: "preference share",
          termEndDescription: "Redemption",
          yieldDescription: "Dividend Rate",
          yieldTooltip:
            "Annual return payable to shareholders as a percentage of the nominal value of their holdings.",
        };
      case AssetType.Share:
        return {
          tokenHolderType: "shareholder",
          paymentTypeLowerCase: "dividend",
          paymentTypeUpperCase: "Dividend",
          unitCategory: "share",
          termEndDescription: "Term End",
          yieldDescription: "Dividend Yield",
          yieldTooltip:
            "Annual dividend income payable to shareholders expressed as a percentage of the market price per share.",
        };
      default:
        return {
          tokenHolderType: "shareholder",
          paymentTypeLowerCase: "profit distribution",
          paymentTypeUpperCase: "Profit Distribution",
          unitCategory: "unit",
          termEndDescription: "Term End",
          yieldDescription: "Profit Rate",
          yieldTooltip:
            "Annual return distributed to investors as a percentage of their invested capital.",
        };
    }
  }, [marketListingViewModel]);

  return (
    <Box sx={{ pt: 4, mr: { sm: 5, xs: 3 }, pb: 5 }}>
      {/* Repayment Section */}
      <Box
        sx={{
          display: "flex",
          mb: 4,
          flexDirection: { sm: "row", xs: "column" },
          width: "100%",
          gap: { sm: "24px", xs: 0 },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "210px 210px",
              xs: "auto",
            },
            gap: "24px",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <MiniDisplayCard
            title="Term Length"
            tooltip={`Duration of the investment period during which a ${dynamicFillInTerms.tokenHolderType} can expect to receive ${dynamicFillInTerms.paymentTypeLowerCase} payments.`}
            info={`${dayjs
              .duration(
                dayjs(dataSheetSection.maturityDate).diff(
                  dayjs(marketListingViewModel.assetIssueDate),
                ),
              )
              .asYears()
              .toFixed()} Years`}
          />
          <MiniDisplayCard
            title={`Nominal Value`}
            tooltip={`Face value per ${dynamicFillInTerms.unitCategory} subscribed to in the current tranche.`}
            info={`${dataSheetSection.totalNominal.token.code} ${dataSheetSection.totalNominal.value.toFormat()}`}
          />
          <MiniDisplayCard
            title="Frequency"
            tooltip={`Interval at which ${dynamicFillInTerms.paymentTypeLowerCase} payments are made to a ${dynamicFillInTerms.tokenHolderType}'s account.`}
            info={frequencyToString(dataSheetSection.frequency)}
          />
          <MiniDisplayCard
            title={dynamicFillInTerms.yieldDescription}
            tooltip={dynamicFillInTerms.yieldTooltip}
            info={`${marketListingViewModel.returnDescription}${
              /\d$/.test(marketListingViewModel.returnDescription) ? "%" : ""
            }`}
            infoHeading={
              marketListingViewModel.returnDescription.length > 15 ? "h5" : "h4"
            }
          />
        </Box>
        <Card
          sx={(theme) => ({
            py: 2,
            px: 3,
            backgroundColor: theme.palette.custom.midnight,
            width: { sm: "308px", xs: "100%" },
            ml: { sm: 1, lg: 0 },
            mt: { sm: 0, xs: 2 },
            mr: { xs: 3, sm: 0 },
          })}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: { sm: "80px", xs: "104px" },
                height: { sm: "80px", xs: "104px" },
                mt: 1,
              }}
            >
              <RepaymentPieChart
                size={smDown ? 104 : 80}
                cashFlowViewModels={dataSheetSection.cashFlowViewModels}
              />
            </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                ml: 2,
              })}
            >
              <Typography variant="caption">Payments</Typography>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontWeight: "bold",
                  mb: 1,
                })}
                variant="h4"
              >
                {amountOfPaymentsMade} of{" "}
                {dataSheetSection.cashFlowViewModels.length}
              </Typography>
              <LegendIcon label="Payments Made" color={() => "#56D9FD"} />
              <LegendIcon label="Upcoming" color={() => "#8E258D"} />
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                color: theme.palette.text.disabled,
              })}
            >
              <Typography variant="caption">Issue Date</Typography>
              <Typography variant="caption">
                {dynamicFillInTerms.termEndDescription}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>
                {dayjs(dataSheetSection.termStart).format("MMM DD, YY")}
              </Typography>
              <Box
                sx={{
                  width: "60px",
                  height: "2px",
                  backgroundColor: "#56D9FD",
                  boxShadow: "0 0 4px 0 #56D9FD",
                  borderRadius: 2,
                }}
              />
              <Typography>
                {dayjs(dataSheetSection.maturityDate).format("MMM DD, YY")}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      {smDown &&
        marketListingViewModel.marketSubscriptionOrderBookViewModel && (
          <Box sx={{ mx: -3 }}>
            <EstimateRepaymentCard
              cashFlowViewModels={dataSheetSection.cashFlowViewModels}
              subscriptionOrderBook={
                marketListingViewModel.marketSubscriptionOrderBookViewModel
              }
              unitsInCirculation={dataSheetSection.unitsInCirculation}
              totalNominal={dataSheetSection.totalNominal}
              instrumentCouponFrequency={dataSheetSection.frequency}
              paymentType={dynamicFillInTerms.paymentTypeUpperCase}
              termEndDescription={dynamicFillInTerms.termEndDescription}
              unitCategory={dynamicFillInTerms.unitCategory}
            />
          </Box>
        )}

      {!smDown &&
        marketListingViewModel.marketSubscriptionOrderBookViewModel && (
          <EstimateRepaymentCard
            cashFlowViewModels={dataSheetSection.cashFlowViewModels}
            subscriptionOrderBook={
              marketListingViewModel.marketSubscriptionOrderBookViewModel
            }
            unitsInCirculation={dataSheetSection.unitsInCirculation}
            totalNominal={dataSheetSection.totalNominal}
            instrumentCouponFrequency={dataSheetSection.frequency}
            paymentType={dynamicFillInTerms.paymentTypeUpperCase}
            termEndDescription={dynamicFillInTerms.termEndDescription}
            unitCategory={dynamicFillInTerms.unitCategory}
          />
        )}
    </Box>
  );
};
