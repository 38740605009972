import { Role } from "james/role";
import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { useEffect, useState } from "react";
import { useApplicationContext } from "../../context/Application/Application";
import { useErrorContext } from "context/Error";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";

export interface RetrieveRoleRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveRoleResponse {
  role: Role;
}

export interface ReadManyRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface ReadManyResponse {
  records: Role[];
  total: number;
}

export const Reader = {
  serviceProvider: "role-Reader",
  async ReadMany(request: ReadManyRequest): Promise<ReadManyResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshAuthorizedURL"),
      method: `${Reader.serviceProvider}.ReadMany`,
      request,
    });
    return {
      records: response.records.map((c: Role) => new Role(c)),
      total: response.total,
    };
  },
};

export function useReadRoles(
  initialReadManyRequest?: ReadManyRequest,
  shouldNotExecute?: boolean,
) {
  const { authContext } = useApplicationContext();
  const [readManyRequest, setReadManyRequest] = useState<ReadManyRequest>(
    initialReadManyRequest || {
      context: authContext,
      query: new Query(),
      criteria: {},
    },
  );
  const [readManyResponse, setReadManyResponse] = useState<ReadManyResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    const timeoutRef = setTimeout(async () => {
      setLoading(true);
      setError(undefined);
      try {
        setReadManyResponse(await Reader.ReadMany(readManyRequest));
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading roles: ${err.message ? err.message : err.toString()}`,
        );
        setError(
          `error reading roles: ${err.message ? err.message : err.toString()}`,
        );
      }
      setLoading(false);
    });

    return () => clearTimeout(timeoutRef);
  }, [readManyRequest, shouldNotExecute]);

  return {
    readManyRequest,
    setReadManyRequest,
    readManyResponse,
    loading,
    error,
  };
}
