// source: banking/fundingOrderStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var banking_fundingOrder_pb = require('../banking/fundingOrder_pb.js');
goog.object.extend(proto, banking_fundingOrder_pb);
goog.exportSymbol('proto.banking.CancelFundingOrderRequest', null, global);
goog.exportSymbol('proto.banking.CancelFundingOrderResponse', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderFailedRequest', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderFailedResponse', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderSettledRequest', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderSettledResponse', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderUnderInvestigationRequest', null, global);
goog.exportSymbol('proto.banking.MarkFundingOrderUnderInvestigationResponse', null, global);
goog.exportSymbol('proto.banking.ResolveFundingOrderRequest', null, global);
goog.exportSymbol('proto.banking.ResolveFundingOrderResponse', null, global);
goog.exportSymbol('proto.banking.SettleFundingOrderRequest', null, global);
goog.exportSymbol('proto.banking.SettleFundingOrderResponse', null, global);
goog.exportSymbol('proto.banking.SubmitFundingOrderRequest', null, global);
goog.exportSymbol('proto.banking.SubmitFundingOrderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SettleFundingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SettleFundingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SettleFundingOrderRequest.displayName = 'proto.banking.SettleFundingOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CancelFundingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CancelFundingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CancelFundingOrderRequest.displayName = 'proto.banking.CancelFundingOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SubmitFundingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SubmitFundingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SubmitFundingOrderRequest.displayName = 'proto.banking.SubmitFundingOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ResolveFundingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ResolveFundingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ResolveFundingOrderRequest.displayName = 'proto.banking.ResolveFundingOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderSettledRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderSettledRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderSettledRequest.displayName = 'proto.banking.MarkFundingOrderSettledRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderFailedRequest.displayName = 'proto.banking.MarkFundingOrderFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderUnderInvestigationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderUnderInvestigationRequest.displayName = 'proto.banking.MarkFundingOrderUnderInvestigationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SettleFundingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SettleFundingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SettleFundingOrderResponse.displayName = 'proto.banking.SettleFundingOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.CancelFundingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.CancelFundingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.CancelFundingOrderResponse.displayName = 'proto.banking.CancelFundingOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.SubmitFundingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.SubmitFundingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.SubmitFundingOrderResponse.displayName = 'proto.banking.SubmitFundingOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.ResolveFundingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.ResolveFundingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.ResolveFundingOrderResponse.displayName = 'proto.banking.ResolveFundingOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderSettledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderSettledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderSettledResponse.displayName = 'proto.banking.MarkFundingOrderSettledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderFailedResponse.displayName = 'proto.banking.MarkFundingOrderFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.banking.MarkFundingOrderUnderInvestigationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.banking.MarkFundingOrderUnderInvestigationResponse.displayName = 'proto.banking.MarkFundingOrderUnderInvestigationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SettleFundingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SettleFundingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SettleFundingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleFundingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SettleFundingOrderRequest}
 */
proto.banking.SettleFundingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SettleFundingOrderRequest;
  return proto.banking.SettleFundingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SettleFundingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SettleFundingOrderRequest}
 */
proto.banking.SettleFundingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingorderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SettleFundingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SettleFundingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SettleFundingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleFundingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SettleFundingOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SettleFundingOrderRequest} returns this
*/
proto.banking.SettleFundingOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SettleFundingOrderRequest} returns this
 */
proto.banking.SettleFundingOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SettleFundingOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fundingOrderId = 2;
 * @return {string}
 */
proto.banking.SettleFundingOrderRequest.prototype.getFundingorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SettleFundingOrderRequest} returns this
 */
proto.banking.SettleFundingOrderRequest.prototype.setFundingorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.banking.SettleFundingOrderRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SettleFundingOrderRequest} returns this
 */
proto.banking.SettleFundingOrderRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CancelFundingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CancelFundingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CancelFundingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CancelFundingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorderid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CancelFundingOrderRequest}
 */
proto.banking.CancelFundingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CancelFundingOrderRequest;
  return proto.banking.CancelFundingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CancelFundingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CancelFundingOrderRequest}
 */
proto.banking.CancelFundingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingorderid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CancelFundingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CancelFundingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CancelFundingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CancelFundingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.CancelFundingOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.CancelFundingOrderRequest} returns this
*/
proto.banking.CancelFundingOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CancelFundingOrderRequest} returns this
 */
proto.banking.CancelFundingOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CancelFundingOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fundingOrderId = 2;
 * @return {string}
 */
proto.banking.CancelFundingOrderRequest.prototype.getFundingorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CancelFundingOrderRequest} returns this
 */
proto.banking.CancelFundingOrderRequest.prototype.setFundingorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.banking.CancelFundingOrderRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.CancelFundingOrderRequest} returns this
 */
proto.banking.CancelFundingOrderRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SubmitFundingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SubmitFundingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SubmitFundingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitFundingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && ledger_amount_pb.FutureAmount.toObject(includeInstance, f),
    bankreference: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bankname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountnumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SubmitFundingOrderRequest}
 */
proto.banking.SubmitFundingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SubmitFundingOrderRequest;
  return proto.banking.SubmitFundingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SubmitFundingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SubmitFundingOrderRequest}
 */
proto.banking.SubmitFundingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new ledger_amount_pb.FutureAmount;
      reader.readMessage(value,ledger_amount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankreference(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SubmitFundingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SubmitFundingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SubmitFundingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitFundingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ledger_amount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getBankreference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBankname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountnumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
*/
proto.banking.SubmitFundingOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SubmitFundingOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ledger.FutureAmount amount = 2;
 * @return {?proto.ledger.FutureAmount}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_amount_pb.FutureAmount, 2));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
*/
proto.banking.SubmitFundingOrderRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SubmitFundingOrderRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string bankReference = 3;
 * @return {string}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getBankreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.setBankreference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bankName = 4;
 * @return {string}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getBankname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.setBankname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reference = 5;
 * @return {string}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string accountNumber = 6;
 * @return {string}
 */
proto.banking.SubmitFundingOrderRequest.prototype.getAccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.SubmitFundingOrderRequest} returns this
 */
proto.banking.SubmitFundingOrderRequest.prototype.setAccountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ResolveFundingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ResolveFundingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ResolveFundingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveFundingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorderid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ResolveFundingOrderRequest}
 */
proto.banking.ResolveFundingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ResolveFundingOrderRequest;
  return proto.banking.ResolveFundingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ResolveFundingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ResolveFundingOrderRequest}
 */
proto.banking.ResolveFundingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundingorderid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ResolveFundingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ResolveFundingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ResolveFundingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveFundingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorderid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.ResolveFundingOrderRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.ResolveFundingOrderRequest} returns this
*/
proto.banking.ResolveFundingOrderRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ResolveFundingOrderRequest} returns this
 */
proto.banking.ResolveFundingOrderRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ResolveFundingOrderRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fundingOrderId = 2;
 * @return {string}
 */
proto.banking.ResolveFundingOrderRequest.prototype.getFundingorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.banking.ResolveFundingOrderRequest} returns this
 */
proto.banking.ResolveFundingOrderRequest.prototype.setFundingorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderSettledRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderSettledRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderSettledRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderSettledRequest}
 */
proto.banking.MarkFundingOrderSettledRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderSettledRequest;
  return proto.banking.MarkFundingOrderSettledRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderSettledRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderSettledRequest}
 */
proto.banking.MarkFundingOrderSettledRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderSettledRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderSettledRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderSettledRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkFundingOrderSettledRequest} returns this
*/
proto.banking.MarkFundingOrderSettledRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderSettledRequest} returns this
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FundingOrder fundingOrder = 2;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 2));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.MarkFundingOrderSettledRequest} returns this
*/
proto.banking.MarkFundingOrderSettledRequest.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderSettledRequest} returns this
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderSettledRequest.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderFailedRequest}
 */
proto.banking.MarkFundingOrderFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderFailedRequest;
  return proto.banking.MarkFundingOrderFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderFailedRequest}
 */
proto.banking.MarkFundingOrderFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkFundingOrderFailedRequest} returns this
*/
proto.banking.MarkFundingOrderFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderFailedRequest} returns this
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FundingOrder fundingOrder = 2;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 2));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.MarkFundingOrderFailedRequest} returns this
*/
proto.banking.MarkFundingOrderFailedRequest.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderFailedRequest} returns this
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderFailedRequest.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderUnderInvestigationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderUnderInvestigationRequest;
  return proto.banking.MarkFundingOrderUnderInvestigationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderUnderInvestigationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest} returns this
*/
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest} returns this
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FundingOrder fundingOrder = 2;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 2));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest} returns this
*/
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationRequest} returns this
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderUnderInvestigationRequest.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SettleFundingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SettleFundingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SettleFundingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleFundingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SettleFundingOrderResponse}
 */
proto.banking.SettleFundingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SettleFundingOrderResponse;
  return proto.banking.SettleFundingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SettleFundingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SettleFundingOrderResponse}
 */
proto.banking.SettleFundingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SettleFundingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SettleFundingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SettleFundingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SettleFundingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.SettleFundingOrderResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.SettleFundingOrderResponse} returns this
*/
proto.banking.SettleFundingOrderResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SettleFundingOrderResponse} returns this
 */
proto.banking.SettleFundingOrderResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SettleFundingOrderResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.CancelFundingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.CancelFundingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.CancelFundingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CancelFundingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.CancelFundingOrderResponse}
 */
proto.banking.CancelFundingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.CancelFundingOrderResponse;
  return proto.banking.CancelFundingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.CancelFundingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.CancelFundingOrderResponse}
 */
proto.banking.CancelFundingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.CancelFundingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.CancelFundingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.CancelFundingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.CancelFundingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.CancelFundingOrderResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.CancelFundingOrderResponse} returns this
*/
proto.banking.CancelFundingOrderResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.CancelFundingOrderResponse} returns this
 */
proto.banking.CancelFundingOrderResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.CancelFundingOrderResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.SubmitFundingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.SubmitFundingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.SubmitFundingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitFundingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.SubmitFundingOrderResponse}
 */
proto.banking.SubmitFundingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.SubmitFundingOrderResponse;
  return proto.banking.SubmitFundingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.SubmitFundingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.SubmitFundingOrderResponse}
 */
proto.banking.SubmitFundingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.SubmitFundingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.SubmitFundingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.SubmitFundingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.SubmitFundingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.SubmitFundingOrderResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.SubmitFundingOrderResponse} returns this
*/
proto.banking.SubmitFundingOrderResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.SubmitFundingOrderResponse} returns this
 */
proto.banking.SubmitFundingOrderResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.SubmitFundingOrderResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.ResolveFundingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.ResolveFundingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.ResolveFundingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveFundingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.ResolveFundingOrderResponse}
 */
proto.banking.ResolveFundingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.ResolveFundingOrderResponse;
  return proto.banking.ResolveFundingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.ResolveFundingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.ResolveFundingOrderResponse}
 */
proto.banking.ResolveFundingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.ResolveFundingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.ResolveFundingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.ResolveFundingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.ResolveFundingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.ResolveFundingOrderResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.ResolveFundingOrderResponse} returns this
*/
proto.banking.ResolveFundingOrderResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.ResolveFundingOrderResponse} returns this
 */
proto.banking.ResolveFundingOrderResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.ResolveFundingOrderResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderSettledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderSettledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderSettledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderSettledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderSettledResponse}
 */
proto.banking.MarkFundingOrderSettledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderSettledResponse;
  return proto.banking.MarkFundingOrderSettledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderSettledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderSettledResponse}
 */
proto.banking.MarkFundingOrderSettledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderSettledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderSettledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderSettledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderSettledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.MarkFundingOrderSettledResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.MarkFundingOrderSettledResponse} returns this
*/
proto.banking.MarkFundingOrderSettledResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderSettledResponse} returns this
 */
proto.banking.MarkFundingOrderSettledResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderSettledResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fundingorder: (f = msg.getFundingorder()) && banking_fundingOrder_pb.FundingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderFailedResponse}
 */
proto.banking.MarkFundingOrderFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderFailedResponse;
  return proto.banking.MarkFundingOrderFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderFailedResponse}
 */
proto.banking.MarkFundingOrderFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new banking_fundingOrder_pb.FundingOrder;
      reader.readMessage(value,banking_fundingOrder_pb.FundingOrder.deserializeBinaryFromReader);
      msg.setFundingorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFundingorder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      banking_fundingOrder_pb.FundingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional FundingOrder fundingOrder = 1;
 * @return {?proto.banking.FundingOrder}
 */
proto.banking.MarkFundingOrderFailedResponse.prototype.getFundingorder = function() {
  return /** @type{?proto.banking.FundingOrder} */ (
    jspb.Message.getWrapperField(this, banking_fundingOrder_pb.FundingOrder, 1));
};


/**
 * @param {?proto.banking.FundingOrder|undefined} value
 * @return {!proto.banking.MarkFundingOrderFailedResponse} returns this
*/
proto.banking.MarkFundingOrderFailedResponse.prototype.setFundingorder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.banking.MarkFundingOrderFailedResponse} returns this
 */
proto.banking.MarkFundingOrderFailedResponse.prototype.clearFundingorder = function() {
  return this.setFundingorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.banking.MarkFundingOrderFailedResponse.prototype.hasFundingorder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.banking.MarkFundingOrderUnderInvestigationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationResponse}
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.banking.MarkFundingOrderUnderInvestigationResponse;
  return proto.banking.MarkFundingOrderUnderInvestigationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.banking.MarkFundingOrderUnderInvestigationResponse}
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.banking.MarkFundingOrderUnderInvestigationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.banking.MarkFundingOrderUnderInvestigationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.banking.MarkFundingOrderUnderInvestigationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.banking);
