import { Amount } from "james/ledger";
import { Entry } from "james/audit/Entry";
import dayjs from "dayjs";

const SubscriptionOrderTypeName = "mesh::market/SubscriptionOrder";

export enum SubscriptionOrderState {
  SubmissionInProgress = "Submission in Progress",
  SubmissionUnderInvestigation = "Submission Under Investigation",
  Failing = "Failing",
  FailureUnderInvestigation = "Failure Under Investigation",
  Failed = "Failed",
  AwaitingSettlement = "Awaiting Settlement",
  Settled = "Settled",
}

export const AllSubscriptionOrderStates: SubscriptionOrderState[] = [
  SubscriptionOrderState.SubmissionInProgress,
  SubscriptionOrderState.SubmissionUnderInvestigation,
  SubscriptionOrderState.Failing,
  SubscriptionOrderState.FailureUnderInvestigation,
  SubscriptionOrderState.Failed,
  SubscriptionOrderState.AwaitingSettlement,
  SubscriptionOrderState.Settled,
];

export class SubscriptionOrder {
  public ["@type"]: string = SubscriptionOrderTypeName;
  public id = "";
  public ownerID = "";
  public subscriptionOrderBookID = "";
  public number = "";
  public state: SubscriptionOrderState | "" = "";
  public amount: Amount = new Amount();
  public tokens: Amount = new Amount();
  public resolutionCount = 0;
  public timeSubmitted = dayjs().format();
  public auditEntry: Entry = new Entry();

  constructor(l?: SubscriptionOrder) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.ownerID = l.ownerID;
    this.subscriptionOrderBookID = l.subscriptionOrderBookID;
    this.number = l.number;
    this.state = l.state;
    this.amount = new Amount(l.amount);
    this.tokens = new Amount(l.tokens);
    this.resolutionCount = l.resolutionCount;
    this.timeSubmitted = l.timeSubmitted;
    this.auditEntry = new Entry(l.auditEntry);
  }
}
