import { Box } from "@mui/material";
import React from "react";

export const LegalSection = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
      })}
    >
      Documents!
    </Box>
  );
};
