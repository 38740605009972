import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { Reader as ClientPublicProfileReader } from "james/views/clientPublicProfileView";
import { useIsMounted } from "hooks";
import { IssuerInformation, useLedgerContext } from "context/Ledger/Ledger";
import { ShortNameIdentifier } from "james/search/identifier";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetType } from "james/views/marketListingView/Model";
import { useErrorContext } from "context/Error";

const cryptoCardAssetTypes: (AssetType | "")[] = [
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

interface IssuerInfoSectionProps {
  marketListingViewModel?:
    | MarketListingViewModel
    | PublicMarketListingViewModel;
}

export const IssuerIconSection = (props: IssuerInfoSectionProps) => {
  const { ledgerContextGetTokenIssuerInformation } = useLedgerContext();
  const isMounted = useIsMounted();
  const { errorContextErrorTranslator } = useErrorContext();

  const [issuerProfile, setIssuerProfile] = useState<
    IssuerInformation | undefined
  >(undefined);
  useEffect(() => {
    const timeoutRef = setTimeout(async () => {
      try {
        if (!props.marketListingViewModel) {
          return;
        }
        if (
          cryptoCardAssetTypes.includes(props.marketListingViewModel.assetType)
        ) {
          const tokenIssuerInformation =
            await ledgerContextGetTokenIssuerInformation(
              props.marketListingViewModel.token,
            );
          if (isMounted()) {
            setIssuerProfile(tokenIssuerInformation);
          }
        } else {
          const retrievedPublicClientProfile = (
            await ClientPublicProfileReader.PublicReadOne({
              identifier: ShortNameIdentifier(
                props.marketListingViewModel.assetOwnerClientShortName,
              ),
            })
          ).publicModel;
          if (isMounted()) {
            setIssuerProfile({
              name: retrievedPublicClientProfile.clientName,
              description: retrievedPublicClientProfile.description,
              logoURL: retrievedPublicClientProfile.clientProfilePicture,
              email: "",
              website: retrievedPublicClientProfile.website,
              twitter: retrievedPublicClientProfile.twitter,
            });
          }
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving issuer information: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    });

    return () => clearTimeout(timeoutRef);
  }, [props.marketListingViewModel]);

  return (
    <>
      {issuerProfile &&
        props.marketListingViewModel &&
        (props.marketListingViewModel.issuerLogo?.url ||
          issuerProfile.logoURL) && (
          <Tooltip title={`Issued by: ${issuerProfile.name}`} placement="top">
            <Box
              component={"img"}
              alt={issuerProfile.name}
              src={
                props.marketListingViewModel.issuerLogo?.url
                  ? props.marketListingViewModel.issuerLogo.url
                  : issuerProfile.logoURL
              }
              sx={{ maxWidth: 200, height: 48 }}
              onClick={(e) => {
                if (e && e.stopPropagation) {
                  e.stopPropagation();
                }
              }}
            />
          </Tooltip>
        )}
    </>
  );
};
