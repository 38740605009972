/**
 * @fileoverview gRPC-Web generated client stub for ledger
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: ledger/assetHolderLookupReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var ledger_assetHolderLookup_pb = require('../ledger/assetHolderLookup_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_token_pb = require('../ledger/token_pb.js')

var ledger_assetHolderLookupReader_meshproto_pb = require('../ledger/assetHolderLookupReader_meshproto_pb.js')
const proto = {};
proto.ledger = require('./assetHolderLookupReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ledger.ReadOneAssetHolderLookupRequest,
 *   !proto.ledger.ReadOneAssetHolderLookupResponse>}
 */
const methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadOneAssetHolderLookupUNSCOPED = new grpc.web.MethodDescriptor(
  '/ledger.AssetHolderLookupReaderUNSCOPED/ReadOneAssetHolderLookupUNSCOPED',
  grpc.web.MethodType.UNARY,
  ledger_assetHolderLookupReader_meshproto_pb.ReadOneAssetHolderLookupRequest,
  ledger_assetHolderLookupReader_meshproto_pb.ReadOneAssetHolderLookupResponse,
  /**
   * @param {!proto.ledger.ReadOneAssetHolderLookupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  ledger_assetHolderLookupReader_meshproto_pb.ReadOneAssetHolderLookupResponse.deserializeBinary
);


/**
 * @param {!proto.ledger.ReadOneAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ledger.ReadOneAssetHolderLookupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ledger.ReadOneAssetHolderLookupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDClient.prototype.readOneAssetHolderLookupUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadOneAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadOneAssetHolderLookupUNSCOPED,
      callback);
};


/**
 * @param {!proto.ledger.ReadOneAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ledger.ReadOneAssetHolderLookupResponse>}
 *     Promise that resolves to the response
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDPromiseClient.prototype.readOneAssetHolderLookupUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadOneAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadOneAssetHolderLookupUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ledger.ReadManyAssetHolderLookupRequest,
 *   !proto.ledger.ReadManyAssetHolderLookupResponse>}
 */
const methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadManyAssetHolderLookupUNSCOPED = new grpc.web.MethodDescriptor(
  '/ledger.AssetHolderLookupReaderUNSCOPED/ReadManyAssetHolderLookupUNSCOPED',
  grpc.web.MethodType.UNARY,
  ledger_assetHolderLookupReader_meshproto_pb.ReadManyAssetHolderLookupRequest,
  ledger_assetHolderLookupReader_meshproto_pb.ReadManyAssetHolderLookupResponse,
  /**
   * @param {!proto.ledger.ReadManyAssetHolderLookupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  ledger_assetHolderLookupReader_meshproto_pb.ReadManyAssetHolderLookupResponse.deserializeBinary
);


/**
 * @param {!proto.ledger.ReadManyAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ledger.ReadManyAssetHolderLookupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ledger.ReadManyAssetHolderLookupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDClient.prototype.readManyAssetHolderLookupUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadManyAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadManyAssetHolderLookupUNSCOPED,
      callback);
};


/**
 * @param {!proto.ledger.ReadManyAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ledger.ReadManyAssetHolderLookupResponse>}
 *     Promise that resolves to the response
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDPromiseClient.prototype.readManyAssetHolderLookupUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadManyAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadManyAssetHolderLookupUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ledger.ReadAllAssetHolderLookupRequest,
 *   !proto.ledger.ReadAllAssetHolderLookupResponse>}
 */
const methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadAllAssetHolderLookupUNSCOPED = new grpc.web.MethodDescriptor(
  '/ledger.AssetHolderLookupReaderUNSCOPED/ReadAllAssetHolderLookupUNSCOPED',
  grpc.web.MethodType.UNARY,
  ledger_assetHolderLookupReader_meshproto_pb.ReadAllAssetHolderLookupRequest,
  ledger_assetHolderLookupReader_meshproto_pb.ReadAllAssetHolderLookupResponse,
  /**
   * @param {!proto.ledger.ReadAllAssetHolderLookupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  ledger_assetHolderLookupReader_meshproto_pb.ReadAllAssetHolderLookupResponse.deserializeBinary
);


/**
 * @param {!proto.ledger.ReadAllAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ledger.ReadAllAssetHolderLookupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ledger.ReadAllAssetHolderLookupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDClient.prototype.readAllAssetHolderLookupUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadAllAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadAllAssetHolderLookupUNSCOPED,
      callback);
};


/**
 * @param {!proto.ledger.ReadAllAssetHolderLookupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ledger.ReadAllAssetHolderLookupResponse>}
 *     Promise that resolves to the response
 */
proto.ledger.AssetHolderLookupReaderUNSCOPEDPromiseClient.prototype.readAllAssetHolderLookupUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ledger.AssetHolderLookupReaderUNSCOPED/ReadAllAssetHolderLookupUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_AssetHolderLookupReaderUNSCOPED_ReadAllAssetHolderLookupUNSCOPED);
};


module.exports = proto.ledger;

