import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { AssetPriceHistoryDataSheetSection } from "james/marketData";
import {
  Autocomplete,
  Box,
  Card,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AllDateFilters,
  DateFilter,
  useGenerateIndicativePriceHistory,
} from "james/marketData/IndicativePriceHistoryGenerator";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { useSnackbar } from "notistack";
import { useIsMounted } from "hooks";
import { IDIdentifier } from "james/search/identifier";
import { formatTextNum } from "utilities/number";
import dayjs from "dayjs";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IndicativePriceDataPoint } from "james/marketData/IndicativePriceDataPoint";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
} from "james/views/marketListingView";
import { AssetType } from "james/views/marketListingView/Model";
import {
  AllPeriods,
  HistoricalPrice,
  Period,
  Resolution,
} from "pkgTemp/market";
import { Token } from "james/ledger";
import { IndicativePrice, MechanismType } from "james/market";
import BigNumber from "bignumber.js";
import { useRQPriceHistorian } from "hooks/reactQuery/useRQPriceHistorian";
import { useErrorContext } from "context/Error";

const PREFIX = "AssetPriceHistoryDataSheetSectionRenderer";

const classes = {
  performanceChartTooltipPadding: `${PREFIX}-performanceChartTooltipPadding`,
  PriceTimeRangeFilterAutocompleteWidth: `${PREFIX}-PriceTimeRangeFilterAutocompleteWidth`,
  zoomText: `${PREFIX}-zoomText`,
  autoCompleteInput: `${PREFIX}-autoCompleteInput`,
  bold: `${PREFIX}-bold`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.performanceChartTooltipPadding}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.PriceTimeRangeFilterAutocompleteWidth}`]: {
    width: 44,
  },

  [`& .${classes.zoomText}`]: {
    marginTop: theme.spacing(0.5),
  },

  [`& .${classes.autoCompleteInput}`]: {
    backgroundColor: theme.palette.custom.midnight,
    width: 66,
  },

  [`& .${classes.bold}`]: { fontWeight: theme.typography.fontWeightBold },
}));

const cryptoCardAssetTypes: (AssetType | "" | undefined)[] = [
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

export type Props = {
  dataSheetSection: AssetPriceHistoryDataSheetSection;
  marketListingViewModel: MarketListingViewModel | PublicMarketListingViewModel;
};

function getLeftMargin(largestValue: number): number {
  if (largestValue >= 1000000000) {
    return 34 + 16;
  } else if (largestValue >= 100000000) {
    return 33 + 16;
  } else if (largestValue >= 10000000) {
    return 30 + 16;
  } else if (largestValue >= 1000000) {
    return 27 + 16;
  } else if (largestValue >= 100000) {
    return 23 + 16;
  } else if (largestValue >= 10000) {
    return 20 + 16;
  } else if (largestValue >= 1000) {
    return 18;
  } else if (largestValue >= 100) {
    return 6;
  } else if (largestValue >= 10) {
    return 16;
  } else if (largestValue >= 1) {
    return 12;
  } else if (largestValue >= 0.01) {
    return 12;
  } else {
    return 36;
  }
}

export const AssetPriceHistoryDataSheetSectionRenderer = (props: Props) => {
  const { current: isCryptoCard } = useRef(
    cryptoCardAssetTypes.includes(props.marketListingViewModel.assetType),
  );

  if (isCryptoCard) {
    return <PriceHistorianGraph {...props} />;
  } else {
    return <IndicativePriceGraph {...props} />;
  }
};

export const IndicativePriceGraph = (props: Props) => {
  const theme = useTheme();
  const { errorContextErrorTranslator } = useErrorContext();

  const [selectedDateFilter, setSelectedDateFilter] = useState<DateFilter>(
    DateFilter._1M,
  );
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const [valuationTokenViewModel, setValuationTokenViewModel] =
    useState<LedgerTokenViewModel>(new LedgerTokenViewModel());
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();

  const {
    loading,
    generateIndicativePriceHistoryResponse,
    generateIndicativePriceHistoryRequest,
    setGenerateIndicativePriceHistoryRequest,
  } = useGenerateIndicativePriceHistory({
    assetIdentifier: IDIdentifier(props.dataSheetSection.assetID),
    dateFilter: selectedDateFilter,
  });

  const [leftMargin, setLeftMargin] = useState(0);
  const [use7DecimalsForYAxis, setUse7DecimalsForYAxis] = useState(false);
  useEffect(() => {
    // get the largest price in series
    const largestPrice =
      generateIndicativePriceHistoryResponse.priceHistory.reduce(
        (prev, price) =>
          prev < price.buyPrice.value.toNumber()
            ? price.buyPrice.value.toNumber()
            : prev,
        0,
      );

    if (isMounted()) {
      setLeftMargin(getLeftMargin(largestPrice));
      setUse7DecimalsForYAxis(largestPrice < 0.01);
    }
  }, [generateIndicativePriceHistoryResponse.priceHistory, isMounted]);

  useEffect(() => {
    if (generateIndicativePriceHistoryResponse.priceHistory.length === 0) {
      return;
    }

    if (!valuationTokenViewModel.token.isUndefined()) {
      return;
    }

    (async () => {
      try {
        const retrievedModel = await getLedgerTokenViewModel(
          generateIndicativePriceHistoryResponse.priceHistory[0].buyPrice.token,
        );
        if (isMounted()) {
          setValuationTokenViewModel(retrievedModel);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting ledger on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error getting ledger on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
    })();
  }, [
    generateIndicativePriceHistoryResponse.priceHistory,
    valuationTokenViewModel,
    getLedgerTokenViewModel,
    isMounted,
    enqueueSnackbar,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function PerformanceChartTooltip({ active, payload }: any) {
    if (active && payload && payload.length) {
      const buyIsSmallNumber = (
        payload[0].payload as IndicativePrice
      ).buyPrice.value.isLessThan(new BigNumber(0.1));
      const sellIsSmallNumber = (
        payload[0].payload as IndicativePrice
      ).sellPrice.value.isLessThan(new BigNumber(0.1));
      const historicalPrice = payload[0].payload as IndicativePriceDataPoint;
      return (
        <Card
          className={classes.performanceChartTooltipPadding}
          sx={(theme) => ({ backgroundColor: theme.palette.custom.grape })}
        >
          <Typography variant="body1" component="p" color="textPrimary">
            <Typography
              className={classes.bold}
              variant="body1"
              component="span"
            >
              {`Buy Price : ${
                valuationTokenViewModel.token.code
              } ${formatTextNum(historicalPrice.buyPrice.value, {
                noDecimalPlaces: buyIsSmallNumber ? 7 : 2,
                addDecimalPadding: !buyIsSmallNumber,
              })}`}
            </Typography>
            <br />
            {`Sell Price : ${
              valuationTokenViewModel.token.code
            } ${formatTextNum(historicalPrice.sellPrice.value, {
              noDecimalPlaces: sellIsSmallNumber ? 7 : 2,
              addDecimalPadding: !sellIsSmallNumber,
            })}`}
            <br />
            {`Date : ${dayjs(historicalPrice.date).format("YYYY/MM/DD")}`}
          </Typography>
        </Card>
      );
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomizedXAxisLabel(componentProps: any) {
    const { x, y, width, height } = componentProps.viewBox;
    const cx = x + width / 2;
    const cy = y + height / 2 + 20;
    return (
      <g>
        <text
          fill={theme.palette.text.primary}
          textAnchor="middle"
          x={cx}
          y={cy + 8}
        >
          DAY
        </text>
      </g>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomizedYAxisLabel(componentProps: any) {
    const { x, y, height } = componentProps.viewBox;
    const cx = x - leftMargin - 6;
    const cy = y - height / 2;
    return (
      <g>
        <Typography
          sx={{
            writingMode: "tb-rl",
            transform: "rotate(-180deg)",
          }}
          component="text"
          fill={theme.palette.text.primary}
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {valuationTokenViewModel.token.code}
        </Typography>
      </g>
    );
  }

  const xAxisTickFormatter = (time: dayjs.ConfigType) =>
    dayjs(time).format("YY/MM/DD");

  const yAxisTickFormatter = (price: string | BigNumber) =>
    formatTextNum(price, {
      noDecimalPlaces: use7DecimalsForYAxis ? 7 : 2,
      addDecimalPadding: true,
    });

  if (loading) {
    return (
      <StyledBox
        sx={{
          height: 310,
          width: "100%",
          paddingRight: theme.spacing(4),
        }}
      >
        <Skeleton width={"100%"} height={"100%"} />
      </StyledBox>
    );
  }

  if (!generateIndicativePriceHistoryResponse.priceHistory.length) {
    return (
      <Box
        sx={{
          height: 310,
          margin: theme.spacing(4, 0, 3, 0),
          paddingRight: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(2),
            paddingRight: "22px",
          }}
        >
          <Typography
            sx={{ marginLeft: "auto" }}
            className={classes.zoomText}
            variant={"body1"}
          >
            ZOOM
          </Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"autocomplete-timeRangeFilter"}
            options={AllDateFilters}
            value={selectedDateFilter}
            disableClearable
            classes={{ inputRoot: classes.autoCompleteInput }}
            className={classes.PriceTimeRangeFilterAutocompleteWidth}
            onChange={(_, v) => {
              setSelectedDateFilter(v as DateFilter);
              setGenerateIndicativePriceHistoryRequest({
                ...generateIndicativePriceHistoryRequest,
                dateFilter: v as DateFilter,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 260,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography children={"No Prices To Show"} />
          <Typography
            variant={"caption"}
            sx={{ color: "text.secondary" }}
            children={"Try Selecting a Different Time Range"}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: 310,
        margin: theme.spacing(4, 0, 3, 0),
        paddingRight: theme.spacing(3),
        display: "grid",
        gridTemplateColumns: "auto",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(2),
          paddingRight: "22px",
        }}
      >
        <Typography
          sx={{ marginLeft: "auto" }}
          className={classes.zoomText}
          variant={"body1"}
        >
          ZOOM
        </Typography>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id={"autocomplete-timeRangeFilter"}
          options={AllDateFilters}
          value={selectedDateFilter}
          disableClearable
          classes={{ inputRoot: classes.autoCompleteInput }}
          className={classes.PriceTimeRangeFilterAutocompleteWidth}
          onChange={(_, v) => {
            setSelectedDateFilter(v as DateFilter);
            setGenerateIndicativePriceHistoryRequest({
              ...generateIndicativePriceHistoryRequest,
              dateFilter: v as DateFilter,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={"outlined"}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </Box>
      <Box sx={(theme) => ({ height: theme.spacing(2) })} />
      <ResponsiveContainer width={"100%"} height={260}>
        <AreaChart
          data={generateIndicativePriceHistoryResponse.priceHistory}
          margin={{
            left: leftMargin,
            right: 0,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <YAxis
            label={<CustomizedYAxisLabel />}
            tick={{
              fontSize: "10px",
              fill: theme.palette.text.secondary,
            }}
            dataKey={(val: IndicativePriceDataPoint) =>
              val.buyPrice.value.toNumber()
            }
            domain={["auto", "auto"]}
            tickFormatter={yAxisTickFormatter}
          />
          <XAxis
            label={<CustomizedXAxisLabel />}
            dataKey="date"
            tickFormatter={xAxisTickFormatter}
            tick={{
              fontSize: "10px",
              fill: theme.palette.text.secondary,
            }}
          />
          <Area
            dataKey={(val: IndicativePriceDataPoint) =>
              val.buyPrice.value.toNumber()
            }
            stroke="#DE1B5580" // Todo colour come from the theme or use defs
            fill="url(#colorPrimary)"
            fillOpacity={1}
          />
          <Tooltip cursor={false} content={<PerformanceChartTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

const PeriodResolutionMap: {
  [key: string]: Resolution;
} = {
  [Period._1D]: Resolution.Hour,
  [Period._1W]: Resolution.Day,
  [Period._1M]: Resolution.Day,
  [Period._3M]: Resolution.Day,
  [Period._6M]: Resolution.Day,
  [Period._1Y]: Resolution.Day,
  [Period._ALL]: Resolution.Day,
};

const first_XLM_mZAR_Date = dayjs("2022/04/22").format("YYYY/MM/DD");

export const PriceHistorianGraph = (props: Props) => {
  const theme = useTheme();

  const isMounted = useIsMounted();

  // on load determine default quote token
  const [defaultQuoteToken, setDefaultQuoteToken] = useState<Token | undefined>(
    undefined,
  );
  useEffect(() => {
    // find spot market mechanism
    const spotMarketMechanism =
      props.marketListingViewModel.listingMarketMechanisms.find(
        (mm) => mm.type === MechanismType.Spot,
      );
    if (!spotMarketMechanism) {
      console.error("expected to find spot market mechanism");
      return;
    }
    if (!spotMarketMechanism.quoteParameters.length) {
      console.error("expected at least 1 quote parameter");
      return;
    }

    // get default quote parameter
    let defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
      (qp) => qp.quoteToken.code === "mZAR",
    );
    if (!defaultQuoteParameter) {
      defaultQuoteParameter = spotMarketMechanism.quoteParameters.find(
        (qp) => qp.quoteToken.code === "USDC",
      );
    }
    if (!defaultQuoteParameter && spotMarketMechanism.quoteParameters.length) {
      defaultQuoteParameter = spotMarketMechanism.quoteParameters[0];
    }
    if (!defaultQuoteParameter) {
      console.error(
        "could not determine quote token to use for price history graph",
      );
      return;
    }
    setDefaultQuoteToken(defaultQuoteParameter.quoteToken);
  }, [props.marketListingViewModel]);

  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState<HistoricalPrice[]>([]);
  const [leftMargin, setLeftMargin] = useState(0);
  const [use7DecimalsForYAxis, setUse7DecimalsForYAxis] = useState(false);
  const priceHistoryTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(Period._1M);
  const { GetPriceForPeriod: PriceHistorianGetPriceForPeriod } =
    useRQPriceHistorian();
  const { errorContextErrorTranslator } = useErrorContext();
  useEffect(() => {
    if (!defaultQuoteToken) {
      return;
    }

    setLoading(true);
    clearTimeout(priceHistoryTimeoutRef.current);
    priceHistoryTimeoutRef.current = setTimeout(async () => {
      try {
        const newPrices = (
          await PriceHistorianGetPriceForPeriod({
            baseToken: props.marketListingViewModel.token,
            quoteToken: defaultQuoteToken,
            period: selectedPeriod,
            allFrom:
              selectedPeriod === Period._ALL
                ? dayjs(first_XLM_mZAR_Date)
                : undefined,
            resolution: PeriodResolutionMap[selectedPeriod] ?? Resolution.Day,
          })
        ).prices;

        // get the largest price in series
        const largestPrice = newPrices.reduce(
          (prev, price) =>
            prev < price.avgPrice.value.toNumber()
              ? price.avgPrice.value.toNumber()
              : prev,
          0,
        );

        if (isMounted()) {
          setLeftMargin(getLeftMargin(largestPrice));
          setPrices(newPrices);
          setUse7DecimalsForYAxis(largestPrice < 0.01);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting price history: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      if (isMounted()) {
        setLoading(false);
      }
    }, 200);
  }, [
    defaultQuoteToken,
    isMounted,
    props.marketListingViewModel,
    selectedPeriod,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function PerformanceChartTooltip({ active, payload }: any) {
    if (active && payload && payload.length) {
      if (!defaultQuoteToken) {
        return null;
      }
      try {
        const historicalPrice = payload[0].payload as HistoricalPrice;
        const smallDecimalNumber = historicalPrice.avgPrice.value.isLessThan(
          new BigNumber(0.1),
        );
        return (
          <Card
            className={classes.performanceChartTooltipPadding}
            sx={(theme) => ({ backgroundColor: theme.palette.custom.grape })}
          >
            <Typography variant={"body1"} component={"p"} color={"textPrimary"}>
              <Typography
                className={classes.bold}
                variant={"body1"}
                component={"span"}
              >
                {`Price : ${defaultQuoteToken.code} ${formatTextNum(
                  historicalPrice.avgPrice.value,
                  {
                    noDecimalPlaces: smallDecimalNumber ? 7 : 2,
                    addDecimalPadding: !smallDecimalNumber,
                  },
                )}`}
              </Typography>
              <br />
              {`Date : ${historicalPrice.time.format("YYYY/MM/DD")}`}
            </Typography>
          </Card>
        );
      } catch (e) {
        console.error(`error rendering crypto performance chart tooltip: ${e}`);
        return null;
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomizedXAxisLabel(componentProps: any) {
    const { x, y, width, height } = componentProps.viewBox;
    const cx = x + width / 2;
    const cy = y + height / 2 + 25;
    return (
      <g>
        <text
          fill={theme.palette.text.primary}
          textAnchor={"middle"}
          x={cx}
          y={cy}
        >
          DAY
        </text>
      </g>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomizedYAxisLabel(componentProps: any) {
    const { x, y, height } = componentProps.viewBox;
    const cx = x - leftMargin - 6;
    const cy = y - height / 2;
    return (
      <g>
        <Typography
          sx={{
            writingMode: "tb-rl",
            transform: "rotate(-180deg)",
          }}
          component="text"
          fill={theme.palette.text.primary}
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {defaultQuoteToken?.code ?? ""}
        </Typography>
      </g>
    );
  }

  const xAxisTickFormatter = (time: dayjs.ConfigType) =>
    dayjs(time).format("YY/MM/DD");

  const yAxisTickFormatter = (price: string | BigNumber) =>
    formatTextNum(price, {
      noDecimalPlaces: use7DecimalsForYAxis ? 7 : 2,
      addDecimalPadding: true,
    });

  if (loading || !defaultQuoteToken) {
    return (
      <Box
        sx={{
          height: 310,
          width: "100%",
          paddingRight: theme.spacing(4),
        }}
      >
        <Skeleton width={"100%"} height={"100%"} />
      </Box>
    );
  }

  if (!prices.length) {
    return (
      <Box
        sx={{
          height: 310,
          margin: theme.spacing(4, 0, 3, 0),
          paddingRight: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(2),
            paddingRight: "22px",
          }}
        >
          <Typography
            sx={{ marginLeft: "auto" }}
            className={classes.zoomText}
            variant={"body1"}
          >
            ZOOM
          </Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id={"autocomplete-timeRangeFilter"}
            options={AllPeriods}
            value={selectedPeriod}
            disableClearable
            classes={{ inputRoot: classes.autoCompleteInput }}
            className={classes.PriceTimeRangeFilterAutocompleteWidth}
            onChange={(_, v) => {
              if (v) {
                setSelectedPeriod(v);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 260,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography children={"No Prices To Show"} />
          <Typography
            variant={"caption"}
            sx={{ color: "text.secondary" }}
            children={"Try Selecting a Different Time Range"}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: 310,
        margin: theme.spacing(4, 0, 3, 0),
        paddingRight: theme.spacing(3),
        display: "grid",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(2),
          paddingRight: "22px",
        }}
      >
        <Typography
          sx={{ marginLeft: "auto" }}
          className={classes.zoomText}
          variant={"body1"}
        >
          ZOOM
        </Typography>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option === value}
          id={"autocomplete-timeRangeFilter"}
          options={AllPeriods}
          value={selectedPeriod}
          disableClearable
          classes={{ inputRoot: classes.autoCompleteInput }}
          className={classes.PriceTimeRangeFilterAutocompleteWidth}
          onChange={(_, v) => {
            if (v) {
              setSelectedPeriod(v);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={"outlined"}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </Box>
      <Box sx={(theme) => ({ height: theme.spacing(2) })} />
      <ResponsiveContainer width={"100%"} height={260}>
        <AreaChart
          data={prices}
          margin={{
            left: leftMargin,
            right: 0,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <YAxis
            label={<CustomizedYAxisLabel />}
            tick={{
              fontSize: "10px",
              fill: theme.palette.text.secondary,
            }}
            domain={["auto", "auto"]}
            dataKey={(val: HistoricalPrice) => val.avgPrice.value.toNumber()}
            tickFormatter={yAxisTickFormatter}
          />
          <XAxis
            label={<CustomizedXAxisLabel />}
            dataKey={(val: HistoricalPrice) => val.time.format()}
            tickFormatter={xAxisTickFormatter}
            tick={{
              fontSize: "10px",
              fill: theme.palette.text.secondary,
            }}
          />
          <Area
            dataKey={(val: HistoricalPrice) => val.avgPrice.value.toNumber()}
            stroke={"#DE1B5580"} // Todo colour come from the theme or use defs
            fill={"url(#colorPrimary)"}
            fillOpacity={1}
          />
          <Tooltip cursor={false} content={<PerformanceChartTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
