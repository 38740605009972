import { Context } from "james/security";
import { Identifier } from "james/search/identifier/Identifier";
import { Query } from "james/search/query";
import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Mint } from ".";

export interface RetrieveMintRequest {
  context: Context;
  identifier: Identifier;
}

export interface RetrieveMintResponse {
  mint: Mint;
}

export interface SearchMintRequest {
  context: Context;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchMintResponse {
  records: Mint[];
  total: number;
}

export const MintRepository = {
  serviceProvider: "ledger-MintRepository",
  async RetrieveMint(
    request: RetrieveMintRequest,
  ): Promise<RetrieveMintResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${MintRepository.serviceProvider}.RetrieveMint`,
      request,
    });
    return { mint: new Mint(response.mint) };
  },
  async SearchMint(request: SearchMintRequest): Promise<SearchMintResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthenticatedURL"),
      method: `${MintRepository.serviceProvider}.SearchMint`,
      request,
    });
    return {
      records: response.records.map((c: Mint) => new Mint(c)),
      total: response.total,
    };
  },
};
