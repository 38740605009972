import { AllLedgerNetworks } from "./Network";
import {
  LedgerAccountCategory,
  LedgerAccountState,
  AllLedgerAccountStates,
} from "./Account";
import { AllTransactionStates, TransactionState } from "./Transaction";
import {
  TransactionCollection,
  useSearchTransactions,
} from "./TransactionCollection";
import { TokenIconController as LedgerTokenIconController } from "./TokenIconController";
import { TransactionSubmissionResolver } from "./TransactionSubmissionResolver";
import { Token } from "./Token";
import { Amount } from "./Amount";
import BigNumber from "bignumber.js";

export * from "./AssetTokeniser";
export * from "./AssetFetcher";
export * from "./Mint";
export * from "./MintRepository";
export * from "./TransactionInspectorErrors";
export * from "./TransactionBatchRepository";
export * from "./TransactionQueueEntryRepository";
export * from "./OwnershipCertificateGeneratorCoordinator";
export * from "./AssetWrapper";

export function shortenIssuer(issuer: string): string {
  if (issuer && issuer.length <= 10) {
    return issuer;
  }
  return `${issuer.slice(0, 5)}...${issuer.slice(-5)}`;
}

export const MaxStateResolutionCount = 10;

export const PlatformMinimumDealSize = new BigNumber("0.00002");

export {
  Token,
  Amount,
  AllLedgerNetworks,
  LedgerAccountCategory,
  LedgerAccountState,
  TransactionState,
  AllTransactionStates,
  LedgerTokenIconController,
  TransactionCollection,
  useSearchTransactions,
  TransactionSubmissionResolver,
  AllLedgerAccountStates,
};
