import { useClientDetailsViewStore } from "../store";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { Manager } from "james/client/ManagerRead";
import { IDIdentifier } from "james/search/identifier";
import { ClientKYBStateController } from "james/kyb/clientKYBStateController";
import { ClientKind } from "james/client/Client";
import { IndividualClientKYCStateController } from "james/kyc";

export const useCompanyClientKYCStatusUpdater = () => {
  const store = useClientDetailsViewStore();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { authContext } = useApplicationContext();

  const markClientKYCStatusVerified = async (client: Client) => {
    const StateController =
      client.clientKind === ClientKind.IndividualType
        ? IndividualClientKYCStateController
        : ClientKYBStateController;
    store.setLoading(true);
    try {
      await StateController.SetKYCStatusVerified({
        context: authContext,
        clientID: client.id,
      });

      const response = await Manager.RetrieveClientUnscoped({
        context: authContext,
        identifier: IDIdentifier(client.id),
      });

      store.setCachedClient(response.client);
      store.setStateFrom(response.client);
      store.setLastUpdateBy(response.client.auditEntry.userID);
      store.setLastUpdateDate(response.client.auditEntry.time);

      // Display success message.
      enqueueSnackbar("Client KYC status set to verified", {
        variant: "success",
      });
    } catch (e) {
      // Handle errors.
      console.error(`error setting KYC status to verified`, e);

      errorContextDefaultErrorFeedback(e, "Error Setting KYC Status Verified");
    }
    store.setLoading(false);
  };

  const markClientKYCStatusOutstanding = async (client: Client) => {
    const StateController =
      client.clientKind === ClientKind.IndividualType
        ? IndividualClientKYCStateController
        : ClientKYBStateController;
    store.setLoading(true);
    try {
      await StateController.SetKYCStatusOutstanding({
        context: authContext,
        clientID: client.id,
        reason: store.kycStatusReason,
      });

      const response = await Manager.RetrieveClientUnscoped({
        context: authContext,
        identifier: IDIdentifier(client.id),
      });

      store.setCachedClient(response.client);
      store.setStateFrom(response.client);
      store.setLastUpdateBy(response.client.auditEntry.userID);
      store.setLastUpdateDate(response.client.auditEntry.time);

      // Display success message.
      enqueueSnackbar("Client KYC status set to outstanding", {
        variant: "success",
      });
    } catch (e) {
      console.error(`error setting KYC status to outstanding`, e);
      errorContextDefaultErrorFeedback(
        e,
        "Error Setting KYC Status to Outstanding",
      );
    }
    store.setLoading(false);
  };

  return {
    markClientKYCStatusVerified,
    markClientKYCStatusOutstanding,
  };
};
