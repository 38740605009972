import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionOrderBook } from "./SubscriptionOrderBook";
import { Amount, Token } from "james/ledger";
import { Dayjs } from "dayjs";

export type NewSubscriptionOrderBookRequest = {
  context: Context;
  token: Token;
  UnitPrice: Amount;
  OpenDate: Dayjs;
  CloseDate: Dayjs;
  SettlementDate: Dayjs;
  FractionalisationAllowed?: boolean;
  SubscriptionAmount: Amount;
  OverSubscriptionAmount: Amount;
};

export type NewSubscriptionOrderBookResponse = {
  SubscriptionOrderBook: SubscriptionOrderBook;
};

export type ResolveSubscriptionOrderBookIDStateRequest = {
  context: Context;
  subscriptionOrderBookID: string;
  ignoreResolutionCount: boolean;
};

export type ResolveSubscriptionOrderBookIDStateResponse = {
  subscriptionOrderBook: SubscriptionOrderBook;
};

export const SubscriptionOrderBookStateControllerServiceProviderName =
  "market-SubscriptionOrderBookStateController";

export const SubscriptionOrderBookStateController = {
  async ResolveSubscriptionOrderBookIDState(
    request: ResolveSubscriptionOrderBookIDStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveSubscriptionOrderBookIDStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookStateControllerServiceProviderName}.ResolveSubscriptionOrderBookIDState`,
        request,
      },
      opts,
    );

    return {
      subscriptionOrderBook: new SubscriptionOrderBook(
        response.subscriptionOrderBook,
      ),
    };
  },
  async NewSubscriptionOrderBook(
    request: NewSubscriptionOrderBookRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<NewSubscriptionOrderBookResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionOrderBookStateControllerServiceProviderName}.NewSubscriptionOrderBook`,
        request,
      },
      opts,
    );

    return {
      SubscriptionOrderBook: new SubscriptionOrderBook(
        response.SubscriptionOrderBook,
      ),
    };
  },
};
