import { useEffect } from "react";
import { useTransactionHistoryViewStore } from "../store";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { UnscopedReader as TransactionHistoryViewReader } from "james/views/stellarTransactionHistoryView/UnscopedReader";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import { Query } from "james/search/query";
import { Client } from "james/client";
import { UnscopedReader } from "james/views/stellarAccountView/UnscopedReader";
import { useStellarContext } from "context/Stellar";

export const useInitialDataFetch = (client: Client) => {
  const store = useTransactionHistoryViewStore();
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();

  useEffect(() => {
    const timeout = setTimeout(async () => {
      store.setLoading(true);
      try {
        const accountsResponse = await UnscopedReader.UnscopedRead({
          context: authContext,
          criteria: {
            ownerID: TextExactCriterion(client.ownerID),
          },
        });

        const accounts = await Promise.all(
          accountsResponse.models.map((m) =>
            stellarAccountContextPopulateModelWithLedgerDetails(m),
          ),
        );

        store.setAccounts(accounts);
        store.setSelectedAccount(accounts[0]);

        const request = {
          criteria: {
            accountID: TextListCriterion(accounts.map((account) => account.id)),
          },
          query: new Query({
            offset: 0,
            limit: 10,
            sorting: [
              {
                field: "sortableID",
                sortOrder: "desc",
              },
            ],
          }),
        };

        store.setRequest(request);

        const response = await TransactionHistoryViewReader.Read({
          context: authContext,
          criteria: request.criteria,
          query: request.query,
        });

        store.setTransactionViewModels(response.models);
        store.setTotal(response.total);
      } catch (e) {
        store.setLoading(false);
        const err = errorContextErrorTranslator.translateError(e);
        errorContextDefaultErrorFeedback(err);
      }

      store.setLoading(false);
    });

    return () => clearTimeout(timeout);
  }, []);
};
