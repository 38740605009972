import React from "react";
import { Client } from "james/client";
import { useTransactionHistoryViewStore } from "./store";
import { useInitialDataFetch } from "./hooks/useInitialDataFetch";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { BPTable } from "components/Table";
import { useSearchTransactionHistoryView } from "./hooks/useSearchTransactionHistoryView";
import { TextField } from "components/FormFields";
import { Model } from "james/views/stellarTransactionHistoryView";
import { Amount } from "components/Ledger/Amount";
import { TextExactCriterion } from "pkgTemp/search/criterion/text";
import {
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { FETable } from "components/Table/FETable";

interface TransactionDetailsViewProps {
  client: Client;
  readOnly?: boolean;
}

export const TransactionDetailsView = ({
  client,
}: TransactionDetailsViewProps) => {
  const store = useTransactionHistoryViewStore();
  useInitialDataFetch(client);
  const { searchTransactionHistoryView } = useSearchTransactionHistoryView();

  return (
    <Box
      className="meshScroll"
      sx={(theme) => ({
        pt: 3,
        overflowY: "auto",
        maxHeight: "calc(100% - 100px)",
        ".MuiAccordionSummary-root": {
          backgroundColor: theme.palette.custom.midnight,
        },
      })}
    >
      <Accordion>
        <AccordionSummary>
          <Typography>Account Balances</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FETable
            title=""
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            disableSelect
            filters={[
              <Autocomplete
                sx={{
                  width: 380,
                }}
                options={store.accounts}
                value={store.selectedHoldingsAccount}
                onChange={(e, v) => {
                  store.setSelectedHoldingsAccount(v);
                }}
                renderOption={(p, a) => (
                  <MenuItem {...p}>
                    {a.number} - {a.category}
                  </MenuItem>
                )}
                getOptionLabel={(option) =>
                  `${option.number} - ${option.category}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Account"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />,
            ]}
            columns={[
              { field: "accountNumber", label: "Account Number" },
              {
                field: "name",
                label: "Asset Name",
              },
              {
                field: "amountHeld",
                label: "Units Held",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: any) => {
                  return <Amount amount={data.amountHeld} />;
                },
              },
            ]}
            data={(() => {
              let accounts = store.accounts;
              if (store.selectedHoldingsAccount) {
                accounts = accounts.filter(
                  (account) =>
                    account.number === store.selectedHoldingsAccount?.number,
                );
              }
              return accounts
                .map((account) =>
                  account.balances.map((balance) => ({
                    accountNumber: `${account.number} - ${account.category}`,
                    amountHeld: balance.amount,
                    name: balance.tokenViewModel.name,
                  })),
                )
                .flat();
            })()}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Transaction History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BPTable
            disableSelect
            style={(theme) => ({
              ".BPTable-tableWrapper": {
                backgroundColor: theme.palette.custom.grape,
              },
            })}
            columns={[
              { field: "accountID", label: "accountID" },
              {
                field: "description",
                label: "description", // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: any) => {
                  const m = data as Model;
                  return (
                    <Tooltip
                      title={m.description.length < 50 ? "" : m.description}
                    >
                      <Typography noWrap sx={{ maxWidth: 400 }}>
                        {m.description}
                      </Typography>
                    </Tooltip>
                  );
                },
              },
              {
                field: "amount",
                label: "amount",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: any) => {
                  const m = data as Model;
                  return <Amount amount={m.amount} />;
                },
              },
              {
                field: "balance",
                label: "balance",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                accessor: (data: any) => {
                  const m = data as Model;
                  return <Amount amount={m.balance} />;
                },
              },
            ]}
            query={store.request.query}
            onQueryChange={(q) => {
              store.setQuery(q);
              searchTransactionHistoryView({
                criteria: store.request.criteria,
                query: q,
              });
            }}
            filters={[
              <Autocomplete
                sx={{
                  width: 380,
                }}
                options={store.accounts}
                value={store.selectedAccount}
                onChange={(e, v) => {
                  store.setSelectedAccount(v);
                  if (v) {
                    store.setCriteria({
                      ...store.request.criteria,
                      accountID: TextExactCriterion(v.id),
                    });
                    searchTransactionHistoryView({
                      criteria: {
                        ...store.request.criteria,
                        accountID: TextExactCriterion(v.id),
                      },
                      query: store.request.query,
                    });
                  } else {
                    store.setCriteria({
                      ...store.request.criteria,
                      accountID: TextListCriterion(
                        store.accounts.map((a) => a.id),
                      ),
                    });
                    searchTransactionHistoryView({
                      criteria: {
                        ...store.request.criteria,
                        accountID: TextListCriterion(
                          store.accounts.map((a) => a.id),
                        ),
                      },
                      query: store.request.query,
                    });
                  }
                }}
                renderOption={(p, a) => (
                  <MenuItem {...p}>
                    {a.number} - {a.category}
                  </MenuItem>
                )}
                getOptionLabel={(option) =>
                  `${option.number} - ${option.category}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Account"}
                    variant={"outlined"}
                    margin={"dense"}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />,
              <TextField
                sx={{
                  width: 220,
                }}
                label="search"
                onChange={(v) => {
                  if (v.target.value) {
                    store.setCriteria({
                      ...store.request.criteria,
                      $or: [
                        { description: TextSubstringCriterion(v.target.value) },
                        { accountID: TextSubstringCriterion(v.target.value) },
                      ],
                    });
                    searchTransactionHistoryView({
                      criteria: {
                        ...store.request.criteria,
                        $or: [
                          {
                            description: TextSubstringCriterion(v.target.value),
                          },
                          { accountID: TextSubstringCriterion(v.target.value) },
                        ],
                      },
                      query: store.request.query,
                    });
                  } else {
                    const c = store.request.criteria;
                    delete c["$or"];
                    store.setCriteria(c);
                    searchTransactionHistoryView({
                      criteria: store.request.criteria,
                      query: store.request.query,
                    });
                  }
                }}
              />,
            ]}
            title={"Transaction history view"}
            data={store.transactionViewModels}
            totalNoRecords={store.total}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
