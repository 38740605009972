import dayjs from "dayjs";
import { AuditEntry } from "james/audit";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { InstrumentStablecoin } from "./InstrumentStablecoin";
import { FinancialInstrumentStablecoinState } from "./InstrumentStablecoinState";

export const ETNStablecoinTypeName = "mesh::financial/ETNStablecoin";

export enum ETNStablecoinState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllETNStablecoinStates: ETNStablecoinState[] = [
  ETNStablecoinState.Draft,
  ETNStablecoinState.PreIssued,
  ETNStablecoinState.Issued,
  ETNStablecoinState.Matured,
  ETNStablecoinState.Cancelled,
  ETNStablecoinState.Deleted,
];

export enum ETNStablecoinAction {
  //
  // ETNStablecoinCreator Actions
  //
  New,

  //
  // ETNStablecoinStateChanger Actions
  //
  MarkDeleted,
  Issue,
  PreIssue,
  MarkIssued,
  Cancel,
  Mature,

  //
  // ETNStablecoinUpdater Actions
  //
  DraftUpdate,
  IncreaseMaximumUnits,
  DecreaseMaximumUnits,
  ChangeMaturityDate,
}

export class ETNStablecoin implements InstrumentStablecoin {
  public ["@type"]: string = ETNStablecoinTypeName;

  public id = "";

  public ownerID = "";

  public state: ETNStablecoinState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public shortName = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public token: Token = new Token();

  public instrumentID = "";

  public valuationToken: Token = new Token();

  public maximumUnits: Amount = new Amount();

  constructor(etnStablecoin?: ETNStablecoin) {
    if (!etnStablecoin) {
      return;
    }
    this.id = etnStablecoin.id;
    this.ownerID = etnStablecoin.ownerID;
    this.state = etnStablecoin.state;
    this.auditEntry = new AuditEntry(etnStablecoin.auditEntry);
    this.shortName = etnStablecoin.shortName;
    this.issueDate = etnStablecoin.issueDate;
    this.maturityDate = etnStablecoin.maturityDate;
    this.token = new Token(etnStablecoin.token);
    this.instrumentID = etnStablecoin.instrumentID;
    this.valuationToken = new Token(etnStablecoin.valuationToken);
    this.maximumUnits = new Amount(etnStablecoin.maximumUnits);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.shortName;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  instrumentStablecoinInstrumentID(): string {
    return this.instrumentID;
  }

  instrumentStablecoinValuationToken(): Token {
    return this.valuationToken;
  }

  instrumentStablecoinMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentStablecoinMaximumUnits(): Amount {
    return this.maximumUnits;
  }

  instrumentStablecoinState(): FinancialInstrumentStablecoinState {
    return this.state;
  }
}

export function getPotentialNextETNStablecoinActions(
  etnStablecoinState: ETNStablecoinState | "",
): ETNStablecoinAction[] {
  switch (etnStablecoinState) {
    case ETNStablecoinState.Draft: {
      return [
        ETNStablecoinAction.MarkDeleted,
        ETNStablecoinAction.DraftUpdate,
        ETNStablecoinAction.Issue,
        ETNStablecoinAction.PreIssue,
      ];
    }

    case ETNStablecoinState.PreIssued: {
      return [
        ETNStablecoinAction.MarkIssued,
        ETNStablecoinAction.Cancel,
        ETNStablecoinAction.IncreaseMaximumUnits,
        ETNStablecoinAction.DecreaseMaximumUnits,
        ETNStablecoinAction.ChangeMaturityDate,
      ];
    }

    case ETNStablecoinState.Issued:
      return [ETNStablecoinAction.Cancel, ETNStablecoinAction.Mature];

    case ETNStablecoinState.Matured:
      return [];

    case ETNStablecoinState.Cancelled:
      return [];

    case ETNStablecoinState.Deleted:
      return [];

    default:
      throw new TypeError(
        `invalid ETNStablecoinState: '${etnStablecoinState}'`,
      );
  }
}
