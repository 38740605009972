// source: financial/smartInstrumentReaderUNSCOPED_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js');
goog.object.extend(proto, financial_smartInstrument_pb);
var ledger_token_pb = require('../ledger/token_pb.js');
goog.object.extend(proto, ledger_token_pb);
var financial_assetClass_pb = require('../financial/assetClass_pb.js');
goog.object.extend(proto, financial_assetClass_pb);
var financial_smartInstrumentType_pb = require('../financial/smartInstrumentType_pb.js');
goog.object.extend(proto, financial_smartInstrumentType_pb);
var financial_unitCategory_pb = require('../financial/unitCategory_pb.js');
goog.object.extend(proto, financial_unitCategory_pb);
var ledger_amount_pb = require('../ledger/amount_pb.js');
goog.object.extend(proto, ledger_amount_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var i8n_timezone_pb = require('../i8n/timezone_pb.js');
goog.object.extend(proto, i8n_timezone_pb);
var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js');
goog.object.extend(proto, financial_smartInstrumentLeg_pb);
var financial_callabilityConfiguration_pb = require('../financial/callabilityConfiguration_pb.js');
goog.object.extend(proto, financial_callabilityConfiguration_pb);
var document_document_pb = require('../document/document_pb.js');
goog.object.extend(proto, document_document_pb);
var financial_smartInstrumentReader_meshproto_pb = require('../financial/smartInstrumentReader_meshproto_pb.js');
goog.object.extend(proto, financial_smartInstrumentReader_meshproto_pb);
