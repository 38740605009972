import React, { useEffect, useState } from "react";
import {
  alpha,
  Box,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { FETable } from "components/Table/FETable";
import { Model } from "james/views/ledgerAssetHolderView";
import { Reader } from "james/views/ledgerAssetHolderView/reader";
import { useApplicationContext } from "context/Application/Application";
import { Token } from "james/ledger";
import { useErrorContext } from "context/Error";

import { AssetHoldersDialog } from "./components/AssetHoldersDialog";
import dayjs from "dayjs";
import { Amount } from "../Amount/Amount";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField } from "components/FormFields";
import ClearIcon from "@mui/icons-material/Clear";
import { FaceOutlined } from "@mui/icons-material";

export interface AssetHoldersTable {
  token: Token;
}

export const AssetHoldersTable = ({ token }: AssetHoldersTable) => {
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();

  const [data, setData] = useState<Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [randInt, setRandInt] = useState(0);
  const [loadCard, setLoadCard] = useState(false);
  const [textFilter, setTextFilter] = useState("");

  useEffect(() => {
    setTextFilter("");
    if (!loading && !loadCard) return;
    setTimeout(async () => {
      try {
        const response = await Reader.Read({
          context: authContext,
          token: token,
        });

        setData(
          response.models.sort((a, b) => {
            if (a.holderName > b.holderName) {
              return 1;
            } else {
              return -1;
            }
          }),
        );
        setLoadCard(false);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setLoadCard(false);
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving asset holder view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    });
  }, [randInt, loadCard]);

  const filteredData = data.filter((v) => {
    if (textFilter === "") return true;

    const lowerTextFilter = textFilter.toLocaleLowerCase();

    const matchesAccountNumber = v.accountNumber
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesHolderEmail = v.holderEmailAddress
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesHolderName = v.holderName
      .toLocaleLowerCase()
      .includes(lowerTextFilter);
    const matchesAccountLedgerID = v.accountLedgerID
      .toLocaleLowerCase()
      .includes(lowerTextFilter);

    return (
      matchesAccountNumber ||
      matchesHolderEmail ||
      matchesHolderName ||
      matchesAccountLedgerID
    );
  });

  return (
    <Box>
      {loadCard || (data.length === 0 && !loading) ? (
        <NoDataCard
          title={"No Asset Holders"}
          subTitle={["There aren't any asset holders"]}
          refresh={() => {
            setLoadCard(true);
          }}
          loading={loadCard}
        />
      ) : (
        <FETable
          filters={[
            <TextField
              label="Text Search"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              InputProps={{
                endAdornment: textFilter ? (
                  <InputAdornment
                    position={"end"}
                    children={
                      <IconButton
                        id={
                          "financialDigitalInstrumentsTable-textFilterClearButton-iconButton"
                        }
                        size={"small"}
                        onClick={() => setTextFilter("")}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                  />
                ) : undefined,
              }}
            />,
          ]}
          loading={loading}
          height={506}
          disableSelect
          columns={[
            // Holder Name
            {
              label: "Name",
              field: "holderName",
            },
            // Amount Held
            {
              label: "Notes",
              field: "amountHeld",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const viewModel = data as Model;
                return (
                  <Amount
                    rootStyles={{
                      "& > .Amount-tokenAmountIssuer": {
                        display: "none",
                      },
                    }}
                    amount={viewModel.amountHeld}
                  />
                );
              },
            },
            // Issue Date
            {
              label: "Asset Issue Date",
              field: "assetIssuanceDate",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const viewModel = data as Model;
                return (
                  <Typography>
                    {dayjs(viewModel.assetIssuanceDate).format(
                      "YYYY/MM/DD HH:mm",
                    )}
                  </Typography>
                );
              },
            },
            // Email Address
            {
              label: "email",
              field: "holderEmailAddress",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const viewModel = data as Model;
                return (
                  <Typography sx={{ maxWidth: 120 }} noWrap>
                    {viewModel.holderEmailAddress}
                  </Typography>
                );
              },
            },
            // Account Number
            {
              label: "Account Number",
              field: "accountNumber",
            },
            // Ledger Account ID
            {
              label: "Ledger Account",
              field: "accountLedgerID",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const viewModel = data as Model;
                return (
                  <Tooltip title={viewModel.accountLedgerID}>
                    <Typography noWrap sx={{ maxWidth: 140 }}>
                      {viewModel.accountLedgerID}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            // Address
            {
              label: "Address",
              field: "address.countryCode",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [p: string]: any }) => {
                const viewModel = data as Model;
                return <AddressColumn viewModel={viewModel} />;
              },
            },
          ]}
          data={filteredData}
          title={"Asset Holders"}
          toolBarControls={[
            <IconButton
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setRandInt(randInt + 1);
              }}
            >
              <RefreshIcon />
            </IconButton>,
          ]}
          style={(theme) => ({
            ".BPTable-tableWrapper": {
              backgroundColor: theme.palette.custom.grape,
            },
          })}
          noDataSplashComponent={
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaceOutlined
                sx={(theme) => ({
                  fontSize: 110,
                  color: alpha(theme.palette.background.default, 0.5),
                })}
              />
              <Typography
                variant="h4"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                  margin: theme.spacing(2, 0),
                })}
                children={"No Asset Holders Found"}
              />
              <Typography
                variant="h5"
                sx={{
                  width: "226px",
                  color: (theme) => theme.palette.text.disabled,
                  textAlign: "center",
                }}
                children={"Please try using different search criteria"}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

interface AddressColumnProps {
  viewModel: Model;
}

const AddressColumn = ({ viewModel }: AddressColumnProps) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Box>
      <AssetHoldersDialog
        closeDialog={() => setShowDialog(false)}
        open={showDialog}
        assetHolderView={viewModel}
      />
      <Typography
        component={Link}
        underline="none"
        onClick={() => setShowDialog(!showDialog)}
      >
        View
      </Typography>
    </Box>
  );
};

interface NoDataCardProps {
  title: string;
  subTitle: string[];
  refresh?: () => void;
  loading?: boolean;
}
export const NoDataCard = ({
  title,
  subTitle,
  refresh,
  loading,
}: NoDataCardProps) => (
  <Card
    sx={(theme) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      textAlign: "center",
      backgroundColor: theme.palette.custom.grape,
      height: 166,
      position: "relative",
    })}
  >
    {refresh && (
      <IconButton
        sx={{ position: "absolute", top: 4, right: 8 }}
        onClick={refresh}
      >
        <RefreshIcon />
      </IconButton>
    )}
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        <Typography
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          variant="h3"
          children={title}
          color="textSecondary"
        />
        {subTitle.map((text, idx) => {
          return (
            <Typography
              key={idx}
              variant="body1"
              children={text}
              color="textSecondary"
            />
          );
        })}
      </>
    )}
  </Card>
);
