import { AuditEntry } from "james/audit";
import { RoleType } from "james/role";
import { Permission } from "james/security/Permission";

export enum ClientKYCStatus {
  Outstanding = "Outstanding",
  VerificationInProgressStatus = "Verification In Progress",
  VerifiedStatus = "Verified",
}

export enum ClientType {
  ManagingCompanyType = "Managing Company",
  IssuerType = "Issuer",
  InvestorType = "Investor",
}

export enum ClientKind {
  IndividualType = "Individual",
  CompanyType = "Company",
}

export const AllClientTypes: ClientType[] = [
  ClientType.ManagingCompanyType,
  ClientType.IssuerType,
  ClientType.InvestorType,
];

export enum ClientStatus {
  ActiveStatus = "Active",
  InactiveStatus = "Inactive",
  CompleteProfile = "Complete Profile",
}

export const AllClientStatuses: ClientStatus[] = [
  ClientStatus.ActiveStatus,
  ClientStatus.InactiveStatus,
];

export const MapClientTypeToRoleType = (
  clientType: ClientType,
  clientKind?: ClientKind | "",
): RoleType[] => {
  switch (clientType) {
    case ClientType.ManagingCompanyType:
      return [
        RoleType.MeshCLIRole,
        RoleType.FinancialOfficerRole,
        RoleType.ConsistencyControllerRole,
        RoleType.ConsistencyViewerRole,
        RoleType.ComplianceOfficerRole,
        RoleType.PlatformAdministratorRole,
        RoleType.MarketManagerRole,
        RoleType.ManagingCompanyRootOnlyRole,
        RoleType.ManagingCompanyOperationsRole,
      ];
    case ClientType.IssuerType:
      return [
        RoleType.AdministratorRole,
        RoleType.IssuerOperationsRole,
        RoleType.IssuerRole,
        RoleType.IssuerDealer,
      ];
    case ClientType.InvestorType:
      switch (clientKind) {
        case ClientKind.CompanyType:
          return [
            RoleType.AdministratorRole,
            RoleType.InvestorOperationsRole,
            RoleType.InvestorDealer,
          ];
        case ClientKind.IndividualType:
          return [RoleType.InvestorRole];
        default:
          return [];
      }
    default:
      return [];
  }
};

export const ClientTypeName = "mesh::client/Client";

export class Client {
  public ["@type"]: string = ClientTypeName;

  public id = "";

  public ownerID = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public clientTypes: ClientType[] = [];

  public clientKind: ClientKind | "" = "";

  public legalEntityID = "";

  public kycResetDate = "";

  public kycVerificationDate = "";

  public name = "";

  public shortName = "";

  public registrationDate = "";

  public status: ClientStatus | "" = "";

  public kycStatus: ClientKYCStatus | "" = "";

  public kycStatusReason = "";

  public description = "";

  public website = "";

  public linkedIn = "";

  public twitter = "";

  public facebook = "";

  public instagram = "";

  public otherSite = "";

  public youtube = "";

  public paasAgreementVersion = "";

  public settings: Settings = new Settings();

  constructor(client?: Client) {
    if (!client) {
      return;
    }
    this.id = client.id;
    this.ownerID = client.ownerID;
    this.auditEntry = new AuditEntry(client.auditEntry);
    this.clientTypes = client.clientTypes.map((c) => c);
    this.clientKind = client.clientKind;
    this.legalEntityID = client.legalEntityID;
    this.kycResetDate = client.kycResetDate;
    this.kycVerificationDate = client.kycVerificationDate;
    this.name = client.name;
    this.shortName = client.shortName;
    this.registrationDate = client.registrationDate;
    this.status = client.status;
    this.kycStatus = client.kycStatus;
    this.kycStatusReason = client.kycStatusReason;
    this.description = client.description;
    this.website = client.website;
    this.linkedIn = client.linkedIn;
    this.twitter = client.twitter;
    this.otherSite = client.otherSite;
    this.instagram = client.instagram;
    this.facebook = client.facebook;
    this.youtube = client.youtube;
    this.paasAgreementVersion = client.paasAgreementVersion;
    this.settings = client.settings;
  }
}

export class Settings {
  public muaSettings: MUASettings = new MUASettings();

  constructor(settings?: Settings) {
    if (!settings) {
      return;
    }
    this.muaSettings = settings.muaSettings;
  }
}

export class MUASettings {
  public enabledFor: Permission[] = [];

  constructor(muaSettings?: MUASettings) {
    if (!muaSettings) {
      return;
    }
    this.enabledFor = muaSettings.enabledFor;
  }
}
