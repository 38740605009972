import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import {
  CallabilitySection,
  DocumentsSection,
  InstrumentSection,
  LegalSection,
} from "./components";

export type InstrumentFormProps = {
  height: number;
};

enum InstrumentFormTab {
  Instrument = "Instrument",
  Callability = "Callability",
  Documents = "Documents",
  Legal = "Legal",
}

const allInstrumentFormTabs: InstrumentFormTab[] = [
  InstrumentFormTab.Instrument,
  InstrumentFormTab.Callability,
  InstrumentFormTab.Documents,
  InstrumentFormTab.Legal,
];

export const InstrumentForm = (props: InstrumentFormProps) => {
  const [selectedTab, setSelectedTab] = useState(InstrumentFormTab.Instrument);

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeDark,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        })}
      >
        <Tabs scrollButtons visibleScrollbar value={selectedTab}>
          {allInstrumentFormTabs.map(
            (instrumentFormTab: InstrumentFormTab, tabIdx: number) => (
              <Tab
                key={tabIdx}
                value={instrumentFormTab}
                label={instrumentFormTab}
                onClick={() => setSelectedTab(instrumentFormTab)}
              />
            ),
          )}
        </Tabs>
      </Box>
      <Box
        className="meshScroll"
        sx={(theme) => ({
          padding: theme.spacing(2, 2, 0, 2),
          height: props.height - 50,
          overflowY: "auto",
          overflowX: "hidden",
        })}
      >
        {(() => {
          switch (selectedTab) {
            case InstrumentFormTab.Instrument:
              return <InstrumentSection />;

            case InstrumentFormTab.Callability:
              return <CallabilitySection />;

            case InstrumentFormTab.Documents:
              return <DocumentsSection />;

            case InstrumentFormTab.Legal:
              return <LegalSection />;

            default:
              return null;
          }
        })()}
      </Box>
    </>
  );
};
