import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Listing, Mechanism } from "james/market";

export interface UpdateListingMarketMechanismRequest {
  context: Context;
  listingID: string;
  marketMechanism: Mechanism;
}

export interface UpdateListingMarketMechanismResponse {
  listing: Listing;
}

const AssetStateListerServiceProviderName = "market-ListingUpdater";

export const ListingUpdater = {
  async UpdateListingMarketMechanism(
    request: UpdateListingMarketMechanismRequest,
    additionalHeaders?: Headers,
  ): Promise<UpdateListingMarketMechanismResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${AssetStateListerServiceProviderName}.UpdateListingMarketMechanism`,
      request,
    });

    return { listing: new Listing(response.listing) };
  },
};
