/**
 * @fileoverview gRPC-Web generated client stub for financialPaymentFundingView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: views/financialPaymentFundingView/modelReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')

var search_query_pb = require('../../search/query_pb.js')

var search_sorting_pb = require('../../search/sorting_pb.js')

var views_financialPaymentFundingView_model_pb = require('../../views/financialPaymentFundingView/model_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var ledger_amount_pb = require('../../ledger/amount_pb.js')

var views_financialPaymentFundingView_modelReader_meshproto_pb = require('../../views/financialPaymentFundingView/modelReader_meshproto_pb.js')
const proto = {};
proto.financialPaymentFundingView = require('./modelReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadOneModelRequest,
 *   !proto.financialPaymentFundingView.ReadOneModelResponse>}
 */
const methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
  grpc.web.MethodType.UNARY,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadOneModelRequest,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadOneModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadOneModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadOneModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadOneModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDClient.prototype.readOneModelUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadOneModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDPromiseClient.prototype.readOneModelUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadManyModelRequest,
 *   !proto.financialPaymentFundingView.ReadManyModelResponse>}
 */
const methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
  grpc.web.MethodType.UNARY,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadManyModelRequest,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadManyModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadManyModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadManyModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadManyModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDClient.prototype.readManyModelUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadManyModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDPromiseClient.prototype.readManyModelUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financialPaymentFundingView.ReadAllModelRequest,
 *   !proto.financialPaymentFundingView.ReadAllModelResponse>}
 */
const methodDescriptor_ModelReaderUNSCOPED_ReadAllModelUNSCOPED = new grpc.web.MethodDescriptor(
  '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadAllModelUNSCOPED',
  grpc.web.MethodType.UNARY,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadAllModelRequest,
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadAllModelResponse,
  /**
   * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  views_financialPaymentFundingView_modelReader_meshproto_pb.ReadAllModelResponse.deserializeBinary
);


/**
 * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financialPaymentFundingView.ReadAllModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financialPaymentFundingView.ReadAllModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDClient.prototype.readAllModelUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadAllModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadAllModelUNSCOPED,
      callback);
};


/**
 * @param {!proto.financialPaymentFundingView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financialPaymentFundingView.ReadAllModelResponse>}
 *     Promise that resolves to the response
 */
proto.financialPaymentFundingView.ModelReaderUNSCOPEDPromiseClient.prototype.readAllModelUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financialPaymentFundingView.ModelReaderUNSCOPED/ReadAllModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadAllModelUNSCOPED);
};


module.exports = proto.financialPaymentFundingView;

