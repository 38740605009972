"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allUnitCategories = void 0;
exports.unitCategoryToString = unitCategoryToString;
exports.stringToUnitCategory = stringToUnitCategory;
const unitCategory_pb_1 = require("./unitCategory_pb");
// Get all unit category as enum values
exports.allUnitCategories = Object.values(unitCategory_pb_1.UnitCategory).filter((value) => typeof value === "number");
// Define explicit mappings between UnitCategory enums and custom string representations
const unitCategoryToStringMapping = {
    [unitCategory_pb_1.UnitCategory.UNDEFINED_UNIT_CATEGORY]: "-",
    [unitCategory_pb_1.UnitCategory.NOTE_UNIT_CATEGORY]: "Note",
    [unitCategory_pb_1.UnitCategory.SHARE_UNIT_CATEGORY]: "Share",
    [unitCategory_pb_1.UnitCategory.PREFERENCE_SHARE_UNIT_CATEGORY]: "Preference Share",
    [unitCategory_pb_1.UnitCategory.BOND_UNIT_CATEGORY]: "Bond",
};
// Reverse mapping from string to UnitCategory enum
const stringToUnitCategoryMapping = {};
for (const [key, value] of Object.entries(unitCategoryToStringMapping)) {
    stringToUnitCategoryMapping[value] = Number(key);
}
class UnsupportedUnitCategoryError extends Error {
    constructor(unitCategory) {
        const message = `Unsupported UnitCategory: ${unitCategory}`;
        super(message);
        this.unitCategory = unitCategory;
    }
}
/**
 * Converts a UnitCategory enum instance to a custom string representation.
 * @param {UnitCategory} unitCategory - The unit category to convert.
 * @returns {string} The custom string representation of the unit category.
 */
function unitCategoryToString(unitCategory) {
    if (unitCategory in unitCategoryToStringMapping) {
        return unitCategoryToStringMapping[unitCategory];
    }
    else {
        throw new UnsupportedUnitCategoryError(unitCategory);
    }
}
class UnsupportedUnitCategoryStringError extends Error {
    constructor(unitCategoryStr) {
        const message = `Unsupported unit category string: ${unitCategoryStr}`;
        super(message);
        this.unitCategoryStr = unitCategoryStr;
    }
}
/**
 * Converts a custom string representation to a UnitCategory enum instance.
 * @param {string} unitCategoryStr - The custom string representation of the unit category.
 * @returns {UnitCategory} The corresponding UnitCategory enum instance.
 */
function stringToUnitCategory(unitCategoryStr) {
    if (unitCategoryStr in stringToUnitCategoryMapping) {
        return stringToUnitCategoryMapping[unitCategoryStr];
    }
    else {
        throw new UnsupportedUnitCategoryStringError(unitCategoryStr);
    }
}
