import { useEffect, useState } from "react";
import { useMarketingInfoContext } from "./Context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MarketingMediaForm } from "./MarketingMediaForm";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Box,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { LedgerNetwork } from "james/ledger/Network";

export const MarketingInfo = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    assetInformation,
    listingInformation,
    initialisationError,
    clearInitialisationError,
  } = useMarketingInfoContext();
  const [tokenCode, setTokenCode] = useState<string | null>(null);

  useEffect(() => {
    // get the token for the asset that is to be listed from the url
    const urlTokenCode = searchParams.get("token-code");
    const urlTokenIssuer = searchParams.get("token-issuer");
    const urlTokenNetwork = searchParams.get("token-network") as LedgerNetwork;

    if (!(urlTokenCode && urlTokenIssuer && urlTokenNetwork)) {
      console.error("token not found in url");
      navigate({
        pathname: "/builder/marketing/table",
      });
      return;
    }
    setTokenCode(urlTokenCode);
  }, []);

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Builder"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while initialsing the builder.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (assetInformation === undefined || listingInformation === undefined) {
    return <div>Getting things ready</div>;
  }

  return (
    <>
      <Breadcrumbs
        sx={(theme) => ({ padding: theme.spacing(0.5) })}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="secondary" href="/builder">
          Issuance Hub
        </Link>
        <Link
          underline="hover"
          color="secondary"
          href="/builder/marketing/table"
        >
          Marketing Content Table
        </Link>
        <Typography color="text.secondary">{tokenCode}</Typography>
      </Breadcrumbs>
      <Box
        className={"meshScroll"}
        sx={{
          maxHeight: "92vh",
          overflowY: "auto",
          overflowX: "hidden",
          mb: 1,
        }}
      >
        <MarketingMediaForm
          asset={assetInformation}
          listing={listingInformation}
        />
      </Box>
    </>
  );
};
