import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getNoDecimals } from "utilities/number";
import { PlaceOrdersDialog } from "views/Marketplace/components/PlaceOrderDialog";
import { SpotTradeDialog } from "components/SpotTradeDialog/SpotTradeDialog";
import { Amount } from "components/Ledger/Amount";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { usePortfolioContext } from "context/Portfolio";
import { useApplicationContext } from "context/Application/Application";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Amount as AmountType,
  AssetTokeniserServiceProviderName,
  GenerateOwnershipCertificateRequest,
  OwnershipCertificateGeneratorCoordinator,
  Token,
} from "james/ledger";
import { Balance, Model } from "james/views/stellarAccountView";
import { DirectOrderType } from "james/market/DirectOrder";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { TextExactCriterion } from "james/search/criterion";
import { NativeAssetTokenCode } from "james/stellar";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import { Permission } from "james/security";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import {
  alpha,
  Box,
  Card,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { BurnAssetDialog } from "../BurnAssetDialog";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import { DefundAccountDialog } from "views/Accounts/components/DefundAccountDialog/DefundAccountDialog";
import { SetLimitDialog } from "views/Accounts/components/SetLimitDialog/SetLimitDialog";
import { TransferMainDialog } from "views/Accounts/components/TransferDialog/TransferMainDialog";
import { AssetTransactionHistoryTable } from "../AssetTransactionHistoryTable/AssetTransactionHistoryTable";
import { useRQMarketListingViewReader } from "hooks/reactQuery/useRQMarketListingViewReader";
import { Query } from "james/search/query";
import { AssetType } from "james/views/marketListingView/Model";
import { ListingState } from "james/market/Listing";
import { CustomMenuItem } from "./components/CustomMenuItem";
import { OffPlatformTransferMainDialog } from "views/Accounts/components/OffPlatformTransferDialog/OffPlatformTransferMainDialog";
import { useAssetColorPicker } from "hooks";
import { useErrorContext } from "context/Error";
import { IDIdentifier } from "james/search/identifier";
import { useSnackbar } from "notistack";

interface AssetCardProps {
  accountModel: Model;
  balance: Balance;
  assetValuation?: LedgerAmount;
  hideValuation?: boolean;
}

const directOrderTokenCategories: (TokenCategory | "")[] = [
  TokenCategory.InstrumentStablecoin,
  TokenCategory.DigitalInstrument,
];

const spotTokenCategories: (TokenCategory | "")[] = [
  TokenCategory.CryptoCurrency,
  TokenCategory.RightsToFiatCurrency,
  TokenCategory.RightsToCryptoCurrency,
  TokenCategory.YieldBearingStablecoin,
];

const fundableTokenCategories: (TokenCategory | "")[] = [
  TokenCategory.RightsToFiatCurrency,
];

const fundableTokenCodes = ["mZAR"];

export const AssetCard = (props: AssetCardProps): JSX.Element => {
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { viewConfiguration, myRolesRetrievalErr, myRoles } =
    useApplicationContext();
  const portfolioContext = usePortfolioContext();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const actionsVertButtonRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);
  const [showDefundDialog, setShowDefundDialog] = useState(false);
  const [showSetLimitDialog, setShowLimitDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showOffPlatformTransferDialog, setShowOffPlatformTransferDialog] =
    useState(false);
  const [showBurnAssetDialog, setShowBurnAssetDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showSpotTradeDialogForBaseToken, setShowSpotTradeDialogForBaseToken] =
    useState<Token | undefined>(undefined);
  const [showDirectOrderDialogType, setShowDirectOrderDialogType] = useState<
    DirectOrderType | undefined
  >(undefined);
  const [downloadingOwnershipCertificate, setDownloadingOwnershipCertificate] =
    useState(false);
  const { authContext } = useApplicationContext();
  // this variable checks if the asset is a bond
  const [digitalBondAsset, setDigitalBondAsset] = useState(false);
  const { Read: ReadMarketListingViewModel } = useRQMarketListingViewReader();
  const assetValuation = props.assetValuation;

  // this useEffect retrieves the market listing view model and checks
  //  if the listing asset type is a bond and disable [Buy/Sell/Trade] options
  // if asset type is a bond
  useEffect(() => {
    const request = {
      context: authContext,
      criteria: {
        listingState: TextExactCriterion(ListingState.Active),
        "token.code": TextExactCriterion(props.balance.amount.token.code),
        "token.network": TextExactCriterion(props.balance.amount.token.network),
        "token.issuer": TextExactCriterion(props.balance.amount.token.issuer),
      },
      query: new Query(),
    };
    (async () => {
      if (
        props.balance.tokenViewModel.tokenCategory !==
        TokenCategory.DigitalInstrument
      ) {
        return;
      }
      try {
        const readResponse = await ReadMarketListingViewModel(request);

        setDigitalBondAsset(
          !!readResponse.models.find((m) => m.assetType === AssetType.Bond) ||
            readResponse.total === 0,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving listing: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, []);

  const accountOverview = useMemo(() => {
    if (!props.accountModel) {
      return undefined;
    }

    if (!portfolioContext.accountOverviews) {
      return undefined;
    }

    return portfolioContext.accountOverviews.find((accOverview) => {
      if (props.accountModel) {
        return accOverview.accountID === props.accountModel.id;
      }
    });
  }, [props.accountModel, portfolioContext.accountOverviews]);

  // sets the max amount of decimals to be shown
  const decimalsForAmount = useMemo(
    (): number => getNoDecimals(props.balance.amount.value),
    [props.balance.amount],
  );

  const pnLAmountForAsset = useMemo(() => {
    if (!accountOverview) {
      return undefined;
    }

    return accountOverview?.pnlBreakdownPerAsset.find((v): boolean => {
      return v.token.isEqualTo(props.balance.tokenViewModel.token);
    })?.pnLAmount;
  }, [accountOverview, props.accountModel, portfolioContext.accountOverviews]);

  const assetAllocationPercentage = useMemo(() => {
    if (!accountOverview) {
      return undefined;
    }
    return accountOverview?.currentAssetAllocation.find((v) => {
      return v.asset.isEqualTo(props.balance.tokenViewModel.token);
    })?.assetAllocationPercentage;
  }, [accountOverview, props.accountModel, portfolioContext.accountOverviews]);

  const permFoundInGroup = useCallback(
    (permission: Permission, ownerID: string) => {
      if (!myRoles) {
        return false;
      }

      return !!myRoles.find(
        (r) =>
          r.ownerID === ownerID &&
          !!r.permissions.find(
            (p) =>
              p.serviceName === permission.serviceName &&
              p.serviceProvider === permission.serviceProvider,
          ),
      );
    },
    [myRoles, myRolesRetrievalErr],
  );

  const menuItems = useMemo(() => {
    // prepare list of items for the asset card actions menu
    const menuItem = [];

    if (
      [
        "",
        TokenCategory.InstrumentStablecoin,
        TokenCategory.RightsToCryptoCurrency,
        TokenCategory.RightsToFiatCurrency,
        TokenCategory.DigitalInstrument,
        TokenCategory.CryptoCurrency,
        TokenCategory.YieldBearingStablecoin,
      ].includes(props.balance.tokenViewModel.tokenCategory)
    ) {
      menuItem.push(
        <CustomMenuItem
          id={"assetCard-menuItem-view-asset"}
          componentKey={"assetCard-menuItem-view-asset"}
          code={props.balance.tokenViewModel.token.code}
          label={"View Asset"}
          onClick={() => {
            const t = props.balance.tokenViewModel.token;
            navigate(
              `/market/asset-overview?code=${t.code}&issuer=${t.issuer}&network=${t.network}`,
            );
          }}
        />,
      );
    }

    // add fund and defund buttons for fundable tokens
    if (
      fundableTokenCodes.includes(props.balance.tokenViewModel.token.code) &&
      fundableTokenCategories.includes(
        props.balance.tokenViewModel.tokenCategory,
      )
    ) {
      if (viewConfiguration.Wallet) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-fund-account"}
            componentKey={"assetCard-menuItem-fund-account"}
            code={props.balance.tokenViewModel.token.code}
            label={"Fund"}
            onClick={() => {
              setOpenMenu(false);
              setShowFundAccDialog(!showFundAccDialog);
            }}
          />,
        );
      }

      if (viewConfiguration.DefundAccount) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-defund-account"}
            componentKey={"assetCard-menuItem-defund-account"}
            code={props.balance.tokenViewModel.token.code}
            label={"Defund"}
            onClick={() => {
              setOpenMenu(false);
              setShowDefundDialog(!showDefundDialog);
            }}
          />,
        );
      }
    }

    // add buy and sell buttons for direct order assets
    if (
      directOrderTokenCategories.includes(
        props.balance.tokenViewModel.tokenCategory,
      ) &&
      !digitalBondAsset
    ) {
      if (
        viewConfiguration.DirectOrderTrading &&
        (viewConfiguration.DirectOrderTrading.InvestorPushBuy ||
          viewConfiguration.DirectOrderTrading.IssuerPushBuy)
      ) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-direct-order-buy"}
            componentKey={"assetCard-menuItem-direct-order-buy"}
            code={props.balance.tokenViewModel.token.code}
            label={"Buy"}
            onClick={() => {
              setOpenMenu(!openMenu);
              setShowDirectOrderDialogType(DirectOrderType.Buy);
            }}
          />,
        );
      }

      if (
        viewConfiguration.DirectOrderTrading &&
        (viewConfiguration.DirectOrderTrading.InvestorPushSell ||
          viewConfiguration.DirectOrderTrading.IssuerPushSell)
      ) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-direct-order-sell"}
            componentKey={"assetCard-menuItem-direct-order-sell"}
            code={props.balance.tokenViewModel.token.code}
            label={"Sell"}
            onClick={() => {
              setOpenMenu(!openMenu);
              setShowDirectOrderDialogType(DirectOrderType.Sell);
            }}
          />,
        );
      }
    }

    // add trade button for spot assets
    if (
      spotTokenCategories.includes(
        props.balance.tokenViewModel.tokenCategory,
      ) &&
      viewConfiguration?.SpotTrading?.SubmitSpot
    ) {
      menuItem.push(
        <CustomMenuItem
          id={"assetCard-menuItem-spot-spot-trade-dialog"}
          componentKey={"assetCard-menuItem-spot-spot-trade-dialog"}
          code={props.balance.tokenViewModel.token.code}
          label={"Trade"}
          onClick={() => {
            setOpenMenu(!openMenu);
            setShowSpotTradeDialogForBaseToken(
              props.balance.tokenViewModel.token,
            );
          }}
        />,
      );
    }

    // add option to allow for ownership certificate download for digital instruments
    if (
      ["", TokenCategory.DigitalInstrument].includes(
        props.balance.tokenViewModel.tokenCategory,
      ) &&
      props.balance.amount.value.gt(0) &&
      props.balance.tokenViewModel.token.code.length > 5
    ) {
      const menuItemToPush = (
        <CustomMenuItem
          id={"assetCard-menuItem-ownership-certificate-download"}
          componentKey={"assetCard-menuItem-ownership-certificate-download"}
          code={props.balance.tokenViewModel.token.code}
          label={
            downloadingOwnershipCertificate
              ? "Downloading"
              : "Download Ownership Certificate"
          }
          disabled={downloadingOwnershipCertificate}
          onClick={() => {
            const request: GenerateOwnershipCertificateRequest = {
              context: authContext,
              assetToken: props.balance.tokenViewModel.token,
              holdingAccountID: IDIdentifier(props.accountModel.accountID()),
            };
            enqueueSnackbar("Generating Ownership Certificate", {
              variant: "info",
            });
            setOpenMenu(!openMenu);
            setDownloadingOwnershipCertificate(true);
            OwnershipCertificateGeneratorCoordinator.GenerateOwnershipCertificate(
              request,
            )
              .then((response) => {
                // Create URL for decoded ownership certificate
                const url = URL.createObjectURL(response.certificate);
                // create download link
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  `${props.balance.tokenViewModel.token.code}.pdf`,
                );
                // create temporary download link on DOM
                document.body.appendChild(link);
                // initiate download automatically
                link.click();
                setDownloadingOwnershipCertificate(false);
                // clean up download link from DOM
                document.body.removeChild(link);
              })
              .catch((err) => {
                errorContextDefaultErrorFeedback(err);
                setDownloadingOwnershipCertificate(false);
              });
          }}
        />
      );

      if (downloadingOwnershipCertificate) {
        menuItem.push(
          <Box display="flex" alignItems="center" marginRight={3}>
            {menuItemToPush}
            <CircularProgress size={20} />
          </Box>,
        );
      } else {
        menuItem.push(menuItemToPush);
      }
    }

    // show set limit button for all assets other than XLM
    if (props.balance.tokenViewModel.token.code !== NativeAssetTokenCode) {
      if (viewConfiguration.SetAssetLimits) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-set-limit"}
            componentKey={"assetCard-menuItem-set-limit"}
            code={props.balance.tokenViewModel.token.code}
            label={"Set Limit"}
            onClick={() => {
              setOpenMenu(false);
              setShowLimitDialog(!showSetLimitDialog);
            }}
          />,
        );
      }
    }

    // do not show transfer button if the asset is liquidity pool shares OR
    // if the user doe not have the required transfer view configuration
    if (
      props.balance.tokenViewModel.tokenCategory !==
        TokenCategory.LiquidityPoolShares &&
      viewConfiguration.LedgerAccountTransfer
    ) {
      menuItem.push(
        <CustomMenuItem
          id={"assetCard-menuItem-transfer"}
          componentKey={"assetCard-menuItem-transfer"}
          code={props.balance.tokenViewModel.token.code}
          label={"Transfer"}
          onClick={() => {
            setOpenMenu(false);
            setShowTransferDialog(!showTransferDialog);
          }}
          data-link-info={JSON.stringify({
            content_interaction_id: "action-popup",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "tab>my account>assets>transfer",
            content_interaction_type: InteractionType.Link,
            content_interaction_driver: InteractionDriver.AccountTransfer,
          } as DataLinkInfoType)}
        />,
      );
    }

    // only show off platform transfer button for XLM
    if (
      props.balance.tokenViewModel.token.code === NativeAssetTokenCode &&
      viewConfiguration.OffPlatformTransfer
    ) {
      menuItem.push(
        <CustomMenuItem
          id={"assetCard-menuItem-off-platform-transfer"}
          componentKey={"assetCard-menuItem-off-platform-transfer"}
          code={props.balance.tokenViewModel.token.code}
          label={"Transfer Off Platform"}
          onClick={() => {
            setOpenMenu(false);
            setShowOffPlatformTransferDialog(!showOffPlatformTransferDialog);
          }}
        />,
      );
    }

    if (viewConfiguration.BurnAsset) {
      if (
        (
          [TokenCategory.InstrumentStablecoin] as unknown as TokenCategory | ""
        ).includes(props.balance.tokenViewModel.tokenCategory) &&
        permFoundInGroup(
          new Permission({
            serviceName: "BurnAsset",
            serviceProvider: AssetTokeniserServiceProviderName,
            description: "",
          }),
          props.balance.tokenViewModel.ownerID,
        ) &&
        props.balance.amount.value.gt(0)
      ) {
        menuItem.push(
          <CustomMenuItem
            id={"assetCard-menuItem-burn-asset"}
            componentKey={"assetCard-menuItem-burn-asset"}
            code={props.balance.tokenViewModel.token.code}
            label={"Burn Asset"}
            onClick={() => {
              setOpenMenu(false);
              setShowBurnAssetDialog(!showBurnAssetDialog);
            }}
          />,
        );
      }
    }

    return menuItem;
  }, [
    viewConfiguration.BurnAsset,
    viewConfiguration.DefundAccount,
    viewConfiguration.MarketplaceListingCard,
    viewConfiguration.SetAssetLimits,
    viewConfiguration.Wallet,
    viewConfiguration.LedgerAccountTransfer,
    viewConfiguration.OffPlatformTransfer,
    openMenu,
    downloadingOwnershipCertificate,
    props.balance.tokenViewModel.token.code,
    props.balance.tokenViewModel.tokenCategory,
    showDefundDialog,
    showFundAccDialog,
    showSetLimitDialog,
    showTransferDialog,
    permFoundInGroup,
  ]);

  return (
    <>
      {showBurnAssetDialog && (
        <BurnAssetDialog
          Token={props.balance.tokenViewModel.token}
          open={showBurnAssetDialog}
          onClose={() => setShowBurnAssetDialog(!showBurnAssetDialog)}
          AccountLedgerID={props.accountModel.ledgerID}
        />
      )}
      {showTransferDialog && (
        <TransferMainDialog
          tokenisationAllowed={!digitalBondAsset}
          token={props.balance.tokenViewModel.token}
          accountID={props.accountModel.id}
          open={showTransferDialog}
          onClose={() => setShowTransferDialog(!showTransferDialog)}
        />
      )}
      {showOffPlatformTransferDialog && (
        <OffPlatformTransferMainDialog
          token={props.balance.tokenViewModel.token}
          accountID={props.accountModel.id}
          open={showOffPlatformTransferDialog}
          onClose={() =>
            setShowOffPlatformTransferDialog(!showOffPlatformTransferDialog)
          }
        />
      )}
      {showSetLimitDialog && (
        <SetLimitDialog
          token={props.balance.tokenViewModel.token}
          accountID={props.accountModel.id}
          open={showSetLimitDialog}
          onClose={() => setShowLimitDialog(!showSetLimitDialog)}
        />
      )}
      {showFundAccDialog && (
        <FundAccountDialog
          onClose={() => setShowFundAccDialog(!showFundAccDialog)}
          open={showFundAccDialog}
          accountID={props.accountModel.id}
        />
      )}
      {showDefundDialog && (
        <DefundAccountDialog
          onClose={() => setShowDefundDialog(!showDefundDialog)}
          open={showDefundDialog}
          accountModel={props.accountModel}
        />
      )}
      {showDirectOrderDialogType && (
        <PlaceOrdersDialog
          closeDialog={() => setShowDirectOrderDialogType(undefined)}
          directOrderType={showDirectOrderDialogType}
          marketListingViewModelCriteria={{
            "token.code": TextExactCriterion(
              props.balance.tokenViewModel.token.code,
            ),
            "token.issuer": TextExactCriterion(
              props.balance.tokenViewModel.token.issuer,
            ),
            "token.network": TextExactCriterion(
              props.balance.tokenViewModel.token.network,
            ),
          }}
        />
      )}
      {!!showSpotTradeDialogForBaseToken && (
        <SpotTradeDialog
          sourceAccountID={props.accountModel.id}
          baseToken={showSpotTradeDialogForBaseToken}
          closeDialog={() => setShowSpotTradeDialogForBaseToken(undefined)}
          initialQuoteTokenCode={fundableTokenCodes[0]}
        />
      )}
      <Menu
        id={`assetCard-menu-${props.balance.tokenViewModel.token.code}`}
        anchorEl={actionsVertButtonRef.current}
        open={openMenu}
        onClose={() => setOpenMenu(!openMenu)}
        autoFocus={false}
      >
        {menuItems.map((item, idx) => {
          return React.cloneElement(item, { key: idx });
        })}
      </Menu>
      <Card
        id={`assetCard-${props.balance.tokenViewModel.token.code}`}
        data-testid={`assetCard-${props.balance.tokenViewModel.token.code}`}
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.cardInner,
          my: 2,
        })}
        data-link-info={JSON.stringify({
          content_interaction_id: "account-accordion",
          content_interaction_action: InteractionAction.Click,
          content_interaction_text: `tab>my account>assets>${props.balance.tokenViewModel.token.code}`,
          content_interaction_type: InteractionType.Accordion,
          content_interaction_driver: InteractionDriver.MoreInfo,
        } as DataLinkInfoType)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { sm: "100%", xs: "80%" },
              pl: 1,
              py: 1,
            }}
            onClick={() => setShowDetails(!showDetails)}
          >
            <Box
              sx={{
                pr: 1,
              }}
            >
              <IconButton onClick={() => setShowDetails(!showDetails)}>
                <ArrowForwardIosSharpIcon
                  sx={[
                    {
                      fontSize: "0.9rem",
                    },
                    showDetails && {
                      transform: "rotate(90deg)",
                    },
                  ]}
                  color="primary"
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pr: {
                  sm: 2,
                  xs: 1,
                },
              }}
            >
              <TokenIconViewUpload
                size={40}
                token={props.balance.tokenViewModel.token}
                tokenIconDownloadURL={
                  props.balance.tokenViewModel.publicIconURL
                }
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gap: 0.5,
              }}
            >
              {/* Name of asset */}
              <Box
                // needed to center
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  id={`accountDetails-assetName-typography-${props.balance.tokenViewModel.name}`}
                  sx={(theme) => ({
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: theme.palette.text.secondary,
                  })}
                  variant="h4"
                  children={props.balance.tokenViewModel.name}
                />
              </Box>
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Amount
                      reverse={[
                        TokenCategory.InstrumentStablecoin,
                        TokenCategory.DigitalInstrument,
                        TokenCategory.Unknown,
                        "",
                      ].includes(props.balance.tokenViewModel.tokenCategory)}
                      codeTypographyProps={{
                        sx: (theme) => ({
                          fontWeight: "bold",
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      formatTextNumOpts={{
                        noDecimalPlaces: decimalsForAmount,
                      }}
                      amount={props.balance.amount}
                    />
                    {props.balance.tokenViewModel.token.code !==
                      portfolioContext.valuationCurrency?.code &&
                      !props.hideValuation &&
                      assetValuation && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={(theme) => ({
                              ml: 1,
                              mr: 0.5,
                              fontSize: "12px",
                              color: theme.palette.text.tertiary,
                            })}
                            children="|"
                          />
                          <Amount
                            codeTypographyProps={{
                              variant: "caption",
                              sx: (theme) => ({
                                fontSize: "12px",
                                color: theme.palette.text.tertiary,
                              }),
                            }}
                            valueTypographyProps={{
                              variant: "caption",
                              sx: (theme) => ({
                                fontSize: "12px",
                                color: theme.palette.text.tertiary,
                              }),
                            }}
                            formatTextNumOpts={{
                              noDecimalPlaces: 2,
                            }}
                            amount={new AmountType(assetValuation)}
                          />
                        </Box>
                      )}
                  </Box>
                  {props.balance.tokenViewModel.token.code !==
                    portfolioContext.valuationCurrency?.code &&
                    pnLAmountForAsset && (
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          children={
                            pnLAmountForAsset.value.eq(0)
                              ? ""
                              : pnLAmountForAsset.value.gt(0)
                                ? "+"
                                : "-"
                          }
                          sx={(theme) => ({
                            color: pnLAmountForAsset.value.eq(0)
                              ? theme.palette.text.secondary
                              : pnLAmountForAsset.value.gt(0)
                                ? theme.palette.success.light
                                : theme.palette.error.light,
                            mx: 0.5,
                            fontSize: {
                              sm: "16px",
                              xs: "11px",
                            },
                          })}
                        />
                        {/* PNL AMOUNT */}
                        <Amount
                          codeTypographyProps={{
                            sx: (theme) => ({
                              fontWeight: "bold",
                              color: pnLAmountForAsset.value.eq(0)
                                ? theme.palette.text.disabled
                                : pnLAmountForAsset.value.gt(0)
                                  ? alpha(theme.palette.success.light, 0.38)
                                  : alpha(theme.palette.error.light, 0.38),
                              fontSize: {
                                sm: "16px",
                                xs: "11px",
                              },
                            }),
                          }}
                          valueTypographyProps={{
                            sx: (theme) => ({
                              color: pnLAmountForAsset.value.eq(0)
                                ? theme.palette.text.secondary
                                : pnLAmountForAsset.value.gt(0)
                                  ? theme.palette.success.light
                                  : theme.palette.error.light,
                              fontSize: {
                                sm: "16px",
                                xs: "11px",
                              },
                            }),
                          }}
                          formatTextNumOpts={{
                            noDecimalPlaces: 2,
                            disallowNegative: true,
                          }}
                          amount={new AmountType(pnLAmountForAsset)}
                        />
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              py: 2,
              pr: { sm: 2, xs: 1 },
              pl: 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenu(!openMenu);
            }}
          >
            <IconButton
              id={`accountDetails-actions-mdButton-${props.balance.tokenViewModel.token.code}`}
              size="small"
              sx={{
                color: "white",
              }}
              ref={actionsVertButtonRef}
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenu(!openMenu);
              }}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Box>
        {isMobile && (
          <Box
            sx={(theme) => ({
              p: {
                sm: theme.spacing(1, 2, 3, 2),
                xs: 2,
              },
              backgroundColor: theme.palette.custom.grape,
            })}
            onClick={() => setShowDetails(!showDetails)}
          >
            <Amount
              reverse={[
                TokenCategory.InstrumentStablecoin,
                TokenCategory.DigitalInstrument,
                TokenCategory.Unknown,
                "",
              ].includes(props.balance.tokenViewModel.tokenCategory)}
              codeTypographyProps={{
                sx: (theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.tertiary,
                }),
              }}
              formatTextNumOpts={{
                noDecimalPlaces: decimalsForAmount,
              }}
              amount={props.balance.amount}
            />
            {props.balance.tokenViewModel.token.code !==
              portfolioContext.valuationCurrency?.code && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {assetValuation && (
                    <Amount
                      codeTypographyProps={{
                        sx: (theme) => ({
                          fontSize: "11px",
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      valueTypographyProps={{
                        sx: (theme) => ({
                          fontSize: "11px",
                          color: theme.palette.text.secondary,
                        }),
                      }}
                      formatTextNumOpts={{
                        noDecimalPlaces: 2,
                        addDecimalPadding: false,
                      }}
                      amount={new AmountType(assetValuation)}
                    />
                  )}
                </Box>
                {pnLAmountForAsset && (
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      children={
                        pnLAmountForAsset.value.eq(0)
                          ? ""
                          : pnLAmountForAsset.value.gt(0)
                            ? "+"
                            : "-"
                      }
                      sx={(theme) => ({
                        color: pnLAmountForAsset.value.eq(0)
                          ? theme.palette.text.secondary
                          : pnLAmountForAsset.value.gt(0)
                            ? theme.palette.success.light
                            : theme.palette.error.light,
                        mx: 0.5,
                        fontSize: "11px",
                      })}
                    />
                    {/* PNL AMOUNT */}
                    <Amount
                      codeTypographyProps={{
                        sx: (theme) => ({
                          fontWeight: "bold",
                          color: pnLAmountForAsset.value.eq(0)
                            ? theme.palette.text.disabled
                            : pnLAmountForAsset.value.gt(0)
                              ? alpha(theme.palette.success.light, 0.38)
                              : alpha(theme.palette.error.light, 0.38),
                          fontSize: "11px",
                        }),
                      }}
                      valueTypographyProps={{
                        sx: (theme) => ({
                          color: pnLAmountForAsset.value.eq(0)
                            ? theme.palette.text.secondary
                            : pnLAmountForAsset.value.gt(0)
                              ? theme.palette.success.light
                              : theme.palette.error.light,
                          fontSize: "11px",
                        }),
                      }}
                      formatTextNumOpts={{
                        noDecimalPlaces: 2,
                        disallowNegative: true,
                      }}
                      amount={new AmountType(pnLAmountForAsset)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        <Collapse in={showDetails}>
          {assetAllocationPercentage && (
            <Box
              sx={(theme) => ({
                display: "flex",
                p: {
                  sm: theme.spacing(1, 7, 3, 6),
                  xs: theme.spacing(0, 2, 2, 2),
                },
                backgroundColor: theme.palette.custom.grape,
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
              })}
            >
              <AccAllocationBar
                percentage={assetAllocationPercentage}
                token={new Token(props.balance.tokenViewModel.token)}
              />
              <Typography
                variant="caption"
                sx={(theme) => ({
                  color: theme.palette.text.tertiary,
                  pb: {
                    sm: 0,
                    xs: 3,
                  },
                  pt: {
                    sm: 0,
                    xs: 1,
                  },
                  ml: { sm: 5 },
                })}
                align="left"
              >
                {props.balance.tokenViewModel.issuer}
              </Typography>
            </Box>
          )}
          <AssetTransactionHistoryTable
            accountLedgerID={props.accountModel.ledgerID}
            token={props.balance.tokenViewModel.token}
          />
        </Collapse>
      </Card>
    </>
  );
};

interface AccAllocationProps {
  percentage?: string;
  token: Token;
}

const AccAllocationBar = ({
  percentage,
  token,
}: AccAllocationProps): JSX.Element => {
  const getColor = useAssetColorPicker();
  return (
    <Box>
      <Typography
        variant="caption"
        sx={(theme) => ({
          color: theme.palette.text.tertiary,
          mb: 0.5,
        })}
      >
        Account Allocation
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{percentage ?? 0}%</Typography>
        <Box
          sx={{
            display: "flex",
            width: { sm: "120px", xs: "100%" },
            backgroundColor: alpha(
              getColor(`${token.code.toString()}:${token.issuer.toString()}`),
              0.38,
            ),
            height: "12px",
            borderRadius: "2px",
            ml: 2,
          }}
        >
          <Box
            sx={{
              width: `${percentage}%`,
              backgroundColor: getColor(
                `${token.code.toString()}:${token.issuer.toString()}`,
              ),
              height: "100%",
              borderRadius: "2px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
