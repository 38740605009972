import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Group } from "james/group/Group";
import cx from "classnames";
import {
  ChevronRight as ExpandCardIcon,
  ExpandMore as CollapseCardIcon,
} from "@mui/icons-material";
import { formatTextNum } from "utilities/number";
import { Model as StellarAccountViewModel } from "james/views/stellarAccountView";

const PREFIX = "AccountCard";

const classes = {
  accountCardTitleOverride: `${PREFIX}-accountCardTitleOverride`,
  cardRootOverride: `${PREFIX}-cardRootOverride`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderCardOpen: `${PREFIX}-cardHeaderCardOpen`,
  accountCardHeaderItemCaption: `${PREFIX}-accountCardHeaderItemCaption`,
  accountCardHeaderItem: `${PREFIX}-accountCardHeaderItem`,
  icon: `${PREFIX}-icon`,
  cardContentLayout: `${PREFIX}-cardContentLayout`,
  groupOwnerAvatarLayout: `${PREFIX}-groupOwnerAvatarLayout`,
  groupOwnerAvatar: `${PREFIX}-groupOwnerAvatar`,
  groupOwnerTeamTitleHeading: `${PREFIX}-groupOwnerTeamTitleHeading`,
  balanceTable: `${PREFIX}-balanceTable`,
  balanceTableHeaderCell: `${PREFIX}-balanceTableHeaderCell`,
  balanceTableBodyCell: `${PREFIX}-balanceTableBodyCell`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.accountCardTitleOverride}`]: {
    display: "flex",
  },

  [`& .${classes.cardRootOverride}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.cardHeader}`]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(
      1,
    )} 0px`,
    display: "grid",
    gridTemplateColumns: "auto auto auto auto 1fr",
    gridTemplateRow: "1fr",
    alignItems: "center",
  },

  [`& .${classes.cardHeaderCardOpen}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.accountCardHeaderItemCaption}`]: {
    color: theme.palette.text.hint,
  },

  [`& .${classes.accountCardHeaderItem}`]: {
    display: "grid",
    gridTemplateRows: "auto auto",
    gridRowGap: theme.spacing(0.5),
    gridTemplateColumns: "auto",
    marginRight: theme.spacing(2),
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.cardContentLayout}`]: {
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.groupOwnerAvatarLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "auto auto",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.groupOwnerAvatar}`]: {
    gridRow: "1/3",
    width: "35px",
    height: "35px",
    fontSize: "0.90rem",
  },

  [`& .${classes.groupOwnerTeamTitleHeading}`]: {
    color: theme.palette.text.hint,
  },

  [`& .${classes.balanceTable}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(6, auto)",
  },

  [`& .${classes.balanceTableHeaderCell}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.balanceTableBodyCell}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface AccountCardProps {
  stellarAccountViewModel: StellarAccountViewModel;
  onViewAccountSelect?: (
    stellarAccountViewModel: StellarAccountViewModel,
  ) => void;
  groupOwner: Group;
  defaultOpen?: boolean;
  cardHeaderControls?: React.ReactNode[];
}

export function AccountCard(props: AccountCardProps) {
  const [cardOpen, setCardOpen] = useState(props.defaultOpen);

  return (
    <StyledCard classes={{ root: classes.cardRootOverride }}>
      <div
        className={cx(classes.cardHeader, {
          [classes.cardHeaderCardOpen]: cardOpen,
        })}
      >
        {cardOpen ? (
          <Tooltip title="Show Less" placement="top">
            <IconButton
              size="small"
              onClick={() => setCardOpen(!cardOpen)}
              className={classes.icon}
            >
              <CollapseCardIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Show More" placement="top">
            <IconButton
              size="small"
              onClick={() => setCardOpen(!cardOpen)}
              className={classes.icon}
            >
              <ExpandCardIcon />
            </IconButton>
          </Tooltip>
        )}
        <div className={classes.accountCardHeaderItem}>
          <Typography
            className={classes.accountCardHeaderItemCaption}
            variant="caption"
          >
            Type
          </Typography>
          <Typography variant="subtitle1">
            {props.stellarAccountViewModel.category}
          </Typography>
        </div>
        <div className={classes.accountCardHeaderItem}>
          <Typography
            className={classes.accountCardHeaderItemCaption}
            variant="caption"
          >
            Account number
          </Typography>
          <Typography variant="subtitle1">
            {props.stellarAccountViewModel.number}
          </Typography>
        </div>
        <div className={classes.accountCardHeaderItem}>
          <Typography
            className={classes.accountCardHeaderItemCaption}
            variant="caption"
          >
            Ledger ID
          </Typography>
          <Typography variant="subtitle1">
            {props.stellarAccountViewModel.ledgerID}
          </Typography>
        </div>
        <Collapse in={cardOpen}>
          <Grid container spacing={1} direction="row-reverse">
            {props.cardHeaderControls ? (
              props.cardHeaderControls.map((ctrl, idx) => (
                <Grid key={idx} item>
                  {ctrl}
                </Grid>
              ))
            ) : (
              <Grid item />
            )}
          </Grid>
        </Collapse>
      </div>
      <Collapse in={cardOpen}>
        <CardContent classes={{ root: classes.cardContentLayout }}>
          <div className={classes.groupOwnerAvatarLayout}>
            <Avatar
              sizes="small"
              color="primary"
              className={classes.groupOwnerAvatar}
            >
              {props.groupOwner.name[0]}
            </Avatar>
            <Typography
              variant="caption"
              className={classes.groupOwnerTeamTitleHeading}
            >
              Assigned Team
            </Typography>
            <Typography color="secondary">{props.groupOwner.name}</Typography>
          </div>

          <Typography variant="h5" className={classes.balanceTableHeaderCell}>
            Asset Balances
          </Typography>
          <div className={classes.balanceTable}>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Code
            </Typography>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Issuer
            </Typography>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Network
            </Typography>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Category
            </Typography>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Amount
            </Typography>
            <Typography variant="h5" className={classes.balanceTableHeaderCell}>
              Limit
            </Typography>
            {props.stellarAccountViewModel.balances.map((b, idx) => (
              <React.Fragment key={idx}>
                <Typography
                  color="secondary"
                  className={classes.balanceTableBodyCell}
                >
                  {b.amount.token.code}
                </Typography>
                <Typography className={classes.balanceTableBodyCell}>
                  {b.tokenViewModel.issuer}
                </Typography>
                <Typography className={classes.balanceTableBodyCell}>
                  {b.amount.token.network}
                </Typography>
                <Typography className={classes.balanceTableBodyCell}>
                  {b.tokenViewModel.tokenCategory}
                </Typography>
                <Typography className={classes.balanceTableBodyCell}>
                  {formatTextNum(b.amount.value)}
                </Typography>
                <Typography className={classes.balanceTableBodyCell}>
                  {formatTextNum(b.limit.value)}
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </CardContent>
      </Collapse>
    </StyledCard>
  );
}
