import React from "react";
import { Router } from "routes";
import {
  ViewSubscriptionOrderBook,
  SubscriptionOrderBookTable,
} from "components/MarketSubscriptions";

export function SubscriptionOrderBook() {
  return (
    <Router
      baseURL={"/market-management/subscriptions/subscription-order-book"}
      redirectPath={
        "/market-management/subscriptions/subscription-order-book/table"
      }
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/market-management/subscriptions/subscription-order-book/table",
          component: (
            <SubscriptionOrderBookTable
              system
              height={window.innerHeight - 138}
            />
          ),
        },
        {
          name: "View",
          id: "view",
          path: "/market-management/subscriptions/subscription-order-book/view",
          component: (
            <ViewSubscriptionOrderBook
              system
              height={window.innerHeight - 138}
            />
          ),
          allowSubPaths: true,
        },
      ]}
    />
  );
}
