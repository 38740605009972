import { Document } from "james/document";
import config from "react-global-configuration";
import { DigitalETF } from "./DigitalETF";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { Amount } from "../ledger/Amount";

export interface DraftUpdateDigitalETFRequest {
  context: Context;
  digitalETF: DigitalETF;
}

export interface DraftUpdateDigitalETFResponse {
  digitalETF: DigitalETF;
}

export interface IncreaseDigitalETFMaximumUnitsRequest {
  context: Context;
  digitalETFID: string;
  amount: Amount;
}

export interface IncreaseDigitalETFMaximumUnitsResponse {
  digitalETF: DigitalETF;
}

export interface DecreaseDigitalETFMaximumUnitsRequest {
  context: Context;
  digitalETFID: string;
  amount: Amount;
}

export interface DecreaseDigitalETFMaximumUnitsResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFMaturityDateRequest {
  context: Context;
  digitalETFID: string;
  maturityDate: string;
}

export interface ChangeDigitalETFMaturityDateResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFHoldingsRequest {
  context: Context;
  digitalETFID: string;
  updatedHoldings: Holding[];
}

export interface ChangeDigitalETFHoldingsResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFSectorAllocationsRequest {
  context: Context;
  digitalETFID: string;
  updatedSectorAllocations: SectorAllocation[];
}

export interface ChangeDigitalETFSectorAllocationsResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFCountryAllocationsRequest {
  context: Context;
  digitalETFID: string;
  updatedCountryAllocations: CountryAllocation[];
}

export interface ChangeDigitalETFCountryAllocationsResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFAnnualPerformanceLogRequest {
  context: Context;
  digitalETFID: string;
  updatedAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface ChangeDigitalETFAnnualPerformanceLogResponse {
  digitalETF: DigitalETF;
}

export interface ChangeDigitalETFSupportingDocumentsRequest {
  context: Context;
  digitalETFID: string;
  updatedSupportingDocuments: Document[];
}

export interface ChangeDigitalETFSupportingDocumentsResponse {
  digitalETF: DigitalETF;
}

export const DigitalETFUpdater = {
  serviceProvider: "financial-DigitalETFUpdater",
  async DraftUpdateDigitalETF(
    request: DraftUpdateDigitalETFRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateDigitalETFResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.DraftUpdateDigitalETF`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async IncreaseDigitalETFMaximumUnits(
    request: IncreaseDigitalETFMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<IncreaseDigitalETFMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.IncreaseDigitalETFMaximumUnits`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async DecreaseDigitalETFMaximumUnits(
    request: DecreaseDigitalETFMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<DecreaseDigitalETFMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.DecreaseDigitalETFMaximumUnits`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFMaturityDate(
    request: ChangeDigitalETFMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFMaturityDate`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFHoldings(
    request: ChangeDigitalETFHoldingsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFHoldingsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFHoldings`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFSectorAllocations(
    request: ChangeDigitalETFSectorAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFSectorAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFSectorAllocations`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFCountryAllocations(
    request: ChangeDigitalETFCountryAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFCountryAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFCountryAllocations`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFAnnualPerformanceLog(
    request: ChangeDigitalETFAnnualPerformanceLogRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFAnnualPerformanceLogResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFAnnualPerformanceLog`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
  async ChangeDigitalETFSupportingDocuments(
    request: ChangeDigitalETFSupportingDocumentsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETFSupportingDocumentsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETFUpdater.serviceProvider}.ChangeDigitalETFSupportingDocuments`,
      request,
    });
    return { digitalETF: new DigitalETF(response.digitalETF) };
  },
};
