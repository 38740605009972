import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon, Opacity as MintIcon } from "@mui/icons-material";
import { Model as TokenViewModel } from "james/views/ledgerTokenView";
import { useSnackbar } from "notistack";
import { useValidatedForm } from "hooks/useForm";
import { Amount, Token } from "james/ledger";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { NativeAssetTokenCode } from "james/stellar";
import { TokenTap } from "picasso/stellar";
import {
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  validationFunc,
} from "./useValidatedForm";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useErrorContext } from "context/Error";

const PREFIX = "MintDialog";

const classes = {
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  dialogContent: `${PREFIX}-dialogContent`,
  textNumFieldCode: `${PREFIX}-textNumFieldCode`,
  row: `${PREFIX}-row`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: theme.spacing(2, 1, 2, 2),
    alignItems: "center",
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(3, 4, 4, 4),
    [theme.breakpoints.up("sm")]: {
      width: 420,
    },
  },

  [`& .${classes.textNumFieldCode}`]: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.row}`]: {
    paddingLeft: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export type DepositDialogProps = {
  closeDialog: () => void;
  tokenToMint: Token;
};

export type FormData = {
  destinationAccountAddress: string;
  mintAmount: Amount;
};

export function MintDialog(props: DepositDialogProps) {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { closeDialog } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [tokenToMintViewModel, setTokenToMintViewModel] =
    useState<TokenViewModel>(new TokenViewModel());
  const [initialising, setInitialising] = useState(true);
  const { errorContextErrorTranslator } = useErrorContext();
  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm<FormData, FormUpdaterSpecsType>(
    validationFunc,
    async () => {
      // prepare initial dialog form state
      const initialState: FormData = {
        destinationAccountAddress: "",
        mintAmount: props.tokenToMint.newAmountOf("0"),
      };

      if (props.tokenToMint.code === NativeAssetTokenCode) {
        initialState.mintAmount = props.tokenToMint.newAmountOf("10000");
      }

      // retrieve any required data
      try {
        await Promise.all([
          // get reserve token view models
          (async () => {
            setTokenToMintViewModel(
              await getLedgerTokenViewModel(props.tokenToMint),
            );
          })(),
        ]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error initialising form: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error initialising form: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return initialState;
      }

      setInitialising(false);
      return initialState;
    },
    formUpdaterSpecs,
    {
      destinationAccountAddress: "",
      mintAmount: props.tokenToMint.newAmountOf("0"),
    },
    new Set<string>(["selectedTradingAccViewModel"]),
  );

  const [mintInProgress, setMintInProgress] = useState(false);
  const handleMint = useCallback(async () => {
    setMintInProgress(true);
    try {
      await TokenTap.Pour({
        amount: formData.mintAmount,
        destinationAccountAddress: formData.destinationAccountAddress,
      });
      enqueueSnackbar("Mint Complete", { variant: "success" });
      closeDialog();
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error minting token: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `error minting token: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }
    setMintInProgress(false);
  }, [
    enqueueSnackbar,
    formData.mintAmount,
    formData.destinationAccountAddress,
    closeDialog,
  ]);

  const loading = mintInProgress;

  const dialogContent = initialising ? (
    <Skeleton width="100%" height={200} />
  ) : (
    <>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Enter deposit parameters
            </Typography>
          </Grid>
          <Grid item>
            <TextNumField
              id="tokenMintDialog-mintAmount-textNumField"
              noDecimalPlaces={7}
              label="Amount"
              disallowNegative
              disabled={
                props.tokenToMint.code === NativeAssetTokenCode ||
                mintInProgress
              }
              fullWidth
              value={formData.mintAmount.value}
              onChange={(e) =>
                formDataUpdate.mintAmount(
                  formData.mintAmount.setValue(e.target.value),
                )
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TokenIconViewUpload
                      disableChangeIcon
                      token={tokenToMintViewModel.token}
                      size={23}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={`Issued by ${tokenToMintViewModel.issuer}`}
                      placement="top"
                    >
                      <Typography
                        variant="body1"
                        className={classes.textNumFieldCode}
                        children={tokenToMintViewModel.token.code}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={!!formDataValidationResult.fieldValidations.mintAmount}
              helperText={formDataValidationResult.fieldValidations.mintAmount}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              disabled={mintInProgress}
              label="Account"
              value={formData.destinationAccountAddress}
              onChange={(e) =>
                formDataUpdate.destinationAccountAddress(e.target.value)
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <Tooltip
          placement="top"
          title={(() => {
            switch (true) {
              case formDataValidationInProgress:
                return "Validation in progress";
              case !formDataValidationResult.valid:
                return "Please ensure that all fields have been completed correctly";
              default:
                return "";
            }
          })()}
        >
          <span>
            <Button
              id="tokenMintDialog-deposit-button"
              fullWidth
              variant="contained"
              color="primary"
              children="mint"
              disabled={
                loading ||
                formDataValidationInProgress ||
                !formDataValidationResult.valid ||
                mintInProgress
              }
              onClick={handleMint}
            />
          </span>
        </Tooltip>
      </DialogContent>
    </>
  );

  return (
    <StyledDialog open maxWidth="lg">
      <DialogTitle classes={{ root: classes.dialogTitleRootOverride }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <MintIcon />
          </Grid>
          <Grid item>
            <Typography variant="h5" children="Mint Token" />
          </Grid>
          {mintInProgress && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <IconButton
                id="tokenMintDialog-close-iconButton"
                size="small"
                onClick={props.closeDialog}
                disabled={loading}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      {dialogContent}
    </StyledDialog>
  );
}
