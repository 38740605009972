import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  Refresh as ReloadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Group, GroupRepository } from "james/group";
import { IDIdentifier, LedgerIDIdentifier } from "james/search/identifier";
import { SetLimitRequest } from "james/stellar/AccountOperator";
import { useSnackbar } from "notistack";
import { TextNumField } from "components/FormFields/TextNumField";
import {
  AccountOperator,
  AllStellarNetworks,
  MaxSetLimitAmount,
} from "james/stellar";
import {
  Model as StellarAccountViewModel,
  Model as AccountViewModel,
} from "james/views/stellarAccountView";
import {
  TextListCriterion,
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { Query } from "james/search/query";
import { useNavigate, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { Amount } from "james/ledger";
import { TokenCategory } from "james/views/ledgerTokenView/Model";

import { AccountCard } from "./AccountCard";
import { useAccountContext } from "context/Account/Account";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

const PREFIX = "Detail";

const classes = {
  root: `${PREFIX}-root`,
  cardTitleRootOverride: `${PREFIX}-cardTitleRootOverride`,
  cardHeaderRootOverride: `${PREFIX}-cardHeaderRootOverride`,
  loadingCircularProgressWrapper: `${PREFIX}-loadingCircularProgressWrapper`,
  editAccountNameCardActionIcon: `${PREFIX}-editAccountNameCardActionIcon`,
  row1Layout: `${PREFIX}-row1Layout`,
  accountDetailsCardContentLayout: `${PREFIX}-accountDetailsCardContentLayout`,
  groupOwnerAvatarLayout: `${PREFIX}-groupOwnerAvatarLayout`,
  groupOwnerAvatar: `${PREFIX}-groupOwnerAvatar`,
  groupOwnerTeamTitleHeading: `${PREFIX}-groupOwnerTeamTitleHeading`,
  infoText: `${PREFIX}-infoText`,
  operationCardContentLayout: `${PREFIX}-operationCardContentLayout`,
  operationSelectInput: `${PREFIX}-operationSelectInput`,
  operationSelect: `${PREFIX}-operationSelect`,
  selectIcon: `${PREFIX}-selectIcon`,
  searchIcon: `${PREFIX}-searchIcon`,
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  actionIcon: `${PREFIX}-actionIcon`,
  selectFormField: `${PREFIX}-selectFormField`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: "calc(100vh - 180px)",
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.cardTitleRootOverride}`]: {
    display: "flex",
  },

  [`& .${classes.cardHeaderRootOverride}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.loadingCircularProgressWrapper}`]: {
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.editAccountNameCardActionIcon}`]: {
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.row1Layout}`]: {
    display: "grid",
    gridTemplateColumns: "4fr 3fr",
    gridColumnGap: theme.spacing(2),
  },

  [`& .${classes.accountDetailsCardContentLayout}`]: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto auto",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.groupOwnerAvatarLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "auto auto",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.groupOwnerAvatar}`]: {
    gridRow: "1/3",
    width: "35px",
    height: "35px",
    fontSize: "0.90rem",
  },

  [`& .${classes.groupOwnerTeamTitleHeading}`]: {
    color: theme.palette.text.hint,
  },

  [`& .${classes.infoText}`]: {
    color: theme.palette.text.hint,
  },

  [`& .${classes.operationCardContentLayout}`]: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },

  [`& .${classes.operationSelectInput}`]: {
    width: 150,
    margin: 0,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.operationSelect}`]: {
    width: 150,
    margin: 0,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.selectIcon}`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${classes.searchIcon}`]: {
    color: theme.palette.text.tertiary,
  },

  //
  // deposit funds dialog
  //
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.actionIcon}`]: {
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.selectFormField}`]: {
    width: 220,
  },
}));

enum Operation {
  setLimit = "Set Limit",
}

const allOperations: Operation[] = [Operation.setLimit];

const defaultCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextNEExactCriterion(TokenCategory.LiquidityPoolShares),
};

export function Detail() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { stellarAccountContext } = useAccountContext();
  const [groupOwner, setGroupOwner] = useState(new Group());
  const [activeOperation, setActiveOperation] = useState(Operation.setLimit);
  const [setLimitRequest, setSetLimitRequest] = useState<SetLimitRequest>({
    context: authContext,
    accountID: "",
    limit: new Amount(),
  });
  const [
    selectedTokenViewModelForLimitSetting,
    setSelectedTokenViewModelForLimitSetting,
  ] = useState<TokenViewModel | null>(null);
  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
    loading: ledgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultCriteria,
  });
  const [accountViewModel, setAccountViewModel] =
    useState<StellarAccountViewModel | null>(null);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // on screen load
  useEffect(() => {
    (async () => {
      // try and get a account number from the url
      const accountViewModelNoFromURL = searchParams.get("number");

      if (accountViewModelNoFromURL === null) {
        // if no account number is given then navigate back to the table
        navigate("/market-management/accounts/table");
        return;
      }

      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        return;
      }

      if (
        accountViewModel &&
        accountViewModelNoFromURL === accountViewModel.number &&
        !reload
      ) {
        // if the ID in the url is the same as the transaction already
        // stored then do nothing
        return;
      }
      setReload(false);

      // otherwise retrieve the account view model
      const updatedAccViewModel: AccountViewModel | undefined =
        stellarAccountContext.accounts.find(
          (v) => v.number === accountViewModelNoFromURL,
        );
      if (!updatedAccViewModel) {
        navigate("/market-management/accounts/table");
        console.error(`unexpected error retrieving account view model`);
        enqueueSnackbar(`Unexpected Error Retrieving Account`, {
          variant: "error",
        });
        return;
      }
      setAccountViewModel(updatedAccViewModel);

      //
      // load account group owner
      //
      try {
        setGroupOwner(
          (
            await GroupRepository.RetrieveGroup({
              context: authContext,
              identifier: IDIdentifier(updatedAccViewModel.ownerID),
            })
          ).group,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        navigate("/market-management/accounts/table");
        console.error(
          `error retrieving account view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error Retrieving Account Owner: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return;
      }

      setLoading(false);
    })();
  }, [
    authContext,
    enqueueSnackbar,
    stellarAccountContext.loading,
    stellarAccountContext.error,
    history,
    accountViewModel,
    reload,
  ]);

  const handleSetLimit = async () => {
    if (!accountViewModel) {
      return;
    }
    setLoading(true);

    // if account context is still loading return
    if (stellarAccountContext.loading) {
      enqueueSnackbar(`Initialisation In Progress, Please Try Again Later`, {
        variant: "info",
      });
    }

    if (stellarAccountContext.loading) {
      console.error(`initialisation error: ${stellarAccountContext.error}`);
      enqueueSnackbar(`Initialisation Error: ${stellarAccountContext.error}`, {
        variant: "error",
      });

      return;
    }

    // determine if user is a signatory on the transaction source account
    try {
      // check if the user is an signatory on the account
      const isUserASignatoryOnAccount =
        await stellarAccountContext.checkUserSignatoryOnAccount(
          LedgerIDIdentifier(accountViewModel.ledgerID),
        );

      if (!isUserASignatoryOnAccount) {
        enqueueSnackbar("user is not a signatory on account", {
          variant: "error",
        });
        setLoading(false);
        return;
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to check if user is signatory on account: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `unable to check if user is signatory on account: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setLoading(false);
      return;
    }

    // perform limit setting
    try {
      await AccountOperator.SetLimit({
        ...setLimitRequest,
        accountID: accountViewModel.id,
      });

      enqueueSnackbar("limit changed", { variant: "success" });
      setSelectedTokenViewModelForLimitSetting(null);
      setSetLimitRequest({
        context: authContext,
        accountID: accountViewModel.id,
        limit: setLimitRequest.limit.setValue("0"),
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error setting limit: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `error setting limit: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }

    setLoading(false);
  };

  return (
    <Root className={cx(classes.root, "meshScroll")}>
      <Grid container direction="column" spacing={2}>
        <Grid item className={classes.row1Layout}>
          <Card>
            <CardContent
              classes={{ root: classes.accountDetailsCardContentLayout }}
            >
              <div className={classes.groupOwnerAvatarLayout}>
                <Avatar
                  sizes="small"
                  color="primary"
                  className={classes.groupOwnerAvatar}
                >
                  {groupOwner.name[0]}
                </Avatar>
                <Typography
                  variant="caption"
                  className={classes.groupOwnerTeamTitleHeading}
                >
                  Assigned Team
                </Typography>
                <Typography color="secondary">{groupOwner.name}</Typography>
              </div>
              <Typography>Ledger ID: {accountViewModel?.ledgerID}</Typography>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <IconButton
                    disabled={loading}
                    size="small"
                    onClick={() => setReload(true)}
                  >
                    <ReloadIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              classes={{
                title: classes.cardTitleRootOverride,
                root: classes.cardHeaderRootOverride,
              }}
              title={
                <>
                  <Select
                    defaultValue={Operation.setLimit}
                    classes={{ icon: classes.selectIcon }}
                    disabled={loading}
                    margin="dense"
                    className={classes.operationSelect}
                    value={activeOperation}
                    onChange={(e) =>
                      setActiveOperation(e.target.value as Operation)
                    }
                  >
                    {allOperations.map((op, idx) => (
                      <MenuItem key={idx} value={op}>
                        {op}
                      </MenuItem>
                    ))}
                  </Select>
                  {loading && (
                    <div className={classes.loadingCircularProgressWrapper}>
                      <CircularProgress size={25} />
                    </div>
                  )}
                </>
              }
              action={(() => {
                switch (activeOperation) {
                  case Operation.setLimit:
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={loading}
                        onClick={handleSetLimit}
                      >
                        Set
                      </Button>
                    );

                  default:
                    return null;
                }
              })()}
            />
            <CardContent classes={{ root: classes.operationCardContentLayout }}>
              {(() => {
                switch (activeOperation) {
                  case Operation.setLimit:
                    return (
                      <>
                        <Typography
                          variant="body1"
                          className={classes.infoText}
                        >
                          Set a limit for a specified asset
                        </Typography>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Autocomplete
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  disabled={loading}
                                  getOptionLabel={(option: TokenViewModel) =>
                                    `${option.token.code} - ${option.issuer}`
                                  }
                                  options={ledgerTokenViewReadResponse.models}
                                  loading={ledgerTokenViewReadLoading}
                                  onChange={(
                                    a,
                                    selected: TokenViewModel | null,
                                  ) => {
                                    if (selected) {
                                      setSetLimitRequest({
                                        ...setLimitRequest,
                                        limit: selected.token.newAmountOf(
                                          setLimitRequest.limit.value,
                                        ),
                                      });
                                      setSelectedTokenViewModelForLimitSetting(
                                        selected,
                                      );
                                    }
                                  }}
                                  value={selectedTokenViewModelForLimitSetting}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onChange={(e) => {
                                        if (e.target.value === "") {
                                          setLedgerTokenViewReadRequest({
                                            ...ledgerTokenViewReadRequest,
                                            criteria: defaultCriteria,
                                          });
                                        } else {
                                          setLedgerTokenViewReadRequest({
                                            ...ledgerTokenViewReadRequest,
                                            criteria: {
                                              ...defaultCriteria,
                                              "token.code":
                                                TextSubstringCriterion(
                                                  e.target.value,
                                                ),
                                            },
                                          });
                                        }
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        placeholder: "Select...",
                                        className: classes.selectFormField,
                                        endAdornment: (
                                          <>
                                            {ledgerTokenViewReadLoading ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            <SearchIcon
                                              className={classes.searchIcon}
                                            />
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  disabled={loading}
                                  children="set max"
                                  onClick={() =>
                                    setSetLimitRequest({
                                      ...setLimitRequest,
                                      limit:
                                        setLimitRequest.limit.setValue(
                                          MaxSetLimitAmount,
                                        ),
                                    })
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <TextNumField
                              disabled={loading}
                              label="Limit"
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              disallowNegative
                              noDecimalPlaces={7}
                              value={setLimitRequest.limit.value}
                              onChange={(e) =>
                                setSetLimitRequest({
                                  ...setLimitRequest,
                                  limit: setLimitRequest.limit.setValue(
                                    e.target.value,
                                  ),
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    );

                  default:
                    return null;
                }
              })()}
            </CardContent>
          </Card>
        </Grid>
        {!!accountViewModel && (
          <>
            <Grid item xs={12}>
              <AccountCard
                stellarAccountViewModel={accountViewModel}
                groupOwner={groupOwner}
                defaultOpen
              />
            </Grid>
          </>
        )}
      </Grid>
    </Root>
  );
}
