export enum Sector {
  Energy = "Energy",
  Materials = "Materials",
  Industrials = "Industrials",
  ConsumerDiscretionary = "Consumer Discretionary",
  ConsumerStaples = "Consumer Staples",
  HealthCare = "Health Care",
  Financials = "Financials",
  InformationTechnology = "Information Technology",
  TelecommunicationServices = "Telecommunication Services",
  Utilities = "Utilities",
  RealEstate = "Real Estate",
  Debt = "Debt",
}

export const AllSectors: Sector[] = [
  Sector.Energy,
  Sector.Materials,
  Sector.Industrials,
  Sector.ConsumerDiscretionary,
  Sector.ConsumerStaples,
  Sector.HealthCare,
  Sector.Financials,
  Sector.InformationTechnology,
  Sector.TelecommunicationServices,
  Sector.Utilities,
  Sector.RealEstate,
  Sector.Debt,
];
