import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Fade,
  FadeProps,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import InfoIcon from "@mui/icons-material/Info";

interface FundAccountAckProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export function FundingAcknowledgementDialog(props: FundAccountAckProps) {
  const [ack, setAck] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": isMobile
            ? {
                width: "100%",
                margin: 0,
                padding: 0,
                bottom: 0,
                position: "absolute",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {
                width: {
                  lg: "800px",
                  sm: "100%",
                },
                maxWidth: {
                  lg: "800px",
                  sm: "672px",
                },
              },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "data-component-info": JSON.stringify({
          component_id: "fund_account_popup",
          component_business_name: "fund account popup",
          component_title: "Funding mesh mZar",
          component_driver: InteractionDriver.FundAccount,
        } as DataComponentInfo),
      }}
      scroll="paper"
      open={props.open}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          padding: theme.spacing(2, 2, 2, 3),
        })}
      >
        <Grid
          sx={{
            alignItems: "center",
          }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            <Typography variant="h5">Funding Mesh mZAR</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="fundAccountAck-close-button"
              data-link-info={JSON.stringify({
                content_interaction_id: "close-modal",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_text: "Close (x)",
                content_interaction_driver:
                  InteractionDriver.FundAccountAbandon,
              } as DataLinkInfoType)}
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({ padding: `${theme.spacing(3, 3, 5, 3)}!important` })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            padding: theme.spacing(0, 2),
            width: "100%",
            backgroundColor: theme.palette.custom.lavenderLight,
            borderRadius: theme.spacing(0.5),
            height: isMobile ? theme.spacing(8) : theme.spacing(5),
            alignItems: "center",
            margin: isMobile ? theme.spacing(1, 0) : theme.spacing(1, 0, 4, 0),
          })}
        >
          <IconButton sx={{ color: "black" }}>
            <InfoIcon />
          </IconButton>
          <Typography variant="body1" color="black">
            Our bank details have changed. Please use our new{" "}
            <Typography fontWeight="bold" component="span">
              Investec{" "}
            </Typography>
            account.
          </Typography>
        </Box>
        <Typography
          sx={(theme) => ({
            padding: theme.spacing(0, 0, 2, 1),
          })}
          color={"textPrimary"}
          fontSize="16px"
          variant="h3"
        >
          To ensure a smooth funding process, please be aware of the following:
        </Typography>
        <Typography
          sx={(theme) => ({
            padding: theme.spacing(0, 0, 2, 1),
          })}
          color={"textSecondary"}
          variant="body1"
        >
          1. Always use your unique reference when funding your Mesh account.
          <br />
          2. Funds can take anywhere from an hour to up to 2 days to reflect in
          your account.
        </Typography>
        <FormControlLabel
          sx={[
            (theme) => ({
              marginRight: theme.spacing(2),
              padding: isMobile
                ? theme.spacing(0, 0, 2, 0)
                : theme.spacing(0, 0, 2, 1),
            }),
            isMobile && {
              margin: theme.spacing(1),
            },
          ]}
          control={
            <Checkbox
              checked={ack}
              onChange={() => setAck(!ack)}
              data-link-info={JSON.stringify({
                content_interaction_id: "accept-tnc",
                content_interaction_action: InteractionAction.Check,
                content_interaction_text: "fund mesh - i acknowledge",
                content_interaction_type: InteractionType.Checkbox,
                content_interaction_driver: InteractionDriver.FundAccount,
              } as DataLinkInfoType)}
              name="checkedB"
              color="primary"
              id="fundAccountAck-ack-checkbox"
            />
          }
          label="I acknowledge"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            data-link-info={JSON.stringify({
              content_interaction_id: "next-steps",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: "button",
              content_interaction_text: "continue",
              content_interaction_driver: InteractionDriver.FundAccount,
            } as DataLinkInfoType)}
            sx={[isMobile && { height: 48 }]}
            fullWidth={isMobile}
            id="fundAccountAck-continue-button"
            onClick={props.onContinue}
            disabled={!ack}
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const slideTransition = (tprops: SlideProps) => {
  return <Slide direction="up" {...tprops} />;
};

const fadeTransition = (tprops: FadeProps) => {
  return <Fade in {...tprops} />;
};
