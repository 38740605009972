import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { AssetParticipants } from "./AssetParticipants";
import { Token } from "./Token";

export const AssetInspectorServiceProviderName = "ledger-AssetInspector";

interface GetAssetParticipantsRequest {
  token: Token;
}

interface GetAssetParticipantsResponse {
  participants: AssetParticipants;
}

export const AssetInspector = {
  serviceProviderName: AssetInspectorServiceProviderName,
  async GetAssetParticipants(
    request: GetAssetParticipantsRequest,
  ): Promise<GetAssetParticipantsResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetAssetParticipants`,
      request,
    });

    return {
      participants: new AssetParticipants(response.participants),
    };
  },
};
