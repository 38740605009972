import React from "react";
import { Router } from "routes";
import { Table } from "./Table";

export function MarketListings() {
  return (
    <Router
      baseURL={"/market-management/market-listing/market-listings"}
      redirectPath={"/market-management/market-listing/market-listings/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/market-management/market-listing/market-listings/table",
          component: <Table />,
        },
      ]}
    />
  );
}
