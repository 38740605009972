import config from "react-global-configuration";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { DigitalETN } from "./DigitalETN";

export interface IssueDigitalETNRequest {
  context: Context;
  digitalETNID: string;
  issuanceAccountID: string;
}

export interface IssueDigitalETNResponse {
  digitalETN: DigitalETN;
}

export interface PreIssueDigitalETNRequest {
  context: Context;
  digitalETNID: string;
  issuanceAccountID: string;
}

export interface PreIssueDigitalETNResponse {
  digitalETN: DigitalETN;
}

export interface CancelDigitalETNRequest {
  context: Context;
  digitalETNID: string;
}

export interface CancelDigitalETNResponse {
  digitalETN: DigitalETN;
}

export interface MatureDigitalETNRequest {
  context: Context;
  digitalETNID: string;
}

export interface MatureDigitalETNResponse {
  digitalETN: DigitalETN;
}

export interface MarkDigitalETNDeletedRequest {
  context: Context;
  digitalETNID: string;
}

export interface MarkDigitalETNDeletedResponse {
  digitalETN: DigitalETN;
}

const DigitalETNStateChangerServiceProviderName =
  "financial-DigitalETNStateChanger";

export const DigitalETNStateChanger = {
  async IssueDigitalETN(
    request: IssueDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<IssueDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNStateChangerServiceProviderName}.IssueDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async PreIssueDigitalETN(
    request: PreIssueDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<PreIssueDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNStateChangerServiceProviderName}.PreIssueDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async CancelDigitalETN(
    request: CancelDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<CancelDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNStateChangerServiceProviderName}.CancelDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async MatureDigitalETN(
    request: MatureDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<MatureDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNStateChangerServiceProviderName}.MatureDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async MarkDigitalETNDeleted(
    request: MarkDigitalETNDeletedRequest,
    additionalHeaders?: Headers,
  ): Promise<MarkDigitalETNDeletedResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNStateChangerServiceProviderName}.MarkDigitalETNDeleted`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
};
