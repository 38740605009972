import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Skeleton,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft as BackIcon,
  Close as CloseIcon,
  Link as LinkIcon,
  MoreVert as MenuIcon,
  OpenInNew as OpenInNewIcon,
  UnfoldLess,
  UnfoldMore,
} from "@mui/icons-material";
import {
  MarketListingViewModel,
  PublicModel as PublicMarketListingViewModel,
  Reader as MarketListingReader,
} from "james/views/marketListingView";
import { useIsMounted } from "hooks";
import { useFirebaseContext } from "context/Firebase";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import dayjs from "dayjs";
import { Token } from "james/ledger";
import { Mechanism, MechanismType } from "james/market";
import { SpotSection } from "components/Cards/MarketplaceCard/components/SpotSection";
import { DirectOrderSection } from "components/Cards/MarketplaceCard/components/DirectOrderSection";
import { IssuePriceSection } from "components/Cards/MarketplaceCard/components/IssuePriceSection";
import { InstrumentTypeC } from "components/Cards/MarketplaceCard/components/InstrumentType/InstrumentType";
import { NetworkLogo } from "components/Cards/MarketplaceCard/components/NetworkLogo";
import { ReturnIndicator } from "components/Cards/MarketplaceCard/components/ReturnIndicator";
import { RiskProfile } from "components/Cards/MarketplaceCard/components/RiskProfile";
import { useWindowSize } from "utilities/general";
import { DataSheetSection } from "james/marketData/DataSheetSection";
import {
  AssetDataSheetReader,
  AssetDocumentsDataSheetSection,
  AssetInvestmentObjectiveDataSheetSection,
  AssetPerformanceDataSheetSection,
  AssetPriceHistoryDataSheetSection,
  AssetSectorAllocationsAndHoldingsDataSheetSection,
} from "james/marketData";
import { TokenIdentifier } from "james/search/identifier";

import { StellarAssetPublicInfoDataSheetSection } from "james/marketData/StellarAssetPublicInfoDataSheetSection";
import {
  getNetworkString,
  getTokenAssetString,
} from "context/Stellar/StellarExpertClient";
import { StellarNetwork } from "james/stellar";
import { LedgerNetwork } from "james/ledger/Network";
import { TabContext, TabPanel } from "@mui/lab";
import { YieldCoinLabel } from "components/Labels/YieldCoinLabel";
import { AssetType } from "james/views/marketListingView/Model";
import { useErrorContext } from "context/Error";
import { HowDoesItWork } from "./HowDoesItWork/HowDoesItWork";
import { useApplicationContext } from "context/Application/Application";
import {
  IssuerName,
  IssuerNameCard,
} from "components/Cards/MarketplaceCard/components/IssuerName/IssuerName";
import { BondSection } from "components/Cards/MarketplaceCard/components/BondSection/BondSection";
import { AssetDataSheets } from "./DataSheetSectionComponents/AssetDataSheets";
import { AssetSubscriptionOverviewDataSheetSection } from "james/marketData/AssetSubscriptionOverviewDataSheetSection";
import { AssetIndependentReviewDataSheetSection } from "james/marketData/AssetIndependentReviewDataSheetSection";
import { AssetManagingRiskDataSheetSection } from "james/marketData/AssetManagingRiskDataSheetSection";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { AssetDetailViewCommerceDetails } from "types/gtm";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Participants } from "./Participants/Participants";
import { AssetMarketingMediaDataSheetSection } from "james/marketData/AssetMarketingMedia";
import { AssetRepaymentTermsDataSheetSection } from "../../../../james/marketData/AssetRepaymentTermsDataSheetSection";
import { WaitingListPopUpDialog } from "components/WaitingListPopUp/WaitingListPopUp";
import { AssetOverviewWelcomeDialog } from "components/Dialogs/Welcome/AssetOverviewWelcomeDialog";
import cx from "classnames";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { Helmet } from "react-helmet-async";
import { DesktopAssetCard, MobileAssetCard } from "./Components";

export const cryptoCardAssetTypes: (AssetType | "" | undefined)[] = [
  AssetType.CryptoCurrency,
  AssetType.RightsToACryptoCurrency,
  AssetType.RightsToAFiatCurrency,
  AssetType.YieldBearingStablecoin,
];

export const bondAssetTypes: (AssetType | "" | undefined)[] = [
  AssetType.Bond,
  AssetType.PreferenceShare,
  AssetType.Share,
];

export const AssetOverview = () => {
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");
  const [searchParams] = useSearchParams();
  const { authContext } = useApplicationContext();
  const [listingViewModel, setListingViewModel] = useState<
    MarketListingViewModel | PublicMarketListingViewModel | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      // only proceed to fetch data required for this view if it is mounted
      if (!isMounted()) {
        return;
      }

      // wait until firebase is authenticated if not public
      if (!firebaseAuthenticated && !isPublic) {
        return;
      }

      // get the asset short name identifier from the url
      const assetTokenCode = searchParams.get("code");
      const assetTokenIssuer = searchParams.get("issuer");
      const assetTokenNetwork = searchParams.get("network");
      if (!(assetTokenCode && assetTokenIssuer && assetTokenNetwork)) {
        navigate("/market");
        return;
      }

      // retrieve listing view model
      let retrievedListingViewModel:
        | MarketListingViewModel
        | PublicMarketListingViewModel;
      try {
        if (isPublic) {
          retrievedListingViewModel = (
            await MarketListingReader.PublicReadOne({
              criteria: new Token({
                code: assetTokenCode,
                issuer: assetTokenIssuer,
                network: assetTokenNetwork as LedgerNetwork,
              }).toFilter(),
            })
          ).publicModel;
        } else {
          retrievedListingViewModel = (
            await MarketListingReader.ReadOne({
              context: authContext,
              criteria: new Token({
                code: assetTokenCode,
                issuer: assetTokenIssuer,
                network: assetTokenNetwork as LedgerNetwork,
              }).toFilter(),
            })
          ).model;
        }
      } catch (e) {
        navigate("/");
        errorContextDefaultWarningFeedback(
          e,
          "error fetching market listing view model",
        );
        return;
      }

      // set listing view model
      if (isMounted()) {
        setListingViewModel(retrievedListingViewModel);
      }
    })();
  }, [isMounted, authContext, firebaseAuthenticated, history]);

  return (
    <AssetOverviewComponent
      listingViewModel={listingViewModel}
      back={() =>
        window.history.length > 1 ? navigate(-1) : navigate("/market")
      }
      toMarketplace={() => navigate("/market/markets")}
    />
  );
};

interface AssetOverviewComponentProps {
  listingViewModel:
    | MarketListingViewModel
    | PublicMarketListingViewModel
    | undefined;
  back: () => void;
  toMarketplace: () => void;
}

export const AssetOverviewComponent = ({
  listingViewModel,
  back,
  toMarketplace,
}: AssetOverviewComponentProps) => {
  const theme = useTheme();
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const [, windowHeight] = useWindowSize();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { pushAssetDetailViewEvent } = useGTMTriggersPusher();
  const [showAssetOverViewWelcomeDialog, setShowAssetOverViewWelcomeDialog] =
    useState(isPublic);

  const metaTagDescription = listingViewModel
    ? `Explore, Buy and Sell ${listingViewModel.assetName} (${listingViewModel.assetShortName}) on Mesh`
    : "Explore, Buy and Sell assets on Mesh";

  useEffect(() => {
    if (!listingViewModel) {
      return;
    }

    (async () => {
      const ledgerTokenViewModelResponse = await getLedgerTokenViewModel(
        listingViewModel.token,
      );

      pushAssetDetailViewEvent({
        // The issue price has long been removed on the backend from the listing.
        // The issue price here is also irrelevant as not all assets have a listing price.
        // If a price is required here then it needs to be fetched elsewhere.
        // transaction_asset_sell_price:
        transaction_asset_sell_price: "0.00",
        transaction_asset_buy_price: "0.00",
        transaction_asset_name: listingViewModel.assetName,
        transaction_asset_id: `${listingViewModel.token.code};${listingViewModel.token.issuer}:${listingViewModel.token.network}`,
        transaction_asset_type: listingViewModel.assetType,
        transaction_asset_issuer:
          ledgerTokenViewModelResponse.issuer.toString(),
        transaction_asset_risk_rating: listingViewModel.instrumentRiskProfile,
        transaction_asset_investor_profile:
          listingViewModel.instrumentRiskProfile,
        transaction_currency: "mZAR",
      } as AssetDetailViewCommerceDetails);
    })();
  }, [listingViewModel]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mesh | {listingViewModel?.assetName ?? "Asset Overview"}</title>
        <meta name="description" content={metaTagDescription} />
      </Helmet>
      {isDesktop ? (
        <Box
          sx={(theme) => ({
            maxWidth: theme.breakpoints.values.lg,
            minWidth: theme.breakpoints.values.md,
            margin: theme.spacing(0, 3, 3, 3),
            position: "absolute",
            top: 0,
            left: `calc((100vw - 1300px)/2)`,
            right: `calc((100vw - 1300px)/2)`,
            pb: 3,
          })}
          data-component-info={JSON.stringify({
            component_id: "asset_detail_card",
            component_business_name: "asset detail card",
            component_title: listingViewModel
              ? listingViewModel.assetName
              : "-",
            component_driver: InteractionDriver.DriveTransaction,
          } as DataComponentInfo)}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              width: "100%",
              zIndex: 100,
            }}
          >
            <NavBar
              marketListingViewModel={listingViewModel}
              back={back}
              toMarketplace={toMarketplace}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <DesktopAssetCard marketListingViewModel={listingViewModel} />
            </Box>
            <Box
              sx={{
                borderRadius: "0 10px 10px 0",
                backgroundColor: theme.palette.background.paper,
                position: "relative",
                ml: 0.5,
                width: "100%",
              }}
            >
              <AccordionDataSheetSections
                scroll={scroll}
                marketListingViewModel={listingViewModel}
              />
            </Box>
          </Box>
        </Box>
      ) : isTablet ? (
        <Box
          sx={{
            width: "100%",
            overflowY: "hidden",
          }}
          data-component-info={JSON.stringify({
            component_id: "asset_detail_card",
            component_business_name: "asset detail card",
            component_title: listingViewModel?.assetName,
            component_driver: InteractionDriver.DriveTransaction,
          } as DataComponentInfo)}
        >
          <NavBar
            marketListingViewModel={listingViewModel}
            back={back}
            toMarketplace={toMarketplace}
          />
          <Box
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.paper,
              height: windowHeight - (isPublic ? 164 : 208),
              overflowY: "auto",
            }}
            className={"meshScroll"}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <TabletAssetCardRow1 marketListingViewModel={listingViewModel} />
            </Box>
            <Box
              sx={{
                width: "100%",
                overflowX: "hidden",
              }}
            >
              <YieldLabelWithIssuer
                listingViewModel={listingViewModel}
                sx={{
                  display: "block",
                  pt: 2,
                }}
                leftOffset={4}
              />
              <TabletAssetCardRow2 marketListingViewModel={listingViewModel} />
              <AccordionDataSheetSections
                marketListingViewModel={listingViewModel}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Dialog
            open
            fullScreen
            data-component-info={JSON.stringify({
              component_id: "asset_detail_card",
              component_business_name: "asset detail card",
              component_title: listingViewModel?.assetName,
              component_driver: InteractionDriver.DriveTransaction,
            } as DataComponentInfo)}
          >
            <MobileDialogHeader
              marketListingViewModel={listingViewModel}
              back={back}
            />
            <DialogContent
              sx={{
                padding: 0,
                pt: 1,
                backgroundColor: theme.palette.background.paper,
                boxShadow: "0 -10px 12px -14px #000 inset",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  height: "99%",
                }}
              >
                <NavBar
                  marketListingViewModel={listingViewModel}
                  back={back}
                  toMarketplace={toMarketplace}
                />
                <MobileAssetCard marketListingViewModel={listingViewModel} />
                <YieldLabelWithIssuer
                  listingViewModel={listingViewModel}
                  sx={{
                    display: "block",
                    pb: 3,
                  }}
                />
                <AccordionDataSheetSections
                  marketListingViewModel={listingViewModel}
                />
              </Box>
            </DialogContent>
            <MobileTradeOrderSection
              marketListingViewModel={listingViewModel}
            />
          </Dialog>
        </>
      )}
      <AssetOverviewWelcomeDialog
        dialogProps={{ open: showAssetOverViewWelcomeDialog }}
        closeDialog={() => setShowAssetOverViewWelcomeDialog(false)}
        assetName={listingViewModel?.assetName ?? ""}
        assetShortName={listingViewModel?.assetShortName ?? ""}
        assetToken={listingViewModel?.token ?? new Token()}
      />
    </>
  );
};

export type AssetOverviewSectionProps = {
  marketListingViewModel?:
    | MarketListingViewModel
    | PublicMarketListingViewModel;
  scroll?: () => void;
};

const YieldLabelWithIssuer = ({
  listingViewModel,
  sx,
  leftOffset,
}: {
  listingViewModel:
    | MarketListingViewModel
    | PublicMarketListingViewModel
    | undefined;
  sx?: SxProps;
  leftOffset?: number;
}) => {
  const theme = useTheme();
  if (
    listingViewModel &&
    listingViewModel.assetType === AssetType.YieldBearingStablecoin
  ) {
    return (
      <Box sx={sx}>
        <IssuerName
          token={listingViewModel.token}
          typographyProps={{
            sx: {
              color: theme.palette.text.secondary,
              pl: leftOffset ?? 3,
              mb: 0.5,
            },
          }}
        />
        <YieldCoinLabel
          sx={{
            position: "relative",
            left: 0,
            borderRadius: "0 5px 5px 0",
            pl: leftOffset ?? 3,
          }}
        />
      </Box>
    );
  } else {
    return <></>;
  }
};

const NavBar = (
  props: AssetOverviewSectionProps & {
    back: () => void;
    toMarketplace: () => void;
  },
) => {
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showWaitingListPopUp, setShowWaitingListPopUp] = useState(false);
  const { authContext } = useApplicationContext();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const pending = useMemo(() => {
    if (!props.marketListingViewModel?.marketSubscriptionOrderBookViewModel)
      return false;
    return (
      props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
        ?.state === SubscriptionOrderBookState.Pending ||
      props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
        ?.state === SubscriptionOrderBookState.Opening ||
      props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
        ?.state === SubscriptionOrderBookState.OpeningFailed ||
      props.marketListingViewModel?.marketSubscriptionOrderBookViewModel
        ?.state === SubscriptionOrderBookState.OpeningUnderInvestigation
    );
  }, [props.marketListingViewModel?.marketSubscriptionOrderBookViewModel]);

  if (isMobile) {
    return (
      <Box
        sx={{
          height: "32px",
          width: "100%",
          display: "flex",
          backgroundColor: theme.palette.custom.spaceblue,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(0, 1, 0, 3),
        }}
      >
        <Breadcrumbs>
          <Typography
            id={"assetOverviewNavBar-markets-link"}
            variant={"caption"}
            children={
              <Link
                color={"secondary"}
                underline={"hover"}
                children={"Markets"}
                onClick={() => {
                  if (props.back) props.back();
                  else isPublic ? navigate("/sign-in") : navigate(-1);
                }}
              />
            }
          />
          <Typography
            variant={"caption"}
            children={props?.marketListingViewModel?.assetShortName ?? "-"}
          />
        </Breadcrumbs>

        <IconButton
          id={"assetOverviewNavBar-menuToggle-iconButton"}
          size={"small"}
          ref={mobileMenuButtonRef}
          onClick={() => setMobileMenuOpen(true)}
          data-link-info={JSON.stringify({
            content_interaction_id: "info-content",
            content_interaction_action: InteractionAction.Click,
            content_interaction_type: InteractionType.Icon,
            content_interaction_text: "expand accordions",
            content_interaction_driver: InteractionDriver.MoreInfo,
          } as DataLinkInfoType)}
        >
          <MenuIcon
            data-link-info={JSON.stringify({
              content_interaction_id: "info-content",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Icon,
              content_interaction_text: "expand accordions",
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
          />
        </IconButton>
        <Menu
          anchorEl={mobileMenuButtonRef.current}
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <MenuItem
            id={"assetOverviewNavBar-menuCopyLink-menuItem"}
            sx={{
              padding: "0px 12px",
              minWidth: "223px",
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: "info-content",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Icon,
              content_interaction_text: "copy asset link",
              content_interaction_driver: InteractionDriver.MoreInfo,
            } as DataLinkInfoType)}
            onClick={() => {
              setMobileMenuOpen(false);
              navigator.clipboard
                .writeText(
                  encodeURI(
                    `${window.location.origin}/public/market/asset-overview?code=${props?.marketListingViewModel?.token.code}&issuer=${props?.marketListingViewModel?.token.issuer}&network=${props?.marketListingViewModel?.token.network}`,
                  ),
                )
                .finally();
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Icon>
                <LinkIcon
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "copy asset link",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                  sx={{ color: "text.secondary" }}
                />
              </Icon>
              <Typography
                data-link-info={JSON.stringify({
                  content_interaction_id: "info-content",
                  content_interaction_action: InteractionAction.Click,
                  content_interaction_type: InteractionType.Icon,
                  content_interaction_text: "copy asset link",
                  content_interaction_driver: InteractionDriver.MoreInfo,
                } as DataLinkInfoType)}
                variant={"body1"}
              >
                Copy Link
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem
            id={"assetOverviewNavBar-menuOpenInStellarExpert-menuItem"}
            sx={{
              padding: "0px 12px",
              minWidth: "223px",
            }}
            onClick={() => {
              if (!props.marketListingViewModel) {
                return;
              }
              try {
                window.open(
                  `https://stellar.expert/explorer/${getNetworkString(
                    props.marketListingViewModel.token
                      .network as StellarNetwork,
                  )}/asset/${getTokenAssetString(
                    props.marketListingViewModel.token,
                  )}`,
                  "_blank",
                );
              } catch (e) {
                console.error("error opening asset in stellar explorer");
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Icon>
                <OpenInNewIcon sx={{ color: "text.secondary" }} />
              </Icon>
              <Typography variant={"body1"}>
                View Asset on Blockchain Explorer
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  }

  if (isPublic) {
    return (
      <Box
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          height: "55px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: {
            lg: 0,
            xs: theme.spacing(1),
          },
          paddingRight: {
            lg: 0,
            sm: theme.spacing(3.5),
          },
          gap: theme.spacing(0.5),
          backgroundColor: theme.palette.background.default,
          zIndex: 200,
          pr: 2,
          py: 2,
        })}
      >
        <Typography
          id={"assetOverviewNavBar-markets-link"}
          variant={"caption"}
          children={
            <Link
              color={"secondary"}
              underline={"hover"}
              children={"Markets"}
              onClick={() => navigate("/sign-in")}
            />
          }
        />
        <Typography
          sx={{ color: "text.disabled" }}
          variant={"caption"}
          children={"/"}
        />
        <Typography
          variant={"caption"}
          children={props?.marketListingViewModel?.assetShortName ?? "-"}
        />
        {!smDown &&
          pending &&
          props.marketListingViewModel?.listingMarketMechanisms[0]?.type ===
            MechanismType.Subscription && (
            <Button
              sx={{
                height: {
                  sm: 36,
                  xs: 48,
                  mr: -1,
                },
                ml: "auto",
                textTransform: "none",
              }}
              onClick={() => setShowWaitingListPopUp(true)}
              variant={"contained"}
              color={"secondary"}
              data-link-info={JSON.stringify({
                content_interaction_id:
                  props.marketListingViewModel.assetName +
                  "_investment_intent_amount",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Button,
                content_interaction_text: "join the waiting list",
                content_interaction_driver: "form",
              } as DataLinkInfoType)}
            >
              Join the Waiting List
            </Button>
          )}

        {showWaitingListPopUp && props.marketListingViewModel && (
          <WaitingListPopUpDialog
            dialogProps={{ open: showWaitingListPopUp }}
            closeDialog={() => setShowWaitingListPopUp(false)}
            assetToken={props.marketListingViewModel.token}
            isPublic={isPublic}
            context={authContext}
            marketListingViewModel={props.marketListingViewModel}
          />
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          height: "55px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingRight: {
            lg: 0,
            sm: theme.spacing(3.5),
          },
          gap: theme.spacing(0.5),
          backgroundColor: theme.palette.background.default,
          zIndex: 200,
          pr: 2,
        })}
      >
        <Button
          id={"assetOverviewNavBar-back-button"}
          sx={{
            textTransform: "none",
            minWidth: "80px",
            width: "80px",
          }}
          variant={"text"}
          color={"secondary"}
          startIcon={<BackIcon />}
          children={"Back"}
          onClick={props.back}
        />
        <Typography
          id={"assetOverviewNavBar-markets-link"}
          sx={{ marginLeft: "auto" }}
          variant={"caption"}
          children={
            <Link
              color={"secondary"}
              underline={"hover"}
              children={"Markets"}
              onClick={props.toMarketplace}
            />
          }
        />
        <Typography
          sx={{ color: "text.disabled" }}
          variant={"caption"}
          children={"/"}
        />
        <Typography
          variant={"caption"}
          children={props?.marketListingViewModel?.assetShortName ?? "-"}
        />
      </Box>
    );
  }
};

const MobileTradeOrderSection = (props: AssetOverviewSectionProps) => {
  const theme = useTheme();
  const [assetIsIssued, setAssetIsIssued] = useState(false);
  useEffect(() => {
    // do nothing until given market listing view model is set
    if (!props.marketListingViewModel) {
      return;
    }
    const isSubscription = [
      AssetType.PreferenceShare,
      AssetType.Bond,
      AssetType.Share,
      "",
    ].includes(props.marketListingViewModel.assetType);
    // compute required parameters
    setAssetIsIssued(
      dayjs(props.marketListingViewModel.assetIssueDate).isBefore(dayjs()) ||
        isSubscription,
    );
  }, [props.marketListingViewModel]);
  // render nothing if asset is not issued
  if (!assetIsIssued) {
    return null;
  }

  return (
    <DialogTitle
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 3, 5, 3),
      }}
    >
      {(() => {
        if (!props.marketListingViewModel) {
          return null;
        }

        // if there are no market mechanisms then no action can be returned
        if (!props.marketListingViewModel.listingMarketMechanisms.length) {
          return null;
        }

        // assume that the first listing market mechanism is the relevant one
        let relevantMarketMechanism: Mechanism =
          props.marketListingViewModel.listingMarketMechanisms[0];

        // There is only 1 supported use case for 2 market mechanisms at the moment.
        // This is when 1 market mechanism is subscription order book and the other is
        // direct order. This is to deal with that use case after the book has settled.
        if (
          props.marketListingViewModel.listingMarketMechanisms.length == 2 &&
          props.marketListingViewModel.listingMarketMechanisms[0].type ===
            MechanismType.Subscription &&
          props.marketListingViewModel.listingMarketMechanisms[1].type ===
            MechanismType.DirectOrder &&
          props.marketListingViewModel.marketSubscriptionOrderBookViewModel &&
          props.marketListingViewModel.marketSubscriptionOrderBookViewModel
            .state === SubscriptionOrderBookState.Settled
        ) {
          relevantMarketMechanism =
            props.marketListingViewModel.listingMarketMechanisms[1];
        }

        switch (relevantMarketMechanism.type) {
          case MechanismType.DirectOrder:
            return assetIsIssued ? (
              <DirectOrderSection
                marketListingViewModel={props.marketListingViewModel}
              />
            ) : (
              <IssuePriceSection
                issueDate={props.marketListingViewModel.assetIssueDate}
              />
            );

          case MechanismType.Spot:
            return assetIsIssued ? (
              <SpotSection
                marketListingViewModel={props.marketListingViewModel}
              />
            ) : (
              <IssuePriceSection
                issueDate={props.marketListingViewModel.assetIssueDate}
              />
            );

          case MechanismType.Subscription:
            return (
              <BondSection
                marketListingViewModel={
                  props.marketListingViewModel as MarketListingViewModel
                }
              />
            );

          default:
            return null;
        }
      })()}
    </DialogTitle>
  );
};

const MobileDialogHeader = (
  props: AssetOverviewSectionProps & { back: () => void },
) => {
  const location = useLocation();
  const isPublic = location.pathname.startsWith("/public");
  const navigate = useNavigate();

  if (props.marketListingViewModel) {
    return (
      <DialogTitle
        sx={(theme) => ({
          padding: theme.spacing(1, 1, 1, 2),
          display: "flex",
          boxShadow: "0 -10px 12px -14px #000 inset",
        })}
      >
        <IconViewUpload
          size={48}
          disableChangeIcon
          token={props.marketListingViewModel.token}
          tokenIconDownloadURL={props.marketListingViewModel.tokenIconURL}
        />
        <Box
          sx={{
            mx: 2,
            width: "calc(100vw - 136px)",
          }}
        >
          <Typography
            noWrap
            variant={"h4"}
            sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
            children={props.marketListingViewModel.assetName}
          />
          <Typography
            noWrap
            variant={"caption"}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            children={props.marketListingViewModel.token.code}
          />
        </Box>
        <Box sx={{ alignSelf: "start" }}>
          <Tooltip title={"Close"} placement={"top"}>
            <span>
              <IconButton
                id={"assetOverviewDialog-close-iconButton"}
                size={"small"}
                onClick={() => {
                  if (props.back) props.back();
                  else isPublic ? navigate("/sign-in") : navigate(-1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </DialogTitle>
    );
  } else {
    return (
      <DialogTitle
        sx={(theme) => ({
          padding: theme.spacing(1, 1, 1, 2),
        })}
      >
        <Grid container direction={"row"} spacing={2} alignItems={"center"}>
          <Grid item>
            <Skeleton variant={"circular"} width={48} height={48} />
          </Grid>
          <Grid item>
            <Skeleton width={200} height={25} />
            <Skeleton width={150} height={15} />
          </Grid>
        </Grid>
        <Box sx={{ alignSelf: "start" }}>
          <Tooltip title={"Close"} placement={"top"} arrow>
            <span>
              <IconButton
                id={"assetOverviewDialog-close-iconButton"}
                size={"small"}
                onClick={() => {
                  if (props.back) props.back();
                  else isPublic ? navigate("/sign-in") : navigate(-1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </DialogTitle>
    );
  }
};

const TabletAssetCardRow1 = (props: AssetOverviewSectionProps) => {
  const [assetIsIssued, setAssetIsIssued] = useState(false);
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    // do nothing until given market listing view model is set
    if (!props.marketListingViewModel) {
      return;
    }

    // compute required parameters
    setAssetIsIssued(
      dayjs(props.marketListingViewModel.assetIssueDate).isBefore(dayjs()),
    );

    setInitialised(true);
  }, [props.marketListingViewModel]);

  if (props.marketListingViewModel && initialised) {
    return (
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "111px",
          display: "flex",
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2.5, 3, 2.5, 3),
          boxShadow: 5,
        })}
      >
        <IconViewUpload
          size={48}
          disableChangeIcon
          tokenIconDownloadURL={props.marketListingViewModel.tokenIconURL}
          token={props.marketListingViewModel.token}
        />
        <Box
          sx={(theme) => ({
            mx: theme.spacing(2),
            flexGrow: 1,
            maxWidth: "calc(100% - 392px)",
          })}
        >
          <Typography
            noWrap
            children={props.marketListingViewModel.assetName}
            variant={"h4"}
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightBold,
            })}
          />
          <Typography
            children={props.marketListingViewModel.assetShortName}
            variant={"subtitle2"}
            sx={{ color: "text.secondary" }}
          />
        </Box>
        <Box sx={{ width: 306 }}>
          {(() => {
            // if there are no market mechanisms then no action can be returned
            if (!props.marketListingViewModel.listingMarketMechanisms.length) {
              return null;
            }

            // assume that the first listing market mechanism is the relevant one
            let relevantMarketMechanism: Mechanism =
              props.marketListingViewModel.listingMarketMechanisms[0];

            // There is only 1 supported use case for 2 market mechanisms at the moment.
            // This is when 1 market mechanism is subscription order book and the other is
            // direct order. This is to deal with that use case after the book has settled.
            if (
              props.marketListingViewModel.listingMarketMechanisms.length ==
                2 &&
              props.marketListingViewModel.listingMarketMechanisms[0].type ===
                MechanismType.Subscription &&
              props.marketListingViewModel.listingMarketMechanisms[1].type ===
                MechanismType.DirectOrder &&
              props.marketListingViewModel
                .marketSubscriptionOrderBookViewModel &&
              props.marketListingViewModel.marketSubscriptionOrderBookViewModel
                .state === SubscriptionOrderBookState.Settled
            ) {
              relevantMarketMechanism =
                props.marketListingViewModel.listingMarketMechanisms[1];
            }

            switch (relevantMarketMechanism.type) {
              case MechanismType.DirectOrder:
                return assetIsIssued ? (
                  <DirectOrderSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <IssuePriceSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              case MechanismType.Spot:
                return assetIsIssued ? (
                  <SpotSection
                    marketListingViewModel={props.marketListingViewModel}
                  />
                ) : (
                  <IssuePriceSection
                    issueDate={props.marketListingViewModel.assetIssueDate}
                  />
                );

              case MechanismType.Subscription:
                return (
                  <BondSection
                    marketListingViewModel={
                      props.marketListingViewModel as MarketListingViewModel
                    }
                  />
                );

              default:
                return null;
            }
          })()}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "111px",
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          columnGap: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2.5, 3, 2.5, 4),
          boxShadow: 5,
        })}
      >
        <Skeleton variant={"circular"} width={48} height={48} />
        <Box>
          <Skeleton width={200} height={25} />
          <Skeleton width={150} height={15} />
        </Box>
        <Skeleton width={306} height={"100%"} />
      </Box>
    );
  }
};

const TabletAssetCardRow2 = (props: AssetOverviewSectionProps) => {
  const theme = useTheme();

  const [isCryptoCard, setIsCryptoCard] = useState(false);
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    // do nothing until given market listing view model is set
    if (!props.marketListingViewModel) {
      return;
    }

    // compute required parameters
    setIsCryptoCard(
      cryptoCardAssetTypes.includes(props.marketListingViewModel?.assetType),
    );

    setInitialised(true);
  }, [props.marketListingViewModel]);

  const isSmartInstrument = useMemo(
    () =>
      props.marketListingViewModel?.assetType === AssetType.Bond ||
      props.marketListingViewModel?.assetType === AssetType.Share ||
      props.marketListingViewModel?.assetType === AssetType.PreferenceShare,
    [props.marketListingViewModel?.assetType],
  );
  const isBond = useMemo(
    () => props.marketListingViewModel?.assetType === AssetType.Bond,
    [props.marketListingViewModel?.assetType],
  );
  const isSubscription = useMemo(
    () =>
      props.marketListingViewModel?.assetType === AssetType.PreferenceShare ||
      props.marketListingViewModel?.assetType === AssetType.Bond ||
      props.marketListingViewModel?.assetType === AssetType.Share,
    [props.marketListingViewModel?.assetType],
  );

  if (props.marketListingViewModel && initialised) {
    if (isCryptoCard) {
      return (
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            columnGap: theme.spacing(2),
            alignItems: "center",
            pt:
              props.marketListingViewModel.assetType ===
              AssetType.YieldBearingStablecoin
                ? 1
                : 3,
            pb: 3,
            pr: 3,
            pl: 4,
          }}
        >
          <InstrumentTypeC
            token={props.marketListingViewModel.token}
            assetType={props.marketListingViewModel.assetType}
          />
          <NetworkLogo
            breakPoint={"sm"}
            network={props.marketListingViewModel.token.network}
          />
        </Box>
      );
    } else {
      return (
        <Box
          className={cx({
            bond: isBond,
          })}
          sx={(theme) => ({
            width: "100%",
            height: 83,
            display: "grid",
            gridTemplateColumns: "2fr 1fr auto",
            columnGap: theme.spacing(2),
            padding: theme.spacing(0, 3, 0, 4),
            alignItems: "center",
            "&.bond": {
              display: "flex",
            },
          })}
        >
          <Box
            className={cx({
              bond: isBond,
            })}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "start",
              "&.bond": {
                display: "flex",
                alignItems: "center",
                width: "100%",
                "& > .returnIndicator": {
                  display: "flex",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  ml: 5,
                  mt: 2,
                },
              },
            }}
          >
            <InstrumentTypeC
              token={props.marketListingViewModel.token}
              assetType={props.marketListingViewModel.assetType}
            />
            <Box className="returnIndicator">
              <ReturnIndicator
                reverseAlign
                returnDescription={
                  isBond
                    ? "Indicative Returns p.a."
                    : `Return (${props.marketListingViewModel.returnDescription})
                `
                }
                returnDisclaimer={
                  isBond
                    ? `*${props.marketListingViewModel.returnDescription}${
                        props.marketListingViewModel.assetType ===
                          AssetType.Bond &&
                        /\d$/.test(
                          props.marketListingViewModel.returnDescription,
                        )
                          ? "%"
                          : ""
                      }`
                    : undefined
                }
                returnValue={props.marketListingViewModel.returnValue}
                isSmartInstrument={isSmartInstrument}
              />
              {isBond && (
                <IssuerNameCard token={props.marketListingViewModel.token} />
              )}
            </Box>
          </Box>

          {!isSubscription && (
            <>
              <RiskProfile
                riskProfile={props.marketListingViewModel.instrumentRiskProfile}
              />
              <Typography
                component={"div"}
                variant={"caption"}
                sx={{
                  marginLeft: "100px",
                  color: "text.disabled",
                  lineHeight: "18px",
                  width: 249,
                }}
              >
                *Note that the Mesh price might differ from the exchange traded
                price.
              </Typography>
            </>
          )}
        </Box>
      );
    }
  } else {
    return (
      <Box
        sx={(theme) => ({
          height: "100px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2.5, 3, 2.5, 4),
          gap: "82px",
        })}
      >
        <Skeleton width={103} height={"100%"} />
        <Skeleton width={80} height={"100%"} />
        <Skeleton sx={{ marginLeft: "auto" }} width={87} height={"100%"} />
      </Box>
    );
  }
};

export enum DataSheetSectionsTab {
  Asset = "Asset",
  Participants = "Participants",
  HowDoesItWork = "How It Works",
}

const AccordionDataSheetSections = (props: AssetOverviewSectionProps) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const { errorContextErrorTranslator } = useErrorContext();
  const [searchParams] = useSearchParams();
  const [selectedDatasheetSectionTab, setSelectedDataSheetSectionTab] =
    useState<DataSheetSectionsTab>(DataSheetSectionsTab.Asset);
  const [dataSheetSections, setDataSheetSections] = useState<
    DataSheetSection[]
  >([]);
  const [expandedState, setExpandedState] = useState<{
    [key in DataSheetSectionsTab]: Record<string, boolean | undefined>;
  }>({
    [DataSheetSectionsTab.Asset]: {},
    [DataSheetSectionsTab.Participants]: {},
    [DataSheetSectionsTab.HowDoesItWork]: {},
  });
  const [linkCopied, setLinkCopied] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [documentsExist, setDocumentsExist] = useState(false);

  const goToIssuer = useCallback(
    () => setSelectedDataSheetSectionTab(DataSheetSectionsTab.Participants),
    [],
  );
  const isBond = useMemo(
    () => props.marketListingViewModel?.assetType === AssetType.Bond,
    [props.marketListingViewModel?.assetType],
  );

  const allDataSheetSectionsTabs: DataSheetSectionsTab[] = useMemo(() => {
    const assetsWithHowDoesItWork = [
      AssetType.CryptoCurrency,
      AssetType.ETF,
      AssetType.ETN,
      AssetType.RightsToACryptoCurrency,
      AssetType.RightsToAnETF,
      AssetType.RightsToAnETN,
      AssetType.YieldBearingStablecoin,
      AssetType.RightsToAFiatCurrency,
      AssetType.Bond,
      AssetType.PreferenceShare,
    ];

    let dataSheetSectionsList: DataSheetSectionsTab[] = [
      DataSheetSectionsTab.Asset,
      DataSheetSectionsTab.Participants,
    ];

    if (
      props.marketListingViewModel?.assetType &&
      assetsWithHowDoesItWork.includes(props.marketListingViewModel?.assetType)
    ) {
      dataSheetSectionsList = dataSheetSectionsList.concat(
        DataSheetSectionsTab.HowDoesItWork,
      );
    }

    return dataSheetSectionsList;
  }, [props.marketListingViewModel]);

  useEffect(() => {
    (async () => {
      // get the asset token from the url
      const assetTokenCode =
        searchParams.get("code") ?? props.marketListingViewModel?.token.code;
      const assetTokenIssuer =
        searchParams.get("issuer") ??
        props.marketListingViewModel?.token.issuer;
      const assetTokenNetwork =
        searchParams.get("network") ??
        props.marketListingViewModel?.token.network;
      if (!(assetTokenCode && assetTokenIssuer && assetTokenNetwork)) {
        return;
      }

      // read asset data sheet
      try {
        const dataSheet = (
          await AssetDataSheetReader.ReadAssetDataSheet({
            assetIdentifier: TokenIdentifier(
              new Token({
                code: assetTokenCode,
                issuer: assetTokenIssuer,
                network: assetTokenNetwork as LedgerNetwork,
              }),
            ),
          })
        ).dataSheet;

        // prepare new expanded state
        const newExpandedState: { [key: number]: boolean | undefined } = {};
        for (let i = 0; i < dataSheet.sections.length; i++) {
          newExpandedState[i] = true; // all panels are expanded initially
        }
        if (isMounted()) {
          // set expanded state
          setExpandedState({
            ...expandedState,
            [selectedDatasheetSectionTab]: newExpandedState,
          });

          // sort sections and set state
          setDataSheetSections(
            dataSheet.sections
              .map((section) => {
                let order = 100;
                switch (true) {
                  case section instanceof
                    AssetSubscriptionOverviewDataSheetSection:
                    order = 0;
                    break;

                  case section instanceof
                    AssetInvestmentObjectiveDataSheetSection:
                    order = 1;
                    break;
                  case section instanceof
                    StellarAssetPublicInfoDataSheetSection:
                    order = 1;
                    break;
                  case section instanceof
                    AssetSectorAllocationsAndHoldingsDataSheetSection:
                    order = 2;
                    break;
                  case section instanceof AssetPerformanceDataSheetSection:
                    order = 3;
                    break;
                  case section instanceof AssetPriceHistoryDataSheetSection:
                    order = 4;
                    break;
                  case section instanceof
                    AssetIndependentReviewDataSheetSection:
                    order = 5;
                    break;
                  case section instanceof AssetManagingRiskDataSheetSection:
                    order = 6;
                    break;
                  case section instanceof AssetDocumentsDataSheetSection:
                    setDocumentsExist(true);
                    order = 7;
                    break;
                  case section instanceof AssetMarketingMediaDataSheetSection:
                    order = 6;
                    break;
                  case section instanceof AssetRepaymentTermsDataSheetSection:
                    order = 2;
                    break;
                }
                return {
                  section,
                  order,
                };
              })
              .sort((x, y) => {
                if (x.order < y.order) {
                  return -1;
                }
                if (x.order > y.order) {
                  return 1;
                }
                return 0;
              })
              .map((sorted) => sorted.section),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching and populating datasheet sections: ${
            err.message ? err.message : err.toString()
          }`,
        );
        return;
      }
    })();
  }, []);

  if (!props.marketListingViewModel || dataSheetSections.length === 0) {
    return (
      <Box
        sx={{
          borderRadius: {
            lg: "0 10px 0 0",
            xs: "0",
          },
          backgroundColor: theme.palette.custom.midnight,
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(0, 3),
            width: "100%",
            height: "49px",
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            justifyContent: "center",
          }}
        >
          <Skeleton width={132} height={"100%"} />
          <Skeleton width={132} height={"100%"} />
          <Skeleton width={132} height={"100%"} />
          {!isMobile && (
            <>
              <Skeleton
                sx={{ marginLeft: "auto" }}
                width={24}
                height={"100%"}
              />
              <Skeleton width={24} height={"100%"} />
            </>
          )}
        </Box>
        <Box
          sx={{
            padding: theme.spacing(0, 3),
            width: "100%",
            height: "52px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Skeleton width={235} height={"100%"} />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "245px",
            display: "grid",
            backgroundColor: theme.palette.background.paper,
            gridTemplateColumns: {
              sm: "1fr auto",
              xs: "1fr",
            },
            padding: theme.spacing(4, 3),
          }}
        >
          <Box>
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"100%"} height={20} />
            <Skeleton width={"80%"} height={20} />
          </Box>
          {!isMobile && (
            <Box
              sx={(theme) => ({
                width: "259px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                paddingTop: "22px",
                gap: theme.spacing(0.5),
              })}
            >
              <Skeleton width={100} height={20} />
              <Skeleton width={137} height={20} />
              <Skeleton width={150} height={1} />
              <Skeleton width={137} height={20} />
              <Skeleton width={100} height={20} />
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(0, 3),
            width: "100%",
            height: "52px",
            display: "flex",
            flexDirection: "row",
          })}
        >
          <Skeleton width={235} height={"100%"} />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            top: 55,
            zIndex: 2,
          },
          backgroundColor: theme.palette.custom.midnight,
          display: "grid",
          paddingRight: theme.spacing(1),
          gridTemplateColumns: {
            sm: "1fr auto",
            xs: "1fr",
          },
          justifyItems: {
            sm: "unset",
            xs: "center",
          },
          boxShadow: "0 -10px 12px -14px #000 inset",
        }}
      >
        <Tabs
          value={selectedDatasheetSectionTab}
          onChange={(_, newTab) => {
            setSelectedDataSheetSectionTab(newTab);
            window.dispatchEvent(new Event("MarketPlaceScrollTop"));
          }}
          textColor={"inherit"}
        >
          {allDataSheetSectionsTabs.map((tab) => (
            <Tab
              sx={{ textTransform: "none" }}
              id={`assetOverviewAccordionSection-${tab}-tab`}
              value={tab}
              key={tab}
              label={tab.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}
              data-link-info={JSON.stringify({
                content_interaction_id: "info-tab",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Tab,
                content_interaction_text: tab.toLocaleLowerCase(),
                content_interaction_driver: InteractionDriver.MoreInfo,
              } as DataLinkInfoType)}
            />
          ))}
        </Tabs>
        {!isMobile && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {[
                DataSheetSectionsTab.Asset,
                DataSheetSectionsTab.HowDoesItWork,
              ].includes(selectedDatasheetSectionTab) && (
                <IconButton
                  id={"assetOverview-toggleAccordionExpandedStates-iconButton"}
                  onClick={() => {
                    // determine if all panels are open
                    const allOpen = Object.keys(
                      expandedState[selectedDatasheetSectionTab],
                    ).reduce(
                      (prevIdx, idx) =>
                        !!expandedState[selectedDatasheetSectionTab][idx] &&
                        prevIdx,
                      true,
                    );

                    // prepare new expanded state
                    const newExpandedState: {
                      [key: string]: boolean | undefined;
                    } = {};
                    for (const idx in expandedState[
                      selectedDatasheetSectionTab
                    ]) {
                      // if all panels are open then close them
                      // otherwise open them all
                      newExpandedState[idx] = !allOpen;
                    }
                    setExpandedState({
                      ...expandedState,
                      [selectedDatasheetSectionTab]: newExpandedState,
                    });
                  }}
                  sx={{
                    opacity: 0.88, // Set default opacity to 88%
                  }}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "expand accordions",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                >
                  {Object.keys(
                    expandedState[selectedDatasheetSectionTab],
                  ).reduce(
                    (prevIdx, idx) =>
                      !!expandedState[selectedDatasheetSectionTab][idx] &&
                      prevIdx,
                    true,
                  ) ? (
                    <UnfoldLess
                      data-link-info={JSON.stringify({
                        content_interaction_id: "info-content",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_text: "expand accordions",
                        content_interaction_driver: InteractionDriver.MoreInfo,
                      } as DataLinkInfoType)}
                    />
                  ) : (
                    <UnfoldMore
                      data-link-info={JSON.stringify({
                        content_interaction_id: "info-content",
                        content_interaction_action: InteractionAction.Click,
                        content_interaction_type: InteractionType.Icon,
                        content_interaction_text: "expand accordions",
                        content_interaction_driver: InteractionDriver.MoreInfo,
                      } as DataLinkInfoType)}
                    />
                  )}
                </IconButton>
              )}
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setLinkCopied(false)}
                open={linkCopied}
                disableFocusListener
                title={"Copied"}
              >
                <IconButton
                  id={"assetOverview-copyLink-iconButton"}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        encodeURI(
                          `${window.location.origin}/public/market/asset-overview?code=${props?.marketListingViewModel?.token.code}&issuer=${props?.marketListingViewModel?.token.issuer}&network=${props?.marketListingViewModel?.token.network}`,
                        ),
                      )
                      .then(() => setLinkCopied(true));
                  }}
                  onMouseLeave={() => setLinkCopied(false)}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "copy asset link",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                >
                  <LinkIcon
                    data-link-info={JSON.stringify({
                      content_interaction_id: "info-content",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_type: InteractionType.Icon,
                      content_interaction_text: "copy asset link",
                      content_interaction_driver: InteractionDriver.MoreInfo,
                    } as DataLinkInfoType)}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={"View Asset on Blockchain Explorer"}
                placement={"top"}
              >
                <IconButton
                  id={"assetOverview-openOnStellarExpert-iconButton"}
                  onClick={() => {
                    if (!props.marketListingViewModel) {
                      return;
                    }
                    try {
                      window.open(
                        `https://stellar.expert/explorer/${getNetworkString(
                          props.marketListingViewModel.token
                            .network as StellarNetwork,
                        )}/asset/${getTokenAssetString(
                          props.marketListingViewModel.token,
                        )}`,
                        "_blank",
                      );
                    } catch (e) {
                      console.error("error opening asset in stellar explorer");
                    }
                  }}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "info-content",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "open on stellar expert",
                    content_interaction_driver: InteractionDriver.MoreInfo,
                  } as DataLinkInfoType)}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
      </Box>
      <TabContext value={selectedDatasheetSectionTab}>
        <TabPanel value={DataSheetSectionsTab.Asset} sx={{ p: 0 }}>
          <AssetDataSheets
            documentsExist={documentsExist}
            dataSheetSections={dataSheetSections}
            marketListingViewModel={props.marketListingViewModel}
            setExpandedState={setExpandedState}
            expandedState={expandedState}
            functions={{
              goToIssuer: goToIssuer,
            }}
            isBond={isBond}
          />
        </TabPanel>
        <TabPanel value={DataSheetSectionsTab.Participants} sx={{ p: 0 }}>
          {props.marketListingViewModel && (
            <Participants
              marketListingViewModel={props.marketListingViewModel}
              token={props.marketListingViewModel.token}
            />
          )}
        </TabPanel>
        <TabPanel value={DataSheetSectionsTab.HowDoesItWork} sx={{ p: 0 }}>
          {props.marketListingViewModel && (
            <HowDoesItWork
              marketListingViewModel={props.marketListingViewModel}
              expandedState={expandedState}
              setExpandedState={setExpandedState}
            />
          )}
        </TabPanel>
      </TabContext>
    </>
  );
};
