import { Document } from "james/document";
import config from "react-global-configuration";
import { DigitalETN } from "./DigitalETN";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { Context } from "../security";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { Amount } from "../ledger/Amount";

export interface DraftUpdateDigitalETNRequest {
  context: Context;
  digitalETN: DigitalETN;
}

export interface DraftUpdateDigitalETNResponse {
  digitalETN: DigitalETN;
}

export interface IncreaseDigitalETNMaximumUnitsRequest {
  context: Context;
  digitalETNID: string;
  amount: Amount;
}

export interface IncreaseDigitalETNMaximumUnitsResponse {
  digitalETN: DigitalETN;
}

export interface DecreaseDigitalETNMaximumUnitsRequest {
  context: Context;
  digitalETNID: string;
  amount: Amount;
}

export interface DecreaseDigitalETNMaximumUnitsResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNMaturityDateRequest {
  context: Context;
  digitalETNID: string;
  maturityDate: string;
}

export interface ChangeDigitalETNMaturityDateResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNHoldingsRequest {
  context: Context;
  digitalETNID: string;
  updatedHoldings: Holding[];
}

export interface ChangeDigitalETNHoldingsResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNSectorAllocationsRequest {
  context: Context;
  digitalETNID: string;
  updatedSectorAllocations: SectorAllocation[];
}

export interface ChangeDigitalETNSectorAllocationsResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNCountryAllocationsRequest {
  context: Context;
  digitalETNID: string;
  updatedCountryAllocations: CountryAllocation[];
}

export interface ChangeDigitalETNCountryAllocationsResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNAnnualPerformanceLogRequest {
  context: Context;
  digitalETNID: string;
  updatedAnnualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface ChangeDigitalETNAnnualPerformanceLogResponse {
  digitalETN: DigitalETN;
}

export interface ChangeDigitalETNSupportingDocumentsRequest {
  context: Context;
  digitalETNID: string;
  updatedSupportingDocuments: Document[];
}

export interface ChangeDigitalETNSupportingDocumentsResponse {
  digitalETN: DigitalETN;
}

export const DigitalETNUpdater = {
  serviceProvider: "financial-DigitalETNUpdater",
  async DraftUpdateDigitalETN(
    request: DraftUpdateDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<DraftUpdateDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.DraftUpdateDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async IncreaseDigitalETNMaximumUnits(
    request: IncreaseDigitalETNMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<IncreaseDigitalETNMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.IncreaseDigitalETNMaximumUnits`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async DecreaseDigitalETNMaximumUnits(
    request: DecreaseDigitalETNMaximumUnitsRequest,
    additionalHeaders?: Headers,
  ): Promise<DecreaseDigitalETNMaximumUnitsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.DecreaseDigitalETNMaximumUnits`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNMaturityDate(
    request: ChangeDigitalETNMaturityDateRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNMaturityDateResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNMaturityDate`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNHoldings(
    request: ChangeDigitalETNHoldingsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNHoldingsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNHoldings`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNSectorAllocations(
    request: ChangeDigitalETNSectorAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNSectorAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNSectorAllocations`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNCountryAllocations(
    request: ChangeDigitalETNCountryAllocationsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNCountryAllocationsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNCountryAllocations`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNAnnualPerformanceLog(
    request: ChangeDigitalETNAnnualPerformanceLogRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNAnnualPerformanceLogResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNAnnualPerformanceLog`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
  async ChangeDigitalETNSupportingDocuments(
    request: ChangeDigitalETNSupportingDocumentsRequest,
    additionalHeaders?: Headers,
  ): Promise<ChangeDigitalETNSupportingDocumentsResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNUpdater.serviceProvider}.ChangeDigitalETNSupportingDocuments`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
};
