import React from "react";
import {
  Box,
  CardHeader,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { Edit as EditIcon, ExitToApp } from "@mui/icons-material";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import { useNavigate } from "react-router-dom";

interface SmallHeaderProps {
  cardHover: boolean;
  marketListingViewModel: MarketListingViewModel;
  onEdit?: () => void;
  onView?: () => void;
}

export const Header = ({ cardHover, onView, ...props }: SmallHeaderProps) => {
  const navigate = useNavigate();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <CardHeader
      sx={(theme) => ({
        padding: {
          xs: theme.spacing(0, 2),
          sm: theme.spacing(0, 2, 0, 3),
        },
        height: 86,
      })}
      disableTypography
      avatar={
        <IconViewUpload
          id={`smallHeader-icon-iconViewUpload-${props.marketListingViewModel.assetName}`}
          token={props.marketListingViewModel.token}
          tokenIconDownloadURL={props.marketListingViewModel.tokenIconURL}
          tokenOwnerID={props.marketListingViewModel.assetOwnerID}
          size={48}
        />
      }
      title={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 40px",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
              variant="h5"
              id={`smallHeader-heading-typography-${props.marketListingViewModel.assetName}`}
              children={props.marketListingViewModel.assetName}
            />
            <Typography
              noWrap
              id={`smallHeader-caption-typography-${props.marketListingViewModel.assetName}`}
              variant={"caption"}
              sx={(theme) => ({ color: theme.palette.text.secondary })}
              children={props.marketListingViewModel.token.code}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContents: "space-around",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {(cardHover || smDown) && props.onEdit && (
              <EditIcon
                id={`smallHeader-edit-iconButton-${props.marketListingViewModel.assetName}`}
                aria-label={"edit"}
                onClick={(e) => {
                  if (e && e.stopPropagation) {
                    e.stopPropagation();
                  }
                  if (props.onEdit) {
                    props.onEdit();
                  }
                }}
              />
            )}

            {(cardHover || smDown) && !(smDown && props.onEdit) && (
              <ExitToApp
                id={`smallHeader-assetDetailView-iconButton-${props.marketListingViewModel.assetName}`}
                onClick={(e) => {
                  if (e && e.stopPropagation) {
                    e.stopPropagation();
                  }
                  LogRocket.track(AssetEvent.explore, {
                    assetName: props.marketListingViewModel.assetName,
                    assetShortName: props.marketListingViewModel.assetShortName,
                    assetType: props.marketListingViewModel.assetType,
                  });
                  if (onView) {
                    onView();
                  } else {
                    navigate(
                      `/market/asset-overview?code=${props.marketListingViewModel.token.code}&issuer=${props.marketListingViewModel.token.issuer}&network=${props.marketListingViewModel.token.network}`,
                    );
                  }
                }}
                sx={(theme) => ({
                  color: {
                    sm: theme.palette.text.primary,
                    xs: theme.palette.text.disabled,
                  },
                })}
              />
            )}
          </Box>
        </Box>
      }
    />
  );
};
