import { useApplicationContext } from "context/Application/Application";
import { useIsMounted } from "hooks";
import { LedgerNetwork } from "james/ledger/Network";
import { TokenIdentifier } from "james/search/identifier";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Asset } from "james/ledger/Asset";
import { Listing, ListingRepository } from "james/market";
import {
  AssetFetcher,
  AssetWrapper,
  FetchAssetRequest,
  Token,
} from "james/ledger";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import {
  newTextExactCriterion,
  newUint32ExactCriterion,
} from "@mesh/common-js/dist/search";
import { enqueueSnackbar } from "notistack";

// TODO: Add view mode.
export type MarketingInfoContextType = {
  apiCallInProgress: boolean;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;
  assetInformation: Asset | undefined;
  listingInformation: Listing | undefined;
};

export const defaultContext: MarketingInfoContextType = {
  apiCallInProgress: false,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  assetInformation: undefined,
  listingInformation: new Listing(),
};

const MarketingInfoContext =
  React.createContext<MarketingInfoContextType>(defaultContext);

export const useMarketingInfoContext = () => useContext(MarketingInfoContext);

export const MarketingInfoContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ system, children }) => {
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();

  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
  } = useAPIContext();

  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  const [asset, setAsset] = useState<Asset | undefined>();
  const [listing, setListing] = useState<Listing | undefined>();

  const [token, setToken] = useState<Token>(new Token());

  useEffect(() => {
    // do nothing if:
    // - if there was a data loading error
    // - component no longer mounted
    if (dataLoadError || !isMounted()) {
      return;
    }

    // get the token for the asset that is to be listed from the url
    const urlTokenCode = searchParams.get("token-code");
    const urlTokenIssuer = searchParams.get("token-issuer");
    const urlTokenNetwork = searchParams.get("token-network");

    if (!(urlTokenCode && urlTokenIssuer && urlTokenNetwork)) {
      console.error("token not found in url");
      navigate({
        pathname: "/builder/marketing/table",
      });
      return;
    }

    setToken(
      new Token({
        code: urlTokenCode,
        issuer: urlTokenIssuer,
        network: urlTokenNetwork as LedgerNetwork,
      }),
    );
  }, [dataLoadError, isMounted]);

  // Get the asset
  useEffect(() => {
    (async () => {
      if (token.isUndefined() || !isMounted()) {
        return;
      }

      try {
        const instrumentForState = (
          system
            ? await smartInstrumentReaderUNSCOPED.readOneSmartInstrumentUNSCOPED(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("token.code", token.code),
                    newTextExactCriterion("token.issuer", token.issuer),
                    newUint32ExactCriterion(
                      "token.network",
                      token.toFutureToken().getNetwork(),
                    ),
                  ]),
              )
            : await smartInstrumentReader.readOneSmartInstrument(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("token.code", token.code),
                    newTextExactCriterion("token.issuer", token.issuer),
                    newUint32ExactCriterion(
                      "token.network",
                      token.toFutureToken().getNetwork(),
                    ),
                  ]),
              )
        ).getSmartinstrument();

        if (instrumentForState === undefined) {
          throw new Error("Instrument not found");
        }

        setAsset(new AssetWrapper(instrumentForState));
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);

        if (`${e}`.includes("not found")) {
          try {
            const fetchAssetRequest: FetchAssetRequest = {
              context: authContext,
              identifier: TokenIdentifier(token, "token"),
            };
            const fetchedAsset = (
              await AssetFetcher.FetchAsset(fetchAssetRequest)
            ).asset;
            setAsset(fetchedAsset);
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error("Error fetching asset: ", err);
            setDataLoadError(err.message);
            setAsset(undefined);
            enqueueSnackbar(`error fetching asset`, { variant: "error" });
          }
        } else {
          setDataLoadError(err.message);
          setAsset(undefined);
          enqueueSnackbar(`error fetching asset`, { variant: "error" });
        }
        return;
      }
    })();
  }, [token, isMounted]);

  // Get the listing
  useEffect(() => {
    (async () => {
      if (token.isUndefined() || !isMounted()) {
        return;
      }
      // Need to add smart instrument listing check
      try {
        const fetchedListing = (
          await ListingRepository.RetrieveListing({
            context: authContext,
            identifier: TokenIdentifier(token, "token"),
          })
        ).listing;
        setListing(fetchedListing);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        setDataLoadError(err.message);
        setListing(undefined);
      }
    })();
  }, [token, isMounted]);

  return (
    <MarketingInfoContext.Provider
      value={{
        apiCallInProgress: false,
        initialised: false,
        initialisationError: dataLoadError,
        clearInitialisationError: () => setDataLoadError(null),
        assetInformation: asset,
        listingInformation: listing,
      }}
    >
      {children}
    </MarketingInfoContext.Provider>
  );
};
