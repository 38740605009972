import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import { Login } from "james/security/claims/Login";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useFirebaseContext } from "context/Firebase";
import { useSnackbar } from "notistack";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import { Authenticator } from "james/security/authentication";
import { useErrorContext } from "context/Error";
import duration from "dayjs/plugin/duration";

const PREFIX = "LoginClaimsChecker";

const classes = {
  dialogTitleTypography: `${PREFIX}-dialogTitleTypography`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleTypography}`]: {
    padding: theme.spacing(1, 0),
  },
}));

const sessionTimeOutWarningWindowMS = 2 * 60 * 1000;

export const LoginClaimsChecker: React.FC = () => {
  dayjs.extend(duration);
  const { firebaseAuthenticated, firebaseContextUpdating } =
    useFirebaseContext();
  const {
    userAuthenticated,
    logout,
    loginClaimsUpdate,
    loginClaims,
    authContext,
    applicationContextUpdating,
  } = useApplicationContext();

  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();

  const sessionTimeOutWarningTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const sessionLogOutTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [showSessionTimeoutWarningDialog, setShowSessionTimeoutWarningDialog] =
    useState(false);
  const timeToTimeoutUpdateRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [timeToTimeout, setTimeToTimeout] = useState("00:00");
  useEffect(() => {
    // do nothing while updating
    if (applicationContextUpdating || firebaseContextUpdating) {
      return;
    }

    if (
      // if claims are blank
      isEqual(loginClaims, new Login()) || // OR
      // user is not logged in
      !userAuthenticated || // OR
      // firebase context not logged in
      !firebaseAuthenticated
    ) {
      // then ensure callbacks are cleared
      clearTimeout(sessionTimeOutWarningTimeoutRef.current);
      clearTimeout(sessionLogOutTimeoutRef.current);
      clearInterval(timeToTimeoutUpdateRef.current);

      // reset time to timeout
      setTimeToTimeout("00:00");

      return;
    }
    // otherwise set timeouts

    // parse expiration time
    const loginClaimsExpirationTime = dayjs.unix(loginClaims.expirationTime);
    console.debug(
      "session expires at:\n",
      loginClaimsExpirationTime.format("h:mm:ss a"),
    );

    // get the number of milliseconds until claims expiry
    const msUntilClaimsExpiry = loginClaimsExpirationTime.diff(
      dayjs(),
      "millisecond",
    );
    if (msUntilClaimsExpiry < sessionTimeOutWarningWindowMS) {
      console.error(
        `claims valid for less than expiry warning window: ${sessionTimeOutWarningWindowMS}`,
      );
      logout().finally();
      return;
    }

    // set interval to check for expiry every 5 seconds to trigger session timeout dialog popup
    clearTimeout(sessionTimeOutWarningTimeoutRef.current);
    sessionTimeOutWarningTimeoutRef.current = setInterval(() => {
      const _msUntilClaimsExpiry = loginClaimsExpirationTime.diff(
        dayjs(),
        "millisecond",
      );
      if (_msUntilClaimsExpiry > sessionTimeOutWarningWindowMS) {
        return;
      }
      clearInterval(sessionTimeOutWarningTimeoutRef.current);
      if (isMounted()) {
        // set interval to update timeout counter
        clearInterval(timeToTimeoutUpdateRef.current);
        timeToTimeoutUpdateRef.current = setInterval(() => {
          const durationToTimeout = dayjs.duration(
            loginClaimsExpirationTime.diff(dayjs()),
          );
          const minutes = durationToTimeout.get("minutes");
          const seconds = durationToTimeout.get("seconds");
          if (isMounted()) {
            setTimeToTimeout(
              `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
            );
          }
        }, 1000);

        // open warning dialog
        setTimeToTimeout("02:00");
        setShowSessionTimeoutWarningDialog(true);
      }
    }, 5000);

    // set interval to check for expiry every 5 seconds to trigger auto log out
    clearTimeout(sessionLogOutTimeoutRef.current);
    sessionLogOutTimeoutRef.current = setInterval(() => {
      const _msUntilClaimsExpiry = loginClaimsExpirationTime.diff(
        dayjs(),
        "millisecond",
      );
      if (_msUntilClaimsExpiry > 1000) {
        return;
      }
      // ensure callbacks are cleared
      clearTimeout(sessionTimeOutWarningTimeoutRef.current);
      clearTimeout(sessionLogOutTimeoutRef.current);
      clearInterval(timeToTimeoutUpdateRef.current);

      // log out of app
      logout().finally();

      // close dialog
      setShowSessionTimeoutWarningDialog(false);

      // notify user
      enqueueSnackbar("Your Session has expired, You have been signed out!", {
        // id: "sessionExpired-snackbar", // FIXME: put back ID
        variant: "info",
        persist: true,
      });
    }, 5000);

    return () => {
      // clean up timeout refs on unmount
      clearTimeout(sessionTimeOutWarningTimeoutRef.current);
      clearTimeout(sessionLogOutTimeoutRef.current);
      clearInterval(timeToTimeoutUpdateRef.current);
    };
  }, [
    applicationContextUpdating,
    loginClaims,
    userAuthenticated,
    logout,
    firebaseAuthenticated,
    firebaseContextUpdating,
    enqueueSnackbar,
    isMounted,
  ]);

  const [refreshingLogin, setRefreshingLogin] = useState(false);
  const handleRefreshLogin = useCallback(async () => {
    if (isMounted()) {
      setRefreshingLogin(true);
    }
    try {
      // refresh login
      const refreshLoginResponse = await Authenticator.RefreshLogin({
        context: authContext,
      });

      await loginClaimsUpdate(refreshLoginResponse.loginClaims);

      if (isMounted()) {
        // close warning dialog
        setShowSessionTimeoutWarningDialog(false);

        // reset time to timeout
        setTimeToTimeout("00:00");

        enqueueSnackbar("Session Refreshed", { variant: "success" });
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error refreshing session: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Refreshing Session: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    if (isMounted()) {
      setRefreshingLogin(false);
    }
  }, [authContext, enqueueSnackbar, isMounted]);

  return (
    <StyledDialog open={showSessionTimeoutWarningDialog} maxWidth="lg">
      <DialogTitle
        children={
          <Typography
            className={classes.dialogTitleTypography}
            variant="h5"
            children={`Session Time Out in 00:${timeToTimeout}`}
          />
        }
      />
      <DialogContent>
        <Typography>
          Your session is about to expire. If you want to continue please click
          refresh.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          children="logout"
          disabled={refreshingLogin}
          onClick={() => {
            // ensure callbacks are cleared
            clearTimeout(sessionTimeOutWarningTimeoutRef.current);
            clearTimeout(sessionLogOutTimeoutRef.current);
            clearInterval(timeToTimeoutUpdateRef.current);
            // log out of app
            logout().finally();
          }}
        />
        <Button
          variant="contained"
          color="primary"
          children="refresh"
          disabled={refreshingLogin}
          onClick={handleRefreshLogin}
        />
      </DialogActions>
    </StyledDialog>
  );
};
