import React, { ReactNode, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateField, TextField, TextNumField } from "components/FormFields";
import {
  AddCircleOutlineOutlined as AddIcon,
  Block as NotAllowedIcon,
  CancelOutlined as RemoveIcon,
  Close as CloseIcon,
  DeleteOutlineOutlined as DeleteIcon,
  Done as DoneIcon,
  EditOutlined as EditIcon,
  ExpandMore as ExpandMoreIcon,
  GetAppOutlined as DownloadIcon,
  Info as InfoIcon,
  PublishOutlined as UploadIcon,
} from "@mui/icons-material";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import cx from "classnames";
import dayjs from "dayjs";
import { Group, GroupRepository } from "james/group";
import { AssetClassDEPRECATED } from "james/financial/AssetClassDEPRECATED";
import {
  AllInvestorProfiles,
  InvestorProfile,
} from "james/financial/InvestorProfile";
import {
  AllInstrumentRiskProfiles,
  InstrumentRiskProfile,
} from "james/financial/InstrumentRiskProfile";
import { countries } from "james/country";
import { CountryOption } from "james/country/countries";
import { CountryAllocation } from "james/financial/CountryAllocation";
import { Holding } from "james/financial/Holding";
import { SectorAllocation } from "james/financial/SectorAllocation";
import {
  AllFinancialSectors,
  DigitalETF,
  DigitalETFAction,
  DigitalETFState,
  ErrDigitalETFNameAlreadyInUseErrorCode,
  ErrDraftDigitalETFNameAlreadyInUseErrorCode,
  FinancialCurrencyCollection,
  FinancialInstrumentCollection,
  getPotentialNextDigitalETFActions,
} from "james/financial";
import { DigitalETFCreator } from "james/financial/DigitalETFCreator";
import { DigitalETFUpdater } from "james/financial/DigitalETFUpdater";
import { DigitalETFStateChanger } from "james/financial/DigitalETFStateChanger";
import { InstrumentDocumentController } from "james/financial/InstrumentDocumentController";
import { useSnackbar } from "notistack";
import isEqual from "lodash/isEqual";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Model as StellarAccountStubViewModel,
  Reader as StellarAccountStubViewReader,
} from "james/views/stellarAccountStubView";
import {
  LedgerAccountCategory,
  Mint,
  MintRepository,
  Token,
} from "james/ledger";
import { TextExactCriterion, TextListCriterion } from "james/search/criterion";
import { IDIdentifier } from "james/search/identifier/ID";
import { useDropzone } from "react-dropzone";
import { Document } from "james/document";
import { doUpload } from "utilities/network/upload";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";
import { formatTextNum } from "utilities/number";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { ClientKYCStatus, Client } from "james/client";
import { MintAssetDialog } from "components/Dialogs/Minting";
import { LedgerAssetReadyToList, ListingInspector } from "james/market";
import { Query } from "james/search/query";
import {
  FinancialCurrencyStablecoinViewModel,
  FinancialCurrencyStablecoinViewReader,
} from "james/views/financialCurrencyStablecoinView";
import { Currency } from "james/financial/Currency";
import { UnderlyingCurrencyCategory } from "james/views/financialCurrencyStablecoinView/Model";
import { download } from "utilities/network/download";
import { ViewEditInstrumentAnnualPerformanceLog } from "./ViewEditInstrumentAnnualPerformanceLog";
import {
  IssueWarningDialog,
  IssueWarningDialogOptions,
} from "./IssueWarningDialog";
import {
  PendingDocumentUpload,
  StepValidationResult,
  TouchedFields,
  WarningDialogOptions,
} from "./common";
import { TokenClass } from "./NewInstrumentDialog";
import { SectorAllocationPieChart } from "./SectorAllocationPieChart";
import { CountryAllocationPieChart } from "./CountryAllocationPieChart";
import { HoldingsPieChart } from "./HoldingsPieChart";
import { InstrumentStateChip, PlacementStateWordsChip } from "./Chips";
import {
  validateDigitalETFStep1,
  validateDigitalETFStep2,
  validateDigitalETFSupportingDocuments,
} from "./digitalETFValidations";
import { InstrumentsViewPaths } from "./Instruments";
import { dateIsValid } from "utilities/date/dateIsValid";
import { useErrorContext } from "context/Error";
import BigNumber from "bignumber.js";
import { useApplicationContext } from "context/Application/Application";

const PREFIX = "DigitalETFDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  heading: `${PREFIX}-heading`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  dialogContent: `${PREFIX}-dialogContent`,
  rootLeftColumn: `${PREFIX}-rootLeftColumn`,
  rootRightColumn: `${PREFIX}-rootRightColumn`,
  successText: `${PREFIX}-successText`,
  errorText: `${PREFIX}-errorText`,
  searchIcon: `${PREFIX}-searchIcon`,
  paddingBottom: `${PREFIX}-paddingBottom`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  killGridPadding: `${PREFIX}-killGridPadding`,
  tokenClassSelectionLayout: `${PREFIX}-tokenClassSelectionLayout`,
  docsMediaMsgLayout: `${PREFIX}-docsMediaMsgLayout`,
  docsMediaInfoIcon: `${PREFIX}-docsMediaInfoIcon`,
  docsMediaInfoMsg: `${PREFIX}-docsMediaInfoMsg`,
  dropZone: `${PREFIX}-dropZone`,
  dropZoneAccept: `${PREFIX}-dropZoneAccept`,
  dropZoneReject: `${PREFIX}-dropZoneReject`,
  dropZoneGuidanceText: `${PREFIX}-dropZoneGuidanceText`,
  docsMediaColContent: `${PREFIX}-docsMediaColContent`,
  docsMediaColContentWarningMsg: `${PREFIX}-docsMediaColContentWarningMsg`,
  docsMediaLineItemLayout: `${PREFIX}-docsMediaLineItemLayout`,
  docsMediaLineItemMarginTop: `${PREFIX}-docsMediaLineItemMarginTop`,
  docsMediaLineItemFileName: `${PREFIX}-docsMediaLineItemFileName`,
  docsMediaLineItemFileNameWithDL: `${PREFIX}-docsMediaLineItemFileNameWithDL`,
  docsMediaDownloadIconButton: `${PREFIX}-docsMediaDownloadIconButton`,
  stepsCommonAccordionSummaryRootOverride: `${PREFIX}-stepsCommonAccordionSummaryRootOverride`,
  stepsCommonAccordionSummaryContentLayout: `${PREFIX}-stepsCommonAccordionSummaryContentLayout`,
  stepsCommonAccordionDetailsLayout: `${PREFIX}-stepsCommonAccordionDetailsLayout`,
  stepsCommonGuidanceText: `${PREFIX}-stepsCommonGuidanceText`,
  step1InstrumentDetailsFieldsSection: `${PREFIX}-step1InstrumentDetailsFieldsSection`,
  instrumentDetailsDigitalInstrumentFieldsRiskProfileLayout: `${PREFIX}-instrumentDetailsDigitalInstrumentFieldsRiskProfileLayout`,
  instrumentDetailsMaxUnitsInIssueLayout: `${PREFIX}-instrumentDetailsMaxUnitsInIssueLayout`,
  instrumentDetailsMaxUnitsInIssueInfoIcon: `${PREFIX}-instrumentDetailsMaxUnitsInIssueInfoIcon`,
  generalDescriptionFieldLayout: `${PREFIX}-generalDescriptionFieldLayout`,
  generalDescriptionFieldTextAreaViewMode: `${PREFIX}-generalDescriptionFieldTextAreaViewMode`,
  generalDescriptionFieldTextArea: `${PREFIX}-generalDescriptionFieldTextArea`,
  holdingsAndAllocationSumLine: `${PREFIX}-holdingsAndAllocationSumLine`,
  holdingsAndAllocationsViewModeLayout: `${PREFIX}-holdingsAndAllocationsViewModeLayout`,
  holdingsAndAllocationsViewModeSectionLayout: `${PREFIX}-holdingsAndAllocationsViewModeSectionLayout`,
  holdingsAndAllocationsViewModeList: `${PREFIX}-holdingsAndAllocationsViewModeList`,
  holdingsAndAllocationsViewModeLineItem: `${PREFIX}-holdingsAndAllocationsViewModeLineItem`,
  holdingsAndAllocationsLayout: `${PREFIX}-holdingsAndAllocationsLayout`,
  holdingsAndAllocationsSpacer: `${PREFIX}-holdingsAndAllocationsSpacer`,
  holdingsAndAllocationsControlLineItem: `${PREFIX}-holdingsAndAllocationsControlLineItem`,
  holdingsAndAllocationsLineItem: `${PREFIX}-holdingsAndAllocationsLineItem`,
  holdingsAnAllocationsLineItemHelperText: `${PREFIX}-holdingsAnAllocationsLineItemHelperText`,
  holdingsAndAllocationsNameField: `${PREFIX}-holdingsAndAllocationsNameField`,
  holdingsAndAllocationsPercentField: `${PREFIX}-holdingsAndAllocationsPercentField`,
  holdingsAndAllocationsList: `${PREFIX}-holdingsAndAllocationsList`,
  instrumentAnnualPerformanceLogHelperText: `${PREFIX}-instrumentAnnualPerformanceLogHelperText`,
  infoIcon: `${PREFIX}-infoIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }) => ({
  //
  // Dialog Title
  //
  [`& .${classes.dialogTitle}`]: {
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
  },

  [`& .${classes.heading}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 40,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  //
  // Dialog Content
  //
  [`& .${classes.dialogContent}`]: {
    padding: 0,
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    height: "calc(100vh - 57px)",
    overflowY: "auto",
  },

  [`& .${classes.rootLeftColumn}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    borderRight: `solid 1px ${theme.palette.divider}`,
  },

  [`& .${classes.rootRightColumn}`]: {
    padding: theme.spacing(5, 3.5, 2, 3.5),
  },

  //
  // text
  //
  [`& .${classes.successText}`]: { color: theme.palette.success.light },

  [`& .${classes.errorText}`]: { color: theme.palette.error.main },

  //
  // other
  //
  [`& .${classes.searchIcon}`]: {
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.paddingBottom}`]: {
    paddingBottom: theme.spacing(10),
  },

  [`& .${classes.fieldWidth}`]: {
    width: 250,
  },

  [`& .${classes.killGridPadding}`]: {},

  //
  // token class selection
  //
  [`& .${classes.tokenClassSelectionLayout}`]: {
    padding: theme.spacing(3, 2, 3, 3),
  },

  //
  // docs media
  //
  [`& .${classes.docsMediaMsgLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(1),
    justifyItems: "center",
  },

  [`& .${classes.docsMediaInfoIcon}`]: {
    fontSize: 60,
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.docsMediaInfoMsg}`]: {
    maxWidth: 300,
    textAlign: "center",
  },

  [`& .${classes.dropZone}`]: {
    padding: theme.spacing(2, 0, 1, 0),
    width: "calc(0.3vw - 30)",
    height: 143,
    border: `1px dashed ${theme.palette.text.primary}`,
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    display: "grid",
    gridTemplateColumns: "1fr",
    alignItems: "center",
    justifyItems: "center",
    gridRowGap: theme.spacing(1),
  },

  [`& .${classes.dropZoneAccept}`]: {
    border: `1px dashed ${theme.palette.success.main}`,
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },

  [`& .${classes.dropZoneReject}`]: {
    border: `1px dashed ${theme.palette.error.main}`,
    backgroundColor: alpha(theme.palette.error.main, 0.2),
  },

  [`& .${classes.dropZoneGuidanceText}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaColContent}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(2),
  },

  [`& .${classes.docsMediaColContentWarningMsg}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.docsMediaLineItemLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.docsMediaLineItemMarginTop}`]: {
    marginTop: theme.spacing(1.5),
  },

  [`& .${classes.docsMediaLineItemFileName}`]: {
    gridColumn: "1/3",
  },

  [`& .${classes.docsMediaLineItemFileNameWithDL}`]: {
    gridColumn: "1/3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.docsMediaDownloadIconButton}`]: {
    marginLeft: theme.spacing(1),
  },

  // -------------------------------------------------
  // Steps Common
  // -------------------------------------------------
  [`& .${classes.stepsCommonAccordionSummaryRootOverride}`]: {
    flexDirection: "row-reverse",
    paddingLeft: theme.spacing(3),
    backgroundColor: alpha(theme.palette.background.default, 0.6),
  },

  [`& .${classes.stepsCommonAccordionSummaryContentLayout}`]: {
    width: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    display: "grid",
    gridTemplateColumns: "200px auto 1fr auto",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.stepsCommonAccordionDetailsLayout}`]: {
    display: "grid",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(3),
  },

  [`& .${classes.stepsCommonGuidanceText}`]: {
    maxWidth: 740,
  },

  // -------------------------------------------------
  // Step 1
  // -------------------------------------------------
  [`& .${classes.step1InstrumentDetailsFieldsSection}`]: {
    display: "grid",
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 250px)",
  },

  [`& .${classes.instrumentDetailsDigitalInstrumentFieldsRiskProfileLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridRowGap: theme.spacing(2),
    gridColumnGap: theme.spacing(5),
  },

  [`& .${classes.instrumentDetailsMaxUnitsInIssueLayout}`]: {
    display: "grid",
    gridTemplateColumns: "160px 1fr",
    alignItems: "center",
  },

  [`& .${classes.instrumentDetailsMaxUnitsInIssueInfoIcon}`]: {
    marginLeft: -45,
    zIndex: 1,
  },

  // -------------------------------------------------
  // Step 2
  // -------------------------------------------------
  [`& .${classes.generalDescriptionFieldLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridRowGap: theme.spacing(0.5),
  },

  [`& .${classes.generalDescriptionFieldTextAreaViewMode}`]: {
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    maxHeight: 131,
    overflowY: "auto",
  },

  [`& .${classes.generalDescriptionFieldTextArea}`]: {
    color: theme.palette.text.tertiary,
    padding: theme.spacing(0.5, 1),
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: "calc(100vw/3.5)",
    maxWidth: "calc(100vw/3.5)",
    borderRadius: 4,
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    resize: "none",
  },

  //
  // holdings and allocations
  //
  [`& .${classes.holdingsAndAllocationSumLine}`]: {
    paddingTop: theme.spacing(1),
    marginLeft: -6,
  },

  [`& .${classes.holdingsAndAllocationsViewModeLayout}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  [`& .${classes.holdingsAndAllocationsViewModeSectionLayout}`]: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto 1fr",
    gridRowGap: theme.spacing(2),
    justifyItems: "center",
  },

  [`& .${classes.holdingsAndAllocationsViewModeList}`]: {
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.holdingsAndAllocationsViewModeLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.holdingsAndAllocationsLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto 1fr auto",
  },

  [`& .${classes.holdingsAndAllocationsSpacer}`]: {
    width: theme.spacing(1),
  },

  [`& .${classes.holdingsAndAllocationsControlLineItem}`]: {
    marginRight: theme.spacing(1.5),
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    justifyItems: "start",
  },

  [`& .${classes.holdingsAndAllocationsLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: theme.spacing(1),
    alignItems: "center",
    justifyItems: "start",
  },

  [`& .${classes.holdingsAnAllocationsLineItemHelperText}`]: {
    gridColumn: "1/4",
    paddingLeft: theme.spacing(0.8),
  },

  [`& .${classes.holdingsAndAllocationsNameField}`]: {
    width: 170,
  },

  [`& .${classes.holdingsAndAllocationsPercentField}`]: {
    width: 65,
  },

  [`& .${classes.holdingsAndAllocationsList}`]: {
    maxHeight: 180,
    overflowY: "auto",
    overflowX: "hidden",
  },

  //
  // Performance Log
  //
  [`& .${classes.instrumentAnnualPerformanceLogHelperText}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    columnGap: theme.spacing(1),
    margin: theme.spacing(-1, 0, 1, 0),
  },

  //
  // other
  //
  [`& .${classes.infoIcon}`]: {
    marginRight: -10,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

const allowedDocumentTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
];

export function DigitalETFDialog() {
  // Hook Invocations
  const {
    authContext,
    viewConfiguration,
    myClientKYCStatus,
    myClient,
    myClientRetrievalErr,
  } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Component State
  const [apiLoading, setAPILoading] = useState(false);
  const [digitalETF, setDigitalETF] = useState(new DigitalETF());
  const [mint, setMint] = useState<Mint | undefined>(undefined);
  const [copyDigitalETF, setCopyOfDigitalETF] = useState(new DigitalETF());
  const [potentialGroupOwners, setPotentialGroupOwners] = useState<Group[]>([]);
  const [
    potentialValuationCurrencyStablecoins,
    setPotentialValuationCurrencyStablecoins,
  ] = useState<FinancialCurrencyStablecoinViewModel[]>([]);
  const [
    valuationStablecoinUnderlyingCurrencies,
    setValuationStablecoinUnderlyingCurrencies,
  ] = useState<Currency[]>([]);
  const [accordionSectionsOpen, setAccordionSectionsOpen] = useState<{
    [key: string]: boolean;
  }>({
    digitalInstrument: true,
    exposure: false,
  });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [pendingCountryAllocation, setPendingCountryAllocation] =
    useState<CountryAllocation>(new CountryAllocation());
  const [pendingHolding, setPendingHolding] = useState<Holding>(new Holding());
  const [pendingSectorAllocation, setPendingSectorAllocation] =
    useState<SectorAllocation>(new SectorAllocation());
  const [step1ValidationResult, setStep1ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [step2ValidationResult, setStep2ValidationResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [supportingDocsStepValidationResult, setSupportingDocsStepResult] =
    useState<StepValidationResult>({
      stepComplete: false,
      fieldValidations: {},
    });
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const [issueWarningDialogOptions, setIssueWarningDialogOptions] =
    useState<IssueWarningDialogOptions | null>(null);
  const [issuanceAccountStubViewModel, setIssuanceAccountStubViewModel] =
    useState(new StellarAccountStubViewModel());
  const [viewModeOn, setViewModeOn] = useState(true);
  const [pendingDocumentUploads, setPendingDocumentUploads] = useState<
    PendingDocumentUpload[]
  >([]);
  const [showMintAssetDialog, setShowMintAssetDialog] = useState(false);
  const instrumentIsPlaced = useRef(false);

  // Computed State
  const unsavedChangesExist = !isEqual(digitalETF, copyDigitalETF);
  const allStepsComplete =
    step1ValidationResult.stepComplete &&
    step2ValidationResult.stepComplete &&
    supportingDocsStepValidationResult.stepComplete;
  const instrumentsViewConfig = viewConfiguration.Instruments
    ? viewConfiguration.Instruments
    : {};
  const marketAssetViewConfig = instrumentsViewConfig.MarketAssetActions
    ? instrumentsViewConfig.MarketAssetActions
    : {};
  const digitalETFActionsViewConfig = instrumentsViewConfig.DigitalETFActions
    ? instrumentsViewConfig.DigitalETFActions
    : {};
  const potentialNextActionsForDigitalETF =
    digitalETF.state === ""
      ? []
      : getPotentialNextDigitalETFActions(digitalETF.state);
  const creatingNewDigitalETF = digitalETF.id === "";
  const editIsANextAction = [
    // if some of these update actions are included in the
    // next potential actions then 'edit' is a next action
    DigitalETFAction.DraftUpdate,
    DigitalETFAction.IncreaseMaximumUnits,
    DigitalETFAction.DecreaseMaximumUnits,
    DigitalETFAction.ChangeMaturityDate,
    DigitalETFAction.ChangeHoldings,
    DigitalETFAction.ChangeSectorAllocations,
    DigitalETFAction.ChangeCountryAllocations,
    DigitalETFAction.ChangeSupportingDocuments,
  ].some((a) => potentialNextActionsForDigitalETF.includes(a));
  const kycStatusVerified =
    myClientKYCStatus === ClientKYCStatus.VerifiedStatus;

  // Initial Component Load
  useEffect(() => {
    (async () => {
      // start loading
      setAPILoading(true);

      if (myClientRetrievalErr && !myClient) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        return;
      }

      if (!myClient) {
        navigate(InstrumentsViewPaths.Table);
        return;
      }

      // prepare the in dialog DigitalETF
      let digitalETFInDialog = new DigitalETF();
      digitalETFInDialog.state = DigitalETFState.Draft;

      // prepare a variable to hold the in dialog Mint
      let mintInDialog: Mint | undefined;

      // determine if an existing instrument is being viewed/manipulated
      // or if this is a new Digital ETF
      const digitalETFIDFromURL = searchParams.get("id");
      let newDigitalETF: boolean;
      if (digitalETFIDFromURL) {
        // id from url set :- viewing/editing existing

        if (searchParams.get("edit") && !digitalETFActionsViewConfig.Update) {
          // if edit mode is selected and user does not have update permission
          // then return to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newDigitalETF = false;

        if (digitalETFIDFromURL === digitalETF.id) {
          // stop reload at this point if the DigitalETF in state matches the url
          setAPILoading(false);
          return;
        }

        // retrieve the DigitalETF
        try {
          const retrievedInstrument = (
            await FinancialInstrumentCollection.RetrieveInstrument({
              context: authContext,
              identifier: IDIdentifier(digitalETFIDFromURL),
            })
          ).instrument;
          if (retrievedInstrument instanceof DigitalETF) {
            digitalETFInDialog = retrievedInstrument;
          } else {
            console.error("unexpected instrument type");
            navigate(InstrumentsViewPaths.Table);
            return;
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `unable to retrieve instrument: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Instrument: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          navigate(InstrumentsViewPaths.Table);
          return;
        }
      } else {
        // id from url not set :- creating new

        // default asset class to Fund
        digitalETFInDialog.assetClass = AssetClassDEPRECATED.Fund;

        if (!digitalETFActionsViewConfig.Create) {
          // if user does not have permission to create then go back to table
          navigate(InstrumentsViewPaths.Table);
          return;
        }

        newDigitalETF = true;
      }
      // If execution reaches here either both DigitalETF has been retrieved
      // or is set as a non-created draft.

      // load associated required data
      await Promise.all([
        // if digital etf can be minted then retrieve the mint
        (async () => {
          try {
            const mintRecords = (
              await MintRepository.SearchMint({
                context: authContext,
                criteria: {
                  "token.code": TextExactCriterion(
                    digitalETFInDialog.token.code,
                  ),
                  "token.issuer": TextExactCriterion(
                    digitalETFInDialog.token.issuer,
                  ),
                  "token.network": TextExactCriterion(
                    digitalETFInDialog.token.network,
                  ),
                },
                query: new Query({
                  limit: 1,
                  offset: 0,
                  sorting: [],
                }),
              })
            ).records;
            if (mintRecords.length === 1) {
              mintInDialog = mintRecords[0];
            }
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving mint: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),

        // determine if instrument is already listed
        (async () => {
          if (!LedgerAssetReadyToList(digitalETFInDialog)) {
            return;
          }
          try {
            instrumentIsPlaced.current = (
              await ListingInspector.DoesListingForTokenExist({
                context: authContext,
                token: digitalETFInDialog.token,
              })
            ).exists;
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error determining if instrument is ready for listing: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),

        // fetch potential owner groups
        (async () => {
          if (digitalETFActionsViewConfig.Create) {
            // if the user has permission to create a DigitalETF then
            // get all groups in which the user has permission to CreateNewInstrument.
            try {
              const updatedPotentialGroupOwners = (
                await GroupRepository.SearchGroups({
                  context: authContext,
                  criteria: (
                    await Determiner.DetermineScopeCriteriaByRoles({
                      context: authContext,
                      service: new Permission({
                        serviceName: "CreateNewDigitalETF",
                        serviceProvider: DigitalETFCreator.serviceProvider,
                        description: "-",
                      }),
                      criteria: {},
                      scopeFields: [ScopeFields.IDField],
                      buildScopeTree: false,
                    })
                  ).criteria,
                })
              ).records;
              setPotentialGroupOwners(updatedPotentialGroupOwners);
              if (updatedPotentialGroupOwners.length && newDigitalETF) {
                digitalETFInDialog.ownerID = updatedPotentialGroupOwners[0].id;
              }
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `unable to search for potential group owners: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          } else if (digitalETFInDialog.id) {
            // Otherwise, if the digital ETF already exists, then just get the owner group
            try {
              const updatedPotentialGroupOwners = (
                await GroupRepository.SearchGroups({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(digitalETFInDialog.ownerID),
                  },
                })
              ).records;
              setPotentialGroupOwners(updatedPotentialGroupOwners);
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `unable to search for potential group owners: ${
                  err.message ? err.message : err.toString()
                }`,
              );
            }
          }
        })(),

        // set issuance account
        (async () => {
          try {
            const potentialIssuanceAccounts = (
              await StellarAccountStubViewReader.Read({
                context: authContext,
                criteria: {
                  ownerID: TextExactCriterion(new Client(myClient).ownerID),
                  category: TextExactCriterion(LedgerAccountCategory.Issuance),
                },
              })
            ).models;

            if (potentialIssuanceAccounts.length > 1) {
              console.error("retrieved more than 1 issuance account");
              enqueueSnackbar("Retrieved More Than 1 Issuance Account", {
                variant: "error",
              });
              setIssuanceAccountStubViewModel(
                new StellarAccountStubViewModel(),
              );
            } else if (potentialIssuanceAccounts.length === 0) {
              console.error("issuance account not found");
              enqueueSnackbar("Issuance Account Not Found", {
                variant: "error",
              });
              setIssuanceAccountStubViewModel(
                new StellarAccountStubViewModel(),
              );
            } else {
              setIssuanceAccountStubViewModel(potentialIssuanceAccounts[0]);
            }
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving issuance account: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Retrieving Issuance Account: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
          }
        })(),

        // get all currency stablecoins in which new instrument can be valued
        (async () => {
          try {
            // retrieve all potential valuation currency stablecoins
            const updatedPotentialValuationStablecoins = (
              await FinancialCurrencyStablecoinViewReader.Read({
                context: authContext,
                criteria: {
                  underlyingCurrencyCategory: TextExactCriterion(
                    UnderlyingCurrencyCategory.Fiat,
                  ),
                },
              })
            ).models;

            // retrieve each associated currency
            const updatedValuationStablecoinUnderlyingCurrencies = (
              await FinancialCurrencyCollection.SearchCurrency({
                context: authContext,
                criteria: {
                  id: TextListCriterion(
                    updatedPotentialValuationStablecoins.map(
                      (sc) => sc.currencyID,
                    ),
                  ),
                },
              })
            ).records;

            // if at least 1 stablecoin is retrieved
            // AND a new digitalETF is being created
            if (updatedPotentialValuationStablecoins.length && newDigitalETF) {
              // then set the starting valuation token
              digitalETFInDialog.valuationToken = new Token(
                updatedPotentialValuationStablecoins[0].token,
              );

              // find resultant starting currency
              const currency =
                updatedValuationStablecoinUnderlyingCurrencies.find(
                  (c: Currency) =>
                    updatedPotentialValuationStablecoins[0].currencyID ===
                    c.currencyID(),
                );
              if (!currency) {
                console.error("could not find associated currency");
                return;
              }

              // use resultant starting currency to set initial issue and maturity date
              digitalETFInDialog.issueDate = dayjs().startOf("day").format();
              digitalETFInDialog.maturityDate = currency.firstCutOffAfter(
                dayjs(digitalETFInDialog.issueDate).add(1, "day").format(),
              );
            }

            setPotentialValuationCurrencyStablecoins(
              updatedPotentialValuationStablecoins,
            );
            setValuationStablecoinUnderlyingCurrencies(
              updatedValuationStablecoinUnderlyingCurrencies,
            );
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `unable to search for potential valuation stablecoin: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
        })(),
      ]);

      setStep1ValidationResult(
        validateDigitalETFStep1(digitalETFInDialog, mintInDialog, {}, false),
      );
      setStep2ValidationResult(
        validateDigitalETFStep2(digitalETFInDialog, {}, false),
      );
      setSupportingDocsStepResult(
        validateDigitalETFSupportingDocuments(digitalETFInDialog, {}, false),
      );

      setDigitalETF(new DigitalETF(digitalETFInDialog));
      setCopyOfDigitalETF(new DigitalETF(digitalETFInDialog));
      setMint(mintInDialog);

      // set view mode as requested
      setViewModeOn(!(newDigitalETF || searchParams.get("edit")));

      setAPILoading(false);
    })();
  }, [
    myClient,
    digitalETFActionsViewConfig.Create,
    digitalETFActionsViewConfig.Update,
    enqueueSnackbar,
    history,
    digitalETF.id,
    authContext,
  ]);

  type DigitalETFField = keyof DigitalETF;
  type DigitalETFValue<T extends DigitalETFField> = DigitalETF[T];

  //  In Dialog Entity Update
  const handleChangeDigitalETFInDialog =
    (field: string, fieldsAffected?: string[]) =>
    <T extends DigitalETFField>(newValue: DigitalETFValue<T>) =>
      setDigitalETF((prevDigitalETF: DigitalETF) => {
        // prepare updated instrument
        const updatedDigitalETF = new DigitalETF({
          ...prevDigitalETF,
          [field]: newValue,
        } as DigitalETF);

        if (
          // if valuation token is set
          !updatedDigitalETF.valuationToken.isUndefined() && // AND
          // the field being updated is one of the following
          ["issueDate", "maturityDate", "valuationToken"].includes(field) && // AND
          // the set issue and maturity date fields are valid
          dateIsValid(updatedDigitalETF.issueDate) &&
          dateIsValid(updatedDigitalETF.maturityDate)
        ) {
          // find selected valuation stablecoin
          const valuationCurrencyStablecoin =
            potentialValuationCurrencyStablecoins.find((c) =>
              c.token.isEqualTo(updatedDigitalETF.valuationToken),
            );
          if (!valuationCurrencyStablecoin) {
            console.error(
              "could not find valuation currency stablecoin among those available",
            );
            return prevDigitalETF;
          }

          // find currency by which the stablecoin is backed
          const backingCurrency = valuationStablecoinUnderlyingCurrencies.find(
            (c) => valuationCurrencyStablecoin.currencyID === c.currencyID(),
          );
          if (!backingCurrency) {
            console.error("could not find backing currency");
            return prevDigitalETF;
          }

          // update dates accordingly
          switch (field) {
            case "issueDate":
              updatedDigitalETF.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedDigitalETF.issueDate).add(1, "minute").format(),
                );
              break;

            case "maturityDate":
              updatedDigitalETF.maturityDate = backingCurrency.firstCutOffAfter(
                dayjs(updatedDigitalETF.maturityDate).startOf("day").format(),
              );
              break;

            case "valuationToken":
              updatedDigitalETF.issueDate =
                backingCurrency.firstStartOfDayBefore(
                  dayjs(updatedDigitalETF.issueDate).add(1, "minute").format(),
                );
              updatedDigitalETF.maturityDate = backingCurrency.firstCutOffAfter(
                dayjs(updatedDigitalETF.maturityDate).startOf("day").format(),
              );
              break;
          }
        }

        if (
          // if any of the following fields
          ["annualPerformanceLog", "issueDate", "maturityDate"].includes(
            field,
          ) && // AND
          // the set issue and maturity date fields are valid
          dateIsValid(updatedDigitalETF.issueDate) &&
          dateIsValid(updatedDigitalETF.maturityDate)
        ) {
          // then process annual performance log

          // get now
          const now = dayjs();

          // remove any years in the future
          updatedDigitalETF.annualPerformanceLog =
            updatedDigitalETF.annualPerformanceLog.filter(
              // by keeping only those entries with year <= this year
              (pl) => pl.year <= now.year(),
            );

          // remove any years prior to issue date
          updatedDigitalETF.annualPerformanceLog =
            updatedDigitalETF.annualPerformanceLog.filter(
              // by keep only those entries with year >= year in which instrument is issued
              (pl) => pl.year >= dayjs(updatedDigitalETF.issueDate).year(),
            );

          // of the entries which remain
          for (const i in updatedDigitalETF.annualPerformanceLog) {
            if (
              Object.prototype.hasOwnProperty.call(
                updatedDigitalETF.annualPerformanceLog,
                i,
              )
            ) {
              for (const month in updatedDigitalETF.annualPerformanceLog[i]
                .monthlyPerformance) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    updatedDigitalETF.annualPerformanceLog[i]
                      .monthlyPerformance,
                    month,
                  )
                ) {
                  // get first day of month in year
                  const firstDayOfMonthInYear = dayjs()
                    .year(updatedDigitalETF.annualPerformanceLog[i].year)
                    .month(+month - 1)
                    .startOf("month");

                  // remove any months in future
                  if (firstDayOfMonthInYear.isAfter(now, "month")) {
                    delete updatedDigitalETF.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }

                  // remove any months before issue date
                  if (
                    firstDayOfMonthInYear.isBefore(
                      updatedDigitalETF.issueDate,
                      "month",
                    )
                  ) {
                    delete updatedDigitalETF.annualPerformanceLog[i]
                      .monthlyPerformance[month];
                  }
                }
              }
            }
          }
        }

        // prepare updated touched fields
        const updatedTouchedFields = {
          ...touchedFields,
          [field]: true,
        };
        if (fieldsAffected) {
          fieldsAffected.forEach((f) => {
            updatedTouchedFields[f] = true;
          });
        }

        // set updated touched fields
        setTouchedFields(updatedTouchedFields);

        // clear any pending validation
        clearTimeout(validationTimeoutRef.current);

        // defer validation to take place in 200ms
        setValidationInProgress(true);
        clearTimeout(validationTimeoutRef.current);
        validationTimeoutRef.current = setTimeout(() => {
          setStep1ValidationResult(
            validateDigitalETFStep1(
              updatedDigitalETF,
              mint,
              updatedTouchedFields,
              false,
            ),
          );
          setStep2ValidationResult(
            validateDigitalETFStep2(
              updatedDigitalETF,
              updatedTouchedFields,
              false,
            ),
          );
          setSupportingDocsStepResult(
            validateDigitalETFSupportingDocuments(
              updatedDigitalETF,
              updatedTouchedFields,
              false,
            ),
          );
          setValidationInProgress(false);
        }, 800);

        return updatedDigitalETF;
      });

  // handleDiscreteDigitalETFUpdates performs any discrete updates required on the DigitalETF.
  // e.g. maturity date change, holdings or allocations
  // The updated DigitalETF is returned.
  const handleDiscreteDigitalETFUpdates: () => Promise<DigitalETF> =
    async () => {
      if (!unsavedChangesExist) {
        // if no unsaved changes exist then do nothing
        return digitalETF;
      }

      // perform each required update
      let updatedDigitalETF = digitalETF;

      if (!isEqual(digitalETF.maturityDate, copyDigitalETF.maturityDate)) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFMaturityDate({
            context: authContext,
            digitalETFID: digitalETF.id,
            maturityDate: digitalETF.maturityDate,
          })
        ).digitalETF;
      }

      if (digitalETF.maximumUnits.value.gt(copyDigitalETF.maximumUnits.value)) {
        updatedDigitalETF = (
          await DigitalETFUpdater.IncreaseDigitalETFMaximumUnits({
            context: authContext,
            digitalETFID: digitalETF.id,
            amount: digitalETF.maximumUnits.setValue(
              digitalETF.maximumUnits.value.minus(
                copyDigitalETF.maximumUnits.value,
              ),
            ),
          })
        ).digitalETF;
      } else if (
        digitalETF.maximumUnits.value.lt(copyDigitalETF.maximumUnits.value)
      ) {
        updatedDigitalETF = (
          await DigitalETFUpdater.DecreaseDigitalETFMaximumUnits({
            context: authContext,
            digitalETFID: digitalETF.id,
            amount: digitalETF.maximumUnits.setValue(
              copyDigitalETF.maximumUnits.value.minus(
                digitalETF.maximumUnits.value,
              ),
            ),
          })
        ).digitalETF;
      }

      if (
        !isEqual(
          digitalETF.countryAllocations,
          copyDigitalETF.countryAllocations,
        )
      ) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFCountryAllocations({
            context: authContext,
            digitalETFID: digitalETF.id,
            updatedCountryAllocations: digitalETF.countryAllocations,
          })
        ).digitalETF;
      }

      if (!isEqual(digitalETF.holdings, copyDigitalETF.holdings)) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFHoldings({
            context: authContext,
            digitalETFID: digitalETF.id,
            updatedHoldings: digitalETF.holdings,
          })
        ).digitalETF;
      }

      if (
        !isEqual(digitalETF.sectorAllocations, copyDigitalETF.sectorAllocations)
      ) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFSectorAllocations({
            context: authContext,
            digitalETFID: digitalETF.id,
            updatedSectorAllocations: digitalETF.sectorAllocations,
          })
        ).digitalETF;
      }

      if (
        !isEqual(
          digitalETF.annualPerformanceLog,
          copyDigitalETF.annualPerformanceLog,
        )
      ) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFAnnualPerformanceLog({
            context: authContext,
            digitalETFID: digitalETF.id,
            updatedAnnualPerformanceLog: digitalETF.annualPerformanceLog,
          })
        ).digitalETF;
      }

      if (
        !isEqual(
          digitalETF.supportingDocuments,
          copyDigitalETF.supportingDocuments,
        )
      ) {
        updatedDigitalETF = (
          await DigitalETFUpdater.ChangeDigitalETFSupportingDocuments({
            context: authContext,
            digitalETFID: digitalETF.id,
            updatedSupportingDocuments: digitalETF.supportingDocuments,
          })
        ).digitalETF;
      }

      return updatedDigitalETF;
    };

  // handlePerformRequiredUpdates performs any updates required on the DigitalETF.
  // i.e. either creation, draft update or discrete update
  // The updated DigitalETF is returned.
  const handlePerformRequiredUpdates: () => Promise<DigitalETF> = async () => {
    // if creation is in progress
    if (creatingNewDigitalETF) {
      // then create the new digital etf
      return (
        await DigitalETFCreator.CreateNewDigitalETF({
          context: authContext,
          ownerID: digitalETF.ownerID,
          name: digitalETF.name,
          assetClass: digitalETF.assetClass,
          issueDate: digitalETF.issueDate,
          maturityDate: digitalETF.maturityDate,
          maximumUnits: digitalETF.maximumUnits,
          valuationToken: digitalETF.valuationToken,
          supportingDocuments: digitalETF.supportingDocuments,
          investorProfile: digitalETF.investorProfile,
          investorProfileDescription: digitalETF.investorProfileDescription,
          riskProfile: digitalETF.riskProfile,
          riskProfileDescription: digitalETF.riskProfileDescription,
          holdings: digitalETF.holdings,
          sectorAllocations: digitalETF.sectorAllocations,
          countryAllocations: digitalETF.countryAllocations,
          annualPerformanceLog: digitalETF.annualPerformanceLog,
        })
      ).digitalETF;
    }
    // Otherwise the instrument already exists
    if (digitalETF.state === DigitalETFState.Draft) {
      // if the instrument is in draft then perform a draft update
      return unsavedChangesExist
        ? (
            await DigitalETFUpdater.DraftUpdateDigitalETF({
              context: authContext,
              digitalETF,
            })
          ).digitalETF
        : digitalETF;
    }
    // otherwise perform multiple discrete updates
    return handleDiscreteDigitalETFUpdates();
  };

  // Document Uploading
  const dropZoneProps = useDropzone({
    // allowed document types
    accept: {
      "image/png": [],
      "image/jpg": [],
      "image/jpeg": [],
      "application/pdf": [],
    },
    // document drop validator
    validator: (f: File) => {
      if (!allowedDocumentTypes.includes(f.type)) {
        enqueueSnackbar("File Type Not Supported", { variant: "warning" });
        return [
          {
            message: "Unsupported File Type",
            code: "unsupported-file-type",
          },
        ];
      }

      if (f.size > 20 * 1000 * 1000) {
        enqueueSnackbar("File is Too Large For Upload", { variant: "warning" });
        return [
          {
            message: "Max of 20MB allowed",
            code: "file-too-large",
          },
        ];
      }

      return null;
    },

    // document drop function
    onDrop: async (acceptedFiles: File[]) => {
      // for every file that has been dropped...
      await Promise.all(
        acceptedFiles.map(async (file: File) => {
          // request to upload a document against the instrument
          const response =
            await InstrumentDocumentController.RequestDocumentUploadForInstrument(
              {
                context: authContext,
              },
            );

          // add a pending document upload
          setPendingDocumentUploads((prev) => [
            ...prev,
            {
              document: new Document({
                id: response.documentID,
                name: file.name,
                mimeType: file.type,
                description: "",
              }),
              file,
              complete: false,
              percent: 0,
            },
          ]);

          // perform upload within an awaited promise to allow asynchronous UI updates
          await (() =>
            new Promise((resolve, reject) => {
              file.arrayBuffer().then((arrayBuffer) => {
                doUpload({
                  url: response.uploadURL,
                  documentData: arrayBuffer,
                  documentType: file.type,
                  onProgress: (p: number) =>
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].percent = p;
                      return [...prev];
                    }),
                  onComplete: () => {
                    setPendingDocumentUploads((prev) => {
                      const idx = prev.findIndex(
                        (d) => d.document.id === response.documentID,
                      );
                      if (idx === -1) {
                        console.error("unable to find document for update");
                        return prev;
                      }
                      prev[idx].complete = true;
                      return [...prev];
                    });
                    resolve(true);
                  },
                  onError: reject,
                });
              });
            }))();
        }),
      );

      // finally update supporting documents and clear the pending document uploads
      setPendingDocumentUploads((prev) => {
        // update supporting documents
        handleChangeDigitalETFInDialog("supportingDocuments")([
          ...prev.map((pd) => pd.document),
          ...digitalETF.supportingDocuments,
        ]);

        return [];
      });
    },
  });

  //  Determine Dialog Actions
  const getAvailableActions = () => {
    // if the issue date is invalid
    if (!dateIsValid(digitalETF.issueDate)) {
      // then return with no actions
      return [];
    }

    const prioritisedIconActions: { node: ReactNode; priority: number }[] = [];
    const prioritisedButtonActions: { node: ReactNode; priority: number }[] =
      [];

    // for every potential action available at the digitalETF's state
    // consider what action buttons to add to list
    for (const action of potentialNextActionsForDigitalETF) {
      switch (action) {
        case DigitalETFAction.PreIssue:
          // if the view configuration for state changing is set
          if (digitalETFActionsViewConfig.ChangeState) {
            if (dayjs(digitalETF.issueDate).isAfter(dayjs())) {
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        id="digitalETFDialog-preIssue-button"
                        // disable unless both steps are complete
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading
                        }
                        children="Pre-Issue"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Pre-Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: true,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare instrument ID for post api actions
                                let digitalETFID = "";

                                // perform required updates and pre-issuance with consistency
                                // perform any required updates
                                digitalETFID = (
                                  await handlePerformRequiredUpdates()
                                ).id;

                                // pre-issue etf stablecoin
                                await DigitalETFStateChanger.PreIssueDigitalETF(
                                  {
                                    context: authContext,
                                    issuanceAccountID:
                                      issuanceAccountStubViewModel.accountID,
                                    digitalETFID,
                                  },
                                );

                                enqueueSnackbar("Instrument Pre-Issued", {
                                  variant: "success",
                                });
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceDigitalInstrument}?&id=${digitalETFID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrDigitalETFNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftDigitalETFNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      name: "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${digitalETF.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  const err =
                                    errorContextErrorTranslator.translateError(
                                      e,
                                    );
                                  console.error(
                                    `error pre-issuing instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(err);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case DigitalETFAction.Issue:
          if (digitalETFActionsViewConfig.ChangeState) {
            if (dayjs(digitalETF.issueDate).isBefore(dayjs())) {
              prioritisedButtonActions.push({
                node: (
                  <Tooltip
                    title={
                      allStepsComplete ? "" : "All steps need to be complete"
                    }
                  >
                    <span>
                      <Button
                        id="digitalETFDialog-issue-button"
                        variant="contained"
                        color="primary"
                        disabled={
                          !allStepsComplete ||
                          validationInProgress ||
                          apiLoading ||
                          !!pendingDocumentUploads.length
                        }
                        children="issue"
                        onClick={() => {
                          if (!kycStatusVerified) {
                            enqueueSnackbar(
                              "A Verified KYC Status is Required to Perform Issuance",
                              { variant: "warning" },
                            );
                            return;
                          }

                          setIssueWarningDialogOptions({
                            preIssuing: false,
                            yesMethod: async (placeAfterIssue: boolean) => {
                              setAPILoading(true);

                              try {
                                // prepare instrument ID for post api actions
                                let digitalETFID = "";

                                digitalETFID = (
                                  await handlePerformRequiredUpdates()
                                ).id;

                                // issue digital etf
                                await DigitalETFStateChanger.IssueDigitalETF({
                                  context: authContext,
                                  issuanceAccountID:
                                    issuanceAccountStubViewModel.accountID,
                                  digitalETFID,
                                });

                                enqueueSnackbar("Instrument Issued", {
                                  variant: "success",
                                });
                                if (placeAfterIssue) {
                                  navigate(
                                    `${InstrumentsViewPaths.PlaceDigitalInstrument}?&id=${digitalETFID}`,
                                  );
                                } else {
                                  navigate(InstrumentsViewPaths.Table);
                                }
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                if (
                                  err.code ===
                                    ErrDigitalETFNameAlreadyInUseErrorCode ||
                                  err.code ===
                                    ErrDraftDigitalETFNameAlreadyInUseErrorCode
                                ) {
                                  setStep2ValidationResult({
                                    stepComplete: false,
                                    fieldValidations: {
                                      ...step2ValidationResult.fieldValidations,
                                      name: "Name is already in use",
                                    },
                                  });
                                  enqueueSnackbar(
                                    `Instrument Name '${digitalETF.name}' is Already in Use`,
                                    { variant: "warning" },
                                  );
                                } else {
                                  const err =
                                    errorContextErrorTranslator.translateError(
                                      e,
                                    );
                                  console.error(
                                    `error issuing instrument: ${
                                      err.message ? err.message : err.toString()
                                    }`,
                                  );
                                  errorContextDefaultErrorFeedback(err);
                                }
                              }

                              setAPILoading(false);
                            },
                            noMethod: () => setIssueWarningDialogOptions(null),
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;

        case DigitalETFAction.MarkDeleted:
          if (digitalETFActionsViewConfig.ChangeState) {
            if (!creatingNewDigitalETF) {
              prioritisedIconActions.push({
                node: (
                  <Tooltip title="Delete Draft" placement="top">
                    <span>
                      <IconButton
                        id="digitalETFDialog-deleteDraftDigitalETF-button"
                        size="small"
                        disabled={apiLoading}
                        onClick={() =>
                          setWarningDialogOptions({
                            yesMethod: async () => {
                              // user confirms deletion
                              setAPILoading(true);

                              try {
                                await DigitalETFStateChanger.MarkDigitalETFDeleted(
                                  {
                                    context: authContext,
                                    digitalETFID: digitalETF.id,
                                  },
                                );

                                enqueueSnackbar("Instrument Deleted", {
                                  variant: "success",
                                });
                                navigate(InstrumentsViewPaths.Table);
                                return;
                              } catch (e) {
                                const err =
                                  errorContextErrorTranslator.translateError(e);
                                console.error(
                                  `error deleting instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                enqueueSnackbar("Error Deleting Instrument", {
                                  variant: "error",
                                });
                              }

                              setAPILoading(false);
                            },
                            // user cancels deletion
                            noMethod: () => setWarningDialogOptions(null),
                            title: "Delete Draft Instrument?",
                            messageParagraphs: [
                              `
                          Select 'Yes' to confirm deletion of instrument.
                          `,
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                ),
                priority: 1,
              });
            }
          }
          break;
      }
    }

    // if the digital etf can be minted
    if (mint) {
      // and the executing user has the Mint view configuration
      // and view mode is on
      if (digitalETFActionsViewConfig.Mint && viewModeOn) {
        // then show the mint button
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              children="mint"
              onClick={() => setShowMintAssetDialog(true)}
            />
          ),
          priority: 1,
        });
      }
    }

    // check if the place button should be shown
    if (
      marketAssetViewConfig.List &&
      LedgerAssetReadyToList(digitalETF) &&
      !instrumentIsPlaced.current
    ) {
      prioritisedButtonActions.push({
        node: (
          <Button
            id="digitalETFDialog-place-button"
            variant="contained"
            color="primary"
            disabled={apiLoading || validationInProgress}
            children="place"
            onClick={async () => {
              if (unsavedChangesExist) {
                // if there are any unsaved changes save them before going to the placement screen
                setAPILoading(true);
                try {
                  await handlePerformRequiredUpdates();
                  setAPILoading(false);
                  return;
                } catch (e) {
                  const err = errorContextErrorTranslator.translateError(e);
                  console.error(
                    `error saving instrument: ${
                      err.message ? err.message : err.toString()
                    }`,
                  );
                  enqueueSnackbar("Error Saving Instrument", {
                    variant: "error",
                  });
                  setAPILoading(false);
                  return;
                }
              }

              // all went well - go to placement screen
              navigate(
                `${InstrumentsViewPaths.PlaceDigitalInstrument}?&id=${digitalETF.id}`,
              );
            }}
          />
        ),
        priority: 1,
      });
    }

    //
    // Update Actions
    //

    // if user has update view config set
    if (digitalETFActionsViewConfig.Update) {
      if (viewModeOn) {
        // and if view mode is on (i.e. not editing)
        // then consider whether or not to show the Edit Button
        // (i.e. the button that turns edit mode on)

        if (editIsANextAction) {
          prioritisedIconActions.push({
            node: (
              <Tooltip title="Edit" placement="top">
                <span>
                  <IconButton
                    id="digitalETFDialog-turnEditModeOn-button"
                    size="small"
                    disabled={apiLoading}
                    onClick={() => {
                      setViewModeOn(false);
                      navigate(
                        `${InstrumentsViewPaths.DigitalETF}?&id=${digitalETF.id}&edit=true`,
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ),
            priority: 2,
          });
        }
      } else {
        // otherwise view mode is off (i.e. editing)
        // show save button at all times
        prioritisedButtonActions.push({
          node: (
            <Button
              variant="outlined"
              id="digitalETFDialog-save-button"
              disabled={
                validationInProgress ||
                apiLoading ||
                !!pendingDocumentUploads.length ||
                // if not in a draft state
                (digitalETF.state !== DigitalETFState.Draft &&
                  // then button is disabled unless all steps are complete
                  !allStepsComplete)
              }
              onClick={async () => {
                setAPILoading(true);

                // if unsaved changes exist then perform any required updates
                let updatedDigitalETF = digitalETF;
                if (creatingNewDigitalETF || unsavedChangesExist) {
                  try {
                    updatedDigitalETF = await handlePerformRequiredUpdates();
                    enqueueSnackbar("Instrument Saved", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    if (
                      err.code === ErrDigitalETFNameAlreadyInUseErrorCode ||
                      err.code === ErrDraftDigitalETFNameAlreadyInUseErrorCode
                    ) {
                      setStep2ValidationResult({
                        stepComplete: false,
                        fieldValidations: {
                          ...step2ValidationResult.fieldValidations,
                          name: "Name is already in se",
                        },
                      });
                      enqueueSnackbar(
                        `Instrument Name '${digitalETF.name}' is Already in Use`,
                        { variant: "warning" },
                      );
                    } else {
                      const err = errorContextErrorTranslator.translateError(e);
                      console.error(
                        `error saving instrument: ${
                          err.message ? err.message : err.toString()
                        }`,
                      );
                      errorContextDefaultErrorFeedback(err);
                    }
                    setAPILoading(false);
                    return;
                  }
                }

                // clear field validations
                setStep1ValidationResult({
                  ...step1ValidationResult,
                  fieldValidations: {},
                });
                setStep2ValidationResult({
                  ...step2ValidationResult,
                  fieldValidations: {},
                });
                setSupportingDocsStepResult({
                  ...supportingDocsStepValidationResult,
                  fieldValidations: {},
                });

                // turn view mode on
                setViewModeOn(true);
                navigate(
                  `${InstrumentsViewPaths.DigitalETF}?&id=${updatedDigitalETF.id}`,
                );

                // update in dialog components
                setDigitalETF(new DigitalETF(updatedDigitalETF));
                setCopyOfDigitalETF(new DigitalETF(updatedDigitalETF));

                // stop api loading
                setAPILoading(false);
              }}
              children="save"
            />
          ),
          priority: 2,
        });
      }
    }

    return [
      // ensure icon actions always render after button actions
      ...prioritisedButtonActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
      ...prioritisedIconActions
        .sort((x, y) => {
          if (x.priority > y.priority) {
            return -1;
          }
          if (x.priority < y.priority) {
            return 1;
          }
          return 0;
        })
        .map((a) => a.node),
    ];
  };

  // Field View Mode Determination
  const draftUpdateOnlyFieldViewModeOn =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![DigitalETFAction.DraftUpdate].some((a) =>
      potentialNextActionsForDigitalETF.includes(a),
    );

  const maturityDateReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![DigitalETFAction.DraftUpdate, DigitalETFAction.ChangeMaturityDate].some(
      (a) => potentialNextActionsForDigitalETF.includes(a),
    );

  const maximumUnitsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      DigitalETFAction.DraftUpdate,
      DigitalETFAction.IncreaseMaximumUnits,
      DigitalETFAction.DecreaseMaximumUnits,
    ].some((a) => potentialNextActionsForDigitalETF.includes(a));

  const holdingsAndAllocationsSectionReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      DigitalETFAction.DraftUpdate,
      DigitalETFAction.ChangeHoldings,
      DigitalETFAction.ChangeCountryAllocations,
      DigitalETFAction.ChangeSectorAllocations,
    ].some((a) => potentialNextActionsForDigitalETF.includes(a));

  const annualPerformanceLogReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      DigitalETFAction.DraftUpdate,
      DigitalETFAction.ChangeAnnualPerformanceLog,
    ].some((a) => potentialNextActionsForDigitalETF.includes(a));

  const supportingDocumentsReadOnly =
    viewModeOn || // if viewMode is on OR
    // user does not have update perms OR
    !digitalETFActionsViewConfig.Update ||
    // one of the following are among next potential actions
    ![
      DigitalETFAction.DraftUpdate,
      DigitalETFAction.ChangeSupportingDocuments,
    ].some((a) => potentialNextActionsForDigitalETF.includes(a));

  return (
    <>
      <StyledDialog open fullScreen>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item className={classes.heading}>
              <div className={classes.miniLogoWrapper}>
                <img alt="" width="100%" src={meshMiniLogo} />
              </div>
              <Typography
                variant="h5"
                children={
                  digitalETF.state
                    ? digitalETF.state === DigitalETFState.Draft
                      ? "Instrument Builder"
                      : digitalETF.name
                    : "Instrument Builder"
                }
              />
            </Grid>
            {!creatingNewDigitalETF && (
              <>
                <Grid item>
                  <InstrumentStateChip state={digitalETF.state} />
                </Grid>
                {LedgerAssetReadyToList(digitalETF) ? (
                  <Grid item>
                    <PlacementStateWordsChip
                      placed={instrumentIsPlaced.current}
                    />
                  </Grid>
                ) : null}
              </>
            )}
            {apiLoading && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" spacing={1} alignItems="center">
            {getAvailableActions().map((a, idx) => (
              <Grid item key={idx}>
                {a}
              </Grid>
            ))}
            <Grid item>
              <Tooltip title="Close" placement="top">
                <span>
                  <IconButton
                    size="small"
                    id="digitalETFDialog-close-button"
                    disabled={apiLoading}
                    onClick={() => {
                      // if unsaved changes exist
                      if (unsavedChangesExist) {
                        // show a warning dialog
                        setWarningDialogOptions({
                          title: "Save Instrument?",
                          yesMethod: async () => {
                            // if the 'Yes' button is pressed on dialog
                            setAPILoading(true);

                            try {
                              await handlePerformRequiredUpdates();
                              enqueueSnackbar("Instrument Saved", {
                                variant: "success",
                              });
                              navigate(InstrumentsViewPaths.Table);
                              return;
                            } catch (e) {
                              const err =
                                errorContextErrorTranslator.translateError(e);
                              if (
                                err.code ===
                                  ErrDigitalETFNameAlreadyInUseErrorCode ||
                                err.code ===
                                  ErrDraftDigitalETFNameAlreadyInUseErrorCode
                              ) {
                                setStep2ValidationResult({
                                  stepComplete: false,
                                  fieldValidations: {
                                    ...step2ValidationResult.fieldValidations,
                                    name: "Name is already in use",
                                  },
                                });
                                enqueueSnackbar(
                                  `Instrument Name '${digitalETF.name}' is Already in Use`,
                                  { variant: "warning" },
                                );
                              } else {
                                console.error(
                                  `error saving instrument: ${
                                    err.message ? err.message : err.toString()
                                  }`,
                                );
                                errorContextDefaultErrorFeedback(err);
                              }
                            }

                            setAPILoading(false);
                          },
                          noMethod: async () => {
                            // if the 'No' button is pressed
                            // just go straight back to instruments table
                            navigate(InstrumentsViewPaths.Table);
                          },
                          messageParagraphs: [
                            `
                        You have not saved the changes that you have made.
                        Select 'Yes' to save the changes made to the ETF
                        you have been working on in it's current state
                        and return to the instrument dashboard.
                        Select 'No' to proceed without saving.
                        `,
                          ],
                        });
                      } else {
                        // if there are no unsaved changes do nothing
                        // and go straight back to instruments table
                        navigate(InstrumentsViewPaths.Table);
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          classes={{ root: cx(classes.dialogContent, "meshScroll") }}
        >
          <div className={classes.rootLeftColumn}>
            {/* ----- Token Class Section ----------------------------- */}
            <Grid
              className={classes.tokenClassSelectionLayout}
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              {(() => [
                creatingNewDigitalETF ? (
                  <TextField
                    label="Token Class"
                    id="digitalETFDialog-tokenClass-textField"
                    className={classes.fieldWidth}
                    disabled={apiLoading}
                    value={TokenClass.NewInstrument}
                    select
                    onChange={(e) => {
                      switch (e.target.value) {
                        case TokenClass.NewInstrument:
                          // do nothing, already here
                          break;

                        case TokenClass.RightsToInstrument:
                          // if unsaved changes exist
                          if (unsavedChangesExist) {
                            // show a warning dialog
                            setWarningDialogOptions({
                              yesMethod: async () => {
                                // if the 'Yes' button is pushed on the warning dialog
                                setAPILoading(true);

                                try {
                                  // then save any existing changes
                                  await handlePerformRequiredUpdates();
                                  enqueueSnackbar("Instrument Saved", {
                                    variant: "success",
                                  });
                                  // and go to new rights to etf creation
                                  navigate(InstrumentsViewPaths.RightsToETF);
                                  return;
                                } catch (e) {
                                  const err =
                                    errorContextErrorTranslator.translateError(
                                      e,
                                    );
                                  if (
                                    err.code ===
                                      ErrDigitalETFNameAlreadyInUseErrorCode ||
                                    err.code ===
                                      ErrDraftDigitalETFNameAlreadyInUseErrorCode
                                  ) {
                                    setStep2ValidationResult({
                                      stepComplete: false,
                                      fieldValidations: {
                                        ...step2ValidationResult.fieldValidations,
                                        name: "Name is already in use",
                                      },
                                    });
                                    enqueueSnackbar(
                                      `Instrument Name '${digitalETF.name}' is Already in Use`,
                                      { variant: "warning" },
                                    );
                                  } else {
                                    console.error(
                                      `error saving instrument: ${
                                        err.message
                                          ? err.message
                                          : err.toString()
                                      }`,
                                    );
                                    errorContextDefaultErrorFeedback(err);
                                  }
                                }

                                setAPILoading(false);
                              },
                              noMethod: async () => {
                                // if the 'No' button is pressed
                                // go straight to rights to rights to an ETF creation
                                navigate(InstrumentsViewPaths.RightsToETF);
                              },
                              title: "Save Instrument?",
                              messageParagraphs: [
                                `
                              Changing the token class requires that you create new rights to an instrument.
                              Select 'Yes' to save your Current ETF and start new Rights to an ETF.
                              Alternatively, select 'No' to proceed without saving.
                              `,
                              ],
                            });
                          } else {
                            // if there are no unsaved changes then do nothing
                            // and go straight to the creation of new RightsToETF
                            navigate(InstrumentsViewPaths.RightsToETF);
                          }
                          break;
                      }
                    }}
                  >
                    <MenuItem value={TokenClass.NewInstrument}>
                      {TokenClass.NewInstrument}
                    </MenuItem>
                    <MenuItem value={TokenClass.RightsToInstrument}>
                      {TokenClass.RightsToInstrument}
                    </MenuItem>
                  </TextField>
                ) : viewModeOn ? (
                  <TextField
                    id="digitalETFDialog-tokenClass-textField"
                    className={classes.fieldWidth}
                    label="Token Class"
                    value="New Instrument"
                    readOnly
                  />
                ) : (
                  <Tooltip
                    title="Cannot Change After Instrument has Been Created"
                    placement="top-start"
                  >
                    <span>
                      <TextField
                        id="digitalETFDialog-tokenClass-textField"
                        className={classes.fieldWidth}
                        label="Token Class"
                        value="New Instrument"
                        readOnly
                        disabled
                      />
                    </span>
                  </Tooltip>
                ),
                <TextField
                  label="Issuer"
                  className={classes.fieldWidth}
                  value={new Client(myClient).name}
                  readOnly
                />,
              ])().map((i, idx) => (
                <Grid key={idx} item>
                  {i}
                </Grid>
              ))}
            </Grid>

            {/* ----- Accordion Section ----------------------------- */}
            <div>
              {/*
              // -------------------------------------------------
              // Step 1
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.digitalInstrument
                    ? accordionSectionsOpen.digitalInstrument
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    digitalInstrument: !accordionSectionsOpen.digitalInstrument,
                  })
                }
              >
                <AccordionSummary
                  id="digitalETFDialog-step1-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="digitalETFDialog-step1-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  classes={{
                    root: classes.stepsCommonAccordionSummaryRootOverride,
                  }}
                >
                  <div
                    className={classes.stepsCommonAccordionSummaryContentLayout}
                  >
                    <Typography variant="h6">Instrument</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 1
                    </Typography>
                    <Typography
                      variant="body2"
                      className={cx({
                        // if any step 1 validation messages are NOT undefined
                        // then apply warning text colour class
                        [classes.errorText]:
                          !step1ValidationResult.stepComplete,

                        // if the step is complete then apply success text colour class
                        [classes.successText]:
                          step1ValidationResult.stepComplete,
                      })}
                    >
                      {step1ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      digitalETFActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="digitalETFDialogStep1-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.DigitalETF}?&id=${digitalETF.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.stepsCommonAccordionDetailsLayout}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.stepsCommonGuidanceText}
                  >
                    Enter defining details of the new instrument that you are
                    building.
                  </Typography>

                  <div className={classes.step1InstrumentDetailsFieldsSection}>
                    {/* Row 1 */}
                    {creatingNewDigitalETF ? (
                      <TextField
                        label="Instrument Type"
                        id="digitalETFDialog-instrumentType-textField"
                        className={classes.fieldWidth}
                        disabled={apiLoading}
                        value="ETF"
                        select
                        onChange={(e) => {
                          switch (e.target.value) {
                            case "ETF":
                              // do nothing, already here
                              break;

                            case "ETN":
                              // if there are unsaved changes
                              if (unsavedChangesExist) {
                                // show a warning dialog
                                setWarningDialogOptions({
                                  title: "Save Instrument?",
                                  yesMethod: async () => {
                                    // if the 'Yes' button is pressed on the warning dialog
                                    setAPILoading(true);

                                    try {
                                      // then save any existing changes
                                      await handlePerformRequiredUpdates();
                                      enqueueSnackbar("Instrument Saved", {
                                        variant: "success",
                                      });
                                      // and go to new digital etn creation
                                      navigate(InstrumentsViewPaths.DigitalETN);
                                      return;
                                    } catch (e) {
                                      const err =
                                        errorContextErrorTranslator.translateError(
                                          e,
                                        );
                                      if (
                                        err.code ===
                                          ErrDigitalETFNameAlreadyInUseErrorCode ||
                                        err.code ===
                                          ErrDraftDigitalETFNameAlreadyInUseErrorCode
                                      ) {
                                        setStep2ValidationResult({
                                          stepComplete: false,
                                          fieldValidations: {
                                            ...step2ValidationResult.fieldValidations,
                                            name: "Name is already in use",
                                          },
                                        });
                                        enqueueSnackbar(
                                          `Instrument Name '${digitalETF.name}' is Already in Use`,
                                          { variant: "warning" },
                                        );
                                      } else {
                                        console.error(
                                          `error saving instrument: ${
                                            err.message
                                              ? err.message
                                              : err.toString()
                                          }`,
                                        );
                                        errorContextDefaultErrorFeedback(err);
                                      }
                                    }

                                    setAPILoading(false);
                                  },
                                  noMethod: async () => {
                                    // if the 'No' button is pressed
                                    // go straight to DigitalETN creation
                                    navigate(InstrumentsViewPaths.DigitalETN);
                                  },
                                  messageParagraphs: [
                                    `
                              Changing the instrument type requires that a new instrument be created.
                              Select 'Yes' to save ETF you are working on in it's current state and
                              proceed to the creation of a new ETN. Alternatively, select 'No' to
                              proceed without saving.
                              `,
                                  ],
                                });
                              } else {
                                // no unsaved changes -
                                // go straight to DigitalETN creation
                                navigate(InstrumentsViewPaths.DigitalETN);
                              }
                              break;
                          }
                        }}
                      >
                        <MenuItem
                          id="digitalETFDialog-instrumentTypeETF-menuItem"
                          value="ETF"
                        >
                          ETF
                        </MenuItem>
                        <MenuItem
                          id="digitalETFDialog-instrumentTypeETN-menuItem"
                          value="ETN"
                        >
                          ETN
                        </MenuItem>
                      </TextField>
                    ) : viewModeOn ? (
                      <TextField
                        id="digitalETFDialog-type-textField"
                        className={classes.fieldWidth}
                        label="Instrument Type"
                        value="ETF"
                        readOnly
                      />
                    ) : (
                      <Tooltip
                        title="Cannot Change After Instrument has Been Created"
                        placement="top-start"
                      >
                        <span>
                          <TextField
                            id="digitalETFDialog-type-textField"
                            className={classes.fieldWidth}
                            label="Instrument Type"
                            value="ETF"
                            readOnly
                            disabled
                          />
                        </span>
                      </Tooltip>
                    )}
                    <DateField
                      label="Issue Date"
                      id="digitalETFDialog-issueDate-dateField"
                      className={classes.fieldWidth}
                      readOnly={draftUpdateOnlyFieldViewModeOn}
                      onChange={(newDate) =>
                        handleChangeDigitalETFInDialog("issueDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="kycDialog-dateOfBirth-dateOfBirth"
                          fullWidth
                          disabled={apiLoading}
                          readOnly={draftUpdateOnlyFieldViewModeOn}
                          helperText={
                            step1ValidationResult.fieldValidations.issueDate
                          }
                          error={
                            !!step1ValidationResult.fieldValidations.issueDate
                          }
                        />
                      )}
                      value={digitalETF.issueDate}
                    />
                    {draftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        label="Valuation Stablecoin"
                        id="digitalETFDialog-valuationToken-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(digitalETF.valuationToken),
                            );
                          return model
                            ? `${model.token.code} - ${model.token.issuer}`
                            : "-";
                        })()}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="digitalETFDialog-valuationToken-autoComplete"
                        className={classes.fieldWidth}
                        disabled={apiLoading || draftUpdateOnlyFieldViewModeOn}
                        getOptionLabel={(
                          option: FinancialCurrencyStablecoinViewModel,
                        ) => `${option.token.code} - ${option.issuer}`}
                        options={potentialValuationCurrencyStablecoins}
                        loading={apiLoading}
                        onChange={(
                          a,
                          selected: FinancialCurrencyStablecoinViewModel | null,
                        ) => {
                          if (selected) {
                            // something selected - set valuationToken
                            handleChangeDigitalETFInDialog("valuationToken")(
                              new Token(selected.token),
                            );
                          } else {
                            // nothing selected - clear valuationToken
                            handleChangeDigitalETFInDialog("valuationToken")(
                              new Token(),
                            );
                          }
                        }}
                        value={(() => {
                          const model =
                            potentialValuationCurrencyStablecoins.find((m) =>
                              m.token.isEqualTo(digitalETF.valuationToken),
                            );
                          return model || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            readOnly={draftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            id="digitalETFDialog-valuationToken-autoCompleteTextField"
                            label="Valuation Stablecoin"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: draftUpdateOnlyFieldViewModeOn
                                ? digitalETF.valuationToken.isUndefined()
                                  ? digitalETF.valuationToken.string()
                                  : "-"
                                : "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              step1ValidationResult.fieldValidations
                                .valuationToken
                            }
                            error={
                              !!step1ValidationResult.fieldValidations
                                .valuationToken
                            }
                          />
                        )}
                      />
                    )}

                    {/* Row 2 */}
                    {viewModeOn || !creatingNewDigitalETF ? (
                      <TextField
                        label="Owner Group"
                        id="digitalETFDialog-ownerID-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === digitalETF.ownerID,
                          );
                          return group ? group.name : "-";
                        })()}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="digitalETFDialog-ownerID-autoComplete"
                        className={classes.fieldWidth}
                        disabled={apiLoading || !creatingNewDigitalETF}
                        getOptionLabel={(option: Group) => option.name}
                        options={potentialGroupOwners}
                        loading={apiLoading}
                        onChange={(a, selected: Group | null) =>
                          handleChangeDigitalETFInDialog("ownerID")(
                            selected ? selected.id : "",
                          )
                        }
                        value={(() => {
                          const group = potentialGroupOwners.find(
                            (g) => g.id === digitalETF.ownerID,
                          );
                          return group || null;
                        })()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="digitalETFDialog-ownerID-autoCompleteTextField"
                            label="Owner Group"
                            InputProps={{
                              ...params.InputProps,
                              placeholder: "Select...",
                            }}
                            InputLabelProps={{ shrink: true }}
                            readOnly={viewModeOn || !creatingNewDigitalETF}
                            disabled={apiLoading}
                            helperText={
                              step1ValidationResult.fieldValidations.ownerID
                            }
                            error={
                              !!step1ValidationResult.fieldValidations.ownerID
                            }
                          />
                        )}
                      />
                    )}
                    <DateField
                      label="Maturity Date"
                      className={classes.fieldWidth}
                      readOnly={draftUpdateOnlyFieldViewModeOn}
                      disabled={apiLoading}
                      onChange={(newDate) =>
                        handleChangeDigitalETFInDialog("maturityDate")(
                          newDate ? newDate.format() : "",
                        )
                      }
                      value={digitalETF.maturityDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="digitalETFDialog-maturityDate-dateField"
                          fullWidth
                          disabled={apiLoading}
                          readOnly={maturityDateReadOnly}
                          helperText={
                            step1ValidationResult.fieldValidations.maturityDate
                          }
                          error={
                            !!step1ValidationResult.fieldValidations
                              .maturityDate
                          }
                        />
                      )}
                    />
                    {viewModeOn ? (
                      <div
                        className={
                          classes.instrumentDetailsMaxUnitsInIssueLayout
                        }
                      >
                        <TextNumField
                          label="Max Units To Issue"
                          id="digitalETFDialog-maximumUnits-textNumField"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly
                          placeholder="0.00"
                          value={digitalETF.maximumUnits.value}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            step1ValidationResult.fieldValidations.maximumUnits
                          }
                          error={
                            !!step1ValidationResult.fieldValidations
                              .maximumUnits
                          }
                        />
                        <InputAdornment
                          position="start"
                          children={
                            <Tooltip
                              placement="top"
                              title="Maximum number of tokens the issuer is looking to mint on Mesh"
                            >
                              <InfoIcon
                                className={cx(
                                  classes.infoIcon,
                                  classes.instrumentDetailsMaxUnitsInIssueInfoIcon,
                                )}
                              />
                            </Tooltip>
                          }
                        />
                      </div>
                    ) : (
                      <TextNumField
                        label="Max Units To Issue"
                        id="digitalETFDialog-maximumUnits-textNumField"
                        className={classes.fieldWidth}
                        disabled={apiLoading}
                        readOnly={maximumUnitsReadOnly}
                        placeholder="0.00"
                        value={digitalETF.maximumUnits.value}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) =>
                          handleChangeDigitalETFInDialog("maximumUnits")(
                            digitalETF.maximumUnits.setValue(e.target.value),
                          )
                        }
                        disallowNegative
                        helperText={
                          step1ValidationResult.fieldValidations.maximumUnits
                        }
                        error={
                          !!step1ValidationResult.fieldValidations.maximumUnits
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              children={
                                <Tooltip
                                  placement="top"
                                  title="Maximum number of tokens the issuer is looking to mint on Mesh"
                                >
                                  <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                              }
                            />
                          ),
                        }}
                      />
                    )}
                  </div>

                  <div className={classes.step1InstrumentDetailsFieldsSection}>
                    <TextField
                      label="Instrument Name"
                      id="digitalETFDialog-instrumentName-textField"
                      className={classes.fieldWidth}
                      disabled={apiLoading}
                      readOnly={draftUpdateOnlyFieldViewModeOn}
                      inputProps={{ maxLength: 40 }}
                      value={
                        draftUpdateOnlyFieldViewModeOn
                          ? digitalETF.name
                            ? digitalETF.name
                            : "-"
                          : digitalETF.name
                      }
                      placeholder="Enter an Instrument Name"
                      onChange={(e) =>
                        handleChangeDigitalETFInDialog("name")(e.target.value)
                      }
                      helperText={step1ValidationResult.fieldValidations.name}
                      error={!!step1ValidationResult.fieldValidations.name}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      label="Asset Class"
                      id="digitalETFDialog-assetClass-textField"
                      className={classes.fieldWidth}
                      readOnly
                      value={digitalETF.assetClass}
                      InputLabelProps={{ shrink: true }}
                    />
                    {mint &&
                      ![
                        DigitalETFState.Draft,
                        DigitalETFState.PreIssued,
                        "",
                      ].includes(digitalETF.state) && (
                        <TextNumField
                          label="Issued Units"
                          id="digitalETFDialog-unitsInIssue-textNumFieldReadOnly"
                          className={classes.fieldWidth}
                          disabled={apiLoading}
                          readOnly
                          InputLabelProps={{ shrink: true }}
                          value={mint.issuedUnits.value}
                        />
                      )}
                  </div>
                </AccordionDetails>
              </Accordion>

              {/*
              // -------------------------------------------------
              // Step 2
              // -------------------------------------------------
            */}
              <Accordion
                expanded={
                  accordionSectionsOpen.exposure
                    ? accordionSectionsOpen.exposure
                    : false
                }
                onChange={() =>
                  setAccordionSectionsOpen({
                    ...accordionSectionsOpen,
                    exposure: !accordionSectionsOpen.exposure,
                  })
                }
              >
                <AccordionSummary
                  id="digitalETFDialog-step2-accordion"
                  expandIcon={
                    <ExpandMoreIcon
                      id="digitalETFDialog-step2-accordionToggleIconButton"
                      color="primary"
                    />
                  }
                  classes={{
                    root: classes.stepsCommonAccordionSummaryRootOverride,
                  }}
                >
                  <div
                    className={classes.stepsCommonAccordionSummaryContentLayout}
                  >
                    <Typography variant="h6">Asset Exposure</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Step 2
                    </Typography>
                    <Typography
                      variant="body2"
                      className={cx({
                        // if any step 2 validation messages are NOT undefined
                        // then apply warning text colour class
                        [classes.errorText]:
                          !step2ValidationResult.stepComplete,

                        // if the step is complete then apply success text colour class
                        [classes.successText]:
                          step2ValidationResult.stepComplete,
                      })}
                    >
                      {step2ValidationResult.stepComplete
                        ? "Complete"
                        : "Not Complete"}
                    </Typography>
                    {viewModeOn &&
                      editIsANextAction &&
                      digitalETFActionsViewConfig.Update && (
                        <Tooltip title="Edit" placement="top">
                          <span>
                            <IconButton
                              id="digitalETFDialogStep2-turnEditModeOn-button"
                              size="small"
                              disabled={apiLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewModeOn(false);
                                navigate(
                                  `${InstrumentsViewPaths.DigitalETF}?&id=${digitalETF.id}&edit=true`,
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={cx(
                    classes.stepsCommonAccordionDetailsLayout,
                    classes.paddingBottom,
                  )}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.stepsCommonGuidanceText}
                  >
                    Enter details of the division of assets within your
                    investment portfolio.
                  </Typography>

                  <div
                    className={
                      classes.instrumentDetailsDigitalInstrumentFieldsRiskProfileLayout
                    }
                  >
                    <Typography variant="h6" children="Investor Profile" />
                    <Typography variant="h6" children="Risk Profile" />

                    {draftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        label="Investor"
                        id="digitalETFDialog-investorProfile-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={
                          digitalETF.investorProfile
                            ? digitalETF.investorProfile
                            : "-"
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="digitalETFDialog-investorProfile-autocomplete"
                        className={classes.fieldWidth}
                        options={AllInvestorProfiles}
                        disabled={apiLoading || draftUpdateOnlyFieldViewModeOn}
                        value={
                          digitalETF.investorProfile
                            ? (digitalETF.investorProfile as InvestorProfile)
                            : null
                        }
                        onChange={(__: object, value) =>
                          handleChangeDigitalETFInDialog("investorProfile")(
                            value || "",
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            readOnly={draftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            id="digitalETFDialog-investorProfile-autocompleteTextField"
                            InputLabelProps={{ shrink: true }}
                            label="Investor"
                            placeholder={
                              draftUpdateOnlyFieldViewModeOn
                                ? digitalETF.investorProfile
                                  ? digitalETF.investorProfile
                                  : "-"
                                : "Select..."
                            }
                            variant="outlined"
                            helperText={
                              step2ValidationResult.fieldValidations
                                .investorProfile
                            }
                            error={
                              !!step2ValidationResult.fieldValidations
                                .investorProfile
                            }
                          />
                        )}
                      />
                    )}
                    {draftUpdateOnlyFieldViewModeOn ? (
                      <TextField
                        label="Risk"
                        id="digitalETFDialog-riskProfile-textField"
                        className={classes.fieldWidth}
                        readOnly
                        value={
                          digitalETF.riskProfile ? digitalETF.riskProfile : "-"
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        id="digitalETFDialog-riskProfile-autocomplete"
                        className={classes.fieldWidth}
                        options={AllInstrumentRiskProfiles}
                        disabled={apiLoading || draftUpdateOnlyFieldViewModeOn}
                        value={
                          digitalETF.riskProfile
                            ? (digitalETF.riskProfile as InstrumentRiskProfile)
                            : null
                        }
                        onChange={(__: object, value) =>
                          handleChangeDigitalETFInDialog("riskProfile")(
                            value || "",
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            readOnly={draftUpdateOnlyFieldViewModeOn}
                            disabled={apiLoading}
                            id="digitalETFDialog-riskProfile-autocompleteTextField"
                            InputLabelProps={{ shrink: true }}
                            label="Risk"
                            placeholder={
                              draftUpdateOnlyFieldViewModeOn
                                ? digitalETF.riskProfile
                                  ? digitalETF.riskProfile
                                  : "-"
                                : "Select..."
                            }
                            variant="outlined"
                            helperText={
                              step2ValidationResult.fieldValidations.riskProfile
                            }
                            error={
                              !!step2ValidationResult.fieldValidations
                                .riskProfile
                            }
                          />
                        )}
                      />
                    )}

                    {draftUpdateOnlyFieldViewModeOn ? (
                      <Typography
                        className={cx(
                          classes.generalDescriptionFieldTextAreaViewMode,
                          "meshScroll",
                        )}
                        variant="body1"
                        color="textSecondary"
                        children={digitalETF.investorProfileDescription}
                      />
                    ) : (
                      <div className={classes.generalDescriptionFieldLayout}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children="Description  (Optional)"
                        />
                        <TextareaAutosize
                          id="digitalETFDialog-investorProfileDescription-textArea"
                          minRows={5}
                          maxRows={5}
                          disabled={apiLoading}
                          value={digitalETF.investorProfileDescription}
                          onChange={(e) => {
                            let newValue: string = e.target.value;
                            if (newValue.length >= 500) {
                              newValue = newValue.slice(0, 500);
                            }
                            handleChangeDigitalETFInDialog(
                              "investorProfileDescription",
                            )(newValue);
                          }}
                          className={cx(
                            classes.generalDescriptionFieldTextArea,
                            "meshScroll",
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children={`${
                            500 - digitalETF.investorProfileDescription.length
                          } characters left`}
                        />
                      </div>
                    )}

                    {draftUpdateOnlyFieldViewModeOn ? (
                      <Typography
                        className={cx(
                          classes.generalDescriptionFieldTextAreaViewMode,
                          "meshScroll",
                        )}
                        variant="body1"
                        color="textSecondary"
                        children={digitalETF.riskProfileDescription}
                      />
                    ) : (
                      <div className={classes.generalDescriptionFieldLayout}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children="Description (Optional)"
                        />
                        <TextareaAutosize
                          minRows={5}
                          maxRows={5}
                          disabled={apiLoading}
                          id="digitalETFDialog-riskProfileDescription-textArea"
                          value={digitalETF.riskProfileDescription}
                          onChange={(e) => {
                            let newValue: string = e.target.value;
                            if (newValue.length >= 500) {
                              newValue = newValue.slice(0, 500);
                            }
                            handleChangeDigitalETFInDialog(
                              "riskProfileDescription",
                            )(newValue);
                          }}
                          className={cx(
                            classes.generalDescriptionFieldTextArea,
                            "meshScroll",
                          )}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          children={`${
                            500 - digitalETF.riskProfileDescription.length
                          } characters left`}
                        />
                      </div>
                    )}
                  </div>

                  {!viewModeOn && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={classes.stepsCommonGuidanceText}
                    >
                      Enter details of asset exposure allocation.
                    </Typography>
                  )}

                  {viewModeOn || holdingsAndAllocationsSectionReadOnly ? (
                    <div
                      className={classes.holdingsAndAllocationsViewModeLayout}
                    >
                      {/* -------------- Country Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography
                          variant="h6"
                          children="Country Allocation"
                        />
                        {accordionSectionsOpen.exposure && (
                          <CountryAllocationPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            countryAllocations={digitalETF.countryAllocations}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {digitalETF.countryAllocations.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={
                                  ca.name
                                    ? countries.find((c) => c.value === ca.name)
                                        ?.label
                                    : "-"
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* -------------- Holding Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography variant="h6" children="Holdings" />
                        {accordionSectionsOpen.exposure && (
                          <HoldingsPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            holdings={digitalETF.holdings}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {digitalETF.holdings.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={ca.name ? ca.name : "-"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* -------------- Sector Allocation -------------- */}
                      <div
                        className={
                          classes.holdingsAndAllocationsViewModeSectionLayout
                        }
                      >
                        <Typography variant="h6" children="Sector Allocation" />
                        {accordionSectionsOpen.exposure && (
                          <SectorAllocationPieChart
                            height={window.innerWidth * (0.5 / 3) - 10}
                            sectorAllocations={digitalETF.sectorAllocations}
                          />
                        )}
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsViewModeList,
                            "meshScroll",
                          )}
                        >
                          {digitalETF.sectorAllocations.map((ca, idx) => (
                            <div
                              key={idx}
                              className={
                                classes.holdingsAndAllocationsViewModeLineItem
                              }
                              style={{
                                width: window.innerWidth * (0.5 / 3) - 50,
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={`${formatTextNum(ca.percentage, {
                                  addDecimalPadding: true,
                                })}%`}
                              />
                              <Typography
                                color="textSecondary"
                                variant="subtitle1"
                                children={ca.sector ? ca.sector : "-"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.holdingsAndAllocationsLayout}>
                      {/* -------------- Country Allocation -------------- */}
                      <div>
                        <Typography
                          variant="h6"
                          children="Country Allocation"
                        />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation =
                              digitalETF.countryAllocations.reduce(
                                (total, ca) => ca.percentage.plus(total),
                                new BigNumber(0),
                              );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.countryAllocations &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            disabled={apiLoading}
                            id="digitalETFDialog-countryAllocationControlRowName-autocomplete"
                            className={classes.holdingsAndAllocationsNameField}
                            getOptionLabel={(c) => c.label}
                            options={countries}
                            value={(() => {
                              const c = countries.find(
                                (countryOption) =>
                                  countryOption.value ===
                                  pendingCountryAllocation.name,
                              );
                              return c || null;
                            })()}
                            onChange={(
                              __: object,
                              country: CountryOption | null,
                            ) => {
                              if (country) {
                                setPendingCountryAllocation(
                                  new CountryAllocation({
                                    ...pendingCountryAllocation,
                                    name: country.value,
                                  }),
                                );
                              } else {
                                setPendingCountryAllocation(
                                  new CountryAllocation({
                                    ...pendingCountryAllocation,
                                    name: "",
                                  }),
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled={apiLoading}
                                id="digitalETFDialog-countryAllocationControlRowName-autocompleteTextField"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Select..."
                                variant="outlined"
                                error={
                                  !!step2ValidationResult.fieldValidations
                                    .countryAllocations
                                }
                              />
                            )}
                          />
                          <TextNumField
                            disallowNegative
                            disabled={apiLoading}
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            id="digitalETFDialog-countryAllocationControlRowPercentage-textNumField"
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingCountryAllocation.percentage}
                            onChange={(e) =>
                              setPendingCountryAllocation({
                                ...pendingCountryAllocation,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            inputProps={{ maxLength: 40 }}
                            error={
                              !!step2ValidationResult.fieldValidations
                                .countryAllocations
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="digitalETFDialog-countryAllocationControlAdd-button"
                            onClick={() => {
                              if (!pendingCountryAllocation.name) {
                                return;
                              }
                              handleChangeDigitalETFInDialog(
                                "countryAllocations",
                              )([
                                ...digitalETF.countryAllocations,
                                new CountryAllocation(pendingCountryAllocation),
                              ]);
                              setPendingCountryAllocation(
                                new CountryAllocation(),
                              );
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step2ValidationResult.fieldValidations
                            .countryAllocations && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step2ValidationResult.fieldValidations
                                  .countryAllocations
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {digitalETF.countryAllocations.map(
                              (countryAllocation, idx) => (
                                <Grid
                                  item
                                  key={idx}
                                  className={
                                    classes.holdingsAndAllocationsLineItem
                                  }
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    disabled={apiLoading}
                                    id={`digitalETFDialog-countryAllocationLineCountryCode-autocomplete-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsNameField
                                    }
                                    getOptionLabel={(c) => c.label}
                                    options={countries}
                                    value={(() => {
                                      const c = countries.find(
                                        (countryOption) =>
                                          countryOption.value ===
                                          countryAllocation.name,
                                      );
                                      return c || null;
                                    })()}
                                    onChange={(
                                      __: object,
                                      country: CountryOption | null,
                                    ) => {
                                      if (country) {
                                        digitalETF.countryAllocations[
                                          idx
                                        ].name = country.value;
                                      } else {
                                        digitalETF.countryAllocations[
                                          idx
                                        ].name = "";
                                      }
                                      handleChangeDigitalETFInDialog(
                                        "countryAllocations",
                                      )(digitalETF.countryAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        disabled={apiLoading}
                                        id={`digitalETFDialog-countryAllocationLineCountryCode-autocompleteTextField-${idx}`}
                                        placeholder="..."
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step2ValidationResult
                                            .fieldValidations[
                                            `countryAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`digitalETFDialog-countryAllocationLinePercentage-textNumField-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsPercentField
                                    }
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={countryAllocation.percentage}
                                    onChange={(e) => {
                                      digitalETF.countryAllocations[
                                        idx
                                      ].percentage = new BigNumber(
                                        e.target.value,
                                      );
                                      handleChangeDigitalETFInDialog(
                                        "countryAllocations",
                                      )(digitalETF.countryAllocations);
                                    }}
                                    error={
                                      !!step2ValidationResult.fieldValidations[
                                        `countryAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`digitalETFDialog-countryAllocationLinePercentage-iconButton-${idx}`}
                                    onClick={() =>
                                      handleChangeDigitalETFInDialog(
                                        "countryAllocations",
                                      )(
                                        digitalETF.countryAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  {!!step2ValidationResult.fieldValidations[
                                    `countryAllocations-${idx}-name`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step2ValidationResult.fieldValidations[
                                          `countryAllocations-${idx}-name`
                                        ]
                                      }
                                    />
                                  )}
                                  {!!step2ValidationResult.fieldValidations[
                                    `countryAllocations-${idx}-percentage`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step2ValidationResult.fieldValidations[
                                          `countryAllocations-${idx}-percentage`
                                        ]
                                      }
                                    />
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </div>
                      </div>

                      {/* placeholder to take up space and keep scroll bar against line items */}
                      <div className={classes.holdingsAndAllocationsSpacer} />

                      {/* -------------- Holding Allocation -------------- */}
                      <div>
                        <Typography variant="h6" children="Holdings" />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation = digitalETF.holdings.reduce(
                              (total, holding) =>
                                holding.percentage.plus(total),
                              new BigNumber("0"),
                            );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.holdings &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <TextField
                            placeholder="Add a Holding"
                            value={pendingHolding.name}
                            inputProps={{ maxLength: 40 }}
                            id="digitalETFDialog-holdingControlRowName-textField"
                            className={classes.holdingsAndAllocationsNameField}
                            disabled={apiLoading}
                            onChange={(e) =>
                              setPendingHolding(
                                new Holding({
                                  ...pendingHolding,
                                  name: e.target.value,
                                }),
                              )
                            }
                            error={
                              !!step2ValidationResult.fieldValidations.holdings
                            }
                          />
                          <TextNumField
                            disallowNegative
                            disabled={apiLoading}
                            id="digitalETFDialog-holdingControlRowPercentage-textNumField"
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingHolding.percentage}
                            onChange={(e) =>
                              setPendingHolding({
                                ...pendingHolding,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            error={
                              !!step2ValidationResult.fieldValidations.holdings
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="digitalETFDialog-holdingControlRowAdd-button"
                            onClick={() => {
                              if (!pendingHolding.name) {
                                return;
                              }
                              handleChangeDigitalETFInDialog("holdings")([
                                ...digitalETF.holdings,
                                new Holding(pendingHolding),
                              ]);
                              setPendingHolding(new Holding());
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step2ValidationResult.fieldValidations
                            .holdings && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step2ValidationResult.fieldValidations.holdings
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {digitalETF.holdings.map((holding, idx) => (
                              <Grid
                                item
                                key={idx}
                                className={
                                  classes.holdingsAndAllocationsLineItem
                                }
                              >
                                <TextField
                                  disabled={apiLoading}
                                  inputProps={{ maxLength: 40 }}
                                  id={`digitalETFDialog-holdingRow-${idx}-name-textField`}
                                  className={
                                    classes.holdingsAndAllocationsNameField
                                  }
                                  value={holding.name}
                                  onChange={(e) => {
                                    digitalETF.holdings[idx].name =
                                      e.target.value;
                                    handleChangeDigitalETFInDialog("holdings")(
                                      digitalETF.holdings,
                                    );
                                  }}
                                  error={
                                    !!step2ValidationResult.fieldValidations[
                                      `holdings-${idx}-name`
                                    ]
                                  }
                                />
                                <TextNumField
                                  disallowNegative
                                  disabled={apiLoading}
                                  id={`digitalETFDialog-holdingControlRow-${idx}-percentage-textNumField`}
                                  className={
                                    classes.holdingsAndAllocationsPercentField
                                  }
                                  InputProps={{ endAdornment: "%" }}
                                  placeholder="-"
                                  value={holding.percentage}
                                  onChange={(e) => {
                                    digitalETF.holdings[idx].percentage =
                                      new BigNumber(e.target.value);
                                    handleChangeDigitalETFInDialog("holdings")(
                                      digitalETF.holdings,
                                    );
                                  }}
                                  error={
                                    !!step2ValidationResult.fieldValidations[
                                      `holdings-${idx}-percentage`
                                    ]
                                  }
                                />
                                <IconButton
                                  size="small"
                                  disabled={apiLoading}
                                  id={`digitalETFDialog-holdingRow-${idx}-remove-button`}
                                  onClick={() =>
                                    handleChangeDigitalETFInDialog("holdings")(
                                      digitalETF.holdings.filter(
                                        (_, i) => i !== idx,
                                      ),
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                                {!!step2ValidationResult.fieldValidations[
                                  `holdings-${idx}-name`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    className={
                                      classes.holdingsAnAllocationsLineItemHelperText
                                    }
                                    children={
                                      step2ValidationResult.fieldValidations[
                                        `holdings-${idx}-name`
                                      ]
                                    }
                                  />
                                )}
                                {!!step2ValidationResult.fieldValidations[
                                  `holdings-${idx}-percentage`
                                ] && (
                                  <Typography
                                    color="error"
                                    variant="body2"
                                    className={
                                      classes.holdingsAnAllocationsLineItemHelperText
                                    }
                                    children={
                                      step2ValidationResult.fieldValidations[
                                        `holdings-${idx}-percentage`
                                      ]
                                    }
                                  />
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>

                      {/* placeholder to take up space and keep scroll bar against line items */}
                      <div className={classes.holdingsAndAllocationsSpacer} />

                      {/* -------------- Sector Allocation -------------- */}
                      <div>
                        <Typography variant="h6" children="Sector Allocation" />
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsLineItem,
                            classes.holdingsAndAllocationSumLine,
                          )}
                        >
                          {(() => {
                            const totalAllocation =
                              digitalETF.sectorAllocations.reduce(
                                (total, ca) => ca.percentage.plus(total),
                                new BigNumber("0"),
                              );
                            return (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                className={cx(
                                  classes.holdingsAnAllocationsLineItemHelperText,
                                  {
                                    [classes.successText]:
                                      totalAllocation.isEqualTo(
                                        new BigNumber(100),
                                      ),
                                    [classes.errorText]:
                                      touchedFields.sectorAllocations &&
                                      (totalAllocation.isZero() ||
                                        totalAllocation.isGreaterThan(
                                          new BigNumber(100),
                                        )),
                                  },
                                )}
                                children={`Total Allocated: ${totalAllocation} %`}
                              />
                            );
                          })()}
                        </div>
                        <div
                          className={
                            classes.holdingsAndAllocationsControlLineItem
                          }
                        >
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            id="digitalETFDialog-sectorControlRowName-autoComplete"
                            className={classes.holdingsAndAllocationsNameField}
                            options={AllFinancialSectors}
                            disabled={apiLoading}
                            value={
                              pendingSectorAllocation.sector
                                ? pendingSectorAllocation.sector
                                : null
                            }
                            onChange={(__: object, value) =>
                              setPendingSectorAllocation(
                                new SectorAllocation({
                                  ...pendingSectorAllocation,
                                  sector: value || "",
                                }),
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="digitalETFDialog-sectorControlRowName-autoCompleteTextField"
                                placeholder={
                                  pendingSectorAllocation.sector
                                    ? pendingSectorAllocation.sector
                                      ? pendingSectorAllocation.sector
                                      : "-"
                                    : "Select..."
                                }
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                error={
                                  !!step2ValidationResult.fieldValidations
                                    .sectorAllocations
                                }
                              />
                            )}
                          />
                          <TextNumField
                            disallowNegative
                            className={
                              classes.holdingsAndAllocationsPercentField
                            }
                            disabled={apiLoading}
                            id="digitalETFDialog-sectorControlRowPercentage-textNumField"
                            InputProps={{ endAdornment: "%" }}
                            placeholder="-"
                            value={pendingSectorAllocation.percentage}
                            onChange={(e) =>
                              setPendingSectorAllocation({
                                ...pendingSectorAllocation,
                                percentage: new BigNumber(e.target.value),
                              })
                            }
                            error={
                              !!step2ValidationResult.fieldValidations
                                .sectorAllocations
                            }
                          />
                          <IconButton
                            size="small"
                            disabled={apiLoading}
                            id="digitalETFDialog-sectorControlRowAdd-button"
                            onClick={() => {
                              if (!pendingSectorAllocation.sector) {
                                return;
                              }
                              handleChangeDigitalETFInDialog(
                                "sectorAllocations",
                              )([
                                ...digitalETF.sectorAllocations,
                                new SectorAllocation(pendingSectorAllocation),
                              ]);
                              setPendingSectorAllocation(
                                new SectorAllocation(),
                              );
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          {!!step2ValidationResult.fieldValidations
                            .sectorAllocations && (
                            <Typography
                              color="error"
                              variant="body2"
                              className={
                                classes.holdingsAnAllocationsLineItemHelperText
                              }
                              children={
                                step2ValidationResult.fieldValidations
                                  .sectorAllocations
                              }
                            />
                          )}
                        </div>
                        <div
                          className={cx(
                            classes.holdingsAndAllocationsList,
                            "meshScroll",
                          )}
                        >
                          <Grid container direction="column">
                            {digitalETF.sectorAllocations.map(
                              (sectorAllocation, idx) => (
                                <Grid
                                  item
                                  key={idx}
                                  className={
                                    classes.holdingsAndAllocationsLineItem
                                  }
                                >
                                  <Autocomplete
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    id={`digitalETFDialog-sectorControlRowName-autocomplete-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsNameField
                                    }
                                    options={AllFinancialSectors}
                                    disabled={apiLoading}
                                    value={
                                      sectorAllocation.sector
                                        ? sectorAllocation.sector
                                        : null
                                    }
                                    onChange={(__: object, value) => {
                                      digitalETF.sectorAllocations[idx].sector =
                                        value || "";
                                      handleChangeDigitalETFInDialog(
                                        "sectorAllocations",
                                      )(digitalETF.sectorAllocations);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id={`digitalETFDialog-etfSectorControlRowName-autocompleteTextField-${idx}`}
                                        label="Sector"
                                        placeholder={
                                          pendingSectorAllocation.sector
                                            ? pendingSectorAllocation.sector
                                              ? pendingSectorAllocation.sector
                                              : "-"
                                            : "Select..."
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        error={
                                          !!step2ValidationResult
                                            .fieldValidations[
                                            `sectorAllocations-${idx}-name`
                                          ]
                                        }
                                      />
                                    )}
                                  />
                                  <TextNumField
                                    disallowNegative
                                    disabled={apiLoading}
                                    id={`digitalETFDialog-sectorControlRowPercentage-textField-${idx}`}
                                    className={
                                      classes.holdingsAndAllocationsPercentField
                                    }
                                    InputProps={{ endAdornment: "%" }}
                                    placeholder="-"
                                    value={sectorAllocation.percentage}
                                    onChange={(e) => {
                                      digitalETF.sectorAllocations[
                                        idx
                                      ].percentage = new BigNumber(
                                        e.target.value,
                                      );
                                      handleChangeDigitalETFInDialog(
                                        "sectorAllocations",
                                      )(digitalETF.sectorAllocations);
                                    }}
                                    error={
                                      !!step2ValidationResult.fieldValidations[
                                        `sectorAllocations-${idx}-percentage`
                                      ]
                                    }
                                  />
                                  <IconButton
                                    size="small"
                                    disabled={apiLoading}
                                    id={`digitalETFDialog-sectorControlRowAdd-button-${idx}`}
                                    onClick={() =>
                                      handleChangeDigitalETFInDialog(
                                        "sectorAllocations",
                                      )(
                                        digitalETF.sectorAllocations.filter(
                                          (_, i) => i !== idx,
                                        ),
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  {!!step2ValidationResult.fieldValidations[
                                    `sectorAllocations-${idx}-name`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step2ValidationResult.fieldValidations[
                                          `sectorAllocations-${idx}-name`
                                        ]
                                      }
                                    />
                                  )}
                                  {!!step2ValidationResult.fieldValidations[
                                    `sectorAllocations-${idx}-percentage`
                                  ] && (
                                    <Typography
                                      color="error"
                                      variant="body2"
                                      className={
                                        classes.holdingsAnAllocationsLineItemHelperText
                                      }
                                      children={
                                        step2ValidationResult.fieldValidations[
                                          `sectorAllocations-${idx}-percentage`
                                        ]
                                      }
                                    />
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* -------------- Returns Log -------------- */}
                  <Typography variant="h6" children="Returns Log" />
                  {!annualPerformanceLogReadOnly && (
                    <div
                      className={
                        classes.instrumentAnnualPerformanceLogHelperText
                      }
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        children="Add returns by month and annually in %"
                      />
                      {!!step2ValidationResult.fieldValidations
                        .annualPerformanceLog && (
                        <Typography
                          color="error"
                          variant="body2"
                          children={
                            step2ValidationResult.fieldValidations
                              .annualPerformanceLog
                          }
                        />
                      )}
                    </div>
                  )}
                  <ViewEditInstrumentAnnualPerformanceLog
                    annualPerformanceLog={digitalETF.annualPerformanceLog}
                    onChange={(updated) =>
                      handleChangeDigitalETFInDialog("annualPerformanceLog")(
                        updated,
                      )
                    }
                    disabled={apiLoading}
                    viewMode={annualPerformanceLogReadOnly}
                    fieldValidations={step2ValidationResult.fieldValidations}
                    issueDate={digitalETF.issueDate}
                    maturityDate={digitalETF.maturityDate}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={classes.rootRightColumn}>
            {!viewModeOn &&
            digitalETFActionsViewConfig.Update &&
            !supportingDocumentsReadOnly ? (
              <div className={classes.docsMediaColContent}>
                <div className={classes.docsMediaColContentWarningMsg}>
                  <Typography
                    variant="h6"
                    children="Supporting Documents/Media"
                  />
                  {!!supportingDocsStepValidationResult.fieldValidations
                    .supportingDocuments && (
                    <Typography
                      variant="body2"
                      color="error"
                      children={
                        supportingDocsStepValidationResult.fieldValidations
                          .supportingDocuments
                      }
                    />
                  )}
                </div>
                <div
                  {...dropZoneProps.getRootProps()}
                  className={cx(classes.dropZone, {
                    [classes.dropZoneAccept]: dropZoneProps.isDragAccept,
                    [classes.dropZoneReject]: dropZoneProps.isDragReject,
                  })}
                >
                  <input
                    {...dropZoneProps.getInputProps()}
                    disabled={apiLoading || !!pendingDocumentUploads.length}
                  />
                  {(() => {
                    if (pendingDocumentUploads.length) {
                      return (
                        <>
                          <Typography
                            variant="body2"
                            children="Uploads in Progress"
                          />
                          <CircularProgress />
                        </>
                      );
                    }

                    if (dropZoneProps.isDragActive) {
                      return dropZoneProps.isDragAccept ? (
                        <>
                          <Typography
                            variant="body1"
                            className={classes.successText}
                            children="Drop Documents For Upload"
                          />
                          <UploadIcon className={classes.successText} />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body1"
                            color="error"
                            children="Unsupported Document Type"
                          />
                          <NotAllowedIcon color="error" />
                        </>
                      );
                    }
                    return (
                      <>
                        <Typography
                          variant="body2"
                          children="Drop Documents Here to Upload"
                        />
                        <Typography variant="body2" children="or" />
                        <Button
                          disabled={apiLoading}
                          id="digitalETFDialog-selectFilesUpload-button"
                          variant="contained"
                          color="secondary"
                          children="Select Files"
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={classes.dropZoneGuidanceText}
                          children=".png, .jpeg, or .pdf up to max size of 20MB each"
                        />
                      </>
                    );
                  })()}
                </div>
                {/* -----------------Render Upload in Progress------------------ */}
                {pendingDocumentUploads.map((d, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id="digitalETFDialog-description-formField"
                      label="Description"
                      value={d.file.name}
                      disabled
                    />
                    {d.complete ? (
                      <DoneIcon className={classes.successText} />
                    ) : (
                      <CircularProgress
                        className={classes.docsMediaLineItemMarginTop}
                        size={25}
                        variant={d.percent ? "determinate" : "indeterminate"}
                        value={d.percent}
                      />
                    )}
                    <Typography
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="secondary"
                      children={d.file.name}
                    />
                  </div>
                ))}

                {/* -----------------Render Existing Docs------------------ */}
                {digitalETF.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <TextField
                      id={`digitalETFDialog-addAName-${doc.name}formField`}
                      label="Name"
                      value={doc.description}
                      inputProps={{ maxLength: 40 }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Add a Name"
                      disabled={apiLoading || !!pendingDocumentUploads.length}
                      onChange={(e) => {
                        digitalETF.supportingDocuments[idx].description =
                          e.target.value;
                        handleChangeDigitalETFInDialog("supportingDocuments", [
                          `supportingDocuments-${idx}-description`,
                        ])(digitalETF.supportingDocuments);
                      }}
                      error={
                        !!supportingDocsStepValidationResult.fieldValidations[
                          `supportingDocuments-${idx}-description`
                        ]
                      }
                      helperText={
                        supportingDocsStepValidationResult.fieldValidations[
                          `supportingDocuments-${idx}-description`
                        ]
                      }
                    />
                    <span>
                      <IconButton
                        id={`digitalETFDialog-deleteDocument-${doc.name}-button`}
                        className={classes.docsMediaLineItemMarginTop}
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        onClick={() =>
                          handleChangeDigitalETFInDialog("supportingDocuments")(
                            digitalETF.supportingDocuments.filter(
                              (_, i) => i !== idx,
                            ),
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                    <Typography
                      id={`digitalETFDialog-docName-${doc.name}typography`}
                      variant="body2"
                      color="secondary"
                      className={classes.docsMediaLineItemFileName}
                      children={doc.name}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={classes.docsMediaColContent}>
                <Typography
                  variant="h6"
                  children="Supporting Documents/Media"
                />
                {!digitalETF.supportingDocuments.length && (
                  <Typography
                    className={classes.docsMediaLineItemFileName}
                    variant="body2"
                    color="textSecondary"
                    children="Nothing has been uploaded yet"
                  />
                )}
                {/* -----------------Render Existing Docs------------------ */}
                {digitalETF.supportingDocuments.map((doc, idx: number) => (
                  <div key={idx} className={classes.docsMediaLineItemLayout}>
                    <Typography
                      className={classes.docsMediaLineItemFileName}
                      variant="body2"
                      color="textSecondary"
                      children={doc.description ? doc.description : "-"}
                    />
                    <div className={classes.docsMediaLineItemFileNameWithDL}>
                      <Typography
                        variant="body2"
                        color="secondary"
                        children={doc.name}
                      />
                      <IconButton
                        size="small"
                        disabled={apiLoading || !!pendingDocumentUploads.length}
                        className={classes.docsMediaDownloadIconButton}
                        onClick={async () => {
                          setAPILoading(true);
                          try {
                            download(
                              (
                                await InstrumentDocumentController.RequestDocumentDownloadForInstrument(
                                  {
                                    context: authContext,
                                    documentID: doc.id,
                                  },
                                )
                              ).downloadURL,
                              doc.name,
                            );
                          } catch (e) {
                            const err =
                              errorContextErrorTranslator.translateError(e);
                            console.error(
                              `error requesting download url: ${
                                err.message ? err.message : err.toString()
                              }`,
                            );
                          }
                          setAPILoading(false);
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
      </StyledDialog>
      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
      />
      <IssueWarningDialog
        showDialog={!!issueWarningDialogOptions}
        onCloseClick={() => setIssueWarningDialogOptions(null)}
        onYesClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.yesMethod
            : () => null
        }
        onNoClick={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.noMethod
            : () => null
        }
        disableControls={apiLoading}
        showProgressIndicator={apiLoading}
        preIssuing={
          issueWarningDialogOptions
            ? issueWarningDialogOptions.preIssuing
            : false
        }
      />
      {showMintAssetDialog && (
        <MintAssetDialog
          onCloseClick={() => setShowMintAssetDialog(false)}
          assetToMintID={digitalETF.id}
        />
      )}
    </>
  );
}
