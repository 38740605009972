import { Document } from "james/document";
import config from "react-global-configuration";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InvestorProfile } from "./InvestorProfile";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { Holding } from "./Holding";
import { SectorAllocation } from "./SectorAllocation";
import { CountryAllocation } from "./CountryAllocation";
import { jsonRPCRequestDEPRECATED } from "../../utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { DigitalETN } from "./DigitalETN";
import { Context } from "../security";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";

export interface CreateNewDigitalETNRequest {
  context: Context;
  ownerID: string;
  name: string;
  assetClass: AssetClassDEPRECATED | "";
  issueDate: string;
  maturityDate: string;
  maximumUnits: Amount;
  valuationToken: Token;
  supportingDocuments: Document[];
  investorProfile: InvestorProfile | "";
  investorProfileDescription: string;
  riskProfile: InstrumentRiskProfile | "";
  riskProfileDescription: string;
  holdings: Holding[];
  sectorAllocations: SectorAllocation[];
  countryAllocations: CountryAllocation[];
  annualPerformanceLog: InstrumentAnnualPerformanceLogEntry[];
}

export interface CreateNewDigitalETNResponse {
  digitalETN: DigitalETN;
}

export const DigitalETNCreator = {
  serviceProvider: "financial-DigitalETNCreator",
  async CreateNewDigitalETN(
    request: CreateNewDigitalETNRequest,
    additionalHeaders?: Headers,
  ): Promise<CreateNewDigitalETNResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      additionalHeaders,
      url: config.get("meshConsistencyAuthorizedURL"),
      method: `${DigitalETNCreator.serviceProvider}.CreateNewDigitalETN`,
      request,
    });
    return { digitalETN: new DigitalETN(response.digitalETN) };
  },
};
