import { Entry } from "james/audit/Entry";
import { Amount } from "./Amount";
import { Token } from "./Token";

export const MintTypeName = "mesh::ledger/Mint";

export class Mint {
  public ["@type"]: string = MintTypeName;

  public id = "";

  public token: Token = new Token();

  public issuedUnits: Amount = new Amount();

  public maximumUnits: Amount = new Amount();

  public auditEntry: Entry = new Entry();

  constructor(mint?: Mint) {
    if (!mint) {
      return;
    }
    this.id = mint.id;
    this.token = new Token(mint.token);
    this.issuedUnits = new Amount(mint.issuedUnits);
    this.maximumUnits = new Amount(mint.maximumUnits);
    this.auditEntry = new Entry(mint.auditEntry);
  }
}
