import { Document } from "james/document/Document";
import { DigitalETF, DigitalETFTypeName } from "./DigitalETF";
import { ETF, ETFTypeName } from "./ETF";
import { DigitalETN, DigitalETNTypeName } from "./DigitalETN";
import { ETN, ETNTypeName } from "./ETN";
import { UnitTrust, UnitTrustTypeName } from "./UnitTrust";
import { InstrumentAnnualPerformanceLogEntry } from "./InstrumentAnnualPerformanceLogEntry";
import { AssetClassDEPRECATED } from "./AssetClassDEPRECATED";
import { InstrumentRiskProfile } from "./InstrumentRiskProfile";
import { InvestorProfile } from "./InvestorProfile";
import { FinancialInstrumentState } from "./InstrumentState";

export interface Instrument {
  ["@type"]: string;

  instrumentID(): string;

  instrumentState(): FinancialInstrumentState;

  instrumentOwnerID(): string;

  instrumentIssueDate(): string;

  instrumentMaturityDate(): string;

  instrumentName(): string;

  instrumentAssetClass(): AssetClassDEPRECATED | "";

  instrumentRiskProfile(): InstrumentRiskProfile | "";

  instrumentAnnualPerformanceLog(): InstrumentAnnualPerformanceLogEntry[];

  instrumentInvestorProfile(): InvestorProfile | "";

  instrumentSupportingDocuments(): Document[];
}

export function NewInstrument(i: Instrument): Instrument {
  switch (i["@type"]) {
    case DigitalETFTypeName:
      return new DigitalETF(i as DigitalETF);

    case ETFTypeName:
      return new ETF(i as ETF);

    case DigitalETNTypeName:
      return new DigitalETN(i as DigitalETN);

    case ETNTypeName:
      return new ETN(i as ETN);

    case UnitTrustTypeName:
      return new UnitTrust(i as UnitTrust);

    default:
      throw new TypeError(`unknown instrument type ${i["@type"]}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InstrumentImplementedBy(object: any): object is Instrument {
  return [
    DigitalETFTypeName,
    ETFTypeName,
    DigitalETNTypeName,
    ETNTypeName,
    UnitTrustTypeName,
  ].includes(object["@type"]);
}
