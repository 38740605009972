import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  MarketSubscriptionOrderBookViewModel,
  MarketSubscriptionOrderBookViewReader,
  MarketSubscriptionOrderBookViewUnscopedReader,
} from "james/views/marketSubscriptionOrderBookView";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextExactCriterion } from "james/search/criterion";
import { SubscriptionOrderTable } from "./SubscriptionOrderTable";
import { SubscriptionUnderwritingTable } from "./SubscriptionUnderwritingTable";
import {
  MarketSubscriptionOrderBookSettlementViewModel,
  MarketSubscriptionOrderBookSettlementViewReader,
  MarketSubscriptionOrderBookSettlementViewUnscopedReader,
} from "james/views/marketSubscriptionOrderBookSettlementView";
import { Amount } from "../Ledger/Amount";
import { formatTextNum } from "utilities/number";
import { TransactionSignatureController } from "james/stellar/TransactionSignatureController";
import { useSnackbar } from "notistack";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import { SubscriptionOrderBookStateChip } from "./Chips";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { SubscriptionOrderBookStateController } from "james/market/SubscriptionOrderBookStateController";
import { TransactionState } from "james/ledger";

export type ViewProps = {
  system: boolean;
  height: number;
};

enum ViewTab {
  Overview = "Overview",
  Subscriptions = "Subscriptions",
  Underwriters = "Underwriters",
}

const allViewTabs: ViewTab[] = [
  ViewTab.Overview,
  ViewTab.Subscriptions,
  ViewTab.Underwriters,
];

export function ViewSubscriptionOrderBook(props: ViewProps) {
  const { authContext } = useApplicationContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { errorContextErrorTranslator } = useErrorContext();
  const [subscriptionOrderBookViewModel, setSubscriptionOrderBookViewModel] =
    useState<MarketSubscriptionOrderBookViewModel | undefined>(undefined);
  const [viewTab, setViewTab] = useState<ViewTab>(ViewTab.Overview);

  // navigate to table if no subscriptionOrderBookViewModel id query param has been set
  useEffect(() => {
    // try and get a subscriptionOrderBookViewModel id from url
    const subscriptionOrderBookViewModelIDFromURL = searchParams.get("id");
    if (!subscriptionOrderBookViewModelIDFromURL) {
      navigate(window.location.href.replace(/\/view.*$/, "/table"));
      return;
    }
  }, [searchParams.get("id")]);

  const [reload, setReload] = useState(false);
  const [
    fetchingSubscriptionOrderBookViewModel,
    setFetchingSubscriptionOrderBookViewModel,
  ] = useState(false);
  useLayoutEffect(() => {
    (async () => {
      // try and get a subscriptionOrderBookViewModel id from url
      const subscriptionOrderBookViewModelIDFromURL = searchParams.get("id");
      if (!subscriptionOrderBookViewModelIDFromURL) {
        return;
      }

      // do not perform load if
      if (
        subscriptionOrderBookViewModel &&
        // subscriptionOrderBookViewModel ID already set correctly
        subscriptionOrderBookViewModelIDFromURL ===
          subscriptionOrderBookViewModel.id && // AND
        // reload not requested
        !reload
      ) {
        return;
      }

      // otherwise clear reload
      setReload(false);

      // load necessary data
      setFetchingSubscriptionOrderBookViewModel(true);
      try {
        setSubscriptionOrderBookViewModel(
          props.system
            ? (
                await MarketSubscriptionOrderBookViewUnscopedReader.UnscopedReadOne(
                  {
                    context: authContext,
                    criteria: {
                      id: TextExactCriterion(
                        subscriptionOrderBookViewModelIDFromURL,
                      ),
                    },
                  },
                )
              ).model
            : (
                await MarketSubscriptionOrderBookViewReader.ReadOne({
                  context: authContext,
                  criteria: {
                    id: TextExactCriterion(
                      subscriptionOrderBookViewModelIDFromURL,
                    ),
                  },
                })
              ).model,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving subscriptionOrderBookViewModel: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setFetchingSubscriptionOrderBookViewModel(false);
    })();
  }, [authContext, history, reload, subscriptionOrderBookViewModel]);

  if (
    !subscriptionOrderBookViewModel ||
    fetchingSubscriptionOrderBookViewModel
  ) {
    return <Box>loading...</Box>;
  }

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto 1fr",
        rowGap: theme.spacing(0.5),
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          marginTop: theme.spacing(-1),
          gap: theme.spacing(1),
        })}
      >
        <Link
          to={window.location.pathname.replace(/\/view.*$/, "/table")}
          style={{ textDecoration: "none" }}
        >
          <Typography
            sx={(theme) => ({
              cursor: "pointer",
              color: theme.palette.text.hint,
              "&:hover": {
                color: theme.palette.primary.main,
                textDecoration: "underline",
              },
            })}
            variant="caption"
          >
            {`Subscriptions >`}
          </Typography>
        </Link>
        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          {`${subscriptionOrderBookViewModel.number}: ${subscriptionOrderBookViewModel.assetName}`}
        </Typography>
      </Box>
      <Paper square sx={(theme) => ({ paddingLeft: theme.spacing(4) })}>
        <Grid container>
          <Grid item>
            <Tabs
              value={viewTab}
              onChange={(_, value) => {
                setViewTab(value);
              }}
              textColor={"inherit"}
            >
              {allViewTabs.map((v, i) => (
                <Tab key={i} value={v} label={v} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      {(() => {
        switch (viewTab) {
          case ViewTab.Overview:
            return (
              <Overview
                system={props.system}
                model={subscriptionOrderBookViewModel}
                reload={() => setReload(true)}
              />
            );

          case ViewTab.Subscriptions:
            return (
              <SubscriptionOrderTable
                system={props.system}
                height={props.height - 65}
                subscriptionOrderBookID={
                  subscriptionOrderBookViewModel.subscriptionOrderBookID
                }
                hideColumns={(() => {
                  const colsToHide = ["Book", "Asset Name"];

                  if (!props.system) {
                    colsToHide.push("Res. Count");
                  }

                  return colsToHide;
                })()}
              />
            );

          case ViewTab.Underwriters:
            return (
              <SubscriptionUnderwritingTable
                system={props.system}
                height={props.height - 65}
                subscriptionOrderBookID={
                  subscriptionOrderBookViewModel.subscriptionOrderBookID
                }
                hideColumns={(() => {
                  const colsToHide = ["Book", "Asset Name"];

                  if (!props.system) {
                    colsToHide.push("Res. Count");
                  }

                  return colsToHide;
                })()}
              />
            );

          default:
            return null;
        }
      })()}
    </Box>
  );
}

type OverviewProps = {
  model: MarketSubscriptionOrderBookViewModel;
  system: boolean;
  reload: () => void;
};

const Overview = (props: OverviewProps) => {
  const { authContext, viewConfiguration } = useApplicationContext();
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();
  const { enqueueSnackbar } = useSnackbar();

  const [settlementViewModel, setSettlementViewModel] = useState<
    MarketSubscriptionOrderBookSettlementViewModel | undefined
  >(undefined);
  useEffect(() => {
    (async () => {
      let retrievedSettlementViewModel: MarketSubscriptionOrderBookSettlementViewModel;
      try {
        if (props.system) {
          retrievedSettlementViewModel = (
            await MarketSubscriptionOrderBookSettlementViewUnscopedReader.UnscopedReadOne(
              {
                context: authContext,
                criteria: {
                  subscriptionOrderBookID: TextExactCriterion(
                    props.model.subscriptionOrderBookID,
                  ),
                },
              },
            )
          ).model;
        } else {
          retrievedSettlementViewModel = (
            await MarketSubscriptionOrderBookSettlementViewReader.ReadOne({
              context: authContext,
              criteria: {
                subscriptionOrderBookID: TextExactCriterion(
                  props.model.subscriptionOrderBookID,
                ),
              },
            })
          ).model;
        }
        setSettlementViewModel(retrievedSettlementViewModel);
      } catch (e) {
        errorContextDefaultErrorFeedback(e);
      }
    })();
  }, [authContext, props.model.subscriptionOrderBookID]);

  const [signingInProgress, setSigningInProgress] = useState(false);
  const handleSign = useCallback(async () => {
    setSigningInProgress(true);
    try {
      await TransactionSignatureController.AddMyMeshKeySignatureToTransaction({
        context: authContext,
        transactionID: props.model.settlementTransactionID,
      });
      enqueueSnackbar(
        "Signing Complete! Settlement in Progress. Refresh to Monitor Settlement.",
      );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error signing`, e);
      enqueueSnackbar(`error signing: ${err.message}`, { variant: "error" });
      setSigningInProgress(false);
    }
  }, [authContext]);

  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const handleResolveSubscriptionOrderBookState = async () => {
    setResolutionInProgress(true);
    try {
      await SubscriptionOrderBookStateController.ResolveSubscriptionOrderBookIDState(
        {
          context: authContext,
          subscriptionOrderBookID: props.model.subscriptionOrderBookID,
          ignoreResolutionCount: true,
        },
      );
      props.reload();
      enqueueSnackbar("Subscription Book State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving subscription Book state`, e);
      enqueueSnackbar(
        `error resolving subscription Book state: ${err.message}`,
        { variant: "error" },
      );
    }
    setResolutionInProgress(false);
  };

  if (!settlementViewModel) {
    return <Box>loading</Box>;
  }

  return (
    <Card>
      <CardContent
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          <Typography variant={"h5"}>Book</Typography>
          <SubscriptionOrderBookStateChip state={props.model.state} />
          <Box
            sx={(theme) => ({
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            })}
          >
            {viewConfiguration?.["Subscription"]?.["Subscription Order Book"]
              ?.ResolveState && (
              <Button
                disabled={resolutionInProgress}
                variant={"contained"}
                color={"primary"}
                children={"Resolve State"}
                onClick={handleResolveSubscriptionOrderBookState}
              />
            )}
            {viewConfiguration?.["Subscriptions"]?.["Sign for Settlement"] &&
              props.model.state ===
                SubscriptionOrderBookState.SettlementInProgress &&
              props.model.settlementTransactionState ===
                TransactionState.SigningInProgress && (
                <Button onClick={handleSign} disabled={signingInProgress}>
                  Sign for Settlement
                </Button>
              )}
            <Tooltip title={"Refresh"}>
              <span>
                <IconButton onClick={props.reload} size={"small"}>
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Subscription Total
            </Typography>
            <Amount amount={props.model.subscriptionAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Oversubscription Total
            </Typography>
            <Amount amount={props.model.overSubscriptionAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Unit Price
            </Typography>
            <Amount amount={props.model.unitPrice} />
          </Box>
        </Box>

        <Divider />

        <Typography variant={"h5"}>Settlement</Typography>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Subscribed Amount
            </Typography>
            <Amount amount={settlementViewModel.subscribedAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Subscribed Tokens
            </Typography>
            <Amount amount={settlementViewModel.subscribedTokens} />
          </Box>
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Outstanding Amount
            </Typography>
            <Amount amount={settlementViewModel.outstandingAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Outstanding Tokens
            </Typography>
            <Amount amount={settlementViewModel.outstandingTokens} />
          </Box>
        </Box>
        <Box>
          <Box
            sx={() => ({
              display: "grid",
              gridTemplateColumns: "repeat(7, auto)",
            })}
          >
            <Typography variant={"caption"} color={"textSecondary"}>
              No.
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              Underwriter
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              %
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              Max Amount
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              Max Tokens
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              Taken Up Amount
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              Taken Up Tokens
            </Typography>
            {settlementViewModel.underwriterSettlementAllocations.map((a) => (
              <React.Fragment key={a.id}>
                <Typography color={"textSecondary"}>{a.number}</Typography>
                <Typography color={"textSecondary"}>
                  {a.ownerClientName}
                </Typography>
                <Typography color={"textSecondary"}>
                  {a.percentage.toString()}
                </Typography>
                <Amount amount={a.maxTakeUpAmount} />
                <Amount amount={a.maxTakeUpTokens} />
                <Amount amount={a.takenUpAmount} />
                <Amount amount={a.takenUpTokens} />
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Total Underwriter Take Up Amount
            </Typography>
            <Amount amount={settlementViewModel.underwriterTakeUpAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Total Underwriter Take Up Tokens
            </Typography>
            <Amount amount={settlementViewModel.underwriterTakeUpTokens} />
          </Box>
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Total Settlement Amount
            </Typography>
            <Amount amount={settlementViewModel.settlementAmount} />
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Total Settlement Tokens
            </Typography>
            <Amount amount={settlementViewModel.settlementTokens} />
          </Box>
        </Box>
        <Box
          sx={() => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          })}
        >
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Left Over Amount [
              {settlementViewModel.subscribedAmount.token.code}]
            </Typography>
            <Typography>
              {formatTextNum(settlementViewModel.leftOverAmount)}
            </Typography>
          </Box>
          <Box>
            <Typography variant={"caption"} color={"textSecondary"}>
              Left Over Tokens [
              {settlementViewModel.subscribedTokens.token.code}]
            </Typography>
            <Typography>
              {formatTextNum(settlementViewModel.leftOverTokens)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
