import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TimeButton } from "./components/TimeButton";
import { MarketListingViewModel } from "james/views/marketListingView";
import { Amount } from "components/Ledger/Amount/Amount";
import { Amount as AmountType } from "james/ledger/Amount";
import { CountdownComponent } from "./components/CountdownComponent";
import { SubscriptionOrderBookState } from "james/market/SubscriptionOrderBook";
import { SubscriptionTicketDialog } from "components/SubscriptionTicketDialog/SubsciptionTicketDialog";
import { useApplicationContext } from "context/Application/Application";
import { ClientKYCStatus } from "james/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LedgerAccountCategory, Token } from "james/ledger";
import { LedgerNetwork } from "james/ledger/Network";
import { useSnackbar } from "notistack";
import { WarningWithActions } from "components/Snackbar/WarningWithActions";
import LogRocket from "logrocket";
import { AssetEvent } from "const/logRocket";
import cx from "classnames";
import { useAccountContext } from "context/Account/Account";
import BigNumber from "bignumber.js";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { MechanismType } from "james/market";
import { WaitingListPopUpDialog } from "components/WaitingListPopUp/WaitingListPopUp";
import { convertShortnameToInteractionDriver } from "./components/interactionFunctions";

interface BondSectionProps {
  marketListingViewModel: MarketListingViewModel;
}

export const BondSection = ({ marketListingViewModel }: BondSectionProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [hover, setHover] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [showWaitingListPopUp, setShowWaitingListPopUp] = useState(false);
  const { viewConfiguration, myClientKYCStatus, authContext } =
    useApplicationContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { current: isPublic } = useRef(window.location.href.includes("public"));
  const navigate = useNavigate();
  const { stellarAccountContext } = useAccountContext();
  const [mZARBalance, setMZARBalance] = useState<string>("");
  const [modelID, setModelID] = useState("");

  // useEffect that sets the mZar balance
  useEffect(() => {
    // if a client kyc status is not verified return
    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      return;
    }

    // if the stellarAccountContext accounts are still being loaded return
    if (stellarAccountContext.loading) {
      return;
    }

    // retrieve the first trading account
    for (const a of stellarAccountContext.accounts) {
      if (a.category === LedgerAccountCategory.Trading) {
        setModelID(a.id);
        for (const bal of a.balances) {
          if (
            bal.tokenViewModel.issuer.includes("Mesh B.V.") &&
            bal.tokenViewModel.token.code.includes("mZAR")
          ) {
            setMZARBalance(bal.availableBalance().value.toString());
          }
        }
        break;
      }
    }
  }, [
    myClientKYCStatus,
    stellarAccountContext.loading,
    stellarAccountContext.accounts,
    viewConfiguration.Wallet,
  ]);

  const pending = useMemo(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel)
      return false;
    return (
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.state ===
        SubscriptionOrderBookState.Pending ||
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.state ===
        SubscriptionOrderBookState.Opening ||
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.state ===
        SubscriptionOrderBookState.OpeningFailed ||
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.state ===
        SubscriptionOrderBookState.OpeningUnderInvestigation
    );
  }, [marketListingViewModel.marketSubscriptionOrderBookViewModel]);
  const open = useMemo(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel)
      return false;
    return (
      marketListingViewModel.marketSubscriptionOrderBookViewModel?.state ===
      SubscriptionOrderBookState.Open
    );
  }, [marketListingViewModel.marketSubscriptionOrderBookViewModel]);
  const closed = useMemo(() => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel)
      return false;
    return [
      "",
      SubscriptionOrderBookState.Closed,
      SubscriptionOrderBookState.SettlementInProgress,
      SubscriptionOrderBookState.SettlementUnderInvestigation,
      SubscriptionOrderBookState.Settled,
      SubscriptionOrderBookState.SettlementFailed,
    ].includes(
      marketListingViewModel.marketSubscriptionOrderBookViewModel.state,
    );
  }, [marketListingViewModel.marketSubscriptionOrderBookViewModel]);

  const buttonDisabled = useMemo(() => {
    return (
      closed ||
      !marketListingViewModel.marketSubscriptionOrderBookViewModel ||
      (isPublic
        ? !pending
        : !viewConfiguration?.SubscriptionTrading?.SubmitSubscription)
    );
  }, [closed, viewConfiguration, myClientKYCStatus, marketListingViewModel]);

  // check if dialog should open as a result of a redirect
  useEffect(() => {
    const assetToken = new Token({
      code: searchParams.get("code") ?? "",
      issuer: searchParams.get("issuer") ?? "",
      network: (searchParams.get("network") ?? "-") as LedgerNetwork,
    });
    if (
      assetToken.isEqualTo(marketListingViewModel.token) &&
      searchParams.get("subscription") &&
      !isPublic &&
      viewConfiguration?.SubscriptionTrading?.SubmitSubscription
    ) {
      if (myClientKYCStatus === ClientKYCStatus.VerifiedStatus) {
        setDialog(true);
      } else {
        searchParams.delete("subscription");
        setSearchParams(searchParams, { replace: true });
        enqueueSnackbar("A Verified KYC Status is Required to Trade", {
          variant: "warning",
        });
      }
    }
  }, [marketListingViewModel, myClientKYCStatus, enqueueSnackbar]);

  const handleOpenDialog = useCallback(() => {
    if (pending) {
      setShowWaitingListPopUp(true);
      return;
    }
    if (isPublic) {
      navigate(
        `/market/asset-overview?code=${marketListingViewModel.token.code}&issuer=${marketListingViewModel.token.issuer}&network=${marketListingViewModel.token.network}&spot=true`,
      );

      return;
    }
    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      enqueueSnackbar("Want to Buy? You need to be verified", {
        preventDuplicate: true,
        content: (key, message) => (
          <WarningWithActions
            id={key}
            message={message}
            inLine={!smDown}
            actions={
              <Button
                id="notVerifiedSubscription-submitKYCInfo-button"
                onClick={() => {
                  navigate("/kyc");
                }}
                sx={(theme) => ({
                  color: theme.palette.secondary.contrastText,
                  borderColor: theme.palette.secondary.contrastText,
                  border: "1px solid",
                  width: { xs: theme.spacing(17.5), sm: theme.spacing(25) },
                  mr: { sm: 2 },
                })}
                variant="outlined"
              >
                submit info
              </Button>
            }
          />
        ),
      });
      return;
    }

    LogRocket.track(AssetEvent.openSubscriptionTicket, {
      assetName: marketListingViewModel.assetName,
      assetShortName: marketListingViewModel.assetShortName,
      assetType: marketListingViewModel.assetType,
    });

    if (open) {
      setDialog(!dialog);
    }
  }, [myClientKYCStatus, open, dialog]);

  const minDealSizeInQuoteToken = useMemo((): AmountType => {
    if (!marketListingViewModel.marketSubscriptionOrderBookViewModel) {
      return new AmountType();
    }

    const marketMechanismQuoteParameter =
      marketListingViewModel.getMarketMechanismQuoteParameter(
        MechanismType.Subscription,
        marketListingViewModel.marketSubscriptionOrderBookViewModel?.unitPrice
          .token,
      );

    return marketMechanismQuoteParameter.quoteToken.newAmountOf(
      marketListingViewModel.marketSubscriptionOrderBookViewModel.unitPrice.value.multipliedBy(
        marketMechanismQuoteParameter.minimumDealSize.value,
      ),
    );
  }, [isPublic]);

  return (
    <Box
      sx={{ position: "relative", width: "100%" }}
      onClick={(e) => {
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <Tooltip
        title={
          viewConfiguration?.SubscriptionTrading?.SubmitSubscription ||
          isPublic ||
          pending
            ? ""
            : "You do not have the required permissions"
        }
        placement={"top"}
      >
        <Box>
          <Button
            id="bond-section-action-button"
            fullWidth
            className={cx({
              hover: hover,
            })}
            sx={(theme) => ({
              height: 36,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: theme.palette.custom.midnight,
              color: theme.palette.secondary.main,
              "&.hover, &:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.primary,
              },
              "&:disabled": {
                backgroundColor: "#716F88",
                color: theme.palette.text.primary,
              },
              fontSize: "14px",
            })}
            variant="contained"
            disabled={buttonDisabled}
            onMouseEnter={() => setTimeout(() => setHover(true))}
            onMouseLeave={() => setHover(false)}
            onClick={(e) => {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }

              handleOpenDialog();
            }}
            data-link-info={JSON.stringify({
              content_interaction_id: "transact-cta",
              content_interaction_action: InteractionAction.Click,
              content_interaction_type: InteractionType.Button,
              content_interaction_text: open
                ? "place buy order"
                : pending
                  ? "join the waiting list"
                  : "subscription",
              content_interaction_driver: pending
                ? convertShortnameToInteractionDriver(
                    marketListingViewModel.assetShortName,
                  )
                : InteractionDriver.Subscribe,
            } as DataLinkInfoType)}
          >
            {!marketListingViewModel.marketSubscriptionOrderBookViewModel && (
              <Typography sx={{ fontWeight: "bold" }} children="Subscription" />
            )}
            {pending &&
              marketListingViewModel.marketSubscriptionOrderBookViewModel && (
                <>
                  <Typography
                    sx={{ fontWeight: "bold", textTransform: "none" }}
                    data-link-info={JSON.stringify({
                      content_interaction_id: "transact-cta",
                      content_interaction_action: InteractionAction.Click,
                      content_interaction_type: InteractionType.Button,
                      content_interaction_text: "join the waiting list",
                      content_interaction_driver:
                        convertShortnameToInteractionDriver(
                          marketListingViewModel.assetShortName,
                        ),
                    } as DataLinkInfoType)}
                  >
                    Join the Waiting List
                  </Typography>
                </>
              )}
            {open &&
              marketListingViewModel.marketSubscriptionOrderBookViewModel && (
                <Typography
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transact-cta",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Button,
                    content_interaction_text: "place buy order",
                    content_interaction_driver: InteractionDriver.Subscribe,
                  } as DataLinkInfoType)}
                  sx={{ fontWeight: "bold" }}
                >
                  Place Buy Order
                </Typography>
              )}
            {closed &&
              marketListingViewModel.marketSubscriptionOrderBookViewModel && (
                <Typography
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transact-cta",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Button,
                    content_interaction_text: "subscribtion",
                    content_interaction_driver: InteractionDriver.Subscribe,
                  } as DataLinkInfoType)}
                  sx={{ fontWeight: "bold" }}
                >
                  Subscription
                </Typography>
              )}
          </Button>
        </Box>
      </Tooltip>
      <Box
        sx={(theme) => ({
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          border: `1px solid ${theme.palette.text.disabled}`,
          borderTop: 0,
          height: 36,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        {!marketListingViewModel.marketSubscriptionOrderBookViewModel && (
          <Typography children="Pending" />
        )}
        {pending &&
          marketListingViewModel.marketSubscriptionOrderBookViewModel && (
            <CountdownComponent
              time={
                marketListingViewModel.marketSubscriptionOrderBookViewModel
                  ?.openDate
              }
            />
          )}
        {open &&
          marketListingViewModel.marketSubscriptionOrderBookViewModel && (
            <>
              <Amount
                codeTypographyProps={{
                  sx: (theme) => ({
                    color: theme.palette.text.secondary,
                  }),
                }}
                amount={minDealSizeInQuoteToken}
              />
              <Typography
                sx={(theme) => ({ ml: 1, color: theme.palette.text.secondary })}
                children={`| Min Size`}
              />
            </>
          )}
        {closed &&
          marketListingViewModel.marketSubscriptionOrderBookViewModel && (
            <Typography>Closed</Typography>
          )}
      </Box>
      {open && marketListingViewModel.marketSubscriptionOrderBookViewModel && (
        <Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            position: "absolute",
            top: "calc(50% - 23px)",
            right: {
              md: "16px",
              xs: "8px",
            },
          }}
        >
          <TimeButton
            accountID={modelID}
            unfunded={marketListingViewModel.marketSubscriptionOrderBookViewModel?.unitPrice.value.gt(
              new BigNumber(mZARBalance),
            )}
            time={
              marketListingViewModel.marketSubscriptionOrderBookViewModel
                ?.closeDate
            }
            disabled={buttonDisabled}
            hover={hover}
          />
        </Box>
      )}
      {dialog && (
        <SubscriptionTicketDialog
          dialogProps={{ open: dialog }}
          marketListingViewModel={marketListingViewModel}
          closeDialog={() => setDialog(false)}
          baseToken={marketListingViewModel.token}
        />
      )}
      {showWaitingListPopUp && (
        <WaitingListPopUpDialog
          dialogProps={{ open: showWaitingListPopUp }}
          closeDialog={() => setShowWaitingListPopUp(false)}
          assetToken={marketListingViewModel.token}
          isPublic={isPublic}
          context={authContext}
          marketListingViewModel={marketListingViewModel}
        />
      )}
    </Box>
  );
};
