import React, { ChangeEvent } from "react";

import { styled } from "@mui/material/styles";

import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ConnectedIndividual } from "james/legal";
import { Company } from "james/legal/company";
import { Address } from "james/location";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import { countries } from "james/country";
import { CountryDropdown } from "components/FormFields/CountryDropdown";
const PREFIX = "HeadOfficeAddress";

const classes = {
  titleLayout: `${PREFIX}-titleLayout`,
  equalColumns2Gap: `${PREFIX}-equalColumns2Gap`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.titleLayout}`]: {
    height: 35,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },

  [`& .${classes.equalColumns2Gap}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2),
  },
}));

interface HeadOfficeAddressProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (name: string) => (value: any) => void;
  company: Company;
  headOfficeAddressSame: boolean;
  setHeadOfficeAddressSame: (value: boolean) => void;
  FormFieldValid: { [key: string]: string | undefined };
  setFormFieldValid: (value: { [key: string]: string | undefined }) => void;
}

export const HeadOfficeAddress: React.FC<HeadOfficeAddressProps> = (
  props: HeadOfficeAddressProps,
) => {
  const {
    company,
    onChangeValue,
    headOfficeAddressSame,
    setHeadOfficeAddressSame,
    FormFieldValid,
    setFormFieldValid,
  } = props;

  const headOfficeAddress = headOfficeAddressSame
    ? company.registeredAddress
    : new Address(company.headOfficeAddress);

  const InlineFormValidation = (field: string, address: Address) =>
    debounce(() => {
      let InvalidFormField = { ...FormFieldValid, [field]: undefined };

      if (isEqual(new Address(address), new Address())) {
        InvalidFormField = {
          ...InvalidFormField,
          headOfficeAddressAddressLine1: undefined,
          headOfficeAddressCountryCode: undefined,
          headOfficeAddressCity: undefined,
        };
      }

      setFormFieldValid(InvalidFormField);
    }, 600);

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    updateState(name, event.target.value);
  };

  const autoCompleteOnUpdate =
    (name: string) =>
    (
      __: React.SyntheticEvent<Element, Event>,
      countryOption: { value: string; label: string } | null,
    ) => {
      updateState(name, countryOption ? countryOption.value : "");
    };

  const updateState = (name: string, value: string) => {
    const keys = name.split(".") as (keyof ConnectedIndividual)[];
    const newAddress = new Address({
      ...headOfficeAddress,
      [keys[1]]: value,
    } as Address);
    onChangeValue("headOfficeAddress")(newAddress);
    InlineFormValidation(camelCase(name), newAddress)();
  };

  const sameAsRegisteredAddress = () => {
    if (headOfficeAddressSame) {
      onChangeValue("headOfficeAddress")(
        new Address(company.registeredAddress),
      );
    } else {
      onChangeValue("headOfficeAddress")(new Address(headOfficeAddress));
    }
    setHeadOfficeAddressSame(!headOfficeAddressSame);
  };

  return (
    <Root>
      <div className={classes.titleLayout}>
        <Typography variant="h6" children="Head Office Address" />
        <FormControlLabel
          id="contactDetails-headOfficeAddress-sameAsRegisteredAddress"
          labelPlacement="start"
          control={
            <Checkbox
              color="primary"
              checked={headOfficeAddressSame}
              onChange={sameAsRegisteredAddress}
            />
          }
          label="Copy Registered"
        />
      </div>
      {headOfficeAddress && (
        <>
          <CountryDropdown
            id="contactDetails-headOfficeAddress-country"
            isOptionEqualToValue={(option, value) => {
              if (value.value === "") {
                return true;
              }
              return option === value;
            }}
            onChange={autoCompleteOnUpdate("headOfficeAddress.countryCode")}
            disabled={headOfficeAddressSame}
            value={
              headOfficeAddress.countryCode === ""
                ? {
                    value: "",
                    label: "",
                  }
                : {
                    value: headOfficeAddress.countryCode,
                    label: countries.filter(
                      (c) =>
                        c.value === new Address(headOfficeAddress).countryCode,
                    )[0].label,
                  }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Country"
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select...",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                error={Boolean(FormFieldValid.headOfficeAddressCountryCode)}
                fullWidth
                helperText={FormFieldValid.headOfficeAddressCountryCode}
              />
            )}
          />
          <TextField
            id="contactDetails-headOfficeAddress-line1"
            margin="dense"
            disabled={headOfficeAddressSame}
            variant="outlined"
            label="Address 1"
            fullWidth
            onChange={onChange("headOfficeAddress.addressLine1")}
            value={headOfficeAddress.addressLine1}
            error={Boolean(FormFieldValid.headOfficeAddressAddressLine1)}
            helperText={FormFieldValid.headOfficeAddressAddressLine1}
          />
          <TextField
            id="contactDetails-headOfficeAddress-line2"
            margin="dense"
            disabled={headOfficeAddressSame}
            variant="outlined"
            label="Address 2"
            placeholder="Optional"
            fullWidth
            onChange={onChange("headOfficeAddress.addressLine2")}
            value={headOfficeAddress.addressLine2}
            helperText={FormFieldValid.headOfficeAddressAddressLine2}
          />
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-headOfficeAddress-city"
              margin="dense"
              disabled={headOfficeAddressSame}
              variant="outlined"
              label="City"
              fullWidth
              onChange={onChange("headOfficeAddress.city")}
              value={headOfficeAddress.city}
              error={Boolean(FormFieldValid.headOfficeAddressCity)}
              helperText={FormFieldValid.headOfficeAddressCity}
            />
            <TextField
              id="contactDetails-headOfficeAddress-suburb"
              margin="dense"
              variant="outlined"
              placeholder="Optional"
              disabled={headOfficeAddressSame}
              label="Suburb"
              fullWidth
              onChange={onChange("headOfficeAddress.suburb")}
              value={headOfficeAddress.suburb}
              helperText={FormFieldValid.headOfficeAddressSuburb}
            />
          </div>
          <div className={classes.equalColumns2Gap}>
            <TextField
              id="contactDetails-headOfficeAddress-province"
              margin="dense"
              placeholder="Optional"
              disabled={headOfficeAddressSame}
              variant="outlined"
              label="Province"
              fullWidth
              onChange={onChange("headOfficeAddress.province")}
              value={headOfficeAddress.province}
              helperText={FormFieldValid.headOfficeAddressProvince}
            />
            <TextField
              id="contactDetails-headOfficeAddress-postalCode"
              margin="dense"
              disabled={headOfficeAddressSame}
              variant="outlined"
              label="Postal Code"
              placeholder="Optional"
              fullWidth
              onChange={onChange("headOfficeAddress.postalCode")}
              value={headOfficeAddress.postalCode}
              helperText={FormFieldValid.headOfficeAddressPostalCode}
            />
          </div>
        </>
      )}
    </Root>
  );
};
