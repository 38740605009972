import dayjs from "dayjs";
import { AuditEntry } from "james/audit";
import { Amount } from "james/ledger/Amount";
import { Token } from "james/ledger/Token";
import { InstrumentStablecoin } from "./InstrumentStablecoin";
import { FinancialInstrumentStablecoinState } from "./InstrumentStablecoinState";

export const UnitTrustStablecoinTypeName =
  "mesh::financial/UnitTrustStablecoin";

export enum UnitTrustStablecoinState {
  Draft = "Draft",
  PreIssued = "Pre-Issued",
  Issued = "Issued",
  Matured = "Matured",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export const AllUnitTrustStablecoinStates: UnitTrustStablecoinState[] = [
  UnitTrustStablecoinState.Draft,
  UnitTrustStablecoinState.PreIssued,
  UnitTrustStablecoinState.Issued,
  UnitTrustStablecoinState.Matured,
  UnitTrustStablecoinState.Cancelled,
  UnitTrustStablecoinState.Deleted,
];

export enum UnitTrustStablecoinAction {
  //
  // UnitTrustStablecoinCreator Actions
  //
  New,

  //
  // UnitTrustStablecoinStateChanger Actions
  //
  MarkDeleted,
  Issue,
  PreIssue,
  MarkIssued,
  Cancel,
  Mature,

  //
  // UnitTrustStablecoinUpdater Actions
  //
  DraftUpdate,
  IncreaseMaximumUnits,
  DecreaseMaximumUnits,
  ChangeMaturityDate,
}

export class UnitTrustStablecoin implements InstrumentStablecoin {
  public ["@type"]: string = UnitTrustStablecoinTypeName;

  public id = "";

  public ownerID = "";

  public state: UnitTrustStablecoinState | "" = "";

  public auditEntry: AuditEntry = new AuditEntry();

  public shortName = "";

  public issueDate: string = dayjs().format();

  public maturityDate: string = dayjs().format();

  public token: Token = new Token();

  public instrumentID = "";

  public valuationToken: Token = new Token();

  public maximumUnits: Amount = new Amount();

  constructor(unitTrustStablecoin?: UnitTrustStablecoin) {
    if (!unitTrustStablecoin) {
      return;
    }
    this.id = unitTrustStablecoin.id;
    this.ownerID = unitTrustStablecoin.ownerID;
    this.state = unitTrustStablecoin.state;
    this.auditEntry = new AuditEntry(unitTrustStablecoin.auditEntry);
    this.shortName = unitTrustStablecoin.shortName;
    this.issueDate = unitTrustStablecoin.issueDate;
    this.maturityDate = unitTrustStablecoin.maturityDate;
    this.token = new Token(unitTrustStablecoin.token);
    this.instrumentID = unitTrustStablecoin.instrumentID;
    this.valuationToken = new Token(unitTrustStablecoin.valuationToken);
    this.maximumUnits = new Amount(unitTrustStablecoin.maximumUnits);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.shortName;
  }

  assetShortName(): string {
    return this.shortName;
  }

  assetFractionalisationAllowed(): boolean {
    return true;
  }

  instrumentStablecoinInstrumentID(): string {
    return this.instrumentID;
  }

  instrumentStablecoinValuationToken(): Token {
    return this.valuationToken;
  }

  instrumentStablecoinMaturityDate(): string {
    return this.maturityDate;
  }

  instrumentStablecoinMaximumUnits(): Amount {
    return this.maximumUnits;
  }

  instrumentStablecoinState(): FinancialInstrumentStablecoinState {
    return this.state;
  }
}

export function getPotentialNextUnitTrustStablecoinActions(
  unitTrustStablecoinState: UnitTrustStablecoinState | "",
): UnitTrustStablecoinAction[] {
  switch (unitTrustStablecoinState) {
    case UnitTrustStablecoinState.Draft: {
      return [
        UnitTrustStablecoinAction.MarkDeleted,
        UnitTrustStablecoinAction.DraftUpdate,
        UnitTrustStablecoinAction.Issue,
        UnitTrustStablecoinAction.PreIssue,
      ];
    }

    case UnitTrustStablecoinState.PreIssued: {
      return [
        UnitTrustStablecoinAction.MarkIssued,
        UnitTrustStablecoinAction.Cancel,
        UnitTrustStablecoinAction.IncreaseMaximumUnits,
        UnitTrustStablecoinAction.DecreaseMaximumUnits,
        UnitTrustStablecoinAction.ChangeMaturityDate,
      ];
    }

    case UnitTrustStablecoinState.Issued:
      return [
        UnitTrustStablecoinAction.Cancel,
        UnitTrustStablecoinAction.Mature,
      ];

    case UnitTrustStablecoinState.Matured:
      return [];

    case UnitTrustStablecoinState.Cancelled:
      return [];

    case UnitTrustStablecoinState.Deleted:
      return [];

    default:
      throw new TypeError(
        `invalid UnitTrustStablecoinState: '${unitTrustStablecoinState}'`,
      );
  }
}
